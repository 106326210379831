import { ReactNode } from 'react';

export type ValidateFunc<Value> = (value: Value) => FieldError;

export type FieldError = string | undefined;

type ComposeValidatorType<Value> = undefined | false | ValidateFunc<Value>;

/**
 * Good when you have multiple validators
 *
 * validationCompose(
 *      value,
 *      required && requiredValidator,
 *      someValidator,
 * );
 */
export function validationCompose<Value>(
  value: Value,
  ...validators: ComposeValidatorType<Value>[]
): FieldError {
  for (const validator of validators) {
    if (!validator) continue;
    const error = validator(value);
    if (error) return error;
  }
}

export function requiredValidator(value: unknown): FieldError {
  if (value === undefined || value === '') return '* Required';
}

/**
 * OnItemChange represent a function when some item of the list change
 */
export type OnItemChange<
  Item,
  Key extends keyof Item = keyof Item,
  Value = Item[Key]
> = (_: {
  currentModel: Item;
  previousModel: Item;
  changeKey: Key;
  changeValue: Value;
}) => Promise<void>;

/**
 * Got onItemChange function and return bake a function that handle when value change (OnValueChange)
 */
export function builtOnValueChange<Item>(
  onItemChange: OnItemChange<Item>
): OnValueChange<Item> {
  return function <Key extends keyof Item>({
    item,
    value,
    key,
  }: {
    item: Item;
    value?: Item[Key];
    key: Key;
  }) {
    return onItemChange({
      previousModel: item,
      currentModel: { ...item, [key]: value as Item[Key] },
      changeValue: value as Item[Key],
      changeKey: key,
    });
  };
}

export type RenderEditableParams<Item, Key extends keyof Item> = {
  value: Item[Key];
  onChange: (value?: Item[Key]) => Promise<void>;
  item: Item;
  key: Key;
};

export type RenderEditable<Item, Key extends keyof Item> = (
  _: RenderEditableParams<Item, Key>
) => ReactNode;

export type OnValueChangeParams<Item, Key extends keyof Item> = {
  item: Item;
  value?: Item[Key];
  key: Key;
};

export type OnValueChange<Item, Key extends keyof Item = keyof Item> = (
  _: OnValueChangeParams<Item, Key>
) => Promise<void>;
