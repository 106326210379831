import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgVisualizeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M16.188 8.64C15.73 7.848 13.216 3.87 8.947 4c-3.949.1-6.234 3.57-6.855 4.641a.714.714 0 0 0 0 .714c.45.779 2.856 4.642 7.062 4.642h.178c3.95-.1 6.241-3.57 6.855-4.642a.715.715 0 0 0 0-.714Zm-6.891 3.928c-3.078.071-5.084-2.564-5.713-3.57.714-1.15 2.578-3.5 5.434-3.571 3.064-.079 5.077 2.563 5.713 3.57-.736 1.15-2.578 3.5-5.434 3.57Z"
      fill="currentColor"
    />
    <path
      d="M9.14 6.498a2.5 2.5 0 1 0 0 4.999 2.5 2.5 0 0 0 0-4.999Zm0 3.57a1.07 1.07 0 1 1 0-2.141 1.07 1.07 0 0 1 0 2.141Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgVisualizeIcon;
