import { Node } from '@tensorleap/api-client';
import { ChangeEventHandler, forwardRef, useCallback, useMemo } from 'react';
import { Input } from '../ui/atoms/Input';
import { DetailsProps } from './NodeDetails';
import { useNetworkMapContext } from '../core/NetworkMapContext';

export const USER_UNIQUE_NAME = 'user_unique_name';
export const USER_NAME_ALREADY_EXISTED_MSG = "This node's name must be unique";

export function isUserUniqueNameDuplicated(
  nodeName: string,
  nodeId: Node['id'],
  nodes: ROMap<Node['id'], Node>
): boolean {
  if (!nodeName) return false;
  const nodesAsArray = Array.from(nodes.values());
  return nodesAsArray.some(
    (someNode) =>
      someNode.id !== nodeId && someNode.data[USER_UNIQUE_NAME] === nodeName
  );
}

export const UserUniqueName = forwardRef<HTMLInputElement, DetailsProps>(
  ({ node }, ref) => {
    const { changeNodeProperty, nodes } = useNetworkMapContext();
    const currentName = node.data[USER_UNIQUE_NAME] || '';

    const error = useMemo(() => {
      const isNameExists = isUserUniqueNameDuplicated(
        currentName,
        node.id,
        nodes
      );
      if (isNameExists) return USER_NAME_ALREADY_EXISTED_MSG;
      else if (!currentName) {
        return 'Required';
      }
    }, [nodes, currentName, node.id]);

    const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (e) => {
        const name = e.target.value || '';
        changeNodeProperty({
          nodeId: node.id,
          nodeDataPropsToUpdate: { [USER_UNIQUE_NAME]: name },
        });
      },
      [changeNodeProperty, node]
    );

    return (
      <Input
        ref={ref}
        error={error}
        required
        label="NAME"
        value={currentName}
        onChange={handleChange}
      />
    );
  }
);

UserUniqueName.displayName = 'UserUniqueName';
