import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgSlackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M16.774 9a2.52 2.52 0 1 0-3.095-3.923V2.52A2.52 2.52 0 0 0 9 1.226 2.52 2.52 0 1 0 5.077 4.32H2.52A2.52 2.52 0 0 0 1.226 9a2.52 2.52 0 1 0 3.095 3.923v2.556A2.52 2.52 0 0 0 9 16.774a2.52 2.52 0 1 0 3.923-3.095h2.556A2.52 2.52 0 0 0 16.774 9ZM15.48 5.04a1.8 1.8 0 1 1 0 3.6h-1.8v-1.8a1.808 1.808 0 0 1 1.8-1.8Zm-2.556 3.6.036-.036v.036h-.036ZM11.16.722a1.809 1.809 0 0 1 1.8 1.8V6.84a1.808 1.808 0 0 1-1.8 1.8h-1.8V2.521a1.809 1.809 0 0 1 1.8-1.8Zm-1.8 12.201.037.036H9.36v-.036ZM8.64 5.077l-.036-.036h.036v.036ZM5.04 2.52a1.8 1.8 0 0 1 3.6 0v1.8h-1.8a1.809 1.809 0 0 1-1.8-1.8ZM.723 6.841a1.809 1.809 0 0 1 1.8-1.8H6.84a1.809 1.809 0 0 1 1.8 1.8v1.8H2.521a1.809 1.809 0 0 1-1.8-1.8ZM5.077 9.36l-.036.036V9.36h.036Zm-2.556 3.6a1.8 1.8 0 1 1 0-3.6h1.8v1.8a1.809 1.809 0 0 1-1.8 1.8Zm4.32 4.318a1.809 1.809 0 0 1-1.8-1.8V11.16a1.809 1.809 0 0 1 1.8-1.8h1.8v6.12a1.809 1.809 0 0 1-1.8 1.8Zm6.118-1.8a1.8 1.8 0 1 1-3.6 0V13.68h1.8a1.808 1.808 0 0 1 1.8 1.8Zm2.52-2.519h-4.32a1.808 1.808 0 0 1-1.8-1.8v-1.8h6.12a1.8 1.8 0 1 1 0 3.6Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSlackIcon;
