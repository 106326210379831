import {
  MultiChartsResponse,
  NumberOrString,
  SessionRunData,
  SplitAgg,
} from '@tensorleap/api-client';
import download from 'downloadjs';
import { unparse } from 'papaparse';

export function exportMultiChartsToCsv(
  xyChartsResponse: MultiChartsResponse,
  selectedSessionRunMap: Map<string, SessionRunData>,
  innerSplit: SplitAgg | null
): void {
  const allCharts: Record<NumberOrString, NumberOrString>[] = [];

  xyChartsResponse.charts.forEach((chart) => {
    const verticalLabel = chart.verticalKey;
    const horizontalLabel = chart.horizontalKey;
    chart.data.data.forEach((data) => {
      const point: Record<NumberOrString, NumberOrString> = {
        ...data.metadata,
        ...data.data,
      };
      const innerKey = innerSplit?.field;
      if (innerKey && innerKey in point) {
        point[innerKey] = mapValue(point[innerKey]);
      }
      if (verticalLabel !== undefined && verticalLabel !== '') {
        point['vertical'] = mapValue(verticalLabel);
      }
      if (horizontalLabel !== undefined && horizontalLabel !== '') {
        point['horizontal'] = mapValue(horizontalLabel);
      }
      allCharts.push(point);
    });
  });

  function mapValue(value: NumberOrString): NumberOrString {
    const sessionRun = selectedSessionRunMap.get(String(value));

    if (sessionRun) {
      return sessionRun.name;
    }
    return value;
  }

  const csv = unparse(allCharts);
  download(csv, 'chartData.csv', 'text/csv');
}
