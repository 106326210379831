import useSWR, { KeyedMutator } from 'swr';
import { SlimVisualization, Visualization } from '@tensorleap/api-client';
import api from '../api-client';
import { CacheKey } from './consts';
import { useMergedObject } from '../useMergedObject';
import { useMapProjectStorageUrl } from '../useProjectStorage';

export interface UseFullVisualization {
  visualization?: Visualization | undefined;
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<Visualization | undefined>;
}

export function useFullVisualization(
  projectId: string,
  visualizationId?: string
): UseFullVisualization {
  const { mapUrl } = useMapProjectStorageUrl();

  const { data: visualization, error, mutate } = useSWR<
    Visualization | undefined,
    Error
  >(`${CacheKey.FULL_VISUALIZATION}-${visualizationId}`, async () => {
    if (!visualizationId) {
      return undefined;
    }
    const { slimVisualization } = await api.getSlimVisualization({
      visualizationId,
      projectId,
    });

    const fullBlobUrl = mapUrl(slimVisualization.blob);
    return await fetchVisualization(fullBlobUrl, slimVisualization);
  });

  return useMergedObject({
    visualization,
    error,
    isLoading: !error && !visualization,
    refetch: mutate,
  });
}

export function useFullVisualizationBySlim(
  slimVisualization: SlimVisualization
): UseFullVisualization {
  const { mapUrl } = useMapProjectStorageUrl();

  const { data: visualization, error, mutate } = useSWR<
    Visualization | undefined,
    Error
  >(`${CacheKey.FULL_VISUALIZATION}-${slimVisualization.cid}`, () => {
    const fullBlobUrl = mapUrl(slimVisualization.blob);
    return fetchVisualization(fullBlobUrl, slimVisualization);
  });

  return useMergedObject({
    visualization,
    error,
    isLoading: !error && !visualization,
    refetch: mutate,
  });
}

export async function fetchVisualization(
  fullBlobUrl: string,
  slimVisualization: SlimVisualization
): Promise<Visualization> {
  const blobContent = await fetch(fullBlobUrl, {
    method: 'GET',
    cache: 'force-cache',
    credentials: 'include',
  });
  const content = await blobContent.json();

  const visualization: Visualization = {
    ...slimVisualization,
    data: content,
  };
  return visualization;
}
