import { ValidateAssetsStatus } from '../../network-editor/interfaces/ValidateGraphStatus';
import { useNetworkMapContext } from '../../core/NetworkMapContext';
import { ProcessButton, ProcessButtonState } from '../atoms/ProcessButton';
import { CheckedIcon } from '../icons';
import { useFreemium } from '../../trial/useFreemium';
import { useCallback } from 'react';

export const VALIDATE_ASSETS_STATUS_TO_BUTTON_STATE: {
  [key in ValidateAssetsStatus]: ProcessButtonState;
} = {
  [ValidateAssetsStatus.Passed]: ProcessButtonState.Passed,
  [ValidateAssetsStatus.Failed]: ProcessButtonState.Failed,
  [ValidateAssetsStatus.Unstarted]: ProcessButtonState.Unstarted,
  [ValidateAssetsStatus.Calculating]: ProcessButtonState.Processing,
  [ValidateAssetsStatus.CalculatingDigest]: ProcessButtonState.Unknown,
};

export interface ValidateAssetsButtonProps {
  className?: string;
}

export function ValidateAssetsButton({
  className = 'h-[2.25rem] w-[17rem]',
}: ValidateAssetsButtonProps): JSX.Element {
  const {
    handleValidateAssetsClicked,
    validateAssetsStatus,
    validateAssetsButtonState,
  } = useNetworkMapContext();
  const { runOrOpenUpgrade, upgradeDialog } = useFreemium();

  const handleValidateAssetsClickedWithFreemiumCheck = useCallback(() => {
    runOrOpenUpgrade(handleValidateAssetsClicked);
  }, [handleValidateAssetsClicked, runOrOpenUpgrade]);

  return (
    <>
      {upgradeDialog}
      <div className="flex justify-center mt-3">
        <ProcessButton
          onClick={handleValidateAssetsClickedWithFreemiumCheck}
          processState={
            VALIDATE_ASSETS_STATUS_TO_BUTTON_STATE[validateAssetsStatus]
          }
          disabled={validateAssetsButtonState.isDisabled}
          tooltipTitle={validateAssetsButtonState.tooltipTitle}
          className={className}
        >
          <CheckedIcon className="h-7 w-7" />
          <span className="mt-[0.1rem]">Validate Assets</span>
        </ProcessButton>
      </div>
    </>
  );
}
