import { Node } from '@tensorleap/api-client';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useCallback } from 'react';
import { NodeDetailsPanel } from './NodeDetalisPanel';
import SvgSettingsCog2 from '../ui/icons/SettingsCog2';
import { useNetworkMapContext } from '../core/NetworkMapContext';

interface LayerSettingProps {
  node: Node;
}
export function LayerSetting({ node }: LayerSettingProps) {
  const { currentDatasetSetup, changeNodeProperty } = useNetworkMapContext();

  const handleDatasetChange = useCallback(
    (e) => {
      changeNodeProperty({
        nodeId: node.id,
        nodeDataPropsToUpdate: { prediction_type: e.target.value as string },
      });
    },
    [changeNodeProperty, node.id]
  );

  return (
    <NodeDetailsPanel
      openByDefault
      title="Settings"
      className="p-4"
      icon={<SvgSettingsCog2 />}
    >
      <FormControl variant="outlined" className="h-12 mb-2 w-full">
        <InputLabel id="prediction-type-id">PREDICTION</InputLabel>
        <Select
          labelId="prediction-type-id"
          label="PREDICTION"
          onChange={handleDatasetChange}
          value={node.data?.prediction_type}
        >
          {currentDatasetSetup?.prediction_types?.map(({ name }) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </NodeDetailsPanel>
  );
}
