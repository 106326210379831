import { Md5 } from 'ts-md5';

export function calcHash(data: unknown): string {
  const stringifyData = sortAndStringifyData(data);
  const hash = Md5.hashStr(String(stringifyData));
  return hash;
}

function sortAndStringifyData(data: unknown): string | number | boolean {
  if (Array.isArray(data)) {
    const val = data.map(sortAndStringifyData).sort().join(', ');
    return `[${val}]`;
  }

  if (data instanceof Date) {
    return data.toISOString();
  }

  if (isObject(data)) {
    const val = Object.keys(data)
      .filter((key) => data[key] !== undefined)
      .sort()
      .map((key) => {
        let val = sortAndStringifyData(data[key]);
        if (typeof val === 'string') {
          val = `"${val}"`;
        }
        return `"${key}": ${val}`;
      })
      .join(',');
    return `{${val}}`;
  }

  return data as string | number | boolean;
}

function isObject(data: unknown): data is Record<string, unknown> {
  return typeof data === 'object' && data !== null;
}
