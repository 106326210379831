import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgTransparencyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.863 14.64a5.777 5.777 0 1 1 0-11.554 5.777 5.777 0 0 1 0 11.554Zm0 1.086A6.863 6.863 0 1 1 6.863 2a6.863 6.863 0 0 1 0 13.726Z"
      fill="currentColor"
    />
    <path
      d="M9 2.34a6.866 6.866 0 0 1 0 13.047A6.863 6.863 0 1 0 9 2.34Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.137 14.64a5.777 5.777 0 1 1 0-11.554 5.777 5.777 0 0 1 0 11.554Zm0 1.086a6.863 6.863 0 1 1 0-13.726 6.863 6.863 0 0 1 0 13.726Z"
      fill="currentColor"
    />
    <mask
      id="transparency-icon_svg__a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={4}
      y={2}
      width={14}
      height={14}
    >
      <circle
        cx={6.863}
        cy={6.863}
        r={6.863}
        transform="matrix(-1 0 0 1 18 2)"
        fill="currentColor"
      />
    </mask>
    <g
      mask="url(#transparency-icon_svg__a)"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
    >
      <path d="M5.034 13.952h8.294v-.906H5.034v.906ZM5.034 12.105h8.294v-.906H5.034v.906ZM5.034 10.258h8.294v-.906H5.034v.905ZM5.034 8.374h8.294V7.47H5.034v.905ZM5.034 6.527h8.294v-.905H5.034v.905ZM5.034 4.68h8.294v-.905H5.034v.905Z" />
    </g>
  </svg>
);

export default SvgTransparencyIcon;
