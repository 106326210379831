import clsx from 'clsx';
import { DatasetVersionProperties } from '../../layer-details/DatasetVersionProperties';
import { DoubleCheck } from '../../ui/icons';
import { Title } from '../../ui/atoms/Title';
import { testStatusToBg } from './utils/helper-functions';
import { useDatasets } from '../../core/DatasetsContext';

export function DatasetVersionDetails(): JSX.Element {
  const { editorDatasetVersion, selectedErrorFn } = useDatasets();

  const modelSetup = editorDatasetVersion?.metadata.modelSetup;
  const datasetSetup = editorDatasetVersion?.metadata.setup;
  const versionStatus = editorDatasetVersion?.testStatus;

  return (
    <div className="flex flex-col w-80 mt-12">
      <div className="flex-row flex justify-between items-center mx-4 pb-4">
        <Title small bottomBorderClassName="border-b-dataset-600">
          SCRIPTS ASSETS
        </Title>

        <DoubleCheck
          className={clsx(
            'rounded-2xl p-1 w-8 h-7',
            testStatusToBg(versionStatus)
          )}
        />
      </div>

      <DatasetVersionProperties
        datasetSetup={datasetSetup}
        modelSetup={modelSetup}
        selectedErrorFn={selectedErrorFn}
      />
    </div>
  );
}
