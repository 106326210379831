import { useMemo } from 'react';
import {
  ClusterVisualizationFilter,
  isInsightDisplayData,
} from '../core/types/filters';
import { useModelFilter } from '../ui/molecules/useModelFilter';
import { InsightHeader } from './InsightCardHeader';
import { InsightCardBody } from './InsightCardBody';
import { BackIcon } from '../ui/icons';
import { ActionButton } from '../ui/molecules/ActionButton';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';
import { InsightSubCategoryType } from './utils';

export interface PinnedInsightProps {
  displayedInsight: ClusterVisualizationFilter;
  insightSubType?: InsightSubCategoryType;
  displayPreviousInsight: () => void;
}

export function PinnedInsight({
  displayedInsight,
  displayPreviousInsight,
  insightSubType,
}: PinnedInsightProps): JSX.Element {
  const { displayData } = displayedInsight;

  const { selected: selectedSessionRuns } = useModelFilter();

  const modelUniqueKey = useMemo(() => {
    if (!displayData) {
      return -1;
    }
    const selectedSessionRun = selectedSessionRuns.find(
      (sr) => sr.id === displayData.sessionRun.id
    );

    if (!selectedSessionRun) {
      return -1;
    }

    return selectedSessionRun.modelUniqueKey;
  }, [displayData, selectedSessionRuns]);

  if (!displayData || !isInsightDisplayData(displayData)) {
    return <></>;
  }

  return (
    <div
      className="flex flex-col border-y-2 border-gray-700 pt-2 "
      id={TOUR_SELECTORS_ENUM.PINNED_INSIGHT_ID}
    >
      <div className="flex flex-row justify-between -mb-3">
        <InsightHeader
          showAsACard={false}
          insightSubType={insightSubType}
          insightIndex={displayData.insightData.index}
        />
        <ActionButton
          onRun={displayPreviousInsight}
          className="w-fit border-2 h-9"
          icon={<BackIcon />}
        >
          Back
        </ActionButton>
      </div>
      <InsightCardBody
        insightData={displayData.insightData}
        selectedSessionRun={{
          name: displayData.sessionRun.name,
          id: displayData.sessionRun.id,
          sessionId: displayData.sessionRun.sessionId,
          visible: true,
          modelUniqueKey,
        }}
      />
    </div>
  );
}
