import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgPopExIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M6.605 2c-2.05 0-3.664.61-4.84 1.832C.588 5.053 0 6.665 0 8.667v6.666c0 2.12.563 3.758 1.69 4.911C2.831 21.414 4.47 22 6.604 22h10.79c2.202 0 3.849-.577 4.941-1.73 1.11-1.154 1.664-2.8 1.664-4.937V8.667c0-2.104-.58-3.74-1.74-4.911C21.1 2.586 19.48 2 17.395 2H6.605ZM6 3.832h12c1.378 0 2.42.365 3.126 1.094.723.73 1.084 1.773 1.084 3.13v7.888c0 1.391-.361 2.451-1.084 3.18-.706.713-1.748 1.07-3.126 1.07H6c-1.361 0-2.403-.365-3.126-1.095-.723-.73-1.084-1.78-1.084-3.155V7.98c0-1.324.361-2.341 1.084-3.054.74-.73 1.781-1.094 3.126-1.094Z"
      fill="currentColor"
    />
    <circle cx={19.5} cy={10} r={1} fill="currentColor" />
    <circle cx={14} cy={12} r={1} fill="currentColor" />
    <circle cx={16} cy={9.5} r={1} fill="currentColor" />
    <circle cx={8} cy={14.5} r={1} fill="currentColor" />
    <circle cx={13} cy={11} r={1} fill="currentColor" />
    <circle cx={13} cy={7} r={1} fill="currentColor" />
    <circle cx={12} cy={14} r={1} fill="currentColor" />
    <circle cx={10} cy={11} r={1} fill="currentColor" />
    <circle cx={9} cy={10} r={1} fill="currentColor" />
    <circle cx={5.5} cy={9} r={1} fill="currentColor" />
    <circle cx={8} cy={12} r={1} fill="currentColor" />
    <circle cx={10} cy={13} r={1} fill="currentColor" />
    <circle cx={11} cy={15.5} r={1} fill="currentColor" />
    <circle cx={18} cy={17} r={1} fill="currentColor" />
    <circle cx={14} cy={14} r={1} fill="currentColor" />
    <circle cx={15} cy={13} r={1} fill="currentColor" />
    <circle cx={17} cy={11.5} r={1} fill="currentColor" />
    <circle cx={11.5} cy={12} r={1} fill="currentColor" />
  </svg>
);

export default SvgPopExIcon;
