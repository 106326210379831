import clsx from 'clsx';
import { ReactNode, useMemo, useState } from 'react';
import { ItemHoverActions } from '../../atoms/ItemHoverActions';
import { ThreeDotsMenu } from '../../ThreeDotsMenu';

export type DynamicTableRowIcon<Item> = (item: Item) => ReactNode;

export type MenuAction<Item> = {
  title: string;
  icon?: ReactNode;
  onSelect: (item: Item) => void;
  filter?: (item: Item) => boolean;
};

export type HoverAction<Item> = {
  title: ReactNode | ((item: Item) => ReactNode);
  onSelect: (item: Item) => void;
  filter?: (item: Item) => boolean;
} & (
  | {
      icon: ReactNode;
    }
  | {
      iconFunction: DynamicTableRowIcon<Item>;
    }
);

export type RowActions<Item> = {
  customActions?: (item: Item) => ReactNode;
  menuActions?: MenuAction<Item>[];
  hoverActions?: HoverAction<Item>[];
  actionPosition?: 'start' | 'end';
  hoverActionsSnapToClass?: string;
};

export type TableRowActionsProps<Item> = RowActions<Item> & {
  className?: string;
  item: Item;
};

export function TableRowActions<Item>({
  className,
  menuActions = [],
  hoverActions = [],
  customActions,
  item,
  actionPosition = 'end',
  hoverActionsSnapToClass,
}: TableRowActionsProps<Item>): JSX.Element | null {
  const menuOptions = useMemo(
    () =>
      menuActions
        .filter(({ filter }) => !filter || filter(item))
        .map(({ onSelect, filter: _filter, ...rest }) => ({
          ...rest,
          onClick: () => onSelect(item),
        })),
    [menuActions, item]
  );

  const hoverOptions = useMemo(
    () =>
      hoverActions
        .filter(({ filter }) => !filter || filter(item))
        .map((action) => {
          const { title, onSelect } = action;
          const displayIcon =
            'icon' in action ? action.icon : action.iconFunction(item);
          return {
            icon: displayIcon,
            label: typeof title === 'function' ? title(item) : title,
            onClick: () => onSelect(item),
          };
        }),
    [hoverActions, item]
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const snapToClass = hoverActionsSnapToClass
    ? hoverActionsSnapToClass
    : actionPosition === 'end'
    ? 'right-[100%]'
    : 'left-[100%]';

  return (
    <div className="relative h-full flex items-center">
      {!!menuOptions?.length && (
        <ThreeDotsMenu
          buttonClass={clsx(
            !isMenuOpen && 'invisible',
            'group-hover:visible',
            className
          )}
          options={menuOptions}
          onMenuOpen={setIsMenuOpen}
          onMenuClose={setIsMenuOpen}
        />
      )}
      {customActions && customActions(item)}
      {!!hoverOptions.length && (
        <ItemHoverActions
          snapToClass={snapToClass}
          className="flex items-center"
          actions={hoverOptions}
        />
      )}
    </div>
  );
}
