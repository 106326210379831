import { Connection } from './Connection';

export const NODE_REPOSITION_DRAG = 'NODE_REPOSITION';

export interface NodeRepositionDragData {
  nodeId: string;
  zoom: number;
}

export interface ConnectionDragData {
  outputNodeId: string;
  outputName: string;
  existingConnection?: Connection;
}

export interface ConnectionDropResult {
  outputNodeId: string;
  outputName: string;
  inputNodeId: string;
  inputName: string;
  isDynamicInput: boolean;
  existingConnections?: Connection[];
}

export interface ConnectionWithType {
  connection: Connection;
  type: string;
}
