import { useMemo, useState } from 'react';
import { Setter } from '../../../core/types';

export type UseOpenState = {
  isOpen: boolean;
  setIsOpen: Setter<boolean>;
  close: () => void;
  open: () => void;
  toggle: () => void;
};
export function useOpenState(defaultOpen: boolean): UseOpenState {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const openState = useMemo<UseOpenState>(
    () => ({
      isOpen,
      setIsOpen,
      close: () => setIsOpen(false),
      open: () => setIsOpen(true),
      toggle: () => setIsOpen((v) => !v),
    }),
    [isOpen, setIsOpen]
  );
  return openState;
}
