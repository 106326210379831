import { PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { Collapse } from '@material-ui/core';
import clsx from 'clsx';

import { useRipple } from '../ui/atoms/utils/useRipple';
import { Alert, Down, UnknownIcon, Up, ViDoneIcon } from '../ui/icons';
import { Tooltip } from '../ui/mui';
import { ValidateAssetsResultEnum } from '../network-editor/interfaces/ValidateGraphStatus';

type NodeDetailsPanelProps = {
  icon?: ReactNode;
  error?: string;
  openByDefault?: boolean;
  title: React.ReactChild;
  className?: string;
  headerClassName?: string;
  enableTooltip?: boolean;
  validateAssetsResult?: ValidateAssetsResultEnum;
  validateAssetsError?: string;
};

export function NodeDetailsPanel({
  title: header,
  children,
  className,
  headerClassName,
  error,
  icon,
  openByDefault = false,
  enableTooltip = false,
  validateAssetsResult,
  validateAssetsError,
}: PropsWithChildren<NodeDetailsPanelProps>) {
  const ripple = useRipple();
  const [open, setOpen] = useState(openByDefault);
  const handleItemClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const errorMsg = validateAssetsError || error;

  return (
    <Tooltip title={enableTooltip ? header : ''}>
      <div className="w-full flex flex-col border-b border-solid border-white/20">
        <div
          onClick={handleItemClick}
          onMouseUp={ripple}
          className={clsx(
            'cursor-pointer flex space-x-3 items-center p-4 hover:bg-white/10',
            headerClassName
          )}
        >
          {icon}
          <div className="flex-1 font-bold text-sm tracking-normal overflow-hidden text-ellipsis">
            {header}
          </div>

          {(validateAssetsResult || errorMsg) &&
            AssetResult(validateAssetsResult, errorMsg)}

          {open ? <Up /> : <Down />}
        </div>
        <Collapse className="w-full" in={open} timeout="auto" unmountOnExit>
          <div className={clsx('flex flex-col w-full', className)}>
            {children}
          </div>
        </Collapse>
      </div>
    </Tooltip>
  );
}

export function Empty({ title = 'No Data' }) {
  return <h4 className="text-center p-4 text-gray-400 "> {title} </h4>;
}

export function NoPreview() {
  return <Empty title=" No Preview" />;
}

function AssetResult(
  validateAssetsResult?: ValidateAssetsResultEnum,
  errorMsg?: string
): JSX.Element {
  if (errorMsg || validateAssetsResult === ValidateAssetsResultEnum.Failed) {
    return (
      <Tooltip placement="top" title={errorMsg || ''}>
        <div>
          <Alert className="w-5 h-5 text-error-500" />
        </div>
      </Tooltip>
    );
  }

  if (validateAssetsResult === ValidateAssetsResultEnum.Passed) {
    return <ViDoneIcon className="w-5 h-5 text-success-500" />;
  }
  return (
    <Tooltip placement="top" title="Not tested">
      <div>
        <UnknownIcon className="w-5 h-5 text-gray-600" />
      </div>
    </Tooltip>
  );
}
