import { AllSessionsTestResults } from '@tensorleap/api-client';
import { ModelTestsCardContentTestResultsRow } from './ModelTestsCardContentTestResultsRow';

export interface ModelTestsCardContentTestResultsProps {
  modelTestsResults: AllSessionsTestResults | undefined;
}

export function ModelTestsCardContentTestResults({
  modelTestsResults,
}: ModelTestsCardContentTestResultsProps): JSX.Element {
  return (
    <div className="w-full px-4">
      <div className="flex flex-col w-full justify-center items-start my-2">
        <span className="capitalize text-xl tracking-widest text-gray-500">
          test results
        </span>
        <div className="h-fit w-full grid grid-cols-10">
          <div className="col-span-6"></div>
          {!!modelTestsResults && (
            <>
              <span className="col-span-4 capitalize inline-flex justify-start items-center">
                avg
              </span>
              {modelTestsResults.sessionsResults.map(
                (modelResult, index, origin) => (
                  <ModelTestsCardContentTestResultsRow
                    key={index}
                    showDivider={index !== origin.length - 1}
                    sessionResult={modelResult}
                  />
                )
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
