import { ReactKeycloakProvider } from '@react-keycloak/web';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import api from '../core/api-client';
import useAsyncEffect from '../core/useAsyncEffect';
import { useMergedObject } from '../core/useMergedObject';
import { createKeycloak } from '../keycloak';
import { AuthProviderEnum } from '@tensorleap/api-client';

export type TokenData = {
  email: string;
  lastName: string;
  userName: string;
};

export type AuthProviderValue = {
  authenticated: boolean;
  tokenParsed?: TokenData;
  token: string;
  logout: () => Promise<void>;
};

const AuthProviderContext = React.createContext<AuthProviderValue>({
  authenticated: false,
  tokenParsed: undefined,
  token: '',
  logout: async () => {},
});

export function useAuthProvider(): AuthProviderValue {
  return useContext(AuthProviderContext);
}

function KeycloakProvider({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const [_, setRefresh] = useState(0);

  const keycloak = useMemo(() => {
    const k = createKeycloak();
    getToken = () => k.token;

    return k;
  }, []);

  useEffect(() => {
    if (keycloak.token) {
      return;
    }
    const interval = setInterval(() => {
      // the keycloak.onReady event and others is not firing for some reason
      if (keycloak.token) {
        setRefresh((r) => r + 1);
        clearInterval(interval);
      }
    }, 100);
  }, [keycloak]);

  const mappedTokenData: TokenData | undefined = useMemo(() => {
    if (!keycloak?.idTokenParsed) {
      return undefined;
    }
    const {
      email,
      family_name: lastName,
      preferred_username: userName,
    } = keycloak.idTokenParsed;
    return { email, lastName, userName };
  }, [keycloak?.idTokenParsed]);

  const value = useMergedObject({
    authenticated: keycloak?.authenticated ?? false,
    tokenParsed: mappedTokenData,
    token: keycloak?.idToken ?? '',
    logout: keycloak?.logout ?? (() => {}),
  });

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: 'login-required' }}
    >
      <AuthProviderContext.Provider value={value}>
        {children}
      </AuthProviderContext.Provider>
    </ReactKeycloakProvider>
  );
}

function LocalProvider({ children }: { children: React.ReactNode }) {
  const [data, setData] = useState<Omit<AuthProviderValue, 'logout'>>({
    authenticated: false,
    tokenParsed: undefined,
    token: '',
  });

  const logout = useCallback(async () => {
    // TODO: Implement logout
  }, []);

  useAsyncEffect(async () => {
    try {
      const { token, user } = await api.localAuth({
        email: 'demo@demo.ai',
        password: '',
      });
      if (!token || !user) {
        console.warn('No token found');
        return;
      }
      const tokenParsed = {
        email: user.local.email,
        lastName: '',
        userName: '',
      };

      getToken = () => token;
      setData({ authenticated: true, token, tokenParsed });
    } catch (e) {
      console.error('Error checking auth', e);
    }
  }, []);

  const value = useMergedObject({
    ...data,
    logout,
  });

  return (
    <AuthProviderContext.Provider value={value}>
      {children}
    </AuthProviderContext.Provider>
  );
}

export let getToken = (): string | undefined => undefined;

export function AuthProviderLoader({
  children,
}: PropsWithChildren<unknown>): React.ReactElement {
  const [authProvider, setAuthProvider] = useState<
    AuthProviderEnum | undefined
  >(undefined);

  useAsyncEffect(async () => {
    try {
      const { authProvider } = await api.getAuthProvider();
      setAuthProvider(authProvider);
    } catch (e) {
      console.error('Error loading auth provider', e);
    }
  }, []);

  if (!authProvider) {
    return <>Loading auth...</>;
  } else if (authProvider === AuthProviderEnum.Local) {
    return <LocalProvider>{children}</LocalProvider>;
  } else {
    return <KeycloakProvider>{children}</KeycloakProvider>;
  }
}
