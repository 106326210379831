import { SingleIssueDrawerContent } from './SingleIssueDrawerContent';
import { AllIssuesDrawerContent } from './AllIssuesDrawerContent';
import { ClosePanel, OpenPanel, PlusMini } from '../ui/icons';
import { IconButton, Tooltip } from '../ui/mui';
import { UseIssuesState } from './useIssuesState';
import { Title } from '../ui/atoms/Title';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';

export type IssueDrawerContentProps = UseIssuesState;

export function IssueDrawerContent({
  issues,
  selectedIssue,
  setSelectedIssue,
  createNewIssue,
  updateIssue,
  deleteSelectedIssue,
  isExpanded,
  setIsExpanded,
  isEditable,
  setIsEditable,
}: IssueDrawerContentProps): JSX.Element {
  return (
    <div className="flex flex-col bg-transparent w-full h-full">
      <div className="flex flex-row w-full text-2xl pb-2 pl-6 pr-3 border-b border-gray-800">
        <Title bottomBorderClassName="border-b-primary-500">issues</Title>
        <IconButton
          className="w-fit h-fit ml-auto"
          onClick={() => setIsExpanded((isExp) => !isExp)}
        >
          {isExpanded ? <OpenPanel /> : <ClosePanel />}
        </IconButton>
        {(isExpanded || !selectedIssue) && (
          <Tooltip title="Create new issue">
            <IconButton
              className="w-fit h-fit "
              onClick={createNewIssue}
              id={TOUR_SELECTORS_ENUM.CREATE_NEW_ISSUE_BUTTON_ID}
            >
              <PlusMini className="text-white" />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {isExpanded ? (
        <div className="flex flex-row w-full h-full">
          <div className="flex w-160 h-full overflow-auto scrollbar-hide pt-3 border-r border-gray-800">
            <AllIssuesDrawerContent
              issues={issues}
              selectedIssue={selectedIssue}
              setSelectedIssue={setSelectedIssue}
              updateIssue={updateIssue}
              isExpanded={isExpanded}
            />
          </div>
          <div className="flex w-280 h-full overflow-auto scrollbar-hide">
            {selectedIssue && (
              <SingleIssueDrawerContent
                selectedIssue={selectedIssue}
                setSelectedIssue={setSelectedIssue}
                isExpanded={isExpanded}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
                updateIssue={updateIssue}
                deleteSelectedIssue={deleteSelectedIssue}
              />
            )}
          </div>
        </div>
      ) : selectedIssue ? (
        <SingleIssueDrawerContent
          selectedIssue={selectedIssue}
          setSelectedIssue={setSelectedIssue}
          isExpanded={isExpanded}
          isEditable={isEditable}
          setIsEditable={setIsEditable}
          updateIssue={updateIssue}
          deleteSelectedIssue={deleteSelectedIssue}
        />
      ) : (
        <AllIssuesDrawerContent
          issues={issues}
          selectedIssue={selectedIssue}
          setSelectedIssue={setSelectedIssue}
          updateIssue={updateIssue}
          isExpanded={isExpanded}
        />
      )}
    </div>
  );
}
