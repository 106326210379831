import { PropsWithChildren } from 'react';
import { ThreeDotsIcon } from '../icons';
import { IconMenu } from './IconMenu';

export function MoreMenu({ children }: PropsWithChildren<unknown>) {
  return (
    <IconMenu
      icon={<ThreeDotsIcon className="h-8 w-8" />}
      paperClassName="mt-12 ml-9 min-w-[15rem]"
      closeOnClick
    >
      {children}
    </IconMenu>
  );
}
