import {
  AnalyticsDashletData,
  AggregationMethod,
  AnalyticsDashletType,
  OrderType,
  SplitAgg,
} from '@tensorleap/api-client';
import { UseFormReturn } from 'react-hook-form';
import { ConfusionMatrixParams } from './form/utils';
import { AreaParams } from './form/Area';
import { BarParams } from './form/Bars';
import { DonutParams } from './form/Donut';
import { HeatmapParams } from './form/Heatmap';
import { LineParams } from './form/LineGraph';
import { TableParams } from './form/Table';
import { XYChartParams } from './form/XYChart';
import { DashletFields } from '../../../core/data-fetching/dashlet-fields';

export const ALPHBETICAL = 'Alphabetical';
export interface DashletFormProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any> = Record<string, any>
> {
  fields: DashletFields;
  metricNames: string[];
  defaultNumericField?: string;
  defaultAggregatableField?: string;
  form: UseFormReturn<AnalyticsDashletData<T>, object>;
  graphType: AnalyticsDashletType;
}

export type UnifyGraphParams =
  | BarParams
  | HeatmapParams
  | AreaParams
  | LineParams
  | DonutParams
  | TableParams
  | XYChartParams
  | ConfusionMatrixParams;

export type GraphAxisParams = {
  yAxis: string;
  xAxis: string;
};

export type MetaDataTableEntity = {
  field: string;
  continuous: boolean;
  size: number;
};

export type MetricTableEntity = {
  field: string;
  aggregation: AggregationMethod;
};

export enum BarsLineAreaGraphsType {
  Bars = 'histogram',
  Line = 'line',
  Area = 'area',
}

export type DataDistributionType = SplitAgg['distribution'];

export interface ChartSplit {
  field: string;
  distribution?: DataDistributionType;
  interval?: number;
  order?: OrderType;
  orderField?: string;
  limit?: number;
}
