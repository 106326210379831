import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgArchiveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.45 4.55a.65.65 0 1 0 0 1.3h9.1a.65.65 0 1 0 0-1.3h-9.1ZM3.8 7.038a1.95 1.95 0 0 1 .65-3.788h9.1a1.95 1.95 0 0 1 .65 3.788V13a1.95 1.95 0 0 1-1.95 1.95h-6.5A1.95 1.95 0 0 1 3.8 13V7.038Zm9.1.112H5.1V13a.65.65 0 0 0 .65.65h6.5a.65.65 0 0 0 .65-.65V7.15ZM7.05 9.1a.65.65 0 0 1 .65-.65h2.6a.65.65 0 1 1 0 1.3H7.7a.65.65 0 0 1-.65-.65Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgArchiveIcon;
