import { Sorter } from '../sorter';
import { Selector } from '../selectors';
import { BaseItem, ModelFields } from '../types';
import { TableHeader } from './TableHeader';
import { ExpendRowComp, TableRow } from './TableRow';
import { useTable } from './useTable';
import { HoverAction, MenuAction, RowActions } from './TableRowActions';
import clsx from 'clsx';
import { ReactNode, useCallback } from 'react';
import { TableColumn } from './table-types';

export type TableExpanderPosition = 'left' | 'hoverAction' | 'menuAction';

type TableProps<Item extends BaseItem> = {
  sorter?: Sorter<Item>;
  fields: ModelFields<Item>;
  inline?: boolean;
  tableBgClass?: string;
  headersBgClass?: string;
  rowBorderBottomClass?: string;
  className?: string;
  fixedLayout?: boolean;
  data?: Item[];
  isSelected?: Selector<Item>['isSelected'];
  expander?: Selector<Item>;
  expanderPosition?: TableExpanderPosition;
  ExpendRowComp?: ExpendRowComp<Item>;
  onRowClick?: (item: Item) => void;
  subHeader?: ReactNode;
  noData?: ReactNode;
  tourId?: string;
  bgRowClass?:
    | string
    | ((item: Item, index: number, selected?: boolean) => string);
} & RowActions<Item>;

const ICON_COLUMN_SIZE = 'w-[32px]';
const NO_DATA = 'NO DATA FOUND';

export function Table<Item extends BaseItem>({
  data,
  fields,
  className,
  inline,
  tableBgClass = 'bg-gray-850',
  headersBgClass = 'bg-gray-800',
  rowBorderBottomClass,
  isSelected,
  expander,
  expanderPosition = 'left',
  onRowClick,
  menuActions,
  hoverActions,
  actionPosition = 'end',
  hoverActionsSnapToClass,
  customActions,
  sorter,
  ExpendRowComp,
  bgRowClass,
  fixedLayout,
  noData = NO_DATA,
  subHeader,
  tourId,
}: TableProps<Item>): JSX.Element {
  const { columns, getRowId } = useTable(fields, sorter);
  const isExpandable = expander && ExpendRowComp;
  const hasData = !!data?.length;
  const hasActions = !!menuActions || !!hoverActions || !!customActions;
  const columnsLength = calcNumColumns({
    columns,
    isExpandable,
    expanderPosition,
    menuActions,
    hoverActions,
  });
  const getBgClass = useCallback(
    (item: Item, index: number, selected?: boolean) =>
      typeof bgRowClass === 'function'
        ? bgRowClass(item, index, selected)
        : bgRowClass,
    [bgRowClass]
  );

  const thAction = hasActions ? (
    <th className={clsx((customActions || menuActions) && ICON_COLUMN_SIZE)} />
  ) : null;

  return (
    <div
      className={clsx(
        'relative max-h-full overflow-auto',
        !inline && 'rounded-xl border-2 border-gray-800',
        className
      )}
      id={tourId}
    >
      <table
        className={clsx(
          fixedLayout && 'table-fixed',
          tableBgClass,
          'w-full h-full border-collapse'
        )}
      >
        <thead className={clsx(headersBgClass, 'z-[1] top-0 sticky')}>
          <tr className="h-12 uppercase text-gray-300">
            {isExpandable && expanderPosition === 'left' && (
              <th className={ICON_COLUMN_SIZE} />
            )}
            {actionPosition === 'start' && thAction}
            {columns.map(
              ({ id, headerStyle, align, label, sort, headerClassName }) => (
                <TableHeader
                  className={headerClassName}
                  key={id}
                  align={align}
                  sort={sort}
                  style={headerStyle}
                >
                  {label}
                </TableHeader>
              )
            )}
            {actionPosition === 'end' && thAction}
          </tr>
          {subHeader && (
            <tr>
              <th className="p-0" colSpan={columnsLength}>
                {subHeader}
              </th>
            </tr>
          )}
        </thead>
        <tbody className="text-gray-300">
          {hasData ? (
            <>
              {data.map((item, itemIndex) => (
                <TableRow
                  key={getRowId(item, itemIndex)}
                  onClick={onRowClick}
                  onToggleExtend={expander?.toggle}
                  expanded={expander?.isSelected(item)}
                  selected={isSelected?.(item)}
                  ExpendComp={ExpendRowComp}
                  hideBottomBorder={itemIndex === data.length - 1 && !inline}
                  borderBottomClass={rowBorderBottomClass}
                  bgClass={getBgClass(item, itemIndex, isSelected?.(item))}
                  {...{
                    item,
                    columns,
                    menuActions,
                    hoverActions,
                    customActions,
                    expanderPosition,
                    hoverActionsSnapToClass,
                    actionPosition,
                  }}
                />
              ))}
              {/* empty row to catch the empty space */}
              <tr>
                <td className="p-0" colSpan={columnsLength}></td>
              </tr>
            </>
          ) : (
            <tr>
              <td
                colSpan={columnsLength}
                className="text-center h-12 py-6 text-gray-500"
              >
                {noData}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export interface calcNumColumnsProps<Item extends BaseItem> {
  columns: TableColumn<Item>[];
  isExpandable: ExpendRowComp<Item> | boolean | undefined;
  expanderPosition: TableExpanderPosition | undefined;
  menuActions?: MenuAction<Item>[] | undefined;
  hoverActions?: HoverAction<Item>[] | undefined;
}
export function calcNumColumns<Item extends BaseItem>({
  columns,
  isExpandable,
  expanderPosition,
  menuActions,
  hoverActions,
}: calcNumColumnsProps<Item>): number {
  return (
    columns.length +
    (isExpandable && expanderPosition === 'left' ? 1 : 0) +
    (!!menuActions || !!hoverActions ? 1 : 0)
  );
}
