export const COLORFULL_PALATE = [
  '#ea5545',
  '#87bc45',
  '#f46a9b',
  '#edbf33',
  '#9b19f5',
  '#27aeef',
  '#e6d800',
  '#e60049',
  '#50e991',
  '#ffa300',
  '#dc0ab4',
  '#00bfa0',
  '#b30000',
  '#4421af',
  '#5ad45a',
  '#ede15b',
  '#b3d4ff',
  '#7c1158',
  '#00b7c7',
  '#bdcf32',
  '#0d88e6',
  '#b33dc6',
  '#8be04e',
  '#ebdc78',
  '#fd7f6f',
  '#7eb0d5',
  '#b2e061',
  '#bd7ebe',
  '#ffb55a',
  '#1a53ff',
  '#ffee65',
  '#beb9db',
  '#fdcce5',
  '#8bd3c7',
  '#ef9b20',
  '#0bb4ff',
];

type ColorAsRGB = [r: number, g: number, b: number];

function colorToRgbArray(color: string): ColorAsRGB {
  const rgb = color.substring(1);
  return [
    parseInt(rgb.substring(0, 2), 16),
    parseInt(rgb.substring(2, 4), 16),
    parseInt(rgb.substring(4, 6), 16),
  ];
}

function rgbColorToString([r, g, b]: ColorAsRGB): string {
  return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
}

type ColorSupplier = {
  get(key: string): string;
  getWithOpacity(key: string, opacity: number): string;
  getAsRGB(key: string): ColorAsRGB;
  clear(): void;
};

type buildFromRgbColors = {
  rgbColors: ColorAsRGB[];
};
type buildFromStringColors = {
  colors: string[];
};
type BuildColorSupplierPropsa = buildFromRgbColors | buildFromStringColors;

export function buildColorSupplier(
  props: BuildColorSupplierPropsa
): ColorSupplier {
  const rgbColors =
    (props as buildFromRgbColors).rgbColors ||
    (props as buildFromStringColors).colors.map(colorToRgbArray);
  const colors =
    (props as buildFromStringColors).colors || rgbColors.map(rgbColorToString);

  const colorsLength = rgbColors.length;
  const colorByKey = new Map<string, number>();

  function getColorIndex(key: string): number {
    if (colorByKey.has(key)) return colorByKey.get(key) as number;

    const colorIndex = colorByKey.size % colorsLength;
    colorByKey.set(key, colorIndex);
    return colorIndex;
  }

  return {
    get(key: string) {
      return colors[getColorIndex(key)];
    },
    getAsRGB(key: string) {
      return rgbColors[getColorIndex(key)];
    },
    getWithOpacity(key: string, opacity: number) {
      const rgb = rgbColors[getColorIndex(key)];
      return `rgba(${rgb}, ${opacity})`;
    },
    clear() {
      colorByKey.clear();
    },
  };
}

export const labelsColorSupplier = buildColorSupplier({
  colors: COLORFULL_PALATE,
});
