import { useState, useCallback } from 'react';
import { useMergedObject } from './useMergedObject';

export type MousePosition = { x: number; y: number };
const INITIAL_MOUSE_POSITION = { x: 0, y: 0 };

export interface UseSelectionGroup {
  handleMouseDown: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleMouseMove: (event: React.MouseEvent<HTMLDivElement>) => void;
  startMousePosition: MousePosition;
  endMousePosition: MousePosition;
  isDrawing: boolean;
}

export function useSelectionGroup(): UseSelectionGroup {
  const [isDrawing, setIsDrawing] = useState(false);
  const [startMousePosition, setStartMousePosition] = useState<MousePosition>(
    INITIAL_MOUSE_POSITION
  );
  const [endMousePosition, setEndMousePosition] = useState<MousePosition>(
    INITIAL_MOUSE_POSITION
  );

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const mousePosition = getMousePosition(event);
      setIsDrawing(true);

      setStartMousePosition(mousePosition);
      setEndMousePosition(mousePosition);

      const onMouseUp = () => {
        setIsDrawing(false);
        window.removeEventListener('mouseup', onMouseUp);
      };
      window.addEventListener('mouseup', onMouseUp);
    },
    []
  );

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (isDrawing) {
        const endMousePosition = getMousePosition(event);
        setEndMousePosition(endMousePosition);
      }
    },
    [isDrawing]
  );

  return useMergedObject({
    startMousePosition,
    endMousePosition,
    isDrawing,
    handleMouseDown,
    handleMouseMove,
  });
}

export function getMousePosition(
  event: React.MouseEvent<HTMLDivElement, MouseEvent>
): MousePosition {
  const rect = event.currentTarget.getBoundingClientRect();
  const x = event.clientX - rect.left;
  const y = event.clientY - rect.top;
  return { x, y };
}

export function isPointInsideRect(
  point: MousePosition,
  { x: sx, y: sy }: MousePosition,
  { x: ex, y: ey }: MousePosition
): boolean {
  return (
    point.x >= Math.min(sx, ex) &&
    point.x <= Math.max(sx, ex) &&
    point.y >= Math.min(sy, ey) &&
    point.y <= Math.max(sy, ey)
  );
}

type SelectionBoxProps = {
  start: MousePosition;
  end: MousePosition;
};

export const SelectionBox: React.FC<SelectionBoxProps> = ({ start, end }) => {
  // Calculate the style properties based on mouse positions
  const style = {
    position: 'absolute' as const,
    top: Math.min(start.y, end.y),
    left: Math.min(start.x, end.x),
    width: Math.abs(end.x - start.x),
    height: Math.abs(end.y - start.y),
  };

  // Render the component
  return (
    <div
      style={style}
      className="border border-dashed border-colorful2-500 bg-colorful2-500/10"
    />
  );
};
