import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgChatboxOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.842 3.094h8.316a2.192 2.192 0 0 1 2.186 2.185v5.254a2.192 2.192 0 0 1-2.186 2.186H8.8l-2.441 2.035a.656.656 0 0 1-1.077-.504v-1.531h-.439a2.192 2.192 0 0 1-2.186-2.186V5.28a2.192 2.192 0 0 1 2.186-2.185Zm4.079 9.523Zm-4.076-8.21a.88.88 0 0 0-.876.875v5.248a.88.88 0 0 0 .876.876h1.093c.362 0 .656.294.656.656v.786l1.485-1.238a.875.875 0 0 1 .561-.204h4.515a.88.88 0 0 0 .876-.876V5.282a.88.88 0 0 0-.876-.876h-8.31Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgChatboxOutline;
