import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  FC,
} from 'react';
import {
  ConfirmDialog,
  ConfirmDialogProps,
} from '../ui/atoms/DeleteContentDialog';
import { OptionType, OptionValueFromOption } from '../ui/atoms/utils/select';
import { SelectDialog, SelectDialogProps } from '../ui/atoms/SelectDialog';

type DialogContentInterface = {
  confirm: (props: ConfirmDialogOptions) => Promise<boolean>;
  select: <Option extends OptionType>(
    props: SelectDialogOptions<Option>
  ) => Promise<OptionValueFromOption<Option> | null>;
};

const DialogContext = createContext<DialogContentInterface>({
  confirm: () => Promise.resolve(false),
  select: () => Promise.resolve(null),
});

type ConfirmDialogOptions = Omit<
  ConfirmDialogProps,
  'isOpen' | 'onClose' | 'onConfirm'
>;
type SelectDialogOptions<Option extends OptionType> = Omit<
  SelectDialogProps<Option>,
  'isOpen' | 'onClose'
>;

type Dialog =
  | {
      type: 'CONFIRM';
      props: ConfirmDialogOptions;
      resolve: (result: unknown) => void;
    }
  | {
      type: 'SELECT';
      props: SelectDialogOptions<OptionType>;
      resolve: (result: unknown) => void;
    };

export const DialogProvider: FC = ({ children }) => {
  const [dialogs, setDialogs] = useState<Dialog[]>([]);

  // Open a dialog of any type (confirm or select) and return a promise
  const openDialog = useCallback((dialog: Omit<Dialog, 'resolve'>) => {
    return new Promise((resolve) => {
      setDialogs((prevDialogs) => [
        ...prevDialogs,
        { ...dialog, resolve } as Dialog,
      ]);
    });
  }, []);

  // Close a dialog and resolve its promise
  const closeDialog = useCallback(
    (index: number, result: unknown) => {
      const dialog = dialogs[index];
      if (dialog && dialog.resolve) {
        dialog.resolve(result);
      }
      setDialogs(dialogs.filter((_, i) => i !== index));
    },
    [dialogs]
  );

  // API for opening specific dialogs
  const confirm = useCallback(
    (props: ConfirmDialogOptions) =>
      openDialog({
        type: 'CONFIRM',

        props: {
          confirmButtonColor: 'blue',
          confirmButtonText: 'Confirm',
          confirmButtonIcon: <React.Fragment />,
          ...props,
        },
      }) as Promise<boolean>,
    [openDialog]
  );
  const select = useCallback(
    <Option extends OptionType>(props: SelectDialogOptions<Option>) =>
      openDialog({
        type: 'SELECT',
        props,
      }) as Promise<OptionValueFromOption<Option> | null>,
    [openDialog]
  );

  const renderDialogs = () =>
    dialogs.map((dialog, index) => {
      if (dialog.type === 'CONFIRM') {
        return (
          <ConfirmDialog
            key={index}
            isOpen={true}
            onClose={() => closeDialog(index, false)}
            onConfirm={() => closeDialog(index, true)}
            {...dialog.props}
          />
        );
      }
      if (dialog.type === 'SELECT') {
        return (
          <SelectDialog
            key={index}
            isOpen={true}
            onClose={(result) => closeDialog(index, result)}
            {...dialog.props}
          />
        );
      }
      return null;
    });

  return (
    <DialogContext.Provider value={{ confirm, select }}>
      {children}
      {renderDialogs()}
    </DialogContext.Provider>
  );
};

DialogProvider.displayName = 'DialogProvider';

export const useDialog = () => {
  return useContext(DialogContext);
};
