import { Tab } from '@material-ui/core';
import { Tabs } from '@material-ui/core';
import { OptionDefaultObj } from '../../ui/atoms/utils/select';
import { Lightning2Icon, NextIcon, Vi1, WeightsCopyIcon } from '../../ui/icons';

export type RunModelMethod = 'Evaluate' | 'Train' | 'Retrain' | 'Continue';

type TrainTab = OptionDefaultObj<RunModelMethod> & { icon: JSX.Element };

export const TRAIN_OPTIONS: TrainTab[] = [
  {
    icon: <Vi1 />,
    value: 'Evaluate',
    label: 'Evaluate',
  },
  {
    icon: <Lightning2Icon />,
    value: 'Train',
    label: 'Train from Scratch',
  },
  {
    icon: <WeightsCopyIcon />,
    value: 'Retrain',
    label: 'Copy Initial Weights',
  },
  {
    icon: <NextIcon />,
    value: 'Continue',
    label: 'Continue Training',
  },
];

type RunModelTabsProps = {
  onChange: (changedValue: RunModelMethod) => void;
  value: RunModelMethod;
};

export function RunModelTabs({ value, onChange }: RunModelTabsProps) {
  return (
    <Tabs
      className="mt-4 mx-6"
      TabIndicatorProps={{
        className: 'top-1',
      }}
      onChange={(_, value) => onChange(value)}
      value={value}
    >
      {TRAIN_OPTIONS.map(({ icon, label, value }) => (
        <Tab
          classes={{
            wrapper: 'flex flex-row gap-2 h-8',
          }}
          key={value}
          icon={icon}
          label={label}
          value={value}
          className="py-0"
        />
      ))}
    </Tabs>
  );
}
