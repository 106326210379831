import { useRef } from 'react';
import { IconButton, Paper } from '../../ui/mui';
import { XClose } from '../../ui/icons';
import { AnalyzeSample, AnalyzeSampleInputs } from './panes';

export interface AddAnalysisPaneProps {
  defaultValues?: Partial<AnalyzeSampleInputs>;
  closeTooltip: () => void;
}
export function AddAnalysisPane({
  defaultValues,
  closeTooltip,
}: AddAnalysisPaneProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Paper
      variant="outlined"
      className="w-[370px] h-fit p-4 overflow-y-auto"
      ref={inputRef}
    >
      <div className="flex flex-col gap-2 justify-evenly items-stretch h-full">
        <div className="flex flex-row justify-between align-center items-center w-full">
          <span className="font-medium text-sm leading-relaxed uppercase">
            ANALYZE SAMPLE
          </span>
          <IconButton onClick={closeTooltip}>
            <XClose />
          </IconButton>
        </div>

        <AnalyzeSample
          defaultValues={defaultValues}
          closeTooltip={closeTooltip}
        />
      </div>
    </Paper>
  );
}
