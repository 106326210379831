import {
  Aggregations,
  GenericDataQueryParams,
  OrderType,
  SplitAgg,
} from '@tensorleap/api-client';
import { useMemo } from 'react';
import { XYChartVizProps } from './interfaces';
import { MultiCharts } from '../../../../ui/charts/visualizers/MultiCharts';
import {
  chartSplitToSplitAgg,
  getSplitLabels,
  toIntervalOrLimit,
} from './utils';
import { useGetChart } from '../../../../core/data-fetching/getChart';
import api from '../../../../core/api-client';
import { useMergedObject } from '../../../../core/useMergedObject';
import { ChartRequestData } from '../../../../ui/charts/common/interfaces';

export function TableViz({
  graphParams,
  filters,
  onFiltersChange,
  sessionRunIds,
  chartType,
  projectId,
}: XYChartVizProps): JSX.Element {
  const params = useMemo<GenericDataQueryParams>(() => {
    const buckets = graphParams?.metaData?.map(
      (bucket): SplitAgg =>
        chartSplitToSplitAgg(
          {
            field: bucket.field,
            distribution: bucket.continuous ? 'continuous' : 'distinct',
            order: OrderType.Asc,
            ...toIntervalOrLimit(
              bucket.continuous ? 'continuous' : 'distinct',
              bucket.size
            ),
          },
          null
        )
    );
    const aggregations = graphParams?.metrics?.map(
      (metric): Aggregations => ({
        field: metric.field,
        aggregation: metric.aggregation,
      })
    );

    const { verticalSplit, horizontalSplit } = getSplitLabels(
      'inner',
      graphParams.firstSplit,
      graphParams.secondSplit
    );

    return {
      projectId,
      sessionRunIds,
      buckets,
      aggregations,
      filters,
      verticalSplit,
      horizontalSplit,
    };
  }, [
    filters,
    graphParams.firstSplit,
    graphParams?.metaData,
    graphParams?.metrics,
    graphParams.secondSplit,
    projectId,
    sessionRunIds,
  ]);

  const chartRequestData = useMergedObject<ChartRequestData>({
    xField: '',
    yField: '',
  });

  const { multiChartsResponse, isLoading, error } = useGetChart({
    params,
    func: async (x) => await api.getTableChart(x),
  });

  return (
    <MultiCharts
      xyChartsResponse={multiChartsResponse}
      chartRequestData={chartRequestData}
      onFiltersChange={onFiltersChange}
      filters={filters}
      chartType={chartType}
      autoScaleY={false}
      isLoading={isLoading}
      error={error}
      horizontalSplit={params.horizontalSplit ?? null}
      verticalSplit={params.verticalSplit ?? null}
    />
  );
}
