import { MouseEventHandler, useMemo, useRef, useState } from 'react';
import { useRangeLegendData } from '../useRangeLegendData';
import { getRangeDataPointColor } from '../useScatterMapData';
import { VisualizationFilter } from '../../core/types/filters';
import { clamp } from 'lodash';

export interface RangeScatterLegendProps {
  filters: VisualizationFilter[];
}
export function RangeScatterLegend() {
  const {
    filteredMinColorValue,
    filteredMaxColorValue,
    addRangeFilter,
  } = useRangeLegendData();

  const stopColors = useMemo(() => {
    const output: string[] = [];
    const stopStepSize =
      (filteredMaxColorValue - filteredMinColorValue) / 5 || 0.1;
    for (
      let i = filteredMaxColorValue;
      i >= filteredMinColorValue;
      i -= stopStepSize
    ) {
      const stepRGBColor = getRangeDataPointColor(
        i,
        filteredMinColorValue,
        filteredMaxColorValue
      );
      output.push(stepRGBColor);
    }
    return output;
  }, [filteredMaxColorValue, filteredMinColorValue]);

  const maxVisualNumber = useMemo(() => toVisualNumber(filteredMaxColorValue), [
    filteredMaxColorValue,
  ]);

  const minVisualNumber = useMemo(() => toVisualNumber(filteredMinColorValue), [
    filteredMinColorValue,
  ]);

  const [selectedRange, setSelectedRange] = useState<{
    start: number;
    end: number;
  }>();

  const selectedRangeRef = useRef(selectedRange);
  selectedRangeRef.current = selectedRange;

  const [hoverLocation, setHoverLocation] = useState<number>();

  const mapPercentageToValue = (percentage: number): number => {
    percentage = 100 - clamp(percentage, 0, 100);
    const range = filteredMaxColorValue - filteredMinColorValue;
    return filteredMinColorValue + (percentage / 100) * range;
  };

  const handleMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
    if (selectedRange) return;
    const rect = e.currentTarget.getBoundingClientRect();
    const y = e.clientY - rect.top;
    const percentage = (y / rect.height) * 100;
    setHoverLocation(percentage);
  };

  const handleMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    setHoverLocation(undefined);
    const rect = e.currentTarget.getBoundingClientRect();
    const y = e.clientY - rect.top;
    const percentage = (y / rect.height) * 100;

    setSelectedRange({
      start: percentage,
      end: percentage,
    });

    function handleMouseMove(e: MouseEvent) {
      const y = e.clientY - rect.top;
      const percentage = (y / rect.height) * 100;
      setSelectedRange((prev) => {
        if (!prev) return prev;
        return {
          start: prev.start,
          end: percentage,
        };
      });
    }
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener(
      'mouseup',
      () => {
        document.removeEventListener('mousemove', handleMouseMove);
        if (selectedRangeRef.current) {
          let { start, end } = selectedRangeRef.current;
          [start, end] = [
            mapPercentageToValue(start),
            mapPercentageToValue(end),
          ];
          const [min, max] = start < end ? [start, end] : [end, start];
          addRangeFilter(min, max);
        }
        setSelectedRange(undefined);
      },
      { once: true }
    );
  };

  return (
    <div
      className="flex flex-row justify-end h-full flex-1"
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseLeave={() => !selectedRange && setHoverLocation(undefined)}
    >
      <div className="flex flex-col justify-between h-full items-end pr-1 relative">
        <p>{maxVisualNumber}</p>
        {hoverLocation !== undefined && (
          <Label
            y={hoverLocation}
            value={mapPercentageToValue(hoverLocation)}
          />
        )}

        {selectedRange && (
          <>
            <Label
              y={selectedRange.start}
              value={mapPercentageToValue(selectedRange.start)}
            />
            <Label
              y={selectedRange.end}
              value={mapPercentageToValue(selectedRange.end)}
            />
          </>
        )}
        <p>{minVisualNumber}</p>
      </div>
      <div className="flex h-full w-3 items-end pointer-events-auto">
        <svg height="100%">
          <defs>
            <linearGradient
              id="myGradient"
              gradientTransform="rotate(90)"
              viewBox="0 0 0 auto"
            >
              {stopColors.map((sc, index) => (
                <stop
                  key={'stopColor' + sc}
                  offset={((index / stopColors.length) * 100).toString() + '%'}
                  stopColor={sc}
                />
              ))}
            </linearGradient>
          </defs>

          <rect width="6" height="100%" fill="url('#myGradient')" x="3" />

          {selectedRange && <SelectedRangeIndicator {...selectedRange} />}
          {hoverLocation && <Circle y={hoverLocation} />}
        </svg>
      </div>
    </div>
  );
}

function SelectedRangeIndicator({
  start,
  end,
}: {
  start: number;
  end: number;
}) {
  [start, end] = start < end ? [start, end] : [end, start];
  return (
    <>
      <Mask y={0} height={start} />

      <Mask y={end} height={100 - end} />

      <Circle y={start} />

      <Circle y={end} />
    </>
  );
}

function Mask({ y, height }: { y: number; height: number }) {
  return (
    <rect
      width="6"
      x="3"
      y={`${y}%`}
      height={`${height}%`}
      fill="#000000"
      opacity=".4"
    />
  );
}

function Circle({ y }: { y: number }) {
  return (
    <circle
      cx="6"
      cy={`${y}%`}
      r="5"
      fill="#93C5FD"
      stroke="white"
      strokeWidth="2"
    />
  );
}

function Label({ y, value }: { y: number; value: number }) {
  return (
    <div className="absolute -mt-3" style={{ top: `${y}%` }}>
      {toVisualNumber(value)}
    </div>
  );
}

const toVisualNumber = (originalNumber: number): string => {
  const stringOriginalNumber = originalNumber.toString();
  const dotIndex = stringOriginalNumber.indexOf('.');
  const eIndex = stringOriginalNumber.indexOf('e');

  if (eIndex <= 4 && eIndex !== -1) return stringOriginalNumber;

  if (eIndex === -1 && dotIndex > 5)
    return stringOriginalNumber.substring(0, dotIndex);

  if (eIndex === -1 && dotIndex <= 5)
    return stringOriginalNumber.substring(0, 5);

  return (
    stringOriginalNumber.substring(0, 5) +
    stringOriginalNumber.substring(eIndex)
  );
};
