import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgZoomFit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M11.724 11A5.428 5.428 0 0 0 13 7.5 5.5 5.5 0 1 0 7.5 13c1.28 0 2.52-.451 3.5-1.276l3.793 3.776.707-.707L11.724 11ZM7.5 12a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9Z"
      fill="currentColor"
    />
    <path
      d="M6 7H5V6a1 1 0 0 1 1-1h1v1H6v1ZM10 7H9V6H8V5h1a1 1 0 0 1 1 1v1ZM7 10H6a1 1 0 0 1-1-1V8h1v1h1v1ZM9 10H8V9h1V8h1v1a1 1 0 0 1-1 1Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgZoomFit;
