import { Session } from '@tensorleap/api-client';
import { useMemo, useRef } from 'react';
import { SessionRunsToggle } from './SessionRunsToggle';
import { Setter } from '../core/types';
import { SessionRunSelectionToggle } from './SessionRunSelectionToggle';

export interface SessionRunsDialogParams {
  session: Session;
  selectedSession: Session | undefined;
  handleClose: () => void;
  setSelectedSession: Setter<Session | undefined>;
}

export function SessionRunsDialog({
  session,
  selectedSession,
  setSelectedSession,
  handleClose,
}: SessionRunsDialogParams): JSX.Element {
  const isSelected = useMemo(() => {
    return (
      session !== undefined &&
      selectedSession !== undefined &&
      session.cid === selectedSession.cid
    );
  }, [selectedSession, session]);

  const iconButtonRef = useRef(null);

  return (
    <div>
      <SessionRunSelectionToggle
        session={session}
        isSelected={isSelected}
        setSelectedSession={setSelectedSession}
        handleClose={handleClose}
        iconButtonRef={iconButtonRef}
      />
      {isSelected && (
        <div>
          <SessionRunsToggle
            isOpen={isSelected}
            session={session}
            handleClose={handleClose}
            iconButtonRef={iconButtonRef}
          />
        </div>
      )}
    </div>
  );
}
