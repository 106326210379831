import useSWR, { KeyedMutator } from 'swr';
import api from '../api-client';
import { CacheKey } from './consts';
import { useMergedObject } from '../useMergedObject';
import { ExportedModelData } from '@tensorleap/api-client';

export interface UseExportedSessionRuns {
  exportedModels: ExportedModelData[] | undefined;
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<ExportedModelData[]>;
}

export function useExportedSessionRuns(
  projectId: string,
  sessionId: string
): UseExportedSessionRuns {
  const { data: exportedModels, error, mutate } = useSWR<
    ExportedModelData[],
    Error
  >(`${CacheKey.EXPORTED_MODELS}-${projectId}-${sessionId}`, async () => {
    if (!projectId || !sessionId) {
      return [];
    }
    const { exportedModelData } = await api.getExportedSessionJobs({
      projectId,
      sessionId,
    });
    return exportedModelData;
  });

  return useMergedObject({
    exportedModels,
    error,
    isLoading: !error && !exportedModels,
    refetch: mutate,
  });
}
