import { Button, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { useState } from 'react';
import { useMounted } from '../../core/useMounted';
import { Check1, ContentCopy1 } from '../icons';

export interface CopyButtonProps {
  textToCopy: string;
  className?: string;
  afterClickedClassName?: string;
}

export function CopyButton({
  textToCopy,
  className = '',
  afterClickedClassName,
}: CopyButtonProps): JSX.Element {
  const [isShowingCopiedIcon, setIsShowingCopiedIcon] = useState(false);
  const isMounted = useMounted();

  return (
    <Tooltip title={isShowingCopiedIcon ? 'Copied!' : 'Copy'}>
      <Button
        className={clsx(
          className,
          isShowingCopiedIcon && afterClickedClassName
        )}
        onClick={() => {
          navigator.clipboard.writeText(textToCopy);
          setIsShowingCopiedIcon(true);
          setTimeout(() => {
            isMounted.current && setIsShowingCopiedIcon(false);
          }, 2000);
        }}
      >
        {isShowingCopiedIcon ? (
          <Check1 className="text-base text-secondary-500" />
        ) : (
          <ContentCopy1 className="text-base" />
        )}
      </Button>
    </Tooltip>
  );
}
