import { FormEvent, useCallback, useEffect, useMemo } from 'react';
import { useController, useForm } from 'react-hook-form';
import {
  DataStateType,
  SampleAnalysisAlgo,
  SampleAnalysisParams,
} from '@tensorleap/api-client';
import api from '../../../core/api-client';
import { PaneActions } from './PaneActions';
import { useEpochRange } from './useSelectedModels';
import { useCurrentProject } from '../../../core/CurrentProjectContext';
import { Input } from '../../../ui/atoms/Input';
import { Select } from '../../../ui/atoms/Select';
import { enumToOptions } from '../../../ui/atoms/utils/select';
import { last } from 'lodash';
import { SelectMultiple } from '../../../ui/atoms/SelectMultiple';
import { useModelFilter } from '../../../ui/molecules/useModelFilter';
import { ModelChip } from '../../../ui/molecules/ModelChip';
import { useLocalStorage } from '../../../core/useLocalStorage';
import {
  ALGO_LOCAL_STORAGE_KEY,
  AlgoSelect,
} from '../../../ui/atoms/AlgoSelect';

export interface AnalyzeSampleInputs {
  sessionRunIds: string[];
  datasetSlice: DataStateType;
  sampleIndex: number;
  fromEpoch: number;
  algo: SampleAnalysisAlgo;
}
export interface AnalyzeSampleProps {
  defaultValues?: Partial<AnalyzeSampleInputs>;
  closeTooltip: () => void;
}
const DATA_STATE_TYPES_OPTIONS = enumToOptions(DataStateType);

export function AnalyzeSample({
  closeTooltip,
  defaultValues,
}: AnalyzeSampleProps): JSX.Element {
  const {
    currentVersion: { cid: versionId } = {},
    fetchValidProjectCid,
  } = useCurrentProject();
  const projectId = fetchValidProjectCid();

  const { getEpochRange, getLastEpoch } = useEpochRange();
  const { selected: selectedSessionRuns } = useModelFilter();

  const [selectedAlgo, setSelectedAlgo] = useLocalStorage<SampleAnalysisAlgo>(
    ALGO_LOCAL_STORAGE_KEY,
    SampleAnalysisAlgo.FocusLayerCam
  );

  const {
    control,
    handleSubmit,
    register,
    trigger,
    formState: { errors },
  } = useForm<AnalyzeSampleInputs>({ mode: 'all' });
  const { field: sessionRunIdsField } = useController({
    control,
    name: 'sessionRunIds',
    rules: {
      validate: (v) => !!v?.length,
    },
    defaultValue:
      defaultValues?.sessionRunIds || selectedSessionRuns.map(({ id }) => id),
  });

  const { field: datasetSliceField } = useController({
    control,
    name: 'datasetSlice',
    rules: { required: true },
    defaultValue: defaultValues?.datasetSlice ?? DataStateType.Training,
  });
  const { field: sampleIndexField } = useController({
    control,
    name: 'sampleIndex',
    rules: { required: true },
    defaultValue: defaultValues?.sampleIndex ?? 800,
  });

  const fromEpochOptions = useMemo(() => {
    if (sessionRunIdsField.value.length !== 1) return;
    const sessionRunId = sessionRunIdsField.value[0];
    return getEpochRange(sessionRunId);
  }, [getEpochRange, sessionRunIdsField.value]);

  const { field: fromEpoch } = useController({
    control,
    name: 'fromEpoch',
    rules: { required: true },
    defaultValue: fromEpochOptions ? last(fromEpochOptions) : 0,
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  const onSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      try {
        await handleSubmit<AnalyzeSampleInputs>(
          async ({ sessionRunIds, datasetSlice, sampleIndex, fromEpoch }) => {
            if (!versionId) {
              console.error(
                'how sampleAnalysis was submitted without versionId?'
              );
              return;
            }

            const isMultipleSessionRunIdsSelected = sessionRunIds.length > 1;

            await Promise.all(
              sessionRunIds.map(async (sessionRunId) => {
                const epoch = isMultipleSessionRunIdsSelected
                  ? getLastEpoch(sessionRunId)
                  : fromEpoch;
                const sampleAnalysisParams: SampleAnalysisParams = {
                  projectId,
                  sessionRunId,
                  sampleIdentity: {
                    state: datasetSlice,
                    index: +sampleIndex,
                  },
                  fromEpoch: epoch,
                  algo: selectedAlgo,
                };

                await api.sampleAnalysis(sampleAnalysisParams);
              })
            );
          }
        )(event);
      } catch (e) {
        console.error(e);
      }
    },
    [handleSubmit, versionId, getLastEpoch, projectId, selectedAlgo]
  );

  return (
    <form onSubmit={onSubmit} className="w-full h-full flex flex-col gap-4">
      <SelectMultiple
        label="SELECTED SESSION RUNS"
        error={errors.sessionRunIds && 'Session Run Ids are Required'}
        {...sessionRunIdsField}
        onChange={(sessionRunIds) => {
          sessionRunIdsField.onChange(sessionRunIds);
          if (sessionRunIds.length === 1) {
            const [sessionRunId] = sessionRunIds;
            const epoch = getLastEpoch(sessionRunId as string);
            fromEpoch.onChange(epoch);
          }
        }}
        renderValue={(_, selected, remove) =>
          selected.map((sessionRun) => (
            <ModelChip
              key={sessionRun.id}
              remove={remove ? () => remove(sessionRun) : undefined}
              {...sessionRun}
            />
          ))
        }
        optionToLabel={(sessionRun) => sessionRun.name}
        optionID={'id'}
        options={selectedSessionRuns}
      />
      {fromEpochOptions ? (
        <Select
          label="FROM EPOCH"
          optionToLabel={(n: number) => n.toString()}
          options={fromEpochOptions}
          required
          {...register('fromEpoch', {
            required: { value: true, message: 'Value is required' },
          })}
          error={errors.fromEpoch && errors?.fromEpoch?.message}
          {...fromEpoch}
        />
      ) : (
        <Input label="FROM EPOCH" value="Max epoch" disabled />
      )}
      <Select
        label="DATASET SLICE"
        options={DATA_STATE_TYPES_OPTIONS}
        {...register('datasetSlice', {
          required: { value: true, message: 'Value is required' },
        })}
        error={errors.datasetSlice && errors?.datasetSlice?.message}
        {...datasetSliceField}
      />
      <Input
        type="number"
        required
        min={0}
        label="SAMPLE INDEX"
        {...register('sampleIndex', {
          required: { value: true, message: 'Value is required' },
          min: { value: 0, message: 'Value must be greater or equal to 0' },
        })}
        error={errors.sampleIndex && errors?.sampleIndex.message}
        {...sampleIndexField}
      />
      <AlgoSelect
        selectedAlgo={selectedAlgo}
        setSelectedAlgo={setSelectedAlgo}
      />

      <PaneActions
        closeTooltip={closeTooltip}
        enableSubmit={
          !errors.sessionRunIds &&
          !errors.datasetSlice &&
          !errors.sampleIndex &&
          !errors.fromEpoch
        }
      />
    </form>
  );
}
