import { Tooltip } from '@material-ui/core';
import { useDatasets } from '../../core/DatasetsContext';
import { ClosePanel, OpenPanel } from '../../ui/icons';

export function ShowFileTreeButton(): JSX.Element {
  const {
    virtualFs: { showFiletree, setShowFiletree },
  } = useDatasets();

  return (
    <Tooltip title={showFiletree ? 'Hide file tree' : 'Show file tree'}>
      <button className="mr-2" onClick={() => setShowFiletree((s) => !s)}>
        {showFiletree ? (
          <ClosePanel className="w-7 h-7" />
        ) : (
          <OpenPanel className="w-7 h-7" />
        )}
      </button>
    </Tooltip>
  );
}
