import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgPencilIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M15.38 2.53a2.117 2.117 0 0 0-.67-.452 2.028 2.028 0 0 0-1.601 0c-.256.11-.48.26-.67.452l-8.935 8.934a.454.454 0 0 0-.055.068l-.027.055-1.396 3.858a.352.352 0 0 0-.027.192c.018.064.05.123.096.178a.54.54 0 0 0 .123.068.367.367 0 0 0 .137.028.122.122 0 0 0 .054-.014h.055l3.858-1.41a.531.531 0 0 0 .069-.027l.055-.054 8.934-8.921c.2-.2.351-.429.451-.684a1.994 1.994 0 0 0 0-1.587c-.1-.256-.25-.484-.451-.684ZM6.008 13.858 2.929 14.98l1.122-3.078 7.758-7.758 1.957 1.957-7.758 7.757Zm8.88-8.88-.63.63-1.956-1.956.629-.63c.128-.127.274-.223.438-.287a1.362 1.362 0 0 1 1.067 0c.173.064.324.16.451.287.128.137.228.292.301.465.073.165.11.338.11.52a1.364 1.364 0 0 1-.41.972Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
    />
  </svg>
);

export default SvgPencilIcon;
