import { useCallback } from 'react';
import { TabModelSummaryList } from '../ui/molecules/TabModelSummaryList';
import {
  TestModelSummaryData,
  ModelTestSummary,
  ModelTestSummaryPlacehoder,
  OnEpochChange,
} from './ModelTestSummary';

export type ModelTestSummaryListProps = {
  data: TestModelSummaryData[];
  onModelEpochChange: OnEpochChange;
  testsCount: number;
  className?: string;
};

export function ModelTestSummaryList({
  data,
  className,
  testsCount,
  onModelEpochChange,
}: ModelTestSummaryListProps) {
  const renderModelSummary = useCallback(
    (modelSummaryData, className: string) => (
      <ModelTestSummary
        className={className}
        onEpochChange={onModelEpochChange}
        {...modelSummaryData}
      />
    ),
    [onModelEpochChange]
  );

  const renderModelSummaryPlaceholder = useCallback(
    (className: string) => (
      <ModelTestSummaryPlacehoder
        testsCount={testsCount}
        className={className}
      />
    ),
    [testsCount]
  );

  return (
    <TabModelSummaryList
      className={className}
      data={data}
      renderModelSummary={renderModelSummary}
      renderModelSummaryPlaceholder={renderModelSummaryPlaceholder}
    />
  );
}
