import { AnalyticsDashletData } from '@tensorleap/api-client';
import { MainDashletForm } from './form/MainForm';
import { useDebounce } from '../../../core/useDebounce';
import { ActionResult } from '../../../core/types';
import { UnifiedXYChartParams } from './form/utils';
import { VisualizationFilter } from '../../../core/types/filters';
import { BaseVisualizationProps, DashletCard } from '../DashletCard';
import { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { AnalyticsDashletTopBar } from './AnalyticsDashletTopBar';
import { ElasticVis } from './ElasticVis/ElasticVis';
import { useFetchModeldMetricNames } from '../../../core/data-fetching/model-metric-names';
import { useFetchDashletFields } from '../../../core/data-fetching/dashlet-fields';
import { useDashboardFilters } from '../useDashletFields';
import { useToggle } from '../../../core/useToggle';
import { TOUR_SELECTORS_ENUM } from '../../../tour/ToursConfig';

export type AnalyticsDashletProps = BaseVisualizationProps &
  AnalyticsDashletData & {
    update: (_: AnalyticsDashletData) => ActionResult;
    defaultFilters?: VisualizationFilter[];
  };

export function AnalyticsDashlet({
  remove,
  duplicate,
  projectId,
  className,
  editMode,
  update,
  toggleEditMode,
  selectedSessionRuns,
  defaultFilters = [],
  updatePinFilters,
  pinFilters,
  type,
  name,
  data,
}: AnalyticsDashletProps) {
  const [value, setValue] = useState({ type, name, data });
  const debounceUpdate = useDebounce(update, 800);
  const {
    filterFieldsMeta,
    dashletFilters: localFilters,
    dashletAndGlobalFilters,
    globalizeFilters,
    updateDashletFilters,
  } = useDashboardFilters({
    projectId,
    defaultFilters,
    pinFilters,
    updatePinFilters,
    useRegisteredFilters: false,
  });
  const sessionRunIds = useMemo(
    () => Array.from(new Set(selectedSessionRuns.map(({ id }) => id))),
    [selectedSessionRuns]
  );
  const { dashletFields: formOptions } = useFetchDashletFields(
    projectId,
    sessionRunIds
  );

  const selectedVisibleSessionRunCids = selectedSessionRuns
    .filter(({ visible }) => visible)
    .map(({ id }) => id);
  const { metricNames } = useFetchModeldMetricNames({
    projectId,
    sessionRunIds: selectedVisibleSessionRunCids,
  });

  const apply = useCallback(
    (data: AnalyticsDashletData) => {
      setValue(data);
      debounceUpdate(data);
    },
    [debounceUpdate]
  );

  const visClasses = clsx(
    'flex-1',
    'overflow-auto',
    editMode ? ' rounded-br-2xl' : 'rounded-b-2xl'
  );

  const [fullScreenMode, toggleFullScreenMode] = useToggle(false);

  return (
    <DashletCard
      fullScreenMode={fullScreenMode}
      toggleFullScreenMode={toggleFullScreenMode}
      className={clsx(className, 'flex')}
    >
      {editMode && (
        <MainDashletForm
          value={value}
          apply={apply}
          cancel={toggleEditMode}
          formOptions={formOptions}
          metricNames={metricNames}
          className="border-r border-r-gray-700"
        />
      )}
      <div
        className="flex flex-col flex-1 transition-all overflow-hidden"
        id={TOUR_SELECTORS_ENUM.ANALYTICS_DASHLET_ID}
      >
        <AnalyticsDashletTopBar
          name={value.name}
          className={editMode ? 'rounded-tr-2xl' : 'rounded-t-2xl'}
          filterFieldsMeta={filterFieldsMeta}
          editMode={editMode}
          toggleEditMode={toggleEditMode}
          remove={remove}
          duplicate={duplicate}
          globalizeFilters={globalizeFilters}
          dashletFilters={localFilters}
          updateDashletFilters={updateDashletFilters}
          fullScreenMode={fullScreenMode}
          toggleFullScreenMode={toggleFullScreenMode}
        />
        {!!Object.keys(value.data).length && (
          <ElasticVis
            sessionRunIds={sessionRunIds}
            graphType={value.type}
            graphParams={value.data as UnifiedXYChartParams}
            className={visClasses}
            filters={dashletAndGlobalFilters}
            onFiltersChange={updateDashletFilters}
          />
        )}
      </div>
    </DashletCard>
  );
}
