import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgCollapseDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M8.602 6.898c.22.22.576.22.796 0l1.5-1.5a.562.562 0 1 0-.796-.796L9 5.705 7.898 4.602a.563.563 0 0 0-.796.796l1.5 1.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 2.813a.937.937 0 0 0-.938.937v10.5a.937.937 0 0 0 .938.938h10.5a.938.938 0 0 0 .938-.938V3.75a.938.938 0 0 0-.938-.938H3.75Zm-1.458-.521a2.063 2.063 0 0 1 1.458-.604h10.5a2.063 2.063 0 0 1 2.063 2.062v10.5a2.062 2.062 0 0 1-2.063 2.063H3.75a2.062 2.062 0 0 1-2.063-2.063V3.75c0-.547.218-1.072.605-1.458Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.688 9c0-.31.251-.563.562-.563h13.5a.562.562 0 1 1 0 1.126H2.25A.563.563 0 0 1 1.687 9Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCollapseDownIcon;
