import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const KEY_INDEX_SIZE = 10;

type UseMapIdToKey = {
  get(id: string): number | undefined;
  updateCurrentIds(ids: string[]): void;
};

export function useMapIdToKey(): UseMapIdToKey {
  const uniqKeysManager = useUniqKeysManager();
  const [mapIdToKey, setMapIdToKey] = useState<Map<string, number>>(new Map());

  useEffect(() => {
    return () => {
      uniqKeysManager.init();
    };
  }, [uniqKeysManager]);

  const updateCurrentIds = useCallback(
    (ids: string[]) => {
      setMapIdToKey((mapIdToKey) => {
        const newUniqueKeyMap = new Map(
          ids.map((id) => {
            const uniqueKey =
              mapIdToKey.get(id) !== undefined
                ? mapIdToKey.get(id)
                : uniqKeysManager.get();
            return [id, uniqueKey ?? -1];
          })
        );

        for (const uniqueKey of Array.from(mapIdToKey.entries())) {
          if (!ids.includes(uniqueKey[0])) {
            uniqKeysManager.free(uniqueKey[1]);
          }
        }

        return newUniqueKeyMap;
      });
    },
    [uniqKeysManager]
  );

  const get = useCallback(
    (id: string) => {
      return mapIdToKey.get(id);
    },
    [mapIdToKey]
  );

  return { get, updateCurrentIds };
}

export function useUniqKeysManager(length = KEY_INDEX_SIZE) {
  const keyList = useRef<number[]>(Array.from({ length }, () => 0));

  const init = useCallback(() => {
    if (keyList.current.some((k) => !!k)) {
      keyList.current = Array.from({ length }, () => 0);
    }
  }, [length]);

  const get = useCallback(() => {
    const index = keyList.current.indexOf(Math.min(...keyList.current));
    keyList.current[index]++;
    return index;
  }, []);

  const free = useCallback((index: number) => {
    keyList.current[index]--;
  }, []);

  return useMemo(
    () => ({
      init,
      get,
      free,
    }),
    [free, get, init]
  );
}
