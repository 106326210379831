import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgSupportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M12 3a8.841 8.841 0 0 0-4.518 1.204 8.888 8.888 0 0 0-3.278 3.278A8.841 8.841 0 0 0 3 12c0 1.629.401 3.135 1.204 4.518a8.888 8.888 0 0 0 3.278 3.278A8.841 8.841 0 0 0 12 21a8.841 8.841 0 0 0 4.518-1.204 8.887 8.887 0 0 0 3.278-3.278A8.841 8.841 0 0 0 21 12a8.841 8.841 0 0 0-1.204-4.518 8.888 8.888 0 0 0-3.278-3.278A8.841 8.841 0 0 0 12 3Zm2.25 1.459c.633.187 1.23.451 1.793.791s1.07.735 1.52 1.187c.452.45.847.958 1.187 1.52.34.563.604 1.16.791 1.793h-3.656a4.378 4.378 0 0 0-1.635-1.635V4.46ZM15.375 12a3.24 3.24 0 0 1-.993 2.39 3.263 3.263 0 0 1-2.382.985 3.35 3.35 0 0 1-2.918-1.679A3.304 3.304 0 0 1 8.625 12c0-.926.331-1.72.993-2.382.662-.662 1.456-.993 2.382-.993.61 0 1.172.152 1.688.457a3.44 3.44 0 0 1 1.23 1.23c.305.516.457 1.079.457 1.688Zm-4.5-7.787a7.277 7.277 0 0 1 2.25 0v3.445a4.108 4.108 0 0 0-1.116-.158c-.37 0-.747.053-1.134.158V4.213Zm-1.125.246v3.656A4.562 4.562 0 0 0 8.115 9.75H4.46c.527-1.77 1.553-3.176 3.076-4.219A7.742 7.742 0 0 1 9.75 4.46ZM4.125 12c0-.094.003-.188.009-.281l.017-.281.027-.282.035-.281h3.445c-.105.375-.158.75-.158 1.125s.047.75.14 1.125H4.214A7.751 7.751 0 0 1 4.125 12Zm5.625 7.541a7.721 7.721 0 0 1-3.313-1.977 7.721 7.721 0 0 1-1.978-3.314h3.656a4.378 4.378 0 0 0 1.635 1.635v3.656Zm3.375.246a7.276 7.276 0 0 1-2.25 0V16.36c.387.094.765.141 1.134.141.369 0 .741-.047 1.116-.14v3.427Zm1.125-.246v-3.656a4.379 4.379 0 0 0 1.635-1.635h3.656a8.056 8.056 0 0 1-1.143 2.33 7.807 7.807 0 0 1-1.819 1.827 7.61 7.61 0 0 1-2.329 1.134Zm2.11-6.416A4.75 4.75 0 0 0 16.5 12c0-.375-.053-.75-.158-1.125h3.445c.059.398.088.773.088 1.125s-.03.727-.088 1.125H16.36Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSupportIcon;
