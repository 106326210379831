export type CategoryOption =
  | string
  | {
      value: string;
      name?: string;
      icon?: string;
    };

export enum StringCategoryTypes {
  String = 'string',
  StringArray = 'string_array',
}
export type CategoryMeta<TCategory extends string = string> = {
  type: StringCategoryTypes;
  name?: string;
  category: TCategory;
  enum: CategoryOption[];
};

export type CategoryValue<TCategory extends string = string> = Partial<
  Record<TCategory, string | string[]>
>;
export type NormalizedOption = { value: string; name: string };
