import { ReactElement, useCallback, useState } from 'react';
import { useFreemium } from '../../trial/useFreemium';
import { Tooltip } from '../../ui/mui';

import { AddAnalysisPane } from './AddAnalysisPane';

type AddAnalysisTooltipProps = {
  children: (toggle: () => void) => ReactElement;
};

export function AddSampleAnalysisTooltip({
  children,
}: AddAnalysisTooltipProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const { runOrOpenUpgrade, upgradeDialog } = useFreemium();

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const toggleIsOpen = useCallback(() => {
    runOrOpenUpgrade(() => setIsOpen(true));
  }, [runOrOpenUpgrade]);

  return (
    <>
      {upgradeDialog}
      <Tooltip
        open={isOpen}
        title={<AddAnalysisPane closeTooltip={handleClose} />}
        classes={{ tooltip: 'max-w-max p-0' }}
        arrow
        interactive
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        {children(toggleIsOpen)}
      </Tooltip>
    </>
  );
}
