import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgNoProblemIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18.5a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17Zm0 1.5c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Z"
      fill="currentColor"
    />
    <path
      d="M8.46 15.333c-.34 0-.62-.14-.84-.42l-2.7-3.54c-.25-.39-.26-.78-.03-1.17.23-.4.565-.57 1.005-.51.29.05.525.19.705.42l1.77 2.31 4.44-7.11c.15-.23.355-.38.615-.45s.535-.03.825.12c.23.15.38.355.45.615s.03.535-.12.825l-5.25 8.4c-.2.32-.48.48-.84.48l-.03.03Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgNoProblemIcon;
