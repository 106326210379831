const GZIP_WORKER = new Worker(new URL('./gzip.worker.ts', import.meta.url), {
  type: 'module',
});

export async function gzip(files: File[], filename: string): Promise<File> {
  const fileBuffers = await Promise.all<ArrayBuffer>(
    files.map((f) => f.arrayBuffer())
  );
  const fileContentList = fileBuffers.map((buffer, index) => ({
    buffer,
    name: files[index].name,
  }));

  return new Promise((resolve, reject) => {
    GZIP_WORKER.addEventListener('error', reject, { once: true });
    GZIP_WORKER.addEventListener(
      'message',
      ({ data: tarball }: MessageEvent<ArrayBufferLike>): void => {
        const file = new File([tarball], filename, {
          type: 'application/x-gtar',
        });
        resolve(file);
      },
      { once: true }
    );

    GZIP_WORKER.postMessage(fileContentList, {
      transfer: fileContentList.map((f) => f.buffer),
    });
  });
}
