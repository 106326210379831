import { LinearProgress } from '@material-ui/core';
import { AllSessionsTestResults, SessionTest } from '@tensorleap/api-client';
import { useCallback, useMemo, useState } from 'react';
import { ConfirmDialog } from '../ui/atoms/DeleteContentDialog';
import { ModelTestsCard } from './ModelTestsCard';
import { sortModelTests } from './modelTestsSorting';
import { BaseModelTestProps } from './useModelTests';

export type ModelTestCardsListProps = BaseModelTestProps & {
  tests: SessionTest[];
  testResults: AllSessionsTestResults[];
  isLoading: boolean;
};

export function ModelTestCardsList({
  tests,
  testResults,
  isLoading,
  onDelete,
  ...testModelProps
}: ModelTestCardsListProps): JSX.Element {
  const [testIdToDelete, setTestIdToDelete] = useState<null | string>(null);

  const closeDeleteModel = useCallback(() => {
    setTestIdToDelete(null);
  }, []);

  const openDeleteModel = useCallback((testId: string) => {
    setTestIdToDelete(testId);
  }, []);

  const sortedTests = useMemo(() => sortModelTests(tests, testResults), [
    tests,
    testResults,
  ]);

  const handleOnDelete = useCallback(() => {
    testIdToDelete && onDelete(testIdToDelete);
    closeDeleteModel();
  }, [testIdToDelete, closeDeleteModel, onDelete]);

  const testResultsById = useMemo(() => {
    const resultsMap: Record<string, AllSessionsTestResults> = {};
    testResults.map((testResult) => {
      resultsMap[testResult.testId] = testResult;
    });
    return resultsMap;
  }, [testResults]);

  return (
    <>
      <ConfirmDialog
        title="Are you sure you want to delete this Test?"
        isOpen={!!testIdToDelete}
        onClose={closeDeleteModel}
        onConfirm={handleOnDelete}
      />
      {isLoading && <LinearProgress />}
      <div className="flex flex-col w-full h-full overflow-y-auto scrollbar-hide">
        {sortedTests.map((modelTest) => (
          <ModelTestsCard
            key={modelTest.cid}
            modelTest={modelTest}
            onDelete={openDeleteModel}
            modelTestsResults={testResultsById[modelTest.cid]}
            sortedTests={sortedTests}
            {...testModelProps}
          />
        ))}
      </div>
    </>
  );
}
