import { FormEvent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../atoms/Button';
import { Input } from '../atoms/Input';
import { FwArrowIcon } from '../icons';
import api from '../../core/api-client';
import { TextArea } from '../atoms/TextArea';
import { useFreemium } from '../../trial/useFreemium';

export interface OpenMessageContactFormWidgetInputs {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  subject: string;
  message: string;
}
export function OpenMessageContactFormWidget() {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<OpenMessageContactFormWidgetInputs>({
    mode: 'onBlur',
  });
  const [messageSent, setMessageSent] = useState(false);
  const isFormValid = isValid && isDirty && !isSubmitting;
  const { freemiumUserData } = useFreemium();

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      await handleSubmit<OpenMessageContactFormWidgetInputs>(
        async ({ firstName, lastName, company, email, subject, message }) => {
          try {
            await api.sendUserMessage({
              firstName,
              lastName,
              company,
              email,
              content: { subject, message },
            });
            setMessageSent(true);
          } catch (e) {
            console.error(e);
          }
        }
      )(event);
    },
    [handleSubmit]
  );

  return (
    <form onSubmit={onSubmit} className="flex flex-col w-full h-full">
      <div className="uppercase w-full grid grid-flow-row grid-cols-2 gap-x-2 gap-y-4">
        <Input
          label="first name"
          {...register('firstName', {
            value: freemiumUserData.firstName,
            required: { value: true, message: 'Value is required' },
          })}
          error={errors.firstName && errors?.firstName?.message}
        />
        <Input
          label="last name"
          {...register('lastName', {
            value: freemiumUserData.lastName,
            required: { value: true, message: 'Value is required' },
          })}
          error={errors.lastName && errors?.lastName?.message}
        />
        <Input
          label="company"
          {...register('company', {
            value: freemiumUserData.company,
          })}
          error={errors.company && errors?.company?.message}
        />
        <Input
          label="email"
          type="email"
          {...register('email', {
            value: freemiumUserData.email,
            required: { value: true, message: 'Value is required' },
          })}
          error={errors.email && errors?.email?.message}
        />
        <Input
          label="subject"
          {...register('subject', {
            required: { value: true, message: 'Value is required' },
          })}
          containerProps={{ className: 'col-span-2' }}
          error={errors.subject && errors?.subject?.message}
        />
        <TextArea
          label="message"
          {...register('message', {
            required: { value: true, message: 'Value is required' },
          })}
          className="col-span-2"
          outlineClasses="h-44"
          error={errors?.message?.message}
        />
      </div>
      <footer className="flex col-start-2 gap-2 mt-auto">
        {messageSent && (
          <span className="h-full w-fit items-center inline-flex text-success-600">
            The Message was sent successfully
          </span>
        )}
        <Button
          type="submit"
          className="uppercase ml-auto"
          disabled={!isFormValid}
        >
          send <FwArrowIcon className="pl-2" />
        </Button>
      </footer>
    </form>
  );
}
