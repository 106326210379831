import { ReactNode, useMemo } from 'react';
import { StateObject, useStateObject } from '../../core/types';
import { SupportDialog } from './SupportDialog';

export type SupportData = {
  supportDialogState: StateObject;
  supportDialog: ReactNode;
};

export function useSupport(): SupportData {
  const supportDialogState = useStateObject(false);

  const supportDialog = useMemo(() => {
    return supportDialogState.state ? (
      <SupportDialog openState={supportDialogState} />
    ) : undefined;
  }, [supportDialogState]);

  return {
    supportDialogState,
    supportDialog,
  };
}
