import { PropsWithChildren } from 'react';
import clsx from 'clsx';

import { useInputClasses } from './useInputClasses';

export type InputOutlineContainerProps = PropsWithChildren<{
  clean: boolean;
  inputHasValue: boolean;
  disabled?: boolean;
  label: string;
  className: string;
  small?: boolean;
  error?: boolean;
  onClick?: ((event: React.MouseEvent<HTMLElement>) => void) | undefined;
}>;

export function InputContainer({
  label,
  inputHasValue,
  error,
  clean,
  small,
  disabled,
  children,
  className,
  onClick,
  ...divProps
}: InputOutlineContainerProps) {
  const { container: containerClasses, label: labelClasses } = useInputClasses({
    clean,
    inputHasValue,
    error,
    small,
    disabled,
  });

  return (
    <div
      {...divProps}
      className={clsx(containerClasses, className)}
      onClick={onClick}
    >
      {children}
      {!clean && <label className={clsx(labelClasses)}>{label}</label>}
    </div>
  );
}
