import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgLightBulbOnIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <g clipPath="url(#light-bulb-on-icon_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 .677c.523 0 .948.424.948.948v1.264a.948.948 0 1 1-1.896 0V1.625c0-.524.424-.948.948-.948Zm-8.714 3.61c.37-.37.97-.37 1.34 0l.894.893a.948.948 0 0 1-1.34 1.34l-.894-.893a.948.948 0 0 1 0-1.34Zm17.428 0c.37.37.37.97 0 1.34l-.894.893a.948.948 0 1 1-1.34-1.34l.893-.894c.37-.37.97-.37 1.34 0ZM13 7.63a5.372 5.372 0 0 0-3.8 9.169l.693.69c.27.27.507.566.708.883h4.797c.203-.318.44-.614.708-.882l.693-.691a5.372 5.372 0 0 0-3.8-9.17Zm1.66 12.637h-3.32c.053.298.08.602.08.91v.67a1.58 1.58 0 1 0 3.16 0v-.67c0-.306.027-.61.08-.91ZM8.962 6.958a7.268 7.268 0 0 1 9.176 11.181l-.692.692a3.315 3.315 0 0 0-.971 2.345v.671a3.476 3.476 0 0 1-6.952 0v-.67a3.319 3.319 0 0 0-.97-2.346l-.693-.691a7.27 7.27 0 0 1-1.575-7.92 7.267 7.267 0 0 1 2.677-3.262ZM.677 13c0-.524.424-.948.948-.948h1.264a.948.948 0 1 1 0 1.896H1.625A.948.948 0 0 1 .677 13Zm21.486 0c0-.524.424-.948.948-.948h1.264a.948.948 0 0 1 0 1.896H23.11a.948.948 0 0 1-.948-.948Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="light-bulb-on-icon_svg__a">
        <path fill="currentColor" d="M0 0h26v26H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgLightBulbOnIcon;
