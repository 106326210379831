import clsx from 'clsx';
import { Select } from '../../../ui/atoms/Select';
import { Alert, ProblemIcon, ShapeInfoIcon, Target1 } from '../../../ui/icons';
import { ActionButton } from '../../../ui/molecules/ActionButton';
import {
  CardBody,
  CardFooter,
  Card,
  CardHeader,
} from '../../../ui/molecules/Card';
import { Tooltip } from '../../../ui/mui';
import { LinearProgress } from '@material-ui/core';
import { NetworkWizardErrorSeverity, WizardCardProps } from '../types';

const BORDER_STYLES: Record<NetworkWizardErrorSeverity, string> = {
  [NetworkWizardErrorSeverity.ERROR]: 'border-l-error-700',
  [NetworkWizardErrorSeverity.WARNING]: 'border-l-warning-400',
  [NetworkWizardErrorSeverity.INFO]: 'border-l-primary-500',
};

const HEADER_ICONS: Record<NetworkWizardErrorSeverity, JSX.Element> = {
  [NetworkWizardErrorSeverity.ERROR]: <ProblemIcon className="w-5" />,
  [NetworkWizardErrorSeverity.WARNING]: <Alert className="w-5" />,
  [NetworkWizardErrorSeverity.INFO]: <ShapeInfoIcon className="w-5" />,
};

export function WizardCard({
  title,
  message,
  showNodeFooter = false,
  showNode,
  showNodeIndex,
  quickFixes = [],
  isLoading = false,
  headerClassName,
  headerIcon,
  errorSeverity,
}: WizardCardProps): JSX.Element {
  const showNodeFlag = showNodeFooter && !!showNode;

  const cardHeaderIcon =
    HEADER_ICONS[
      headerIcon && errorSeverity
        ? errorSeverity
        : NetworkWizardErrorSeverity.WARNING
    ];

  return (
    <Card
      className={clsx(
        'border border-l-4 !w-[16.5rem]',
        errorSeverity && BORDER_STYLES[errorSeverity]
      )}
    >
      <CardHeader
        small
        icon={cardHeaderIcon}
        title={title}
        className={clsx(
          'bg-gray-800 border-b-2 border-gray-700 xs/button-small: bold―xs―xs !text-xs',
          headerClassName
        )}
      />
      {quickFixes.length > 0 && !!isLoading && <LinearProgress />}
      <CardBody className="bg-gray-850">
        <div className="flex flex-col overflow-y-auto break-words max-h-16">
          <span className="text-xs">{message}</span>
        </div>
      </CardBody>
      {(showNodeFlag || quickFixes.length > 0) && (
        <CardFooter className="flex flex-row-reverse overflow-hidden px-1 py-2 h-10">
          <CardFooter className="w-fit p-1">
            <div className="flex w-fit flex-row-reverse">
              {quickFixes.map((quickFix, index) => {
                if (quickFix.selectOptions) {
                  return (
                    <div className="w-fit" key={index}>
                      <Select
                        small
                        label={quickFix.title}
                        options={quickFix.selectOptions}
                        onChange={quickFix.onSelect}
                        listPaperClassName="w-fit -translate-x-1/2"
                      />
                    </div>
                  );
                }
                return (
                  <Tooltip title={quickFix.tooltipMsg || ''} key={index}>
                    <div>
                      <ActionButton
                        icon={quickFix.icon}
                        onRun={quickFix.onSelect}
                        disabled={quickFix.disableQuickFix}
                      >
                        <span className="text-xs">{quickFix.title}</span>
                      </ActionButton>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </CardFooter>
          {showNodeFlag && (
            <Tooltip title="Show Node">
              <div>
                <ActionButton
                  icon={<Target1 className="w-5" />}
                  onRun={showNode}
                  className="w-fit"
                >
                  <div className="flex flex-row gap-1 text-xs">
                    <span>Show</span>
                    {showNodeIndex && (
                      <span>
                        {showNodeIndex.current + 1}/{showNodeIndex.total}
                      </span>
                    )}
                  </div>
                </ActionButton>
              </div>
            </Tooltip>
          )}
        </CardFooter>
      )}
    </Card>
  );
}
