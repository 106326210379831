import { useMemo } from 'react';
import { Sorter } from '../sorter';
import { calcAlign, calcHeaderSorting, calcHeaderStyle } from './table-helpers';
import { TableColumn, TableColumnDisplay } from './table-types';
import { createCell } from './TableCell';
import { BaseItem, ModelFields } from '../types';
import { getIdKey, isModelField } from '../utils';

export function useTable<Item extends BaseItem>(
  fields: ModelFields<Item>,
  sorter?: Sorter<Item>
) {
  const columns = useMemo<TableColumn<Item>[]>(
    () =>
      fields
        .filter(({ hidden }) => !hidden)
        .map((field, i) => {
          const { table, label } = field;
          const align = calcAlign(table as TableColumnDisplay);
          return {
            id: label + i,
            label,
            headerStyle: calcHeaderStyle(table as TableColumnDisplay),
            align,
            headerClassName: table?.headerClassName,
            sort: isModelField(field)
              ? calcHeaderSorting(
                  field.accessorKey as string,
                  field.sortable,
                  sorter
                )
              : undefined,
            Cell: createCell(field, align, table?.cellClassName),
          };
        }),
    [fields, sorter]
  );

  const idKey = getIdKey(fields);
  return {
    columns,
    idKey,
    getRowId: (row: Item, index: number) => String(idKey ? row[idKey] : index),
  };
}
