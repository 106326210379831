import { useMemo } from 'react';
import { useHubIndex } from '../core/data-fetching/useHubIndex';
import { useEnvironmentInfo } from '../core/EnvironmentInfoContext';
import { ProjectCategoriesValue } from '../projects/consts';

export type ProjectManifest = {
  name: string;
  sourceProjectId: string;
  title?: string;
  description: string;
  size: number;
  tags: string[];
  categories: ProjectCategoriesValue;
  bgImageSrc?: string;
  importUrl: string;
  upgradeRequired: boolean;
};

export function useHubProjectManifests(): ProjectManifest[] {
  const {
    environmentInfo: { schemaVersion, hubUrl, hubDefaultNamespace },
  } = useEnvironmentInfo();

  const hubPrefixUrl = `${hubUrl}/${hubDefaultNamespace}`;
  const indexUrl = `${hubPrefixUrl}/meta.yaml`;
  const hubIndex = useHubIndex(indexUrl);

  const projectManifestList = useMemo<ProjectManifest[]>(() => {
    if (hubIndex === null || hubIndex?.namespace == undefined) {
      return [];
    }
    return Object.values(hubIndex.projects).flatMap(({ versions }) => {
      const currentVersion = versions[schemaVersion];
      const upgradeRequired = !currentVersion;
      const [featureSchemeVersion, featureVersionData] =
        Object.entries(versions).find(
          ([versionNumber]) => Number(versionNumber) > schemaVersion
        ) || [];

      const isOldVersions = !currentVersion && !featureSchemeVersion;
      if (isOldVersions) return [];

      const {
        size,
        path: importPath,
        sourceProjectId,
        name,
        title,
        description,
        bgImagePath,
        tags,
        categories,
      } = currentVersion || featureVersionData;

      const bgImageSrc = `${hubPrefixUrl}/${bgImagePath}`;
      const importUrl = `${hubPrefixUrl}/${importPath}`;

      const projectManifest: ProjectManifest = {
        name,
        title,
        description,
        bgImageSrc,
        upgradeRequired,
        tags,
        categories,
        importUrl,
        size,
        sourceProjectId,
      };
      return projectManifest;
    });
  }, [hubIndex, hubPrefixUrl, schemaVersion]);

  return projectManifestList;
}
