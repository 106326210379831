import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgImportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.965 4.094A.569.569 0 0 0 5.8 4.5c0 .157.058.295.17.405.11.112.248.17.405.17a.557.557 0 0 0 .409-.17l1.641-1.642v5.362c0 .157.058.295.17.405.11.112.248.17.405.17a.564.564 0 0 0 .408-.17c.11-.11.167-.25.167-.405V3.263l1.641 1.641c.11.113.251.171.409.171a.554.554 0 0 0 .405-.17.554.554 0 0 0 .17-.405.562.562 0 0 0-.167-.408L9.404 1.463A.575.575 0 0 0 9 1.3a.575.575 0 0 0-.404.163l-.004.004-2.627 2.627Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m7.5 5.978-3.55 2.07a.404.404 0 0 0-.2.35v5.653a.404.404 0 0 0 .2.35l4.846 2.826a.403.403 0 0 0 .408 0L14.05 14.4a.404.404 0 0 0 .2-.349V8.398a.405.405 0 0 0-.2-.35l-3.55-2.07v.935l2.545 1.485L9 10.757l-4.045-2.36L7.5 6.914v-.935Zm1.096 5.478L4.558 9.1v4.72l4.038 2.356v-4.72Zm.808 0v4.72l4.038-2.356V9.1l-4.038 2.356Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgImportIcon;
