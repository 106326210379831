import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgBarsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M11.137 16.132c0 .236-.08.447-.24.631a.824.824 0 0 1-.603.237.962.962 0 0 1-.451-.108.772.772 0 0 1-.322-.318.921.921 0 0 1-.11-.442v-4.737c0-.237.08-.434.241-.592a.89.89 0 0 1 .642-.277c.241 0 .442.092.602.277.16.157.241.355.241.592v4.737Zm-3.411 0a.863.863 0 0 1-.281.631.824.824 0 0 1-.602.237.824.824 0 0 1-.602-.237.934.934 0 0 1-.241-.631V14.75c0-.237.08-.434.24-.592a.824.824 0 0 1 .603-.237c.24 0 .441.079.602.237.187.158.28.355.28.592v1.382Zm5.137 0v-3.08c0-.236.08-.434.24-.591a.824.824 0 0 1 .603-.237c.24 0 .441.079.602.236.16.158.24.356.24.593v3.079c0 .236-.08.447-.24.631a.824.824 0 0 1-.602.237.824.824 0 0 1-.602-.237.934.934 0 0 1-.241-.631Zm3.411-7.264c0-.236.08-.434.241-.592A.766.766 0 0 1 17.117 8c.161 0 .305.041.43.124.128.084.24.195.327.322a.733.733 0 0 1 .126.422v7.264a.863.863 0 0 1-.28.631.824.824 0 0 1-.603.237.824.824 0 0 1-.602-.237.934.934 0 0 1-.24-.631V8.868Z"
      fill="currentColor"
    />
    <path
      d="M6.605 2c-2.05 0-3.664.61-4.84 1.832C.588 5.053 0 6.665 0 8.667v6.666c0 2.12.563 3.758 1.69 4.911C2.831 21.414 4.47 22 6.604 22h10.79c2.202 0 3.849-.577 4.941-1.73 1.11-1.154 1.664-2.8 1.664-4.937V8.667c0-2.104-.58-3.74-1.74-4.911C21.1 2.586 19.48 2 17.395 2H6.605ZM6 3.832h12c1.378 0 2.42.365 3.126 1.094.723.73 1.084 1.773 1.084 3.13v7.888c0 1.391-.361 2.451-1.084 3.18-.706.713-1.748 1.07-3.126 1.07H6c-1.361 0-2.403-.365-3.126-1.095-.723-.73-1.084-1.78-1.084-3.155V7.98c0-1.324.361-2.341 1.084-3.054.74-.73 1.781-1.094 3.126-1.094Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBarsIcon;
