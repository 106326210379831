import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgSizeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.321 2.533a7 7 0 1 1 5.358 12.934A7 7 0 0 1 6.32 2.533ZM5.041 12.96a5.6 5.6 0 1 0 7.919-7.92 5.6 5.6 0 0 0-7.92 7.92Zm6.325-1.116.263-2.159H6.37l.262 2.159L3.793 9l2.84-2.844-.262 2.159h5.258l-.263-2.159L14.206 9l-2.84 2.844Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSizeIcon;
