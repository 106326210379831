import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  ConversationHeader,
  MessageInput,
} from '@chatscope/chat-ui-kit-react';
import './ChatDialogStyles.css';
import { useCallback, useState } from 'react';
import { GitBookAPI } from '@gitbook/api';
import { LinearProgress } from '@material-ui/core';

const TENSORLEAP_GITBOOK_ORGANIZATION_ID = '9UXeOlFqlw8pl79U2HGU'; // Its public (not secret)

const NO_RESPONSE_MESSAGE = 'Sorry but I dont have an answer to that question';
const DEFAULT_MESSAGE = 'Type a message...';
const TITLE = 'Tensorleap Documentation';
const MAX_USER_MESSAGE_HISTORY = 10; //Used to send previous queries to the server for conversation context

interface MessageData {
  role: string;
  content: string;
}
type MessageLog = MessageData[];

export const allChatDialogMessages: MessageLog = [];
let userMessageHistory: string[] = [];

const client = new GitBookAPI({});

export function ChatDialog() {
  const [inputValue, setInputValue] = useState<string>('');
  const [
    waitingOnServerResponse,
    setWaitingOnServerResponse,
  ] = useState<boolean>(false);

  const sendUserMessageToServer = useCallback(async (question: string) => {
    try {
      const response = await client.spaces.askQueryInSpace(
        TENSORLEAP_GITBOOK_ORGANIZATION_ID,
        {
          query: question,
          previousQueries: userMessageHistory,
        }
      );
      if (response.data.answer?.text) {
        allChatDialogMessages.push({
          role: 'bot',
          content: response.data.answer.text,
        });
      } else {
        allChatDialogMessages.push({
          role: 'bot',
          content: NO_RESPONSE_MESSAGE,
        });
      }
    } catch (error) {
      console.error('Failed sending chat to Tensorleap documentation', error);
    } finally {
      setWaitingOnServerResponse(false);
    }
  }, []);

  const handleUserMessage = useCallback(
    (newMessage: string) => {
      userMessageHistory.push(newMessage);
      userMessageHistory = userMessageHistory.slice(
        Math.max(userMessageHistory.length - MAX_USER_MESSAGE_HISTORY, 0)
      );
      setWaitingOnServerResponse(true);
      allChatDialogMessages.push({ role: 'user', content: newMessage });
      setInputValue('');
      sendUserMessageToServer(newMessage);
    },
    [sendUserMessageToServer]
  );

  return (
    <div className="flex flex-col items-center justify-center absolute top-20 right-48 w-[600px] h-[600px]">
      <MainContainer className="w-[600px] h-[600px]">
        <ChatContainer>
          <ConversationHeader>
            <ConversationHeader.Content userName={TITLE} />
          </ConversationHeader>
          <LinearProgress className="h-[4px] w-full" />
          <MessageList className="pt-2 relative">
            {allChatDialogMessages.map((msg, index) => (
              <Message
                key={index}
                model={{
                  message: msg.content,
                  direction: msg.role === 'user' ? 'incoming' : 'outgoing',
                  sender: msg.role,
                  position: 'single',
                }}
              />
            ))}
          </MessageList>
          <MessageInput
            placeholder={DEFAULT_MESSAGE}
            value={inputValue}
            onChange={(val: string) => setInputValue(val)}
            onSend={handleUserMessage}
            attachButton={false}
          />
        </ChatContainer>
      </MainContainer>
      <div className="flex h-[4px] w-full justify-center items-center">
        {waitingOnServerResponse && (
          <LinearProgress className="flex h-[2px] w-[580px]" />
        )}
      </div>
    </div>
  );
}
