import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgStopCircledIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <g filter="url(#stop-circled-icon_svg__a)">
      <path
        d="M8.5 12a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5V7a.5.5 0 0 0-.5-.5H9a.5.5 0 0 0-.5.5v5Zm3-10C7.36 2 4 5.36 4 9.5c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5C19 5.36 15.64 2 11.5 2Zm0 13.5c-3.307 0-6-2.693-6-6 0-3.308 2.693-6 6-6s6 2.692 6 6c0 3.307-2.693 6-6 6Z"
        fill="#EEE"
      />
    </g>
    <defs>
      <filter
        id="stop-circled-icon_svg__a"
        x={-1.5}
        y={-1.5}
        width={26}
        height={26}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_22179_322559"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_22179_322559"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SvgStopCircledIcon;
