import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgBwArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="m9 1 1.41 1.41L4.83 8H17v2H4.83l5.58 5.59L9 17 1 9l8-8Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBwArrowIcon;
