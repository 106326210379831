import { useCallback, useMemo, useState } from 'react';
import { useNetworkMapContext } from '../../../core/NetworkMapContext';
import {
  NetworkWizardCategory,
  NetworkWizardData,
  GraphErrorKind,
  NetworkWizardErrorSeverity,
} from '../types';
import { GraphNodesErrorType } from '../errors';

export type NodesMessageData = {
  nodeIds: string[];
  msg: string;
  category?: NetworkWizardCategory;
};

export function useGraphNodesErrorData({
  title = 'INVALID NODES',
  nodesMessageData,
  category: categoryFromProps,
}: GraphNodesErrorType & {
  nodesMessageData: NodesMessageData[];
}): NetworkWizardData[] {
  const { selectNode, onFitNodeToScreen } = useNetworkMapContext();

  const [showNodeIndex, setShowNodeIndex] = useState(0);
  const showNode = useCallback(
    (nodesIds: string[]) => {
      if (nodesIds) {
        setShowNodeIndex((prevIndex) => {
          const newIndex =
            prevIndex + 1 > nodesIds.length - 1 ? 0 : prevIndex + 1;
          const newSelectedId = nodesIds[newIndex];
          onFitNodeToScreen(newSelectedId);
          selectNode(newSelectedId);
          return newIndex;
        });
      }
    },
    [onFitNodeToScreen, selectNode]
  );

  return useMemo(
    () =>
      nodesMessageData.map(
        ({ nodeIds = [], msg, category }): NetworkWizardData => {
          const calculateKey = () => msg + nodeIds.join(',');

          return {
            errorType: GraphErrorKind.nodes,
            category:
              category || categoryFromProps || NetworkWizardCategory.CODE,
            showNodeFooter: true,
            title: title,
            message: msg,
            calculateKey,
            showNode: () => {
              showNode(nodeIds);
            },
            showNodeIndex: {
              current: showNodeIndex,
              total: nodeIds?.length || 0,
            },
            errorSeverity: NetworkWizardErrorSeverity.ERROR,
            key: calculateKey(),
          };
        }
      ),
    [categoryFromProps, nodesMessageData, showNode, showNodeIndex, title]
  );
}
