import { DataStateType } from '@tensorleap/api-client';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Input } from '../../ui/atoms/Input';
import { parsePositiveInt } from '../helper-functions';
import { SelectMultiple } from '../../ui/atoms/SelectMultiple';
import { useLocalStorage } from '../../core/useLocalStorage';
import { DEFAULT_BATCH_SIZE_KEY } from '../RunModel';

type EvaluatePlanFields = { selectedSubsets: string[]; batchSize: number };

const SUBSET_OPTIONS = Object.values(DataStateType);

type EvaluatePlanProps<Fields extends EvaluatePlanFields> = {
  form: UseFormReturn<Fields>;
};

export function EvaluatePlan<Fields extends EvaluatePlanFields>({
  form,
}: EvaluatePlanProps<Fields>) {
  const { control } = (form as unknown) as UseFormReturn<EvaluatePlanFields>;
  const [_, setDefaultBatchSize] = useLocalStorage(DEFAULT_BATCH_SIZE_KEY);
  return (
    <div className="flex flex-col gap-4">
      <span className="font-normal text-base tracking-normal">
        Please define the evaluation plan:
      </span>
      <div className="flex flex-row gap-2">
        <Controller
          name="selectedSubsets"
          control={control}
          rules={{ required: true, minLength: 1 }}
          render={({ field }) => (
            <SelectMultiple
              className="w-full"
              options={SUBSET_OPTIONS}
              label="SELECTED SUBSET"
              {...field}
            />
          )}
        />

        <Controller
          name="batchSize"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              label="BATCH SIZE"
              type="number"
              className="w-28"
              error={fieldState.error && 'Batch Size is Required'}
              onChange={(e) => {
                const numberValue = parsePositiveInt(e.target.value);
                field.onChange(numberValue);
                if (numberValue) setDefaultBatchSize(numberValue);
              }}
            />
          )}
        />
      </div>
    </div>
  );
}
