import { FlatCountdown } from '../molecules/FlatCountdown';
import { Button } from '../atoms/Button';
import { Input } from '../atoms/Input';
import { FormEvent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Check1, XCloseIcon2 } from '../icons';
import clsx from 'clsx';
import { useAuth } from '../../auth/AuthContext';
import api from '../../core/api-client';
import { useFreemium } from '../../trial/useFreemium';
import { Setter, StateObject } from '../../core/types';
import { PopupPaneTitle } from '../molecules/PopupPane';

export interface ExtensionTokenFormProps {
  openState: StateObject;
  setExtensionTokenIsOpen: Setter<boolean>;
  backClosesDialog?: boolean;
}

export interface ExtensionTokenFormInputs {
  token: string;
  setExtensionTokenIsOpen: Setter<boolean>;
}

type CodeStatus = 'none' | 'success' | 'fail';

export function ExtensionTokenDialogContent({
  openState: { setState: setIsOpen },
  setExtensionTokenIsOpen,
  backClosesDialog = false,
}: ExtensionTokenFormProps) {
  const [codeStatus, setCodeStatus] = useState<CodeStatus>('none');
  const { register, handleSubmit } = useForm<ExtensionTokenFormInputs>({
    mode: 'onBlur',
  });
  const { refreshUser } = useAuth();

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      await handleSubmit<ExtensionTokenFormInputs>(async ({ token }) => {
        try {
          if (!token?.trim()) {
            return;
          }
          const { success } = await api.extendTrial({ token });
          setCodeStatus(success ? 'success' : 'fail');
          if (success) {
            await refreshUser();
          }
        } catch (e) {
          console.error(e);
        }
      })(event);
    },
    [handleSubmit, refreshUser]
  );
  const { freemiumUserData } = useFreemium();

  return (
    <>
      <div className="flex justify-between items-center h-1/6 w-full">
        <PopupPaneTitle className="capitalize">
          hey{freemiumUserData.fullName ? ` ${freemiumUserData.fullName}` : ''},
        </PopupPaneTitle>
        <FlatCountdown />
      </div>
      <p className="pt-2 h-1/6 w-full">
        If you already received your extension code, please paste it below. If
        you still need one,
        <span
          className="text-primary-500 cursor-pointer px-2"
          onClick={() => setExtensionTokenIsOpen(false)}
        >
          please contact us
        </span>
        to get an up to date free demo extension code, to extend this demo.
      </p>
      <div className="w-full h-4/6 flex flex-col">
        <div className="flex flex-col gap-1.5 h-full pb-4">
          <div className="flex flex-row w-full h-full">
            <form onSubmit={onSubmit} className="flex flex-col w-full h-full">
              <div className="w-full h-fit flex flex-row gap-2">
                <div className="w-3/4 h-fit">
                  <Input
                    label="Time Extension Code"
                    {...register('token')}
                    onChange={() => {
                      if (codeStatus !== 'none') {
                        setCodeStatus('none');
                      }
                    }}
                  />
                </div>
                <div className="w-10 h-full flex justify-center items-center">
                  {codeStatus !== 'none' &&
                    (codeStatus === 'success' ? (
                      <Check1 className="text-success-500 w-3/4 h-auto" />
                    ) : (
                      <XCloseIcon2 className="text-error-800 w-3/4 h-auto" />
                    ))}
                </div>
                <Button variant="outline">apply code</Button>
              </div>
              <div className="w-full h-2/3 pt-4">
                <p
                  className={clsx('text-sm h-5 pb-12', {
                    'text-success-500': codeStatus === 'success',
                    'text-error-800': codeStatus !== 'success',
                  })}
                >
                  {codeStatus !== 'none' &&
                    (codeStatus == 'success'
                      ? 'The Code applied successfully — time added to your demo period. Enjoy.'
                      : 'Sorry — That code did not work.')}
                </p>
                <span>
                  Try copy and pasting the extension code again from the message
                  you received from Tensorleap, or
                  <span
                    className="text-primary-500 cursor-pointer px-2"
                    onClick={() => setExtensionTokenIsOpen(false)}
                  >
                    contact our support
                  </span>
                  for another extension code.
                </span>
              </div>
            </form>
          </div>
        </div>
        <div className="w-full h-fit flex flex-row">
          <Button
            variant="outline"
            className="align-bottom items-end"
            onClick={() => {
              if (backClosesDialog) {
                setIsOpen(false);
              } else {
                setExtensionTokenIsOpen(false);
              }
            }}
          >
            back
          </Button>
          <Button
            className="align-bottom ml-auto"
            disabled={codeStatus !== 'success'}
            onClick={() => setIsOpen(false)}
          >
            done
          </Button>
        </div>
      </div>
    </>
  );
}
