import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgFailedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.525 14.803a6.102 6.102 0 1 0-4.478-4.912l.102.617a.7.7 0 0 1-1.38.228l-.102-.618a7.501 7.501 0 0 1 12.942-6.271 7.5 7.5 0 0 1-7.437 12.31.7.7 0 0 1 .353-1.354Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.303 5.942a.758.758 0 0 1 0 1.072l-7.089 7.089a.758.758 0 0 1-1.071-1.072l7.089-7.089a.758.758 0 0 1 1.071 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.028 12.14a.758.758 0 0 1-1.071 0L5.83 7.014a.758.758 0 1 1 1.072-1.072l5.126 5.127a.758.758 0 0 1 0 1.071Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFailedIcon;
