import { Info } from '../icons';
import { Tooltip } from '../mui';

export interface InfoGuideProps {
  title: string;
}

export function InfoGuide({ title }: InfoGuideProps): JSX.Element {
  return (
    <Tooltip title={title}>
      <div className="flex  h-full items-center ">
        <Info className="cursor-pointer text-gray-500 hover:text-gray-700 w-6 h-6" />
      </div>
    </Tooltip>
  );
}
