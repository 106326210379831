import { BaseItem, ItemId, ModelFields, ModelFieldUnion } from './types';

import {
  BaseModelField,
  ComputedModelField,
  FieldFormat,
  ModelField,
  Sortable,
} from './model-field-types';
import { useMemo } from 'react';
import {
  RenderEditable,
  OnValueChange,
} from '../atoms/utils/editable-field-utils';

export function createFieldHelper<Item extends BaseItem>({
  sortable = true,
} = {}) {
  function numericField<Key extends keyof Item>(
    key: Key,
    value: Omit<ModelField<Item, Key>, 'accessorKey'>
  ): ModelField<Item, Key> {
    return {
      accessorKey: key,
      ...value,
      sortable: value.sortable ?? sortable,
      table: { align: 'right', ...(value.table || {}) },
    };
  }

  function textField<Key extends keyof Item>(
    key: Key,
    value: Omit<ModelField<Item, Key>, 'accessorKey'>
  ): ModelField<Item, Key> {
    return {
      accessorKey: key,
      ...value,
      sortable: value.sortable ?? sortable,
    };
  }

  function field<Key extends keyof Item>(
    key: Key,
    value: Omit<ModelField<Item, Key>, 'accessorKey'>
  ): ModelField<Item, Key> {
    return {
      accessorKey: key,
      ...value,
    };
  }

  function computed(value: ComputedModelField<Item>): ComputedModelField<Item> {
    return value;
  }

  function editableFormat<Key extends keyof Item>(
    onValueChange: OnValueChange<Item, Key>,
    render: RenderEditable<Item, Key>
  ): FieldFormat<Item, Key> {
    const format: FieldFormat<Item, Key> = (value, item, key) => {
      const onChange = (value?: Item[Key]) =>
        onValueChange({ value, key, item });

      return render({ onChange, value, item, key });
    };
    return format;
  }

  type EditableField<Key extends keyof Item> = BaseModelField & {
    isId?: boolean;
    sortable?: Sortable;
    onChange: OnValueChange<Item, Key>;
    editable: RenderEditable<Item, Key>;
  };

  function editableField<Key extends keyof Item>(
    key: Key,
    { editable, onChange, ...value }: EditableField<Key>
  ) {
    return {
      accessorKey: key,
      ...value,
      format: editableFormat<Key>(onChange, editable),
    };
  }

  return {
    textField,
    numericField,
    computed,
    editableField,
    field,
  };
}

export function isComputedField<Item extends BaseItem>(
  field: ModelFieldUnion<Item>
): field is ComputedModelField<Item> {
  return (field as ModelField<Item, string>).accessorKey === undefined;
}

export function getIdKey<Item extends BaseItem>(
  fields: ModelFields<Item>
): (ItemId & keyof Item) | undefined {
  const field = fields.find((field) => isModelField(field) && field.isId);
  if (field && isModelField(field))
    return field.accessorKey as ItemId & keyof Item;
}

export function useIdKey<Item extends BaseItem>(
  fields: ModelFields<Item>
): ItemId & keyof Item {
  return useMemo(() => {
    const idKey = getIdKey(fields);
    if (idKey) return idKey;
    throw Error('No ID field found - Please add `isId: true` on the id field');
  }, [fields]);
}

export function isModelField<Item extends BaseItem>(
  field: ModelFieldUnion<Item>
): field is ModelField<Item, keyof Item> {
  return !!(field as ModelField<Item, string>).accessorKey;
}
