import { useMemo } from 'react';
import { useVersionControl } from '../../core/VersionControlContext';

export type SelectedSessionRun = {
  name: string;
  id: string;
  sessionId: string;
  visible: boolean;
  modelUniqueKey: number;
};

export type UseModelFilter = {
  remove(ids: string[]): void;
  toggleVisibility(id: string): void;
  selected: SelectedSessionRun[];
};
export function useModelFilter(): UseModelFilter {
  const {
    selectedSessionRunMap,
    isSessionRunVisible: isModelVisible,
    removeSelectedSessionRuns: remove,
    toggleSelectedSessionRunVisibility: toggleVisibility,
    getSelectedSessionRunUniqueKey: getSelectedModelUniqKey,
  } = useVersionControl();

  const selected = useMemo<SelectedSessionRun[]>(
    () =>
      Array.from(selectedSessionRunMap.values()).map(
        ({ cid, name, sessionId }) => {
          const modelUniqueKey = getSelectedModelUniqKey(cid);

          return {
            id: cid,
            name,
            sessionId,
            visible: isModelVisible(cid),
            modelUniqueKey,
          };
        }
      ),
    [selectedSessionRunMap, getSelectedModelUniqKey, isModelVisible]
  );
  return useMemo(
    () => ({
      remove,
      toggleVisibility,
      selected,
    }),
    [selected, remove, toggleVisibility]
  );
}
