import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgCloudDownload2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M13.9 7.692A4.993 4.993 0 0 0 9 3.666a4.997 4.997 0 0 0-4.433 2.693A3.996 3.996 0 0 0 1 10.33a4.003 4.003 0 0 0 4 4h8.667A3.334 3.334 0 0 0 17 10.997a3.317 3.317 0 0 0-3.1-3.306Zm-1.567 1.973L9 12.997 5.667 9.665h2V6.999h2.666v2.666h2Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCloudDownload2;
