import { PropsWithChildren, ReactNode } from 'react';
import { Title } from '../../../ui/atoms/Title';
import clsx from 'clsx';
import { BORDER_COLOR_TEMPLATES } from '../../../ui/molecules/colorTemplates';
import { LoadingDot } from '../../../ui/LoadingDot';
import { LinearProgress } from '@material-ui/core';

// these containers keep the style behavior of session run previews

type PreviewsContainerProps = PropsWithChildren<{
  flex?: boolean;
}>;

export function PreviewsContainer({
  children,
  flex = false,
}: PreviewsContainerProps) {
  return (
    <>
      <div className={clsx('flex overflow-x-auto', flex && 'flex-1')}>
        {children}
      </div>
      {!flex && <span className="flex-1" />}
    </>
  );
}

type PreviewContainerProps = PropsWithChildren<{
  sessionRun: { name: string; modelUniqueKey: number; id: string };
  className?: string;
  flex?: boolean;
  header?: ReactNode;
  loadingType?: 'updating' | 'refreshing';
}>;

export function PreviewContainer({
  sessionRun,
  children,
  header,
  flex,
  className,
  loadingType,
}: PreviewContainerProps): JSX.Element {
  return (
    <div
      className={clsx(
        flex && 'flex-1',
        'flex flex-col h-full overflow-hidden',
        className
      )}
    >
      <div className="flex px-4 justify-between items-center h-fit relative">
        {sessionRun && (
          <div className="flex items-center gap-2">
            <Title
              small
              bottomBorderClassName={clsx(
                BORDER_COLOR_TEMPLATES[sessionRun.modelUniqueKey]
              )}
            >
              {sessionRun.name}
            </Title>
            {loadingType === 'updating' && <LoadingDot />}
          </div>
        )}

        {header}
        {loadingType === 'refreshing' && (
          <LinearProgress className="absolute bottom-0 inset-x-0" />
        )}
      </div>
      <div className="bg-gray-900 flex-1 flex flex-row justify-center overflow-hidden h-full overflow-y-auto">
        {children}
      </div>
    </div>
  );
}
