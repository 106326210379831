import { ScatterViz } from '@tensorleap/api-client';

import api from '../../../core/api-client';
import { last } from 'lodash';
import useAsyncEffect from '../../../core/useAsyncEffect';
import { useFetchScatterVisualizationsMap } from '../../../core/data-fetching/scatter-visualizations-map';
import { useMemo, useState } from 'react';
import {
  markAsMetadataPreview,
  markAsVisualizationPreview,
} from '../VisualizationDisplay/visDataHelpers';

export const VISUALIZATION_PAYLOAD_FILE_NAME = 'payload.json';

type VisualizationDisplay = { visType: string; visName: string };

export type ScatterOption<TOption = string> = {
  options: TOption[];
  value?: TOption;
  setOption: (option: TOption) => void;
};

export type ScatterViewSetting = {
  sizeOrShape: ScatterOption;
  dotColor: ScatterOption;
  previewBy: ScatterOption<string | undefined | null>;
};

export type ScatterViewSettingValues = {
  sizeOrShape?: string;
  dotColor?: string;
  previewBy?: null | string;
};

export type ScatterViewSettingOptions = {
  sizeOrShape: string[];
  dotColor: string[];
  previewBy: string[];
};

type UseScatterAssetsProps = {
  projectId: string;
  sessionRunId: string;
  epoch: number;
};

type UseScattersAssets = {
  scatterSampleVisualizationsPrefix: string;
  visualizationDisplays: VisualizationDisplay[];
  samplesIdsWithAssets: Set<string>;
};

export function useScattersAssets({
  projectId,
  sessionRunId,
  epoch,
}: UseScatterAssetsProps): UseScattersAssets {
  const { scatterVisualizationsMapResponse } = useFetchScatterVisualizationsMap(
    projectId,
    sessionRunId,
    epoch
  );
  const [
    scatterSampleVisualizationsPrefix,
    setScatterSampleVisualizationsPrefix,
  ] = useState<string>('');
  const [visualizationDisplays, setVisualizationDisplays] = useState<
    VisualizationDisplay[]
  >([]);

  const samplesIdsWithAssets = useMemo(
    () => new Set(scatterVisualizationsMapResponse?.samplesIds),
    [scatterVisualizationsMapResponse?.samplesIds]
  );

  useAsyncEffect(async () => {
    if (!scatterVisualizationsMapResponse) return;

    const {
      samplesIds,
      scatterSampleVisualizationsPrefix,
    } = scatterVisualizationsMapResponse;

    const lastSample = last(samplesIds);
    if (!lastSample || !scatterSampleVisualizationsPrefix) return;

    const {
      paths: sampleVisualizationPaths,
    } = await api.getSampleVisualizationsPath({
      scatterSampleVisualizationsPrefix: scatterSampleVisualizationsPrefix,
      fileNameMatch: VISUALIZATION_PAYLOAD_FILE_NAME,
      sampleId: lastSample,
    });

    const visualizationDisplays: VisualizationDisplay[] = sampleVisualizationPaths.map(
      (path) => {
        const [visType, visName] = path
          .replace(`${scatterSampleVisualizationsPrefix}${lastSample}/`, '')
          .replace(`/${VISUALIZATION_PAYLOAD_FILE_NAME}`, '')
          .split('/');

        return { visType, visName };
      }
    );

    const sortedVisualizationDisplays = visualizationDisplays.sort((v1, v2) =>
      v1.visType.startsWith('image') && !v2.visType.startsWith('image') ? -1 : 1
    );

    setScatterSampleVisualizationsPrefix(scatterSampleVisualizationsPrefix);
    setVisualizationDisplays(sortedVisualizationDisplays);
  }, [scatterVisualizationsMapResponse]);

  return {
    scatterSampleVisualizationsPrefix,
    visualizationDisplays,
    samplesIdsWithAssets,
  };
}

// helpers

export function settingValuesWithDefault(
  values: ScatterViewSettingValues,
  options: ScatterViewSettingOptions
): ScatterViewSettingValues {
  return {
    sizeOrShape: keyIfIncludesInOptions(
      options.sizeOrShape,
      values.sizeOrShape
    ),
    dotColor: keyIfIncludesInOptions(options.dotColor, values.dotColor),
    previewBy: keyIfIncludesInOptions(options.previewBy, values.previewBy),
  };
}

export function extractScatterSettingOptions(
  payload: ScatterViz,
  visualizationDisplays: VisualizationDisplay[]
): ScatterViewSettingOptions {
  const metadata = payload.scatter_data.metadata;

  const metadataKeys = Object.keys(metadata);
  const sizeOrShape = metadataKeys;

  const dotColor = metadataKeys;

  const visValues = visualizationDisplays.map(({ visName }) => visName);

  const previewBy = [
    ...visValues.map((value) => markAsVisualizationPreview(value)),
    ...metadataKeys.map((key) => markAsMetadataPreview(key)),
  ];

  return {
    sizeOrShape,
    dotColor,
    previewBy,
  };
}

function keyIfIncludesInOptions(
  keys: string[],
  selectedKey: string | undefined | null
) {
  return selectedKey === null
    ? keys[0]
    : selectedKey !== undefined && keys.includes(selectedKey)
    ? selectedKey
    : undefined;
}
