import { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { RunModelDialog } from './RunModel';
import { RunningToolbarDialog } from './RunningDialog';
import { Down, HelpWheel, QuestionCircleIcon, Up, Vi1 } from '../ui/icons';
import Lightning2Icon from '../ui/icons/Lightning2Icon';
import { useVersionControl } from '../core/VersionControlContext';
import { NotificationPanel } from '../actions-dialog/NotificationPanel';
import { useFreemium } from '../trial/useFreemium';
import { useSupport } from '../ui/support/useSupport';
import { ActionButton } from '../ui/molecules/ActionButton';
import { Button } from '../ui/atoms/Button';
import { useStateObject } from '../core/types';
import { CircledIcon } from '../ui/atoms/CircledIcon';
import { useAuth } from '../auth/AuthContext';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { ChatDialog } from '../ui/support/ChatDialog';
import { URLS_ENUM } from '../url/url-builder';
import { ToursDialog } from '../tour/ToursDialog';
import { Tooltip } from '@material-ui/core';
import { useToggle } from '../core/useToggle';
import { Drawer } from '../ui/atoms/Drawer';
import { RightMenu } from '../menu/RightMenu';
import { UserManagementDialog } from '../team-management/UserManagementDialog';

export function ToolbarActions(): JSX.Element {
  const { user } = useAuth();
  const rightMenuDrawerState = useStateObject(false);
  const userManagerDialogState = useStateObject(false);

  const {
    currentVersion,
    isTrainDialogOpen,
    setIsTrainDialogOpen,
    isEvaluateDialogOpen,
    setIsEvaluateDialogOpen,
  } = useCurrentProject();
  const { versions } = useVersionControl();
  const { runOrOpenUpgrade, upgradeDialog } = useFreemium();
  const {
    supportDialogState: {
      state: supportDialogSetIsOpen,
      setState: setSupportDialogSetIsOpen,
    },
    supportDialog,
  } = useSupport();

  const [chatDialogIsOpen, toggleChatDialogIsOpen] = useToggle(false);

  const match = useRouteMatch({ path: URLS_ENUM.PROJECT });
  const isMainPage = !!match?.path;

  const currentSlimVersion = useMemo(
    () =>
      versions.find(({ cid }) => cid === currentVersion?.cid) || versions[0],
    [versions, currentVersion]
  );

  const openTrainDialog = useCallback(() => {
    runOrOpenUpgrade(() => setIsTrainDialogOpen(true));
  }, [runOrOpenUpgrade, setIsTrainDialogOpen]);

  const openEvaluateDialog = useCallback(() => {
    runOrOpenUpgrade(() => setIsEvaluateDialogOpen(true));
  }, [runOrOpenUpgrade, setIsEvaluateDialogOpen]);

  const handleTrainDialogClose = useCallback(() => {
    setIsTrainDialogOpen(false);
  }, [setIsTrainDialogOpen]);

  const handleEvaluateDialogClose = useCallback(() => {
    setIsEvaluateDialogOpen(false);
  }, [setIsEvaluateDialogOpen]);

  return (
    <>
      {chatDialogIsOpen && <ChatDialog />}
      {supportDialog}
      {upgradeDialog}
      <UserManagementDialog openState={userManagerDialogState} />
      {isMainPage && (
        <>
          <ActionButton icon={<Lightning2Icon />} onRun={openTrainDialog}>
            TRAIN
          </ActionButton>

          <ActionButton icon={<Vi1 />} onRun={openEvaluateDialog}>
            EVALUATE
          </ActionButton>
        </>
      )}

      <ToursDialog />

      <RunningToolbarDialog />

      <Tooltip title="Contact Tensorleap support">
        <Button
          variant="action-icon"
          onClick={() => setSupportDialogSetIsOpen(true)}
        >
          <HelpWheel />
          {supportDialogSetIsOpen ? <Up /> : <Down />}
        </Button>
      </Tooltip>

      <Tooltip title="Documentation">
        <Button variant="action-icon" onClick={toggleChatDialogIsOpen}>
          <QuestionCircleIcon />
          {chatDialogIsOpen ? <Up /> : <Down />}
        </Button>
      </Tooltip>

      <NotificationPanel />

      {user && (
        <Button
          variant="action-icon"
          onClick={() => rightMenuDrawerState.setState(true)}
        >
          <CircledIcon text={user.local.name} borderStyle="border-2" />
          {rightMenuDrawerState.state ? <Up /> : <Down />}
        </Button>
      )}

      {currentSlimVersion && isEvaluateDialogOpen && (
        <RunModelDialog
          isOpen={isEvaluateDialogOpen}
          onClose={handleEvaluateDialogClose}
          initialVersion={currentSlimVersion}
          initialRunModelMethod="Evaluate"
        />
      )}
      {currentSlimVersion && isTrainDialogOpen && (
        <RunModelDialog
          isOpen={isTrainDialogOpen}
          onClose={handleTrainDialogClose}
          initialVersion={currentSlimVersion}
          initialRunModelMethod="Train"
        />
      )}
      {rightMenuDrawerState.state && (
        <Drawer
          openState={rightMenuDrawerState}
          drawerClassName="absolute top-16 right-0 h-[calc(100%_-_4.1rem)] w-80 bg-gray-800"
          position="top"
        >
          <RightMenu userManagerDialogState={userManagerDialogState} />
        </Drawer>
      )}
    </>
  );
}
