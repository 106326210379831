import { ReactNode, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { VisualizationFilter } from '../core/types/filters';
import {
  Back,
  Forward,
  Plus,
  SmallFilterEmpty,
  SmallFilterFilled,
} from '../ui/icons';
import { Badge, Button, CircularProgress, IconButton } from '../ui/mui';
import { FilterElements } from './FilterElements';
import { FilterFieldMeta } from '../filters/helpers';
import { FilterFormPopup } from '../filters/FilterFormPopup';
import { useOpenState } from '../ui/atoms/utils/useOpenState';
import { stopPropagation } from '../core/stopPropagation';
import { isFilterLoading } from '../core/filters';

export interface FilterListProps {
  filters: VisualizationFilter[];
  onFiltersChange: (_: VisualizationFilter[]) => void;
  filterFieldsMeta: FilterFieldMeta[];
  listClassName?: string;
  buttonClassName?: string;
  extraContent?: ReactNode;
  showAddFilter?: boolean;
  showFilterToggleButton?: boolean;
  expandedByDefault?: boolean;
  loadingMsg?: ReactNode;
  allowDisable?: boolean;
  allowPin?: boolean;
}
export function FilterList({
  filters,
  onFiltersChange,
  filterFieldsMeta,
  listClassName,
  buttonClassName,
  extraContent,
  showAddFilter = true,
  showFilterToggleButton = true,
  expandedByDefault = true,
  allowDisable = true,
  allowPin = true,
}: FilterListProps) {
  const { isOpen, open, close } = useOpenState(false);
  const initialShowFilters =
    !showFilterToggleButton || (expandedByDefault && filters.length > 0);
  const [showFilters, setShowFilters] = useState(initialShowFilters);

  const [initialFiltersAdded, setInitialFiltersAdded] = useState(false);
  const [userClosedFilters, setUserClosedFilters] = useState(false);

  const toggleShowFilter = useCallback(() => {
    setShowFilters((s) => !s);
    setUserClosedFilters((s) => !s);
  }, []);

  const handleFilterAdd = useCallback(
    (newFilter) => {
      onFiltersChange([...filters, newFilter]);
      if (
        (!initialFiltersAdded && filters.length === 0) ||
        (filters.length === 0 && !userClosedFilters)
      ) {
        setInitialFiltersAdded(true);
        setShowFilters(true);
        setUserClosedFilters(false);
      }
    },
    [onFiltersChange, filters, initialFiltersAdded, userClosedFilters]
  );

  useEffect(() => {
    if (filters.length === 0) {
      setShowFilters(false);
    } else if (filters.length === 1 && !initialFiltersAdded) {
      setShowFilters(true);
      setInitialFiltersAdded(true);
    }
  }, [filters, initialFiltersAdded]);

  const isSomeFilterLoading = filters.some(isFilterLoading);

  return (
    <div className="flex flex-row items-center basis-0">
      {showFilterToggleButton && (
        <Button
          className={clsx('text-sm m-0 mr-2 p-0 relative', buttonClassName)}
          onClick={toggleShowFilter}
          onMouseDown={stopPropagation}
          data-testid="custom-visualization-filter-bar-expand"
          disabled={!filters.length}
        >
          {filters.length ? (
            <Badge badgeContent={filters.length} color="primary">
              <SmallFilterFilled />
            </Badge>
          ) : (
            <SmallFilterEmpty />
          )}
          {showFilters ? (
            <Back className="ml-1 h-4 w-4" />
          ) : (
            <Forward className="ml-1 h-4 w-4" />
          )}
          {isSomeFilterLoading && !showFilters && (
            <div className="absolute left-1">
              <CircularProgress size={28} className="ml-1" />
            </div>
          )}
        </Button>
      )}
      {showFilters && (
        <FilterElements
          filters={filters}
          onFiltersChange={onFiltersChange}
          listClassName={clsx(listClassName)}
          buttonClassName={buttonClassName}
          filterFieldsMeta={filterFieldsMeta}
          extraContent={extraContent}
          allowDisable={allowDisable}
          allowPin={allowPin}
        />
      )}
      <FilterFormPopup
        onClose={close}
        onApply={handleFilterAdd}
        fields={filterFieldsMeta}
        open={isOpen}
        allowDisable={allowDisable}
      >
        {showAddFilter ? (
          <IconButton onClick={open} onMouseDown={stopPropagation}>
            <Plus className="w-4 h-4" />
          </IconButton>
        ) : (
          <></>
        )}
      </FilterFormPopup>
    </div>
  );
}
