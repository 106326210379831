import React, { MouseEventHandler, PropsWithChildren } from 'react';
import clsx from 'clsx';

import { ListItem as ListItemMui } from '@material-ui/core';

export type ListItemProps = {
  selected?: boolean;
  children: React.ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLElement>;
};

export const ListItem = React.forwardRef<HTMLLIElement, ListItemProps>(
  ({ className, selected, onClick, children }, ref) => {
    return (
      <ListItemMui
        ref={ref}
        onClick={onClick}
        selected={selected}
        // since onClick and button are dynamic, we need to set button to as false as a workaround
        button={!!onClick as false}
        className={clsx(
          clsx(
            'flex',
            'flex-row',
            onClick && 'cursor-pointer',
            selected
              ? '!bg-primary-950 hover:!bg-primary-950/70'
              : 'hover:bg-primary-925'
          ),
          className
        )}
      >
        {children}
      </ListItemMui>
    );
  }
);
ListItem.displayName = 'ListItem';

export type ListProps = PropsWithChildren<{
  className?: string;
  onClick?: MouseEventHandler<HTMLElement>;
}>;
export function List({ className, children, ...listProps }: ListProps) {
  return (
    <ul
      {...listProps}
      className={clsx('flex flex-col overflow-y-auto', className)}
    >
      {children}
    </ul>
  );
}
