import { Connection } from './interfaces/Connection';

export function numberToEnglishWord(number: number): string {
  switch (number) {
    case 0:
      return 'Zero';
    case 1:
      return 'One';
    case 2:
      return 'Two';
    case 3:
      return 'Three';
    case 4:
      return 'Four';
    case 5:
      return 'Five';
    case 6:
      return 'Six';
    case 7:
      return 'Seven';
    case 8:
      return 'Eight';
    case 9:
      return 'Nine';
    case 10:
      return 'Ten';

    default:
      return number.toString();
  }
}

export function connectionToIdentifier(connection: Connection) {
  return `${connection.inputNodeId}-${connection.inputName}-${connection.outputNodeId}-${connection.outputName}`;
}
