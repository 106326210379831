import { ConfusionMatrixTableParams } from '@tensorleap/api-client';
import { MultiCharts } from '../../../../ui/charts/visualizers/MultiCharts';
import { getSplitLabels } from './utils';
import { XYChartVizProps } from './interfaces';
import api from '../../../../core/api-client';
import { useGetChart } from '../../../../core/data-fetching/getChart';
import { ChartRequestData } from '../../../../ui/charts/common/interfaces';
import { useMergedObject } from '../../../../core/useMergedObject';
import { ConfusionMatrixTypeEnum } from '../form/ConfusionMatrix';
import { useMemo } from 'react';

export function ConfusionMatrixTableVis({
  graphParams: {
    modelIdPosition,
    firstSplit,
    metricName,
    autoScaleY,
    threshold,
    showPercentages,
    confusionMatrixLabelsFilter,
    splitByLabel = false,
    splitByLabelOrder,
  },
  filters,
  sessionRunIds,
  onFiltersChange,
  chartType,
  projectId,
}: XYChartVizProps) {
  const { verticalSplit, horizontalSplit } = useMemo(
    () => getSplitLabels(modelIdPosition, firstSplit),
    [modelIdPosition, firstSplit]
  );

  const params = useMergedObject<ConfusionMatrixTableParams>({
    projectId,
    filters,
    sessionRunIds,
    customMetricName: metricName,
    verticalSplit:
      splitByLabel && modelIdPosition === 'horizontal'
        ? undefined
        : verticalSplit,
    horizontalSplit:
      splitByLabel && modelIdPosition !== 'horizontal'
        ? undefined
        : horizontalSplit,
    threshold: threshold ? Number(threshold) : undefined,
    filterLabels: confusionMatrixLabelsFilter,
    splitByLabel,
    splitByLabelOrder,
  });

  const chartRequestData = useMergedObject<ChartRequestData>({
    xField: '',
    yField: '',
    showPercentages,
  });

  const { multiChartsResponse, isLoading, error } = useGetChart({
    params,
    func: async (x) => {
      return await api.getConfusionMatrixTable(x);
    },
  });

  return (
    <MultiCharts
      xyChartsResponse={multiChartsResponse}
      chartRequestData={chartRequestData}
      onFiltersChange={onFiltersChange}
      filters={filters}
      chartType={chartType}
      chartSubType={ConfusionMatrixTypeEnum.ConfusionMatrixTable}
      autoScaleY={autoScaleY}
      isLoading={isLoading}
      horizontalSplit={params.horizontalSplit ?? null}
      verticalSplit={params.verticalSplit ?? null}
      error={error}
      showLegend={false}
    />
  );
}
