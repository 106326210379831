import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgHelpWheel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M5.96 1.597A8.019 8.019 0 0 1 9 1c1.046 0 2.059.199 3.04.597a7.84 7.84 0 0 1 2.62 1.744 7.838 7.838 0 0 1 1.743 2.62A8.02 8.02 0 0 1 17 9a8.02 8.02 0 0 1-.597 3.04 7.84 7.84 0 0 1-1.744 2.62 7.84 7.84 0 0 1-2.62 1.743A8.02 8.02 0 0 1 9 17a8.02 8.02 0 0 1-3.04-.597 7.838 7.838 0 0 1-2.62-1.744 7.84 7.84 0 0 1-1.743-2.62A8.019 8.019 0 0 1 1 9c0-1.045.199-2.059.597-3.04A7.838 7.838 0 0 1 3.34 3.34a7.838 7.838 0 0 1 2.62-1.743Zm.006 5.397-2.08-2.08a6.542 6.542 0 0 0-1.068 6.239c.243.701.599 1.345 1.068 1.932l2.08-2.08A3.555 3.555 0 0 1 5.364 9c0-.731.2-1.4.602-2.006Zm7.12 7.12-2.08-2.08A3.556 3.556 0 0 1 9 12.636c-.731 0-1.4-.2-2.006-.602l-2.08 2.08a6.543 6.543 0 0 0 6.239 1.068 6.444 6.444 0 0 0 1.932-1.068ZM10.54 7.46A2.088 2.088 0 0 0 9 6.818c-.602 0-1.117.214-1.545.642A2.097 2.097 0 0 0 6.818 9c0 .602.212 1.117.637 1.546.428.424.943.636 1.545.636s1.116-.212 1.54-.636c.428-.429.642-.944.642-1.546 0-.602-.214-1.116-.642-1.54Zm.613-4.642a6.542 6.542 0 0 0-4.306 0c-.701.243-1.345.599-1.932 1.068l2.08 2.08A3.555 3.555 0 0 1 9 5.364c.731 0 1.4.2 2.006.602l2.08-2.08a6.445 6.445 0 0 0-1.933-1.068Zm2.96 2.097-2.079 2.08c.402.605.602 1.274.602 2.005 0 .731-.2 1.4-.602 2.006l2.08 2.08a6.543 6.543 0 0 0 1.068-6.239 6.445 6.445 0 0 0-1.068-1.932Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgHelpWheel;
