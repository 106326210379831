import { Down, TipsOn, Up, ViIcon, XCloseIcon2 } from '../ui/icons';
import {
  LinearProgress,
  Popover,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { Button } from '../ui/atoms/Button';
import { useCallback, useMemo, useState } from 'react';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import clsx from 'clsx';
import { useRipple } from '../ui/atoms/utils/useRipple';
import { TourData, TourSequence, ToursTypesEnum } from './ToursConfig';
import { useTours } from './ToursContext';
import { Divider } from '../dashboard/Divider';
import { StateToggleVi } from '../ui/StateToggleVi';

const StyledTourLinearProgress = withStyles({
  root: {
    borderRadius: 50,
    backgroundColor: '#14532D',
  },
  bar: {
    borderRadius: 50,
    backgroundColor: '#4ADE80',
  },
})(LinearProgress);

export function ToursDialog() {
  const popoverState = usePopupState({
    variant: 'popover',
    popupId: 'toursPopover',
  });

  return (
    <>
      <Tooltip title="Guiding Tours">
        <Button {...bindTrigger(popoverState)} variant="action-icon">
          <TipsOn />
          {popoverState.isOpen ? <Up /> : <Down />}
        </Button>
      </Tooltip>
      <Popover
        {...bindPopover(popoverState)}
        classes={{
          paper:
            'max-h-[80%] overflow-hidden overflow-y-auto w-[23rem] rounded-b-lg bg-gray-900 border-b border-x border-gray-700 !p-0 !m-0 !top-16 !right-[18rem] !left-auto',
        }}
      >
        <ToursPopoverDialog onClose={popoverState.close} />
      </Popover>
    </>
  );
}

interface ToursPopoverContentProps {
  onClose: () => void;
}

function ToursPopoverDialog({ onClose }: ToursPopoverContentProps) {
  const handleBlur = useCallback(
    (_: React.FocusEvent<HTMLDivElement>) => {
      onClose();
    },
    [onClose]
  );

  return (
    <div className="flex flex-col w-full gap-3 pb-3" onBlur={handleBlur}>
      <TourDialogHeader onClose={onClose} />
      <TourDialogTours onClose={onClose} />
    </div>
  );
}

interface ToursDialogSequenceProps {
  tourType: ToursTypesEnum;
  sequence: TourSequence;
  index: number;
  total: number;
  onClose: () => void;
}
function ToursDialogSequence({
  sequence,
  tourType,
  index,
  total,
  onClose,
}: ToursDialogSequenceProps) {
  const ripple = useRipple();
  const isLast = index === total - 1;
  const { getSequenceState, updateSequenceState, selectTour } = useTours();
  const sequenceState = useMemo(() => getSequenceState(tourType, index), [
    getSequenceState,
    tourType,
    index,
  ]);

  const onClickSequence = useCallback(() => {
    selectTour(tourType, index);
    onClose();
  }, [selectTour, tourType, index, onClose]);

  const onClickSequenceState = useCallback(() => {
    updateSequenceState(tourType, index, !sequenceState.completed);
  }, [updateSequenceState, tourType, index, sequenceState.completed]);

  return (
    <div
      className={clsx(
        'flex flex-row w-full min-h-[2rem] px-2 justify-start items-start gap-2',
        sequenceState.completed ? 'bg-primary-950' : 'bg-gray-800',
        'hover:bg-primary-925',
        !isLast && 'border-b border-gray-700'
      )}
    >
      <div className="flex w-6 h-full pt-[0.35rem]">
        <StateToggleVi
          onClick={onClickSequenceState}
          completed={sequenceState.completed}
        >
          <span className="text-xs align-middle text-center justify-center items-center select-none text-gray-350">
            {index + 1}
          </span>
        </StateToggleVi>
      </div>
      <div
        className="flex w-full py-1 select-none text-gray-350 cursor-pointer"
        onMouseUp={ripple}
        onClick={onClickSequence}
      >
        <span>{sequence.description}</span>
      </div>
    </div>
  );
}

interface ToursDialogSequencesProps {
  sequences: TourSequence[];
  tourType: ToursTypesEnum;
  onClose: () => void;
}
function ToursDialogSequences({
  sequences,
  tourType,
  onClose,
}: ToursDialogSequencesProps) {
  return (
    <div className="flex flex-col w-full">
      {sequences.map((sequence, index) => (
        <ToursDialogSequence
          key={index}
          tourType={tourType}
          sequence={sequence}
          index={index}
          total={sequences.length}
          onClose={onClose}
        />
      ))}
    </div>
  );
}

interface ToursDialogCardInformationProps {
  description: string;
  tourType: ToursTypesEnum;
}
function ToursDialogCardInformation({
  description,
  tourType,
}: ToursDialogCardInformationProps) {
  const { getTourState, updateTourState } = useTours();
  const completedTour = useMemo(() => {
    return getTourState(tourType).sequenceStates.every(
      (s) => s.completed === true
    );
  }, [getTourState, tourType]);

  return (
    <div className="flex flex-1 min-h-[4rem]">
      <div className="flex h-full w-full flex-row py-2 px-4">
        <div className="flex h-full w-full">
          <span>{description}</span>
        </div>
        <div className="flex h-full w-12 justify-end pr-1">
          <StateToggleVi
            onClick={() => updateTourState(tourType, !completedTour)}
            completed={completedTour}
          >
            <ViIcon className={clsx('h-4 w-4 rounded-xl')} />
          </StateToggleVi>
        </div>
      </div>
    </div>
  );
}

interface ToursDialogCardExpandableProps {
  tourType: ToursTypesEnum;
  sequences: TourSequence[];
  onClick: () => void;
  onClose: () => void;
  isOpen: boolean;
  timeMinutes?: number;
}
function ToursDialogCardExpandable({
  tourType,
  sequences,
  onClick,
  onClose,
  isOpen,
  timeMinutes,
}: ToursDialogCardExpandableProps) {
  const ripple = useRipple();
  const { getTourState } = useTours();
  const stepsCompleted = useMemo(() => {
    const tourState = getTourState(tourType);
    return tourState.sequenceStates.filter((s) => s.completed).length;
  }, [getTourState, tourType]);

  const stepsText = useMemo(() => {
    if (!sequences.length) return '';
    if (stepsCompleted !== sequences.length && stepsCompleted > 0)
      return `STEP ${stepsCompleted} OF ${sequences.length}`;
    return `${sequences.length} STEP${sequences.length !== 1 ? 'S' : ''}`;
  }, [sequences.length, stepsCompleted]);

  const timeText = useMemo(() => (timeMinutes ? `, ~${timeMinutes} MIN` : ''), [
    timeMinutes,
  ]);

  return (
    <div
      className="flex flex-row items-end justify-start text-gray-400 hover:text-gray-100 hover:bg-gray-600 px-4 pb-2 cursor-pointer select-none w-full"
      onMouseUp={ripple}
      onClick={onClick}
    >
      <span className="text-xs flex-1 text-left">
        {stepsText}
        {timeText}
      </span>
      <TourStartContinueButton tourType={tourType} onClose={onClose} />
      {isOpen ? <Up className="pt-1" /> : <Down className="pt-1" />}
    </div>
  );
}

interface ToursDialogCardProps {
  tourData: TourData;
  tourType: ToursTypesEnum;
  onClose: () => void;
}
function ToursDialogCard({
  tourData: { description, sequences, timeMinutes },
  tourType,
  onClose,
}: ToursDialogCardProps) {
  const [isOpen, setOpen] = useState(false);
  const onClick = useCallback(() => setOpen(!isOpen), [isOpen]);
  const { getTourState } = useTours();
  const percentComplete = useMemo(() => {
    const tourState = getTourState(tourType);
    const completed = tourState.sequenceStates.filter((s) => s.completed)
      .length;
    return Math.round((completed / sequences.length) * 100);
  }, [getTourState, sequences.length, tourType]);

  return (
    <div className="flex flex-col bg-gray-700 min-h-[6rem] rounded-md overflow-hidden">
      <StyledTourLinearProgress variant="determinate" value={percentComplete} />
      <div className="flex flex-col w-full h-full flex-1">
        <ToursDialogCardInformation
          description={description}
          tourType={tourType}
        />
        <ToursDialogCardExpandable
          sequences={sequences}
          onClick={onClick}
          isOpen={isOpen}
          tourType={tourType}
          onClose={onClose}
          timeMinutes={timeMinutes}
        />
      </div>
      <div className={clsx(isOpen ? 'flex flex-col w-full gap-3' : 'hidden')}>
        <ToursDialogSequences
          sequences={sequences}
          tourType={tourType}
          onClose={onClose}
        />
      </div>
    </div>
  );
}

interface TourDialogToursProps {
  onClose: () => void;
}
function TourDialogTours({ onClose }: TourDialogToursProps) {
  const { pageTours, toggleCategoryIsExpanded, openCategories } = useTours();

  const toursByCategory = useMemo(() => {
    return Object.entries(pageTours).reduce((acc, [type, tour]) => {
      const categoryName = tour.category?.name || '';
      if (!acc[categoryName]) {
        acc[categoryName] = [];
      }
      acc[categoryName].push([type, tour]);
      return acc;
    }, {} as Record<string, [string, TourData][]>);
  }, [pageTours]);

  const uncategorizedTours = toursByCategory[''] || [];
  const categorizedTours = Object.entries(toursByCategory).filter(
    ([categoryName]) => categoryName !== ''
  );

  return (
    <div className="flex flex-col gap-3 px-2">
      {uncategorizedTours.length > 0 && (
        <div className="flex flex-col gap-3">
          {uncategorizedTours.map(([type, tour]) => (
            <ToursDialogCard
              tourData={tour}
              key={type}
              tourType={type as ToursTypesEnum}
              onClose={onClose}
            />
          ))}
        </div>
      )}
      {categorizedTours.map(([categoryName, tours]) => {
        const isOpen = openCategories[categoryName] ?? true;
        return (
          <div key={categoryName}>
            <div
              className="flex flex-row pb-2 justify-center items-center gap-2 cursor-pointer"
              onClick={() => toggleCategoryIsExpanded(categoryName)}
            >
              <div className="flex-grow">
                <Divider className="w-full" />
              </div>
              <div className="flex flex-row items-center justify-center align-middle gap-2">
                <div className="flex flex-shrink-0 border h-5 w-5 justify-center items-center rounded-md mt-[0.05rem]">
                  {isOpen ? <Up /> : <Down />}
                </div>
                {tours[0][1].category?.icon && (
                  <div className="flex-shrink-0 -mt-[0.05rem]">
                    {tours[0][1].category?.icon}
                  </div>
                )}
                <div className="flex-shrink-0">
                  <span className="text-sm font-semibold uppercase">
                    {categoryName}
                  </span>
                </div>
              </div>
              <div className="flex-grow">
                <Divider className="w-full" />
              </div>
            </div>
            {isOpen && (
              <div className="flex flex-col gap-3">
                {tours.map(([type, tour]) => (
                  <ToursDialogCard
                    tourData={tour}
                    key={type}
                    tourType={type as ToursTypesEnum}
                    onClose={onClose}
                  />
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

interface TourDialogHeaderProps {
  onClose: () => void;
}
function TourDialogHeader({ onClose }: TourDialogHeaderProps) {
  const { toursState } = useTours();

  const totalSequences = useMemo(
    () =>
      toursState.reduce(
        (acc, { sequenceStates }) => acc + sequenceStates.length,
        0
      ),
    [toursState]
  );

  const totalPercentCompleted = useMemo(() => {
    const completedSequences = toursState.reduce(
      (acc, { sequenceStates }) =>
        acc + sequenceStates.filter((s) => s.completed).length,
      0
    );
    return totalSequences === 0
      ? 0
      : Math.round((completedSequences / totalSequences) * 100);
  }, [totalSequences, toursState]);

  return (
    <div className="flex flex-col w-full bg-gray-700 pt-2 px-2 pb-3 gap-2 align-bottom">
      <div className="flex w-full justify-end">
        <XCloseIcon2
          onClick={onClose}
          className="text-base cursor-pointer hover:text-gray-100 transition-colors text-gray-400"
        />
      </div>
      {totalSequences === 0 ? (
        <div className="flex w-full justify-center items-center">
          <span className="text-sm">No tours available on this page</span>
        </div>
      ) : (
        <>
          <div className="flex w-full justify-between items-end">
            <span className="font-bold">Walkthroughs</span>
            <span className="text-sm">{totalPercentCompleted}%</span>
          </div>
          <StyledTourLinearProgress
            variant="determinate"
            value={totalPercentCompleted}
            className="!h-[0.4rem] rounded-md"
          />
        </>
      )}
    </div>
  );
}

interface TourStartContinueButtonProps {
  tourType: ToursTypesEnum;
  onClose: () => void;
}
function TourStartContinueButton({
  tourType,
  onClose,
}: TourStartContinueButtonProps) {
  const { getTourState, selectTour } = useTours();
  const startedTour = useMemo(
    () => getTourState(tourType).sequenceStates.some((s) => s.completed),
    [getTourState, tourType]
  );
  const completedTour = useMemo(
    () => getTourState(tourType).sequenceStates.every((s) => s.completed),
    [getTourState, tourType]
  );
  const onClick = useCallback(() => {
    let index = 0;
    if (startedTour) {
      index = getTourState(tourType).sequenceStates.findIndex(
        (s) => !s.completed
      );
    }
    selectTour(tourType, index);
    onClose();
  }, [getTourState, onClose, selectTour, startedTour, tourType]);

  if (completedTour) {
    return null;
  }
  return (
    <Button
      variant="inverted-gray"
      className="flex !h-4 mr-2 uppercase pt-1 !border-white"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <span className="!text-xs !text-white pt-[0.3rem]">
        {startedTour ? 'continue' : 'let`s see'}
      </span>
    </Button>
  );
}
