import { useCallback, useMemo } from 'react';
import { SessionRunData } from '@tensorleap/api-client';

import { useVersionControl } from '../../../core/VersionControlContext';

export function useSelectedSessionRuns(): SessionRunData[] {
  const { selectedSessionRunMap: selectedModelsMap } = useVersionControl();
  const selectedSessionRuns = useMemo(
    () => Array.from(selectedModelsMap.values()),
    [selectedModelsMap]
  );

  return selectedSessionRuns;
}

export interface UseEpochRange {
  getEpochRange: (modelId: string) => number[];
  getLastEpoch: (modelId: string) => number;
}

export function useEpochRange(): UseEpochRange {
  const { getSessionRunEpochs } = useVersionControl();

  const getEpochRange = useCallback(
    (modelId: string) => {
      if (!modelId) {
        return [];
      }
      return getSessionRunEpochs(modelId);
    },
    [getSessionRunEpochs]
  );

  const getLastEpoch = useCallback(
    (modelId: string) => {
      if (!modelId) {
        return 0;
      }
      const epochRange = getEpochRange(modelId);
      return epochRange[epochRange.length - 1];
    },
    [getEpochRange]
  );

  return { getEpochRange, getLastEpoch };
}
