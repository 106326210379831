import { useEffect } from 'react';
import { throttle } from 'lodash';

import api from './api-client';

const BROWSER_INTERACTION_EVENTS: (keyof GlobalEventHandlersEventMap)[] = [
  'keydown',
  'click',
];
let hasInit = false;

export function useServerWarmup() {
  useEffect(() => {
    if (!hasInit) {
      const warmupServers = throttle(() => {
        api.warmup();
      }, 1_000 * 60 * 10 /* 10 minutes */);

      BROWSER_INTERACTION_EVENTS.forEach((eventName) => {
        window.addEventListener(eventName, warmupServers);
      });

      hasInit = true;

      return () => {
        BROWSER_INTERACTION_EVENTS.forEach((eventName) => {
          window.removeEventListener(eventName, warmupServers);
        });
        warmupServers.cancel();
        hasInit = false;
      };
    }
  }, []);
}
