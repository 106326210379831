import { TestPieChart, TestResult } from '../ui/atoms/TestPieChart';
import {
  SessionRunSummaryData,
  ModelSummaryPlaceholder,
  TabModelSummary,
} from '../ui/molecules/TabModelSummary';

export type TestModelSummaryData = SessionRunSummaryData & {
  testResult: TestResult;
};

export type OnEpochChange = (sessionRunId: string, epoch: number) => void;

export type ModelTestSummaryProps = TestModelSummaryData & {
  onEpochChange: OnEpochChange;
  className?: string;
};

export function ModelTestSummary({
  testResult,
  ...tabModelSummaryProps
}: ModelTestSummaryProps) {
  return (
    <TabModelSummary {...tabModelSummaryProps}>
      <TestPieChart variant="lg" {...testResult} />
    </TabModelSummary>
  );
}

export type ModelTestSummaryPlacehoderProps = {
  testsCount: number;
  className?: string;
};

export function ModelTestSummaryPlacehoder({
  className,
  testsCount: testCount,
}: ModelTestSummaryPlacehoderProps) {
  return (
    <ModelSummaryPlaceholder className={className}>
      <div className="w-28 h-28 rounded-full border-[11px] border-gray-450 flex items-center justify-center">
        <span className="text-gray-300">{testCount}</span>
      </div>
    </ModelSummaryPlaceholder>
  );
}
