import { IconButton, Tooltip } from '@material-ui/core';
import { SessionRunData } from '@tensorleap/api-client';
import { useVersionControl } from '../core/VersionControlContext';
import { RemDb, AddDb } from '../ui/icons';
import clsx from 'clsx';

export interface AddToDashboardToggleProps {
  sessionRun?: SessionRunData;
}

export function AddToDashboardToggle({
  sessionRun,
}: AddToDashboardToggleProps): JSX.Element | null {
  const { toggleSelectSessionRun, isSessionRunSelected } = useVersionControl();

  let title = '';
  if (!sessionRun?.cid) {
    title = 'Evaluate to show visualizations';
  } else if (isSessionRunSelected?.(sessionRun.cid)) {
    title = 'Remove from dashboard';
  } else {
    title = 'Add to dashboard';
  }
  const isDisabled = !sessionRun?.weightAssets.length;

  return (
    <Tooltip title={title} placement="right">
      <IconButton
        className="h-10 w-10 top-px"
        onClick={() => !isDisabled && toggleSelectSessionRun(sessionRun.cid)}
      >
        {isSessionRunSelected?.(sessionRun?.cid) ? (
          <RemDb
            className={clsx(isDisabled ? 'text-gray-700' : 'text-primary-500')}
          />
        ) : (
          <AddDb
            className={clsx(isDisabled ? 'text-gray-700' : 'text-gray-300')}
          />
        )}
      </IconButton>
    </Tooltip>
  );
}
