import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgOpenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <g filter="url(#open-icon_svg__b)" clipPath="url(#open-icon_svg__a)">
      <path
        d="m9.688 9.188.03 7.124a.688.688 0 0 1-.218.5.636.636 0 0 1-.469.188.789.789 0 0 1-.531-.188.746.746 0 0 1-.188-.5V9.157l-2.03 2.031c-.334.292-.667.292-1 0-.292-.333-.292-.666 0-1L8.5 7a.747.747 0 0 1 .5-.188c.188 0 .344.063.469.188l3.187 3.188c.334.333.334.666 0 1a.636.636 0 0 1-.469.187.831.831 0 0 1-.5-.188l-2-2ZM17 2.688V13.28a.723.723 0 0 1-.219.531.592.592 0 0 1-.468.22H12.03a.747.747 0 0 1-.5-.188.747.747 0 0 1-.187-.5c0-.188.062-.344.187-.469a.689.689 0 0 1 .5-.219h3.563v-8.25H2.406v8.25h3.625c.188 0 .344.073.469.219a.592.592 0 0 1 .219.469.689.689 0 0 1-.219.5.636.636 0 0 1-.469.187H1.688a.688.688 0 0 1-.5-.219.788.788 0 0 1-.188-.53V2.686c0-.187.063-.343.188-.468a.688.688 0 0 1 .5-.219h14.624c.188 0 .344.073.47.219a.592.592 0 0 1 .218.469Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="open-icon_svg__a">
        <path fill="currentColor" d="M0 0h18v18H0z" />
      </clipPath>
      <filter
        id="open-icon_svg__b"
        x={-1}
        y={2}
        width={20}
        height={19}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_16596_168866"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_16596_168866"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SvgOpenIcon;
