import { useCallback, useMemo } from 'react';

import { Switch } from '../ui/atoms/Switch';
import { useFeatureFlags, FeatureFlagRecord } from './FeatureFlagsContext';

export function normalizeName(name: string): string {
  return name
    .replaceAll('_', ' ')
    .replace(/([a-z])([A-Z])/g, (_, p1, p2) => `${p1} ${p2}`);
}

function useFeatureFlagDisplayName(flagName: string): string {
  return useMemo(() => normalizeName(flagName), [flagName]);
}

type FlagProps = {
  name: string;
  value: boolean;
  setValue: (_: boolean) => void;
};
function Flag({ name, value, setValue }: FlagProps) {
  const label = useFeatureFlagDisplayName(name);
  const toggle = useCallback((v: boolean) => setValue(v), [setValue]);

  return (
    <li className="flex">
      <h2 className="p-2 flex-1 capitalize">{label}</h2>
      <Switch value={value} onClick={toggle} />
    </li>
  );
}

export function FeatureFlagsPage() {
  const { featureFlags, setFeatureFlag } = useFeatureFlags();

  return (
    <div className="flex flex-col w-full h-full items-center">
      <main className="flex overflow-hidden flex-1 flex-col shadow-md w-[500px] border border-gray-700 p-4 my-4 rounded">
        <h1 className="text-lg p-2 uppercase">flags</h1>
        <ul className="flex-1 overflow-auto">
          {Object.entries(featureFlags).map(([name, value]) => (
            <Flag
              key={name}
              value={!!value}
              name={name}
              setValue={(v) =>
                setFeatureFlag(name as keyof FeatureFlagRecord, v)
              }
            />
          ))}
        </ul>
      </main>
    </div>
  );
}

export default FeatureFlagsPage;
