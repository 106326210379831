import clsx from 'clsx';
import { forwardRef, PropsWithChildren } from 'react';
import { InputContainer } from './utils/InputContainer';
import { InputNote } from './utils/InputNote';
import { useInputHasValueTracker } from './utils/useInputHasValueTracker';

type HTMLTextAreaProps = React.HTMLProps<HTMLTextAreaElement>;

export type TextAreaProps = {
  name?: string;
  label?: string;
  type?: HTMLTextAreaProps['type'];
  onChange: HTMLTextAreaProps['onChange'];
  onBlur?: HTMLTextAreaProps['onBlur'];
  onFocus?: HTMLTextAreaProps['onFocus'];
  value?: HTMLTextAreaProps['value'];
  containerClassName?: string;
  clean?: boolean;
  className?: string;
  outlineClasses?: string;
  min?: string | number;
  max?: string | number;
  maxLength?: number;
  minLength?: number;
  pattern?: string;
  required?: boolean;
  disabled?: boolean;
  error?: string;
  info?: string;
  containerProps?: string;
  hideScroll?: boolean;
  resizable?: boolean;
  noOutline?: boolean;
};

const TEXTAREA_CLASSES = [
  'w-full',
  'h-full',
  'bg-transparent',
  'leading-normal',
  'shadow-none',
  'focus:ring-0',
  'disabled:text-gray-500 text-gray-200',
  'px-3 pt-2.5 pb-1.5',
  'outline-none',
  'focus:outline-none',
  'text-base',
  'peer',
].join(' ');

export const TextArea = forwardRef<
  HTMLTextAreaElement,
  PropsWithChildren<TextAreaProps>
>(
  (
    {
      label,
      error,
      info,
      clean = false,
      className,
      outlineClasses,
      hideScroll,
      resizable,
      onChange,
      containerClassName,
      value,
      ...inputProps
    },
    ref
  ) => {
    const {
      onChangeHandler,
      inputHasValue,
      wrappedRef,
    } = useInputHasValueTracker(value, onChange, ref);

    return (
      <div className={className}>
        <InputContainer
          clean={clean}
          disabled={inputProps.disabled}
          error={!!error}
          label={label || ''}
          className={clsx(outlineClasses, 'h-full w-full', containerClassName)}
          inputHasValue={inputHasValue}
        >
          <textarea
            {...inputProps}
            onChange={onChangeHandler}
            placeholder={clean ? label : ''}
            ref={wrappedRef}
            value={value}
            className={clsx(
              { ['overflow-hidden']: hideScroll },
              { ['resize-none']: !resizable },
              TEXTAREA_CLASSES
            )}
          />
        </InputContainer>
        <InputNote error>{error}</InputNote>
        <InputNote info>{info}</InputNote>
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';
