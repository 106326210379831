import { ReactNode, useEffect, useMemo, useState } from 'react';
import { InsightsPushMessage, SlimVersion } from '@tensorleap/api-client';
import { DrawerSideTab } from '../ui/atoms/DrawerSideTab';
import { LightBulbOffIcon, LightBulbOnIcon } from '../ui/icons';
import {
  SelectedSessionRun,
  useModelFilter,
} from '../ui/molecules/useModelFilter';
import { InsightsDrawerContent } from './InsightDrawerContent';
import { usePushNotifications } from '../core/PushNotificationsContext';
import { isInsightsPushMessage } from '../core/websocket-message-types';
import { useVersionControl } from '../core/VersionControlContext';
import { KeyedMutator } from 'swr';
import { InsightCard } from './InsightCard';
import { useInsightsContext } from './InsightsContext';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';

export type InsightsDashboardComponents = {
  insightMenuOption: ReactNode;
  insightDrawerPopup: ReactNode;
  insightDrawerContent: ReactNode;
};

export function useInsightsComponents(
  selectedInsightDrawerTab: boolean,
  onMenuClick: () => void
): InsightsDashboardComponents {
  const { selected: selectedSessionRuns } = useModelFilter();
  const { refetch: versionControlRefetch } = useVersionControl();
  const lastInsightsPushNotification = useLastInsightsPushNotification();
  const { insightBySessionRunAndEpoch, insightCount } = useInsightsContext();

  const insightMenuOption = useInsightsDrawerButton(
    !!insightCount,
    selectedInsightDrawerTab,
    onMenuClick
  );
  const insightDrawerPopup = useInsightsPopup(
    selectedInsightDrawerTab,
    selectedSessionRuns,
    versionControlRefetch,
    lastInsightsPushNotification
  );

  const insightDrawerContent = useMemo(
    () => <InsightsDrawerContent data={insightBySessionRunAndEpoch} />,
    [insightBySessionRunAndEpoch]
  );

  return { insightMenuOption, insightDrawerPopup, insightDrawerContent };
}

function useInsightsDrawerButton(
  insightsExist: boolean,
  selected: boolean,
  onClick: () => void
): ReactNode {
  return (
    <DrawerSideTab
      onClick={onClick}
      selected={selected}
      icon={
        insightsExist ? (
          <LightBulbOnIcon className="rotate-180 h-auto w-3/4 text-insight-500" />
        ) : (
          <LightBulbOffIcon className="rotate-180 h-auto w-3/4" />
        )
      }
      buttonColor="yellow"
      tourId={TOUR_SELECTORS_ENUM.INSIGHTS_PANEL_BUTTON_ID}
    >
      insights
    </DrawerSideTab>
  );
}

function useInsightsPopup(
  selected: boolean,
  selectedModels: SelectedSessionRun[],
  versionControlRefetch: KeyedMutator<SlimVersion[]>,
  lastInsightPushNotification?: InsightsPushMessage
): ReactNode {
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    setShowPopup(true);
    versionControlRefetch();
  }, [versionControlRefetch]);

  const popup = useMemo(() => {
    if (!lastInsightPushNotification?.sessionRunId) return null;

    const popupMessageModel = selectedModels.find(
      ({ id }) => id === lastInsightPushNotification.sessionRunId
    );

    const popupMessage = `Discovered ${lastInsightPushNotification?.insights.length} new insights`;

    return (
      popupMessageModel &&
      !selected &&
      showPopup && (
        <InsightCard
          title="new insights"
          body={<span>{popupMessage}</span>}
          asPopup
          onClose={() => setShowPopup(false)}
        />
      )
    );
  }, [lastInsightPushNotification, selected, selectedModels, showPopup]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setShowPopup(false);
    }, 10_000);
    return () => {
      clearTimeout(timerId);
    };
  }, [popup]);

  return popup;
}

function useLastInsightsPushNotification(): InsightsPushMessage | undefined {
  const { lastServerMessage } = usePushNotifications();
  const [
    lastInsightsPushNotification,
    setLastInsightsPushNotification,
  ] = useState<InsightsPushMessage>();

  useEffect(() => {
    if (
      isInsightsPushMessage(lastServerMessage) &&
      lastServerMessage?.insights?.length > 0
    ) {
      setLastInsightsPushNotification(lastServerMessage as InsightsPushMessage);
    }
  }, [lastServerMessage]);

  return lastInsightsPushNotification;
}
