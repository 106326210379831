import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { ReactNode } from 'react';

export type LoadingVisualizationPreviewProps = {
  title?: ReactNode;
  className?: string;
};

export function LoadingVisualizationPreview({
  className,
  title,
}: LoadingVisualizationPreviewProps) {
  return (
    <div className={clsx('flex h-full w-full flex-col p-2', className)}>
      <span className="font-normal text-xs leading-relaxed">{title}</span>
      <div className="flex-1 flex flex-col items-center justify-center">
        <CircularProgress />
        <span className="p-4 font-normal text-base tracking-normal">
          Loading...
        </span>
      </div>
    </div>
  );
}
