import { sum } from 'lodash';

export const CHIP_COLOR_TEMPLATES: string[] = [
  'bg-colorful0-900 border-colorful0-700 hover:bg-colorful0-800',
  'bg-colorful1-900 border-colorful1-700 hover:bg-colorful1-800',
  'bg-colorful2-900 border-colorful2-700 hover:bg-colorful2-800',
  'bg-colorful3-900 border-colorful3-700 hover:bg-colorful3-800',
  'bg-colorful4-900 border-colorful4-700 hover:bg-colorful4-800',
  'bg-colorful5-900 border-colorful5-700 hover:bg-colorful5-800',
  'bg-colorful6-900 border-colorful6-700 hover:bg-colorful6-800',
  'bg-colorful7-900 border-colorful7-700 hover:bg-colorful7-800',
  'bg-colorful8-900 border-colorful8-700 hover:bg-colorful8-800',
  'bg-colorful9-900 border-colorful9-700 hover:bg-colorful9-800',
  'bg-colorful10-900 border-colorful10-700 hover:bg-colorful10-800',
];

export const TEXT_COLOR_TEMPLATES: string[] = [
  'text-colorful0-500',
  'text-colorful1-500',
  'text-colorful2-500',
  'text-colorful3-500',
  'text-colorful4-500',
  'text-colorful5-500',
  'text-colorful6-500',
  'text-colorful7-500',
  'text-colorful8-500',
  'text-colorful9-500',
  'text-colorful10-500',
];

export const BORDER_COLOR_TEMPLATES: string[] = [
  'border-colorful0-500',
  'border-colorful1-500',
  'border-colorful2-500',
  'border-colorful3-500',
  'border-colorful4-500',
  'border-colorful5-500',
  'border-colorful6-500',
  'border-colorful7-500',
  'border-colorful8-500',
  'border-colorful9-500',
  'border-colorful10-500',
];

export const BG_COLOR_TEMPLATES: string[] = [
  'bg-colorful0-500',
  'bg-colorful1-500',
  'bg-colorful2-500',
  'bg-colorful3-500',
  'bg-colorful4-500',
  'bg-colorful5-500',
  'bg-colorful6-500',
  'bg-colorful7-500',
  'bg-colorful8-500',
  'bg-colorful9-500',
  'bg-colorful10-500',
];

export const BG_900_COLOR_TEMPLATES: string[] = [
  'bg-colorful0-900',
  'bg-colorful1-900',
  'bg-colorful2-900',
  'bg-colorful3-900',
  'bg-colorful4-900',
  'bg-colorful5-900',
  'bg-colorful6-900',
  'bg-colorful7-900',
  'bg-colorful8-900',
  'bg-colorful9-900',
  'bg-colorful10-900',
];

export const GENTLE_BG_COLOR_TEMPLATES: string[] = [
  'bg-colorful0-900/20',
  'bg-colorful1-900/20',
  'bg-colorful2-900/20',
  'bg-colorful3-900/20',
  'bg-colorful4-900/20',
  'bg-colorful5-900/20',
  'bg-colorful6-900/20',
  'bg-colorful7-900/20',
  'bg-colorful8-900/20',
  'bg-colorful9-900/20',
  'bg-colorful10-900/20',
];

export const BG_COLOR_TEMPLATES_HEX: string[] = [
  '#F43F5E',
  '#F97316',
  '#0EA5E9',
  '#3B82F6',
  '#6366F1',
  '#EC4899',
  '#A855F7',
  '#8B5CF6',
  '#D946EF',
  '#84CC16',
  '#06B6D4',
];

export function getConstColorByString(value: string): string {
  return CHIP_COLOR_TEMPLATES[
    (sum(value.split('').map((c) => c.charCodeAt(0))) %
      CHIP_COLOR_TEMPLATES.length) -
      1
  ];
}
