import { DatasetVersion } from '@tensorleap/api-client';
import { Node } from '@tensorleap/engine-contract';
import { isCustomLayerNode } from '../utils';

export function isInputsNode(node: Node): boolean {
  return (
    node.data?.type?.toLowerCase() === 'dataset' ||
    node.name === 'Dataset' ||
    node.name === 'DatasetStub'
  );
}

export function isInputNode(node: Node): boolean {
  return node.data.type === 'Input';
}

export function isUnselectedInputNode(
  node: Node,
  selectedCodeIntegrationVersion: DatasetVersion | undefined
): boolean {
  if (selectedCodeIntegrationVersion === undefined) return false;

  const inputsNames = (
    selectedCodeIntegrationVersion.metadata.setup?.inputs || []
  ).map(({ name }) => name);

  return (
    (isInputNode(node) || isInputsNode(node)) &&
    !inputsNames.includes(node.data.output_name)
  );
}

export const isUnselectedCustomLayerNode = (node: Node): boolean => {
  return isCustomLayerNode(node) && !node.data?.selected;
};

export function isLayerNode(node: Node): boolean {
  return (
    node.data.type === 'Layer' ||
    node.data.type === 'CustomLayer' ||
    // the type `layer` is missing for Representation Block
    node.name === 'Representation Block'
  );
}

export function isVisualizerNode(node: Node): boolean {
  return node.data.type === 'Visualizer';
}

export function isOptimizerNode(node: Node): boolean {
  return node.data.type === 'Optimizer';
}

export function isCustomlossNode(node: Node): boolean {
  return node.data.type === 'CustomLoss';
}

export const isKindOfLossNode = (n: Node): boolean =>
  isLossNode(n) || isCustomlossNode(n);

export function isLossNode(node: Node): boolean {
  const type = node.data?.type?.toLowerCase();
  return type === 'loss' || type === 'customloss';
}

export function isCustomMetricNode(node: Node): boolean {
  const type = node.data?.type?.toLowerCase();
  return type === 'metric';
}

export function isGroundTruthNode(node: Node): boolean {
  return (
    node.data?.type?.toLowerCase() === 'datasetoutput' ||
    node.name === 'DatasetOutput' ||
    node.name === 'GroundTruth'
  );
}

export function isRequiredUniqeNameNode(node: Node): boolean {
  return (
    isVisualizerNode(node) || isCustomlossNode(node) || isCustomMetricNode(node)
  );
}
