/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider } from '@material-ui/core';
import { useController } from 'react-hook-form';
import { Select } from '../../../../ui/atoms/Select';
import { DashletFormProps } from '../types';
import { first, uniq } from 'lodash';
import {
  SelectDistributionField,
  SelectOrderByField,
  SelectOrderField,
  InputSizeIntervalField,
  DisplayOnlyLastEpoch,
  AutoScaleY,
  AbsAxis,
  Split,
  ShowPercentage,
  ShowAllAveragePrecisions,
  FlipcolorRange,
  ConfusionMatrixLabelSelector,
  SplitByLabel,
} from './FormFields';
import { ConfusionMatrixParams, splitPositions } from './utils';
import { ToggleButtonGroup } from '../../../../ui/atoms/ToggleButtonGroup';
import { useEffect, useMemo } from 'react';
import { NO_SPLIT_SELECTED } from '../ElasticVis/utils';
import { Input } from '../../../../ui/atoms/Input';
import clsx from 'clsx';

export enum ConfusionMatrixTypeEnum {
  F1 = 'F1',
  BalancedAccuracy = 'BALANCED ACCURACY',
  PrCurve = 'PR CURVE',
  Roc = 'ROC',
  ConfusionMatrixTable = 'CONFUSION MATRIX TABLE',
  MeanAveragePrecision = 'MEAN AVERAGE PRECISION',
  ConfusionMatrixByLabelVis = 'CONFUSION MATRIX BY LABEL',
}

export type ConfusionMatrixType = `${ConfusionMatrixTypeEnum}`;

const CONFUSION_MATRIX_OPTIONS: ConfusionMatrixType[] = Object.values(
  ConfusionMatrixTypeEnum
);

export function ConfusionMatrixForm(
  props: DashletFormProps<ConfusionMatrixParams>
): JSX.Element {
  const dashletFields = props.fields;

  const {
    fields,
    metricNames,
    defaultAggregatableField = '',
    form: { control },
  } = props;

  const {
    field: { ref: _xAxisRef, ...xAxisField },
  } = useController({
    control,
    name: 'data.xAxis',
    defaultValue: defaultAggregatableField,
  });

  const {
    field: { ref: _metricNameRef, ...metricNameField },
  } = useController({
    control,
    name: 'data.metricName',
    defaultValue: first(metricNames) || '',
  });

  const {
    field: { ref: _thresholdRef, ...thresholdField },
  } = useController({
    control,
    name: 'data.threshold',
  });

  const {
    field: { ref: _type, ...typeField },
  } = useController({
    control,
    name: 'data.type',
    defaultValue: ConfusionMatrixTypeEnum.ConfusionMatrixTable,
  });

  const {
    field: { ref: _modelIdPosition, ...modelIdPositionField },
  } = useController({
    control,
    name: 'data.modelIdPosition',
    defaultValue: 'vertical',
  });

  const {
    field: { ref: _firstSplit, ...firstSplitField },
  } = useController({
    control,
    name: 'data.firstSplit',
    defaultValue: { field: NO_SPLIT_SELECTED },
  });

  const {
    field: { ref: _secondSplit, ...secondSplitField },
  } = useController({
    control,
    name: 'data.secondSplit',
    defaultValue: { field: NO_SPLIT_SELECTED },
  });

  const { aggregation, dataDistribution } = props.form.getValues()?.data || {};

  const firstSplitLabel = useMemo(
    () =>
      modelIdPositionField.value === 'vertical' ? 'horizontal' : 'vertical',
    [modelIdPositionField.value]
  );
  const secondSplitLabel = useMemo(
    () => `${modelIdPositionField.value === 'inner' ? 'horizontal' : 'inner'}`,
    [modelIdPositionField.value]
  );

  const predictionNameOptions = useMemo(() => {
    if (!metricNameField.value) return metricNames;
    return uniq(metricNames.concat(metricNameField.value));
  }, [metricNameField.value, metricNames]);

  const isConfusionMatrixTable =
    typeField.value === ConfusionMatrixTypeEnum.ConfusionMatrixTable;

  const isConfusionMatrixTableByLabel =
    typeField.value === ConfusionMatrixTypeEnum.ConfusionMatrixByLabelVis;

  const isF1 = typeField.value === ConfusionMatrixTypeEnum.F1;

  const isMeanAveragePrecision =
    typeField.value === ConfusionMatrixTypeEnum.MeanAveragePrecision;

  const isBalancedAccuracyOrF1OrMap =
    typeField.value === ConfusionMatrixTypeEnum.BalancedAccuracy ||
    isF1 ||
    isMeanAveragePrecision;

  const isShowInnerSplit = isF1;

  const filteredSplitOptions = Object.values(splitPositions).filter(
    (x) => x !== 'inner' || isShowInnerSplit
  );

  useEffect(() => {
    const isModelPositionIsInnerButNotAllow =
      !isShowInnerSplit && modelIdPositionField.value === 'inner';
    if (isModelPositionIsInnerButNotAllow) {
      modelIdPositionField.onChange('horizontal');
    }
  }, [isShowInnerSplit, modelIdPositionField]);

  // Not use if statement on input fields it will failed on required confusion metric validation and not saving value when user switch between confusion matrix type
  return (
    <div className="flex flex-col gap-6 overflow-x-hidden p-2">
      <Select
        label="TYPE"
        value={typeField.value}
        className="flex-1 mt-2"
        onChange={typeField.onChange}
        options={CONFUSION_MATRIX_OPTIONS}
      />
      <Divider orientation="horizontal" />
      <Select
        label="PREDICTION NAME"
        options={predictionNameOptions}
        className="flex-1"
        {...metricNameField}
      />

      <div className={isBalancedAccuracyOrF1OrMap ? 'contents' : 'hidden'}>
        <Divider orientation="horizontal" />

        <Select
          label="X-AXIS"
          options={fields.aggregatableFields}
          inactiveOptions={fields.notActiveFields}
          className="flex-1"
          {...xAxisField}
        />

        <SelectDistributionField {...props} axisField={xAxisField.value} />

        <SelectOrderByField
          {...props}
          fieldName={'Avg Y Value'}
          aggregation={aggregation}
          dataDistribution={dataDistribution}
        />

        <SelectOrderField {...props} dataDistribution={dataDistribution} />

        <InputSizeIntervalField
          {...props}
          dataDistribution={dataDistribution}
          name="xAxisSizeInterval"
        />

        <DisplayOnlyLastEpoch {...(props as DashletFormProps<any>)} />
      </div>

      <div
        className={
          !isMeanAveragePrecision &&
          !isConfusionMatrixTable &&
          !isConfusionMatrixTableByLabel
            ? 'contents'
            : 'hidden'
        }
      >
        <AutoScaleY {...(props as DashletFormProps<any>)} />
      </div>

      <div className={isMeanAveragePrecision ? 'contents' : 'hidden'}>
        <ShowAllAveragePrecisions {...(props as DashletFormProps<any>)} />
      </div>

      <div
        className={clsx(
          'flex flex-row w-full h-fit',
          isConfusionMatrixTable || isConfusionMatrixTableByLabel
            ? 'contents'
            : 'hidden'
        )}
      >
        <ConfusionMatrixLabelSelector
          selectedConfusionMatrixKey={metricNameField.value}
          {...(props as DashletFormProps<any>)}
        />
      </div>
      <div
        className={clsx(
          'flex flex-row w-full h-fit',
          isConfusionMatrixTableByLabel ? 'contents' : 'hidden'
        )}
      >
        <FlipcolorRange {...(props as DashletFormProps<any>)} />
      </div>
      <div
        className={clsx(
          'flex flex-row w-full h-fit',
          isConfusionMatrixTable ? 'contents' : 'hidden'
        )}
      >
        <SplitByLabel {...(props as DashletFormProps<any>)} />
      </div>

      <div
        className={clsx(
          'flex flex-row w-full h-fit',
          isConfusionMatrixTable || isConfusionMatrixTableByLabel
            ? 'contents'
            : 'hidden'
        )}
      >
        <ShowPercentage {...(props as DashletFormProps<any>)} />
        <div>
          <Input
            label="Threshold (default: max probability)"
            {...thresholdField}
            type="number"
            step="0.1"
          />
        </div>
      </div>

      <div className={typeField.value === 'ROC' ? 'contents' : 'hidden'}>
        <AbsAxis {...(props as DashletFormProps<any>)} />
      </div>
      <Divider orientation="horizontal" />
      <span className="pr-3">
        Split By Model:
        <ToggleButtonGroup
          className="pl-2"
          options={filteredSplitOptions}
          {...modelIdPositionField}
        />
      </span>
      <div
        className={
          typeField.value !== ConfusionMatrixTypeEnum.ConfusionMatrixTable ||
          props.form.getValues()?.data?.splitByLabel !== true
            ? 'contents'
            : 'hidden'
        }
      >
        <Split
          label={`${firstSplitLabel} split`}
          dashletFields={dashletFields}
          {...firstSplitField}
        />
      </div>

      {isShowInnerSplit && (
        <Split
          label={`${secondSplitLabel} split`}
          dashletFields={dashletFields}
          {...secondSplitField}
        />
      )}
    </div>
  );
}
