import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgTrain2Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 16.65a7.65 7.65 0 1 0 0-15.3 7.65 7.65 0 0 0 0 15.3ZM9 18A9 9 0 1 0 9 0a9 9 0 0 0 0 18Z"
      fill="currentColor"
    />
    <path
      d="M6.893 14.46c-.034-.034.113-.428.44-1.184.614-1.412.946-2.176.996-2.294.336-.756.496-1.167.479-1.234-.008-.05-.403-.24-1.184-.567-.773-.328-1.185-.559-1.235-.693-.042-.101.31-.605 1.058-1.512a73.147 73.147 0 0 1 2.268-2.634c.395-.437.689-.752.882-.945.202-.201.311-.294.328-.277.05.034-.26.819-.933 2.356-.672 1.529-1 2.306-.982 2.331.008.034.407.214 1.197.542.772.328 1.18.567 1.222.718.042.168-.689 1.122-2.193 2.86-1.503 1.74-2.284 2.583-2.343 2.533Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTrain2Icon;
