import { useScatterData } from './ScatterDataContext';
import { VisualizationFilter } from '../core/types/filters';

export interface LabelsLegendData {
  filters: VisualizationFilter[];
}
export function useLabelsLegendData() {
  const {
    scatterData: { metadata = {}, scatter_data: scatterData },
    settings: { dotColor },
  } = useScatterData();

  if (!dotColor) {
    console.error(
      'calcLabelsLegendData called with no dotColor, we should not get here'
    );
    return new Map<string | number, number>([['None', 0]]);
  }

  const dotColorSubjectMetadata = metadata[dotColor];
  if (dotColorSubjectMetadata.type !== 'labels') {
    throw new Error('calcLabelsLegendData called with non-labels data');
  }

  const filteredData = Array.from(
    { length: scatterData.length },
    (_, i) => i
  ).map((sampleIndex) => metadata[dotColor].body[sampleIndex]);

  const labelsAppearances = new Map<string | number, number>();
  for (const label of filteredData) {
    const appearance = labelsAppearances.get(label);
    labelsAppearances.set(label, appearance ? appearance + 1 : 1);
  }
  return labelsAppearances;
}
