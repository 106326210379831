import { MoreMenu } from '../../../ui/atoms/MoreMenu';
import {
  Duplicate,
  EnterFullScreenIcon,
  ExitFullScreenIcon,
  Trash,
} from '../../../ui/icons';
import { ActionButton } from '../../../ui/molecules/ActionButton';

export interface TopBarRightButtonsProps {
  remove: () => void;
  duplicate: () => void;
  fullScreenMode: boolean;
  toggleFullScreenMode: () => void;
}

export function TopBarRightButtons({
  remove,
  duplicate,
  fullScreenMode,
  toggleFullScreenMode,
}: TopBarRightButtonsProps) {
  return (
    <div className="flex flex-row items-center">
      <MoreMenu>
        <ActionButton
          icon={
            fullScreenMode ? <ExitFullScreenIcon /> : <EnterFullScreenIcon />
          }
          onRun={toggleFullScreenMode}
        >
          {fullScreenMode ? 'Exit Full Screen [Esc]' : 'Full Screen'}
        </ActionButton>

        <ActionButton icon={<Duplicate />} onRun={duplicate}>
          Duplicate
        </ActionButton>

        <ActionButton icon={<Trash />} onRun={remove}>
          Delete
        </ActionButton>
      </MoreMenu>
    </div>
  );
}
