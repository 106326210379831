import { AllSessionsTestResults, SessionTest } from '@tensorleap/api-client';
import clsx from 'clsx';
import { Divider } from '../ui/mui';
import { ModelTestsCardContentCondition } from './ModelTestsCardContentCondition';
import { DatasetFiltersController } from './DatasetFiltersController';
import { ModelTestsCardContentTestResults } from './ModelTestsCardContentTestResults';
import { UseFormReturn } from 'react-hook-form';
import { ModelTestsFormProps } from './ModelTestsCard';
import { ModelTestsCardContentNameChange } from './ModelTestsCardContentNameChange';

export interface ModelTestsCardContentProps {
  modelTest: SessionTest;
  modelTestsResults?: AllSessionsTestResults;
  className?: string;
  editable: boolean;
  form: UseFormReturn<ModelTestsFormProps, unknown>;
}

export function ModelTestsCardContent({
  modelTest,
  modelTestsResults,
  form,
  className,
  editable,
}: ModelTestsCardContentProps): JSX.Element {
  return (
    <div
      className={clsx(
        'flex flex-col justify-start items-start h-fit w-full bg-black',
        className
      )}
    >
      <ModelTestsCardContentNameChange editable={editable} form={form} />
      <DatasetFiltersController
        modelTest={modelTest}
        editable={editable}
        form={form}
      />
      <Divider className="w-full bg-gray-800" />
      <ModelTestsCardContentCondition
        modelTest={modelTest}
        editable={editable}
        form={form}
      />
      <Divider className="w-full bg-gray-800" />
      <ModelTestsCardContentTestResults modelTestsResults={modelTestsResults} />
    </div>
  );
}
