import api from '../../../core/api-client';
import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { SelectedSessionRun } from '../../../ui/molecules/useModelFilter';
import { SampleVisualizations } from '../SampleAnalysis/useSampleListState';
import { NoSampleSelected } from './NoSampleSelected';
import { VisAssetMenu } from './VisAssetMenu';
import {
  isZoomSupported,
  MetadataMap,
  VisAssetWithIdentity,
  VisDataTypes,
  VisPayloadElements,
  VisPayloadType,
} from './visDataHelpers';
import { AssetVisDisplay } from './AssetVisDisplay';
import { Divider } from '../../Divider';
import { ContainerWrapperType } from './types';
import {
  Job,
  SampleAnalysisAlgo,
  SampleIdentity,
} from '@tensorleap/api-client';
import { MousePosition } from '../../../core/useSelectionGroup';
import { TOUR_SELECTORS_ENUM } from '../../../tour/ToursConfig';
import { createFieldHelper } from '../../../ui/model-list/utils';
import { ModelFields } from '../../../ui/model-list/types';
import { Table } from '../../../ui/model-list/table/Table';
import { sort, useSort } from '../../../ui/model-list/sorter';
import { VisualizeIcon, XCloseIcon2 } from '../../../ui/icons';
import { Tooltip } from '../../../ui/mui';
import { LoadMoreSamplesState } from '../../../core/data-fetching/fullVisualizations';
import { CircularProgress } from '@material-ui/core';
import { useCurrentProject } from '../../../core/CurrentProjectContext';
import { useEpochVisualizationState } from '../common/useEpochVisualization';
import { ALGO_LOCAL_STORAGE_KEY } from '../../../ui/atoms/AlgoSelect';
import { useLocalStorage } from '../../../core/useLocalStorage';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { SampleAnalysisPreviewContainer } from '../../SampleAnalysisView/SampleAnalysisPreviewWrapper';
import { ZoomButtons } from '../../../ui/molecules/ZoomButtons';

const LOADING_DIV_HEIGHT = 48;
export interface SampleVisDisplayProps {
  activeSamples: SampleVisualizations[];
  selectedSessionRuns: SelectedSessionRun[];
  visPayloadElements?: VisPayloadElements;
  containerType: ContainerWrapperType;
  analyzeSample?: () => Promise<Job | undefined>;
  mousePosition: MousePosition;
  selectedPayloadType: VisPayloadType;
  setSelectedPayloadType: (payloadType: VisPayloadType) => void;
  removeSample?: (sampleToRemove: SampleIdentity) => void;
  deselectSample: (sample: SampleIdentity) => void;
  showAsGallery: boolean;
  loadMoreSamplesState?: LoadMoreSamplesState;
}

export function SampleVisDisplay({
  activeSamples,
  selectedSessionRuns,
  visPayloadElements,
  containerType,
  mousePosition,
  selectedPayloadType,
  setSelectedPayloadType,
  removeSample,
  deselectSample,
  showAsGallery,
  loadMoreSamplesState,
}: SampleVisDisplayProps): JSX.Element {
  const [selectedGroupId, setSelectedGroupId] = useState<string>();

  const selectedVisElements = useMemo(() => {
    return getVisPayloadElements({
      visPayloadElements,
      selectedPayloadType,
      selectedGroupId,
    });
  }, [visPayloadElements, selectedPayloadType, selectedGroupId]);

  if (!activeSamples || activeSamples.length === 0) {
    return <NoSampleSelected />;
  }

  return (
    <div
      className="relative flex flex-row w-full h-full overflow-hidden"
      id={TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_DASHLET_LOADED_CONTENT_ID}
    >
      <div className="flex flex-row h-full w-full overflow-hidden">
        <VisAssetMenu
          visPayloadElements={visPayloadElements}
          selectedVisElement={selectedVisElements}
          selectedPayloadType={selectedPayloadType}
          setSelectedPayloadType={setSelectedPayloadType}
          selectedGroupId={selectedGroupId}
          setSelectedGroupId={setSelectedGroupId}
        />
        {selectedVisElements && (
          <VisualizationDisplayContent
            selectedSessionRuns={selectedSessionRuns}
            activeSamples={activeSamples}
            selectedPayloadType={selectedPayloadType}
            selectedGroupId={selectedGroupId}
            selectedVisElements={selectedVisElements}
            containerType={containerType}
            mousePosition={mousePosition}
            removeSample={removeSample}
            deselectSample={deselectSample}
            showAsGallery={showAsGallery}
            loadMoreSamplesState={loadMoreSamplesState}
          />
        )}
      </div>
    </div>
  );
}

interface VisualizationDisplayContentProps {
  selectedSessionRuns: SelectedSessionRun[];
  activeSamples: SampleVisualizations[];
  selectedPayloadType: VisPayloadType;
  selectedGroupId: string | undefined;
  selectedVisElements: Record<string, VisAssetWithIdentity[]>;
  containerType: ContainerWrapperType;
  mousePosition: MousePosition;
  removeSample?: (sampleToRemove: SampleIdentity) => void;
  deselectSample: (sample: SampleIdentity) => void;
  showAsGallery: boolean;
  loadMoreSamplesState?: LoadMoreSamplesState;
}

function VisualizationDisplayContent({
  selectedSessionRuns,
  activeSamples,
  selectedPayloadType,
  selectedVisElements,
  containerType,
  mousePosition,
  removeSample,
  deselectSample,
  showAsGallery,
  loadMoreSamplesState,
}: VisualizationDisplayContentProps): JSX.Element {
  if (activeSamples.length === 0) {
    return <NoSampleSelected />;
  }

  if (
    activeSamples.length > 1 &&
    selectedPayloadType === VisPayloadType.Metadata
  ) {
    return (
      <RenderMultiSampleMetadataTable
        selectedSessionRuns={selectedSessionRuns}
        selectedPayloadType={selectedPayloadType}
        selectedVisElements={selectedVisElements}
      />
    );
  }

  if (
    showAsGallery &&
    activeSamples.length > 1 &&
    selectedPayloadType !== VisPayloadType.Metadata
  ) {
    return (
      <RenderGallerySamples
        selectedSessionRuns={selectedSessionRuns}
        selectedPayloadType={selectedPayloadType}
        selectedVisElements={selectedVisElements}
        mousePosition={mousePosition}
        removeSample={removeSample}
        deselectSample={deselectSample}
        loadMoreSamplesState={loadMoreSamplesState}
      />
    );
  }

  return (
    <div className="flex flex-col w-full h-full overflow-y-auto overflow-x-hidden">
      {activeSamples.map((activeSample, index) => (
        <RenderManySamples
          key={index}
          activeSample={activeSample}
          selectedSessionRuns={selectedSessionRuns}
          selectedPayloadType={selectedPayloadType}
          selectedVisElements={selectedVisElements}
          containerType={containerType}
          mousePosition={mousePosition}
        />
      ))}
    </div>
  );
}

interface RenderManySampleProps {
  activeSample: SampleVisualizations;
  selectedSessionRuns: SelectedSessionRun[];
  selectedPayloadType: VisPayloadType;
  selectedVisElements: Record<string, VisAssetWithIdentity[]>;
  containerType: ContainerWrapperType;
  mousePosition: MousePosition;
}

function RenderManySamples({
  activeSample,
  selectedSessionRuns,
  selectedPayloadType,
  selectedVisElements,
  containerType,
  mousePosition,
}: RenderManySampleProps): JSX.Element {
  return (
    <div className="flex h-full w-full overflow-x-auto overflow-y-hidden min-h-[300px]">
      <div className="flex flex-row w-full h-full overflow-hidden">
        {selectedSessionRuns.map((sessionRun, index) => (
          <RenderSingleSample
            key={index}
            activeSample={activeSample}
            sessionRun={sessionRun}
            selectedPayloadType={selectedPayloadType}
            selectedVisElements={selectedVisElements[sessionRun.id] || []}
            containerType={containerType}
            mousePosition={mousePosition}
          />
        ))}
      </div>
    </div>
  );
}

interface RenderSingleSampleProps {
  activeSample: SampleVisualizations;
  sessionRun: SelectedSessionRun;
  selectedPayloadType: VisPayloadType;
  selectedVisElements: VisAssetWithIdentity[];
  containerType: ContainerWrapperType;
  mousePosition: MousePosition;
}

function RenderSingleSample({
  activeSample,
  sessionRun,
  selectedPayloadType,
  selectedVisElements,
  containerType,
  mousePosition,
}: RenderSingleSampleProps): JSX.Element {
  const { fetchValidProjectCid } = useCurrentProject();
  const projectId = fetchValidProjectCid();

  const [selectedAlgo, setSelectedAlgo] = useLocalStorage<SampleAnalysisAlgo>(
    ALGO_LOCAL_STORAGE_KEY,
    SampleAnalysisAlgo.FocusLayerCam
  );

  const sessionRunId = sessionRun.id;

  const sampleIdentity = activeSample.id;

  const slimVisualizations =
    activeSample.slimVisualizationsPerSessionId[sessionRunId];

  const [inProcessEpochs, setInProcessEpochs] = useState<number[]>([]);

  const upgradeAnalyze = useCallback(
    async (epoch: number) => {
      if (!projectId || !sessionRun.id || !sampleIdentity) return;
      try {
        setInProcessEpochs((prev) => [...prev, epoch]);
        await api.sampleAnalysis({
          projectId,
          sessionRunId: sessionRun.id,
          sampleIdentity,
          fromEpoch: epoch,
          algo: selectedAlgo,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [projectId, sessionRun.id, sampleIdentity, setInProcessEpochs, selectedAlgo]
  );

  const sampleVisElements = useMemo(() => {
    return selectedVisElements
      .filter(
        (element) =>
          element.sampleIdentity.index === sampleIdentity.index &&
          element.sampleIdentity.state === sampleIdentity.state
      )
      .reverse();
  }, [selectedVisElements, sampleIdentity]);

  const epochVisualizationState = useEpochVisualizationState({
    sessionRunId: sessionRun.id,
    slimVisualizations,
    upgrade: upgradeAnalyze,
    inProcessEpochs,
  });

  const selectedVisElement = useMemo(() => {
    const selectedIndex = epochVisualizationState.epochsOptions.findIndex(
      (epoch) =>
        epoch.slimVisualization?.cid ===
        epochVisualizationState.selectedEpoch.slimVisualization?.cid
    );
    return sampleVisElements[selectedIndex];
  }, [epochVisualizationState, sampleVisElements]);

  const key = useMemo(() => {
    return `${sessionRunId}-${sampleIdentity.index}-${sampleIdentity.state}`;
  }, [sessionRunId, sampleIdentity.index, sampleIdentity.state]);

  return (
    <TransformWrapper
      wheel={{
        activationKeys: ['Control'],
      }}
    >
      <div className="flex flex-row min-h-0 h-full w-full">
        <TransformComponent>
          {/* This is a hack to manipulate the zoom package. without this it will crash */}
          <span />
        </TransformComponent>
        <SampleAnalysisPreviewContainer
          key={key}
          type={containerType}
          sessionRun={sessionRun}
          selectedVisElement={selectedVisElement}
          epochVisualizationState={epochVisualizationState}
          upgradeAnalyze={upgradeAnalyze}
          selectedAlgo={selectedAlgo}
          setSelectedAlgo={setSelectedAlgo}
        >
          {selectedVisElement ? (
            <AssetVisDisplay
              visType={selectedPayloadType}
              selectedVisElement={selectedVisElement}
              mousePosition={mousePosition}
            />
          ) : (
            <span />
          )}
        </SampleAnalysisPreviewContainer>

        <Divider vertical className="!m-0 !bg-gray-800" />
      </div>
    </TransformWrapper>
  );
}

interface RenderGallerySamples {
  selectedSessionRuns: SelectedSessionRun[];
  selectedPayloadType: VisPayloadType;
  selectedVisElements: Record<string, VisAssetWithIdentity[]>;
  mousePosition: MousePosition;
  removeSample?: (sampleToRemove: SampleIdentity) => void;
  deselectSample: (sample: SampleIdentity) => void;
  loadMoreSamplesState?: LoadMoreSamplesState;
}

function RenderGallerySamples({
  selectedSessionRuns,
  selectedPayloadType,
  selectedVisElements,
  mousePosition,
  removeSample,
  deselectSample,
  loadMoreSamplesState,
}: RenderGallerySamples): JSX.Element {
  if (selectedPayloadType === VisPayloadType.Metadata) {
    console.error(
      "RenderGallerySamples Can't render multiple samples for metadata, we shouldnt get here"
    );
    return <span />;
  }

  return (
    <div className="flex flex-row w-full h-full overflow-auto">
      {selectedSessionRuns.map((sessionRun) => {
        const sessionRunId = sessionRun.id;
        const elements = selectedVisElements[sessionRunId] || [];
        return (
          <div key={sessionRunId} className="flex flex-col w-full h-full">
            <RenderGallery
              elements={elements}
              sessionRun={sessionRun}
              selectedPayloadType={selectedPayloadType}
              mousePosition={mousePosition}
              removeSample={removeSample}
              deselectSample={deselectSample}
              loadMoreSamplesState={loadMoreSamplesState}
            />
          </div>
        );
      })}
    </div>
  );
}

interface RenderMultiSampleMetadataTableProps {
  selectedSessionRuns: SelectedSessionRun[];
  selectedPayloadType: VisPayloadType;
  selectedVisElements: Record<string, VisAssetWithIdentity[]>;
}

export function RenderMultiSampleMetadataTable({
  selectedSessionRuns,
  selectedVisElements,
}: RenderMultiSampleMetadataTableProps): JSX.Element {
  const { field } = createFieldHelper<Record<string, string>>();

  const formatLabel = (label: string): string => {
    return label
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const { data, fields } = useMemo(() => {
    const allMetadataKeys = new Set<string>();
    const data: Record<string, string>[] = [];

    selectedSessionRuns.forEach((sessionRun) => {
      const sessionRunId = sessionRun.id;
      const elements = selectedVisElements[sessionRunId] || [];

      elements.forEach((element) => {
        const metadata = element.data as MetadataMap;
        const rowData: Record<string, string> = {
          sessionRunName: sessionRun.name,
          sampleIndex: element.sampleIdentity.index.toString(),
          sampleState: element.sampleIdentity.state,
        };

        Object.entries(metadata).forEach(([key, value]) => {
          allMetadataKeys.add(key);
          rowData[key] = value;
        });

        data.push(rowData);
      });
    });

    const fields: ModelFields<
      Record<string, string>
    > = (selectedSessionRuns.length > 1
      ? [
          field('sessionRunName' as keyof Record<string, string>, {
            label: 'Session Run',
            sortable: true,
            table: { width: 'fit-content' },
          }),
        ]
      : []
    ).concat([
      field('sampleIndex' as keyof Record<string, string>, {
        label: 'Sample Index',
        sortable: true,
        table: { width: 'fit-content' },
      }),
      field('sampleState' as keyof Record<string, string>, {
        label: 'Sample State',
        sortable: true,
        table: { width: 'fit-content' },
      }),
      ...Array.from(allMetadataKeys)
        .filter(metaDataTableBlackListFilter)
        .map((key) =>
          field(key as keyof Record<string, string>, {
            label: formatLabel(key),
            sortable: true,
            table: { width: 'fit-content' },
          })
        ),
    ]);

    return { data, fields };
  }, [field, selectedSessionRuns, selectedVisElements]);

  const sorter = useSort(fields);
  const sortedData = useMemo(() => sort(data, sorter.sortBy), [
    data,
    sorter.sortBy,
  ]);

  return (
    <div className="w-full h-full overflow-auto">
      <Table
        fields={fields}
        data={sortedData}
        sorter={sorter}
        fixedLayout={false}
        className="w-full"
      />
    </div>
  );
}

interface RenderGalleryProps {
  elements: VisAssetWithIdentity[];
  sessionRun: SelectedSessionRun;
  selectedPayloadType: VisPayloadType;
  mousePosition: MousePosition;
  removeSample?: (sampleToRemove: SampleIdentity) => void;
  deselectSample: (sample: SampleIdentity) => void;
  loadMoreSamplesState?: LoadMoreSamplesState;
}

interface GalleryItemProps {
  element: VisAssetWithIdentity;
  itemSize: number;
  selectedPayloadType: VisPayloadType;
  mousePosition: MousePosition;
  removeSample?: (sampleToRemove: SampleIdentity) => void;
  deselectSample: (sample: SampleIdentity) => void;
}

const GalleryItem: React.FC<GalleryItemProps> = React.memo(
  ({
    element,
    itemSize,
    selectedPayloadType,
    mousePosition,
    removeSample,
    deselectSample,
  }) => (
    <div
      className="flex flex-col"
      style={{
        width: `${itemSize}px`,
        height: `${itemSize}px`,
        flexBasis: `${itemSize}px`,
        flexGrow: 0,
        flexShrink: 0,
        boxSizing: 'border-box',
      }}
    >
      <div className="flex justify-start items-center font-bold uppercase mb-2 px-2">
        <span>{`${element.sampleIdentity.index} - ${element.sampleIdentity.state}`}</span>
        <div className="flex flex-row gap-1 ml-auto">
          {isZoomSupported(element.data.data as VisDataTypes) && (
            <ZoomButtons />
          )}
          <Tooltip title="Hide sample">
            <div
              onClick={() => deselectSample(element.sampleIdentity)}
              className="focus:outline-none cursor-pointer"
            >
              <VisualizeIcon className="w-5 h-5 text-gray-400 hover:text-white" />
            </div>
          </Tooltip>
          {removeSample && (
            <Tooltip title="Remove sample">
              <div
                onClick={() => removeSample(element.sampleIdentity)}
                className="focus:outline-none cursor-pointer"
              >
                <XCloseIcon2 className="w-5 h-5 text-gray-400 hover:text-white" />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <AssetVisDisplay
        visType={selectedPayloadType}
        selectedVisElement={element}
        mousePosition={mousePosition}
      />
    </div>
  )
);

GalleryItem.displayName = 'GalleryItem';

const RenderGallery: React.FC<RenderGalleryProps> = ({
  elements,
  selectedPayloadType,
  mousePosition,
  removeSample,
  deselectSample,
  loadMoreSamplesState,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [layout, setLayout] = useState<{ columns: number; itemSize: number }>({
    columns: 1,
    itemSize: 320,
  });

  const updateLayout = useCallback(() => {
    const currentContainerRef = containerRef.current;
    if (currentContainerRef) {
      const containerWidth = currentContainerRef.clientWidth;
      const minItemSize = 320;
      const itemCount = elements.length;
      const gap = 8;
      const padding = 32;
      const availableWidth = containerWidth - padding;

      const layoutFits = (cols: number, size: number) => {
        return size * cols + gap * (cols - 1) <= availableWidth;
      };

      const calculateOptimalColumns = () => {
        let cols = Math.min(
          itemCount,
          Math.floor(availableWidth / minItemSize)
        );
        let itemSize = Math.floor((availableWidth - gap * (cols - 1)) / cols);

        while (!layoutFits(cols, itemSize) && cols > 1) {
          cols--;
          itemSize = Math.floor((availableWidth - gap * (cols - 1)) / cols);
        }

        itemSize = Math.max(itemSize, minItemSize);

        if (cols === 2) {
          const twoItemSize = Math.floor((availableWidth - gap) / 2);
          if (layoutFits(2, twoItemSize)) {
            itemSize = twoItemSize;
          }
        }

        return { columns: cols, itemSize };
      };

      const { columns, itemSize } = calculateOptimalColumns();

      if (columns !== layout.columns || itemSize !== layout.itemSize) {
        setLayout({ columns, itemSize });
      }
    }
  }, [elements.length, layout.columns, layout.itemSize]);

  useEffect(() => {
    const debouncedUpdateLayout = debounce(updateLayout, 200);

    const resizeObserver = new ResizeObserver(() => {
      debouncedUpdateLayout();
    });

    const currentContainerRef = containerRef.current;

    if (currentContainerRef) {
      resizeObserver.observe(currentContainerRef);
    }

    updateLayout();

    return () => {
      if (currentContainerRef) {
        resizeObserver.unobserve(currentContainerRef);
      }
      resizeObserver.disconnect();
    };
  }, [updateLayout]);

  const handleScroll: React.UIEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const target = event.target as HTMLDivElement;
      if (
        loadMoreSamplesState &&
        loadMoreSamplesState.allowLoadMore &&
        !loadMoreSamplesState.isLoading &&
        target.scrollHeight - target.scrollTop - LOADING_DIV_HEIGHT <
          target.clientHeight
      ) {
        loadMoreSamplesState.handleLoadMoreSamples();
      }
    },
    [loadMoreSamplesState]
  );

  return (
    <div
      className="flex flex-col gap-8 scroll-auto overflow-auto"
      onScroll={handleScroll}
    >
      <div
        ref={containerRef}
        className="flex flex-wrap content-start max-w-full gap-1"
      >
        {elements.map((element) => (
          <TransformWrapper
            wheel={{ activationKeys: ['Control'] }}
            key={`element-${element.sampleIdentity.index}-${element.sampleIdentity.state}`}
          >
            <div className="flex">
              <TransformComponent>
                {/* This is a hack to manipulate the zoom package. without this it will crash */}
                <span />
              </TransformComponent>
              <GalleryItem
                element={element}
                itemSize={layout.itemSize}
                selectedPayloadType={selectedPayloadType}
                mousePosition={mousePosition}
                removeSample={removeSample}
                deselectSample={deselectSample}
              />
            </div>
          </TransformWrapper>
        ))}
      </div>

      {loadMoreSamplesState && loadMoreSamplesState.isLoading && (
        <div
          className="flex justify-center items-center w-full pb-8"
          style={{ height: `${LOADING_DIV_HEIGHT}px` }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

interface GetVisPayloadElementsParams {
  visPayloadElements?: VisPayloadElements;
  selectedPayloadType: VisPayloadType;
  selectedGroupId?: string;
}

function getVisPayloadElements({
  visPayloadElements,
  selectedPayloadType,
  selectedGroupId,
}: GetVisPayloadElementsParams):
  | Record<string, VisAssetWithIdentity[]>
  | undefined {
  if (!visPayloadElements) {
    return undefined;
  }
  if (selectedPayloadType === VisPayloadType.Metadata || !selectedGroupId) {
    return visPayloadElements[VisPayloadType.Metadata];
  }
  return visPayloadElements[selectedPayloadType]?.[selectedGroupId];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | null = null;
  return (...args: Parameters<T>) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

export default SampleVisDisplay;

export function metaDataTableBlackListFilter(value: string): boolean {
  return value !== 'dataset_version_id';
}
