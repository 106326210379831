import useSWR, { KeyedMutator } from 'swr';
import { DatasetVersion } from '@tensorleap/api-client';

import api from '../api-client';
import { useMergedObject } from '../useMergedObject';
import { CacheKey, REFRESH_INTERVALS } from './consts';
import { useEffect } from 'react';

export interface UseDatasetVersions {
  datasetVersions: DatasetVersion[];
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<DatasetVersion[]>;
}

export interface UseDatasetVersionsProps {
  datasetId?: string;
}

export function useFetchDatasetVersions({
  datasetId,
}: UseDatasetVersionsProps): UseDatasetVersions {
  const cacheKey = `${CacheKey.DATASET_VERSIONS}${datasetId}`;
  const { data: datasetVersions, error, mutate } = useSWR<
    DatasetVersion[],
    Error
  >(
    cacheKey,
    async () => {
      if (!datasetId) return [];

      const { datasetVersions } = await api.getDatasetVersions({ datasetId });
      return datasetVersions;
    },
    {
      refreshInterval: REFRESH_INTERVALS.datasetVersions,
    }
  );

  // Solve case when datasetId change but SWR doesn't refetch
  useEffect(() => {
    mutate();
  }, [datasetId, mutate]);

  return useMergedObject({
    datasetVersions: datasetVersions || [],
    error,
    isLoading: !error && !datasetVersions,
    refetch: mutate,
  });
}
