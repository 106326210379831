import { useMemo } from 'react';
import {
  createStyles,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Popover,
  PopoverProps,
} from '../ui/mui';
import { ThreeDotsIcon } from './icons';
import {
  usePopupState,
  bindPopover,
  bindTrigger,
} from 'material-ui-popup-state/hooks';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
  createStyles({
    versionSubmenuList: {
      '& > *': {
        border: `1px solid ${theme.palette.grey[600]}`,
      },
    },
  })
);

export interface ThreeDotsMenuOption {
  title: string;
  onClick: () => void;
}

export interface ThreeDotsMenuProps {
  buttonClass?: string;
  options: ThreeDotsMenuOption[];
  onMenuOpen?: (isMenuOpen: boolean) => void;
  onMenuClose?: (isMenuOpen: boolean) => void;
}
export function ThreeDotsMenu({
  buttonClass,
  options,
  onMenuOpen,
  onMenuClose,
}: ThreeDotsMenuProps): JSX.Element {
  const classes = useStyles();

  const popoverState = usePopupState({ variant: 'popover', popupId: null });

  const triggerProps = useMemo(() => {
    const { onClick, ...otherProps } = bindTrigger(popoverState);
    return {
      ...otherProps,
      onClick: (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        onMenuOpen?.(true);
        onClick(event);
      },
    };
  }, [popoverState, onMenuOpen]);

  const popoverProps = useMemo<PopoverProps>(() => {
    const { onClose, ...otherProps } = bindPopover(popoverState);
    return {
      ...otherProps,
      classes: {
        paper: 'bg-gray-800 w-80',
      },
      onClose: (event: React.MouseEvent<HTMLElement>) => {
        onMenuClose?.(false);
        event.preventDefault();
        event.stopPropagation();
        onClose();
      },
      anchorOrigin: {
        vertical: 'center',
        horizontal: 'right',
      },
    };
  }, [popoverState, onMenuClose]);

  const wrappedOptions = useMemo(
    () =>
      options.map(({ onClick, ...rest }) => ({
        ...rest,
        onClick: (event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
          event.preventDefault();
          popoverState.close();
          onClick();
        },
      })),
    [options, popoverState]
  );

  return (
    <>
      <IconButton {...triggerProps} size="small">
        <ThreeDotsIcon className={buttonClass} />
      </IconButton>
      <Popover {...popoverProps}>
        <List className={clsx('p-0', classes.versionSubmenuList)}>
          {wrappedOptions.map(({ title, onClick }, index) => (
            <ListItem key={index} component="li" button onClick={onClick}>
              <h6 className="font-semibold text-lg tracking-normal">{title}</h6>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
}
