import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="m12.084 16.049 4.576-3.674-4.576-3.674v2.75h-8.25V13.3h8.25v2.75Zm6.402-11.924h-8.25c-.501 0-.93.179-1.289.537a1.758 1.758 0 0 0-.537 1.29v3.673h1.826V5.951h8.25V18.8h-8.25v-3.674H8.41v3.674c0 .501.18.93.537 1.289.358.358.788.537 1.29.537h8.25c.515 0 .952-.179 1.31-.537.358-.358.537-.788.537-1.29V5.952c0-.501-.18-.93-.537-1.289a1.783 1.783 0 0 0-1.31-.537Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLogout;
