import { Collapse } from '@material-ui/core';
import {
  AllSessionsTestResults,
  ESFilter,
  SessionTest,
  ClientFilterParams,
} from '@tensorleap/api-client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ModelTestsCardContent } from './ModelTestsCardContent';
import { ModelTestsCardTitle } from './ModelTestsCardTitle';
import { clone } from 'lodash';
import clsx from 'clsx';
import { BaseModelTestProps } from './useModelTests';

export type ModelTestsCardProps = BaseModelTestProps & {
  modelTest: SessionTest;
  modelTestsResults?: AllSessionsTestResults;
  className?: string;
  sortedTests: SessionTest[];
};

export interface ModelTestsFormProps {
  cid: string;
  projectId: string;
  name?: string;
  testFilter: ClientFilterParams;
  datasetFilter: ESFilter[];
}

export function ModelTestsCard({
  modelTest,
  modelTestsResults,
  className,
  selectedTestId,
  toggleSelectedTestId,
  editedTestState,
  onStartEditable,
  onStopEditable,
  onDelete,
  onUpdate,
  sortedTests,
}: ModelTestsCardProps): JSX.Element {
  const isSelected = selectedTestId === modelTest.cid;
  const isOpen = isSelected;
  const editable = isSelected && !!editedTestState;
  const isAnotherCardEditable = !editable && !!editedTestState;
  const [, setIsFormReset] = useState(false);
  const editableRef = useRef(editable);
  editableRef.current = editable;

  const form = useForm<ModelTestsFormProps>({
    mode: 'onChange',
  });

  const { reset, handleSubmit } = form;
  const ref = useRef<HTMLDivElement>(null);

  const submit = handleSubmit(async (props) => {
    onUpdate(props);
  });

  useEffect(() => {
    return () => {
      // save the form state on component unmounted
      editableRef.current && onStartEditable(form.getValues());
    };
  }, [editableRef, onStartEditable, form]);

  useEffect(() => {
    if (!editable || !editedTestState) {
      return;
    }
    reset(editedTestState);
    setTimeout(() => setIsFormReset(true)); // rerender the componet on reset
  }, [editable, editedTestState, onStartEditable, reset, form]);

  const toggleSelect = useCallback(() => toggleSelectedTestId(modelTest.cid), [
    toggleSelectedTestId,
    modelTest.cid,
  ]);

  const handelStartEditable = useCallback(
    () => onStartEditable(clone(modelTest)),
    [onStartEditable, modelTest]
  );

  const handelStopEditable = useCallback(() => onStopEditable(modelTest.cid), [
    onStopEditable,
    modelTest.cid,
  ]);

  //Tests would cause scroll issue when sorted because of time it took to sort. Json keeps track of the sorted tests order and allows rerendering if order changes
  const sortedTestsJson = JSON.stringify(sortedTests);

  useEffect(() => {
    if (editable) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [editable, sortedTestsJson]);

  return (
    <div
      ref={ref}
      className={clsx(
        'shadow-2xl drop-shadow-xl border-black border-solid border-b-4',
        !editable && 'cursor-pointer',
        className
      )}
      onClick={editable || editedTestState ? undefined : toggleSelect}
    >
      <ModelTestsCardTitle
        disabledEdit={isAnotherCardEditable}
        modelTest={modelTest}
        modelTestsResults={modelTestsResults}
        isOpen={isOpen}
        editable={editable}
        onEdit={handelStartEditable}
        onDelete={onDelete}
        onSubmit={submit}
        onClose={handelStopEditable}
      />

      <Collapse in={isOpen}>
        <ModelTestsCardContent
          editable={editable}
          form={form}
          modelTest={modelTest}
          modelTestsResults={modelTestsResults}
        />
      </Collapse>
    </div>
  );
}
