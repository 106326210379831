import { FilterOperatorType, SessionTest } from '@tensorleap/api-client';
import { useMemo } from 'react';
import { useController, UseFormReturn } from 'react-hook-form';
import { getFormattedModelTestName } from './modelTestHelpers';
import { Input } from '../ui/atoms/Input';
import { Select } from '../ui/atoms/Select';
import { ModelTestsFormProps } from './ModelTestsCard';
import { useFetchDashletFields } from '../core/data-fetching/dashlet-fields';
import { useCurrentProject } from '../core/CurrentProjectContext';

const conditionFilterTypes = [
  FilterOperatorType.GreaterThan,
  FilterOperatorType.LessThan,
  FilterOperatorType.Equal,
];

export interface ModelTestsCardContentConditionProps {
  modelTest: SessionTest;
  editable: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<ModelTestsFormProps, any>;
}

export function ModelTestsCardContentCondition({
  modelTest,
  editable,
  form,
}: ModelTestsCardContentConditionProps): JSX.Element {
  const { fetchValidProjectCid } = useCurrentProject();
  const projectId = fetchValidProjectCid();

  const parsedTitle = useMemo(() => getFormattedModelTestName(modelTest), [
    modelTest,
  ]);

  const { dashletFields } = useFetchDashletFields(projectId, []);
  const { numericFields: metricOptions = [] } = dashletFields || {};

  const {
    register,
    control,
    formState: { errors },
  } = form;

  const { testFilter: testErrors } = errors;

  const { field: metricField } = useController({
    name: 'testFilter.field',
    control,
    rules: {
      required: {
        value: true,
        message: 'Metric is required',
      },
    },
  });

  const { field: operatorField } = useController({
    name: 'testFilter.operator',
    control,
    rules: {
      required: {
        value: true,
        message: 'Operator is required',
      },
    },
  });

  return (
    <div className="w-full px-4">
      <div className="flex flex-col w-full justify-center items-start my-4">
        <div className="flex flex-row w-full justify-center items-start">
          <span className="capitalize text-xl tracking-widest text-gray-500">
            test conditions
          </span>
          <div className="flex-1" />
        </div>
        <div className="h-fit w-full mt-2">
          {editable ? (
            <div className="grid grid-cols-2 w-full h-fit gap-4 my-2">
              <Select
                className="col-span-2"
                label="tested metric"
                options={metricOptions}
                optionToLabel={(x) => x}
                {...metricField}
                error={testErrors?.field && testErrors?.field?.message}
              />
              <Select
                label="testFilter.operator"
                options={conditionFilterTypes}
                optionToLabel={(x) => x.replace('-', ' ').toUpperCase()}
                {...operatorField}
                error={testErrors?.operator && testErrors?.operator?.message}
              />
              <div>
                <Input
                  type="number"
                  label="value"
                  {...register('testFilter.value', {
                    required: {
                      value: true,
                      message: 'Value is required',
                    },
                  })}
                  error={testErrors?.value && testErrors?.value.message}
                />
              </div>
            </div>
          ) : (
            <span className="text-xl capitalize">{parsedTitle}</span>
          )}
        </div>
      </div>
    </div>
  );
}
