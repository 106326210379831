import { useEffect } from 'react';
import { Divider } from '../../ui/mui';
import { DatasetVersionDetails } from './DatasetVersionDetails';
import { useDatasets } from '../../core/DatasetsContext';
import { CodeIntegrationEditor } from './CodeIntegrationEditor';
import { FullScreenEnabler } from '../../ui/atoms/FullScreenDialogEnabler';

export function AssetsCodeView(): JSX.Element {
  const {
    dataset,
    switchDatasetVersion,
    editorDatasetVersion,
    datasetVersions,
    fullScreenMode,
    setFullScreenMode,
  } = useDatasets();

  useEffect(() => {
    if (
      datasetVersions.length &&
      dataset?.cid !== editorDatasetVersion?.datasetId
    ) {
      switchDatasetVersion(datasetVersions[0]);
    }
  }, [
    dataset?.cid,
    datasetVersions,
    editorDatasetVersion?.datasetId,
    switchDatasetVersion,
  ]);

  return (
    <FullScreenEnabler
      fullScreenMode={fullScreenMode}
      exitFullScreenMode={() => setFullScreenMode(false)}
    >
      <div className="h-full w-full flex flex-row-reverse bg-gray-900">
        {/* todo: We have wired situation that cause the hasUnsavedCode to be true even if there is no change need more investigation */}
        {/* <Prompt
          when={hasUnsavedCode}
          message="Leaving will discard your unsaved changes"
        /> */}

        <>
          <Divider orientation="vertical" />
          <DatasetVersionDetails />
        </>

        <CodeIntegrationEditor />
      </div>
    </FullScreenEnabler>
  );
}
