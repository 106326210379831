import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgLinkedinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M17 4v10c0 .82-.295 1.52-.885 2.104-.59.59-1.296.886-2.115.886H4a2.898 2.898 0 0 1-2.125-.886C1.292 15.534 1 14.834 1 14V4c0-.826.292-1.531.875-2.115A2.898 2.898 0 0 1 4 1h10c.82 0 1.524.295 2.115.885.59.59.885 1.296.885 2.115Zm-4.875 6.531v3.865h2.406V10.25c0-.535-.066-1.003-.198-1.406a2.78 2.78 0 0 0-.562-1.021 2.356 2.356 0 0 0-.875-.615A2.978 2.978 0 0 0 11.76 7c-.944 0-1.67.406-2.177 1.219h.021V7.167H7.198c.02.458.02 2.868 0 7.229h2.406v-4.042c0-.27.024-.465.073-.583.104-.243.257-.448.458-.615.209-.173.47-.26.782-.26.805 0 1.208.545 1.208 1.635ZM6.031 4.937a1.231 1.231 0 0 0-.375-.895c-.243-.236-.566-.354-.968-.354-.403 0-.733.118-.99.354a1.18 1.18 0 0 0-.375.896c0 .18.031.347.094.5.07.145.16.277.27.395.119.111.257.198.417.26.167.063.35.095.552.095h.01c.209 0 .396-.032.563-.094a1.26 1.26 0 0 0 .427-.26 1.257 1.257 0 0 0 .375-.896ZM3.47 7.168v7.229h2.406v-7.23H3.469Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLinkedinIcon;
