import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgUnknownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.343 3.343a8 8 0 1 0 11.314 11.314A8 8 0 0 0 3.343 3.343ZM15.4 9A6.4 6.4 0 1 0 2.6 9a6.4 6.4 0 0 0 12.8 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.499 5.9c-.333 0-.629.114-.828.285-.195.167-.27.361-.27.528a.9.9 0 0 1-1.8 0c0-.742.344-1.42.899-1.895A3.079 3.079 0 0 1 8.499 4.1h.57c.728 0 1.45.247 2 .718.548.47.891 1.138.9 1.87a2.613 2.613 0 0 1-1.688 2.567c-.128.069-.282.212-.407.461a1.98 1.98 0 0 0-.19.956.9.9 0 1 1-1.799.076c-.027-.635.1-1.278.38-1.837.278-.557.712-1.034 1.28-1.3a.898.898 0 0 1 .083-.034.813.813 0 0 0 .542-.813.866.866 0 0 1-.002-.05c0-.168-.076-.362-.27-.529-.2-.17-.496-.285-.828-.285h-.571Zm.285 6.194a.9.9 0 0 1 .9.9V13a.9.9 0 1 1-1.8 0v-.006a.9.9 0 0 1 .9-.9Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgUnknownIcon;
