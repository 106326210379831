import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgProblemIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M9 11.057a1.029 1.029 0 1 1 0 2.057 1.029 1.029 0 0 1 0-2.057Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.343 3.343a8 8 0 1 0 11.314 11.314A8 8 0 0 0 3.343 3.343ZM15.4 9A6.4 6.4 0 1 0 2.6 9a6.4 6.4 0 0 0 12.8 0ZM8.2 9a.8.8 0 0 0 1.6 0V4.886a.8.8 0 1 0-1.6 0V9Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgProblemIcon;
