import { Sector, SectorProps } from 'recharts';
import { ReactElement } from 'react';
import { GRAPH_STYLE } from '../../common/constants';

export function ActiveShape({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
}: Pick<
  SectorProps,
  | 'cx'
  | 'cy'
  | 'innerRadius'
  | 'outerRadius'
  | 'startAngle'
  | 'endAngle'
  | 'fill'
>): ReactElement {
  return (
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
      fillOpacity={GRAPH_STYLE.line.strongShowOpacity}
    />
  );
}
