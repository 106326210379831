import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgHand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M14.59 3.33c.126-.03.282-.044.468-.044.73 0 1.358.264 1.886.792.528.529.793 1.157.793 1.887v.19c.788-.045 1.458.193 2.009.714.565.52.848 1.171.848 1.953v5.558a4.851 4.851 0 0 1-.09.926l-.848 4.498a2.453 2.453 0 0 1-.86 1.462c-.453.387-.985.58-1.595.58H9.522c-.446 0-.874-.1-1.283-.301-.402-.201-.737-.48-1.005-.837L2.95 15.005a2.76 2.76 0 0 1-.57-1.719c0-.789.28-1.462.837-2.02a2.753 2.753 0 0 1 2.02-.837c.588 0 1.065.126 1.43.38V5.964c0-.737.26-1.366.78-1.887a2.589 2.589 0 0 1 1.898-.792c.186 0 .342.015.468.045.231-.454.559-.811.983-1.072a2.58 2.58 0 0 1 1.406-.402c.513 0 .982.134 1.406.402.424.26.752.618.982 1.072Zm-3.64 1.206v7.321h-.356V5.965c0-.343-.123-.637-.369-.882a1.203 1.203 0 0 0-.881-.368c-.343 0-.636.122-.882.368-.246.245-.368.54-.368.882v8.75l-1.72-2.288a1.348 1.348 0 0 0-1.137-.57c-.395 0-.733.142-1.016.425-.275.275-.413.61-.413 1.004 0 .313.097.599.29.86l4.286 5.703c.275.379.655.569 1.138.569h7.679c.253 0 .48-.082.68-.246a.992.992 0 0 0 .38-.625l.848-4.509c.023-.119.037-.23.045-.335a4.83 4.83 0 0 0 .011-.323V8.822c0-.343-.123-.636-.368-.882a1.203 1.203 0 0 0-.882-.368c-.342 0-.636.122-.882.368-.245.246-.368.54-.368.882v3.035h-.357V5.965c0-.343-.123-.637-.368-.882a1.203 1.203 0 0 0-.882-.368c-.342 0-.636.122-.882.368-.245.245-.368.54-.368.882v5.892h-.357V4.536c0-.342-.123-.636-.369-.882a1.203 1.203 0 0 0-.881-.368c-.342 0-.636.123-.882.368-.245.246-.368.54-.368.882Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgHand;
