import useSWR, { KeyedMutator } from 'swr';
import { Issue } from '@tensorleap/api-client';
import api from '../api-client';
import { CacheKey } from './consts';
import { useMergedObject } from '../useMergedObject';

export interface UseFetchIssues {
  issues: Issue[] | undefined;
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<Issue[]>;
}

export function useFetchIssues(projectId: string | undefined): UseFetchIssues {
  const { data: issues, error, mutate } = useSWR<Issue[], Error>(
    `${CacheKey.ISSUES}-${projectId}`,
    async () => {
      if (!projectId) {
        return [];
      }
      const { issues } = await api.getProjectIssues({
        projectId,
      });
      return issues;
    }
  );

  return useMergedObject({
    issues,
    error,
    isLoading: !error && !issues,
    refetch: mutate,
  });
}
