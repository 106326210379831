import { useCallback, useEffect, useMemo, useState } from 'react';
import { CircledIcon } from '../../ui/atoms/CircledIcon';
import clsx from 'clsx';
import { Back, Check1, Down, DownSmallIcon, Forward, Up } from '../../ui/icons';
import { useLocalStorageToggle } from '../../core/useToggle';
import { Collapse } from '@material-ui/core';
import { NetworkWizardCategory, WizardCategoryStatus } from './types';
import { NetworkWizardContent } from './NetworkWizardContent';
import { useNetworkWizardContext } from './NetworkWizardContext';

const NetworkWizardCategoryOrder = [
  NetworkWizardCategory.MODEL,
  NetworkWizardCategory.CODE,
  NetworkWizardCategory.INPUTS,
  NetworkWizardCategory.LOSS,
  NetworkWizardCategory.METRICS,
  NetworkWizardCategory.VIS,
];

const CATEGORY_STATUS_COLORS: Record<WizardCategoryStatus, string> = {
  [WizardCategoryStatus.NONE]: '!bg-gray-500',
  [WizardCategoryStatus.OK]: '!bg-secondary-500',
  [WizardCategoryStatus.WARNING]: '!bg-warning-400',
  [WizardCategoryStatus.ERROR]: '!bg-warning-500',
};

const SELECTED_ELEMENT_STYLE = 'border-[2px]';

export function NetworkWizardCategories(): JSX.Element {
  const { allAlerts } = useNetworkWizardContext();

  const firstCategoryWithError = useMemo(
    () =>
      NetworkWizardCategoryOrder[
        Math.min(
          ...allAlerts.map(({ category }) =>
            NetworkWizardCategoryOrder.indexOf(category)
          )
        )
      ],
    [allAlerts]
  );
  const [selectedCategory, setSelectedCategory] = useState<
    NetworkWizardCategory | undefined
  >();

  const [showContent, toggleShowContent] = useLocalStorageToggle(
    'showWizardContentPane',
    false
  );

  const allStatuses = useMemo(() => {
    let dataElementFound = false;

    const statuses = Object.values(NetworkWizardCategory).reduce(
      (acc, type) => {
        if (dataElementFound) {
          acc[type] = WizardCategoryStatus.NONE;
          return acc;
        }
        const exists = allAlerts.some(({ category }) => category === type);
        if (exists) {
          acc[type] = WizardCategoryStatus.ERROR;
          dataElementFound = true;
        } else {
          acc[type] = WizardCategoryStatus.OK;
        }
        return acc;
      },
      {} as Record<NetworkWizardCategory, WizardCategoryStatus>
    );
    return statuses;
  }, [allAlerts]);

  const handleCategoryClick = useCallback(
    (category: NetworkWizardCategory) => {
      if (!allAlerts.length) {
        setSelectedCategory(undefined);
        return;
      }

      if (category === selectedCategory) {
        return;
      } else {
        setSelectedCategory(category);
      }
    },
    [allAlerts.length, selectedCategory]
  );
  const handleArrowClick = useCallback(
    (arrow: 'left' | 'right') => {
      if (!allAlerts.length) {
        setSelectedCategory(undefined);
        return;
      }

      const currentIndex = NetworkWizardCategoryOrder.indexOf(
        selectedCategory as NetworkWizardCategory
      );
      let nextIndex = arrow === 'left' ? currentIndex - 1 : currentIndex + 1;
      if (nextIndex === -1) {
        nextIndex = NetworkWizardCategoryOrder.length - 1;
      } else if (nextIndex === NetworkWizardCategoryOrder.length) {
        nextIndex = 0;
      }
      if (nextIndex >= 0 && nextIndex < NetworkWizardCategoryOrder.length) {
        setSelectedCategory(
          NetworkWizardCategoryOrder[nextIndex] as NetworkWizardCategory
        );
      }
    },
    [allAlerts.length, selectedCategory]
  );

  useEffect(() => {
    if (!allAlerts.length) {
      setSelectedCategory(undefined);
    } else if (
      !selectedCategory ||
      (selectedCategory &&
        NetworkWizardCategoryOrder.indexOf(selectedCategory) <
          NetworkWizardCategoryOrder.indexOf(firstCategoryWithError))
    ) {
      setSelectedCategory(firstCategoryWithError);
    }
  }, [allAlerts, allAlerts.length, firstCategoryWithError, selectedCategory]);

  const allGreen = Object.values(allStatuses).every(
    (status) => status === WizardCategoryStatus.OK
  );

  return (
    <div className="flex flex-col !w-[20rem] px-4 justify-center items-center bg-black bg-opacity-40 rounded-xl h-fit mr-14">
      <div className="flex w-full flex-col h-16">
        <div className="flex flex-row h-full gap-2 justify-center items-center pt-4">
          <WizardSwitchCategoryArrow
            arrowDirection="left"
            handleArrowClick={handleArrowClick}
          />
          <div className="flex flex-row h-full w-full gap-7 justify-center items-start">
            {Object.entries(allStatuses).map(([category, status], index) => (
              <StatusComponent
                key={index}
                selectedCategory={selectedCategory}
                category={category}
                status={status}
                index={index}
                allStatuses={allStatuses}
                allGreen={allGreen}
                handleCategoryClick={handleCategoryClick}
              />
            ))}
          </div>
          <WizardSwitchCategoryArrow
            arrowDirection="right"
            handleArrowClick={handleArrowClick}
          />
        </div>
      </div>
      <Collapse in={showContent}>
        <NetworkWizardContent selectedCategory={selectedCategory} />
      </Collapse>
      <WizardColapseExpandArrow
        showContent={showContent}
        toggleShowContent={toggleShowContent}
      />
    </div>
  );
}

interface StatusComponentProps {
  selectedCategory: NetworkWizardCategory | undefined;
  category: string;
  status: WizardCategoryStatus;
  index: number;
  allStatuses: Record<NetworkWizardCategory, WizardCategoryStatus>;
  allGreen: boolean;
  handleCategoryClick: (category: NetworkWizardCategory) => void;
}
function StatusComponent({
  selectedCategory,
  category,
  status,
  index,
  allStatuses,
  allGreen,
  handleCategoryClick,
}: StatusComponentProps): JSX.Element {
  const categoryIsSelected = selectedCategory === category;

  const circleIconBorderStyle = [
    'border-white',
    CATEGORY_STATUS_COLORS[status],
    ...(categoryIsSelected ? [SELECTED_ELEMENT_STYLE] : []),
  ].join(' ');

  const categoryIndex =
    NetworkWizardCategoryOrder.indexOf(category as NetworkWizardCategory) + 1;

  const addConnectingLine = index !== Object.keys(allStatuses).length - 1;

  return (
    <div
      className="flex relative justify-center items-center cursor-pointer"
      key={index}
    >
      <div
        onClick={() => handleCategoryClick(category as NetworkWizardCategory)}
        className="flex flex-col justify-center items-center relative"
      >
        {!allGreen && categoryIsSelected && (
          <DownSmallIcon className="absolute -top-5 text-gray-300" />
        )}
        <CircledIcon
          className="flex justify-center z-[10] items-center !h-5 !w-5"
          dropShadow
          label={category}
          borderStyle={circleIconBorderStyle}
        >
          <div className="flex w-full h-full justify-center items-center relative select-none">
            {status === WizardCategoryStatus.OK ? (
              <Check1 className="flex h-4 w-4 absolute -top-[0.41rem] -right-[0.47rem] font-bold" />
            ) : (
              <span className="text-xs font-bold select-none">
                {categoryIndex}
              </span>
            )}
          </div>
        </CircledIcon>
        <span
          className={clsx(
            'absolute top-8 text-xs capitalize select-none',
            !allGreen &&
              selectedCategory &&
              !categoryIsSelected &&
              'text-gray-400'
          )}
        >
          {category}
        </span>
      </div>
      {addConnectingLine && <ConnectionLine status={status} />}
    </div>
  );
}

interface ConnectionLineProps {
  status: WizardCategoryStatus;
}
function ConnectionLine({ status }: ConnectionLineProps): JSX.Element {
  return (
    <div
      className={clsx(
        'flex h-1 w-10 left-4 top-2 absolute',
        status === WizardCategoryStatus.OK
          ? 'bg-secondary-500 border-secondary-800'
          : 'bg-gray-400'
      )}
    />
  );
}

interface WizardSwitchCategoryArrowProps {
  arrowDirection: 'left' | 'right';
  handleArrowClick: (arrow: 'left' | 'right') => void;
}
function WizardSwitchCategoryArrow({
  arrowDirection,
  handleArrowClick,
}: WizardSwitchCategoryArrowProps): JSX.Element {
  return (
    <div className="flex w-fit justify-center items-center">
      {arrowDirection === 'left' ? (
        <Back
          className="flex cursor-pointer text-gray-500 hover:text-gray-200"
          onClick={() => handleArrowClick('left')}
        />
      ) : (
        <Forward
          className="flex cursor-pointer text-gray-500 hover:text-gray-200"
          onClick={() => handleArrowClick('right')}
        />
      )}
    </div>
  );
}

interface WizardColapseExpandArrowProps {
  showContent: boolean;
  toggleShowContent: () => void;
}
function WizardColapseExpandArrow({
  showContent,
  toggleShowContent,
}: WizardColapseExpandArrowProps): JSX.Element {
  return (
    <div className="flex w-fit justify-center items-center">
      {showContent ? (
        <Up
          className="flex cursor-pointer text-gray-500 hover:text-gray-200 mb-1"
          onClick={toggleShowContent}
        />
      ) : (
        <Down
          className="flex cursor-pointer text-gray-500 hover:text-gray-200 mt-2"
          onClick={toggleShowContent}
        />
      )}
    </div>
  );
}
