import { useMemo } from 'react';
import { DatasetMask, parseDatasetVersion } from './dataset-helper';
import { NoPreview } from './NodeDetalisPanel';
import { DatasetListItem } from './DatasetVersionProperties';
import { useNetworkMapContext } from '../core/NetworkMapContext';
import { useCurrentProject } from '../core/CurrentProjectContext';

export function InputsDetails(): JSX.Element {
  const { currentDatasetSetup } = useNetworkMapContext();
  const { selectedCodeIntegrationVersion } = useCurrentProject();

  const inputs = useMemo(() => {
    return parseDatasetVersion(
      DatasetMask.Inputs,
      currentDatasetSetup,
      selectedCodeIntegrationVersion?.metadata.modelSetup
    );
  }, [selectedCodeIntegrationVersion, currentDatasetSetup]);

  return (
    <>
      {inputs.length ? (
        inputs.map((item, index) => <DatasetListItem {...item} key={index} />)
      ) : (
        <NoPreview />
      )}
    </>
  );
}
