import clsx from 'clsx';
import { GraphIcon } from '../../icons';

export function NoDataChart({
  text = 'No results found',
  className = '',
}): JSX.Element {
  return (
    <div
      className={clsx(
        'flex flex-col flex-1 min-h-[200px] justify-center items-center',
        className
      )}
    >
      <GraphIcon className="text-gray-700" />
      <span className="text-sm text-gray-400">{text}</span>
    </div>
  );
}
