import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgBackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M9.467 6.217A.737.737 0 0 1 10 6c.207 0 .383.074.527.223.149.144.223.32.223.527 0 .21-.074.389-.223.533L6.561 11.25H18.25c.207 0 .383.074.527.223.149.144.223.32.223.527a.727.727 0 0 1-.223.533.718.718 0 0 1-.527.217H6.56l3.967 3.967a.715.715 0 0 1 .223.533.708.708 0 0 1-.223.527A.708.708 0 0 1 10 18a.725.725 0 0 1-.533-.217l-5.25-5.25A.75.75 0 0 1 4 12a.75.75 0 0 1 .217-.533l5.25-5.25Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBackIcon;
