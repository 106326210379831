import { useEffect } from 'react';

export function useEscKeypress(cb: () => void): void {
  useEffect(() => {
    const keyDownHandler = ({ key }: KeyboardEvent): void => {
      if (key === 'Escape') {
        cb();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [cb]);
}
