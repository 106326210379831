import { useState, useCallback } from 'react';
import { useLocalStorage } from '../../../core/useLocalStorage';
import { FilterList } from '../../../filters/FilterList';
import { mapToEsFilters } from '../../../model-tests/modelTestHelpers';
import { BackButton } from '../../../ui/atoms/BackButton';
import { FetchSimilarButton } from '../../ScatterAnalyzerView/FetchSimilarButton';
import { useScatterData } from '../../ScatterDataContext';
import { useDashletScatterContext } from '../PopulationExploration/DashletScatterContext';
import { useSelectedSessionRuns } from '../../add-analysis/panes/useSelectedModels';
import { Input } from '../../../ui/atoms/Input';
import { TOUR_SELECTORS_ENUM } from '../../../tour/ToursConfig';

export interface FetchSimilarDrawerProps {
  closeFetchSimilarDrawer: () => void;
}

export function FetchSimilarDrawer({
  closeFetchSimilarDrawer,
}: FetchSimilarDrawerProps): JSX.Element {
  const {
    projectId,
    filters: {
      createClusterFilterByFetchSimilar,
      dashletAndGlobalFilters,
      filterFieldsMeta,
    },
  } = useDashletScatterContext();

  const {
    sessionRunId,
    epoch,
    selection: { selected },
    scatterData,
  } = useScatterData();

  const selectedSessionRuns = useSelectedSessionRuns();

  const [fetchSimilarFilters, setFetchSimilarFilters] = useState(
    dashletAndGlobalFilters.filter((f) => !f.disable)
  );

  const [limit, setLimit] = useLocalStorage('FETCH_SIMILAR_LIMIT', '100');

  const fetchSimilar = useCallback(() => {
    const sampleIds = Array.from(selected || []).map(
      (index) => scatterData.samples[index]
    );

    if (sampleIds.length === 0) {
      console.warn('No samples selected');
      return;
    }

    const sessionRun = selectedSessionRuns.find(
      ({ cid }) => cid === sessionRunId
    );

    if (!sessionRun) {
      console.warn('No session run selected');
      return;
    }

    createClusterFilterByFetchSimilar({
      sampleIds,
      sessionRunId,
      epoch,
      filters: mapToEsFilters(fetchSimilarFilters),
      fetchSimilarFilters,
      projectId,
      limit: Number(limit),
      sessionRun: {
        id: sessionRun.cid,
        name: sessionRun.name,
        sessionId: sessionRun.sessionId,
      },
    });

    closeFetchSimilarDrawer();
  }, [
    selected,
    selectedSessionRuns,
    createClusterFilterByFetchSimilar,
    sessionRunId,
    epoch,
    fetchSimilarFilters,
    projectId,
    limit,
    closeFetchSimilarDrawer,
    scatterData.samples,
  ]);

  const numOfSamples = selected?.size || 0;

  return (
    <div
      className="flex p-4 gap-4 flex-col bg-gray-850 w-full border-2 border-gray-800 rounded-lg"
      id={TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_FETCH_SIMILAR_DRAWER_ID}
    >
      <BackButton onClick={closeFetchSimilarDrawer} />

      <div className="flex flex-row gap-6 items-center text-gray-200">
        <div className="flex flex-col gap-1">
          <p className="text-base">
            {numOfSamples} sample{numOfSamples > 1 ? 's' : ''} selected.
          </p>
          <p className="text-base">Additional samples required:</p>
        </div>
        <div className="mt-2">
          <Input
            value={limit}
            min={1}
            clean
            label=""
            type="number"
            className="w-24"
            onChange={(e) => setLimit(e.target.value)}
          />
        </div>
      </div>

      <div className="p-2 w-full min-h-[80px] border-2 rounded-lg border-gray-800">
        <FilterList
          filters={fetchSimilarFilters}
          onFiltersChange={setFetchSimilarFilters}
          filterFieldsMeta={filterFieldsMeta}
          showFilterToggleButton={false}
          listClassName="flex flex-wrap gap-2 break-word"
          allowDisable={false}
          allowPin={false}
        />
      </div>
      <div className="flex justify-end">
        <FetchSimilarButton
          onClick={fetchSimilar}
          buttonClassName="h-8 max-w-[300px]"
          tourId={
            TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_FETCH_SIMILAR_TRIGGER_BUTTON_ID
          }
        />
      </div>
    </div>
  );
}
