import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgNextIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M9 1.5C4.864 1.5 1.5 4.864 1.5 9s3.364 7.5 7.5 7.5 7.5-3.364 7.5-7.5S13.136 1.5 9 1.5ZM9 15c-3.308 0-6-2.692-6-6s2.692-6 6-6 6 2.692 6 6-2.692 6-6 6Z"
      fill="currentColor"
    />
    <path d="m6 12 3.75-3L6 6v6Zm3.75-3v3h1.5V6h-1.5v3Z" fill="currentColor" />
  </svg>
);

export default SvgNextIcon;
