import {
  CircularProgress,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import clsx from 'clsx';
import { ReactNode } from 'react';

export type BuildRenderInputTextProps = {
  label?: string;
  small?: boolean;
  clean?: boolean;
  error?: boolean;
  readonly?: boolean;
  icon?: ReactNode;
  loading?: boolean;
};
export const buildRenderInputText = ({
  label,
  small,
  clean,
  error,
  readonly,
  icon,
  loading,
}: BuildRenderInputTextProps) => {
  return function renderInputText(params: TextFieldProps) {
    const overrideHeight = small ? '!h-9' : '!h-11';
    const maxHieight = small ? 'max-h-9' : 'max-h-11';

    const disabled = readonly ? false : params.InputProps?.disabled;

    const endAdornment = loading ? (
      <InputAdornment position="end">
        <CircularProgress size={20} />
      </InputAdornment>
    ) : (
      params.InputProps?.endAdornment
    );

    return clean ? (
      <TextField
        {...params}
        variant="standard"
        className={maxHieight}
        error={error}
        InputProps={{
          ...params.InputProps,
          className: overrideHeight,
          disableUnderline: true,
          readOnly: readonly,
          disabled,
          endAdornment,
          startAdornment: icon ? (
            <InputAdornment
              position="start"
              className="opacity-50 group-hover:opacity-100"
            >
              {icon}
            </InputAdornment>
          ) : undefined,
        }}
        inputProps={{
          ...params.inputProps,
          placeholder: label,
        }}
      />
    ) : (
      <TextField
        {...params}
        variant="outlined"
        error={error}
        label={label}
        className={maxHieight}
        InputLabelProps={{
          className: clsx(
            small ? 'translate-y-[11px]' : 'translate-y-[15px]',
            'translate-x-[14px]'
          ),
        }}
        InputProps={{
          ...params.InputProps,
          endAdornment: endAdornment,
          readOnly: readonly,
          disabled,
          className: overrideHeight,
        }}
      />
    );
  };
};
