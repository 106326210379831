import { IconButton } from '@material-ui/core';
import { Session } from '@tensorleap/api-client';
import clsx from 'clsx';
import { RefObject, useCallback, useMemo } from 'react';
import { Setter } from '../core/types';
import { useVersionControl } from '../core/VersionControlContext';
import { CircledIcon } from '../ui/atoms/CircledIcon';
import { MultiDsDashletIcon } from '../ui/icons';
import SvgDown from '../ui/icons/Down';
import SvgUp from '../ui/icons/Up';

export interface SessionRunSelectionToggleParams {
  session: Session;
  isSelected: boolean;
  handleClose: () => void;
  setSelectedSession: Setter<Session | undefined>;
  iconButtonRef: RefObject<HTMLButtonElement>;
}

export function SessionRunSelectionToggle({
  session,
  isSelected,
  handleClose,
  setSelectedSession,
  iconButtonRef,
}: SessionRunSelectionToggleParams): JSX.Element {
  const { selectedSessionRunMap } = useVersionControl();
  const selectedSessionRunsInSession = useMemo(() => {
    const selectedSessionRunIds = Array.from(selectedSessionRunMap.keys());
    return (
      session.sessionRuns
        ?.map(({ cid }) => cid)
        .filter((id) => selectedSessionRunIds.includes(id)).length || 0
    );
  }, [selectedSessionRunMap, session.sessionRuns]);

  const clickSelectModelEvalDialog = useCallback(() => {
    if (isSelected) {
      handleClose();
    } else {
      setSelectedSession(session);
    }
  }, [handleClose, isSelected, session, setSelectedSession]);

  return (
    <IconButton ref={iconButtonRef} onClick={clickSelectModelEvalDialog}>
      <div className="flex flex-row justify-center items-center relative">
        <MultiDsDashletIcon
          className={clsx(isSelected && 'text-primary-200')}
        />
        {selectedSessionRunsInSession > 0 && (
          <CircledIcon
            className="flex !h-4 !w-4 absolute -top-[6px] right-[7px]"
            borderStyle="!bg-primary-500"
            text={`${selectedSessionRunsInSession} Session Runs`}
          >
            <span className="text-xs !text-gray-300">
              {String(selectedSessionRunsInSession)}
            </span>
          </CircledIcon>
        )}
        {isSelected ? (
          <SvgUp className="text-sm" />
        ) : (
          <SvgDown className="text-sm" />
        )}
      </div>
    </IconButton>
  );
}
