import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
export interface CircularCenteredProgressBarProps {
  text?: string;
  current: number;
  max: number;
}

export function CircularCenteredProgressBar({
  text = '',
  current,
  max,
}: CircularCenteredProgressBarProps): JSX.Element {
  return (
    <div className="w-48 h-36">
      <CircularProgressbarWithChildren
        value={current}
        maxValue={max}
        minValue={0}
        strokeWidth={4}
        circleRatio={0.65}
        styles={buildStyles({
          rotation: 0.677,
          strokeLinecap: 'butt',
          trailColor: '#616161',
          pathColor: '#3B82F6',
        })}
      >
        <div className="flex flex-col w-full h-full justify-center items-center text-gray-400">
          <div className="tracking-lg">
            <span className="text-white text-6xl">{current}</span>
            <span className="text-4xl">/ {max}</span>
          </div>
          <span className="uppercase text-xl tracking-lg">{text}</span>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
}
