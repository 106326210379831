import {
  ReactNode,
  useMemo,
  useCallback,
  Ref,
  forwardRef,
  ComponentType,
  RefObject,
  useRef,
  FocusEventHandler,
  useEffect,
} from 'react';
import clsx from 'clsx';

import { InputNote } from './utils/InputNote';
import {
  CLEAN_CONTAINER_ERROR_CLASSES,
  CLEAN_CONTAINER_NOT_ERROR_CLASSES,
} from './utils/useInputClasses';
import { Autocomplete } from '../mui';
import { Down } from '../icons';
import {
  buidDefaultLabelMap,
  mapValue,
  OptionType,
  useAsyncOptions,
  AsyncOption,
} from './utils/select';
import { buildRenderInputText } from './utils/buildRenderInputText';
import { Popper, PopperProps } from '@material-ui/core';
import {
  AutocompleteChangeReason,
  AutocompleteRenderOptionState,
} from '@material-ui/lab';
import { NumberOrString } from '@tensorleap/api-client';

export type SelectProps<Option = OptionType> = {
  label?: string;
  options: AsyncOption<Option>;
  clean?: boolean;
  value?: string | number;
  noOptionsText?: ReactNode;
  disabled?: boolean;
  info?: string;
  className?: string;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  onFocus?: FocusEventHandler<HTMLDivElement>;
  onChange: (
    value: string | undefined,
    option: Option | null
  ) => void | Promise<void>;
  queryToOption?: (query: string) => Option; // in case of selecting an option that is not in the list
  optionToLabel?: (option: Option) => string;
  renderOption?: (
    option: Option,
    state: AutocompleteRenderOptionState
  ) => JSX.Element;
  optionID?: keyof Option & string;
  inactiveOptions?: NumberOrString[];
  error?: string;
  small?: boolean;
  required?: boolean;
  readonly?: boolean;
  listPaperClassName?: string;
  icon?: ReactNode;
  groupBy?: (option: Option) => string;
};

function SelectInner<Option>(
  {
    label,
    options = [],
    noOptionsText = 'No Options',
    clean = false,
    optionID = 'value' as keyof Option & string,
    optionToLabel,
    inactiveOptions,
    renderOption,
    value,
    disabled,
    info,
    className,
    onChange,
    onFocus,
    onBlur,
    error,
    small,
    required = true,
    readonly,
    listPaperClassName,
    icon,
    queryToOption,
    groupBy,
  }: SelectProps<Option>,
  ref: Ref<unknown>
) {
  const { loadedOptions, loading, setQuery, queryRef, query } = useAsyncOptions(
    options,
    value === undefined ? '' : String(value)
  );

  const currentOptions = Array.isArray(options) ? options : loadedOptions;

  const warpOnChange = useCallback(
    (value: string | undefined, option: Option | null) => {
      queryRef.current = null;
      setQuery(null);
      onChange(value, option);
    },
    [onChange, setQuery, queryRef]
  );

  const selected = useMemo(() => {
    const founded = currentOptions.find(
      (o: Option) => mapValue(o, optionID) === value
    );
    if (!founded && queryToOption) {
      const isAlreadySelected = queryRef.current === null;
      const valueAsStr = String(value);
      if (isAlreadySelected && valueAsStr) {
        return queryToOption(String(value));
      }
      if (query) {
        return queryToOption(query);
      }
    }
    return founded;
  }, [currentOptions, value, optionID, query, queryToOption, queryRef]);

  const optionToLabelWithDefault = useMemo(
    () => (optionToLabel ? optionToLabel : buidDefaultLabelMap(optionID)),
    [optionToLabel, optionID]
  );

  const optionIsObj = typeof currentOptions[0] === 'object';

  const onChangeHandler = useCallback(
    (_, itemOrValue: unknown, changeType: AutocompleteChangeReason) => {
      switch (changeType) {
        case 'clear':
        case 'remove-option':
          return warpOnChange(undefined, null);
        case 'create-option':
          {
            const query = itemOrValue as string;
            queryToOption && warpOnChange(query, queryToOption(query));
          }
          break;
        case 'select-option':
          {
            const item: Option = itemOrValue as Option;
            const value = mapValue(item, optionID) as string;

            warpOnChange(value, item);
          }
          break;
        case 'blur':
          if (query) {
            queryToOption && warpOnChange(query, queryToOption(query));
          }
          return;
      }
    },
    [warpOnChange, optionID, queryToOption, query]
  );

  const maxHeight = small ? 'max-h-9' : 'max-h-11';
  const hasError = !!error;
  const renderInput = useMemo(
    () =>
      buildRenderInputText({
        clean,
        small,
        label,
        error: hasError,
        readonly,
        icon,
        loading,
      }),
    [clean, icon, small, label, hasError, readonly, loading]
  );

  function createPooper(
    ref: RefObject<HTMLDivElement>
  ): ComponentType<PopperProps> {
    const refWidth = ref.current?.clientWidth;
    const style = refWidth === undefined ? {} : { minWidth: refWidth + 'px' };

    const Comp = (props: JSX.IntrinsicAttributes & PopperProps) => (
      <Popper {...props} placement="bottom-start" style={style} />
    );

    return Comp;
  }

  const divRef = useRef<HTMLDivElement>(null);

  const renderOptionWithDefault = useMemo(() => {
    if (renderOption) return renderOption;
    const useCustomRender = inactiveOptions?.length;
    if (!useCustomRender) return undefined;
    const inactiveOptionsSet = new Set(inactiveOptions);

    const renderOptionFunc = (
      option: Option,
      state: AutocompleteRenderOptionState
    ) => (
      <li
        {...state}
        className={clsx(
          inactiveOptionsSet.has(mapValue(option, optionID)) && 'text-gray-400'
        )}
      >
        {optionToLabelWithDefault(option)}
      </li>
    );
    return renderOptionFunc;
  }, [inactiveOptions, optionID, renderOption, optionToLabelWithDefault]);

  const popperComponent = useMemo(() => createPooper(divRef), [divRef]);
  const freeSolo = queryToOption !== undefined;

  const wrapOnBlur = useCallback(
    (e) => {
      if (queryToOption) {
        const isCalledAfterOnChange =
          queryRef.current === null || query === null;
        if (isCalledAfterOnChange) {
          return;
        }
        const option = queryToOption(query);
        const optionValue = mapValue(option, optionID);
        if (optionValue !== value) {
          warpOnChange(String(optionValue), option);
        }
      }
      onBlur?.(e);
    },
    [onBlur, query, queryToOption, optionID, value, warpOnChange, queryRef]
  );

  useEffect(() => {
    // This is needed to update the query when the value changes
    setQuery(String(value ?? ''));
  }, [value, setQuery]);

  return (
    <div className={clsx('flex flex-col', className)} ref={divRef}>
      <Autocomplete
        value={selected ?? ''}
        ref={ref}
        freeSolo={freeSolo}
        loading={loading}
        onInputChange={(_, newQuery) => {
          setQuery(newQuery);
        }}
        disabled={disabled || readonly}
        onChange={onChangeHandler}
        popupIcon={<Down />}
        options={currentOptions}
        blurOnSelect
        disableClearable={required}
        groupBy={groupBy}
        className={clsx(
          'w-full',
          maxHeight,
          clean && 'px-2 rounded',
          clean &&
            (error
              ? CLEAN_CONTAINER_ERROR_CLASSES
              : CLEAN_CONTAINER_NOT_ERROR_CLASSES)
        )}
        onFocus={onFocus}
        onBlur={wrapOnBlur}
        classes={{ popper: 'z-[1500]', paper: listPaperClassName }}
        getOptionLabel={optionToLabelWithDefault}
        renderOption={renderOptionWithDefault}
        itemID={optionIsObj ? optionID : undefined}
        noOptionsText={noOptionsText}
        renderInput={renderInput}
        PopperComponent={popperComponent}
      />
      <InputNote error>{error}</InputNote>
      <InputNote info>{info}</InputNote>
    </div>
  );
}

// There is a problem to use forwardRef with generic props so this is good enough workaround
export const Select = forwardRef(SelectInner) as <Option>(
  props: SelectProps<Option> & { ref?: Ref<HTMLSelectElement> }
) => JSX.Element;
(Select as ComponentType).displayName = 'Select';
