import { AnalyticsDashletType } from '@tensorleap/api-client';
import { IconTabs } from '../../../../ui/atoms/IconsTabs';
import { useGraphMetaMap } from './FormFields';

export interface DashletFormLeftMenuProps {
  value: AnalyticsDashletType;
  onChange: (_: AnalyticsDashletType) => void;
}

export function DashletFormLeftMenu({
  value,
  onChange,
}: DashletFormLeftMenuProps): JSX.Element {
  const { tabItems } = useGraphMetaMap();
  return <IconTabs selected={value} setSelected={onChange} tabs={tabItems} />;
}
