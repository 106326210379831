import { IconButton } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { TOUR_SELECTORS_ENUM } from '../../tour/ToursConfig';
import { DownSmallIcon, UpSmallIcon } from '../../ui/icons';
import { FetchSimilarButton } from './FetchSimilarButton';
import { Button } from '../../ui/atoms/Button';
import { FetchSimilarDrawer } from '../dashlet/VisualizationDisplay/FetchSimilarDrawer';
import { SampleIdentity } from '@tensorleap/api-client';
import { SampleAnalysisDrawer } from '../dashlet/VisualizationDisplay/SampleAnalysisDrawer';

export interface SamplesActionsDrawerProps {
  selectedSampleIdentities: SampleIdentity[];
}

export function SamplesActionsDrawer({
  selectedSampleIdentities,
}: SamplesActionsDrawerProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(true);
  const [isFetchSimilarDrawerOpen, setIsFetchSimilarDrawerOpen] = useState(
    false
  );
  const [isSampleAnalysisDrawerOpen, setIsSampleAnalysisDrawerOpen] = useState(
    false
  );

  const handleOpenDrawer = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeFetchSimilarDrawer = useCallback(() => {
    setIsFetchSimilarDrawerOpen(false);
  }, []);

  const openFetchSimilarDrawer = useCallback(() => {
    setIsFetchSimilarDrawerOpen(true);
  }, []);

  const openSampleAnalysisDrawer = useCallback(() => {
    setIsSampleAnalysisDrawerOpen(true);
  }, []);

  const closeSampleAnalysisDrawer = useCallback(() => {
    setIsSampleAnalysisDrawerOpen(false);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setIsOpen(false);
    closeFetchSimilarDrawer();
    closeSampleAnalysisDrawer();
  }, [closeFetchSimilarDrawer, closeSampleAnalysisDrawer]);

  return (
    <div className="flex flex-col bg-gray-850 w-full border-2 border-gray-800 rounded-lg">
      <div className="flex flex-row h-[6px] gap-1">
        <span className="bg-primary-700 w-full h-[6px] -mr-4 rounded-tl-md" />
        <IconButton
          onClick={isOpen ? handleCloseDrawer : handleOpenDrawer}
          className="w-2 h-2 p-[10px] bg-primary-700 font-bold -mt-2"
        >
          {isOpen ? <DownSmallIcon /> : <UpSmallIcon />}
        </IconButton>

        <span className="bg-primary-700 w-full h-[6px] -ml-4 rounded-tr-md" />
      </div>
      {isFetchSimilarDrawerOpen ? (
        <FetchSimilarDrawer closeFetchSimilarDrawer={closeFetchSimilarDrawer} />
      ) : isSampleAnalysisDrawerOpen ? (
        <SampleAnalysisDrawer
          closeSampleAnalysisDrawer={closeSampleAnalysisDrawer}
          selectedSampleIdentities={selectedSampleIdentities}
        />
      ) : isOpen ? (
        <div className="flex flex-col bg-gray-850 w-full border-2 border-gray-800 rounded-lg">
          <div className="flex flex-wrap justify-center p-4 pt-5 gap-2 w-full">
            <FetchSimilarButton
              onClick={openFetchSimilarDrawer}
              buttonClassName="min-w-[190px] h-8"
              tourId={
                TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_FETCH_SIMILAR_EXPANDER_BUTTON_ID
              }
            />

            <Button
              variant="inverted-blue"
              onClick={openSampleAnalysisDrawer}
              className="min-w-[190px] h-8"
              tourId={
                TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_OPEN_SAMPLE_ANALYSIS_DRAWER_BUTTON_ID
              }
            >
              analyze sample
            </Button>
          </div>
        </div>
      ) : (
        <span className="h-0" />
      )}
    </div>
  );
}
