import {
  ResponsiveContainer,
  BarChart as ReBarChart,
  Bar,
  Tooltip,
  YAxis,
  XAxis,
  ReferenceArea,
} from 'recharts';
import { NoDataChart } from '../common/NoDataChart';
import { GRAPH_STYLE } from '../common/constants';
import { useXYChart } from '../hooks/useXYChart';
import { useXYChartDragFilter } from '../hooks/useXYChartDragFilter';
import { XYChartProps } from '../common/interfaces';
import { AnalyticsDashletType } from '@tensorleap/api-client';
import { useTooltipProps } from './ChartBlocks/TooltipProps';
import { useXAxisProps } from './ChartBlocks/XAxisProps';
import { useYAxisProps } from './ChartBlocks/YAxisProps';

export function BarChart({
  graphData,
  chartRequestData,
  className,
  onFiltersChange,
  filters = [],
  xAxisDomain,
  yAxisDomain,
  showXAxisLine = true,
  showYLabel = true,
  hiddenLabels,
  hoverLabel,
  colorMap,
  axisType,
  mapValue,
}: XYChartProps): JSX.Element {
  const {
    mergedData,
    strokeOpacity,
    fillOpacity,
    isHidden,
    allLabels,
    setIsMouseDown,
    graphKey,
  } = useXYChart({
    graphData,
    chartRequestData,
    chartType: AnalyticsDashletType.Bar,
    hiddenLabels,
    hoverLabel,
  });
  const lines = mergedData();

  const {
    onMouseDown,
    onMouseLeave,
    onMouseMove,
    onMouseUp,
    referenceAreaProps,
  } = useXYChartDragFilter({
    graphData,
    chartRequestData,
    axisType,
    filters,
    onFiltersChange,
    setIsMouseDown,
  });

  const xAxisProps = useXAxisProps({
    displayName: chartRequestData.xField,
    displayAxis: showXAxisLine,
    domain: xAxisDomain,
    axisType,
  });
  const yAxisProps = useYAxisProps({
    displayName: chartRequestData.yField,
    showLabel: showYLabel,
    domain: yAxisDomain,
  });
  const tooltipProps = useTooltipProps({
    graphData,
    colorMap,
    chartRequestData,
    mapValue,
  });

  if (!graphData.data?.length || !lines) {
    return <NoDataChart />;
  }

  return (
    <ResponsiveContainer className={className} key={graphKey}>
      <ReBarChart
        data={lines}
        margin={GRAPH_STYLE.margin}
        className="select-none"
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
      >
        <XAxis {...xAxisProps} allowDuplicatedCategory={true} />
        <YAxis {...yAxisProps} />
        <Tooltip {...tooltipProps} />
        {referenceAreaProps && <ReferenceArea {...referenceAreaProps} />}
        {allLabels.map((datakey, index) => (
          <Bar
            key={index}
            dataKey={datakey}
            fill={colorMap[datakey]}
            fillOpacity={fillOpacity(datakey)}
            hide={isHidden(datakey)}
            isAnimationActive={false}
            stroke={colorMap[datakey]}
            strokeWidth={1}
            strokeOpacity={strokeOpacity(datakey)}
          />
        ))}
      </ReBarChart>
    </ResponsiveContainer>
  );
}
