import { UseFormReturn } from 'react-hook-form';
import { Input } from '../ui/atoms/Input';
import { ModelTestsFormProps } from './ModelTestsCard';

export interface ModelTestsCardContentNameChangeProps {
  editable: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<ModelTestsFormProps, any>;
}

export function ModelTestsCardContentNameChange({
  editable,
  form,
}: ModelTestsCardContentNameChangeProps): JSX.Element | null {
  const {
    register,
    formState: { errors },
  } = form;

  if (!editable) {
    return null;
  }
  return (
    <div className="w-full px-4">
      <div className="flex flex-col w-full justify-center items-start my-4">
        <div className="flex flex-row w-full justify-center items-start">
          <span className="capitalize text-xl tracking-widest text-gray-500">
            test name
          </span>
          <div className="flex-1" />
        </div>
        <div className="h-fit w-full mt-2">
          <div className="grid grid-cols-2 w-full h-fit gap-4 my-2">
            <div className="col-span-2">
              <Input
                type="string"
                label="Test Name"
                {...register('name')}
                error={errors?.name && errors?.name?.message}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
