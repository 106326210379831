import {
  MutualInformationElement,
  NumberOrString,
  OrderType,
  SplitAgg,
} from '@tensorleap/api-client';
import clsx from 'clsx';
import { isNumber, orderBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { Setter } from '../../../core/types';
import { useVersionControl } from '../../../core/VersionControlContext';
import { getSplitLabel } from '../common/utils';
import { SortTypeEnum, LabelsLegendMenu } from './LabelsLegendMenu';
import { ScatterLabelElement } from './ScatterLabelElement';

export const DEFAULT_TRUNCATE_LONG_TAIL = 16;

export interface LabelsLegendsProps {
  labels: NumberOrString[];
  appearances?: Map<NumberOrString, number>;
  colorMap?: Record<string, string>;
  className?: string;
  showNames: boolean;
  toggleShowNames: () => void;
  truncatedLongtail: number;
  setTruncatedLongtail: Setter<number>;
  onLegendClick?: (label: NumberOrString) => void;
  setHoverLabel?: Setter<NumberOrString | undefined>;
  setHiddenLabels?: Setter<NumberOrString[]>;
  hiddenLabels?: NumberOrString[];
  innerSplit: SplitAgg | null;
  clusterData?: Record<string, MutualInformationElement[]>;
}

type DisplayLabel = {
  value: NumberOrString;
  label: NumberOrString;
};

export function LabelsLegend({
  labels,
  appearances,
  className,
  colorMap,
  showNames,
  toggleShowNames,
  setHoverLabel,
  setHiddenLabels,
  onLegendClick,
  hiddenLabels,
  innerSplit,
  clusterData,
  truncatedLongtail,
  setTruncatedLongtail,
}: LabelsLegendsProps) {
  const { selectedSessionRunMap } = useVersionControl();

  const [sortMethod, setSortMethod] = useState<SortTypeEnum>(
    innerSplit?.order === OrderType.Desc
      ? SortTypeEnum.DESC_ALPHABETICALLY
      : SortTypeEnum.ASC_ALPHABETICALLY
  );

  const sortOrder =
    sortMethod === SortTypeEnum.DESC_ALPHABETICALLY ||
    sortMethod === SortTypeEnum.DESC_BY_PRESENCE
      ? OrderType.Desc
      : OrderType.Asc;

  const parsedLabels = useMemo(() => {
    const reversedOrder =
      sortOrder === OrderType.Desc ? OrderType.Asc : OrderType.Desc;
    const split = { ...innerSplit, order: reversedOrder };
    return labels.map((value) => ({
      value,
      label: getSplitLabel(String(value), selectedSessionRunMap, split),
    }));
  }, [labels, selectedSessionRunMap, innerSplit, sortOrder]);

  const sortLabelAlphabetically = useCallback((): DisplayLabel[] => {
    const sortedArray = orderBy(
      parsedLabels,
      ({ value }) => {
        const asNumber = Number(value);
        return isNaN(asNumber) ? value : asNumber;
      },
      sortOrder
    );
    return sortedArray;
  }, [sortOrder, parsedLabels]);

  const sortLabelByAppearances = useCallback(() => {
    if (!appearances) return [];
    const sortedArray = Array.from(appearances.entries())
      .sort(
        ([label1, appearance1], [label2, appearance2]) =>
          appearance1 - appearance2 ||
          (isNumber(label1) && isNumber(label2)
            ? label1 - label2
            : String(label1).localeCompare(String(label2)))
      )
      .map(([label, _]) => label);

    const mappedLabels = new Map(parsedLabels.map((l) => [l.value, l]));

    const sortedArrayWithLabels = sortedArray.map(
      (label) => mappedLabels.get(label) as DisplayLabel
    );

    return sortOrder === OrderType.Desc
      ? sortedArrayWithLabels.reverse()
      : sortedArrayWithLabels;
  }, [appearances, parsedLabels, sortOrder]);

  const orderedLabels = useMemo(() => {
    if (
      sortMethod === SortTypeEnum.DESC_ALPHABETICALLY ||
      sortMethod === SortTypeEnum.ASC_ALPHABETICALLY
    ) {
      return sortLabelAlphabetically();
    }
    if (
      sortMethod === SortTypeEnum.DESC_BY_PRESENCE ||
      sortMethod === SortTypeEnum.ASC_BY_PRESENCE
    ) {
      return sortLabelByAppearances();
    }
    return [];
  }, [sortLabelAlphabetically, sortLabelByAppearances, sortMethod]);

  const handleLegendClick = useCallback(
    (label) => {
      onLegendClick?.(label);
      if (!setHiddenLabels) return;
      setHiddenLabels((currentLabels) => {
        let newLabels = Array.from(currentLabels);
        if (newLabels.includes(label)) {
          newLabels = newLabels.filter((item) => item !== label);
        } else {
          newLabels.push(label);
        }
        return newLabels;
      });
    },
    [setHiddenLabels, onLegendClick]
  );

  const handleLegendMouseOver = useCallback(
    (label) => {
      if (hiddenLabels?.includes(label)) return;
      setHoverLabel?.(label);
    },
    [hiddenLabels, setHoverLabel]
  );

  const handleLegendMouseLeave = useCallback(() => {
    setHoverLabel?.(undefined);
  }, [setHoverLabel]);

  const clickShowAll = useCallback(() => {
    setHiddenLabels?.([]);
  }, [setHiddenLabels]);

  const clickHideAll = useCallback(() => {
    const values = parsedLabels.map(({ value }) => value);
    setHiddenLabels?.(values);
  }, [parsedLabels, setHiddenLabels]);

  return (
    <div className={clsx('flex flex-col py-2 h-full items-end', className)}>
      <LabelsLegendMenu
        sortMethod={sortMethod}
        setSortMethod={setSortMethod}
        showNames={showNames}
        toggleShowNames={toggleShowNames}
        truncateLongtail={truncatedLongtail}
        setTruncateLongtail={setTruncatedLongtail}
        clickHideAll={setHiddenLabels ? clickHideAll : undefined}
        clickShowAll={setHiddenLabels ? clickShowAll : undefined}
        showAppearancesOrder={!!appearances}
      />
      <div className="flex flex-col h-full w-fit overflow-auto">
        {orderedLabels.map(({ label, value }, index) => (
          <ScatterLabelElement
            key={index}
            label={label}
            value={value}
            handleLegendClick={handleLegendClick}
            handleLegendMouseOver={handleLegendMouseOver}
            handleLegendMouseLeave={handleLegendMouseLeave}
            showNames={showNames}
            truncatedLongtail={truncatedLongtail}
            appearances={appearances}
            clusterData={clusterData}
            hiddenLabels={hiddenLabels}
            colorMap={colorMap}
          />
        ))}
      </div>
    </div>
  );
}
