import { LightBulbOnIcon } from '../ui/icons';
import { CardHeader } from '../ui/molecules/Card';
import clsx from 'clsx';
import { InsightSubCategoryType, SCATTER_INSIGHT_TYPE_MAP } from './utils';
import { Tooltip } from '@material-ui/core';

export type InsightCardHeaderProps = {
  title?: string;
  asPopup?: boolean;
  onClose?: () => void;
  insightSubType?: InsightSubCategoryType;
  showAsACard?: boolean;
  tourId?: string;
  insightIndex?: number;
};
export function InsightHeader({
  title,
  asPopup,
  onClose,
  insightSubType,
  showAsACard = true,
  tourId,
  insightIndex,
}: InsightCardHeaderProps): JSX.Element {
  const cardStyle = insightSubType
    ? SCATTER_INSIGHT_TYPE_MAP[insightSubType].card
    : undefined;

  const headerIconClassName =
    cardStyle?.headerIconClassName || 'text-insight-500';

  const insightTitle = title || cardStyle?.title || '';

  if (!showAsACard) {
    return (
      <div className="flex flex-row gap-2">
        <LightBulbOnIcon className={headerIconClassName} />
        {insightTitle && <span className="font-bold">{insightTitle}</span>}
        {insightIndex !== undefined && <InsightIndex value={insightIndex} />}
      </div>
    );
  }

  const headerBorderClassName =
    cardStyle?.headerBorderClassName || 'border-b-insight-500';

  return (
    <CardHeader
      icon={<LightBulbOnIcon className={headerIconClassName} />}
      title={
        <div className="flex flex-row justify-between">
          <span>{insightTitle}</span>

          {insightIndex !== undefined && <InsightIndex value={insightIndex} />}
        </div>
      }
      className={clsx(headerBorderClassName, 'border-b-2 text-sm')}
      asPopup={asPopup}
      onCloseClick={onClose}
      tourId={tourId}
    />
  );
}

function InsightIndex({ value }: { value: number }): JSX.Element {
  return (
    <Tooltip title="Insight ID">
      <span># {value + 1}</span>
    </Tooltip>
  );
}
