import { useCallback, useMemo, useState } from 'react';
import { useMergedObject } from './useMergedObject';
import { Setter } from './types';

export type UseMultiSelectMode = 'add' | 'subtract' | 'select';

export type UseMultiSelect<ID> = {
  mode: UseMultiSelectMode;
  setMode: Setter<UseMultiSelectMode>;
  setPressedMode: Setter<UseMultiSelectMode | undefined>;
  selected: Set<ID> | undefined;
  setSelected: Setter<Set<ID> | undefined>;
  marked: Set<ID> | undefined;
  setMarked: Setter<Set<ID> | undefined>;
  nextSelected: Set<ID> | undefined;
  selectNext: () => void;
  someSelected: boolean;
  clear: () => void;
};

export function useMultiSelect<ID>(): UseMultiSelect<ID> {
  const [_mode, setMode] = useState<UseMultiSelectMode>('select');
  const [pressedMode, setPressedMode] = useState<UseMultiSelectMode>();
  const mode = pressedMode ?? _mode;
  const [selected, setSelected] = useState<Set<ID>>();
  const [marked, setMarked] = useState<Set<ID>>();

  const nextSelected = useMemo(() => {
    return calcNextSelection(mode, selected, marked);
  }, [mode, selected, marked]);

  const selectNext = useCallback(() => {
    setMarked(undefined);
    setSelected(nextSelected);
  }, [nextSelected]);

  const clear = useCallback(() => {
    setSelected(undefined);
    setMarked(undefined);
  }, []);

  return useMergedObject({
    mode,
    setMode,
    setPressedMode,
    selected,
    setSelected,
    marked,
    setMarked,
    nextSelected,
    selectNext,
    someSelected: !!selected?.size,
    clear,
  });
}

export function calcNextSelection<ID>(
  mode: UseMultiSelectMode,
  selected?: Set<ID>,
  marked?: Set<ID>
): Set<ID> {
  if (mode === 'select') {
    return marked || selected || new Set();
  }
  const next = new Set(selected);
  if (!marked) {
    return next;
  }
  marked.forEach((id) => {
    if (mode === 'add') {
      next.add(id);
    } else if (mode === 'subtract') {
      next.delete(id);
    }
  });
  return next;
}
