import { VisualizationFilter } from '../core/types/filters';
import { buildTestConditionFromFilterConverter } from './condition-utils';
import { FieldValueExtractor } from './utils';

export function search<T>(
  data: T[],
  filters: VisualizationFilter[] = [],
  extractFieldValue?: FieldValueExtractor<T>
) {
  const testConditionFromFilterConverter = buildTestConditionFromFilterConverter<T>(
    extractFieldValue
  );
  const testConditions = filters
    .filter((f) => !f.disable)
    .map(testConditionFromFilterConverter);

  return data.filter((item) =>
    testConditions.every((testCondition) => testCondition(item))
  );
}
