import { useCallback, useMemo } from 'react';
import { DatasetMask, parseDatasetVersion } from './dataset-helper';
import { NodeDetailsPanel, NoPreview } from './NodeDetalisPanel';
import { Settings, Visible } from '../ui/icons';
import { DetailsProps } from './NodeDetails';
import { Select } from '../ui/atoms/Select';
import { Input } from '../ui/atoms/Input';
import { Unreachable } from '../core/Errors';
import { useNetworkMapContext } from '../core/NetworkMapContext';
import { useCurrentProject } from '../core/CurrentProjectContext';

export function DatasetGroundTruth({ node }: DetailsProps): JSX.Element {
  const {
    changeNodeProperty,
    updateConnection,
    currentDatasetSetup,
  } = useNetworkMapContext();
  const { selectedCodeIntegrationVersion } = useCurrentProject();

  const groundTruths = useMemo(() => {
    return parseDatasetVersion(
      DatasetMask.GroundTruths,
      currentDatasetSetup,
      selectedCodeIntegrationVersion?.metadata.modelSetup
    );
  }, [selectedCodeIntegrationVersion, currentDatasetSetup]);

  const selectedGroundTruth = useMemo(
    () => groundTruths.find(({ fnName }) => fnName === node.data.output_name),
    [groundTruths, node.data.output_name]
  );

  const handleGroundTruthChange = useCallback(
    (groundTruthName?: string) => {
      if (!groundTruthName) throw new Unreachable();

      changeNodeProperty({
        nodeId: node.id,
        nodeDataPropsToUpdate: { output_name: groundTruthName },
      });

      updateConnection(node.id, undefined, [groundTruthName]);
    },
    [changeNodeProperty, node, updateConnection]
  );

  return (
    <>
      <NodeDetailsPanel title="Properties" icon={<Settings />} openByDefault>
        <Select
          className="m-4"
          label="SELECTED GROUND TRUTH"
          optionID="fnName"
          required
          optionToLabel={(o) => o.title}
          onChange={handleGroundTruthChange}
          value={node.data.output_name}
          options={groundTruths}
        />
      </NodeDetailsPanel>
      <NodeDetailsPanel
        className="gap-8 p-4"
        title="Preview"
        icon={<Visible />}
        openByDefault
      >
        {selectedGroundTruth ? (
          selectedGroundTruth.subItems.map(({ title, value }) => (
            <Input key={title} label={title} value={value} readOnly />
          ))
        ) : (
          <NoPreview />
        )}
      </NodeDetailsPanel>
    </>
  );
}
