import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgUserCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M16.313 9a7.313 7.313 0 1 0-12.154 5.475 7.298 7.298 0 0 0 9.683 0 .566.566 0 0 0 .092-.083A7.295 7.295 0 0 0 16.313 9Zm-13.5 0a6.187 6.187 0 1 1 10.81 4.107 5.594 5.594 0 0 0-2.535-2.021 3.375 3.375 0 1 0-4.176 0 5.594 5.594 0 0 0-2.536 2.02A6.162 6.162 0 0 1 2.812 9Zm3.937-.563a2.25 2.25 0 1 1 4.5 0 2.25 2.25 0 0 1-4.5 0Zm-1.541 5.45a4.5 4.5 0 0 1 7.582 0 6.174 6.174 0 0 1-7.582 0Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgUserCircle;
