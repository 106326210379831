import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgAlerts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M16 16.531v-6c0-1.234-.352-2.289-1.055-3.164-.703-.89-1.68-1.336-2.93-1.336-1.234 0-2.21.446-2.93 1.336-.702.875-1.054 1.93-1.054 3.164v6H16Zm2.016-1.031 2.015 2.016v.984H4v-.984L6.016 15.5v-4.969c0-.765.101-1.492.304-2.18A6.51 6.51 0 0 1 7.187 6.5c.391-.563.86-1.04 1.407-1.43a5.986 5.986 0 0 1 1.922-.867V3.5c0-.422.14-.773.421-1.055A1.469 1.469 0 0 1 12.017 2c.421 0 .773.148 1.054.445.297.282.446.633.446 1.055v.703a5.713 5.713 0 0 1 1.898.867c.563.39 1.031.867 1.406 1.43.39.547.688 1.172.89 1.875.204.688.306 1.406.306 2.156V15.5Zm-6 6c-.532 0-1-.188-1.407-.563-.406-.39-.609-.859-.609-1.406h4.031c0 .547-.203 1.016-.61 1.407-.39.375-.858.562-1.405.562Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgAlerts;
