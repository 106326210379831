import {
  SessionTestResult,
  SessionTestResultError,
  SessionTestResultNotFound,
  SessionTestResultSuccess,
} from '@tensorleap/api-client';
import { useMemo } from 'react';
import { TestResult } from '../ui/atoms/TestPieChart';
import { useSelectedModelSummary } from '../ui/molecules/useSelectedModelSummary';
import { TestModelSummaryData, OnEpochChange } from './ModelTestSummary';
import {
  UseModelTestsResultsReturn,
  UseModelTetstResultQueryReturn,
} from './useModelTestsResults';

export type UseModelTestsSummaryReturn = {
  updateEpochChange: OnEpochChange;
  data: TestModelSummaryData[];
};

const DEFAULT_TEST_RESULT = { succeeded: 0, failed: 0, errors: 0, notFound: 0 };

export type useModelTestsSummaryProps = {
  testsResult: UseModelTestsResultsReturn;
  testsResultsQuery: UseModelTetstResultQueryReturn;
};

export function useModelTestsSummary({
  testsResult,
  testsResultsQuery: { updateEpochChange, epochBySessionRunId },
}: useModelTestsSummaryProps): UseModelTestsSummaryReturn {
  const testsSummaryMap = useMemo(() => {
    const result = new Map<string, TestResult | null>();

    testsResult.data.forEach((test) => {
      test.sessionsResults.forEach((sessionTestResult: SessionTestResult) => {
        let modelSummaryResult = result.get(sessionTestResult.sessionRunId);
        if (!modelSummaryResult) {
          modelSummaryResult = { ...DEFAULT_TEST_RESULT };
          result.set(sessionTestResult.sessionRunId, modelSummaryResult);
        }
        if (isSessionTestResultError(sessionTestResult))
          return modelSummaryResult.errors++;
        if (isSessionTestResultNotFound(sessionTestResult))
          return modelSummaryResult.notFound++;
        if (isSessionTestResultSuccess(sessionTestResult)) {
          sessionTestResult.testSucceeded
            ? modelSummaryResult.succeeded++
            : modelSummaryResult.failed++;
        }
      });
    });

    return result;
  }, [testsResult]);

  const modelData = useSelectedModelSummary({
    epochBySessionRunId: epochBySessionRunId,
  });
  const data = useMemo<TestModelSummaryData[]>(
    () =>
      modelData.map((md) => ({
        ...md,
        testResult:
          testsSummaryMap.get(md.sessionRun.id) || DEFAULT_TEST_RESULT,
      })),
    [testsSummaryMap, modelData]
  );

  return {
    updateEpochChange,
    data,
  };
}

export function isSessionTestResultSuccess(
  sessionTestResult: SessionTestResult
): sessionTestResult is SessionTestResultSuccess {
  return sessionTestResult.queryStatus === 'success';
}

export function isSessionTestResultNotFound(
  sessionTestResult: SessionTestResult
): sessionTestResult is SessionTestResultNotFound {
  return sessionTestResult.queryStatus === 'notFound';
}

export function isSessionTestResultError(
  sessionTestResult: SessionTestResult
): sessionTestResult is SessionTestResultError {
  return sessionTestResult.queryStatus === 'error';
}
