import { LeapDataType } from '@tensorleap/api-client';

import { Position } from '../core/position';
import { NodePropertyType } from './networkStateUtils';

export const DUPLICATION_OFFSET: Position = [50, 50];

export const LOSS_NODE_NEEDS_OPTI_MESSAGE =
  'The Loss node must be connected to an Optimizer node';

export const IMAGE_VISUALIZER_PROPERTIES: Readonly<
  Record<string, NodePropertyType>
> = {
  name: 'Image',
  visualizer_name: 'Image',
  visualizer_type: LeapDataType.Image,
  arg_names: ['data'],
};
