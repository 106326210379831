import { useCallback, useEffect, useMemo, useState } from 'react';
import { useVersionControl } from '../../core/VersionControlContext';
import { SessionRunSummaryData } from './TabModelSummary';
import { useModelFilter } from './useModelFilter';
import { max, min } from 'lodash';

export function useSelectedModelSummary({
  epochBySessionRunId,
}: {
  epochBySessionRunId: Map<string, number>;
}): SessionRunSummaryData[] {
  const { selected: selectedSessionRuns } = useModelFilter();
  const { getSessionRunEpochs } = useVersionControl();
  return useMemo(
    () =>
      selectedSessionRuns.map((sessionRun, i) => {
        const epochs = getSessionRunEpochs(sessionRun.id);
        const minEpoch = min(epochs) || 0;
        const maxEpoch = max(epochs) || 0;
        return {
          sessionRun,
          dataset: `MyDataset ${i}`, // todo: update to get from test results once node returns this value
          epoch: {
            selected: epochBySessionRunId.get(sessionRun.id) ?? 0,
            max: maxEpoch,
            min: minEpoch,
            best: maxEpoch, // todo: currently supporting best = max, need to update logic to select best epoc
          },
        };
      }),
    [selectedSessionRuns, getSessionRunEpochs, epochBySessionRunId]
  );
}

export function useSelectedModelSummaryFilter() {
  const { selected: selectedSessionRuns } = useModelFilter();
  const { getSessionRunEpochs, versions } = useVersionControl();

  const maxEpochBySessionRunId = useMemo(
    () =>
      new Map(
        selectedSessionRuns.map(({ id }) => [
          id,
          max(getSessionRunEpochs(id)) || 0,
        ])
      ),
    [selectedSessionRuns, getSessionRunEpochs]
  );

  const [
    selectedEpochBySessionRunId,
    setSelectedEpochBySessionRunId,
  ] = useState(maxEpochBySessionRunId);

  useEffect(() => {
    setSelectedEpochBySessionRunId(maxEpochBySessionRunId);
  }, [maxEpochBySessionRunId, setSelectedEpochBySessionRunId, versions]);

  const updateEpochChange = useCallback((id: string, updatedEpoch: number) => {
    setSelectedEpochBySessionRunId((oldData) => {
      const newMap = new Map(oldData);
      newMap.set(id, updatedEpoch);
      return newMap;
    });
  }, []);

  return {
    updateEpochChange,
    epochBySessionRunId: selectedEpochBySessionRunId,
  };
}
