import {
  InteractiveTooltip,
  InteractiveTooltipProps,
} from '../../ui/molecules/InteractiveTooltip';
import {
  CategoriesSelector,
  CategoriesSelectorProps,
} from './CategoriesSelector';

type CategoriesSelectorPopupProps = CategoriesSelectorProps & {
  open: boolean;
  onClose: () => void;
  children: InteractiveTooltipProps['children'];
};

export function CategoriesSelectorPopup({
  open,
  onClose,
  children,
  ...hubCategoriesProps
}: CategoriesSelectorPopupProps) {
  return (
    <InteractiveTooltip
      open={open}
      onClose={onClose}
      content={
        <CategoriesSelector className="max-h-80 w-96" {...hubCategoriesProps} />
      }
    >
      {children}
    </InteractiveTooltip>
  );
}
