import { useRef, useCallback, useEffect, useState, ForwardedRef } from 'react';

const isValueEmpty = (v?: unknown) => v === null || v === undefined || v === '';
export function useInputHasValueTracker<
  V,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  OC extends (_: any) => void,
  E extends HTMLElement
>(value?: V, onChange?: OC, ref?: ForwardedRef<E>) {
  const [isUncontrolledValueEmpty, setIsUncontrolledValueEmpty] = useState(
    isValueEmpty(value)
  );
  const isControlled = value !== undefined;
  const valuePropEmpty = isValueEmpty(value);
  const localRef = useRef<HTMLInputElement>();
  const hasValue = useCallback(
    () =>
      !isValueEmpty(localRef?.current?.value) ||
      (isControlled && !valuePropEmpty) ||
      (!isControlled && !isUncontrolledValueEmpty),
    [isControlled, isUncontrolledValueEmpty, valuePropEmpty]
  );
  const [inputHasValue, setInputHasValue] = useState(hasValue());

  const wrappedRef = useCallback(
    (inputRef) => {
      if (typeof ref === 'function') {
        ref(inputRef);
      } else if (ref) {
        ref.current = inputRef;
      }
      localRef.current = inputRef;
    },
    [ref]
  );

  useEffect(() => {
    setInputHasValue(hasValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValueEmpty(localRef?.current?.value), hasValue]);

  const onChangeHandler = useCallback(
    (e) => {
      !isControlled &&
        setIsUncontrolledValueEmpty(isValueEmpty(e.target.value));
      onChange?.(e);
    },
    [onChange, isControlled]
  );

  return {
    onChangeHandler,
    inputHasValue,
    wrappedRef,
  };
}
