import React, { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';
import { ErrorBoundary } from '../../core/ErrorBoundary';
import { Button } from '../atoms/Button';
import { XClose } from '../icons';

export interface CardHeaderProps {
  icon: JSX.Element;
  title: string | ReactNode;
  action?: ReactNode;
  className?: string;
  asPopup?: boolean;
  small?: boolean;
  uppercase?: boolean;
  onCloseClick?: () => void;
  tourId?: string;
}

export const CardHeader = React.forwardRef<HTMLDivElement, CardHeaderProps>(
  (
    {
      icon,
      title,
      action,
      className,
      asPopup = false,
      uppercase = true,
      small = false,
      onCloseClick,
      tourId,
    },
    ref
  ): JSX.Element => {
    return (
      <div
        className={clsx(
          'flex items-center p-3 bg-gray-850 text-gray-200 flex-row gap-1 rounded-t-lg relative',
          small ? 'h-8 text-2xs' : ' h-12',
          { uppercase },
          className
        )}
        ref={ref}
        id={tourId}
      >
        {icon}
        <div className="flex-1 pl-1">{title}</div>
        {action}
        {asPopup && (
          <>
            <Button
              className="ml-auto w-2 h-2"
              onClick={() => onCloseClick?.()}
              variant="text"
            >
              <XClose />
            </Button>
            <div className="w-5 h-5 absolute top-5 -right-2.5 rotate-45 bg-inherit border-white/10 border-solid border-r-2 border-t-2" />
          </>
        )}
      </div>
    );
  }
);
CardHeader.displayName = 'CardHeader';

export function CardBody({
  children,
  className,
}: PropsWithChildren<{ className?: string }>): JSX.Element {
  return (
    <div className={clsx('flex flex-1 h-fit items-center p-4', className)}>
      {children}
    </div>
  );
}

export function CardFooter({
  children,
  className,
  small,
}: PropsWithChildren<{ className?: string; small?: boolean }>): JSX.Element {
  return (
    <div
      className={clsx(
        'flex items-center p-4 bg-gray-850 rounded-b-lg',
        small ? 'h-8' : 'h-14',
        className
      )}
    >
      {children}
    </div>
  );
}

export type CardProps = PropsWithChildren<{
  className?: string;
  fixedSize?: boolean;
  tourId?: string;
}>;

export const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className, fixedSize = true, tourId, children }, ref): JSX.Element => {
    return (
      <ErrorBoundary>
        <div
          ref={ref}
          className={clsx(
            'flex flex-col bg-gray-800 border-gray-800 border-solid border rounded-lg shadow-4c',
            fixedSize && 'w-80',
            className
          )}
          id={tourId}
        >
          {children}
        </div>
      </ErrorBoundary>
    );
  }
);
Card.displayName = 'Card';
