import SvgProblemIcon from '../../../ui/icons/ProblemIcon';
import { useDatasets } from '../../../core/DatasetsContext';
import { useCallback, useMemo } from 'react';
import SvgOpenPanel from '../../../ui/icons/OpenPanel';
import { useNetworkMapContext } from '../../../core/NetworkMapContext';
import { MappingError } from '@tensorleap/api-client';
import {
  NetworkWizardData,
  GraphErrorKind,
  NetworkWizardCategory,
  NetworkWizardErrorSeverity,
} from '../types';
import {
  MAPPING_ERROR_TYPES_MAP,
  useMappingUtils,
} from '../../../core/mappingUtils';
import { NetworkTabsEnum } from '../../NetworkDrawer';

export function useDatasetMappingGeneralIssueMessageData(
  errorData: MappingError[]
): NetworkWizardData[] {
  const {
    setOpenNetworkTab,
    codeIntegrationIsExpanded,
    toggleCodeIntegrationExpanded,
  } = useNetworkMapContext();
  const { datasetMappingFileExists } = useDatasets();
  const { addOrUpdateNetworkMapping } = useMappingUtils();
  const createOrUpdateButtonText = useMemo(
    () => (datasetMappingFileExists ? 'Show' : 'Create'),
    [datasetMappingFileExists]
  );

  const onSelect = useCallback(() => {
    if (datasetMappingFileExists) {
      setOpenNetworkTab(NetworkTabsEnum.CodeIntegration);
      if (!codeIntegrationIsExpanded) {
        toggleCodeIntegrationExpanded();
      }
    } else {
      addOrUpdateNetworkMapping();
    }
  }, [
    addOrUpdateNetworkMapping,
    codeIntegrationIsExpanded,
    datasetMappingFileExists,
    setOpenNetworkTab,
    toggleCodeIntegrationExpanded,
  ]);

  const quickFixes = useMemo(
    () => [
      {
        title: createOrUpdateButtonText,
        onSelect,
        disableQuickFix: false,
        icon: <SvgOpenPanel className="w-5" />,
      },
      {
        title: 'Update',
        onSelect: addOrUpdateNetworkMapping,
        disableQuickFix: false,
        icon: <SvgOpenPanel className="w-5" />,
      },
    ],
    [addOrUpdateNetworkMapping, createOrUpdateButtonText, onSelect]
  );

  return useMemo(
    () =>
      errorData.map(({ title, message, type }) => {
        const warningType = MAPPING_ERROR_TYPES_MAP[type];
        const generalTitle = `Encountered an ${
          warningType === GraphErrorKind.datasetMappingApplyError
            ? 'error'
            : 'warning'
        } while trying to create, update or apply dataset mapping`;

        const calculateKey = () => message + (title || generalTitle);

        return {
          errorType: GraphErrorKind.datasetMappingApplyError,
          category: NetworkWizardCategory.CODE,
          title: title || generalTitle,
          message,
          calculateKey,
          showNodeFooter: true,
          quickFixes: quickFixes,
          headerIcon: <SvgProblemIcon />,
          errorSeverity: NetworkWizardErrorSeverity.WARNING,
          key: calculateKey(),
        };
      }),

    [errorData, quickFixes]
  );
}
