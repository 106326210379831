import { useMemo } from 'react';
import { List } from '../ui/mui';
import { LoadProjectItem } from './LoadProjectItem';
import { Logout, UserCircle } from '../ui/icons';
import { useAuth } from '../auth/AuthContext';
import { StateObject } from '../core/types';
import { MenuItem, MenuItemData } from './LeftMenu';

export interface RightMenuProps {
  userManagerDialogState: StateObject<boolean>;
}

export function RightMenu({
  userManagerDialogState,
}: RightMenuProps): JSX.Element {
  const { logout } = useAuth();

  const menuItems = useMemo<MenuItemData[]>(() => {
    const menuOptions = [
      {
        title: 'EDIT PROFILE',
        icon: <UserCircle />,
        onClick: () => userManagerDialogState.setState(true),
      },
      {
        title: 'LOG OUT',
        icon: <Logout />,
        onClick: logout,
      },
    ];

    return menuOptions;
  }, [logout, userManagerDialogState]);

  return (
    <div className="flex h-full flex-col bg-gray-800">
      <List className="flex-1">
        {menuItems.map(({ title, icon, onClick, hasSubmenu }, index) => {
          const hasDivider = index < menuItems.length - 1;
          const itemProps = { key: index, title, icon, onClick, hasDivider };

          return hasSubmenu ? (
            <LoadProjectItem {...itemProps} />
          ) : (
            <MenuItem {...itemProps} />
          );
        })}
      </List>
    </div>
  );
}
