import { VisualizationResponse } from '@tensorleap/api-client';
import { LoadingVisualizationPreview } from '../../LoadingVisualizationPreview';
import { ScatterAnalyzerItem } from '../../ScatterAnalyzerView';
import { ScatterDataProvider } from '../../ScatterDataContext';
import { MousePosition } from '../../../core/useSelectionGroup';

type ScatterLoaderProps = {
  projectId: string;
  epoch: number;
  sessionRunId: string;
  scatterVisualization?: VisualizationResponse;
  mousePosition: MousePosition;
};

export function ScatterLoader({
  scatterVisualization,
  projectId,
  epoch,
  sessionRunId,
  mousePosition,
}: ScatterLoaderProps) {
  return scatterVisualization ? (
    <ScatterDataProvider
      scatterVisualization={scatterVisualization}
      projectId={projectId}
      epoch={epoch}
      sessionRunId={sessionRunId}
    >
      <ScatterAnalyzerItem
        projectId={projectId}
        sessionRunId={sessionRunId}
        epoch={epoch}
        mousePosition={mousePosition}
        className="flex-1"
      />
    </ScatterDataProvider>
  ) : (
    <LoadingVisualizationPreview />
  );
}
