import MDEditor, { ICommand, commands } from '@uiw/react-md-editor';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useFileSelection } from '../core/useFileSelection';
import { ImageVideoIcon } from '../ui/icons';
import { useCurrentProject } from '../core/CurrentProjectContext';
import clsx from 'clsx';
import './md-textarea-override.css';

const IMG_FILE_TYPES = 'image/png, image/gif, image/jpeg, image/jpg';

export interface IssueMdTextareaParams {
  message: string | undefined;
  setMessage: Dispatch<SetStateAction<string | undefined>>;
  imagesToUpload: Record<string, File>;
  setImagesToUpload: Dispatch<SetStateAction<Record<string, File>>>;
  readOnly?: boolean;
  className?: string;
  textAreaClassName?: string;
}

export function IssueMdTextarea({
  message,
  setMessage,
  imagesToUpload,
  setImagesToUpload,
  readOnly = false,
  className,
  textAreaClassName,
}: IssueMdTextareaParams): JSX.Element {
  const { fetchValidProjectCid } = useCurrentProject();
  const projectId = fetchValidProjectCid();

  const onFileChange = useCallback(
    async (files: File[]) => {
      if (!projectId) {
        console.error('Shouldnt be reached, no project selected');
        return;
      }
      const newImageUrls: string[] = [];
      const newImagesToUploadMap = files.reduce((acc, file) => {
        const tempUrl = URL.createObjectURL(file);
        acc[tempUrl] = file;
        newImageUrls.push(tempUrl);
        return acc;
      }, imagesToUpload);
      setImagesToUpload(newImagesToUploadMap);
      const imgElements = newImageUrls
        .map(
          (imgUrl) =>
            `${
              message ? '\n' : ''
            }<img width="auto" height="auto" src="${imgUrl}" />\n`
        )
        .join('');

      const updatedContentValue = `${message}${imgElements}`;
      setMessage(updatedContentValue);
    },
    [projectId, imagesToUpload, setImagesToUpload, message, setMessage]
  );
  const { openFileSelection } = useFileSelection({
    onFileChange,
    multiple: true,
    accept: IMG_FILE_TYPES,
  });
  const addImg: ICommand = {
    name: 'addImg',
    keyCommand: 'addImg',
    buttonProps: { 'aria-label': 'Add Image' },
    icon: (
      <div className="flex justify-center items-center h-3 w-3 relative">
        <ImageVideoIcon className="h-5 w-5 absolute" />
      </div>
    ),
    execute: async () => {
      openFileSelection();
    },
  };
  const toolbarLeftSide = [
    commands.bold,
    commands.italic,
    commands.title,
    commands.strikethrough,
    commands.divider,
    commands.code,
    commands.codeBlock,
    commands.comment,
    commands.divider,
    commands.orderedListCommand,
    commands.unorderedListCommand,
    commands.divider,
    commands.hr,
    commands.link,
    commands.divider,
    addImg,
  ];
  const toolbarRightSide = [commands.codePreview, commands.codeEdit];

  const editMode = useMemo(() => (readOnly ? 'preview' : 'edit'), [readOnly]);
  return (
    <MDEditor
      className={clsx(
        'border-none !min-h-24 max-h-screen overflow-y-auto md-textarea',
        className
      )}
      value={message}
      onChange={setMessage}
      preview={editMode}
      extraCommands={toolbarRightSide}
      commands={toolbarLeftSide}
      hideToolbar={readOnly}
      previewOptions={{
        className: clsx(
          '!relative h-full !bg-gray-800 !list-disc',
          textAreaClassName
        ),
      }}
    />
  );
}
