import {
  useModelTestsSummary,
  UseModelTestsSummaryReturn,
} from './useModelTestSummaryResult';
import { useModelTests, UseModelTestsReturn } from './useModelTests';
import {
  useModelTestsResults,
  useModelTestsResultsQuery,
  UseModelTestsResultsReturn,
} from './useModelTestsResults';

export type UseModelTestStateReturn = {
  tests: UseModelTestsReturn;
  testsSummary: UseModelTestsSummaryReturn;
  testsResult: UseModelTestsResultsReturn;
};

export function useModelTestState(): UseModelTestStateReturn {
  const testsResultsQuery = useModelTestsResultsQuery();

  const testsResult = useModelTestsResults(testsResultsQuery);
  const tests = useModelTests(testsResult.refetch);
  const testsSummary = useModelTestsSummary({ testsResult, testsResultsQuery });

  return {
    tests,
    testsSummary,
    testsResult,
  };
}
