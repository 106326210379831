import { DependencyList, UIEvent, useCallback, useRef } from 'react';

export function stopPropagation(event: UIEvent<HTMLElement>) {
  event.stopPropagation();
}

export function useStopPropagation<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (event: UIEvent<HTMLElement>, ...args: any[]) => any
>(func: T, depnds: DependencyList): T {
  const funcRef = useRef(func);
  funcRef.current = func;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useCallback((e: UIEvent<HTMLElement>, ...args: any[]) => {
    stopPropagation(e);
    return funcRef.current(e, ...args);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, depnds) as T;
}
