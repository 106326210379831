import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgAddDb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="m13.441 11.638 3.797-8.315c.235-.526.117-.92-.351-1.183-.492-.263-.89-.155-1.196.322L10.734 10.6c-.375.55-.398 1.11-.07 1.684.281.478.691.717 1.23.717.704 0 1.22-.454 1.547-1.362ZM17.45 5.688c.398-.382.773-.37 1.124.036C20.191 7.492 21 9.62 21 12.104a.796.796 0 0 1-.246.573.708.708 0 0 1-.527.216.877.877 0 0 1-.598-.216.866.866 0 0 1-.211-.573c0-2.031-.668-3.787-2.004-5.269-.352-.43-.34-.812.035-1.147ZM13.195 4.219a.702.702 0 0 1-.562.179l-.317-.036c-.21 0-.316.012-.316.036-2.063 0-3.82.753-5.273 2.258-1.43 1.505-2.145 3.333-2.145 5.484a.797.797 0 0 1-.246.573c-.14.144-.328.215-.563.215a.83.83 0 0 1-.562-.215A.866.866 0 0 1 3 12.14c0-2.58.879-4.78 2.637-6.595 1.758-1.84 3.879-2.76 6.363-2.76.07 0 .188.012.352.036h.421c.211.024.387.12.528.287.14.167.199.358.176.573a.807.807 0 0 1-.282.538Z"
      fill="currentColor"
    />
    <path
      d="M2 1h20a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-7v1h7a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7v-1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Z"
      fill="currentColor"
    />
    <path
      d="M16.904 19.5a1.069 1.069 0 0 0-.335-.796 1.087 1.087 0 0 0-.796-.335l-2.648.006.006-2.648c0-.307-.112-.572-.335-.796a1.098 1.098 0 0 0-1.591 0 1.087 1.087 0 0 0-.336.796l.006 2.648-2.648-.006c-.307 0-.572.112-.795.335a1.114 1.114 0 0 0-.349.796c.005.302.12.568.349.795.223.224.488.336.795.336l2.648-.006-.006 2.648c0 .306.112.572.335.795a1.098 1.098 0 0 0 1.591 0c.224-.223.336-.489.336-.795l-.006-2.648 2.648.006c.307 0 .572-.112.796-.336.227-.227.34-.493.335-.795Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgAddDb;
