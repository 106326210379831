import { Node } from '@tensorleap/engine-contract';
import { UIComponent, UI_COMPONENTS } from '../../core/types/ui-components';
import { getOrSetDefault } from '../../core/map-helper';
import { COMPONENT_DESCRIPTORS_MAP } from '../interfaces';
import { CONDITIONAL_LABEL_DESCRIPTORS, LABEL_DESCRIPTORS } from './labels';
import { NodeDescriptorName, NODE_DESCRIPTORS } from './nodes';
import { NODE_TYPE_DESCRIPTORS } from './nodeTypes';
import {
  BaseDescriptor,
  NodeLabels,
  NodeTypes,
  NodeWithLabels,
  UpdateStateCtx,
} from './types';
import { Connection } from '../interfaces/Connection';
import {
  isUserUniqueNameDuplicated,
  USER_NAME_ALREADY_EXISTED_MSG,
  USER_UNIQUE_NAME,
} from '../../layer-details/UserUniqueName';
import { getInputsData } from '../utils';
import {
  isInputNode,
  isInputsNode,
  isOptimizerNode,
  isVisualizerNode,
} from '../graph-calculation/utils';
import { NodePresentationState } from '../graph-calculation/contract';
import { LOSS_NODE_NEEDS_OPTI_MESSAGE } from '../consts';
import {
  GraphNodeErrorType,
  OneOfGraphErrorTypes,
  ERROR_TYPE_TO_COMP_MAP,
} from '../wizard/errors';
import { GraphErrorKind } from '../wizard/types';

export function useDescriptors({
  name,
  data: { type },
  labels,
}: NodeWithLabels): BaseDescriptor[] {
  return getDescriptors({ name, type, labels });
}

export interface getDescriptorsProps {
  name: NodeWithLabels['name'];
  type: NodeWithLabels['data']['type'];
  labels: NodeWithLabels['labels'];
}

export function getDescriptors({
  name,
  type,
  labels = [],
}: getDescriptorsProps): BaseDescriptor[] {
  const descriptors: (BaseDescriptor | undefined)[] = [
    NODE_TYPE_DESCRIPTORS[type as NodeTypes],
    NODE_DESCRIPTORS[name as NodeDescriptorName],
    ...labels.map((label) => LABEL_DESCRIPTORS[label as NodeLabels]),
    ...CONDITIONAL_LABEL_DESCRIPTORS.filter(({ hasLabel }) => hasLabel(name)),
  ];

  return descriptors.filter(
    (d: BaseDescriptor | undefined): d is BaseDescriptor => !!d
  );
}

export function getNodeDataDisaplyName(node: Node) {
  const nodeName = node.name;
  const dataName = node.data.name;
  return `${nodeName}${dataName ? ` - ${dataName}` : ''}`;
}

export function updateStateIfPropNotExisted(
  node: Readonly<Node>,
  propName: string,
  propLabel: string,
  nodeStates: ROMap<string, NodePresentationState>
): ROMap<string, NodePresentationState> {
  return setOrRemoveErrorFromNodeStates(
    !!node.data[propName],
    {
      type: GraphErrorKind.nodeAttr,
      msg: `${propLabel} was not selected`,
      nodeId: node.id,
      attrName: propName,
    },
    node.id,
    nodeStates
  );
}

export function updateStateIfNotAllNodeInputsConnected(
  node: Node,
  { connectionsByInputId, setNodeStates }: UpdateStateCtx
) {
  setNodeStates((current) => {
    const nodeDescriptor = COMPONENT_DESCRIPTORS_MAP.get(node.name);
    if (!nodeDescriptor) {
      console.warn(
        `[AllNodeInputsConnected] Not found component descriptor for node name: ${node.name}`
      );
      return current;
    }

    const connections = connectionsByInputId[node.id];
    const connectionsNames = new Set(
      connections?.map(({ inputName }) => inputName)
    );
    const { inputsData } = getInputsData(node, nodeDescriptor);
    const disconnectedInput = inputsData
      .filter(({ name }) => !connectionsNames?.has(name))
      .map(({ name }) => name);

    return setOrRemoveErrorFromNodeStates(
      !disconnectedInput.length,
      {
        type: GraphErrorKind.node,
        nodeId: node.id,
        msg: `Missing input${
          disconnectedInput.length > 1 ? 's' : ''
        } (${disconnectedInput.join(', ')})`,
      } as GraphNodeErrorType,
      node.id,
      current
    );
  });
}

export function updateStateIfNotConnectedToOptimizer(
  node: Node,
  { nodes, setNodeStates, connectionsByOutputId }: UpdateStateCtx
) {
  setNodeStates((current) => {
    const outputNodes = connectionsByOutputId[node.id] || [];
    const hasOptimizerOutput = outputNodes.some(({ inputNodeId }) => {
      const connectedTo = nodes.get(inputNodeId);
      return !!connectedTo && isOptimizerNode(connectedTo);
    });

    return setOrRemoveErrorFromNodeStates(
      hasOptimizerOutput,
      {
        type: GraphErrorKind.node,
        msg: LOSS_NODE_NEEDS_OPTI_MESSAGE,
        nodeId: node.id,
      } as GraphNodeErrorType,
      node.id,
      current
    );
  });
}

export function setOrRemoveErrorFromNodeStates(
  isOk: boolean,
  error: OneOfGraphErrorTypes,
  nodeId: string,
  nodeStates: ROMap<string, NodePresentationState>
): ROMap<string, NodePresentationState> {
  const newNodeStates = new Map(nodeStates);

  const nodeState = getOrSetDefault(
    newNodeStates,
    nodeId,
    {} as NodePresentationState
  );

  let nodeStateErrorKey = '';
  if (nodeState.error?.type) {
    const wizardDataFetcher = ERROR_TYPE_TO_COMP_MAP[nodeState.error.type];
    const wizardData = wizardDataFetcher({
      ...nodeState.error,
      message: nodeState.error.msg,
      nodeId: (nodeState.error as OneOfGraphErrorTypes).nodeId,
    });
    nodeStateErrorKey = wizardData
      .map(({ key }) => key)
      .sort()
      .join(', ');
  }

  let errorKey = '';
  if (error?.type) {
    const wizardDataFetcher = ERROR_TYPE_TO_COMP_MAP[error.type];
    const wizardData = wizardDataFetcher({
      ...error,
      message: error.msg,
      nodeId: (error as OneOfGraphErrorTypes).nodeId,
    });
    errorKey = wizardData
      .map(({ key }) => key)
      .sort()
      .join(', ');
  }

  if (isOk && nodeStateErrorKey === errorKey) {
    nodeState.error = undefined;
  } else if (nodeStateErrorKey !== errorKey) {
    nodeState.error = error;
  }

  return newNodeStates;
}

export function validateNodes(ctx: UpdateStateCtx) {
  for (const node of Array.from(ctx.nodes.values())) {
    validateNode(node, ctx);
  }
}

function validateNode(node: NodeWithLabels, updateStateCtx: UpdateStateCtx) {
  const {
    name,
    data: { type },
    labels,
  } = node;
  getDescriptors({ name, type, labels }).forEach((d) => {
    d.updateState?.(node, updateStateCtx);
  });
}

type HasNodeTypeInput = {
  node: Node;
  nodes: ROMap<Node['id'], Node>;
  connectionsByOutputId: Record<Node['id'], Connection[]>;
  predicate: (_: Node) => boolean;
};
export function hasNodeType({
  node: { id: nodeId },
  nodes,
  connectionsByOutputId,
  predicate,
}: HasNodeTypeInput): boolean {
  const outputNodes = connectionsByOutputId[nodeId] || [];
  return outputNodes.some(({ inputNodeId }) => {
    const connectedTo = nodes.get(inputNodeId);
    return !!connectedTo && predicate(connectedTo);
  });
}

export function hasVisualizerConnectedToInput(
  nodes: ROMap<Node['id'], Node>,
  connectionsByOutputId: { [outputId: string]: Connection[] }
): boolean {
  return Array.from(nodes.values()).some((node) => {
    if (!isInputNode(node) && !isInputsNode(node)) return false;

    return hasNodeType({
      node,
      nodes,
      connectionsByOutputId,
      predicate: isVisualizerNode,
    });
  });
}

export function updateStateIfUserUniqueNameIsInvalid(
  node: Node,
  { nodes, setNodeStates }: Pick<UpdateStateCtx, 'nodes' | 'setNodeStates'>
) {
  const name = node.data[USER_UNIQUE_NAME];
  const hasName = !!name;

  setNodeStates((current) => {
    return setOrRemoveErrorFromNodeStates(
      hasName,
      {
        type: GraphErrorKind.nodeAttr,
        msg: 'Name is required',
        nodeId: node.id,
        attrName: USER_UNIQUE_NAME,
      },
      node.id,
      current
    );
  });

  if (hasName) {
    setNodeStates((current) => {
      const isNameNotDuplicated = !isUserUniqueNameDuplicated(
        name,
        node.id,
        nodes
      );
      return setOrRemoveErrorFromNodeStates(
        isNameNotDuplicated,
        {
          type: GraphErrorKind.nodeAttr,
          msg: USER_NAME_ALREADY_EXISTED_MSG,
          nodeId: node.id,
          attrName: USER_UNIQUE_NAME,
        },
        node.id,
        current
      );
    });
  }
}

export function areAllInputsConnected({
  node,
  componentName,
  connectionsByInputId,
}: {
  node: Node;
  componentName: UIComponent['name'];
  connectionsByInputId: Record<Node['id'], Connection[]>;
}): boolean {
  const connectedInputs = connectionsByInputId[node.id]?.length || 0;

  let possibleInputs: number;
  if (Array.isArray(node.data.arg_names)) {
    possibleInputs = node.data.arg_names.length;
  } else {
    possibleInputs =
      UI_COMPONENTS.find((c) => c.name === componentName)?.inputs_data.inputs
        .length || 0;
  }

  return connectedInputs === possibleInputs;
}
