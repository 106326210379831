import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgFakeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M3.975 5.775 8.75 1l4.775 4.775a6.559 6.559 0 0 1 1.471 2.215 6.752 6.752 0 0 1 0 5.121 6.574 6.574 0 0 1-1.47 2.21 6.752 6.752 0 0 1-7.336 1.477 6.634 6.634 0 0 1-2.215-1.478 6.751 6.751 0 0 1-1.47-7.33 6.676 6.676 0 0 1 1.47-2.215Zm8.49 1.06L8.75 3.122 5.035 6.836c-.5.504-.88 1.078-1.142 1.723A5.249 5.249 0 0 0 3.5 10.55a5.18 5.18 0 0 0 1.535 3.709 5.249 5.249 0 0 0 7.43 0 5.103 5.103 0 0 0 1.142-1.717 5.249 5.249 0 0 0 0-3.984 5.088 5.088 0 0 0-1.142-1.723Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFakeIcon;
