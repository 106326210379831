import clsx from 'clsx';
import { useMemo } from 'react';
import { useFreemium } from '../../trial/useFreemium';

export type Variant = 'regular' | 'upgrade';

export interface FlatCountdownProps {
  variant?: Variant;
  className?: string;
}

export function FlatCountdown({
  variant = 'regular',
  className,
}: FlatCountdownProps): JSX.Element {
  const { daysLeft } = useFreemium();
  const countDownStyle = useMemo(() => {
    const borderStyle = 'rounded-md px-2';
    if (variant === 'upgrade') {
      return ['bg-success-950', borderStyle].join(' ');
    } else {
      return daysLeft <= 1
        ? ['bg-error-975', borderStyle].join(' ')
        : ['bg-primary-925', borderStyle].join(' ');
    }
  }, [daysLeft, variant]);

  return (
    <div
      className={clsx(
        'flex flex-row h-8 w-fit rounded-none overflow-hidden',
        className
      )}
    >
      <div
        className={clsx(
          'flex border-[1.5px] border-gray-600 border-solid z-0',
          countDownStyle
        )}
      >
        <span className="uppercase h-full w-full justify-center items-center flex tracking-ultrawide text-gray-300 select-none px-4">
          {daysLeft} days left
        </span>
      </div>
    </div>
  );
}
