import { RefObject, useEffect, useState } from 'react';

const LEFT_MOUSE_BUTTON = 0;

export function useIsMouseDown<T extends HTMLElement>(
  domTarget: RefObject<T>
): boolean {
  const [isMouseDown, setIsMouseDown] = useState(false);
  useEffect(() => {
    const target = domTarget.current;
    if (!target) return;

    function onMouseDown(e: MouseEvent): void {
      if (e.button === LEFT_MOUSE_BUTTON) {
        setIsMouseDown(true);
      }
    }
    function onMouseUp(): void {
      setIsMouseDown(false);
    }

    target.addEventListener('mousedown', onMouseDown);
    target.addEventListener('mouseup', onMouseUp);

    return () => {
      target.removeEventListener('mousedown', onMouseDown);
      target.removeEventListener('mouseup', onMouseUp);
    };
  }, [domTarget]);

  return isMouseDown;
}
