import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgExitFullScreenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.09 1a.727.727 0 0 1 .728.727v4.364a.727.727 0 0 1-.727.727H1.727a.727.727 0 1 1 0-1.454h3.637V1.727A.727.727 0 0 1 6.09 1Zm5.82 0a.727.727 0 0 1 .726.727v3.637h3.637a.727.727 0 1 1 0 1.454h-4.364a.727.727 0 0 1-.727-.727V1.727A.727.727 0 0 1 11.909 1ZM1 11.91a.727.727 0 0 1 .727-.728h4.364a.727.727 0 0 1 .727.727v4.364a.727.727 0 1 1-1.454 0v-3.637H1.727A.728.728 0 0 1 1 11.91Zm10.182 0a.727.727 0 0 1 .727-.728h4.364a.727.727 0 1 1 0 1.454h-3.637v3.637a.728.728 0 0 1-1.454 0v-4.364Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgExitFullScreenIcon;
