import useSWR, { KeyedMutator } from 'swr';
import { Project } from '@tensorleap/api-client';
import api from '../api-client';
import { CacheKey, REFRESH_INTERVALS } from './consts';
import { useMergedObject } from '../useMergedObject';

export interface UseFetchProjects {
  projects?: Project[];
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<Project[]>;
}

async function fetchProjects(): Promise<Project[]> {
  const projectsResponse = await api.getProjects();
  const projects = projectsResponse.data.sort((a, b) =>
    a.lastAccessed > b.lastAccessed ? -1 : 1
  );
  return projects;
}

export function useFetchProjects(): UseFetchProjects {
  const { data: projects, error, mutate } = useSWR<Project[], Error>(
    CacheKey.PROJECTS,
    fetchProjects,
    { refreshInterval: REFRESH_INTERVALS.projects }
  );

  return useMergedObject({
    projects: projects || undefined,
    error,
    isLoading: !error && !projects,
    refetch: mutate,
  });
}
