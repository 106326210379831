import { PropsWithChildren, ReactNode } from 'react';
import { Dialog } from '@headlessui/react';

import { XClose } from '../icons';
import { Button } from '../atoms/Button';
import { StateObject } from '../../core/types';
import { PopupTransitionDialog } from '../molecules/PopupTransitionDialog';
import clsx from 'clsx';

const DIALOG_PANEL_CLASSNAME = [
  'px-16 !py-16',
  'before:absolute before:inset-0 before:-z-20 before:rounded-3xl',
  'before:[background-image:radial-gradient(104.85%_104.85%_at_50%_77.53%,#000000_0%,#000000_65.62%,#007DFF_100%)]',
  'after:absolute after:inset-0 after:top-1/3 after:-z-10 after:rounded-b-3xl',
  'after:[background-image:linear-gradient(to_bottom,rgba(0,0,0,0)_0%,#171717_83.55%,#414141_100%)]',
  'after:[box-shadow:0px_25px_50px_rgba(0,0,0,0.5),0px_50px_100px_rgba(0,0,0,0.5)]',
].join(' ');

export type PopupPaneProps = PropsWithChildren<{
  openState: StateObject;
  persist?: boolean;
  className?: string;
  showClose?: boolean;
}>;
export function PopupPane({
  children,
  persist = false,
  openState: { state: isOpen, setState: setIsOpen },
  className,
  showClose,
}: PopupPaneProps) {
  return (
    <PopupTransitionDialog
      open={isOpen || persist}
      onOpenChange={setIsOpen}
      dialogPanelClassName={[DIALOG_PANEL_CLASSNAME, className].join(' ')}
      persist={persist}
    >
      {showClose && (
        <Button
          className="absolute left-[95%] top-6 inset-0 w-6 h-6"
          variant="text"
          onClick={() => setIsOpen(false)}
        >
          <XClose className="text-gray-500" />
        </Button>
      )}

      {children}
    </PopupTransitionDialog>
  );
}

type PopupPaneTitleProps = {
  className?: string;
  children: ReactNode;
};
export function PopupPaneTitle({ className, children }: PopupPaneTitleProps) {
  return (
    <Dialog.Title className={clsx('text-5xl tracking-wider', className)}>
      {children}
    </Dialog.Title>
  );
}
