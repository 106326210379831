import {
  FetchSimilarFilterDisplayData,
  SampleIdentity,
} from '@tensorleap/api-client';
import { Card, CardBody, CardHeader } from '../ui/molecules/Card';
import { GridMagnet } from '../ui/icons';
import { groupBy } from 'lodash';
import { CodeTextArea } from '../ui/atoms/CodeTextArea';
import { ModelChip } from '../ui/molecules/ModelChip';
import { useModelFilter } from '../ui/molecules/useModelFilter';
import { useMemo } from 'react';

export type FetchSimilarPopupProps = FetchSimilarFilterDisplayData;

export function FetchSimilarPopup({
  limit,
  sampleIds,
  epoch,
  filtersUsed,
  sessionRun,
}: FetchSimilarPopupProps) {
  const { selected: selectedSessionRuns } = useModelFilter();

  const modelUniqueKey = useMemo(() => {
    const selectedSessionRun = selectedSessionRuns.find(
      (sr) => sr.id === sessionRun.id
    );
    if (!selectedSessionRun) {
      return -1;
    }
    return selectedSessionRun.modelUniqueKey;
  }, [selectedSessionRuns, sessionRun.id]);

  return (
    <Card fixedSize={false} className="w-fit">
      <CardHeader
        icon={<GridMagnet className="text-warning-400" />}
        title={'FETCH SIMILAR'}
        className="border-b-2 text-sm text-warning-400"
      />
      <CardBody className="text-sm">
        <div className="flex flex-col gap-2 min-w-[320px] max-w-[400px] capitalize">
          <div className="flex flex-row justify-between w-full">
            <span>Samples Fetched: </span>
            <span>{limit}</span>
          </div>

          <div className="flex flex-row justify-between w-full">
            <span>Model:</span>
            <ModelChip
              name={sessionRun.name}
              id={sessionRun.id}
              sessionId={sessionRun.sessionId}
              visible={true}
              modelUniqueKey={modelUniqueKey}
            />
          </div>

          <div className="flex flex-row justify-between w-full">
            <span>From epoch:</span>
            <span>{epoch}</span>
          </div>

          {filtersUsed.length > 0 && (
            <div className="flex flex-col gap-1">
              <span>Filters used:</span>
              <div className="flex flex-row w-full gap-2 flex-wrap">
                {filtersUsed.map((filter, index) => (
                  <span
                    key={index}
                    className="border-2 border-gray-700 text-gray-400 rounded-lg w-fit px-2 py-1"
                  >
                    {filter}
                  </span>
                ))}
              </div>
            </div>
          )}

          <div className="flex flex-col gap-1">
            <span>Similar to:</span>
            <CodeTextArea
              text={calcSamplesIdsText(sampleIds)}
              className="capitalize max-h-32"
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

function calcSamplesIdsText(sampleIds: SampleIdentity[]): string {
  return Object.entries(groupBy(sampleIds, (sample) => sample.state))
    .map(
      ([state, samples]) =>
        `${state}: ${samples
          .map(({ index }) => index)
          .sort((a, b) => a - b)
          .join(', ')}
      `
    )
    .join('\n');
}
