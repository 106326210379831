import { MagnetIcon } from '../../ui/icons';
import { useMemo } from 'react';
import { useDashletScatterContext } from '../dashlet/PopulationExploration/DashletScatterContext';
import { isFilterLoading } from '../../core/filters';
import {
  ProcessButton,
  ProcessButtonState,
} from '../../ui/atoms/ProcessButton';

export type FetchSimilarButtonProps = {
  onClick: () => void;
  buttonClassName?: string;
  tourId?: string;
};

export function FetchSimilarButton({
  onClick,
  buttonClassName,
  tourId,
}: FetchSimilarButtonProps): JSX.Element {
  const {
    filters: { dashletAndGlobalFilters },
  } = useDashletScatterContext();

  const isProcessing = useMemo(
    () => dashletAndGlobalFilters.some(isFilterLoading),
    [dashletAndGlobalFilters]
  );

  return (
    <ProcessButton
      processState={
        isProcessing
          ? ProcessButtonState.Processing
          : ProcessButtonState.Unstarted
      }
      onClick={onClick}
      className={buttonClassName}
      tourId={tourId}
    >
      <div className="flex flex-row overflow-hidden">
        <MagnetIcon className="mr-2 h-5" />
        fetch similar
      </div>
    </ProcessButton>
  );
}
