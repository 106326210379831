import { useCallback, useMemo } from 'react';
import { DatasetMask, parseDatasetVersion } from './dataset-helper';
import { NodeDetailsPanel, NoPreview } from './NodeDetalisPanel';
import { InputIcon, Settings } from '../ui/icons';
import { DetailsProps } from './NodeDetails';
import { Select } from '../ui/atoms/Select';
import { Input } from '../ui/atoms/Input';
import { Unreachable } from '../core/Errors';
import { useNetworkMapContext } from '../core/NetworkMapContext';
import { useCurrentProject } from '../core/CurrentProjectContext';

export function InputDetails({ node }: DetailsProps): JSX.Element {
  const {
    changeNodeProperty,
    updateConnection,
    currentDatasetSetup,
  } = useNetworkMapContext();
  const { selectedCodeIntegrationVersion } = useCurrentProject();

  const inputs = useMemo(() => {
    return parseDatasetVersion(
      DatasetMask.Inputs,
      currentDatasetSetup,
      selectedCodeIntegrationVersion?.metadata.modelSetup
    );
  }, [selectedCodeIntegrationVersion, currentDatasetSetup]);

  const selectedInput = useMemo(
    () => inputs.find(({ fnName }) => fnName === node.data.output_name),
    [inputs, node.data.output_name]
  );

  const handleChange = useCallback(
    (inputName?: string) => {
      if (!inputName) throw new Unreachable();
      changeNodeProperty({
        nodeId: node.id,
        nodeDataPropsToUpdate: { output_name: inputName },
      });
      updateConnection(node.id, undefined, [inputName]);
    },
    [changeNodeProperty, node, updateConnection]
  );

  return (
    <>
      <NodeDetailsPanel title="Properties" icon={<Settings />} openByDefault>
        <Select
          className="m-4"
          label="SELECTED INPUT"
          optionID="fnName"
          required
          optionToLabel={(o) => o.title}
          onChange={handleChange}
          value={node.data.output_name}
          options={inputs}
        />
      </NodeDetailsPanel>
      <NodeDetailsPanel
        className="gap-8 p-4"
        title="Preview"
        icon={<InputIcon />}
        openByDefault
      >
        {selectedInput ? (
          selectedInput.subItems.map(({ title, value }) => (
            <Input key={title} label={title} value={value} readOnly />
          ))
        ) : (
          <NoPreview />
        )}
      </NodeDetailsPanel>
    </>
  );
}
