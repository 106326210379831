import { List, ListItem, Popover, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import { ChangeEvent, useCallback, useState } from 'react';
import { parsePositiveInt } from '../../../actions-dialog/helper-functions';
import {
  DownSelectedIcon,
  LegendIcon,
  UpSelectedIcon,
  ViIcon,
} from '../../icons';

export enum SortTypeEnum {
  ASC_ALPHABETICALLY,
  DESC_ALPHABETICALLY,
  ASC_BY_PRESENCE,
  DESC_BY_PRESENCE,
}

const listItemClassName = 'grid grid-cols-8';
const viIconClassName = 'col-start-1';
const headerClassName =
  'col-start-2 col-end-6 font-semibold text-base tracking-normal';
const orderDescIconClassName = 'col-start-7';
const orderAscIconClassName = 'col-start-8';
const selectedOrderByIcon = 'bg-primary-975 rounded-full';
const longTailTextBoxClassName = 'col-start-7 col-end-8 w-20';

export interface LabelsLegendMenuProps {
  sortMethod: SortTypeEnum;
  setSortMethod: (_: SortTypeEnum) => void;
  showNames: boolean;
  toggleShowNames: () => void;
  truncateLongtail: number;
  setTruncateLongtail: (_: number) => void;
  clickShowAll?: () => void;
  clickHideAll?: () => void;
  showAppearancesOrder: boolean;
}

export function LabelsLegendMenu({
  sortMethod,
  setSortMethod,
  showNames,
  toggleShowNames,
  truncateLongtail,
  setTruncateLongtail,
  clickShowAll,
  clickHideAll,
  showAppearancesOrder,
}: LabelsLegendMenuProps): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const popoverState = usePopupState({
    variant: 'popover',
    popupId: null,
    disableAutoFocus: true,
  });

  const handleOpenMenu = useCallback((e: React.MouseEvent) => {
    setAnchorEl(e.target as HTMLElement);
    setIsMenuOpen(true);
  }, []);
  const handleCloseMenu = useCallback(() => setIsMenuOpen(false), []);

  const handleToggleSortAlphabetically = useCallback(() => {
    setSortMethod(
      sortMethod === SortTypeEnum.DESC_ALPHABETICALLY
        ? SortTypeEnum.ASC_ALPHABETICALLY
        : SortTypeEnum.DESC_ALPHABETICALLY
    );
  }, [setSortMethod, sortMethod]);

  const handleToggleSortByPresence = useCallback(() => {
    setSortMethod(
      sortMethod === SortTypeEnum.DESC_BY_PRESENCE
        ? SortTypeEnum.ASC_BY_PRESENCE
        : SortTypeEnum.DESC_BY_PRESENCE
    );
  }, [setSortMethod, sortMethod]);

  const handleTruncateLongtail = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTruncateLongtail(parsePositiveInt(e.target.value) || 1);
    },
    [setTruncateLongtail]
  );

  return (
    <div className="flex justify-end pointer-events-auto">
      <LegendIcon onClick={handleOpenMenu} className="m-1 cursor-pointer" />

      <Popover
        {...bindPopover(popoverState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        onMouseLeave={popoverState.close}
        PaperProps={{
          style: { overflowY: 'hidden', width: 'fit-content' },
        }}
      >
        <List className="w-80">
          <ListItem
            component="li"
            divider
            button
            onClick={handleToggleSortAlphabetically}
            className={listItemClassName}
          >
            {(sortMethod === SortTypeEnum.DESC_ALPHABETICALLY ||
              sortMethod === SortTypeEnum.ASC_ALPHABETICALLY) && (
              <ViIcon className={viIconClassName} />
            )}
            <p className={headerClassName}>Sort Alphabetically</p>
            <DownSelectedIcon
              className={clsx(
                orderDescIconClassName,
                sortMethod === SortTypeEnum.DESC_ALPHABETICALLY
                  ? selectedOrderByIcon
                  : ''
              )}
            />
            <UpSelectedIcon
              className={clsx(
                orderAscIconClassName,
                sortMethod === SortTypeEnum.ASC_ALPHABETICALLY
                  ? selectedOrderByIcon
                  : ''
              )}
            />
          </ListItem>

          {showAppearancesOrder && (
            <ListItem
              component="li"
              divider
              button
              onClick={handleToggleSortByPresence}
              className={listItemClassName}
            >
              {(sortMethod === SortTypeEnum.DESC_BY_PRESENCE ||
                sortMethod === SortTypeEnum.ASC_BY_PRESENCE) && (
                <ViIcon className={viIconClassName} />
              )}
              <p className={headerClassName}>Sort By Presence</p>
              <DownSelectedIcon
                className={clsx(
                  orderDescIconClassName,
                  sortMethod === SortTypeEnum.DESC_BY_PRESENCE &&
                    selectedOrderByIcon
                )}
              />
              <UpSelectedIcon
                className={clsx(
                  orderAscIconClassName,
                  sortMethod === SortTypeEnum.ASC_BY_PRESENCE &&
                    selectedOrderByIcon
                )}
              />
            </ListItem>
          )}

          <ListItem
            component="li"
            divider
            button
            onClick={toggleShowNames}
            className={listItemClassName}
          >
            {showNames && <ViIcon className={viIconClassName} />}
            <p className={headerClassName}>Show Names</p>
          </ListItem>

          {showNames && (
            <ListItem component="li" divider className={listItemClassName}>
              <p className={headerClassName}>Truncate Longtails:</p>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                value={truncateLongtail}
                onChange={handleTruncateLongtail}
                className={longTailTextBoxClassName}
              />
            </ListItem>
          )}
          {clickHideAll && (
            <ListItem
              component="li"
              divider
              button
              onClick={clickHideAll}
              className={listItemClassName}
            >
              <p className={headerClassName}>Hide All</p>
            </ListItem>
          )}
          {clickShowAll && (
            <ListItem
              component="li"
              divider
              button
              onClick={clickShowAll}
              className={listItemClassName}
            >
              <p className={headerClassName}>Show All</p>
            </ListItem>
          )}
        </List>
      </Popover>
    </div>
  );
}
