import clsx from 'clsx';
import { PopupButton as CalendlyPopupButton } from 'react-calendly';
import { HUBSPOT_CALENDAR_USER_URL } from '../../env.config';

export function CalendarSupportWidget(): JSX.Element {
  return (
    <div className="flex justify-center h-full -my-14">
      <div className="w-3/4 h-[605px]">
        <iframe
          src={HUBSPOT_CALENDAR_USER_URL}
          className="w-full h-full"
        ></iframe>
      </div>
    </div>
  );
}

export type CalendlyPopupVariant = 'button' | 'text';

export interface CalendlyPopupProps {
  variant: CalendlyPopupVariant;
  text: string;
  className?: string;
}

const popupStyle: Record<CalendlyPopupVariant, string> = {
  button: [
    'bg-transparent',
    'text-primary-500',
    'hover:bg-primary-300',
    'hover:text-primary-700',
    'active:bg-primary-100',
    'disabled:text-gray-400',
    'disabled:bg-transparent',
    'py-2.5',
    'px-5',
    'border border-primary-500',
    'disabled:border-gray-500',
  ].join(' '),
  text: 'cursor-pointer text-primary-500 hover:text-primary-800 px-2',
};

export function CalendarSupportPopup({
  variant,
  text,
  className = '',
}: CalendlyPopupProps): JSX.Element {
  return (
    <CalendlyPopupButton
      className={clsx(popupStyle[variant], className)}
      url={HUBSPOT_CALENDAR_USER_URL}
      rootElement={document.body}
      text={text}
    />
  );
}
