import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgVisible = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M20.8 12.908a10.03 10.03 0 0 1-1.648 2.089c-.63.61-1.343 1.148-2.14 1.617C15.432 17.538 13.762 18 12 18c-1.768 0-3.442-.465-5.022-1.396a11.726 11.726 0 0 1-2.14-1.617 9.971 9.971 0 0 1-1.637-2.08c-.275-.462-.275-.924 0-1.386a9.977 9.977 0 0 1 1.637-2.079 11.73 11.73 0 0 1 2.14-1.617c1.58-.93 3.254-1.396 5.022-1.396 1.768 0 3.439.465 5.012 1.396.797.469 1.51 1.008 2.14 1.617a9.8 9.8 0 0 1 1.647 2.08c.282.461.282.923 0 1.386ZM12 7.875c-.837 0-1.557.301-2.16.904a2.93 2.93 0 0 0-.893 2.15c0 .134.046.248.14.341.094.094.208.14.342.14a.465.465 0 0 0 .341-.14.465.465 0 0 0 .14-.341c0-.576.205-1.068.614-1.477.408-.408.9-.613 1.476-.613a.465.465 0 0 0 .342-.14.465.465 0 0 0 .14-.342.465.465 0 0 0-.14-.341.465.465 0 0 0-.342-.14Zm7.714 4.34c-1.017-1.58-2.293-2.763-3.827-3.546.409.69.613 1.443.613 2.26 0 1.239-.442 2.3-1.326 3.184-.877.877-1.935 1.316-3.174 1.316-1.232 0-2.293-.439-3.184-1.316-.877-.877-1.316-1.939-1.316-3.184 0-.817.204-1.57.613-2.26-1.534.783-2.81 1.965-3.827 3.545.89 1.38 2.005 2.475 3.345 3.285A8.287 8.287 0 0 0 12 16.714 8.235 8.235 0 0 0 16.36 15.5c1.339-.81 2.457-1.905 3.354-3.285Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgVisible;
