import { useCallback, useMemo, ReactNode } from 'react';

import {
  ToggleButton as ToggleButtonMui,
  ToggleButtonGroup as ToggleButtonGroupMui,
  Tooltip,
} from '../mui';
import { NumberOrString } from '@tensorleap/api-client';
import { OptionDefaultObj } from './utils/select';

export type ToggleButtonOption<
  TValue extends NumberOrString = string
> = OptionDefaultObj<TValue> & {
  title?: ReactNode;
  icon?: JSX.Element;
  disabled?: boolean;
};

export type ToggleButtonGroupProps<TValue extends NumberOrString = string> = {
  options: (TValue | ToggleButtonOption<TValue>)[];
  value: TValue;
  disabled?: boolean;
  className?: string;
  tooltipClassName?: string;
  onChange: (val: TValue) => void;
};
export function ToggleButtonGroup<TValue extends NumberOrString = string>({
  options,
  value,
  onChange,
  disabled: groupDisabled,
  className,
  tooltipClassName,
}: ToggleButtonGroupProps<TValue>) {
  const handleChange = useCallback(
    (_, newAlignment) => {
      onChange(newAlignment);
    },
    [onChange]
  );

  const mappedOption = useMemo<ToggleButtonOption<TValue>[]>(
    () =>
      options.map((option) => {
        if (typeof option === 'string' || typeof option === 'number') {
          return {
            label: String(option),
            value: option,
          };
        }
        return option as ToggleButtonOption<TValue>;
      }),
    [options]
  );

  return (
    <ToggleButtonGroupMui
      size="small"
      color="primary"
      aria-disabled={groupDisabled}
      className={className}
      value={[value]}
      exclusive
      onChange={handleChange}
    >
      {mappedOption.map(({ label, value, disabled, icon, title }) => {
        const key = String(value);
        const displayLabel = label ?? value;
        const showLabel = label || !icon;
        const showTooltip = title || !showLabel;

        const content = (
          <div className="flex gap-4 items-center">
            {icon ? icon : null}
            {showLabel && <span className="mt-1">{displayLabel}</span>}
          </div>
        );

        return (
          <ToggleButtonMui
            disabled={disabled || groupDisabled}
            key={key}
            value={value}
          >
            {showTooltip ? (
              <Tooltip
                classes={{ tooltip: tooltipClassName }}
                key={key}
                title={title ?? displayLabel}
              >
                {content}
              </Tooltip>
            ) : (
              content
            )}
          </ToggleButtonMui>
        );
      })}
    </ToggleButtonGroupMui>
  );
}
