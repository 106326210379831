import { Collapse, Popover, Tooltip } from '@material-ui/core';
import { Session, SlimVersion } from '@tensorleap/api-client';
import clsx from 'clsx';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { useMemo, useState, useRef, useEffect } from 'react';
import { useVersionControl } from '../../core/VersionControlContext';
import { Input } from '../../ui/atoms/Input';
import { List, ListItem } from '../../ui/atoms/List';
import { Alert, Down, FwArrowIcon, GitIcon, Up } from '../../ui/icons';
import { Selector, useMultiSelector } from '../../ui/model-list/selectors';
import { sessionHasTrainedEpochs } from '../helper-functions';
import { truncateLongtail } from '../../core/formatters/string-formatting';
import { Button } from '../../ui/atoms/Button';

export type ModelSelectorProps = {
  session?: Session;
  onChange: (session: Session, version: SlimVersion) => void;
  initialSelectedVersion: SlimVersion;
  getVersionDisabledMsg?: (version: SlimVersion) => string | undefined;
  allowCreateSession?: boolean;
  createNewSession?: boolean;
  onCreateAndSelectSession?: (version: SlimVersion) => void;
};

export function ModelSelector({
  session,
  onChange,
  getVersionDisabledMsg,
  initialSelectedVersion,
  allowCreateSession = false,
  createNewSession,
  onCreateAndSelectSession,
}: ModelSelectorProps) {
  const { versions } = useVersionControl();
  const popoverState = usePopupState({
    variant: 'popover',
    popupId: null,
  });

  const selectedVersion = useMemo(
    () =>
      versions.find(({ sessions }) =>
        sessions.some(({ cid }) => cid === session?.cid)
      ) || initialSelectedVersion,
    [versions, initialSelectedVersion, session]
  );

  return (
    <div className="flex-1 cursor-pointer rounded border border-gray-700">
      <div
        className="flex gap-2 p-2 w-full items-center"
        {...bindTrigger(popoverState)}
      >
        {selectedVersion && (
          <>
            <div className="max-w-[50%] text-gray-300 flex gap-2">
              <GitIcon />
              {truncateLongtail({
                value: selectedVersion?.notes,
                startSubsetLength: 10,
                endSubsetLength: 10,
              })}
            </div>
            <FwArrowIcon className="h-4 w-4" />
          </>
        )}
        {createNewSession ? (
          <span>new-session</span>
        ) : session === undefined ? (
          <span className="flex-1 text-gray-500 uppercase">select model</span>
        ) : (
          <div className="flex flex-1 gap-2">
            {truncateLongtail({
              value: session.modelName,
              startSubsetLength: 10,
              endSubsetLength: 10,
            })}
          </div>
        )}

        {popoverState.isOpen ? <Up /> : <Down />}
      </div>
      <Popover
        {...bindPopover(popoverState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 0 }}
        classes={{ paper: 'bg-gray-850 m-0 border border-gray-700' }}
      >
        <ModelOptions
          value={session}
          onChange={onChange}
          selectedVersion={selectedVersion}
          getVersionDisabledMsg={getVersionDisabledMsg}
          close={popoverState.close}
          versions={versions}
          allowCreateSession={allowCreateSession}
          onCreateAndSelect={onCreateAndSelectSession}
        />
      </Popover>
    </div>
  );
}

type VersionTitleProps = {
  expander: Selector<SlimVersion>;
  versionDisabledMsg?: string;
  version: SlimVersion;
};

function VersionTitle({
  expander,
  versionDisabledMsg,
  version,
}: VersionTitleProps) {
  return (
    <div
      onClick={() => expander.toggle(version)}
      className="px-3 py-2 cursor-pointer text-gray-300 flex gap-2 border-t border-t-gray-600"
    >
      {expander.isSelected(version) ? <Up /> : <Down />}
      <GitIcon />
      {truncateLongtail({
        value: version.notes,
        startSubsetLength: 15,
        endSubsetLength: 15,
      })}
      <span className="flex-1"></span>
      {versionDisabledMsg && (
        <Tooltip title={versionDisabledMsg}>
          <span>
            <Alert />
          </span>
        </Tooltip>
      )}
    </div>
  );
}

interface NoModelsProps {
  version?: SlimVersion;
  allowCreateSession?: boolean;
  onCreateAndSelect?: (version: SlimVersion) => void;
  close?: () => void;
}

function NoModels({
  version,
  allowCreateSession = false,
  onCreateAndSelect,
  close,
}: NoModelsProps) {
  return (
    <div className="flex flex-row px-3 w-full text-center text-gray-400 uppercase">
      <span className="pt-2">no models found</span>
      {allowCreateSession && !!version && !!onCreateAndSelect && !!close ? (
        <div className="flex-1 flex justify-end items-center">
          <Button
            variant="text"
            onClick={() => {
              onCreateAndSelect(version);
              close();
            }}
          >
            create & select
          </Button>
        </div>
      ) : (
        <span />
      )}
    </div>
  );
}

type ModelOptionsProps = {
  close: () => void;
  versions: SlimVersion[];
  selectedVersion?: SlimVersion;
  value?: Session;
  onChange: (session: Session, version: SlimVersion) => void;
  getVersionDisabledMsg?: (version: SlimVersion) => string | undefined;
  allowCreateSession?: boolean;
  onCreateAndSelect?: (version: SlimVersion) => void;
};

export function ModelOptions({
  close,
  versions,
  value,
  selectedVersion,
  onChange,
  getVersionDisabledMsg,
  allowCreateSession = false,
  onCreateAndSelect,
}: ModelOptionsProps) {
  const [query, setQuery] = useState<string | undefined>();
  const searchRef = useRef<HTMLInputElement | null>(null);

  const expander = useMultiSelector<SlimVersion>({
    itemIdKey: 'cid',
    defaultValue: versions
      .filter((version) => selectedVersion?.cid === version.cid)
      .map(({ cid }) => cid),
  });

  useEffect(() => searchRef.current?.focus(), []);

  const filteredVersions = useMemo(
    () =>
      query
        ? versions
            .map((versions) => ({
              ...versions,
              models: versions.sessions.filter(({ modelName }) =>
                modelName.startsWith(query)
              ),
            }))
            .filter(({ models }) => models.length)
        : versions,
    [versions, query]
  );

  return (
    <div className="flex-col w-96 overflow-hidden">
      <Input
        ref={searchRef}
        clean
        label="Search Model"
        className="w-full"
        value={query}
        onChange={(e) => setQuery((e.target as HTMLInputElement).value)}
      />
      <div className="flex-1 h-96 overflow-auto">
        {filteredVersions.length ? (
          filteredVersions.map((version) => {
            const versionDisabledMsg = getVersionDisabledMsg?.(version);
            return (
              <div key={version.cid}>
                <VersionTitle
                  versionDisabledMsg={versionDisabledMsg}
                  expander={expander}
                  version={version}
                />
                <Collapse in={expander.isSelected(version)}>
                  {version.sessions.length ? (
                    <ModelList
                      version={version}
                      versionDisabledMsg={versionDisabledMsg}
                      value={value}
                      onChange={onChange}
                      close={close}
                    />
                  ) : (
                    <NoModels
                      allowCreateSession={allowCreateSession}
                      onCreateAndSelect={onCreateAndSelect}
                      version={version}
                      close={close}
                    />
                  )}
                </Collapse>
              </div>
            );
          })
        ) : (
          <NoModels />
        )}
      </div>
    </div>
  );
}

type VersionModelListProps = {
  version: SlimVersion;
  versionDisabledMsg?: string;
  value?: Session;
  onChange: (session: Session, version: SlimVersion) => void;
  close: () => void;
};
function ModelList({
  version,
  versionDisabledMsg,
  value,
  onChange,
  close,
}: VersionModelListProps) {
  return (
    <List>
      {version.sessions.map((session) => {
        if (!session) {
          return undefined;
        }
        let modelDisabledMsg: string | undefined;
        if (!sessionHasTrainedEpochs(session)) {
          modelDisabledMsg = 'No trained epochs found';
        }

        const disabled = modelDisabledMsg || versionDisabledMsg;

        return (
          <ListItem
            className={clsx(
              'h-12 px-4 justify-between items-center border-b border-b-gray-800',
              disabled ? 'opacity-60 cursor-not-allowed' : 'cursor-pointer'
            )}
            selected={value?.cid === session.cid}
            onClick={
              disabled
                ? undefined
                : () => {
                    if (session.sessionWeights?.length) {
                      onChange(session, version);
                    }
                    close();
                  }
            }
            key={session.cid}
          >
            {truncateLongtail({
              value: session.modelName,
              startSubsetLength: 15,
              endSubsetLength: 15,
            })}
            {modelDisabledMsg && (
              <Tooltip title={modelDisabledMsg}>
                <span>
                  <Alert />
                </span>
              </Tooltip>
            )}
          </ListItem>
        );
      })}
    </List>
  );
}
