import { useMemo } from 'react';
import clsx from 'clsx';

const CLEAN_CONTAINER_CLASSES = 'rounded';

type useInputClassesProps = useInputOutlineClassesProps & { clean: boolean };

type useInputOutlineClassesProps = {
  inputHasValue: boolean;
  error?: boolean;
  small?: boolean;
  disabled?: boolean;
};

type UseInputClassesResult = {
  container: string;
  input: string;
  label: string;
};

export function useInputClasses({
  clean,
  ...rest
}: useInputClassesProps): UseInputClassesResult {
  const outlineClasses = useInputOutlineClasses(rest);
  const cleanClasses = useInputCleanClasses(rest);
  return clean ? cleanClasses : outlineClasses;
}

export const CLEAN_CONTAINER_ERROR_CLASSES = `hover:bg-error-500/5 border border-solid border-error-500`;
export const CLEAN_CONTAINER_NOT_ERROR_CLASSES = `hover:bg-white/10 bg-white/5`;

function useInputCleanClasses({
  error,
  disabled,
}: useInputOutlineClassesProps): UseInputClassesResult {
  return useMemo(() => {
    return {
      container: clsx(
        CLEAN_CONTAINER_CLASSES,
        error
          ? CLEAN_CONTAINER_ERROR_CLASSES
          : CLEAN_CONTAINER_NOT_ERROR_CLASSES
      ),
      input: clsx(disabled && 'cursor-not-allowed'),
      label: 'text-gray-400',
    };
  }, [disabled, error]);
}

function useInputOutlineClasses({
  inputHasValue,
  error,
  small,
  disabled,
}: useInputOutlineClassesProps): UseInputClassesResult {
  return useMemo(() => {
    const containerBorderColor = error
      ? 'border-error-500'
      : clsx(
          'border-gray-600 focus-within:border-primary-500 focus-within:hover:border-primary-500',
          !disabled && 'hover:border-gray-300'
        );

    const container = clsx(
      'group',
      'border',
      'relative',
      'w-full',
      'rounded',
      'focus-within:border-2 focus-within:border-t-0 border-1',
      containerBorderColor,
      inputHasValue && 'border-t-0'
    );

    const labelBorderAndTextColor = error
      ? 'before:border-error-500 after:border-error-500 text-error-500'
      : clsx(
          'before:border-gray-600 after:border-gray-600 text-gray-400',
          'peer-focus:before:border-primary-500 peer-focus:after:border-primary-500 peer-focus:text-primary-500',
          !disabled &&
            'group-hover:before:border-gray-300 group-hover:after:border-gray-300'
        );

    const labelBeforeAndAfter = clsx(
      'before:rounded-tl',
      'before:border-t',
      'before:mt-[5px]',
      'before:mr-1.5',
      'before:w-[8px]',
      'peer-focus:before:w-[9px]',
      'after:mt-[5px]',
      'after:ml-1.5',
      'after:flex-1',
      'after:rounded-tr',
      'peer-focus:after:border-t-2',
      'peer-focus:before:border-t-2',
      inputHasValue
        ? 'after:border-t before:border-t'
        : 'after:border-t-0 before:border-t-0'
    );

    const label = clsx(
      labelBeforeAndAfter,
      inputHasValue
        ? 'text-xs leading-[1]'
        : small
        ? 'text-sm leading-[3.5]'
        : 'leading-[3.5]',
      labelBorderAndTextColor,
      'peer-focus:text-xs',
      'peer-focus:leading-[1]',
      'absolute',
      '-top-1.5',
      'peer-focus:-left-[2px]',
      'peer-focus:w-[calc(100%+4px)]',
      'left-0',
      'w-full',
      'h-full',
      'pointer-events-none',
      'flex',
      'flex-row',
      'transition-[font-size line-height]',
      'duration-300'
    );

    const input = [
      'peer',
      'focus:outline-none',
      'border-none',
      'bg-transparent',
      'px-2',
    ].join(' ');

    return {
      container,
      label,
      input,
    };
  }, [inputHasValue, error, disabled, small]);
}
