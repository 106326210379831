import { FilterOperatorType } from '@tensorleap/api-client';
import { first } from 'lodash';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useController, useForm, UseFormReturn } from 'react-hook-form';
import { stopPropagation } from '../core/stopPropagation';
import {
  ClusterVisualizationFilter,
  INVisualizationFilter,
  VisualizationFilter,
} from '../core/types/filters';
import { Button } from '../ui/atoms/Button';
import { Select } from '../ui/atoms/Select';
import { FwArrowIcon, XClose } from '../ui/icons';
import { CircularProgress, IconButton } from '../ui/mui';
import {
  BetweenOrNotBetweenValueInput,
  ClusterValueInput,
  EqualOrNotEqualValueInput,
  InValueInput,
} from './FilterValueInputs';
import {
  AGG_FIELDS_ALLOWED_OPS,
  BetweenOrNotBetweenFilterType,
  BETWEEN_OR_NOT_BETWEEN_SET,
  EqualOrNotEqualFilterType,
  EQUAL_OR_NOT_EQUAL_SET,
  FilterFieldMeta,
  mapFieldValue,
  NUM_FIELDS_ALLOWED_OPS,
  OP_TO_LABEL,
} from './helpers';
import clsx from 'clsx';

enum DisableSelectOptions {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

export interface FilterFormProps {
  defaultFilter?: VisualizationFilter;
  fields: FilterFieldMeta[];
  onApply: (_: VisualizationFilter) => void;
  onClose: () => void;
  allowDisable?: boolean;
}
export const FilterForm = forwardRef<HTMLFormElement, FilterFormProps>(
  ({ defaultFilter, onApply, onClose, fields, allowDisable }, ref) => {
    const actionName = defaultFilter ? 'edit' : 'create';
    const [numFieldSet, allFields] = useMemo(() => {
      return [
        new Set(
          fields
            .filter(({ type }) => type === 'number')
            .map(({ field }) => field)
        ),
        fields.map(({ field }) => field),
      ];
    }, [fields]);

    const form = useForm<VisualizationFilter>({
      mode: 'onChange',
    });

    const {
      control,
      handleSubmit,
      setValue,
      formState: { errors, isValid },
    } = form;

    const { field } = useController({
      control,
      name: 'field',
      rules: { required: true },
      defaultValue: defaultFilter?.field ?? allFields[0],
    });
    const { field: operatorField } = useController({
      control,
      name: 'operator',
      rules: { required: true },
      defaultValue: defaultFilter?.operator ?? FilterOperatorType.Equal,
    });
    const { field: disableField } = useController({
      control,
      name: 'disable',
      defaultValue: !!defaultFilter?.disable,
    });
    const currentFieldMeta = useMemo<FilterFieldMeta>(
      () =>
        fields.find((meta) => meta.field === field.value) || {
          field: '',
          type: 'string',
        },
      [field.value, fields]
    );

    const [allowedOps, setAllowedOps] = useState(() =>
      numFieldSet.has(field.value)
        ? NUM_FIELDS_ALLOWED_OPS
        : AGG_FIELDS_ALLOWED_OPS
    );

    const handleFieldChange = useCallback(
      (updateField) => {
        setValue(
          'value',
          (undefined as unknown) as VisualizationFilter['value'],
          {
            shouldTouch: false,
          }
        );
        field.onChange(updateField);
        const updateAllowedOps = numFieldSet.has(updateField)
          ? NUM_FIELDS_ALLOWED_OPS
          : AGG_FIELDS_ALLOWED_OPS;

        setAllowedOps(updateAllowedOps);
        if (!updateAllowedOps.includes(operatorField.value)) {
          operatorField.onChange(first(updateAllowedOps));
        }
      },
      [operatorField, field, numFieldSet, setValue]
    );

    const onSubmit = (data: VisualizationFilter) => {
      const mappedValue = mapFieldValue(data.value, currentFieldMeta);

      onApply({
        ...data,
        pin: defaultFilter?.pin,
        value: mappedValue,
      } as VisualizationFilter);
      onClose();
    };

    useEffect(() => {
      // update the value
      defaultFilter?.value !== undefined &&
        setValue('value', defaultFilter?.value, { shouldTouch: false });
    }, [defaultFilter, setValue]);

    const isClusterFilter = operatorField.value === 'cluster';

    const notActiveFields = useMemo(
      () =>
        fields.filter(({ notActive }) => notActive).map(({ field }) => field),
      [fields]
    );

    const isLoading = !fields.length;
    if (isLoading)
      return (
        <div className="flex justify-center items-center h-14 w-14">
          <CircularProgress className="col-span-full place-self-center" />
        </div>
      );

    return (
      <form
        ref={ref}
        onSubmit={handleSubmit(onSubmit)}
        onMouseDown={stopPropagation}
        className="grid grid-cols-2 min-w-[4rem] gap-3 justify-items-stretch justify-center"
      >
        <header className="contents">
          <h3 className="uppercase text-lg self-center">{actionName} filter</h3>
          <IconButton className="m-0 p-0 justify-self-end" onClick={onClose}>
            <XClose />
          </IconButton>
        </header>
        <div className="h-px w-full col-span-full border border-gray-800" />
        <section className="contents capitalize">
          <Select
            label="field"
            className="col-span-full"
            required
            inactiveOptions={notActiveFields}
            disabled={isClusterFilter}
            error={errors.field?.message}
            options={isClusterFilter ? [field.value] : allFields}
            value={field.value}
            onChange={handleFieldChange}
          />
          <Select
            label="operator"
            className="col-span-full"
            disabled={isClusterFilter}
            options={isClusterFilter ? [operatorField.value] : allowedOps}
            optionToLabel={(option) => OP_TO_LABEL[option]}
            value={operatorField.value}
            onChange={operatorField.onChange}
          />
          {EQUAL_OR_NOT_EQUAL_SET.has(operatorField.value) ? (
            <EqualOrNotEqualValueInput
              key={currentFieldMeta.field}
              className="col-span-full"
              fieldMeta={currentFieldMeta}
              form={form as UseFormReturn<EqualOrNotEqualFilterType>}
            />
          ) : BETWEEN_OR_NOT_BETWEEN_SET.has(operatorField.value) ? (
            <BetweenOrNotBetweenValueInput
              key={currentFieldMeta.field}
              fieldMeta={currentFieldMeta}
              form={form as UseFormReturn<BetweenOrNotBetweenFilterType>}
            />
          ) : operatorField.value === 'in' ? (
            <InValueInput
              key={currentFieldMeta.field}
              fieldMeta={currentFieldMeta}
              form={form as UseFormReturn<INVisualizationFilter>}
            />
          ) : operatorField.value === 'cluster' ? (
            <ClusterValueInput
              key={currentFieldMeta.field}
              className="col-span-full"
              fieldMeta={currentFieldMeta}
              form={form as UseFormReturn<ClusterVisualizationFilter>}
            />
          ) : null}
        </section>
        <div className="h-px w-full col-span-full border border-gray-800" />
        <footer className="contents">
          {allowDisable && (
            <Select
              className={clsx(
                'col-span-1 place-self-start',
                defaultFilter?.pin && 'invisible'
              )}
              label="State"
              options={Object.values(DisableSelectOptions)}
              value={
                disableField.value
                  ? DisableSelectOptions.DISABLED
                  : DisableSelectOptions.ENABLED
              }
              onChange={(v) => {
                disableField.onChange(v === DisableSelectOptions.DISABLED);
              }}
            />
          )}
          <Button type="submit" disabled={!isValid} className="uppercase ">
            {actionName} <FwArrowIcon className="w-4 h-4" />
          </Button>
        </footer>
      </form>
    );
  }
);

FilterForm.displayName = 'FilterForm';
