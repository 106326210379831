import { FilterOperatorType } from '@tensorleap/api-client';
import {
  EQVisualizationFilter,
  BTWVisualizationFilter,
  INVisualizationFilter,
  NEVisualizationFilter,
  NotBTWVisualizationFilter,
  ClusterVisualizationFilter,
  VisualizationFilter,
} from './types/filters';

export interface CreateEQFilterProps {
  field: string;
  value: number | string;
  disable?: boolean;
}
export function createEQFilter({
  field,
  value,
  disable = false,
}: CreateEQFilterProps): EQVisualizationFilter {
  return {
    field,
    value,
    disable,
    operator: FilterOperatorType.Equal,
  };
}
export function isEQVisualizationFilter(
  obj: unknown
): obj is EQVisualizationFilter {
  return (obj as EQVisualizationFilter)?.operator === FilterOperatorType.Equal;
}

export interface CreateNEFilterProps {
  field: string;
  value: number | string;
  disable?: boolean;
}
export function createNEFilter({
  field,
  value,
  disable = false,
}: CreateNEFilterProps): NEVisualizationFilter {
  return {
    field,
    value,
    disable,
    operator: FilterOperatorType.NotEqual,
  };
}
export function isNEVisualizationFilter(
  obj: unknown
): obj is NEVisualizationFilter {
  return (
    (obj as NEVisualizationFilter)?.operator === FilterOperatorType.NotEqual
  );
}

export interface CreateINFilterProps {
  field: string;
  value: number[] | string[];
  disable?: boolean;
}
export function createINFilter({
  field,
  value,
  disable = false,
}: CreateINFilterProps): INVisualizationFilter {
  return {
    field,
    value,
    disable,
    operator: FilterOperatorType.In,
  };
}
export function isINVisualizationFilter(
  obj: unknown
): obj is INVisualizationFilter {
  return (obj as INVisualizationFilter)?.operator === FilterOperatorType.In;
}

export interface CreateBTWFilterProps {
  field: string;
  lt: number;
  gte: number;
  disable?: boolean;
}
export function createBTWFilter({
  field,
  lt,
  gte,
  disable = false,
}: CreateBTWFilterProps): BTWVisualizationFilter {
  return {
    field,
    value: { gte, lt },
    disable,
    operator: FilterOperatorType.Between,
  };
}
export function isBTWVisualizationFilter(
  obj: unknown
): obj is BTWVisualizationFilter {
  const maybeFilter = obj as BTWVisualizationFilter | undefined;
  return (
    maybeFilter?.operator === FilterOperatorType.Between &&
    (typeof maybeFilter?.value.gte === 'number' ||
      typeof maybeFilter?.value.lt === 'number')
  );
}

export interface CreateNotBTWFilterProps {
  field: string;
  lt: number;
  gte: number;
  disable?: boolean;
}
export function createNotBTWFilter({
  field,
  lt,
  gte,
  disable = false,
}: CreateNotBTWFilterProps): NotBTWVisualizationFilter {
  return {
    field,
    value: { gte, lt },
    disable,
    operator: FilterOperatorType.NotBetween,
  };
}
export function isNotBTWVisualizationFilter(
  obj: unknown
): obj is NotBTWVisualizationFilter {
  const maybeFilter = obj as NotBTWVisualizationFilter | undefined;
  return (
    maybeFilter?.operator === FilterOperatorType.NotBetween &&
    (typeof maybeFilter?.value.gte === 'number' ||
      typeof maybeFilter?.value.lt === 'number')
  );
}

export function isClusterVisualizationFilter(
  obj: unknown
): obj is ClusterVisualizationFilter {
  return (
    (obj as ClusterVisualizationFilter)?.operator === FilterOperatorType.Cluster
  );
}

export function isInsightFilter(obj: unknown) {
  return (
    isClusterVisualizationFilter(obj) && obj.displayData?.type === 'insight'
  );
}

export function isFilterLoading(filter: VisualizationFilter): boolean {
  return isClusterVisualizationFilter(filter) && filter.value.state !== 'ready';
}

export function isFilterReady(filter: VisualizationFilter): boolean {
  if (filter.disable) {
    return false;
  }
  if (!isClusterVisualizationFilter(filter)) {
    return true;
  }
  return filter.value.state !== 'calculating';
}
