import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgScissorsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M7.542 5.031A3.321 3.321 0 1 0 4.22 8.352a3.303 3.303 0 0 0 2.235-.878L8.1 8.992 8.11 9l-.003.003-1.652 1.523a3.299 3.299 0 0 0-4.08-.318 3.321 3.321 0 1 0 5.167 2.761c0-.267-.04-.524-.1-.772l2.56-1.792 3.714 2.758c1.99 1.394 3.384-.596 3.384-.596L7.442 5.803c.06-.248.1-.505.1-.772ZM4.22 6.651a1.62 1.62 0 1 1 0-3.24 1.62 1.62 0 0 1 0 3.24Zm0 7.938a1.62 1.62 0 1 1 0-3.24 1.62 1.62 0 0 1 0 3.24ZM17.1 5.434s-1.393-1.99-3.384-.596L10.83 6.849l2.119 1.483 4.15-2.898Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgScissorsIcon;
