import React, { useMemo } from 'react';
import { last } from 'lodash';
import * as d3 from 'd3';
import useResizeObserver from 'use-resize-observer';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import { truncateLongtail } from '../core/formatters/string-formatting';

const GRAPH_MARGIN = { top: 20, right: 80, bottom: 50, left: 80 };

export interface HorizontalBarGraphProps {
  labels: string[];
  values: number[][];
  onBarClick?: (index: number) => void;
  LegendComponent?: JSX.Element;
}
export const HorizontalBarGraph = React.memo<HorizontalBarGraphProps>(
  ({ labels, values, onBarClick, LegendComponent }) => {
    const { ref, width = 0, height = 0 } = useResizeObserver<HTMLDivElement>();
    const svgStyle = useMemo(() => ({ width, height }), [width, height]);

    const graphWidth = useMemo(
      () => width - GRAPH_MARGIN.left - GRAPH_MARGIN.right,
      [width]
    );
    const graphHeight = useMemo(
      () => height - GRAPH_MARGIN.top - GRAPH_MARGIN.bottom,
      [height]
    );

    const xScale = useMemo(
      () =>
        d3
          .scaleLinear()
          .domain([
            Math.min(0, ...values.flat()),
            Math.max(0, ...values.flat()),
          ])
          .range([0, graphWidth]),
      [values, graphWidth]
    );
    const yScale = useMemo(
      () => d3.scaleBand().domain(labels).range([0, graphHeight]).padding(0.75),
      [graphHeight, labels]
    );

    return (
      <div className="flex flex-col w-full h-full min-h-0" ref={ref}>
        {width > 0 && height && (
          <>
            <svg style={svgStyle}>
              <g
                transform={`translate(${GRAPH_MARGIN.left},${GRAPH_MARGIN.top})`}
              >
                {values.map((v, index) => (
                  <GraphBars
                    key={index}
                    labels={labels}
                    values={v}
                    xScale={xScale}
                    yScale={yScale}
                    barLevel={index}
                    onClick={onBarClick}
                  />
                ))}
                <XAxis xScale={xScale} height={graphHeight} />
                <YAxis xScale={xScale} yScale={yScale} values={values[0]} />
              </g>
            </svg>
            {LegendComponent}
          </>
        )}
      </div>
    );
  }
);
HorizontalBarGraph.displayName = 'HorizontalBarGraph';

interface GraphBarsProps {
  labels: string[];
  values: number[];
  xScale: d3.ScaleLinear<number, number, never>;
  yScale: d3.ScaleBand<string>;
  onClick?: (index: number) => void;
  barLevel?: number;
}
const GraphBars = React.memo<GraphBarsProps>(
  ({ labels, values, xScale, yScale, onClick, barLevel = 0 }) => (
    <>
      {values.map((value, index) => (
        <Tooltip
          key={index}
          title={
            <span>
              <span>label: {labels[index]}</span>
              <br />
              <span>value: {value}</span>
            </span>
          }
        >
          <rect
            x={xScale(Math.min(0, value))}
            y={(yScale(labels[index]) || 0) - barLevel * 10}
            width={Math.abs(xScale(value) - xScale(0))}
            height={yScale.bandwidth()}
            onClick={() => onClick?.(index)}
            rx="5"
            className={clsx('drop-shadow-xl', {
              'fill-primary-500': barLevel % 2,
              'fill-secondary-500': !(barLevel % 2),
            })}
          />
        </Tooltip>
      ))}
    </>
  )
);
GraphBars.displayName = 'GraphBars';

interface XAxisProps {
  xScale: d3.ScaleLinear<number, number, never>;
  height: number;
}

const textStyles: React.CSSProperties = {
  textAnchor: 'end',
};

const XAxis = React.memo<XAxisProps>(({ xScale, height }) => (
  <g
    transform={`translate(0,${height})`}
    fill="none"
    fontSize="10"
    fontFamily="sans-serif"
    textAnchor="middle"
  >
    <path stroke="currentColor" d={`M0.5,6V0.5H${last(xScale.range())}V6`} />
    {xScale.ticks().map((value) => (
      <g key={value} transform={`translate(${xScale(value)},0)`}>
        <line stroke="currentColor" y2="6"></line>
        <text
          fill="currentColor"
          y="9"
          dy="0.71em"
          transform="translate(-10,0)rotate(-45)"
          style={textStyles}
        >
          {value}
        </text>
      </g>
    ))}
  </g>
));
XAxis.displayName = 'XAxis';

interface YAxisProps {
  xScale: d3.ScaleLinear<number, number, never>;
  yScale: d3.ScaleBand<string>;
  values: number[];
}
const YAxis = React.memo<YAxisProps>(({ xScale, yScale, values }) => {
  const halfBarHeight = yScale.bandwidth() / 2;
  return (
    <g
      fill="none"
      fontSize="10"
      fontFamily="sans-serif"
      transform={`translate(${xScale(0)},0)`}
    >
      <path
        stroke="currentColor"
        d={`M-6,0.5H0.5V${last(yScale.range())}H-6`}
      />
      {yScale.domain().map((label, idx) => {
        const isNegative = values[idx] < 0;
        return (
          <g
            key={idx}
            transform={`translate(0,${(yScale(label) || 0) + halfBarHeight})`}
          >
            <line stroke="currentColor" x2={isNegative ? 6 : -6}></line>
            <Tooltip title={label}>
              <text
                className="text-sm overflow-ellipsis"
                fill="currentColor"
                x={isNegative ? 9 : -9}
                textAnchor={isNegative ? 'start' : 'end'}
                dy="0.32em"
              >
                {truncateLongtail({
                  value: label,
                  startSubsetLength: 7,
                  endSubsetLength: 0,
                })}
              </text>
            </Tooltip>
          </g>
        );
      })}
    </g>
  );
});
YAxis.displayName = 'YAxis';
