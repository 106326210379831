import { useCallback, useState, useEffect } from 'react';
import api from '../core/api-client';
import {
  CreateSessionTestRequest,
  FilterOperatorType,
  SessionTest,
  ClientFilterParams,
  UpdateSessionTestRequest,
} from '@tensorleap/api-client';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { useFetchAllModelTests } from '../core/data-fetching/model-tests';
import { ModelTestsFormProps } from './ModelTestsCard';
import { ToggleString, useStringToggle } from '../core/useStringToggle';
import { useFetchDashletFields } from '../core/data-fetching/dashlet-fields';

export type BaseModelTestProps = {
  onUpdate: (form: ModelTestsFormProps) => void;
  onDelete: (testId: string) => void;
  toggleSelectedTestId: ToggleString;
  onStartEditable: (test: ModelTestsFormProps) => void;
  onStopEditable: (id: string) => void;
  selectedTestId?: string;
  editedTestState: ModelTestsFormProps | null;
};

export type UseModelTestsReturn = {
  toggleSelectedTestId: ToggleString;
  selectedTestId?: string;
  editedTestState: ModelTestsFormProps | null;
  startEditable: (test: ModelTestsFormProps) => void;
  stopEditable: (id: string) => void;
  isLoading: boolean;
  create: (_?: CreateModelTestProps) => void;
  update: (form: ModelTestsFormProps) => void;
  delete: (testId: string) => void;
  data: SessionTest[];
};

type CreateModelTestProps = Partial<
  Pick<CreateSessionTestRequest, 'name' | 'datasetFilter' | 'testFilter'>
>;

export function useModelTests(onMutated?: () => void): UseModelTestsReturn {
  const { fetchValidProjectCid } = useCurrentProject();
  const projectId = fetchValidProjectCid();
  const [
    selectedTestId,
    toggleSelectedTestId,
    setSelectedTestId,
  ] = useStringToggle();

  const [
    editedTestState,
    seteditedTestState,
  ] = useState<ModelTestsFormProps | null>(null);

  const {
    dashletFields,
    isLoading: isLoadingDashletFields,
  } = useFetchDashletFields(projectId, []);

  const { modelTests, refetch, isLoading } = useFetchAllModelTests({
    projectId,
  });

  const handleOnMutated = useCallback(async () => {
    onMutated?.();
    await refetch();
  }, [refetch, onMutated]);

  const startEditable = useCallback(
    ({ cid, name, datasetFilter, testFilter }: ModelTestsFormProps) => {
      seteditedTestState({
        cid,
        projectId,
        name,
        datasetFilter,
        testFilter,
      });
      setSelectedTestId(cid);
    },
    [projectId, setSelectedTestId]
  );

  const stopEditable = useCallback(() => {
    seteditedTestState(null);
  }, []);

  const create = useCallback(
    async ({
      name = '',
      datasetFilter = [],
      testFilter,
    }: CreateModelTestProps = {}) => {
      if (!dashletFields) {
        return;
      }

      const lossMetric = dashletFields?.numericFields.find(
        (m) => m === 'metrics.loss'
      );
      const testedMetric =
        lossMetric ||
        dashletFields?.numericFields[0] ||
        dashletFields?.aggregatableFields[0] ||
        '';

      const defaultTestFilter = {
        field: testedMetric,
        operator: FilterOperatorType.LessThan,
        value: 0.1,
      };

      const createSessionTestRequest: CreateSessionTestRequest = {
        projectId,
        name,
        datasetFilter,
        testFilter: testFilter || defaultTestFilter,
      };

      const id = await api.createSessionTest(createSessionTestRequest);
      await handleOnMutated();
      // TODO the string http response returned with " - will be hander in the future
      startEditable({
        cid: id.replaceAll('"', ''),
        projectId,
        name,
        datasetFilter,
        testFilter: createSessionTestRequest.testFilter,
      });
    },
    [dashletFields, handleOnMutated, projectId, startEditable]
  );

  const deleteOne = useCallback(
    async (testId: string) => {
      await api.deleteSessionTest({
        cid: testId,
        projectId,
      });
      handleOnMutated();
    },
    [handleOnMutated, projectId]
  );

  const update = useCallback(
    async ({
      cid,
      name,
      testFilter,
      datasetFilter,
      projectId,
    }: ModelTestsFormProps) => {
      const formattedTestFilter: ClientFilterParams = {
        ...testFilter,
        value: Number(testFilter.value),
      };
      const updateSessionTestRequest: UpdateSessionTestRequest = {
        projectId,
        cid,
        name,
        testFilter: formattedTestFilter,
        datasetFilter,
      };
      await api.updateSessionTest(updateSessionTestRequest);
      await handleOnMutated();
      stopEditable();
    },
    [handleOnMutated, stopEditable]
  );

  useEffect(() => {
    if (
      selectedTestId &&
      !modelTests.some(({ cid }) => cid === selectedTestId)
    ) {
      seteditedTestState(null);
      setSelectedTestId(undefined);
    }
  }, [modelTests, selectedTestId, setSelectedTestId]);

  return {
    create,
    delete: deleteOne,
    update,
    toggleSelectedTestId,
    startEditable,
    stopEditable,
    editedTestState,
    selectedTestId,
    isLoading: isLoading || isLoadingDashletFields,
    data: modelTests,
  };
}
