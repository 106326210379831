import { SlimUserData } from '@tensorleap/api-client';
import { IssueStatus } from '@tensorleap/api-client/dist/models/IssueStatus';
import { useMemo } from 'react';

export const fullIssueTypeText: Record<IssueStatus, string> = {
  Open: 'Open',
  AtWork: 'In Progress',
  Closed: 'Closed',
};

export const issueFullTextToType: Record<string, IssueStatus> = {
  Open: IssueStatus.Open,
  'In Progress': IssueStatus.AtWork,
  Closed: IssueStatus.Closed,
};

export function useUserLocalName(
  userId: string | undefined,
  orgUsers: SlimUserData[]
): string | undefined {
  return useMemo(() => {
    return userId && orgUsers.find((x) => x.cid === userId)?.local.name;
  }, [userId, orgUsers]);
}

export function getUserId(
  userName: string | undefined,
  orgUsers: SlimUserData[]
): string | undefined {
  return userName && orgUsers.find((x) => x.local.name === userName)?.cid;
}
