import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgSizeIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M11.53 6.443a3.466 3.466 0 0 1 1.053 2.543c0 .993-.351 1.84-1.054 2.543a3.466 3.466 0 0 1-2.543 1.054 3.397 3.397 0 0 1-2.53-1.068l2.53-2.529V5.389c.993 0 1.84.351 2.543 1.054ZM9 3c1.658 0 3.073.585 4.244 1.756C14.414 5.927 15 7.337 15 8.986c0 1.649-.585 3.058-1.756 4.23-1.171 1.17-2.586 1.756-4.244 1.756s-3.073-.586-4.244-1.756C3.586 12.045 3 10.635 3 8.986c0-1.649.585-3.059 1.756-4.23C5.927 3.586 7.342 3 9 3Zm0 10.792c1.32 0 2.45-.469 3.386-1.406.937-.936 1.406-2.07 1.406-3.4s-.469-2.464-1.406-3.4C11.45 4.648 10.321 4.18 9 4.18c-1.32 0-2.45.469-3.386 1.405-.937.937-1.406 2.07-1.406 3.401 0 1.33.469 2.464 1.406 3.4.936.937 2.065 1.406 3.386 1.406Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSizeIcon2;
