import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgZoomIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.043 4.963 13.664 7.23v2.666l3.379 2.262V4.963Zm.272 7.365h-.003.003Zm.214-8.45c.4.156.471.576.471.838v7.688c0 .167-.024.346-.107.506a.678.678 0 0 1-.454.354.812.812 0 0 1-.454-.037 1.384 1.384 0 0 1-.311-.164l-3.588-2.403v1.723c0 .538-.467.902-.946.902H3.043C1.414 13.29.006 12.072 0 10.473V4.737c0-.538.466-.902.944-.902h9.098c1.568-.005 2.93 1.125 3.037 2.635l3.586-2.406c.107-.08.228-.151.36-.193a.75.75 0 0 1 .504.007Zm-5.4 2.772c-.006-.993-.904-1.862-2.084-1.857H.957v5.677c.005.993.902 1.862 2.084 1.858H12.13V6.65Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgZoomIcon;
