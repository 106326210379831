import { useCallback, useEffect, useState } from 'react';
import {
  Issue,
  IssueActionType,
  IssueActivity,
  IssueStatus,
  UpdateIssueParams,
} from '@tensorleap/api-client';
import { IssueActivityCard } from './IssueActivityCard';
import { BackIcon, ChatboxOutline, Trash, XClose } from '../ui/icons';
import { Button } from '../ui/atoms/Button';
import clsx from 'clsx';
import { useTeamUsers } from '../core/data-fetching/users';
import { useAuth } from '../auth/AuthContext';
import { ConfirmDialog } from '../ui/atoms/DeleteContentDialog';
import { IssueInfo } from './IssueInfo';
import { Setter, useStateObject } from '../core/types';
import { IconButton } from '../ui/mui';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { EditableInputCell } from '../ui/atoms/EditableInputCell';
import { uploadFile } from '../core/file-helper';
import api, { addBasePathToURL } from '../core/api-client';
import { useEnvironmentInfo } from '../core/EnvironmentInfoContext';
import { IssueMdTextarea } from './IssueMdTextarea';

export type SingleIssueDrawerContentParams = {
  selectedIssue: Issue | undefined;
  setSelectedIssue: Setter<Issue | undefined>;
  isExpanded: boolean;
  isEditable: boolean;
  setIsEditable: Setter<boolean>;
  updateIssue: (updateIssueParams: UpdateIssueParams) => Promise<void>;
  deleteSelectedIssue: () => Promise<void>;
};

export function SingleIssueDrawerContent({
  selectedIssue,
  setSelectedIssue,
  isExpanded,
  updateIssue,
  deleteSelectedIssue,
}: SingleIssueDrawerContentParams): JSX.Element {
  const orgUsers = useTeamUsers();
  const { fetchValidProjectCid } = useCurrentProject();
  const projectId = fetchValidProjectCid();
  const [title, setTitle] = useState(selectedIssue?.title || '');
  const [commentValue, setCommentValue] = useState<string | undefined>();
  const [imagesToUpload, setImagesToUpload] = useState<Record<string, File>>(
    {}
  );
  const {
    environmentInfo: { clientStoragePrefixUrl },
  } = useEnvironmentInfo();

  const uploadImages = useCallback(
    async (comment: string, uploadImageMap: Record<string, File>) => {
      if (!projectId || !selectedIssue?.cid) {
        console.error('Undefined issueId or projectId, this should not happen');
        return '';
      }
      let updatedContentValue = comment;
      for (const [tempUrl, file] of Object.entries(uploadImageMap)) {
        if (updatedContentValue?.includes(tempUrl)) {
          const { url, fileName } = await api.getIssueFileUploadSignedUrl({
            projectId,
            fileName: file.name,
            issueId: selectedIssue.cid,
          });
          const urlWithBasePath = addBasePathToURL(url);
          await uploadFile(urlWithBasePath, file);
          const imgSrc = `${clientStoragePrefixUrl}/${fileName}`;
          updatedContentValue = updatedContentValue.replace(tempUrl, imgSrc);
        }
      }
      return updatedContentValue || '';
    },
    [projectId, selectedIssue?.cid, clientStoragePrefixUrl]
  );

  useEffect(() => {
    setTitle(selectedIssue?.title || '');
  }, [selectedIssue?.title]);

  const { user } = useAuth();
  const saveNewComment = useCallback(async () => {
    if (!commentValue || !selectedIssue?.cid || !selectedIssue?.activities) {
      return;
    }
    const newCommentValue = await uploadImages(commentValue, imagesToUpload);
    setCommentValue(newCommentValue);
    setImagesToUpload({});

    const updatedActivities: IssueActivity[] = [
      ...selectedIssue.activities,
      {
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: user?.cid || '',
        actionType: IssueActionType.Comment,
        action: { comment: { message: newCommentValue } },
      },
    ];
    await updateIssue({
      projectId,
      cid: selectedIssue.cid,
      activities: updatedActivities,
    });

    setCommentValue('');
  }, [
    commentValue,
    imagesToUpload,
    projectId,
    selectedIssue?.activities,
    selectedIssue?.cid,
    updateIssue,
    uploadImages,
    user?.cid,
  ]);

  const editActivity = useCallback(
    async (index, updatedActivity: IssueActivity) => {
      if (!selectedIssue?.activities.length) {
        return;
      }
      const updatedActivities = [...selectedIssue.activities];
      updatedActivities[index] = updatedActivity;
      await updateIssue({
        projectId,
        cid: selectedIssue.cid,
        activities: updatedActivities,
      });
    },
    [projectId, selectedIssue?.activities, selectedIssue?.cid, updateIssue]
  );

  const deleteActivity = useCallback(
    async (activityToRemove: IssueActivity) => {
      if (!selectedIssue?.cid || !selectedIssue?.activities.length) {
        return;
      }
      const updatedActivities = selectedIssue.activities.filter(
        (activity) => activity !== activityToRemove
      );
      await updateIssue({
        projectId,
        cid: selectedIssue.cid,
        activities: updatedActivities,
      });
    },
    [projectId, selectedIssue, updateIssue]
  );

  const updateTitle = useCallback(
    async (newTitle: string) => {
      if (!selectedIssue?.cid) {
        console.error('Issue not selected, should not happen');
        return;
      }

      await updateIssue({
        cid: selectedIssue.cid,
        projectId,
        title: newTitle,
      });
    },
    [projectId, selectedIssue, updateIssue]
  );

  const closeIssue = useCallback(async () => {
    if (!selectedIssue?.cid) {
      console.error('Issue not selected, should not happen');
      return;
    }

    await updateIssue({
      cid: selectedIssue.cid,
      projectId,
      status: IssueStatus.Closed,
    });
  }, [projectId, selectedIssue, updateIssue]);

  const deleteIssueDialogState = useStateObject(false);

  useEffect(() => {
    setCommentValue('');
  }, [selectedIssue]);

  return (
    <div className="flex flex-col w-full h-full gap-5 items-center pb-20 mt-5 mb-20 overflow-auto overflow-x-hidden scrollbar-hide">
      <ConfirmDialog
        title="Are you sure you want to delete this Issue?"
        isOpen={deleteIssueDialogState.state}
        onClose={() => deleteIssueDialogState.setState(false)}
        onConfirm={deleteSelectedIssue}
      />

      <div className="flex flex-row w-full justify-start items-start text-2xl pl-4 pb-4 border-b border-gray-800  shadow-lg">
        {!isExpanded && (
          <IconButton
            className="w-8 h-8 !p-0 !my-0 ml-0 mr-2 !text-white"
            onClick={() => setSelectedIssue(undefined)}
          >
            <BackIcon className="w-8 h-8" />
          </IconButton>
        )}
        <div className="flex flex-1 pr-4 gap-4">
          <div className="w-full">
            <EditableInputCell value={title} required onChange={updateTitle} />
          </div>
          <IconButton
            className="h-8 w-8"
            onClick={() => deleteIssueDialogState.setState(true)}
          >
            <Trash />
          </IconButton>
        </div>
      </div>
      <div className="flex flex-col h-fit w-full justify-center items-center px-4 py-6 border-b border-gray-800 shadow-lg">
        <IssueInfo
          selectedIssue={selectedIssue}
          updateIssue={updateIssue}
          isExpanded={isExpanded}
        />
      </div>
      <div
        className={clsx(
          'flex flex-col w-full gap-5',
          selectedIssue?.activities?.length &&
            'border-b border-gray-800 shadow-lg px-4 pb-6'
        )}
      >
        {selectedIssue?.activities?.map((issueActivity, index) => (
          <IssueActivityCard
            orgUsers={orgUsers}
            issueActivity={issueActivity}
            key={`${index}-${selectedIssue.cid}`}
            deleteActivity={deleteActivity}
            editActivity={editActivity}
            index={index}
            uploadImages={uploadImages}
          />
        ))}
      </div>
      <div className="flex flex-col w-full px-4 gap-4">
        <IssueMdTextarea
          message={commentValue}
          setMessage={setCommentValue}
          imagesToUpload={imagesToUpload}
          setImagesToUpload={setImagesToUpload}
          readOnly={false}
        />
        <div className="flex flex-row w-full h-fit justify-start items-center">
          <Button variant="inverted-gray" uppercase onClick={closeIssue}>
            <XClose className="text-error-500 w-fit h-fit" />
            close issue
          </Button>
          <Button
            variant="inverted-gray"
            uppercase
            className="ml-auto"
            onClick={saveNewComment}
            disabled={!commentValue}
          >
            <ChatboxOutline className="text-secondary-500 w-fit h-fit" />
            comment
          </Button>
        </div>
      </div>
    </div>
  );
}
