import { useMemo, useCallback, useState } from 'react';
import { DetailsProps } from './NodeDetails';
import { Empty, NodeDetailsPanel } from './NodeDetalisPanel';
import { Settings } from '../ui/icons';
import { Select } from '../ui/atoms/Select';
import { Input } from '../ui/atoms/Input';
import { extractName, findByName } from '../core/named-halper';
import { ChangeNodePropFunc } from '../network-editor/networkStateUtils';
import { CustomLayerInstance, Node } from '@tensorleap/api-client';
import { NodeTypes } from '../network-editor/descriptor/types';
import { useNetworkMapContext } from '../core/NetworkMapContext';
import { useCurrentProject } from '../core/CurrentProjectContext';

export type CustomLayerData = Record<string, number | undefined> & {
  selected: string;
  type: string;
};

export function updateCustomLayerNode(
  node: Node,
  changeNodeProperty: ChangeNodePropFunc,
  newCustomLayer?: CustomLayerInstance,
  propsValueCache = new Map<string, number | undefined>()
): void {
  let { selected = undefined, type = NodeTypes.CustomLayer } = newCustomLayer
    ? (node.data as CustomLayerData)
    : {};

  let init_args = {};

  if (newCustomLayer) {
    selected = newCustomLayer.name;
    type = NodeTypes.CustomLayer;
    init_args = Object.fromEntries(
      newCustomLayer.init_arg_names.map((argName) => [
        argName,
        propsValueCache.get(argName) as number,
      ])
    );
  }

  init_args = {
    ...init_args,
    custom_input_keys: Object.keys(node.inputs).map((_, i) => i.toString()),
  };

  changeNodeProperty({
    nodeId: node.id,
    nodeDataPropsToUpdate: { type, ...init_args, selected },
    override: true,
  });
}

export function CustomLayerDetails({ node }: DetailsProps): JSX.Element {
  const { changeNodeProperty } = useNetworkMapContext();
  const { selectedCodeIntegrationVersion } = useCurrentProject();

  const nodeData = node.data as CustomLayerData;

  const customLayers = useMemo<CustomLayerInstance[]>(
    () =>
      selectedCodeIntegrationVersion?.metadata.modelSetup?.custom_layers ?? [],
    [selectedCodeIntegrationVersion]
  );

  const selectedCustomLater = useMemo(
    () => findByName(customLayers, nodeData.selected),
    [nodeData.selected, customLayers]
  );

  const [cacheValues] = useState(
    () =>
      new Map(
        selectedCustomLater?.init_arg_names.map((argName) => [
          argName,
          nodeData[argName],
        ])
      )
  );

  const updateCustomLayer = useCallback(
    (name?: string) => {
      const customLayer = findByName(customLayers, name);

      updateCustomLayerNode(node, changeNodeProperty, customLayer, cacheValues);
    },
    [node, customLayers, cacheValues, changeNodeProperty]
  );

  const updateCustomLayerProps = useCallback(
    (propName: string, updatedValue) => {
      cacheValues.set(propName, updatedValue);
      changeNodeProperty({
        nodeId: node.id,
        nodeDataPropsToUpdate: { [propName]: updatedValue },
      });
    },
    [node.id, cacheValues, changeNodeProperty]
  );

  return (
    <div className="flex flex-col overflow-auto">
      <NodeDetailsPanel title="Properties" icon={<Settings />} openByDefault>
        <Select
          className="m-4"
          value={selectedCustomLater?.name}
          onChange={updateCustomLayer}
          label="SELECT CUSTOM LAYER"
          options={customLayers}
          optionToLabel={extractName}
          optionID="name"
        />

        {selectedCustomLater ? (
          selectedCustomLater.init_arg_names.map((propName) => (
            <Input
              containerProps={{ className: 'mx-4 my-2' }}
              onChange={(event) =>
                updateCustomLayerProps(propName, Number(event.target.value))
              }
              key={propName}
              label={propName}
              value={nodeData[propName]}
              type="number"
            />
          ))
        ) : (
          <Empty title=" No Custom Layer Arguments" />
        )}
      </NodeDetailsPanel>
    </div>
  );
}
