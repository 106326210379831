export enum ValidateAssetsStatus {
  CalculatingDigest = 'CALCULATING_DIGEST',
  Unstarted = 'UNSTARTED',
  Calculating = 'CALCULATING',
  Failed = 'FAILED',
  Passed = 'PASSED',
}

export enum ConfirmValidateAssetsDialogMsg {
  AlreadyCalculated = 'The result for your request is already displayed right now.\
  \nAre you sure you want to execute it again anyway?',
  ThereIsAnotherRunningProcess = 'There is already an identical request being processed.\
  \nDo you want to terminate and re-execute it?',
}

export interface ValidateAssetsButtonState {
  isDisabled: boolean;
  confirmDialogMsg?: string;
  tooltipTitle: string;
}

export enum ValidateAssetsResultEnum {
  Passed = 'PASSED',
  Failed = 'FAILED',
  NotTested = 'NOT_TESTED',
}
