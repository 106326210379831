import { Label } from 'recharts';
import {
  TICK_LINE_SIZE,
  AXIS_LINE_STROKE_COLOR,
  valueFormattingFunction,
} from '../../chart.utils';
import { AxisDomain } from 'recharts/types/util/types';
import {
  CHART_LABEL_TRUNCATE_LENGTH,
  GRAPH_STYLE,
} from '../../common/constants';
import { useMemo } from 'react';
import { Props as XAxisProps } from 'recharts/types/cartesian/XAxis';
import { getLabelFontSize } from '../../hooks/utils';
import { truncateLongtail } from '../../../../core/formatters/string-formatting';

export interface XAxisParams {
  displayName: string;
  domain?: AxisDomain;
  displayAxis?: boolean;
  axisType?: 'number' | 'category';
}

export function useXAxisProps({
  displayName,
  domain,
  axisType,
  displayAxis = true,
}: XAxisParams): XAxisProps {
  const fontSize = useMemo(() => getLabelFontSize(displayName), [displayName]);

  return useMemo(
    (): XAxisProps => ({
      allowDuplicatedCategory: false,
      allowDataOverflow: true,
      domain,
      axisLine: displayAxis,
      label: displayAxis ? (
        <Label
          dy={GRAPH_STYLE.xAxis.label.dy}
          fill={GRAPH_STYLE.gray500}
          textAnchor="middle"
          className="uppercase"
          fontSize={fontSize}
        >
          {truncateLongtail({
            value: displayName,
            startSubsetLength: CHART_LABEL_TRUNCATE_LENGTH,
          })}
        </Label>
      ) : undefined,
      type: axisType,
      dataKey: displayName,
      tickSize: TICK_LINE_SIZE,
      tickFormatter: valueFormattingFunction,
      tick: displayAxis ? GRAPH_STYLE.xAxis.tick : false,
      stroke: AXIS_LINE_STROKE_COLOR,
    }),
    [domain, displayAxis, fontSize, displayName, axisType]
  );
}
