import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useQueryParams(paramName: string): string[] {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search).getAll(paramName), [
    paramName,
    search,
  ]);
}
