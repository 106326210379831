import { DependencyList, useEffect } from 'react';

type UseAsyncEffectCtx = {
  isMounted: boolean;
};

export default function useAsyncEffect(
  fn: (ctx: UseAsyncEffectCtx) => void,
  dependencies = [] as DependencyList
): void {
  useEffect(() => {
    const ctx = { isMounted: true };
    fn(ctx);
    return () => {
      ctx.isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
