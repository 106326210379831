import { useAuth } from '../auth/AuthContext';
import { useCallback, useEffect, useState } from 'react';
import { StateObject } from '../core/types';
import { Button } from '../ui/atoms/Button';
import { XCloseIcon2 } from '../ui/icons';
import { Input } from '../ui/atoms/Input';
import { CircledIcon } from '../ui/atoms/CircledIcon';
import api from '../core/api-client';
import { PopupTransitionDialog } from '../ui/molecules/PopupTransitionDialog';

export interface UserManagementDialogProps {
  openState: StateObject;
}

export function UserManagementDialog({
  openState,
}: UserManagementDialogProps): JSX.Element {
  const { user, refreshUser } = useAuth();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const { setState: setIsOpen } = openState;

  useEffect(() => {
    const splitName = user?.local.name.split(' ') || [];
    setFirstName(splitName[0]);
    setLastName(splitName.length > 1 ? splitName.slice(1).join(' ') : '');
  }, [user?.local.name]);

  const updateUserName = useCallback(async () => {
    const userName = `${firstName} ${lastName}`;
    await api.updateUserName({
      userName,
    });
    refreshUser();
  }, [firstName, lastName, refreshUser]);

  return (
    <PopupTransitionDialog
      open={openState.state}
      onOpenChange={openState.setState}
      dialogPanelClassName="bg-gray-850"
    >
      <div className="flex flex-col h-full w-full">
        <div className="flex justify-start items-center h-fit w-full py-10 shadow-2xl drop-shadow-xl">
          <span className="uppercase text-2xl tracking-ultrawide pl-20">
            edit profile
          </span>
          <Button
            className="inset-0 w-6 h-6 mr-20 ml-auto"
            variant="text"
            onClick={() => setIsOpen(false)}
          >
            <XCloseIcon2 className="text-gray-500" />
          </Button>
        </div>
        <div className="h-full w-full shadow-2xl drop-shadow-xl">
          <div className="grid grid-cols-4 grid-rows-5 px-36 py-16 h-full w-full">
            <div className="col-span-3 flex justify-start items-center">
              <span className="uppercase text-xl">personal information</span>
            </div>
            <div className="row-span-5 flex justify-center items-center">
              <CircledIcon
                className="flex !w-28 !h-28"
                textSizeClass="text-6xl"
                text={user?.local.name}
                dropShadow
                borderStyle="border-2"
              />
            </div>
            <div className="col-span-3 flex flex-row justify-start items-center gap-6">
              <Input
                containerProps={{ className: 'uppercase w-64' }}
                label="first name"
                value={firstName}
                onChange={(x) => setFirstName(x.currentTarget.value)}
              />
              <Input
                containerProps={{ className: 'uppercase w-64' }}
                label="last name"
                value={lastName}
                onChange={(x) => setLastName(x.currentTarget.value)}
              />
            </div>
            <div className="col-span-3 flex flex-row justify-start items-center">
              <Input
                containerProps={{ className: 'uppercase w-[33.5rem]' }}
                label="email"
                value={user?.local.email}
                disabled
              />
            </div>
            <div className="col-span-3 flex justify-start items-center">
              <span className="uppercase text-xl">account information</span>
            </div>
            <div className="col-span-3 flex flex-row justify-start items-center gap-6">
              <Input
                containerProps={{ className: 'uppercase w-64' }}
                className="uppercase"
                label="status"
                value={user?.activated ? 'activated' : 'pending'}
                disabled
              />
              <Input
                containerProps={{ className: 'uppercase w-64' }}
                className="uppercase"
                label="account type"
                value={user?.role}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="flex justify-start gap-4 items-center py-10 px-[4.5rem] ">
          <div className="flex-1">
            <Button
              className="uppercase w-40"
              variant="outline"
              onClick={() => setIsOpen(false)}
            >
              cancel
            </Button>
          </div>
          <Button
            className="uppercase w-40"
            onClick={() => {
              updateUserName();
              setIsOpen(false);
            }}
          >
            save
          </Button>
        </div>
      </div>
    </PopupTransitionDialog>
  );
}
