import useSWR from 'swr';
import yaml from 'js-yaml';
import { useCallback } from 'react';
import { ProjectCategoriesValue } from '../../projects/consts';

type HubProjectVersion = {
  path: string;
  size: number; // bytes
  name: string;
  title?: string;
  description: string;
  bgImagePath: string;
  tags: string[];
  categories: ProjectCategoriesValue;
  createdAt: number;
  sourceProjectId: string;
};
type HubProject = {
  createdAt: number;
  versions: HubProjectVersion[];
};
type HubIndex = {
  namespace: string;
  projects: Record<string, HubProject>;
};

export function useHubIndex(hubUrl: string): HubIndex | null {
  const fetchList = useCallback(async () => {
    const res = await fetch(hubUrl, { cache: 'no-store' });
    const text = await res.text();
    return yaml.load(text) as HubIndex;
  }, [hubUrl]);

  const { data: hubIndex = null } = useSWR(hubUrl, fetchList);

  return hubIndex;
}
