import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgCloudDlIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M17.724 8.683c-.047.934-.42 1.751-1.121 2.452-.7.677-1.646 1.016-2.837 1.016h-1.751a.837.837 0 0 1-.56-.21.884.884 0 0 1-.211-.596c0-.21.07-.385.21-.525a.771.771 0 0 1 .56-.245h1.752c.747 0 1.32-.187 1.716-.56.42-.398.654-.877.7-1.437a2.165 2.165 0 0 0-.49-1.576c-.373-.467-.934-.747-1.681-.84a.645.645 0 0 1-.525-.351c-.14-.164-.187-.374-.14-.63.233-.935.046-1.565-.56-1.892-.678-.374-1.332-.245-1.962.385-.187.187-.397.257-.63.21-.234-.023-.433-.163-.596-.42-.35-.63-.77-1.109-1.261-1.436-.467-.327-.923-.479-1.366-.455a3.28 3.28 0 0 0-1.261.245c-.607.28-1.04.712-1.296 1.296-.257.56-.245 1.25.035 2.066.07.257.058.467-.035.63-.07.211-.222.351-.455.421-.701.234-1.238.549-1.612.946-.35.397-.548.724-.595.98-.023.258-.012.503.035.736.047.444.233.84.56 1.191.327.327.736.49 1.226.49h2.382a.7.7 0 0 1 .56.246c.164.14.246.315.246.525 0 .234-.082.42-.246.56-.14.14-.326.21-.56.21H3.573c-.817 0-1.553-.28-2.206-.84-.63-.56-1.004-1.273-1.121-2.137-.117-.77.011-1.53.385-2.276.397-.77 1.086-1.378 2.067-1.822-.21-1.004-.094-1.914.35-2.732.467-.84 1.12-1.46 1.961-1.856.77-.467 1.693-.572 2.767-.315C8.851.37 9.75.989 10.473 1.993c1.051-.56 2.067-.572 3.048-.035 1.027.607 1.506 1.553 1.436 2.837.957.304 1.67.83 2.136 1.576.467.724.677 1.495.63 2.312Zm-6.095 4.729-1.891 1.891V8.228a.771.771 0 0 0-.245-.56.701.701 0 0 0-.56-.246.771.771 0 0 0-.561.245.837.837 0 0 0-.21.56l.035 7.076-1.892-1.891c-.373-.374-.747-.374-1.12 0-.374.373-.374.735 0 1.085l3.222 3.258c.163.163.338.245.525.245.21 0 .397-.082.56-.245l3.223-3.223c.374-.373.374-.747 0-1.12-.35-.374-.712-.374-1.086 0Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCloudDlIcon;
