import { MouseEvent, useCallback } from 'react';
import Ripple from 'material-ripple-effects';

const RIPPLE = new Ripple();

export function useRipple() {
  return useCallback((e: MouseEvent) => {
    if (window.__STORYSHOTS_CAPTURE__) return;
    RIPPLE.create(e, 'dark');
  }, []);
}
