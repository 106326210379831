import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '../ui/mui';
import { useController, useForm } from 'react-hook-form';
import { TextArea } from '../ui/atoms/TextArea';
import { Input } from '../ui/atoms/Input';
import { Button } from '../ui/atoms/Button';
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { ProjectManifest } from '../welcome/useProjectManifests';
import { ChipsInput } from '../ui/atoms/ChipsInput';
import { ToggleButtonGroup } from '../ui/atoms/ToggleButtonGroup';
import { HubGalleryItem } from '../welcome/HubGallery';
import { Divider } from '../dashboard/Divider';
import { Visible } from '../ui/icons';
import { useFeatureFlags } from '../core/FeatureFlagsContext';
import { uploadFile } from '../core/file-helper';
import api, { addBasePathToURL } from '../core/api-client';
import { HubPublishPolicy, Project, ProjectMeta } from '@tensorleap/api-client';
import { calcFullProjectPath } from '../core/useProjectStorage';
import { enumToOptions } from '../ui/atoms/utils/select';
import { useEnvironmentInfo } from '../core/EnvironmentInfoContext';
import { SelectCategories } from '../core/category/SelectCategories';
import {
  PROJECT_CATEGORIES_META,
  ProjectCategoriesValue,
} from '../projects/consts';
import { buildProjectUrl } from '../url/url-builder';
import { useFetchProjects } from '../core/data-fetching/projects';
import { useHistory } from 'react-router';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';

const FORM_ID = 'EDIT_PROJECT_FORM';
const hubPublishOptions = enumToOptions(HubPublishPolicy);

export interface NewProjectDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export function NewProjectDialog({ isOpen, onClose }: NewProjectDialogProps) {
  const history = useHistory();
  const { refetch } = useFetchProjects();

  const handleSubmit = useCallback(
    async (details: ProjectMeta) => {
      try {
        const { project: newProject } = await api.addProject(details);
        await refetch();
        history.push(buildProjectUrl(newProject.cid));
      } catch (e) {
        console.error(e);
      }
      onClose();
    },
    [history, onClose, refetch]
  );

  return (
    <EditProjectDialog
      isOpen={isOpen}
      onClose={onClose}
      title="NEW PROJECT"
      submitText="CREATE"
      onSubmit={handleSubmit}
    />
  );
}

export type UpdateProjectDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  project: Project;
};

export function UpdateProjectDialog({
  isOpen,
  onClose,
  project,
}: UpdateProjectDialogProps) {
  const { refetch } = useFetchProjects();
  const handleSubmit = useCallback(
    async (details: ProjectMeta) => {
      try {
        await api.updateProjectMeta({ projectId: project.cid, ...details });
        await refetch();
      } catch (e) {
        console.error(e);
      }

      onClose();
    },
    [onClose, project.cid, refetch]
  );

  const {
    environmentInfo: { clientStoragePrefixUrl },
  } = useEnvironmentInfo();

  const {
    name,
    description,
    tags,
    categories,
    hubPublishPolicy,
    bgImagePath,
  } = project;
  const bgImageUrl = useMemo(() => {
    if (!bgImagePath) {
      return;
    }
    const projectStoragePathPrefix = calcFullProjectPath({
      clientStoragePrefixUrl,
      teamId: project.teamId,
      projectId: project.cid,
    });
    return `${projectStoragePathPrefix}/${bgImagePath}`;
  }, [bgImagePath, clientStoragePrefixUrl, project]);

  return (
    <EditProjectDialog
      isOpen={isOpen}
      onClose={onClose}
      title="EDIT PROJECT"
      isEdit
      submitText="SAVE"
      onSubmit={handleSubmit}
      defaultValue={{
        name,
        description: description || '',
        tags,
        categories,
        hubPublishPolicy,
        bgImageUrl,
      }}
    />
  );
}

export type ImportProjectDialogProps = {
  projectManifest: ProjectManifest;
  isOpen: boolean;
  onClose: () => void;
};
export function ImportProjectDialog({
  projectManifest,
  isOpen,
  onClose,
}: ImportProjectDialogProps) {
  const { refetch } = useFetchProjects();

  const handleOnSubmit = useCallback(
    async (projectDetails: ProjectMeta) => {
      if (!projectDetails.bgImageUrl) {
        projectDetails.bgImageUrl = projectManifest.bgImageSrc;
      }

      try {
        await api.importProject({
          ...projectDetails,
          importUrl: projectManifest.importUrl,
        });
        await refetch();
      } catch (e) {
        console.error(e);
      }

      onClose();
    },
    [onClose, projectManifest.bgImageSrc, projectManifest.importUrl, refetch]
  );
  return (
    <EditProjectDialog
      isOpen={isOpen}
      onClose={onClose}
      title="IMPORT PROJECT"
      submitText="IMPORT"
      defaultValue={{
        name: projectManifest.name,
        description: projectManifest.description,
        tags: projectManifest.tags || [],
        hubPublishPolicy: HubPublishPolicy.NoPublish,
        bgImageUrl: projectManifest.bgImageSrc,
        categories: projectManifest.categories,
      }}
      onSubmit={handleOnSubmit}
    />
  );
}

export interface EditProjectDialogProps {
  isOpen: boolean;
  isEdit?: boolean;
  onClose: () => void;
  title: ReactNode;
  onSubmit: (details: ProjectMeta) => Promise<void>;
  submitText: string;
  defaultValue?: ProjectMeta;
}

export function EditProjectDialog({
  isOpen,
  onClose,
  title,
  onSubmit,
  submitText,
  defaultValue,
  isEdit,
}: EditProjectDialogProps): JSX.Element {
  const fileRef = useRef<HTMLInputElement>();
  const { projects = [] } = useFetchProjects();
  const [uploadedBgImage, setUploadedBgImage] = useState<File | null>(null);
  const [selectedImage, setSelectedImage] = useState<null | string>(null);
  const existedProjectNamesSet = useMemo(() => {
    const existedProjectNames = projects
      .map(({ name }) => name)
      .filter((name) => name !== defaultValue?.name || !isEdit);
    return new Set(existedProjectNames);
  }, [projects, defaultValue?.name, isEdit]);
  const displayImg = selectedImage || defaultValue?.bgImageUrl;

  const validateName = useCallback(
    (value) => {
      const trimName = value?.trim();
      if (trimName.length === 0) {
        return '';
      }
      if (existedProjectNamesSet.has(trimName)) {
        return 'The project name already exists';
      }
    },
    [existedProjectNamesSet]
  );

  const onSubmitWrapper = useCallback(
    async (details: ProjectMeta) => {
      if (uploadedBgImage) {
        const { url, fileName } = await api.getUploadSignedUrl({
          fileName: uploadedBgImage.name,
        });
        const urlWithBasePath = addBasePathToURL(url);
        await uploadFile(urlWithBasePath, uploadedBgImage);
        details.bgImageUrl = fileName;
      }
      onSubmit(details);
    },
    [onSubmit, uploadedBgImage]
  );
  const changeFileHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files?.length) {
        setUploadedBgImage(null);
        setSelectedImage(null);
        return;
      }

      event.preventDefault();
      const file = event.target.files[0];
      setUploadedBgImage(file);
      const reader = new FileReader();

      reader.onload = () => {
        setSelectedImage(reader.result as string);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    },
    []
  );
  const {
    featureFlags: { publishableProject },
  } = useFeatureFlags();

  const defaultFormValue = useMemo(() => {
    if (!defaultValue) return undefined;
    const { bgImageUrl: _, ...defaultFormValue } = defaultValue;
    return defaultFormValue;
  }, [defaultValue]);

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<ProjectMeta>({
    mode: 'onChange',
    defaultValues: defaultFormValue,
  });

  const {
    field: { ref: _refTags, ...tagsField },
  } = useController({
    control,
    name: 'tags',
    defaultValue: [],
  });

  const {
    field: { ref: _refHubAccess, ...hubAccessField },
  } = useController({
    control,
    name: 'hubPublishPolicy',
    defaultValue: HubPublishPolicy.NoPublish,
  });

  const {
    field: { ref: _refCategoryField, ...categoryField },
  } = useController({
    control,
    name: 'categories',
    defaultValue: {},
  });

  const name = watch('name');
  const description = watch('description');

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth="xl"
    >
      <div id={TOUR_SELECTORS_ENUM.IMPORT_PROJECT_DIALOG_ID}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <form
            className="w-120 px-2 flex flex-col space-y-4"
            id={FORM_ID}
            action=""
            onSubmit={handleSubmit(onSubmitWrapper)}
          >
            <Input
              label="NAME"
              {...register('name', {
                required: true,
                validate: validateName,
              })}
              error={
                errors.name?.message ||
                (errors.name && 'This field is required')
              }
            />
            <TextArea label="DESCRIPTION" {...register('description', {})} />
            <ChipsInput
              label="TAGS"
              value={tagsField.value}
              onChange={tagsField.onChange}
            />
            {publishableProject && (
              <>
                <SelectCategories
                  label="PROJECT CATEGORY"
                  categoriesMeta={PROJECT_CATEGORIES_META}
                  onChange={categoryField.onChange}
                  value={categoryField.value}
                />
                <label className="flex items-center">
                  <span className="text-sm">PUBLISH POLICY:</span>
                  <span className="flex-1" />
                  <ToggleButtonGroup
                    onChange={hubAccessField.onChange}
                    value={hubAccessField.value}
                    options={hubPublishOptions}
                  />
                </label>
                <label>
                  <span className="text-sm">UPLOAD BACKGROUND IMAGE:</span>
                  <TextField
                    className="w-full"
                    InputProps={{
                      className: '!max-h-11',
                    }}
                    variant="outlined"
                    type="search"
                    inputRef={fileRef}
                    inputProps={{
                      id: 'uploadBgImage',
                      accept: 'image/png, image/jpeg',
                      type: 'file',
                      onChange: changeFileHandler,
                    }}
                  />
                </label>
                <Divider />
                <label>
                  <Visible /> PREVIEW
                </label>
                <HubGalleryItem
                  onImport={() => undefined}
                  projectMeta={{
                    name: '',
                    tags: tagsField.value,
                    description,
                    sourceProjectId: '',
                    title: name,
                    bgImageSrc: displayImg,
                    upgradeRequired: false,
                    categories: categoryField.value as ProjectCategoriesValue,
                    size: 1000000,
                    importUrl: '',
                  }}
                />
              </>
            )}
          </form>
        </DialogContent>
        <DialogActions className="p-4">
          <Button onClick={onClose} variant="text">
            CANCEL
          </Button>
          <Button disabled={!isValid} form={FORM_ID} type="submit">
            {submitText}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
