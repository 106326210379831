import { BaseVisualizationProps, DashletCard } from '../DashletCard';
import { PreviewsContainer } from '../common/PreviewsContainer';
import { PushFiltersIcon, Settings } from '../../../ui/icons';
import { NoDataChart } from '../../../ui/charts/common/NoDataChart';
import { ScatterAnalyzerView } from './ScatterAnalyzerView';
import clsx from 'clsx';
import {
  DashletScatterContextProvider,
  POP_EXP_DEFAULT_NAME,
  useDashletScatterContext,
} from './DashletScatterContext';
import { FilterList } from '../../../filters/FilterList';
import { VisualizationFilter } from '../../../core/types/filters';
import { IconButton } from '@material-ui/core';
import { TopBarRightButtons } from '../topbar/TopBarRightButtons';
import { ScatterSettings } from './ScatterSettings';
import { useToggle } from '../../../core/useToggle';
import { useMemo, useState } from 'react';
import {
  MousePosition,
  getMousePosition,
} from '../../../core/useSelectionGroup';
import { TOUR_SELECTORS_ENUM } from '../../../tour/ToursConfig';
import { PopExpSettings } from './PopExpSettings';
import { Divider } from '../../Divider';
import { PopulationExplorationDashletData } from '@tensorleap/api-client';

type PopulationExplorationDashletProps = BaseVisualizationProps & {
  defaultFilters?: VisualizationFilter[];
  update: (data: PopulationExplorationDashletData) => Promise<void>;
  data?: PopulationExplorationDashletData;
};
const RIGHT_BORDER_CLASSES = 'border-r border-gray-800 border-solid';

export function PopulationExplorationDashlet({
  cid,
  projectId,
  selectedSessionRuns,
  defaultFilters = [],
  pinFilters = [],
  updatePinFilters,
  remove,
  duplicate,
  editMode,
  toggleEditMode,
  update,
  data,
}: PopulationExplorationDashletProps) {
  const [fullScreenMode, toggleFullScreenMode] = useToggle(false);

  const [mousePosition, setMousePosition] = useState<MousePosition>({
    x: 0,
    y: 0,
  });

  const dashletName = useMemo(
    () => (data?.data?.name as string) || POP_EXP_DEFAULT_NAME,
    [data]
  );

  return (
    <DashletScatterContextProvider
      cid={cid}
      projectId={projectId}
      data={data}
      filterProps={{
        defaultFilters,
        pinFilters,
        updatePinFilters,
      }}
    >
      <DashletCard
        fullScreenMode={fullScreenMode}
        toggleFullScreenMode={toggleFullScreenMode}
        className="flex overflow-hidden"
      >
        <div className="flex flex-row w-full h-full">
          {editMode && (
            <PopExpSettings cancel={toggleEditMode} update={update} />
          )}

          <div
            onMouseMove={(e) => setMousePosition(getMousePosition(e))}
            className="flex flex-1 bg-gray-850 flex-col overflow-hidden"
            id={TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DASHLET_ID}
          >
            <PopulationExplorationDashletTopBar
              remove={remove}
              duplicate={duplicate}
              fullScreenMode={fullScreenMode}
              toggleFullScreenMode={toggleFullScreenMode}
              editMode={editMode}
              toggleEditMode={toggleEditMode}
              dashletName={dashletName}
            />
            <div className="flex flex-1">
              {selectedSessionRuns?.length ? (
                <PreviewsContainer flex>
                  {selectedSessionRuns.map((sessionRun, i) => (
                    <ScatterAnalyzerView
                      dashletId={cid}
                      key={sessionRun.id}
                      sessionRun={sessionRun}
                      mousePosition={mousePosition}
                      className={clsx(
                        i < selectedSessionRuns.length - 1 &&
                          RIGHT_BORDER_CLASSES
                      )}
                    />
                  ))}
                </PreviewsContainer>
              ) : (
                <NoResult />
              )}
            </div>
          </div>
        </div>
      </DashletCard>
    </DashletScatterContextProvider>
  );
}

function NoResult() {
  return (
    <div className="flex flex-col flex-1 bg-gray-900">
      <NoDataChart />
    </div>
  );
}

interface PopulationExplorationDashletTopBarParams {
  remove: () => void;
  duplicate: () => void;
  fullScreenMode: boolean;
  toggleFullScreenMode: () => void;
  editMode: boolean;
  toggleEditMode: () => void;
  dashletName: string;
}

function PopulationExplorationDashletTopBar({
  remove,
  duplicate,
  fullScreenMode,
  toggleFullScreenMode,
  editMode,
  toggleEditMode,
  dashletName,
}: PopulationExplorationDashletTopBarParams) {
  const {
    filters: {
      dashletFilters: localFilters,
      updateDashletFilters: setLocalFilters,
      filterFieldsMeta,
      globalizeFilters,
    },
    viewSettings,
  } = useDashletScatterContext();

  return (
    <div className="flex flex-row h-12 border-b draggable-dashlet allow-dragging text-sm border-solid items-center gap-2 border-gray-800 overflow-hidden overflow-ellipsis">
      {!editMode && (
        <IconButton className="p-1 h-10 w-10" onClick={toggleEditMode}>
          <Settings />
        </IconButton>
      )}

      {!editMode && (
        <>
          <span className="font-bold uppercase whitespace-nowrap min-w-0 overflow-hidden overflow-ellipsis">
            {dashletName}
          </span>
          <Divider vertical className="my-4 bg-gray-400 opacity-75" />
        </>
      )}

      <ScatterSettings viewSettings={viewSettings} />

      <FilterList
        expandedByDefault
        filterFieldsMeta={filterFieldsMeta}
        filters={localFilters}
        onFiltersChange={setLocalFilters}
        extraContent={
          <IconButton
            title="Push Filters Back to Dashboard"
            className="w-8 h-8 bg-success-600 hover:bg-success-600"
            onClick={globalizeFilters}
          >
            <PushFiltersIcon className="w-5 h-5 m-0 p-0" />
          </IconButton>
        }
      />

      <span className="flex-1" />

      <TopBarRightButtons
        remove={remove}
        duplicate={duplicate}
        fullScreenMode={fullScreenMode}
        toggleFullScreenMode={toggleFullScreenMode}
      />
    </div>
  );
}
