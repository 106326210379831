import { Dialog } from '@material-ui/core';
import { PropsWithChildren } from 'react';

export interface FullScreenEnablerProps {
  fullScreenMode: boolean;
  exitFullScreenMode: (fullScreenMode: boolean) => void;
}

export function FullScreenEnabler({
  fullScreenMode,
  exitFullScreenMode,
  children,
}: PropsWithChildren<FullScreenEnablerProps>): JSX.Element {
  const FullScreenDialog = (
    <Dialog open={fullScreenMode} onClose={exitFullScreenMode} fullScreen>
      {children}
    </Dialog>
  );

  return (
    <>
      {children}
      {FullScreenDialog}
    </>
  );
}
