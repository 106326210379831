import { Divider } from '@material-ui/core';
import { useController } from 'react-hook-form';
import { NO_SPLIT_SELECTED } from '../ElasticVis/utils';
import {
  ChartSplit,
  DashletFormProps,
  MetaDataTableEntity,
  MetricTableEntity,
} from '../types';
import { MetaDataTable, MetricsTable, Split } from './FormFields';

export type TableParams = {
  metaData: MetaDataTableEntity[];
  metrics: MetricTableEntity[];
  displayOnlyLastEpoch: boolean;
  splitSeriesBySubset: boolean;
  splitByPrediction: boolean;
  firstSplit: ChartSplit;
  secondSplit: ChartSplit;
};

export function TableDashletForm(
  props: DashletFormProps<TableParams>
): JSX.Element {
  const dashletFields = props.fields;

  const {
    fields,
    form: { control },
    defaultAggregatableField = '',
    defaultNumericField = '',
  } = props;

  const {
    field: { ...metaDataField },
  } = useController({
    control,
    name: 'data.metaData',
    defaultValue: [],
  });

  const {
    field: { ...metricsField },
  } = useController({
    control,
    name: 'data.metrics',
    defaultValue: [],
  });

  const {
    field: { ref: _firstSplit, ...firstSplitField },
  } = useController({
    control,
    name: 'data.firstSplit',
    defaultValue: { field: NO_SPLIT_SELECTED },
  });

  const {
    field: { ref: _secondSplit, ...secondSplitField },
  } = useController({
    control,
    name: 'data.secondSplit',
    defaultValue: { field: NO_SPLIT_SELECTED },
  });

  return (
    <div className="flex flex-col gap-6 overflow-x-hidden p-2">
      <MetricsTable
        options={fields.numericFields}
        defaultAggregatableField={defaultNumericField}
        entities={metricsField.value}
        setEntities={metricsField.onChange}
      />

      <MetaDataTable
        fields={fields}
        defaultAggregatableField={defaultAggregatableField}
        entities={metaDataField.value}
        onEntitiesChange={metaDataField.onChange}
        className="pb-2"
      />

      <Divider orientation="horizontal" />

      <Split
        label="vertical split"
        dashletFields={dashletFields}
        {...firstSplitField}
      />

      <Split
        label="horizontal split"
        dashletFields={dashletFields}
        {...secondSplitField}
      />
    </div>
  );
}
