import React, { useContext, FC, useMemo, createContext } from 'react';
import { useGraphErrors } from './state-managers/NetworkAlertsCalc';
import { useGenerateGraphWarnings } from './state-managers/NetworkWarningsCalc';
import { NetworkWizardData } from './types';

export interface NetworkWizardContext {
  allAlerts: NetworkWizardData[];
}

const DEFAULT: NetworkWizardContext = {
  allAlerts: [],
};

export const NetworkWizardContext = createContext<NetworkWizardContext>(
  DEFAULT
);

export const NetworkWizardContextProvider: FC = ({ children }) => {
  const graphAlerts = useGraphErrors();
  const graphWarnings = useGenerateGraphWarnings();

  const value = useMemo(() => {
    const allAlerts = [graphAlerts, graphWarnings].flatMap((x) => x);
    return { allAlerts };
  }, [graphAlerts, graphWarnings]);

  return (
    <NetworkWizardContext.Provider value={value}>
      {children}
    </NetworkWizardContext.Provider>
  );
};

export const useNetworkWizardContext: () => NetworkWizardContext = () =>
  useContext(NetworkWizardContext);
