import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgSaveAs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M20.426 11.75a1.96 1.96 0 0 1 1.387.557c.176.169.313.374.41.615a1.958 1.958 0 0 1-.41 2.139l-7.002 7.002-3.682.918.918-3.682 7.002-6.992c.19-.19.4-.33.635-.42a2.03 2.03 0 0 1 .742-.137Zm.498 2.432a.678.678 0 0 0 .205-.498.627.627 0 0 0-.205-.489.675.675 0 0 0-.498-.195.73.73 0 0 0-.263.049.538.538 0 0 0-.225.136l-6.758 6.758-.332 1.319 1.318-.332 6.758-6.748ZM9.88 18h1.25l-1.25 1.25H5.241l-1.612-1.611V5.5c0-.176.033-.339.098-.488a1.288 1.288 0 0 1 .664-.664c.15-.065.313-.098.488-.098h12.5a1.177 1.177 0 0 1 .879.371 1.177 1.177 0 0 1 .371.879v5l-1.25 1.25V5.5h-1.25v6.25h-10V5.5H4.88v11.611l.89.889h.36v-5h8.75v1.25l-1.25 1.25v-1.25H7.38V18h1.25v-2.5h1.25V18Zm-2.5-7.5h7.5v-5h-7.5v5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSaveAs;
