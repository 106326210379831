import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { SecretManager } from '@tensorleap/api-client';

import api from '../core/api-client';

export interface SecretManagementContextProps {
  isLoading: boolean;
  secretManagers: SecretManager[];
  fetchSecretManagers: () => Promise<void>;
  error?: Error;
}
const contextDefaults: SecretManagementContextProps = {
  isLoading: true,
  secretManagers: [],
  fetchSecretManagers: Promise.resolve,
};

const SecretManagementContext = createContext<SecretManagementContextProps>(
  contextDefaults
);

export const SecretManagementProvider: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(contextDefaults.isLoading);
  const [secretManagers, setSecretManagers] = useState(
    contextDefaults.secretManagers
  );
  const [error, setError] = useState(contextDefaults.error);

  const fetchSecretManagers = useCallback<
    SecretManagementContextProps['fetchSecretManagers']
  >(async () => {
    try {
      setIsLoading(true);
      setError(undefined);

      const { results } = await api.getSecretManagerList();
      setSecretManagers(results);
    } catch (e) {
      setError(e as Error);
    } finally {
      setIsLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchSecretManagers();
  }, [fetchSecretManagers]);

  return (
    <SecretManagementContext.Provider
      value={{ isLoading, secretManagers, error, fetchSecretManagers }}
    >
      {children}
    </SecretManagementContext.Provider>
  );
};
SecretManagementProvider.displayName = 'SecretManagementProvider';

export function useSecretManagers(): SecretManagementContextProps {
  return useContext(SecretManagementContext);
}
