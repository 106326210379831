import { CircularProgress } from '@material-ui/core';
import { Button } from '../../../ui/atoms/Button';
import { SampleAnalysisAlgo } from '@tensorleap/api-client';
import { Setter } from '../../../core/types';
import { AlgoSelect } from '../../../ui/atoms/AlgoSelect';

export interface VisualizationRunningPlaceholderProps {
  processName?: string;
  tourId?: string;
}

export function VisualizationRunningPlaceholder({
  processName = 'processing...',
  tourId,
}: VisualizationRunningPlaceholderProps) {
  return (
    <div
      className="text-gray-600 w-full text-center flex flex-col gap-2 items-center justify-center uppercase"
      id={tourId}
    >
      <CircularProgress size={80} className="mt-4" />
      {processName}
    </div>
  );
}

type NoVisualizationsForEpochPlaceholderProps = {
  onUpgrade: () => void;
  resourceName?: string;
  actionName?: string;
  selectedAlgo: SampleAnalysisAlgo;
  setSelectedAlgo: Setter<SampleAnalysisAlgo>;
};

export function NoVisualizationsForEpochPlaceholder({
  onUpgrade,
  resourceName = 'visualizations',
  actionName = 'fetch',
  selectedAlgo,
  setSelectedAlgo,
}: NoVisualizationsForEpochPlaceholderProps) {
  return (
    <div className="text-gray-600 w-full text-center flex flex-col items-center justify-center uppercase">
      <p className="w-60 pb-6">
        no {resourceName} is available for this epoch.
      </p>
      <p className="w-60 pb-8">click to fetch it...</p>
      <AlgoSelect
        selectedAlgo={selectedAlgo}
        setSelectedAlgo={setSelectedAlgo}
        small
        className="pb-6 w-44"
      />
      <Button onClick={onUpgrade} variant="inverted-gray" className="max-h-9">
        {actionName}
      </Button>
    </div>
  );
}
