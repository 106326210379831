import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgApplyMapIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m15.027 2.2-4.5 2.813-4.5-2.813-4.5 2.813V15.7l4.5-2.813 4.5 2.813 4.5-2.813v-2.074h-1.125v1.45l-.225.141v.09h-.144L11.09 14.02v-2.61l-.112-.098-1.013-.886v3.594l-3.375-2.11V3.875l3.375 2.114v1.485l1.013-.886.112-.098v-.5l2.813-1.759v2.923h1.125V2.2ZM5.464 3.883v8.029L2.652 13.67V5.64l2.812-1.757Z"
      fill="currentColor"
    />
    <path
      d="m9.627 8.957 3.371 2.707V9.637H18v-1.36h-5.002V6.25L9.627 8.957Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgApplyMapIcon;
