import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path d="M9 7.2a.9.9 0 1 0 0-1.8.9.9 0 0 0 0 1.8Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.05 13.95a7 7 0 1 1 9.9-9.9 7 7 0 0 1-9.9 9.9ZM14.6 9A5.6 5.6 0 1 1 3.4 9a5.6 5.6 0 0 1 11.2 0ZM8.3 9a.7.7 0 0 1 1.4 0v3.6a.7.7 0 1 1-1.4 0V9Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgInfo;
