import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMergedObject } from '../core/useMergedObject';
import {
  SelectedSessionRun,
  useModelFilter,
} from '../ui/molecules/useModelFilter';
import { groupBy } from 'lodash';
import { REQUEST_INIT_NO_CACHE } from '../core/data-fetching/fetch-json';
import {
  FetchJsonResponse,
  useFetchJsonMultiple,
} from '../core/data-fetching/fetch-json-multiple';
import { GeneralInsightsInfo, InsightType } from '@tensorleap/api-client';
import { LoadingStatus } from '../core/data-fetching/loading-status';
import { useFetchArchiveInsights } from '../core/data-fetching/archiveInsights';
import { useCurrentProject } from '../core/CurrentProjectContext';
import api from '../core/api-client';
import { extractDigest } from './utils';

const REFRESH_INTERVAL_MS = 5000; // 0.5 minutes
const REFRESH_INTERVAL_AFTER_FOUND_MS = 120000; // 2 minutes
const MAX_IS_LOADING_TIMEOUT_MS = 600000; // 10 minutes

export type InsightsDigestedResponse = {
  insights: InsightType[];
  scatter_visualization_guid: string;
  performance_info?: GeneralInsightsInfo;
};

export type InsightPerDigest = {
  url?: string;
  csvUrl?: string;
  loadingStatus: LoadingStatus;
  dashletIds: string[];
};

export type InsightsBySessionRunAndEpoch = {
  selectedSessionRun: SelectedSessionRun;
  epoch: number;
  data: InsightPerDigest[];
};

export type InsightRegisterKey = {
  sessionRunId: string;
  epoch: number;
  dashletId: string;
  digest: string;
};

export type InsightRegister = {
  key: InsightRegisterKey;
  url?: string;
  loadingStatus: LoadingStatus;
  csvUrl?: string;
};
export type InsightsContextProps = {
  registerInsights: (
    key: InsightRegisterKey,
    loadingStatus: LoadingStatus,
    url?: string,
    csvUrl?: string
  ) => void;
  unregisterInsights: (key: InsightRegisterKey) => void;
  insightBySessionRunAndEpoch: InsightsBySessionRunAndEpoch[];
  insightCount: number;
  fetchedInsights: FetchJsonResponse<InsightsDigestedResponse>[];
  archivedInsights: {
    [digest: string]: number[];
  };
  archiveInsight: (popExpDigest: string, insightIndex: number) => void;
  unarchivedInsight: (
    popExpDigest: string,
    insightIndex: number
  ) => Promise<void>;
};

const DEFAULT_VALUES: InsightsContextProps = {
  registerInsights: () => undefined,
  unregisterInsights: () => undefined,
  insightBySessionRunAndEpoch: [],
  insightCount: 0,
  fetchedInsights: [],
  archivedInsights: {},
  archiveInsight: () => undefined,
  unarchivedInsight: () => Promise.resolve(),
};

export const InsightsContext = createContext<InsightsContextProps>(
  DEFAULT_VALUES
);
export const InsightsContextProvider: FC = ({ children }) => {
  const { currentProjectId } = useCurrentProject();
  if (!currentProjectId) {
    console.error('InsightsContextProvider: currentProjectId is not set');
    throw new Error('InsightsContextProvider: currentProjectId is not set');
  }

  const [insightsRegistrations, setInsightsRegistrations] = useState<
    InsightRegister[]
  >([]);

  const registerInsights = useCallback(
    (
      key: InsightRegisterKey,
      loadingStatus: LoadingStatus,
      url?: string,
      csvUrl?: string
    ) => {
      setInsightsRegistrations((prev) => [
        ...prev,
        { key, url, loadingStatus, csvUrl },
      ]);
    },
    []
  );

  const unregisterInsights = useCallback((key: InsightRegisterKey) => {
    setInsightsRegistrations((prev) => prev.filter((r) => r.key !== key));
  }, []);

  const { selected: selectedSessionRuns } = useModelFilter();

  const insightBySessionRunAndEpoch = useMemo<
    InsightsBySessionRunAndEpoch[]
  >(() => {
    const bySessionRunAndEpoch = groupBy(
      insightsRegistrations,
      (r) => `${r.key.sessionRunId}-${r.key.epoch}`
    );

    const selectedSessionRunsById = Object.fromEntries(
      selectedSessionRuns.map((sr) => [sr.id, sr])
    );

    return Object.entries(bySessionRunAndEpoch).flatMap(
      ([key, registrations]) => {
        const [sessionRunId, epoch] = key.split('-');
        const selectedSessionRun = selectedSessionRunsById[sessionRunId];
        if (!selectedSessionRun) return [];

        const byUrlOrSessionRunAndEpoch = groupBy(
          registrations,
          (r) => r.url || `${r.key.sessionRunId}-${r.key.epoch}`
        );
        const data = Object.entries(byUrlOrSessionRunAndEpoch).map(
          ([, registrations]) => ({
            url: registrations[0].url,
            csvUrl: registrations[0].csvUrl,
            loadingStatus: registrations[0].loadingStatus,
            dashletIds: registrations.map((r) => r.key.dashletId),
          })
        );

        return [
          {
            selectedSessionRun,
            epoch: Number(epoch),
            data,
          },
        ];
      }
    );
  }, [insightsRegistrations, selectedSessionRuns]);

  const [fetchedInsightsState, setFetchedInsightsState] = useState<
    FetchJsonResponse<InsightsDigestedResponse>[]
  >([]);

  const urls = useMemo(
    () =>
      insightBySessionRunAndEpoch.flatMap(({ data }) =>
        data.map(({ url }) => url).filter((url): url is string => !!url)
      ),
    [insightBySessionRunAndEpoch]
  );

  const {
    data: fetchedInsightsResponse,
  } = useFetchJsonMultiple<InsightsDigestedResponse>({
    urls,
    fetchOptions: REQUEST_INIT_NO_CACHE,
    refreshIntervalMs: REFRESH_INTERVAL_MS,
    maxIsLoadingTimeoutMs: MAX_IS_LOADING_TIMEOUT_MS,
    refreshIntervalAfterSuccessMS: REFRESH_INTERVAL_AFTER_FOUND_MS,
  });

  function deepEqual(obj1: unknown, obj2: unknown) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  const extractSessionRunId = (key: string) => {
    const match = key.match(/sessionruns\/(.*?)\/epochs/);
    return match ? match[1] : null;
  };

  const popExpDigests = useMemo(() => {
    return fetchedInsightsResponse
      ?.map((response) => {
        const digest = extractDigest(response.url);
        return digest;
      })
      .filter((digest): digest is string => !!digest);
  }, [fetchedInsightsResponse]);

  const {
    archivedInsights,
    refetch: refetchArchiveInsights,
  } = useFetchArchiveInsights({
    projectId: currentProjectId,
    popExpDigests,
  });

  useEffect(() => {
    refetchArchiveInsights();
  }, [refetchArchiveInsights, popExpDigests]);

  const archiveInsight = useCallback(
    async (popExpDigest: string, insightIndex: number) => {
      await api.archiveInsight({
        projectId: currentProjectId,
        popExpDigest,
        insightIndexes: [insightIndex],
      });
      refetchArchiveInsights();
    },
    [currentProjectId, refetchArchiveInsights]
  );

  const unarchivedInsight = useCallback(
    async (popExpDigest: string, insightIndex: number) => {
      await api.unarchiveInsight({
        projectId: currentProjectId,
        popExpDigest,
        insightIndexes: [insightIndex],
      });
      refetchArchiveInsights();
    },
    [currentProjectId, refetchArchiveInsights]
  );

  useEffect(() => {
    if (!fetchedInsightsResponse) {
      return;
    }

    const computeNewState = (
      prevState: FetchJsonResponse<InsightsDigestedResponse>[]
    ): FetchJsonResponse<InsightsDigestedResponse>[] => {
      const responseMap = new Map(
        fetchedInsightsResponse.map((item) => [
          extractSessionRunId(item.url),
          item,
        ])
      );

      const newState = prevState
        .filter((item) => responseMap.has(extractSessionRunId(item.url)))
        .map((item) => {
          const guid = extractSessionRunId(item.url);
          const responseItem = responseMap.get(guid);
          if (
            responseItem &&
            responseItem.error === undefined &&
            !responseItem.isLoading
          ) {
            return responseItem;
          }
          return item;
        });

      fetchedInsightsResponse.forEach((responseItem) => {
        const guid = extractSessionRunId(responseItem.url);
        if (!newState.some((item) => extractSessionRunId(item.url) === guid)) {
          newState.push(responseItem);
        }
      });

      return newState;
    };

    setFetchedInsightsState((prevState) => {
      const newState = computeNewState(prevState);
      if (!deepEqual(newState, prevState)) {
        return newState;
      }
      return prevState;
    });
  }, [fetchedInsightsResponse]);

  const fetchedInsights = useMemo((): FetchJsonResponse<InsightsDigestedResponse>[] => {
    const stateDataMap = new Map(
      fetchedInsightsState.map((item) => [
        extractSessionRunId(item.url),
        item.data,
      ])
    );

    return (
      fetchedInsightsResponse?.map((responseItem) => {
        const guid = extractSessionRunId(responseItem.url);
        const stateData = stateDataMap.get(guid);
        if (
          (responseItem.isLoading || responseItem.error !== undefined) &&
          stateData
        ) {
          return {
            ...responseItem,
            data: stateData,
          };
        }
        return responseItem;
      }) || []
    );
  }, [fetchedInsightsState, fetchedInsightsResponse]);

  const insightCount = useMemo(
    () => fetchedInsights.flatMap(({ data }) => data?.insights || []).length,
    [fetchedInsights]
  );

  const value = useMergedObject({
    registerInsights,
    unregisterInsights,
    insightBySessionRunAndEpoch,
    insightCount,
    fetchedInsights,
    archivedInsights,
    archiveInsight,
    unarchivedInsight,
  });

  return (
    <InsightsContext.Provider value={value}>
      {children}
    </InsightsContext.Provider>
  );
};

export function useInsightsContext() {
  return useContext(InsightsContext);
}
