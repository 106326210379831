import { InsightType } from '@tensorleap/api-client';
import { CardBody } from '../ui/molecules/Card';
import { SelectedSessionRun } from '../ui/molecules/useModelFilter';
import { InsightCardText } from './InsightCardText';

export type InsightCardBodyProps = {
  body?: JSX.Element;
  insightData?: InsightType;
  selectedSessionRun?: SelectedSessionRun;
};
export function InsightCardBody({
  body,
  insightData,
  selectedSessionRun,
}: InsightCardBodyProps): JSX.Element {
  if (body !== undefined) {
    return <CardBody className="text-sm">{body}</CardBody>;
  }

  if (insightData !== undefined && selectedSessionRun !== undefined) {
    return (
      <CardBody className="text-sm">
        <InsightCardText
          insightData={insightData}
          selectedModel={selectedSessionRun}
        />
      </CardBody>
    );
  }

  console.error('cant create insight body');
  return <span />;
}
