import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgBagelIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M6.605 2c-2.05 0-3.664.61-4.84 1.832C.588 5.053 0 6.665 0 8.667v6.666c0 2.12.563 3.758 1.69 4.911C2.831 21.414 4.47 22 6.604 22h10.79c2.202 0 3.849-.577 4.941-1.73 1.11-1.154 1.664-2.8 1.664-4.937V8.667c0-2.104-.58-3.74-1.74-4.911C21.1 2.586 19.48 2 17.395 2H6.605ZM6 3.832h12c1.378 0 2.42.365 3.126 1.094.723.73 1.084 1.773 1.084 3.13v7.888c0 1.391-.361 2.451-1.084 3.18-.706.713-1.748 1.07-3.126 1.07H6c-1.361 0-2.403-.365-3.126-1.095-.723-.73-1.084-1.78-1.084-3.155V7.98c0-1.324.361-2.341 1.084-3.054.74-.73 1.781-1.094 3.126-1.094Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.91 6.845A5.995 5.995 0 0 1 11.597 6v1.607c-2.242.202-4 2.09-4 4.387a4.402 4.402 0 0 0 4.4 4.405 4.38 4.38 0 0 0 2.816-1.02l1.135 1.137a5.997 5.997 0 0 1-9.492-2.224 6.013 6.013 0 0 1 2.452-7.447Zm6.47 7.968 1.134 1.136a5.986 5.986 0 0 0 1.484-3.955 5.993 5.993 0 0 0-.465-2.323l-1.435.719c.194.497.3 1.038.3 1.604a4.39 4.39 0 0 1-1.018 2.82Zm.36-5.14 1.434-.718A6.002 6.002 0 0 0 12.398 6v1.607a4.399 4.399 0 0 1 3.341 2.067Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBagelIcon;
