import { useMemo } from 'react';
import {
  GraphErrorKind,
  NetworkWizardCategory,
  NetworkWizardData,
  NetworkWizardErrorSeverity,
} from '../types';
import { GraphErrorType } from '../errors';

export function useGraphErrorData({
  title,
  msg,
  category,
}: GraphErrorType): NetworkWizardData[] {
  return useMemo(
    () => [
      {
        errorType: GraphErrorKind.graph,
        category: category || NetworkWizardCategory.MODEL,
        title: title || 'INVALID GRAPH',
        message: msg,
        calculateKey: () => msg || '',
        errorSeverity: NetworkWizardErrorSeverity.ERROR,
        key: msg || '',
      },
    ],
    [category, msg, title]
  );
}
