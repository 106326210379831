import { Slider } from '@material-ui/core';
import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { labelsColorSupplier } from '../../core/color-helper';
import { useFetcher } from '../../core/useFetcher';
import {
  FILTER_LABEL_ICON,
  LabelOption,
  MultiSelectIconMenu,
  renderLabelOption,
} from '../atoms/MultiSelectIconMenu';
import { OptionalCropImage } from './CropImage';
import { CropImg } from './types';
import { fetchImageData, imageDataToImageSrc, IMG_DATA_CHANELS } from './utils';
import {
  StoredProjectImg,
  useMapStoredProjectImgUrl,
} from '../../core/useProjectStorage';
import { TransformComponent } from 'react-zoom-pan-pinch';

type ImageWithSegmentationProps = {
  imgSrc: string;
  segmentaionSrc: string;
  crop?: CropImg;
  className?: string;
  labels: string[];
  showMenu?: boolean;
  allowZoom?: boolean;
};

export function ImageWithSegmentationAndMapUrl({
  imgSrc,
  segmentaionSrc,
  allowZoom,
  ...props
}: ImageWithSegmentationProps): JSX.Element {
  const mappedImgSrc = useMapStoredProjectImgUrl(imgSrc);
  const mappedSegSrc = useMapStoredProjectImgUrl(segmentaionSrc);

  return (
    <ImageWithSegmentation
      imgSrc={mappedImgSrc}
      segmentaionSrc={mappedSegSrc}
      allowZoom={allowZoom}
      {...props}
    />
  );
}

export function ImageWithSegmentation({
  imgSrc,
  segmentaionSrc,
  className,
  crop,
  labels,
  allowZoom,
  showMenu = true,
}: ImageWithSegmentationProps): JSX.Element {
  const fetchImage = useCallback(() => {
    return fetchImageData(segmentaionSrc, crop);
  }, [segmentaionSrc, crop]);
  const { data: imgData } = useFetcher(fetchImage);
  const [segOpacity, setSegOpacity] = useState(0.8);
  const [ignoredLabels, setIgnoredLabels] = useState<number[]>([]);

  const [imageSrc, labelOptions] = useMemo(() => {
    if (!imgData) return [undefined, []];
    const [filterImgData, labelOptions] = preparSegmantationImgData(
      imgData,
      labels,
      ignoredLabels
    );
    const imageSrc = imageDataToImageSrc(filterImgData);
    return [imageSrc, labelOptions];
  }, [imgData, labels, ignoredLabels]);

  return (
    <div className={clsx('flex flex-col', className)}>
      {allowZoom ? (
        <TransformComponent
          contentStyle={{
            height: '100%',
            width: '100%',
          }}
          wrapperStyle={{
            height: '100%',
            width: '100%',
          }}
        >
          <Content
            imgSrc={imgSrc}
            imageSrc={imageSrc}
            segOpacity={segOpacity}
            crop={crop}
          />
        </TransformComponent>
      ) : (
        <Content
          imgSrc={imgSrc}
          imageSrc={imageSrc}
          segOpacity={segOpacity}
          crop={crop}
        />
      )}
      {showMenu && (
        <div className="flex justify-center">
          <MultiSelectIconMenu<LabelOption, number>
            icon={FILTER_LABEL_ICON}
            iconWrapperClassName="m-2 h-8 w-8"
            invertedSelection
            options={labelOptions}
            value={ignoredLabels}
            onChange={setIgnoredLabels}
            renderOption={renderLabelOption}
          />
          <div className="flex flex-col min-w-[100px] items-center">
            <Slider
              className="max-w-[240px]"
              value={segOpacity}
              step={0.05}
              min={0}
              max={1}
              onChange={(_, v) => setSegOpacity(v as number)}
            />
            <span className="text-xs font-bold text-gray-500">OPACITY</span>
          </div>
        </div>
      )}
    </div>
  );
}

function preparSegmantationImgData(
  imageData: ImageData,
  labels: string[],
  ignoreLabelIndeces: number[]
): [ImageData, LabelOption[]] {
  const newData = new Uint8ClampedArray(imageData.data.length).fill(0);
  const ignoreLabelIndecesSet = new Set(ignoreLabelIndeces);
  const labelOptionsByShownLabelIndex = new Map<number, LabelOption>();

  const colorByLabelIndex = new Map(
    labels.map((label, labelIndex) => [
      labelIndex,
      labelsColorSupplier.getAsRGB(label),
    ])
  );

  const unknownLabelIndex = new Set();

  for (let i = 0; i < imageData.data.length; i += IMG_DATA_CHANELS) {
    const labelIndex = imageData.data[i];
    const color = colorByLabelIndex.get(labelIndex);
    if (!color) {
      unknownLabelIndex.add(labelIndex);
      continue;
    }
    if (!labelOptionsByShownLabelIndex.has(labelIndex)) {
      labelOptionsByShownLabelIndex.set(labelIndex, {
        label: labels[labelIndex],
        value: labelIndex,
        color: labelsColorSupplier.get(labels[labelIndex]),
      });
    }
    if (ignoreLabelIndecesSet.has(labelIndex)) continue;

    const [r, g, b] = color;
    newData[i] = r;
    newData[i + 1] = g;
    newData[i + 2] = b;
    newData[i + 3] = 255;
  }

  if (unknownLabelIndex.size) {
    console.warn(
      `Segmentation image contains unknown pixel values: ${Array.from(
        unknownLabelIndex
      )}, Are you sure your labels are mapped currently?`
    );
  }

  const mappedImageData = new ImageData(newData, imageData.width);
  return [mappedImageData, Array.from(labelOptionsByShownLabelIndex.values())];
}

interface ContentProps {
  imgSrc: string;
  imageSrc?: string;
  segOpacity: number;
  crop?: CropImg;
}

function Content({ imgSrc, imageSrc, segOpacity, crop }: ContentProps) {
  return (
    <div className="relative flex-1 max-w-fit self-center min-h-0">
      <OptionalCropImage
        src={imgSrc}
        crop={crop}
        className="self-center h-full object-contain"
      />

      {imageSrc && (
        <div className="absolute inset-0">
          <StoredProjectImg
            className="h-full object-contain"
            src={imageSrc}
            style={{ opacity: segOpacity }}
          />
        </div>
      )}
    </div>
  );
}
