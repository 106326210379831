import { Layout } from 'react-grid-layout';
import {
  Responsive as ReactGridLayout,
  ResponsiveProps,
  Layouts,
} from 'react-grid-layout';
import { useEffect, useMemo, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import { isEqual } from 'lodash';
import {
  BaseDashboardItem,
  Layout as ApiLayout,
  SizedLayout,
} from '@tensorleap/api-client';

import { useDashboardContext } from './DashboardContext';
import { useDebounce } from '../core/useDebounce';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './react-grid-layout-override.css';
import clsx from 'clsx';

type DashboardSize = keyof SizedLayout;

export const XS_WIDTH = 4;
export const SM_WIDTH = 6;
export const MD_WIDTH = 10;
export const LG_WIDTH = 12;

export const MIN_WIDTH = 3;
export const MIN_HIEGHT = 2;

export const DEFAULT_HEIGHT = 3;

export const DEFAULT_LAYOUT_BY_SIZE = {
  lg: { x: 0, y: 0, w: LG_WIDTH, h: DEFAULT_HEIGHT },
  md: { x: 0, y: 0, w: MD_WIDTH, h: DEFAULT_HEIGHT },
  sm: { x: 0, y: 0, w: SM_WIDTH, h: DEFAULT_HEIGHT },
  xs: { x: 0, y: 0, w: XS_WIDTH, h: DEFAULT_HEIGHT },
};

export const DEFAULT_GRID_ITEM_PROPS: Record<
  DashboardSize,
  Omit<Layout, 'i'>
> = {
  lg: { ...DEFAULT_LAYOUT_BY_SIZE.lg, minH: MIN_HIEGHT, minW: MIN_WIDTH },
  md: { ...DEFAULT_LAYOUT_BY_SIZE.md, minH: MIN_HIEGHT, minW: MIN_WIDTH },
  sm: { ...DEFAULT_LAYOUT_BY_SIZE.sm, minH: MIN_HIEGHT, minW: MIN_WIDTH },
  xs: { ...DEFAULT_LAYOUT_BY_SIZE.xs, minH: MIN_HIEGHT, minW: MIN_WIDTH },
};

const BREAKPOINTS: Record<DashboardSize, number> = {
  lg: 1200,
  md: 996,
  sm: 768,
  xs: 0,
};

const COLS: Record<DashboardSize, number> = {
  lg: LG_WIDTH,
  md: MD_WIDTH,
  sm: SM_WIDTH,
  xs: XS_WIDTH,
};

export function generateLayout(
  items: BaseDashboardItem[],
  size: DashboardSize
): Layout[] {
  return items.map((item) => {
    return {
      ...DEFAULT_GRID_ITEM_PROPS[size],
      ...(item.layout[size] ? item.layout[size] : item.layout),
      i: item.cid,
    };
  });
}

function layoutToApiLayout({ x, y, w, h }: Layout): ApiLayout {
  return { x, y, w, h };
}

export function updateDashboardLayout(
  layout: Record<DashboardSize, Layout[]>,
  items: BaseDashboardItem[]
): BaseDashboardItem[] {
  return Object.entries(layout).reduce(
    (itemsMapped, [size, layout]) =>
      updateDashboardLayoutBySize(layout, size as DashboardSize, itemsMapped),
    items
  );
}

function updateDashboardLayoutBySize<T extends BaseDashboardItem>(
  layout: Layout[],
  size: DashboardSize,
  items: T[]
): T[] {
  return items.map(
    ({ cid, ...rest }) =>
      (({
        ...rest,
        cid,
        layout: {
          ...rest.layout,
          [size]: layoutToApiLayout(layout.find((l) => l.i === cid) as Layout),
        },
      } as BaseDashboardItem) as T)
  );
}

export const RESIZE_HANDLES: ResponsiveProps['resizeHandles'] = [
  's',
  'w',
  'e',
  'n',
  'sw',
  'nw',
  'se',
  'ne',
];

export type DashboardLayoutProps<T extends BaseDashboardItem> = {
  className?: string;
  onChange: (_: T[]) => void;
  ignoreItem?: (_: T) => boolean;
  items: T[];
  renderDashlet: (item: T) => JSX.Element;
};
export function DashboardLayout<T extends BaseDashboardItem>({
  className,
  onChange: update,
  ignoreItem,
  items,
  renderDashlet: renderDashled,
}: DashboardLayoutProps<T>) {
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useResizeObserver({ ref });

  const { setReactGridLayoutRef } = useDashboardContext();
  useEffect(() => {
    if (ref.current) {
      setReactGridLayoutRef(ref);
    }
  }, [setReactGridLayoutRef]);

  const [layouts, setLayouts] = useState<Layouts | null>(null);
  const ignoreItems = useMemo(
    () =>
      new Set(ignoreItem ? items.filter(ignoreItem).map(({ cid }) => cid) : []),
    [items, ignoreItem]
  );

  useEffect(() => {
    const newLayout = {
      lg: generateLayout(items, 'lg'),
      md: generateLayout(items, 'md'),
      sm: generateLayout(items, 'sm'),
      xs: generateLayout(items, 'xs'),
    };
    setLayouts(newLayout);
  }, [items]);

  const handleLayoutChange = useDebounce<
    Exclude<ResponsiveProps['onLayoutChange'], undefined>
  >((currentLayout, allLayouts) => {
    let size: DashboardSize = 'lg';
    if (currentLayout === allLayouts.sm) {
      size = 'sm';
    } else if (currentLayout === allLayouts.md) {
      size = 'md';
    } else if (currentLayout === allLayouts.xs) {
      size = 'xs';
    }

    const updatedVisualizations = updateDashboardLayoutBySize(
      currentLayout,
      size,
      items
    );

    if (
      isEqual(
        updatedVisualizations.map(({ layout }) => layout[size]),
        items.map(({ layout }) => layout[size])
      )
    ) {
      return;
    }
    update(updatedVisualizations);
  }, 200);

  return (
    <div ref={ref} className={className}>
      {layouts && width && (
        <ReactGridLayout
          width={width}
          layouts={layouts}
          onLayoutChange={handleLayoutChange}
          resizeHandles={RESIZE_HANDLES}
          breakpoints={BREAKPOINTS}
          cols={COLS}
          draggableHandle=".allow-dragging"
          draggableCancel=".no-dragging"
        >
          {items.map((item) => (
            <div
              key={item.cid}
              className={clsx(ignoreItems.has(item.cid) && 'hidden')}
            >
              {renderDashled(item)}
            </div>
          ))}
        </ReactGridLayout>
      )}
    </div>
  );
}
