import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  PopulationExplorationDigest,
  calcPopulationExplorationDigest,
} from './calcPopulationExplorationDigest';
import {
  JobStatus,
  PopulationExplorationResponse,
} from '@tensorleap/api-client';
import api from '../../../core/api-client';
import { useEnvironmentInfo } from '../../../core/EnvironmentInfoContext';
import { useAsyncInterval } from '../../../core/useAsyncInterval';
import { first } from 'lodash';
import { useMounted } from '../../../core/useMounted';

export enum DataStatus {
  NoData = 'NoData',
  Ready = 'Ready',
  Updating = 'Updating',
}

type Props = {
  dataStatus: DataStatus;
  populationParams: PopulationExplorationDigest['populationParams'];
  projectId: string;
};

type PeRequestState = {
  dataStatus: DataStatus;
  digest: string;
  baseDigest: string;
  populationParams: PopulationExplorationDigest['populationParams'];
  jobResult?: PopulationExplorationResponse;
};
const MIN_INTERVAL_BETWEEN_PULLING = 5 * 60 * 1000; // 5 minutes
const CHECK_STATUS_INTERVAL = 1 * 1000; // 1 seconds
export function useTriggerAndCheckPopulationExplorationStatus({
  populationParams,
  dataStatus,
  projectId,
}: Props): PeRequestState[] {
  const [requestsStatusQueue, setRequestsStatusQueue] = useState<
    PeRequestState[]
  >([]);
  const [nextRequest, setNextRequest] = useState<PeRequestState | undefined>(
    undefined
  );
  const [topStatus, setTopStatus] = useState<JobStatus | undefined>(undefined);
  const topRequestRef = useRef<PeRequestState | undefined>(undefined);
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  const dataStatusRef = useRef<DataStatus>(dataStatus);
  dataStatusRef.current = dataStatus;
  const isMounted = useMounted();

  const {
    environmentInfo: { clientStoragePrefixUrl },
  } = useEnvironmentInfo();

  const stopInterval = useMemo(() => {
    return (
      !nextRequest &&
      (requestsStatusQueue.length === 0 || isAllUpdated(requestsStatusQueue))
    );
  }, [nextRequest, requestsStatusQueue]);

  useAsyncInterval(
    async () => {
      let queue = requestsStatusQueue;

      if (nextRequest) {
        queue = [nextRequest, ...queue];
        setNextRequest(undefined);
        setRequestsStatusQueue([...queue]);
      }

      if (isAllUpdated(queue)) {
        return;
      }

      queue = await updateRequestsStatusQueue(
        queue,
        projectId,
        clientStoragePrefixUrl
      );

      setRequestsStatusQueue([...queue]);
    },
    stopInterval,
    CHECK_STATUS_INTERVAL
  );

  useEffect(() => {
    topRequestRef.current = first(requestsStatusQueue);
    setTopStatus(topRequestRef.current?.jobResult?.status);
  }, [requestsStatusQueue]);

  useEffect(() => {
    function update() {
      const topState = topRequestRef.current;

      const isNeedUpdate =
        isMounted.current &&
        dataStatusRef.current === DataStatus.Updating &&
        topState?.jobResult &&
        isEndStatus(topState.jobResult.status);

      if (!isNeedUpdate) {
        return;
      }

      if (timeoutIdRef.current !== undefined) {
        clearTimeout(timeoutIdRef.current);
      }

      const newState: PeRequestState = {
        ...topState,
        digest: createDigestOnPulling(
          topState.baseDigest,
          MIN_INTERVAL_BETWEEN_PULLING
        ),
      };

      if (newState.digest === topState.digest) {
        const previousRoundTime = Number(topState.digest.split('-')[1]);
        const timeToWait = calcTimeToWaitForUpdatingDigest(
          previousRoundTime,
          MIN_INTERVAL_BETWEEN_PULLING
        );
        timeoutIdRef.current = setTimeout(update, timeToWait);
        return;
      }

      setNextRequest((prev) => (prev ? prev : newState));
    }
    update();
  }, [dataStatus, topStatus, isMounted]);

  const enqueue = useCallback(() => {
    if (dataStatus === DataStatus.NoData) {
      return;
    }
    const baseDigest = calcPopulationExplorationDigest({ populationParams });
    const digest =
      dataStatus === DataStatus.Updating
        ? createDigestOnPulling(baseDigest, MIN_INTERVAL_BETWEEN_PULLING)
        : baseDigest;
    const requestState = {
      dataStatus,
      digest,
      baseDigest,
      populationParams,
      status: undefined,
    };
    const isSameDigestAndNotFailed =
      topRequestRef.current?.digest === digest &&
      topRequestRef.current?.jobResult?.status !== JobStatus.Failed;

    if (isSameDigestAndNotFailed) {
      return;
    }

    setNextRequest(requestState);
  }, [populationParams, dataStatus]);

  useEffect(() => {
    enqueue();
  }, [enqueue]);

  return requestsStatusQueue;
}

async function updateRequestsStatusQueue(
  queue: PeRequestState[],
  projectId: string,
  clientStoragePrefixUrl: string
): Promise<PeRequestState[]> {
  for (const i in queue) {
    const index = Number(i);
    const state = queue[index];
    if (state.jobResult?.status === JobStatus.Finished) {
      return queue.slice(0, index + 1);
    }
    if (state.jobResult?.status === JobStatus.Failed) {
      continue;
    }

    const params = {
      ...state.populationParams,
      projectId,
      digest: state.digest,
    };
    const isFirstTime = !state.jobResult;
    const jobResult = isFirstTime
      ? await api.populationExploration(params)
      : await api.getPopulationExplorationStatus(params);

    jobResult.readyArtifacts = mapUrl(
      jobResult.readyArtifacts,
      clientStoragePrefixUrl
    );

    state.jobResult = jobResult;
  }
  return queue;
}

function isAllUpdated(queue: PeRequestState[]): boolean {
  return queue.every(
    ({ jobResult: status }) => status?.status && isEndStatus(status.status)
  );
}

export function isEndStatus(status: JobStatus): boolean {
  return status !== JobStatus.Started && status !== JobStatus.Pending;
}

export function createDigestOnPulling(
  digest: string,
  pullingMinInterval: number
): string {
  const time = roundTimestamp(pullingMinInterval);
  return `${digest}-${time}`;
}

function mapUrl(
  readyArtifacts: PopulationExplorationResponse['readyArtifacts'],
  clientStoragePrefixUrl: string
): PopulationExplorationResponse['readyArtifacts'] {
  const result = { ...readyArtifacts };
  for (const [key, url] of Object.entries(readyArtifacts)) {
    if (!url) {
      continue;
    }
    result[
      key as keyof typeof readyArtifacts
    ] = `${clientStoragePrefixUrl}/${url}`;
  }
  return result;
}

function calcTimeToWaitForUpdatingDigest(
  previousRoundTime: number,
  pullingMinInterval: number
): number {
  const roundTime = roundTimestamp(pullingMinInterval);
  if (previousRoundTime !== roundTime) {
    return 0;
  }
  const now = Date.now();
  return pullingMinInterval - (now - roundTime);
}

function roundTimestamp(pullingMinInterval: number): number {
  const now = Date.now();
  const roundTime = Math.floor(now / pullingMinInterval) * pullingMinInterval;

  return roundTime;
}
