import { EpochData } from '@tensorleap/api-client';
import useSWR from 'swr';
import { useCurrentProject } from '../CurrentProjectContext';
import { REFRESH_INTERVALS } from './consts';
import api from '../api-client';

type UseFetchSessionEpochsReturn = {
  epochs: EpochData[] | undefined;
  projectId: string;
};

export function useFetchSessionsEpochs(
  sessionIds: string[]
): UseFetchSessionEpochsReturn {
  const { currentProjectId } = useCurrentProject();

  const { data } = useSWR(
    `${currentProjectId}/sessions/${sessionIds.join(',')}/epochs`,
    async () =>
      currentProjectId
        ? await api.getSessionsEpochs({
            projectId: currentProjectId,
            sessionIds,
          })
        : undefined,
    {
      refreshInterval: REFRESH_INTERVALS.sessionEpochs,
    }
  );

  return { epochs: data?.epochs, projectId: currentProjectId as string };
}
