import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgFolderOpenO = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M21.552 13.365c0-.244-.184-.365-.552-.365H9.667c-.278 0-.575.075-.891.224-.316.15-.564.332-.745.547L4.97 17.552c-.125.167-.188.306-.188.417 0 .243.184.364.552.364h11.334c.277 0 .576-.076.895-.229.32-.153.566-.337.74-.552l3.063-3.781c.125-.153.187-.288.187-.406ZM9.667 11.667h8V10a.965.965 0 0 0-.292-.708.964.964 0 0 0-.708-.292h-6a.964.964 0 0 1-.709-.292A.964.964 0 0 1 9.667 8v-.667a.964.964 0 0 0-.292-.708.964.964 0 0 0-.708-.292H5.333a.964.964 0 0 0-.708.292.964.964 0 0 0-.292.708v8.886L7 12.937c.306-.368.708-.671 1.208-.911s.986-.36 1.459-.36Zm13.218 1.698c0 .43-.16.847-.479 1.25l-3.073 3.78c-.298.369-.701.673-1.208.912-.507.24-.993.36-1.458.36H5.333c-.639 0-1.187-.23-1.646-.688A2.246 2.246 0 0 1 3 17.333v-10c0-.639.23-1.187.688-1.646A2.246 2.246 0 0 1 5.332 5h3.334c.639 0 1.187.23 1.646.688.458.458.687 1.006.687 1.645v.334h5.667c.639 0 1.187.229 1.645.687.459.459.688 1.007.688 1.646v1.667h2c.375 0 .719.085 1.031.255.313.17.545.415.698.734.104.223.156.459.156.709Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFolderOpenO;
