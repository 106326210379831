import { useCallback, useMemo } from 'react';
import { UseEpochVisualizationState } from '../dashlet/common/useEpochVisualization';
import {
  NoVisualizationsForEpochPlaceholder,
  VisualizationRunningPlaceholder,
} from '../dashlet/common/Placeholders';
import { EpochVisualizationSelector } from '../dashlet/common/EpochVisualizationSelector';
import { PreviewContainer } from '../dashlet/common/PreviewsContainer';
import { SampleAnalysisAlgo } from '@tensorleap/api-client';
import { SelectedSessionRun } from '../../ui/molecules/useModelFilter';
import {
  isZoomSupported,
  VisAssetWithIdentity,
  VisDataTypes,
} from '../dashlet/VisualizationDisplay/visDataHelpers';
import { BaseContainerWrapperProps } from '../dashlet/VisualizationDisplay/types';
import { Setter } from '../../core/types';
import { ZoomButtons } from '../../ui/molecules/ZoomButtons';

export interface SampleAnalysisPreviewContainerProps
  extends BaseContainerWrapperProps {
  sessionRun: SelectedSessionRun;
  selectedVisElement?: VisAssetWithIdentity;
  epochVisualizationState: UseEpochVisualizationState;
  selectedAlgo: SampleAnalysisAlgo;
  setSelectedAlgo: Setter<SampleAnalysisAlgo>;
  upgradeAnalyze: (epoch: number) => Promise<void>;
  children: JSX.Element;
}

export function SampleAnalysisPreviewContainer({
  sessionRun,
  selectedVisElement,
  epochVisualizationState,
  selectedAlgo,
  type,
  setSelectedAlgo,
  upgradeAnalyze,
  children,
}: SampleAnalysisPreviewContainerProps): JSX.Element {
  const isUpgrading = useMemo(
    () =>
      !selectedVisElement && epochVisualizationState.isSelectedEpochUpgrading,
    [epochVisualizationState.isSelectedEpochUpgrading, selectedVisElement]
  );

  const onUpgrade = useCallback(() => {
    upgradeAnalyze(epochVisualizationState.selectedEpoch.epoch);
  }, [epochVisualizationState.selectedEpoch.epoch, upgradeAnalyze]);

  const header = useMemo(() => {
    return (
      <div className="flex flex-row gap-2">
        {isZoomSupported(selectedVisElement?.data?.data as VisDataTypes) && (
          <ZoomButtons
            className={type === 'sampleAnalysis' ? 'pt-[10px]' : ''}
          />
        )}
        {type === 'sampleAnalysis' && (
          <EpochVisualizationSelector {...epochVisualizationState} />
        )}
      </div>
    );
  }, [epochVisualizationState, selectedVisElement?.data?.data, type]);

  return (
    <PreviewContainer flex sessionRun={sessionRun} header={header}>
      {isUpgrading ? (
        <VisualizationRunningPlaceholder processName="analyzing..." />
      ) : !selectedVisElement ? (
        <NoVisualizationsForEpochPlaceholder
          resourceName="analysis"
          actionName="analyze"
          onUpgrade={onUpgrade}
          selectedAlgo={selectedAlgo}
          setSelectedAlgo={setSelectedAlgo}
        />
      ) : (
        children
      )}
    </PreviewContainer>
  );
}
