import { LinearProgress, Tooltip } from '@material-ui/core';
import { MouseEventHandler, PropsWithChildren, forwardRef } from 'react';
import { Button, Variant } from './Button';
import { ViIcon, XClose } from '../icons';
import clsx from 'clsx';

export enum ProcessButtonState {
  Passed = 'PASSED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Unknown = 'UNKNOWN',
  Unstarted = 'UNSTARTED',
}

const stateToButtonVariant: {
  [key in ProcessButtonState]: Variant;
} = {
  [ProcessButtonState.Passed]: 'inverted-green',
  [ProcessButtonState.Failed]: 'inverted-red',
  [ProcessButtonState.Unstarted]: 'inverted-blue',
  [ProcessButtonState.Processing]: 'inverted-blue',
  [ProcessButtonState.Unknown]: 'inverted-orange',
};
const stateToButtonVariantFilled: {
  [key in ProcessButtonState]: Variant;
} = {
  [ProcessButtonState.Passed]: 'inverted-filled-green',
  [ProcessButtonState.Failed]: 'inverted-filled-red',
  [ProcessButtonState.Unstarted]: 'inverted-filled-blue',
  [ProcessButtonState.Processing]: 'inverted-filled-blue',
  [ProcessButtonState.Unknown]: 'inverted-filled-orange',
};

const stateToButtonIcon: {
  [key in ProcessButtonState]: JSX.Element;
} = {
  [ProcessButtonState.Passed]: <ViIcon className="w-5 h-5" />,
  [ProcessButtonState.Failed]: <XClose className="w-5 h-5" />,
  [ProcessButtonState.Unstarted]: <></>,
  [ProcessButtonState.Processing]: <></>,
  [ProcessButtonState.Unknown]: <></>,
};

export type ProcessButtonProps = PropsWithChildren<{
  onClick: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  buttonTitle?: string;
  processState: ProcessButtonState;
  tooltipTitle?: string;
  filledVariant?: boolean;
  className?: string;
  tourId?: string;
}>;

export const ProcessButton = forwardRef<HTMLButtonElement, ProcessButtonProps>(
  (
    {
      onClick,
      disabled = false,
      buttonTitle,
      processState,
      tooltipTitle = '',
      filledVariant = false,
      className,
      tourId,
      children,
    },
    ref
  ) => {
    const button = (
      <Button
        ref={ref}
        onClick={onClick}
        tourId={tourId}
        variant={
          filledVariant
            ? stateToButtonVariantFilled[processState]
            : stateToButtonVariant[processState]
        }
        className={clsx(
          'relative flex items-center overflow-hidden',
          className
        )}
        disabled={disabled}
      >
        {children ? (
          children
        ) : (
          <div className="flex flex-row gap-2">
            <p>{buttonTitle}</p>
            {stateToButtonIcon[processState]}
          </div>
        )}
        {processState === ProcessButtonState.Processing && (
          <div className="absolute bottom-0 inset-x-0">
            <LinearProgress />
          </div>
        )}
      </Button>
    );

    return tooltipTitle ? (
      <Tooltip title={tooltipTitle}>
        <span>{button}</span>
      </Tooltip>
    ) : (
      button
    );
  }
);

ProcessButton.displayName = 'ProcessButton';
