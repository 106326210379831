import { useCallback, useMemo } from 'react';
import api from '../core/api-client';
import { UseFetchTeams } from '../core/data-fetching/teams';
import { PlusMini } from '../ui/icons';
import { useStateObject } from '../core/types';
import { CreateNewTeamDialog } from './CreateNewTeamDialog';
import clsx from 'clsx';
import { MachineTypeSelector } from './MachineTypeSelector';
import { SlimTeam } from '@tensorleap/api-client';
import { MenuAction } from '../ui/model-list/table/TableRowActions';
import { ModelFields } from '../ui/model-list/types';
import { Table } from '../ui/model-list/table/Table';
import { ChartLoading } from '../ui/charts/common/ChartLoading';
import { ActionButton } from '../ui/molecules/ActionButton';
import { EditableInputCell } from '../ui/atoms/EditableInputCell';

export interface TeamManagementProps {
  className?: string;
}

export function TeamManagement({
  className,
}: TeamManagementProps): JSX.Element {
  const { teams: allTeams, isLoading, refetch: refetchTeams } = UseFetchTeams();

  const createNewTeamDialogState = useStateObject(false);

  const setAsDefaultTeam = useCallback(
    async (cid: string) => {
      await api.setDefaultTeam({
        cid,
      });
      await refetchTeams();
    },
    [refetchTeams]
  );

  const menuActions = useMemo<MenuAction<SlimTeam>[]>(
    () => [
      {
        title: 'Set as Default Team',
        onSelect: ({ cid }) => setAsDefaultTeam(cid),
      },
    ],
    [setAsDefaultTeam]
  );

  const updateTeamPublicName = useCallback(
    async (cid: string, publicName: string) => {
      await api.updateTeamPublicName({
        cid,
        publicName,
      });
      await refetchTeams();
    },
    [refetchTeams]
  );

  const teamsColumns = useMemo<ModelFields<SlimTeam>>(
    () => [
      {
        accessorKey: 'publicName',
        label: 'TEAM NAME',
        format: (publicName, { cid }) => (
          <EditableInputCell
            value={publicName}
            required
            onChange={(newValue: string) => updateTeamPublicName(cid, newValue)}
          />
        ),
      },
      {
        accessorKey: 'machineTypeId',
        label: 'HARDWARE ACCELERATOR',
        format: (_, team) => <MachineTypeSelector team={team} />,
      },
    ],
    [updateTeamPublicName]
  );

  return (
    <div
      className={clsx(
        'flex gap-2 flex-col w-full h-full overflow-hidden',
        className
      )}
    >
      <CreateNewTeamDialog openState={createNewTeamDialogState} />
      <div className="flex w-full justify-start items-center">
        <span className="uppercase text-xl">Teams</span>
        <ActionButton
          className="ml-auto"
          icon={<PlusMini />}
          onRun={() => createNewTeamDialogState.setState(true)}
        >
          Add new team
        </ActionButton>
      </div>

      <Table
        noData={isLoading ? <ChartLoading /> : undefined}
        fields={teamsColumns}
        data={allTeams}
        menuActions={menuActions}
      />
    </div>
  );
}
