import { useMemo, useCallback } from 'react';
import { DatasetMask, parseDatasetVersion } from './dataset-helper';
import { DetailsProps } from './NodeDetails';
import { NodeDetailsPanel, NoPreview } from './NodeDetalisPanel';
import { Settings, Visible } from '../ui/icons';
import { Input } from '../ui/atoms/Input';
import { VisualizerInstance } from '@tensorleap/api-client';
import { Select } from '../ui/atoms/Select';
import { UserUniqueName, USER_UNIQUE_NAME } from './UserUniqueName';
import { useNetworkMapContext } from '../core/NetworkMapContext';

export function VisualizerDetails({ node }: DetailsProps): JSX.Element {
  const {
    changeNodeProperty,
    updateConnection,
    datasetSetup,
  } = useNetworkMapContext();

  const visualizerName = node.data.visualizer_name || '';
  const visualizersNames = datasetSetup?.visualizers || [];

  const visualizerDetails = useMemo(
    () =>
      parseDatasetVersion(
        DatasetMask.Visualizers,
        datasetSetup
      ).find(({ title }) => title.endsWith(visualizerName)),
    [datasetSetup, visualizerName]
  );

  const handleVisualizerChange = useCallback(
    (name: string | undefined, customLossItem: VisualizerInstance | null) => {
      const { arg_names = [], type } = customLossItem || {};

      changeNodeProperty({
        nodeId: node.id,
        nodeDataPropsToUpdate: {
          name,
          visualizer_name: name,
          visualizer_type: type,
          arg_names,
          [USER_UNIQUE_NAME]: name,
        },
      });

      updateConnection(node.id, arg_names);
    },
    [changeNodeProperty, node.id, updateConnection]
  );

  return (
    <div className="flex flex-col overflow-auto">
      <NodeDetailsPanel
        title="Properties"
        className="p-4 gap-8"
        icon={<Settings />}
        openByDefault
      >
        <Select
          label="SELECTED VISUALIZER"
          optionID="name"
          options={visualizersNames}
          onChange={handleVisualizerChange}
          value={visualizerName}
        />
        <UserUniqueName node={node} />
      </NodeDetailsPanel>

      <NodeDetailsPanel
        title="Preview"
        className="p-4 gap-8"
        icon={<Visible />}
        openByDefault
      >
        {visualizerDetails ? (
          visualizerDetails.subItems.map(({ title, value }) => (
            <Input key={title} label={title} value={value} readOnly />
          ))
        ) : (
          <NoPreview />
        )}
      </NodeDetailsPanel>
    </div>
  );
}
