import { SelectedSessionRun } from '../ui/molecules/useModelFilter';
import { InsightCard } from './InsightCard';
import { useCallback, useEffect, useMemo } from 'react';
import {
  InsightSubCategoryPerformance,
  InsightType,
} from '@tensorleap/api-client';
import { InsightSummary } from './InsightSummary';
import { Collapse, Divider } from '@material-ui/core';
import {
  extractDigest,
  INSIGHT_CATEGORIES,
  InsightCategoryType,
  InsightSubCategoryType,
} from './utils';
import { useOpenState } from '../ui/atoms/utils/useOpenState';
import { ArchiveIcon, CsvIcon, Epoch } from '../ui/icons';
import clsx from 'clsx';
import {
  InsightsBySessionRunAndEpoch,
  InsightsDigestedResponse,
  useInsightsContext,
} from './InsightsContext';
import { FetchJsonResponse } from '../core/data-fetching/fetch-json-multiple';
import {
  LoadingStatus,
  calcLoadingStatus,
  isAnyKindOfLoading,
} from '../core/data-fetching/loading-status';
import { first } from 'lodash';
import { Button } from '../ui/atoms/Button';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';
import { UpOrDownArrow } from '../ui/atoms/UpOrDownArrow';
import { CircularProgressBar } from './InsightCircularProgressBar';
import { ModelChip } from '../ui/molecules/ModelChip';
import { LoadingDot } from '../ui/LoadingDot';
import { useLocalStorage } from '../core/useLocalStorage';

type SessionRunAndEpochInsightsProps = InsightsBySessionRunAndEpoch & {
  showBottomBorder?: boolean;
};

const EMPTY_TEST_RESULT: Record<InsightSubCategoryType, number> = {
  under_presentation: 0,
  over_presentation: 0,
  data_leakage: 0,
  failing_clusters: 0,
  over_fit: 0,
};

const FILTER_INSIGHTS_RESULT: Record<InsightSubCategoryType, boolean> = {
  under_presentation: false,
  over_presentation: false,
  data_leakage: false,
  failing_clusters: false,
  over_fit: false,
};

export function SessionRunAndEpochInsights({
  selectedSessionRun,
  epoch,
  data,
  showBottomBorder,
}: SessionRunAndEpochInsightsProps) {
  const { fetchedInsights } = useInsightsContext();

  const [insightsFilters, setInsightsFilters] = useLocalStorage(
    `insightsFilters-${selectedSessionRun.id}-${epoch}`,
    FILTER_INSIGHTS_RESULT
  );

  const loadingStatus = useMemo<LoadingStatus>(() => {
    const urls = data.map(({ url }) => url);
    const isLoading = fetchedInsights
      .filter(({ url: key }) => urls.includes(key))
      .some(({ isLoading, error }) => isLoading || error !== undefined);

    if (isLoading) {
      return 'loading';
    }
    return calcLoadingStatus(data.map(({ loadingStatus }) => loadingStatus));
  }, [data, fetchedInsights]);

  const groupedInsights = useMemo((): Record<
    string,
    FetchJsonResponse<InsightsDigestedResponse>
  > => {
    return fetchedInsights.reduce((acc, insight) => {
      acc[insight.url] = insight;
      return acc;
    }, {} as Record<string, FetchJsonResponse<InsightsDigestedResponse>>);
  }, [fetchedInsights]);

  const insightSummaryData = useMemo(() => {
    const [insightsPerDigest] = data;
    if (!insightsPerDigest) return { ...EMPTY_TEST_RESULT };
    const insightsByUrl = groupedInsights[insightsPerDigest?.url || ''] || [];

    return (insightsByUrl.data?.insights || []).reduce(
      (acc, insight) => {
        const {
          insight_sub_category_ds_curation,
          insight_category_performance,
        } = insight;

        if (insight_sub_category_ds_curation)
          acc[insight_sub_category_ds_curation]++;

        if (insight_category_performance) acc[insight_category_performance]++;

        return acc;
      },
      { ...EMPTY_TEST_RESULT }
    );
  }, [data, groupedInsights]);

  const [selectedTab, setSelectedTab] = useLocalStorage<InsightCategoryType>(
    `insightTab-${selectedSessionRun.id}-${epoch}`,
    'ds curation'
  );

  const handleTabSelected = useCallback(
    (tab: InsightCategoryType) => {
      setSelectedTab(tab);
      setInsightsFilters(FILTER_INSIGHTS_RESULT);
    },
    [setInsightsFilters, setSelectedTab]
  );

  const dashboardsSummaryData: CircularProgressBar[] = useMemo(() => {
    const [insightsPerDigest] = fetchedInsights;
    const insightsInfo = insightsPerDigest?.data?.performance_info;
    if (!insightsInfo) return [];

    if (selectedTab === 'ds curation') {
      return [
        {
          value:
            insightsInfo.n_samples_to_add_from_unlabeled_by_score[
              insightsInfo.unlabeled_samples_to_add_default_score
            ],
          totalScale: insightsInfo.n_total_unlabeled_samples,
          subtitle: 'Samples to Label',
          textColor: '#22C55E',
        },
        {
          value:
            insightsInfo.n_samples_to_remove_by_score[
              insightsInfo.samples_to_remove_default_score
            ],
          totalScale: insightsInfo.n_total_non_unlabeled_samples,
          subtitle: 'Samples to Prune',
          textColor: '#F97316',
        },
        {
          value: insightsInfo.n_samples_involved_in_leakage,
          totalScale: insightsInfo.n_total_samples,
          subtitle: 'Leaked Samples',
          textColor: '#7C3AED',
        },
      ];
    }

    return [
      {
        value: insightSummaryData[InsightSubCategoryPerformance.OverFit],
        totalScale: insightsInfo.n_total_clusters_found,
        subtitle: `Overfitted Clusters`,
        textColor: '#EF4444',
      },
      {
        value:
          insightSummaryData[InsightSubCategoryPerformance.FailingClusters],
        totalScale: insightsInfo.n_total_clusters_found,
        subtitle: `Low Performing Clusters`,
        textColor: '#67E8F9',
      },
    ];
  }, [fetchedInsights, insightSummaryData, selectedTab]);

  const handleToggleInsightsFilters = useCallback(
    (insightSubCategoryType: InsightSubCategoryType) => {
      setInsightsFilters((prev) => {
        if (prev[insightSubCategoryType]) {
          return {
            ...prev,
            [insightSubCategoryType]: false,
          };
        }
        return Object.keys(prev).reduce((acc, key) => {
          if (key === insightSubCategoryType) {
            return { ...acc, [key]: false };
          }
          return { ...acc, [key]: true };
        }, {} as Record<InsightSubCategoryType, boolean>);
      });
    },
    [setInsightsFilters]
  );

  const csvBlobUrl = first(data)?.csvUrl;

  const downloadCsv = useCallback(() => {
    window.open(csvBlobUrl, '_blank');
  }, [csvBlobUrl]);

  const insightsToDisplay = useCallback(
    (insights: InsightType[]) => {
      return insights.filter((insight) => {
        if (
          selectedTab === 'ds curation' &&
          insight.insight_sub_category_ds_curation
        )
          return !insightsFilters[insight.insight_sub_category_ds_curation];

        if (
          selectedTab === 'performance' &&
          insight.insight_category_performance
        )
          return !insightsFilters[insight.insight_category_performance];

        return false;
      });
    },
    [insightsFilters, selectedTab]
  );

  const hasDashboards = dashboardsSummaryData.length > 0;

  const { isOpen, toggle } = useOpenState(true);

  return (
    <div
      className={clsx(
        'flex flex-col w-full gap-2',
        showBottomBorder && 'border-b-2 border-gray-700'
      )}
    >
      <div className="flex justify-between items-center">
        <div className="flex flex-row justify-start items-center gap-2">
          <ModelChip {...selectedSessionRun} />
          {loadingStatus === 'updating' && <LoadingDot />}
        </div>

        <div className="flex gap-2 text-gray-300 items-center justify-end">
          <Epoch /> Epoch:
          <span className="font-bold text-white">{epoch} </span>
          {hasDashboards && (
            <div className="py-2 flex w-5">
              <UpOrDownArrow isOpen={isOpen} onClick={toggle} />
            </div>
          )}
        </div>
      </div>

      <Collapse in={isOpen}>
        <Divider orientation="horizontal" />
        <InsightCategoriesTabs
          selectedTab={selectedTab}
          onTabSelected={handleTabSelected}
        />
        <Divider orientation="horizontal" className="mb-1" />

        <InsightSummary
          summary={insightSummaryData}
          toggleInsightsFilters={handleToggleInsightsFilters}
          insightsFilters={insightsFilters}
          loadingStatus={loadingStatus}
          insightCategoryType={selectedTab}
          dashboardsSummaryData={dashboardsSummaryData}
        />

        <Divider orientation="horizontal" className="my-1" />

        {selectedTab === 'ds curation' && (
          <>
            <Button
              className={clsx(
                'w-full h-9 !bg-gray-850',
                csvBlobUrl
                  ? 'text-gray-300 hover:text-gray-200 active:text-white cursor-pointer'
                  : 'text-gray-700'
              )}
              variant="inverted-gray"
              disabled={!csvBlobUrl}
              onClick={downloadCsv}
            >
              <div className="flex flex-row items-center">
                <span className="text-gray-400 uppercase mr-4">
                  download full report
                </span>

                <CsvIcon />
              </div>
            </Button>
            <Divider orientation="horizontal" className="my-2" />
          </>
        )}

        <div className="flex flex-col gap-3 pb-2">
          {data.map(({ url }, i) => {
            const fetchState = url ? groupedInsights[url] : undefined;

            const data = fetchState?.data;
            const isNoData = !data || data?.insights.length === 0;

            if (isNoData || !url) {
              const isLoading =
                isAnyKindOfLoading(loadingStatus) || fetchState?.isLoading;

              return (
                <div
                  key={url ?? String(i)}
                  className="flex justify-center items-center h-14 w-full bg-gray-850 rounded-l border border-gray-800 my-2"
                >
                  {isLoading ? 'Loading...' : 'No insights found'}
                </div>
              );
            }
            const popExpDigest = extractDigest(url);
            if (!popExpDigest) {
              console.error('Could not extract digest from url', url);
              return null;
            }

            return (
              <DisplayInsights
                key={url}
                selectedSessionRun={selectedSessionRun}
                epoch={epoch}
                insights={insightsToDisplay(data.insights)}
                scatterVisualizationGuid={data.scatter_visualization_guid}
                loadingStatus={loadingStatus}
                insightCategoryType={selectedTab}
                popExpDigest={popExpDigest}
              />
            );
          })}
        </div>
      </Collapse>
    </div>
  );
}

type DisplayInsightsProps = {
  selectedSessionRun: SelectedSessionRun;
  epoch: number;
  insights: InsightType[];
  scatterVisualizationGuid: string;
  loadingStatus: LoadingStatus;
  insightCategoryType: InsightCategoryType;
  popExpDigest: string;
};

function DisplayInsights({
  selectedSessionRun,
  epoch,
  insights,
  scatterVisualizationGuid,
  loadingStatus,
  insightCategoryType,
  popExpDigest,
}: DisplayInsightsProps) {
  const { archivedInsights = {} } = useInsightsContext();

  const { archivedInsightsList, unarchiveInsightsList } = useMemo(() => {
    const archivedInsightsSet = new Set(archivedInsights[popExpDigest]);
    const archivedInsightsList = insights.filter((insight) => {
      return (
        insight.index !== undefined && archivedInsightsSet.has(insight.index)
      );
    });
    const unarchiveInsightsList = insights.filter((insight) => {
      return (
        insight.index === undefined || !archivedInsightsSet.has(insight.index)
      );
    });
    return { archivedInsightsList, unarchiveInsightsList };
  }, [archivedInsights, insights, popExpDigest]);

  const { isOpen: showArchivedOpen, setIsOpen: setShowArchived } = useOpenState(
    false
  );
  useEffect(() => {
    if (archivedInsightsList.length === 0) setShowArchived(false);
  }, [archivedInsightsList, setShowArchived]);
  useEffect(() => {
    setShowArchived(false);
  }, [insightCategoryType, setShowArchived]);

  return (
    <div className="flex flex-col gap-3">
      {unarchiveInsightsList.map((insight, i) => (
        <InsightCard
          key={i}
          tourId={TOUR_SELECTORS_ENUM.INSIGHT_CARD_ID}
          headerTourId={TOUR_SELECTORS_ENUM.INSIGHT_HEADER_ID}
          displayButtonTourId={TOUR_SELECTORS_ENUM.INSIGHT_DISPLAY_BUTTON_ID}
          filterButtonTourId={TOUR_SELECTORS_ENUM.INSIGHT_FILTER_BUTTON_ID}
          {...{
            modelId: selectedSessionRun.id,
            visualizationUUID: scatterVisualizationGuid,
            insightData: insight,
            isArchived: false,
            selectedSessionRun: selectedSessionRun,
            clusterUrl: insight.blob_path,
            epoch,
            isWaitingForUpdate: loadingStatus !== 'ready',
            insightSubType:
              insightCategoryType === 'ds curation'
                ? insight.insight_sub_category_ds_curation
                : insight.insight_category_performance,
          }}
        />
      ))}
      {archivedInsightsList.length > 0 && (
        <div className="flex flex-col gap-3">
          <UpOrDownArrow
            isOpen={showArchivedOpen}
            onClick={() => setShowArchived((p) => !p)}
            text={`ARCHIVED RESULTS (${archivedInsightsList.length})`}
            icon={<ArchiveIcon />}
            className="h-6"
          />

          <Collapse in={showArchivedOpen}>
            <div className="flex flex-col gap-3">
              {archivedInsightsList.map((insight, i) => (
                <InsightCard
                  key={i}
                  tourId={TOUR_SELECTORS_ENUM.INSIGHT_CARD_ID}
                  headerTourId={TOUR_SELECTORS_ENUM.INSIGHT_HEADER_ID}
                  displayButtonTourId={
                    TOUR_SELECTORS_ENUM.INSIGHT_DISPLAY_BUTTON_ID
                  }
                  filterButtonTourId={
                    TOUR_SELECTORS_ENUM.INSIGHT_FILTER_BUTTON_ID
                  }
                  {...{
                    modelId: selectedSessionRun.id,
                    visualizationUUID: scatterVisualizationGuid,
                    insightData: insight,
                    isArchived: true,
                    selectedSessionRun: selectedSessionRun,
                    clusterUrl: insight.blob_path,
                    epoch,
                    isWaitingForUpdate: loadingStatus !== 'ready',
                    insightSubType:
                      insightCategoryType === 'ds curation'
                        ? insight.insight_sub_category_ds_curation
                        : insight.insight_category_performance,
                  }}
                />
              ))}
            </div>
          </Collapse>
        </div>
      )}
    </div>
  );
}

type InsightCategoriesTabsProps = {
  selectedTab: InsightCategoryType;
  onTabSelected: (tab: InsightCategoryType) => void;
};

const InsightCategoriesTabs: React.FC<InsightCategoriesTabsProps> = ({
  selectedTab,
  onTabSelected,
}) => {
  return (
    <div
      className="flex justify-between items-center gap-2 mb-2 uppercase"
      id={TOUR_SELECTORS_ENUM.INSIGHTS_CATAGORIES_TABS_ID}
    >
      {INSIGHT_CATEGORIES.map((title) => (
        <div
          key={title}
          onClick={() => onTabSelected(title as InsightCategoryType)}
          className="cursor-pointer flex-1 text-center"
        >
          <span
            className={clsx(
              'inline-block px-4 pt-2 text-gray-400',
              selectedTab === title
                ? 'border-b-2 border-primary-500 font-bold text-white'
                : 'border-b-2 border-transparent'
            )}
          >
            {title}
          </span>
        </div>
      ))}
    </div>
  );
};
