import clsx from 'clsx';
import { PropsWithChildren, useMemo } from 'react';

export function InputNote({
  children,
  info,
}: PropsWithChildren<{ error?: boolean; info?: boolean }>) {
  const textColorClass = useMemo(() => {
    if (info) {
      return 'text-gray-500';
    } else {
      return 'text-error-500';
    }
  }, [info]);
  return (
    <>
      {children && (
        <span className={clsx('block mt-1 text-xs', textColorClass)}>
          {children}
        </span>
      )}
    </>
  );
}
