import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgTagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M5.643 5.66A.774.774 0 0 0 6.9 5.412a.761.761 0 0 0-.167-.833.774.774 0 0 0-1.09 0c-.3.299-.3.783 0 1.081Zm8.82 4.5a1.991 1.991 0 0 0 0-2.83L9.704 2.6a2.02 2.02 0 0 0-1.414-.586L5.025 2a2.007 2.007 0 0 0-2.021 2.015l.024 3.373c.004.526.216 1.03.59 1.4l4.69 4.66a2.022 2.022 0 0 0 2.846 0l3.31-3.288Zm-.711-2.123a.998.998 0 0 1 0 1.415l-3.31 3.29c-.393.39-1.03.39-1.423 0l-4.69-4.661a.997.997 0 0 1-.294-.7L4.01 4.008A1.003 1.003 0 0 1 5.02 3l3.265.014c.265 0 .52.106.708.293l4.76 4.73h-.001Zm1.25 2.713a2 2 0 0 1-.46 2.115l-1.964 1.964a3.998 3.998 0 0 1-5.657 0l-3.482-3.482a1.5 1.5 0 0 1-.44-1.06v-.78l3.922 3.922c.069.069.14.135.212.197l.495.496a2.999 2.999 0 0 0 4.243 0l.499-.499c.072-.062.14-.127.208-.194l1.964-1.964c.203-.203.36-.446.46-.715Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTagIcon;
