import { CSSProperties, FC } from 'react';
import { createMuiTheme, MuiThemeProvider, StylesProvider } from './mui';

import '@fontsource/nunito-sans/400.css';
import '@fontsource/nunito-sans/600.css';
import '@fontsource/nunito-sans/700.css';
import '@fontsource/nunito-sans/900.css';
import '@fontsource/dejavu-mono/400.css';

import './index.css';

export type FontCssProps = Pick<
  CSSProperties,
  'fontWeight' | 'fontSize' | 'lineHeight' | 'letterSpacing' | 'textTransform'
>;

export const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: { main: '#007DFF' },
    versions: { main: '#FF505F', dark: '#B23842' },
    dashboard: { main: '#007DFF', dark: '#0057B2' },
    map: { main: '#7373FF', dark: '#5050B2' },
    creation: '#084A24',
    mainPanes: {
      border: '1px solid #424242',
      network: {
        background: '#05001D',
        boxShadow: '-4px 0px 4px rgba(0, 0, 0, 0.25)',
      },
      dashboard: {
        boxShadow: '-4px 0px 8px rgba(0, 0, 0, 0.15)',
      },
    },
    trainDialog: {
      sourceNetwork: '#001E35',
      sourceModel: '#0D3C61',
      newModelName: '#005503',
      enabledFromEpoch: '#005503',
      disabledFromEpoch: '#0D3C61',
      toolBar: '#FF4D00',
      hover: '#2B3842',
    },
    networkEditor: {
      stroke: '#64B6F7',
      socket: '#AFE3FF',
      boxShadow: 'rgba(0, 0, 0, 0.12)',
      layer: {
        main: '#64B6F7',
        title: '#83C5F9',
        hover: {
          main: '#83C5F9',
          title: '#AFE3FF',
        },
      },
      loss: {
        main: '#E31B0C',
        title: '#F44336',
        hover: {
          main: '#F44336',
          title: '#F88078',
        },
      },
      customLoss: {
        main: '#E31B0C',
        title: '#F44336',
        hover: {
          main: '#F44336',
          title: '#F88078',
        },
      },
      trainer: {
        main: '#2196F3',
        title: '#0D447C',
        hover: {
          main: '#0057B2',
          title: '#0057B2',
        },
      },
      prediction: {
        main: '#14B8A6',
        title: '#2DD4BF',
        hover: {
          main: '#14B8A6',
          title: '#2DD4BF',
        },
      },
      visualizer: {
        main: '#2196F3',
        title: '#0D447C',
        hover: {
          main: '#0057B2',
          title: '#0057B2',
        },
      },
      metric: {
        main: '#2196F3',
        title: '#0D447C',
        hover: {
          main: '#0057B2',
          title: '#0057B2',
        },
      },
      dataset: {
        main: '#D67F00',
        title: '#FF9800',
        hover: {
          main: '#FF9800',
          title: '#FFB400',
        },
      },
      optimizer: {
        main: '#008F98',
        title: '#00CDDA',
        hover: {
          main: '#00CDDA',
          title: '#2BF2FF',
        },
      },
      groundTruth: {
        main: '#D67F00',
        title: '#FF9800',
        hover: {
          main: '#FF9800',
          title: '#FFB400',
        },
      },
      input: {
        main: '#D67F00',
        title: '#FF9800',
        hover: {
          main: '#FF9800',
          title: '#FFB400',
        },
      },
    },
    assets: {
      main: '#FF9800',
      title: '#FF9800',
      success: '#2A9041',
    },
    runningDialog: {
      titleBorder: '#3CCE5E',
    },
    lineGraph: ['#fad390', '#e55039', '#6a89cc', '#b8e994', '#82ccdd'],
  },
  typography: {
    fontFamily: ['Nunito Sans', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontWeightMedium: 400,
    inputLabel: {
      fontWeight: 900,
      fontSize: 12,
      lineHeight: 'auto',
      letterSpacing: 0.5,
    },
    helperText: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '18px',
      letterSpacing: 0.4,
    },
    inputText: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '19px',
      letterSpacing: 0.15,
    },
    avatarLetter: {
      fontWeight: 500,
      fontSize: 8,
      lineHeight: 'auto',
      letterSpacing: 0,
    },
    chip: {
      fontWeight: 'bold',
      fontSize: 10,
      lineHeight: '18px',
      letterSpacing: 5,
      textTransform: 'uppercase',
    },
    tooltip: {
      fontWeight: 500,
      fontSize: 11.5,
      lineHeight: '14px',
      letterSpacing: 0,
    },
    alertTitle: {
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: '1.5em',
      letterSpacing: 0.15,
    },
    tableHeader: {
      fontWeight: 'bold',
      fontSize: 10,
      lineHeight: 'auto',
      letterSpacing: 0.1,
      textTransform: 'uppercase',
    },
    buttonLargeLight: {
      fontSize: 15,
      lineHeight: '26px',
      letterSpacing: '0.25em',
      textTransform: 'uppercase',
    },
    buttonLarge: {
      fontWeight: 900,
      fontSize: 15,
      lineHeight: '26px',
      letterSpacing: '0.2em',
      textTransform: 'uppercase',
    },
    buttonMedium: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: 24,
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
    },
    buttonSmall: {
      fontWeight: 'bold',
      fontSize: 13,
      lineHeight: '22px',
      letterSpacing: 0.5,
      textTransform: 'uppercase',
    },
    megaTooltipHeader: {
      fontWeight: 900,
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0,
    },
    megaTooltip: {
      fontSize: 14,
      lineHeight: '18px',
      letterSpacing: 0,
    },
    code: { fontSize: 12, lineHeight: '1.5em' },
  },
});

declare module '@material-ui/core/styles/createPalette' {
  export interface Palette {
    versions: Palette['primary'];
    dashboard: Palette['primary'];
    map: Palette['primary'];
    networkEditor: NetworkMapTheme;
    trainDialog: TrainDialog;
    creation: string;
    mainPanes: MainPanes;
    assets: NodeTheme & { success: string };
    runningDialog: { titleBorder: string };
    lineGraph: string[];
  }
  export interface PaletteOptions {
    versions: PaletteOptions['primary'];
    dashboard: PaletteOptions['primary'];
    map: PaletteOptions['primary'];
    networkEditor: NetworkMapTheme;
    trainDialog: TrainDialog;
    creation: string;
    mainPanes: MainPanes;
    assets: NodeTheme & { success: string };
    runningDialog: { titleBorder: string };
    lineGraph: string[];
  }
}
declare module '@material-ui/core/styles/createTypography' {
  export interface Typography {
    inputLabel: FontCssProps;
    helperText: FontCssProps;
    inputText: FontCssProps;
    avatarLetter: FontCssProps;
    chip: FontCssProps;
    tooltip: FontCssProps;
    alertTitle: FontCssProps;
    tableHeader: FontCssProps;
    buttonLargeLight: FontCssProps;
    buttonLarge: FontCssProps;
    buttonMedium: FontCssProps;
    buttonSmall: FontCssProps;
    megaTooltipHeader: FontCssProps;
    megaTooltip: FontCssProps;
    code: FontCssProps;
  }
  export interface TypographyOptions {
    inputLabel: FontCssProps;
    helperText: FontCssProps;
    inputText: FontCssProps;
    avatarLetter: FontCssProps;
    chip: FontCssProps;
    tooltip: FontCssProps;
    alertTitle: FontCssProps;
    tableHeader: FontCssProps;
    buttonLargeLight: FontCssProps;
    buttonLarge: FontCssProps;
    buttonMedium: FontCssProps;
    buttonSmall: FontCssProps;
    megaTooltipHeader: FontCssProps;
    megaTooltip: FontCssProps;
    code: FontCssProps;
  }
}

interface NodeTheme {
  main: string;
  title: string;
}

interface NodeOptions extends NodeTheme {
  hover: NodeTheme;
}

interface NetworkMapTheme {
  boxShadow: string;
  stroke: string;
  socket: string;
  loss: NodeOptions;
  layer: NodeOptions;
  trainer: NodeOptions;
  optimizer: NodeOptions;
  dataset: NodeOptions;
  groundTruth: NodeOptions;
  input: NodeOptions;
  prediction: NodeOptions;
  visualizer: NodeOptions;
  metric: NodeOptions;
  customLoss: NodeOptions;
}

interface TrainDialog {
  sourceNetwork: string;
  newModelName: string;
  sourceModel: string;
  enabledFromEpoch: string;
  disabledFromEpoch: string;
  toolBar: string;
  hover: string;
}

interface MainPanes {
  border: string;
  network: Pane;
  dashboard: Pane;
}

interface Pane {
  background?: string;
  boxShadow: string;
}

export const ThemeProvider: FC = ({ children }) => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  </StylesProvider>
);
