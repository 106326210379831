import { ReactNode } from 'react';
import { Node } from '@tensorleap/api-client';

export interface WizardCardProps {
  title: string;
  message: string;
  errorSeverity: NetworkWizardErrorSeverity;
  showNodeFooter?: boolean;
  showNode?: () => void;
  showNodeIndex?: { current: number; total: number }; // [current index, total number of nodes]
  quickFixes?: QuickFixProps[];
  isLoading?: boolean;
  headerClassName?: string;
  headerIcon?: JSX.Element;
}

export type NetworkWizardData = {
  errorType: GraphErrorKind | GraphWarningKind;
  category: NetworkWizardCategory;
  calculateKey: () => string;
  key: string;
} & WizardCardProps;

export type QuickFixProps = {
  title: string;
  onSelect: (_?: string) => void | Promise<void>;
  disableQuickFix?: boolean;
} & (
  | {
      icon: ReactNode;
      tooltipMsg?: string;
      selectOptions?: never;
    }
  | {
      icon?: never;
      tooltipMsg?: never;
      selectOptions: ReactNode[];
    }
);

export type GraphWarningData = {
  warningType: GraphWarningKind;
  nodeId: Node['id'];
  category?: NetworkWizardCategory;
};

export enum NetworkWizardCategory {
  MODEL = 'model',
  CODE = 'code',
  INPUTS = 'inputs',
  LOSS = 'loss',
  METRICS = 'metrics',
  VIS = 'vis',
}

export enum NetworkWizardErrorSeverity {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum WizardCategoryStatus {
  NONE = 'none',
  OK = 'ok',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum GraphErrorKind {
  graph = 'graph',
  validateAssets = 'validateAssets',
  node = 'graph.node',
  nodes = 'graph.nodes',
  nodeInput = 'graph.node.input',
  nodeAttr = 'graph.node.attr',
  codeIntegration = 'codeIntegration',
  datasetMappingCreateUpdateApply = 'datasetMappingCreateUpdateApply',
  datasetMappingApplyError = 'datasetMappingApplyError',
  datasetMappingApplyWarning = 'datasetMappingApplyWarning',
}

export enum GraphWarningKind {
  predictionNeedsVisualization = 'predictionNeedsVisualization',
  predictionNeedsMetric = 'predictionNeedsMetric',
  gtNeedsVisualization = 'gtNeedsVisualization',
  inputNeedsVisualization = 'inputNeedsVisualization',
}
