import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgWeightsCopyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M11.016 8.67a.715.715 0 0 1-.528.21c-.199 0-.375-.07-.527-.21a.776.776 0 0 1-.211-.545c0-.21.07-.387.21-.527a.724.724 0 0 1 .528-.229c.211 0 .387.076.528.229.152.14.228.316.228.527 0 .21-.076.393-.228.545ZM9.96 9.848a.724.724 0 0 1 .527-.229c.211 0 .387.076.528.229.152.14.228.316.228.527 0 .21-.076.393-.228.545a.716.716 0 0 1-.528.21c-.199 0-.375-.07-.527-.21a.776.776 0 0 1-.211-.545c0-.21.07-.387.21-.527Zm0 2.25a.724.724 0 0 1 .527-.229c.211 0 .387.076.528.229.152.14.228.316.228.527 0 .21-.076.393-.228.545a.716.716 0 0 1-.528.21c-.199 0-.375-.07-.527-.21a.776.776 0 0 1-.211-.545c0-.21.07-.387.21-.527Z"
      fill="currentColor"
    />
    <rect x={6.5} y={5.5} width={8} height={10} rx={1} stroke="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 3h6a.5.5 0 0 1 .5.5V4h1v-.5A1.5 1.5 0 0 0 10.5 2h-6A1.5 1.5 0 0 0 3 3.5v8A1.5 1.5 0 0 0 4.5 13H5v-1h-.5a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgWeightsCopyIcon;
