import { Fragment, PropsWithChildren, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';

import { Setter } from '../../core/types';

export type DialogSize = 'sm' | 'md' | 'ml' | 'lg' | 'xl';

const DIALOG_SIZE: Record<DialogSize, string> = {
  sm: 'w-[35rem] h-[20rem]',
  md: 'w-[55rem] h-[30rem]',
  ml: 'h-[39rem] w-[50rem]',
  lg: 'w-[75rem] h-[50rem]',
  xl: 'w-[85rem] h-[60rem]',
};

export interface PopupTransitionDialogProps {
  open: boolean;
  onOpenChange: Setter<boolean>;
  dialogPanelClassName?: string;
  size?: DialogSize;
  persist?: boolean;
}

export function PopupTransitionDialog({
  open,
  onOpenChange,
  dialogPanelClassName,
  size = 'xl',
  children,
  persist,
}: PropsWithChildren<PopupTransitionDialogProps>): JSX.Element {
  const onClose = useCallback(() => {
    if (!persist) {
      onOpenChange(false);
    }
  }, [onOpenChange, persist]);

  return (
    <Transition
      as={Fragment}
      show={open}
      enter="transition duration-300 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-150 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog open onClose={onClose} className="fixed inset-0 z-[1200]">
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center">
          <Dialog.Panel
            className={clsx(
              'rounded-3xl',
              'relative',
              'pt-8',
              'max-h-[90vh] max-w-[90vw]',
              DIALOG_SIZE[size],
              dialogPanelClassName
            )}
          >
            {children}
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition>
  );
}
