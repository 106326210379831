import { DatasetSetupStatus } from '@tensorleap/api-client';
import { PARSE_ERROR_KEY, PRINT_LOG_KEY } from './utils/consts';

export function extractErrorsAndInfoFromDatasetStatus({
  bindersStatus,
  generalError,
  printLog,
}: DatasetSetupStatus): Record<string, string> {
  const initObj: Record<string, string> = {};
  if (printLog) {
    initObj[PRINT_LOG_KEY] = printLog;
  }

  if (generalError) {
    initObj[PARSE_ERROR_KEY] = generalError;
  }

  return bindersStatus.reduce((accu, binderStatus) => {
    if (!binderStatus.is_passed) {
      let errorMsg = '';
      for (const [k, v] of Object.entries(binderStatus.display)) {
        errorMsg += `${k}: ${v}\n`;
      }
      accu[binderStatus.name] = errorMsg;
    }
    return accu;
  }, initObj);
}
