import { useCallback } from 'react';
import {
  ClippingSlideSelectorProps,
  ClippingSlideSelector,
} from './ClippingSlideSelector';
import {
  OpacitySlideSelectorProps,
  OpacitySlideSelector,
} from './OpacitySlideSelector';
import { ScissorsIcon, Settings, TransparencyIcon } from '../../ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { useLocalStorage } from '../../core/useLocalStorage';
import { TOUR_SELECTORS_ENUM } from '../../tour/ToursConfig';

export interface HeatmapSlidersProps {
  opacitySlideSelectorProps?: OpacitySlideSelectorProps;
  clippingSlideSelectorProps?: ClippingSlideSelectorProps;
}

export function HeatmapSliders({
  opacitySlideSelectorProps,
  clippingSlideSelectorProps,
}: HeatmapSlidersProps): JSX.Element {
  const [isOpen, setIsOpen] = useLocalStorage<boolean>(
    'heatmapSliderIsOpen',
    false
  );
  const toggleOpen = useCallback(() => setIsOpen((isOpen) => !isOpen), [
    setIsOpen,
  ]);

  return (
    <div
      className="flex w-full h-fit z-[5000]"
      id={TOUR_SELECTORS_ENUM.HEAT_MAP_SLIDERS_ID}
    >
      <div
        className={clsx(
          'w-full bg-black rounded-b-2xl transition-all duration-300 ease-in-out',
          isOpen
            ? 'opacity-100 h-auto pl-2 py-2'
            : 'opacity-0 h-0 overflow-hidden'
        )}
      >
        {isOpen && (
          <div className="flex w-full flex-col gap-1 pr-14 z-[50000]">
            {opacitySlideSelectorProps && (
              <div className="flex flex-row w-full h-fit gap-3 justify-center">
                <Tooltip title="Opacity">
                  <div className="flex p-0 m-0 h-fit">
                    <TransparencyIcon className="h-4 w-4 text-gray-300" />
                  </div>
                </Tooltip>
                <OpacitySlideSelector
                  {...opacitySlideSelectorProps}
                  className="mt-1"
                />
              </div>
            )}
            {clippingSlideSelectorProps && (
              <div className="flex flex-row w-full h-fit gap-3 justify-center">
                <Tooltip title="Clipping">
                  <div className="flex p-0 m-0 h-fit">
                    <ScissorsIcon className="h-4 w-4 text-gray-300" />
                  </div>
                </Tooltip>
                <ClippingSlideSelector
                  {...clippingSlideSelectorProps}
                  className="mt-1"
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="h-fit flex top-3 right-0 absolute">
        <IconButton
          className={clsx(
            '!h-8 !w-8 mx-2',
            isOpen ? 'bg-primary-950' : 'bg-gray-800'
          )}
          onClick={toggleOpen}
        >
          <Settings className="h-4 w-4" />
        </IconButton>
      </div>
    </div>
  );
}
