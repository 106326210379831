import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgShieldKeyhole = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M14.453 10.747c0 .801-.483 1.489-1.172 1.79l.582 2.894a.781.781 0 0 1-.23.553c-.146.146-.926.229-1.133.229-.207 0-.987-.083-1.134-.23a.781.781 0 0 1-.229-.552l.582-2.894a1.953 1.953 0 1 1 2.734-1.79Z"
      fill="currentColor"
    />
    <path
      d="M11.16 3.19a12.981 12.981 0 0 1-2.683 1.368 14.478 14.478 0 0 1-4.22.9 8.007 8.007 0 0 1-.265.01h-.078a.781.781 0 0 0-.789.782v4.305a11.719 11.719 0 0 0 5.758 10.089l3.222 1.905a.781.781 0 0 0 .795 0l3.219-1.904a11.72 11.72 0 0 0 5.756-10.089V6.25a.782.782 0 0 0-.794-.781h-.078a12.32 12.32 0 0 1-1.256-.1 14.033 14.033 0 0 1-3.205-.81 13.582 13.582 0 0 1-2.694-1.374 7.958 7.958 0 0 1-.625-.465c-.06-.049-.117-.1-.173-.153a.781.781 0 0 0-1.103.008l-.17.152c-.129.11-.333.27-.616.464Zm-6.473 7.365v-3.56a16.04 16.04 0 0 0 4.344-.978c1.06-.393 2.07-.908 3.01-1.536.18-.121.334-.234.465-.336.133.1.29.213.469.335a15.129 15.129 0 0 0 3.003 1.536c1.39.533 2.85.865 4.335.984v3.558a10.157 10.157 0 0 1-4.99 8.744l-2.821 1.667L9.678 19.3a10.157 10.157 0 0 1-4.99-8.745Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgShieldKeyhole;
