import {
  styled,
  Slider as MuiSlider,
  Tooltip,
  ValueLabelProps,
  SliderTypeMap,
} from '@material-ui/core';
import clsx from 'clsx';
import { uniq } from 'lodash';
import React, { useCallback, useMemo } from 'react';

export type Range = [number, number];

export type SliderBackgroundType = 'Base' | 'GradientRainbow' | 'GradiantBW';
const sliderBackgroundColorsMap: Record<SliderBackgroundType, string> = {
  Base: '#303030',
  GradientRainbow: 'linear-gradient(to right, #0000ff, #00ff00, #ff0000)',
  GradiantBW: 'linear-gradient(to right, #000000, #ffffff)',
};

interface StyledSliderProps {
  background: string;
}

const StyledSlider = styled(MuiSlider)(({ background }: StyledSliderProps) => ({
  padding: '0',
  '& .MuiSlider-thumb': {
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-rail': {
    color: '#303030',
    opacity: 1,
    border: '1px solid #9e9e9e',
    height: '10px',
    borderRadius: '2px',
    background: background,
  },
}));

export type SliderProps = SliderTypeMap['props'] & {
  className?: string;
  thumbClassName?: string;
  labelClassName?: string;
  labelPrefix?: string;
  possibleValues?: number[];
  sliderColor?: SliderBackgroundType;
};

export function Slider({
  labelClassName,
  labelPrefix,
  thumbClassName,
  className,
  onChange,
  possibleValues,
  sliderColor = 'Base',
  ...sliderprops
}: SliderProps): JSX.Element {
  const sortedPossibleValues = useMemo(
    () => uniq(possibleValues)?.sort((a, b) => a - b),
    [possibleValues]
  );

  const onChangeFunc = useCallback(
    (event: React.ChangeEvent<object>, newValue: number | number[]) => {
      onChange?.(
        event,
        sortedPossibleValues?.length
          ? sortedPossibleValues[newValue as number]
          : newValue
      );
    },
    [onChange, sortedPossibleValues]
  );

  const maxSliderValue = useMemo(() => {
    if (sortedPossibleValues?.length) return sortedPossibleValues.length - 1;
    return sliderprops.max;
  }, [sortedPossibleValues, sliderprops.max]);

  const minSliderValue = useMemo(() => {
    if (sortedPossibleValues?.length) return 0;
    return sliderprops.min;
  }, [sortedPossibleValues, sliderprops.min]);

  const valueSliderOrIndex = useMemo(() => {
    if (sortedPossibleValues?.length)
      return sortedPossibleValues.indexOf(sliderprops.value as number);
    return sliderprops.value;
  }, [sliderprops.value, sortedPossibleValues]);

  const fullBackgroundColor = sliderBackgroundColorsMap[sliderColor];
  return (
    <StyledSlider
      valueLabelDisplay="auto"
      aria-labelledby="continuous-slider"
      track={false}
      background={fullBackgroundColor}
      {...sliderprops}
      ValueLabelComponent={(props: ValueLabelProps) =>
        ValueLabelComponent({
          props,
          labelClassName,
          labelPrefix,
          possibleValues: sortedPossibleValues,
        })
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ThumbComponent={(props: any) => ThumbComponent(props, thumbClassName)}
      className={className}
      onChange={onChangeFunc}
      min={minSliderValue}
      max={maxSliderValue}
      value={valueSliderOrIndex}
    />
  );
}

interface ValueLabelComponentProps {
  props: ValueLabelProps;
  labelClassName?: string;
  labelPrefix?: string;
  possibleValues?: number[];
}
const ValueLabelComponent = ({
  props,
  labelClassName = '',
  labelPrefix = '',
  possibleValues = [],
}: ValueLabelComponentProps) => {
  const { children, open, value } = props;

  const displayValue = useMemo(() => {
    if (Array.isArray(value)) {
      return value
        .map((val) =>
          possibleValues?.length
            ? `${labelPrefix}${possibleValues[val]}`
            : `${labelPrefix}${val}`
        )
        .join(' - ');
    } else {
      const actualValue = possibleValues?.length
        ? possibleValues[value]
        : value;
      return `${labelPrefix}${actualValue}`;
    }
  }, [labelPrefix, possibleValues, value]);

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      className={labelClassName}
      title={
        <div
          className="flex justify-center items-center"
          style={{
            fontSize: '0.5rem',
            margin: 0,
            padding: 0,
            height: '5px',
          }}
        >
          {displayValue}
        </div>
      }
    >
      <span>{children}</span>
    </Tooltip>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ThumbComponent = (props: any, thumbClassName = '') => {
  const { style, className, ...otherProps } = props;

  return (
    <div
      {...otherProps}
      className={clsx(
        'h-2.5 w-1.5 bg-gray-600 border border-white',
        className,
        thumbClassName
      )}
      style={{
        ...style,
        borderRadius: '2px',
        margin: '0 0 0 -3px',
        height: '10px',
      }}
    />
  );
};
