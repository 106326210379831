import useSWR, { KeyedMutator } from 'swr';
import { SlimTeam } from '@tensorleap/api-client';
import api from '../api-client';
import { CacheKey } from './consts';
import { useMergedObject } from '../useMergedObject';

export interface UseFetchTeams {
  teams: SlimTeam[] | undefined;
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<SlimTeam[]>;
}

export function UseFetchTeams(): UseFetchTeams {
  const { data: teams, error, mutate } = useSWR<SlimTeam[], Error>(
    CacheKey.TEAMS,
    async () => {
      const { teams } = await api.getTeams();
      return teams;
    }
  );

  return useMergedObject({
    teams,
    error,
    isLoading: !error && !teams,
    refetch: mutate,
  });
}
