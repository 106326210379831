import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgLoss = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M16.851 17.793a.68.68 0 0 1-.498-.21.669.669 0 0 1-.21-.498c0-.195.07-.363.21-.504l3.298-3.298h-6.795a6.232 6.232 0 0 1-2.474-.503 6.322 6.322 0 0 1-2.036-1.362 6.383 6.383 0 0 1-1.356-2.03 6.187 6.187 0 0 1-.509-2.48V6.2c0-.196.07-.362.21-.498a.669.669 0 0 1 .498-.21.68.68 0 0 1 .498.21c.14.14.21.306.21.498v.708c0 .672.132 1.314.394 1.926.261.613.614 1.14 1.056 1.583.443.443.97.795 1.583 1.057a4.846 4.846 0 0 0 1.926.393h6.795l-3.298-3.304a.68.68 0 0 1-.21-.498c0-.196.07-.364.21-.504a.669.669 0 0 1 .498-.21c.196 0 .364.07.504.21l4.505 4.51a.685.685 0 0 1 .204.504.678.678 0 0 1-.204.498l-4.505 4.51a.687.687 0 0 1-.504.21Z"
      fill="currentColor"
    />
    <path
      d="M6.603 5.614a.794.794 0 0 1 .586-.247c.226 0 .424.084.587.247.162.162.247.36.247.586v.708c0 .655.127 1.28.382 1.877.257.599.6 1.113 1.03 1.543.432.431.946.775 1.544 1.03a4.723 4.723 0 0 0 1.877.384h6.494l-3.085-3.09a.806.806 0 0 1-.247-.587c0-.229.084-.428.246-.592a.794.794 0 0 1 .587-.247c.23 0 .43.084.592.247l4.505 4.51c.16.16.241.36.241.592a.803.803 0 0 1-.241.586l-4.505 4.51a.812.812 0 0 1-.592.247.806.806 0 0 1-.585-.246.794.794 0 0 1-.248-.587c0-.23.084-.429.247-.592l3.085-3.085h-6.494c-.88 0-1.721-.17-2.523-.513a6.446 6.446 0 0 1-2.076-1.388 6.508 6.508 0 0 1-1.382-2.07 6.312 6.312 0 0 1-.519-2.529V6.2c0-.229.083-.427.247-.586Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3.339a.75.75 0 0 1 .75.75v12.854c0 1.217.257 1.787.588 2.167.23.263.787.63 1.753.63h11.59a.75.75 0 1 1 0 1.5H6.092c-1.353 0-2.34-.521-2.884-1.144-.646-.742-.957-1.71-.957-3.153V4.09a.75.75 0 0 1 .75-.75Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLoss;
