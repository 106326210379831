import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgHamburgerMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M0 3h18v2.016H0V3Zm0 6.984V8.016h18v1.968H0ZM0 15v-2.016h18V15H0Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgHamburgerMenu;
