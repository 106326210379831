import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgRefresh2Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.569 3.177a6.825 6.825 0 0 1 10.174 4.97.75.75 0 0 1-1.486.206A5.324 5.324 0 0 0 4.643 6H6a.75.75 0 1 1 0 1.5H3.392a.72.72 0 0 1-.034 0H3a.75.75 0 0 1-.75-.75v-3a.75.75 0 1 1 1.5 0v.955A6.824 6.824 0 0 1 5.57 3.177Zm-2.672 5.83a.75.75 0 0 1 .846.64A5.325 5.325 0 0 0 13.357 12H12a.75.75 0 0 1 0-1.5h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-.955A6.824 6.824 0 0 1 2.257 9.853a.75.75 0 0 1 .64-.846Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgRefresh2Icon;
