import { useCallback, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { stopPropagation } from '../../../core/stopPropagation';
import { DashletFormHeader } from '../Analytics/form/FormFields';
import {
  DEFAULT_NUM_OF_SAMPLES,
  METADATA_PREFIX,
  METRICS_PREFIX,
  useDashletScatterContext,
} from './DashletScatterContext';

import {
  PopulationExplorationDashletData,
  PopulationExplorationDisplayParams,
  ReductionAlgorithm,
} from '@tensorleap/api-client';
import { useController, useForm } from 'react-hook-form';
import { Input } from '../../../ui/atoms/Input';
import {
  parsePositiveInt,
  removeMetadataPrefix,
} from '../../../actions-dialog/helper-functions';
import { SelectMultiple } from '../../../ui/atoms/SelectMultiple';
import { SimpleAutocomplete } from '../../../ui/molecules/SimpleAutocomplete';
import { BboxIcon } from '../../../ui/icons';
import { useDebounce } from '../../../core/useDebounce';
import { Divider } from '../../Divider';
import { Flag } from '../../../actions-dialog/RunModel/Flags';
import { InfoGuide } from '../../../ui/atoms/InfoGuide';

interface PopExpSettingsForm {
  name: string;
  numOfSamples: string;
  projectionMetric?: string;
  balanceBy: string[];
  reductionAlgorithm: string;
  shouldFillRemainingWithUnbalance: boolean;
}

const FORM_ID = 'pop-exp-settings-form';
export interface PopExpSettingsProps {
  update: (data: PopulationExplorationDashletData) => Promise<void>;
  cancel: () => void;
  className?: string;
}

export interface PopExpSettingsFields {
  projectionMetric?: string;
  projectionMetricsFieldsNames: string[];
  displayParams: PopulationExplorationDisplayParams;
  balanceFieldsNames: string[];
  dashletName: string;
}

export function PopExpSettings({
  update,
  cancel,
  className,
}: PopExpSettingsProps): JSX.Element {
  const {
    settings: {
      projectionMetric,
      projectionMetricsFieldsNames,
      displayParams,
      balanceFieldsNames,
      dashletName,
    },
  } = useDashletScatterContext();

  const {
    register,
    handleSubmit,
    setFocus,
    reset: _reset,
    formState: { isValid, errors },
    control,
    watch,
  } = useForm<PopExpSettingsForm>({
    mode: 'onSubmit',
    defaultValues: {
      name: dashletName,
      numOfSamples: displayParams.population_exploration_n_samples.toString(),
    },
  });

  const {
    field: { ...projectionMetricField },
  } = useController({
    control,
    name: 'projectionMetric',
    defaultValue: projectionMetric,
  });

  const {
    field: { ...reduceAlgorithmField },
  } = useController({
    control,
    name: 'reductionAlgorithm',
    defaultValue: displayParams.reduction_algorithm,
  });

  const {
    field: { ...balanceField },
  } = useController({
    control,
    name: 'balanceBy',
    rules: {
      validate: (value) =>
        (value.length >= 0 && value.length <= 2) ||
        'Maximum of 2 values allowed',
    },
    defaultValue: displayParams.balance_by,
  });

  const {
    field: { ...shouldFillRemainingWithUnbalanceField },
  } = useController({
    control,
    name: 'shouldFillRemainingWithUnbalance',
    defaultValue: displayParams.should_fill_remaining_with_unbalance,
  });

  const projectionMetricOptions = useMemo(
    () => [
      { value: undefined, label: 'None' },
      ...projectionMetricsFieldsNames.map((metricName) => ({
        value: metricName,
        label: metricName?.startsWith(METRICS_PREFIX)
          ? metricName.split(METRICS_PREFIX)[1]
          : metricName?.startsWith(METADATA_PREFIX)
          ? metricName.split(METADATA_PREFIX)[1]
          : metricName,
      })),
    ],
    [projectionMetricsFieldsNames]
  );

  const reset = useCallback(() => {
    _reset({
      name: dashletName,
      numOfSamples: DEFAULT_NUM_OF_SAMPLES.toString(),
      projectionMetric: undefined,
      reductionAlgorithm: ReductionAlgorithm.Tsne,
      balanceBy: [],
      shouldFillRemainingWithUnbalance: true,
    });
  }, [_reset, dashletName]);

  const onSubmit = useCallback(
    async (data: PopExpSettingsForm) => {
      if (!isValid) {
        console.error('Invalid form');
        return;
      }

      const parsedNumber = parsePositiveInt(data.numOfSamples);

      if (typeof parsedNumber !== 'number') {
        console.error('Invalid number of samples');
        return;
      }

      const displayParams: PopulationExplorationDisplayParams = {
        balance_by: data.balanceBy.sort(),
        should_fill_remaining_with_unbalance:
          data.shouldFillRemainingWithUnbalance,
        population_exploration_n_samples: parsedNumber,
        reduction_algorithm: data.reductionAlgorithm as ReductionAlgorithm,
      };

      await update({
        name: register('name').name,
        type: 'PopulationExploration',
        data: {
          name: data.name,
          projectionMetric: data.projectionMetric,
          numOfSamples: parsedNumber,
          displayParams,
        },
      });
    },
    [isValid, update, register]
  );

  const debounceApply = useDebounce(onSubmit, 200);

  useEffect(() => {
    const subscription = watch(() => handleSubmit(debounceApply)());
    return () => subscription.unsubscribe();
  }, [watch, debounceApply, handleSubmit]);

  return (
    <form
      className={clsx(
        'flex flex-row bg-gray-800 w-120 rounded-l-2xl',
        className
      )}
      id={FORM_ID}
      onMouseDown={stopPropagation}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex overflow-hidden flex-1 flex-col gap-2 py-3 px-6 pb-4 border-r border-r-gray-700 w-full">
        <DashletFormHeader
          graphType={'Population Exploration'}
          reset={reset}
          cancel={cancel}
        />
        <div className="flex flex-col gap-4 pt-2 h-full overflow-y-auto overflow-x-hidden">
          <Input
            {...register('name')}
            className="w-full"
            label="Name"
            onFocus={() => setFocus('name')}
          />

          <Divider className="bg-gray-700" />

          <div className="flex flex-row w-full gap-2">
            <Input
              label="NUMBER OF SAMPLES"
              type="number"
              min={1}
              containerProps={{ className: 'w-full' }}
              error={errors.numOfSamples?.message}
              {...register('numOfSamples', {
                required: { value: true, message: 'Value is required' },
                min: {
                  value: 1,
                  message: 'Value must be greater or equal to 1',
                },
              })}
            />
            <InfoGuide title="Set the number of samples to be displayed in the population exploration" />
          </div>

          <Divider className="bg-gray-700" />

          <div className="flex flex-row w-full gap-2">
            <SimpleAutocomplete
              title="Projection metric"
              label="PROJECTION METRIC"
              small={false}
              clean={false}
              options={projectionMetricOptions}
              icon={<BboxIcon />}
              className="w-full flex-1"
              {...projectionMetricField}
            />
            <InfoGuide title="Use a dimensionality reduction technique that optimizes the distribution of values across a selected metric" />
          </div>

          <Divider className="bg-gray-700" />

          <div className="flex flex-row w-full gap-2">
            <SimpleAutocomplete
              title="Reduction Algorithm"
              label="REDUCTION ALGORITHM"
              small={false}
              clean={false}
              options={[
                { value: ReductionAlgorithm.Pca, label: 'PCA' },
                { value: ReductionAlgorithm.Tsne, label: 'TSNE' },
              ]}
              icon={<BboxIcon />}
              className="w-full flex-1"
              {...reduceAlgorithmField}
            />
            <InfoGuide title="Select the algorithm to use for dimensionality reduction" />
          </div>

          <Divider className="bg-gray-700" />

          <div className="flex flex-row w-full gap-2">
            <SelectMultiple
              label={'Metadata Based Balancing'}
              options={balanceFieldsNames}
              optionToLabel={removeMetadataPrefix}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              error={(errors.balanceBy as any)?.message}
              {...balanceField}
              value={balanceField.value || []}
              className="w-full flex-1"
            />

            <InfoGuide title="Create a balanced population exploration with equal amounts of samples from each value of the selected metadata" />
          </div>

          {balanceField.value?.length > 0 && (
            <div className="flex flex-row w-full gap-1 -mt-4">
              <Flag
                title="Approximate Balance"
                subtitle={null}
                {...shouldFillRemainingWithUnbalanceField}
              />
              <InfoGuide title="When enabled, if balancing is unable to produce enough samples, the rest of the samples are added in an unbalanced manner" />
            </div>
          )}
        </div>
      </div>
    </form>
  );
}
