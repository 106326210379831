import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { uniq } from 'lodash';
import { Setter } from '../../../core/types';
import { useMergedObject } from '../../../core/useMergedObject';
import {
  ScatterViewSettingValues,
  ScatterViewSettingOptions,
  ScatterViewSetting,
  settingValuesWithDefault,
} from './hooks';
import {
  DashboardFiltersParams,
  DashboardFiltersResponse,
  useDashboardFilters,
} from '../useDashletFields';
import {
  PopulationExplorationDashletData,
  PopulationExplorationDisplayParams,
  ReductionAlgorithm,
} from '@tensorleap/api-client';
import { useLocalStorage } from '../../../core/useLocalStorage';
import { PopExpSettingsFields } from './PopExpSettings';

export const DEFAULT_NUM_OF_SAMPLES = 2000;
export const POP_EXP_DEFAULT_NAME = 'Population Exploration';
export const METADATA_PREFIX = 'metadata.';
export const METRICS_PREFIX = 'metrics.';

export function useScatterOptions(
  scatterSettingOptions: ScatterViewSettingOptions,
  scatterValues: ScatterViewSettingValues,
  setScatterValues: Setter<ScatterViewSettingValues>
): ScatterViewSetting {
  return useMemo(() => {
    const sizeOrShape = {
      options: scatterSettingOptions.sizeOrShape,
      value: scatterValues.sizeOrShape,
      setOption: (option?: string) =>
        setScatterValues({ ...scatterValues, sizeOrShape: option }),
    };
    const dotColor = {
      options: scatterSettingOptions.dotColor,
      value: scatterValues.dotColor,
      setOption: (option?: string) =>
        setScatterValues({ ...scatterValues, dotColor: option }),
    };
    const previewBy = {
      options: scatterSettingOptions.previewBy,
      value: scatterValues.previewBy,
      setOption: (option?: string | null) =>
        setScatterValues({ ...scatterValues, previewBy: option }),
    };

    return {
      sizeOrShape,
      dotColor,
      previewBy,
    };
  }, [scatterSettingOptions, scatterValues, setScatterValues]);
}

export interface DashletScatterContextValue {
  projectId: string;

  register: (key: string, options: ScatterViewSettingOptions) => void;
  unregister: (key: string) => void;
  viewSettingsValues: ScatterViewSettingValues;
  viewSettings: ScatterViewSetting;
  settings: PopExpSettingsFields;
  filters: DashboardFiltersResponse;
}

export const DEFAULT_DISPLAY_PARAMS: PopulationExplorationDisplayParams = {
  population_exploration_n_samples: DEFAULT_NUM_OF_SAMPLES,
  balance_by: [],
  should_fill_remaining_with_unbalance: true,
  reduction_algorithm: ReductionAlgorithm.Tsne,
};

export const contextDefaults: DashletScatterContextValue = {
  projectId: '',
  register: () => undefined,
  unregister: () => undefined,
  viewSettingsValues: {} as ScatterViewSettingValues,
  viewSettings: {} as ScatterViewSetting,
  settings: {
    dashletName: POP_EXP_DEFAULT_NAME,
    projectionMetric: undefined,
    projectionMetricsFieldsNames: [],
    balanceFieldsNames: [],
    displayParams: DEFAULT_DISPLAY_PARAMS,
  },
  filters: {} as DashboardFiltersResponse,
};
const DashletScatterContext = createContext<DashletScatterContextValue>(
  contextDefaults
);

type DashletScatterContextProviderProps = PropsWithChildren<{
  cid: string;
  projectId: string;
  data?: PopulationExplorationDashletData;
  filterProps: Omit<
    DashboardFiltersParams,
    'projectId' | 'useRegisteredFilters'
  >;
}>;

export function DashletScatterContextProvider({
  cid,
  children,
  projectId,
  data,
  filterProps,
}: DashletScatterContextProviderProps): JSX.Element {
  const [scatterOptionsByKey, setScatterOptionByKey] = useState<
    Record<string, ScatterViewSettingOptions>
  >({});

  const scatterOptions = useMemo(() => {
    const allOptions = Object.values(scatterOptionsByKey);
    const options = {
      sizeOrShape: uniq(allOptions.flatMap((o) => o.sizeOrShape)),
      dotColor: uniq(allOptions.flatMap((o) => o.dotColor)),
      previewBy: uniq(allOptions.flatMap((o) => o.previewBy)),
    };

    return options;
  }, [scatterOptionsByKey]);

  const [
    scatterValues,
    setScatterValues,
  ] = useLocalStorage<ScatterViewSettingValues>(`pe-settings-${cid}`, {
    sizeOrShape: 'metrics.loss',
    dotColor: 'metrics.loss',
    previewBy: null,
  });

  const dashletName =
    (data?.data?.name as string | undefined) || POP_EXP_DEFAULT_NAME;

  const valuesWithDefault = useMemo(
    () => settingValuesWithDefault(scatterValues, scatterOptions),
    [scatterValues, scatterOptions]
  );

  const viewSettings = useScatterOptions(
    scatterOptions,
    valuesWithDefault,
    setScatterValues
  );

  const filters = useDashboardFilters({
    projectId,
    ...filterProps,
    useRegisteredFilters: true,
  });

  const projectionMetric = data?.data?.projectionMetric as string | undefined;

  const projectionMetricsFieldsNames = useMemo(
    () =>
      filters.filterFieldsMeta
        .filter(
          ({ field }) =>
            field.startsWith(METRICS_PREFIX) ||
            field.startsWith(METADATA_PREFIX)
        )
        .map(({ field }) => field),
    [filters.filterFieldsMeta]
  );
  const displayParams = useMemo(() => {
    return {
      ...DEFAULT_DISPLAY_PARAMS,
      ...((data?.data?.displayParams as PopulationExplorationDisplayParams) ||
        undefined),
    };
  }, [data]);

  const balanceFieldsNames = useMemo(
    () =>
      filters.filterFieldsMeta
        .filter(({ field }) => field.startsWith('metadata.'))
        .map(({ field }) => field),
    [filters.filterFieldsMeta]
  );

  const register = useCallback(
    (key: string, options: ScatterViewSettingOptions) => {
      setScatterOptionByKey((prev) => ({ ...prev, [key]: options }));
    },
    []
  );

  const unregister = useCallback((key: string) => {
    setScatterOptionByKey((prev) => {
      const { [key]: _, ...rest } = prev;
      return rest;
    });
  }, []);

  const value: DashletScatterContextValue = useMergedObject({
    projectId,
    register,
    unregister,
    viewSettings,
    viewSettingsValues: valuesWithDefault,
    settings: {
      dashletName,
      projectionMetric,
      projectionMetricsFieldsNames,
      displayParams,
      balanceFieldsNames,
    },
    filters,
  });
  return (
    <DashletScatterContext.Provider value={value}>
      {children}
    </DashletScatterContext.Provider>
  );
}

export function useDashletScatterContext(): DashletScatterContextValue {
  return useContext(DashletScatterContext);
}
