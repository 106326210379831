import img from '../../assets/SlackScreenshotLight.png';
import { SLACK_LINK } from '../../env.config';
import { Button } from '../atoms/Button';
import { SlackIcon } from '../icons';

export function SlackSupportWidget() {
  return (
    <div className="h-full w-full gird grid-cols-1">
      <span className="h-fit col-span-1">
        Our Slack support channel is full of tips and answers to real questions,
        asked by our community of users, and that could help you, right now.
      </span>
      <div className="h-full w-full col-span-1">
        <img src={img} className="h-30 w-fit pt-14"></img>
        <div className="h-auto w-full flex flex-row justify-end">
          <Button
            className="w-fit mt-20"
            onClick={() => openInNewTab(SLACK_LINK)}
          >
            <SlackIcon className="pr-1" /> subscribe to channel
          </Button>
        </div>
      </div>
    </div>
  );
}
const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};
