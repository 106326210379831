import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgFwArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M9 1 7.59 2.41 13.17 8H1v2h12.17l-5.58 5.59L9 17l8-8-8-8Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFwArrowIcon;
