import { Controller, UseFormReturn } from 'react-hook-form';
import { Input } from '../../ui/atoms/Input';
import { Select } from '../../ui/atoms/Select';
import { parsePositiveInt } from '../helper-functions';
import { Flag } from './Flags';

export const MAIN_KPI_OPTIONS = ['LOSS', 'ACCURACY', 'MEAN'];

export type EarlyStepFields = {
  earlyStop: boolean;
  mainKPI?: string;
  patiencePeriod?: number;
};

export type EarlyStepProps<Fields extends EarlyStepFields> = {
  form: UseFormReturn<Fields>;
};

export function EarlyStep<Fields extends EarlyStepFields>({
  form,
}: EarlyStepProps<Fields>) {
  const {
    control,
    watch,
  } = (form as unknown) as UseFormReturn<EarlyStepFields>;
  return (
    <div className="flex pb-6 pt-2 gap-4 flex-col">
      <Controller
        name="earlyStop"
        control={control}
        defaultValue={true}
        render={({ field }) => (
          <Flag
            {...field}
            title="Early Stop"
            subtitle="Stop once the main KPI stops improving"
          />
        )}
      />
      <div className="flex flex-row gap-2">
        <Controller
          name="mainKPI"
          control={control}
          render={({ field }) => (
            <Select
              disabled={!watch('earlyStop')}
              label="MAIN KPI"
              className="flex-1"
              options={MAIN_KPI_OPTIONS}
              {...field}
            />
          )}
        />
        <Controller
          name="patiencePeriod"
          control={control}
          rules={{ required: watch('earlyStop') }}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              type="number"
              error={
                fieldState.error && watch('earlyStop')
                  ? 'patiencePeriod is Required'
                  : undefined
              }
              label="PATIENCE PERIOD (IN EPOCHS)"
              disabled={!watch('earlyStop')}
              containerProps={{ className: 'flex-1' }}
              onChange={(e) => {
                field.onChange(
                  parsePositiveInt((e.target as HTMLInputElement).value)
                );
              }}
            />
          )}
        />
      </div>
    </div>
  );
}
