import { VisualizedItem } from '@tensorleap/api-client';
import {
  MetadataMap,
  VisAssetWithIdentity,
  VisPayloadType,
} from './visDataHelpers';
import { DisplayMetadata } from '../common/DisplayMetadata';
import { DisplayVisualizedItem } from './VisData';
import { MousePosition } from '../../../core/useSelectionGroup';

export interface AssetVisDisplayProps {
  visType: VisPayloadType;
  selectedVisElement: VisAssetWithIdentity;
  mousePosition: MousePosition;
}

export function AssetVisDisplay({
  visType,
  selectedVisElement,
  mousePosition,
}: AssetVisDisplayProps): JSX.Element {
  if (!selectedVisElement) {
    return <></>;
  }

  const { data } = selectedVisElement;

  if (visType === VisPayloadType.Metadata) {
    return <DisplayMetadata content={data as MetadataMap} />;
  }

  return (
    <DisplayVisualizedItem
      visualizedItem={data as VisualizedItem}
      mousePosition={mousePosition}
    />
  );
}
