import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IconButton, ListItem, Popover, Tooltip } from '@material-ui/core';
import { Dataset, DatasetVersion, TestStatus } from '@tensorleap/api-client';
import clsx from 'clsx';
import { bindPopover } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';

import { useToggle } from '../../core/useToggle';
import { Branch, DoubleCheck, Down, XClose } from '../icons';
import { Button } from '../atoms/Button';
import { minTwoDigits } from '../../core/formatters/number-formatting';
import { dateFormatter } from '../../core/formatters/date-formatting';
import { testStatusToBg } from '../../assets-management/code-integration/utils/helper-functions';
import { Chip } from '../atoms/Chip';
import { CardTabs } from '../atoms/CardTabs';
import { truncateLongtail } from '../../core/formatters/string-formatting';
import { orderBy, uniq } from 'lodash';

export interface DatasetVersionsPopoverProps {
  datasetVersions: DatasetVersion[];
  currentBranch: string;
  codeIntegrationVersionToVersionIndexMap: Map<string, number>;
  currentDatasetVersion: DatasetVersion | undefined;
  handleDatasetVersionIdSelect: (id: string) => void;
  handleBranchSelect: (branch: string) => void;
  hideNonPassedVersionsByDefault?: boolean;
  showBranchByDefault?: boolean;
  dataset: Dataset;
  openByDefault?: boolean;
  children?: React.ReactNode;
}
const VERSION_TAB = 'Versions';
const BRANCH_TAB = 'Branches';

const TAB_OPTIONS = [
  { value: BRANCH_TAB, label: 'Branches' },
  { value: VERSION_TAB, label: 'Versions' },
];
export function DatasetVersionsPopover({
  datasetVersions,
  codeIntegrationVersionToVersionIndexMap,
  currentDatasetVersion,
  handleDatasetVersionIdSelect,
  handleBranchSelect,
  hideNonPassedVersionsByDefault = false,
  currentBranch,
  showBranchByDefault,
  dataset,
  openByDefault = false,
  children,
}: DatasetVersionsPopoverProps): JSX.Element {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const buttonRef = useRef(null);

  const [showOnlyPassedVersions, toggleShowOnlyPassedVersions] = useToggle(
    hideNonPassedVersionsByDefault
  );
  const [showAllBranches, toggleShowAllBranches] = useToggle(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [tab, setTab] = useState(
    showBranchByDefault ? BRANCH_TAB : VERSION_TAB
  );

  useEffect(() => {
    if (openByDefault) {
      setIsSelectOpen(true);
      setAnchorEl(buttonRef.current);
    }
  }, [openByDefault]);

  const handleSelectClick = useCallback<MouseEventHandler<HTMLElement>>(
    (event) => {
      setIsSelectOpen(true);
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleCloseSelect = useCallback(() => {
    setIsSelectOpen(false);
    setAnchorEl(null);
  }, [setIsSelectOpen]);

  const datasetVersionsOptions = useMemo(() => {
    const filteredVersions = datasetVersions.filter(
      ({ testStatus, branch }) =>
        (!showOnlyPassedVersions || testStatus === TestStatus.TestSuccess) &&
        (!currentBranch || showAllBranches || branch === currentBranch)
    );

    const orderedVersions = orderBy(
      filteredVersions,
      ({ createdAt }) => new Date(createdAt),
      'desc'
    );

    return orderedVersions.map((datasetVersion) => (
      <CodeIntegrationVersionItem
        key={datasetVersion.cid}
        codeIntegrationVersionToVersionIndexMap={
          codeIntegrationVersionToVersionIndexMap
        }
        datasetVersion={datasetVersion}
        isSelected={datasetVersion.cid === currentDatasetVersion?.cid}
        handleClose={handleCloseSelect}
        handleDatasetVersionIdSelect={handleDatasetVersionIdSelect}
      />
    ));
  }, [
    currentDatasetVersion,
    datasetVersions,
    handleCloseSelect,
    handleDatasetVersionIdSelect,
    showOnlyPassedVersions,
    showAllBranches,
    currentBranch,
    codeIntegrationVersionToVersionIndexMap,
  ]);

  const datasetBranchesOptions = useMemo(() => {
    const latestVersionByBranch = dataset.latestVersions.reduce(
      (acc, { branch, latestValid, latest }) => {
        const latestVersion = hideNonPassedVersionsByDefault
          ? latestValid
          : latest;

        acc.set(branch, latestVersion);

        return acc;
      },
      new Map()
    );

    const branches = uniq(
      datasetVersions.map(({ branch }) => branch).filter(Boolean)
    );

    const orderedBranches = orderBy(
      branches,
      (branch) => new Date(latestVersionByBranch.get(branch)?.createdAt),
      'desc'
    );

    return orderedBranches.map((branch) => (
      <BranchListItem
        key={branch}
        branch={branch}
        isSelected={branch === currentBranch}
        handleClose={handleCloseSelect}
        handleBranchSelect={handleBranchSelect}
        latestVersion={latestVersionByBranch.get(branch)}
        codeIntegrationVersionToVersionIndexMap={
          codeIntegrationVersionToVersionIndexMap
        }
      />
    ));
  }, [
    dataset.latestVersions,
    datasetVersions,
    handleCloseSelect,
    handleBranchSelect,
    hideNonPassedVersionsByDefault,
    currentBranch,
    codeIntegrationVersionToVersionIndexMap,
  ]);

  const popoverState = usePopupState({
    variant: 'popover',
    popupId: null,
  });

  return (
    <>
      <Tooltip title="Switch version/branch">
        <IconButton
          onClick={handleSelectClick}
          className="text-gray-300"
          size="small"
          ref={buttonRef}
        >
          <Down />
        </IconButton>
      </Tooltip>
      <div
        className="flex self-center items-center"
        onClick={handleSelectClick}
      >
        {children}
      </div>
      <Popover
        {...bindPopover(popoverState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        anchorEl={anchorEl}
        open={isSelectOpen}
        onClose={handleCloseSelect}
        onMouseLeave={popoverState.close}
        PaperProps={{
          style: { overflowY: 'hidden', width: 'fit-content' },
        }}
      >
        <div className="flex p-4 flex-row justify-between bg-gray-850 text-gray-500">
          <p>VERSIONS HISTORY</p>
          <div className="flex gap-2 flex-row">
            {tab === VERSION_TAB && (
              <>
                <Tooltip
                  title={
                    showAllBranches
                      ? `Show only current branch (${currentBranch})`
                      : 'Show all branches'
                  }
                >
                  <div>
                    <Button
                      onClick={() => toggleShowAllBranches()}
                      className={clsx(
                        'h-4 rounded-full p-1 !px-2 gap-1 hover:bg-version-300',
                        !showAllBranches
                          ? ' bg-version-600 text-gray-300 hover:bg-version-500 active:bg-version-500 focus:bg-version-600'
                          : 'bg-gray-800 text-gray-500 hover:bg-gray-700 active:bg-gray-700 focus:bg-gray-800'
                      )}
                    >
                      <Branch />
                      {currentBranch}
                    </Button>
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    showOnlyPassedVersions
                      ? 'Show all versions'
                      : 'Show only valid versions'
                  }
                >
                  <Button
                    onClick={toggleShowOnlyPassedVersions}
                    variant="text"
                    className={clsx(
                      'h-2 w-2 rounded-full',
                      showOnlyPassedVersions && 'bg-secondary-500'
                    )}
                  >
                    <DoubleCheck className="rounded-full text-gray-350" />
                  </Button>
                </Tooltip>
              </>
            )}
            <Button
              onClick={handleCloseSelect}
              variant="text"
              className="h-2 w-2"
            >
              <XClose className="rounded-full text-gray-350" />
            </Button>
          </div>
        </div>

        <CardTabs
          options={TAB_OPTIONS}
          value={tab}
          className="w-full"
          onChange={setTab}
          tabClassName="w-full p-2"
        />

        <div className="max-h-96 min-w-[800px] overflow-y-auto">
          {tab === VERSION_TAB ? (
            datasetVersionsOptions.length ? (
              datasetVersionsOptions
            ) : (
              <ListItem className="uppercase justify-center text-gray-400">
                no versions found
              </ListItem>
            )
          ) : datasetBranchesOptions.length ? (
            datasetBranchesOptions
          ) : (
            <ListItem className="uppercase justify-center text-gray-400">
              no branches found
            </ListItem>
          )}
        </div>
      </Popover>
    </>
  );
}

interface CodeIntegrationVersionItemProps {
  codeIntegrationVersionToVersionIndexMap: Map<string, number>;
  datasetVersion: DatasetVersion;
  isSelected: boolean;
  handleClose: () => void;
  handleDatasetVersionIdSelect: (id: string) => void;
}
function CodeIntegrationVersionItem({
  codeIntegrationVersionToVersionIndexMap,
  datasetVersion,
  isSelected,
  handleClose,
  handleDatasetVersionIdSelect,
}: CodeIntegrationVersionItemProps): JSX.Element {
  return (
    <ListItem
      component="li"
      button
      className={isSelected ? 'bg-gray-700' : 'bg-gray-800'}
      onClick={() => {
        handleDatasetVersionIdSelect(datasetVersion.cid);
        handleClose();
      }}
    >
      <ShowVersion
        codeIntegrationVersion={datasetVersion}
        codeIntegrationVersionToVersionIndexMap={
          codeIntegrationVersionToVersionIndexMap
        }
        commitStartTruncateLength={28}
      />
    </ListItem>
  );
}

export function formatVersionIndex(index: number): string {
  return `#${minTwoDigits.format(index)}`;
}

export function BranchChip({ branch }: { branch: string }): JSX.Element {
  return (
    <Tooltip title={branch}>
      <div>
        <Chip className="h-6 max-w-[120px] w-[120px] justify-center">
          {truncateLongtail({
            value: branch,
            startSubsetLength: 7,
            endSubsetLength: 5,
          })}
        </Chip>
      </div>
    </Tooltip>
  );
}

type ShowVersionProps = {
  codeIntegrationVersion?: DatasetVersion;
  codeIntegrationVersionToVersionIndexMap: Map<string, number>;
  commitStartTruncateLength: number;
  hideBranch?: boolean;
};

export function ShowVersion({
  codeIntegrationVersion,
  codeIntegrationVersionToVersionIndexMap,
  commitStartTruncateLength,
  hideBranch,
}: ShowVersionProps): JSX.Element {
  const versionNumber = useMemo(() => {
    if (!codeIntegrationVersion) {
      return '';
    }
    const versionIndex = codeIntegrationVersionToVersionIndexMap.get(
      codeIntegrationVersion.cid
    );
    return formatVersionIndex(versionIndex ?? 0);
  }, [codeIntegrationVersion, codeIntegrationVersionToVersionIndexMap]);

  if (!codeIntegrationVersion) {
    return <div>No version found</div>;
  }

  return (
    <div className="flex flex-1 gap-2 flex-wrap">
      <DoubleCheck
        className={clsx(
          testStatusToBg(codeIntegrationVersion.testStatus),
          'rounded-full'
        )}
      />
      {!hideBranch && <BranchChip branch={codeIntegrationVersion.branch} />}
      <span className="text-gray-350">{versionNumber}</span>
      {codeIntegrationVersion.note ? (
        <Tooltip title={codeIntegrationVersion.note}>
          <div className="flex-1 whitespace-nowrap">
            {truncateLongtail({
              value: codeIntegrationVersion.note,
              startSubsetLength: commitStartTruncateLength,
              endSubsetLength: 10,
            })}
          </div>
        </Tooltip>
      ) : (
        <span className="text-gray-500 flex-1 whitespace-nowrap">
          NO MESSAGE
        </span>
      )}
      <div className="flex justify-end text-sm text-gray-400">
        {dateFormatter.format(new Date(codeIntegrationVersion.createdAt))}
      </div>
    </div>
  );
}

interface BranchListItemProps {
  codeIntegrationVersionToVersionIndexMap: Map<string, number>;
  latestVersion?: DatasetVersion;
  branch: string;
  isSelected: boolean;
  handleClose: () => void;
  handleBranchSelect: (id: string) => void;
}

export function BranchListItem({
  branch,
  isSelected,
  latestVersion,
  handleClose,
  handleBranchSelect,
  codeIntegrationVersionToVersionIndexMap,
}: BranchListItemProps): JSX.Element {
  return (
    <ListItem
      key={branch}
      component="li"
      button
      className={isSelected ? 'bg-gray-700' : 'bg-gray-800'}
      onClick={() => {
        handleBranchSelect(branch);
        handleClose();
      }}
    >
      <div className="flex items-center gap-4 text-gray-300 w-full">
        <BranchChip branch={branch} />
        {latestVersion && (
          <>
            <div className="flex text-gray-350 text-xs whitespace-nowrap">
              Latest:
            </div>

            <ShowVersion
              codeIntegrationVersion={latestVersion}
              codeIntegrationVersionToVersionIndexMap={
                codeIntegrationVersionToVersionIndexMap
              }
              commitStartTruncateLength={28}
              hideBranch
            />
          </>
        )}
      </div>
    </ListItem>
  );
}
