import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgSmallFilterEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M4.556 4h9.166v.99l-.104.104-3.229 4.062v2.969l-.156.13-1.667 1.25L7.89 14V9.156l-3.23-4.062-.103-.104V4Zm.937.833 3.021 3.75h1.25l3.02-3.75h-7.29Zm3.23 4.584v2.916l.833-.625V9.417h-.834Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSmallFilterEmpty;
