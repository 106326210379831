import { useCallback, useEffect, useRef, useState } from 'react';
import { FieldError } from './editable-field-utils';

export type UseEditableCellProps<Value> = {
  value?: Value;
  onChange(value?: Value): Promise<void>;
  validate?(value?: Value): FieldError;
};

export function useEditableCell<Value>({
  value: originalValue,
  validate,
  onChange,
}: UseEditableCellProps<Value>) {
  const [value, setValue] = useState<Value>();
  const valueRef = useRef(value);
  valueRef.current = value;
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [isOnFocus, setIsOnFocus] = useState<boolean>(false);
  const isChanged = originalValue !== value;

  const handleChange = useCallback(
    (updateValue?: Value) => {
      valueRef.current = updateValue;
      setValue(updateValue);
      validate && setError(validate(updateValue));
    },
    [validate]
  );

  const handleSave = useCallback(
    async (value?: Value) => {
      handleChange(value);
      setSaving(true);
      try {
        await onChange(value);
      } finally {
        setSaving(false);
      }
    },
    [onChange, handleChange]
  );

  const handleClose = useCallback(() => {
    setValue(originalValue);
    setError(undefined);
  }, [originalValue]);

  useEffect(() => {
    !isOnFocus && setValue(originalValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalValue]);

  const onFocus = useCallback(() => setIsOnFocus(true), []);
  const onBlur = useCallback(() => setIsOnFocus(false), []);

  return {
    value,
    valueRef,
    saving,
    error,
    isChanged,
    handleSave,
    handleClose,
    handleChange,
    onFocus,
    onBlur,
  };
}
