export enum URLS_ENUM {
  PROJECT = '/project',
  VERSION = '/version',
  TAB = '/tab',
  PANEL = '/panel',
  TEAM_MANAGEMENT = '/team-management',
  SETTINGS = '/settings',
  ASSETS = '/assets',
  FLAGS = '/flags',
  CONFLICT_USERS = '/conflict-users',
  AUTHORIZATION_REQUEST = '/authorization-request',
  WELCOME = '/welcome',
  SIGNUP = '/signup',
  LOGIN = '/login',
  TRIAL = '/init-trial',
}

export enum URL_SUB_PATHS_ENUM {
  SECRET = 'secrets',
  DATASETS = 'datasets',
}

export const DEFAULT_VERSION_ID = 'default-verison-id';

//query params
export const SELECTED_SESSION_RUN_KEY = 'selected';
export const SECRET_MANAGER_ID_KEY = 'id';
export const SELECTED_DASHBOARD_KEY = 'dashboard';
export const CODE_CHALLENGE_KEY = 'code_challenge';

export const getIdFromUrl = (url: string, key: URLS_ENUM) => {
  const urlParts = url.split('/');
  const keyIndex = urlParts.indexOf(key.replace('/', ''));
  if (keyIndex === -1 || urlParts.length <= keyIndex + 1) {
    return undefined;
  }
  return urlParts[keyIndex + 1];
};

export function buildProjectUrl(projectId: string) {
  return `${URLS_ENUM.PROJECT}/${projectId}`;
}

export function buildVersionUrl(projectId: string, versionId: string) {
  return `${buildProjectUrl(projectId)}${URLS_ENUM.VERSION}/${versionId}`;
}

export function switchVersionUrl(url: string, versionId: string) {
  const currentVersionId = getIdFromUrl(url, URLS_ENUM.VERSION);
  if (!currentVersionId) {
    throw new Error('No version id found in the current url');
  }

  return url.replace(currentVersionId, versionId);
}

export function buildTabUrl(projectId: string, versionId: string, tab: string) {
  return `${buildVersionUrl(projectId, versionId)}${URLS_ENUM.TAB}/${tab}`;
}

export function removeVersionUrl(url: string) {
  const versionId = getIdFromUrl(url, URLS_ENUM.VERSION);
  if (!versionId) {
    console.warn('No version id found in the current url');
    return url;
  }

  return url.replace(
    `${URLS_ENUM.VERSION}/${versionId}`,
    `${URLS_ENUM.VERSION}/${DEFAULT_VERSION_ID}`
  );
}

export function changeQueryParams(
  queryParameters: string,
  key: string,
  values: string[]
): string {
  const queryParametersObj = new URLSearchParams(queryParameters);

  queryParametersObj.delete(key);

  for (const value of values) {
    queryParametersObj.append(key, value);
  }

  return queryParametersObj.toString();
}

export function deleteQueryParam(queryParameters: string, key: string): string {
  const queryParametersObj = new URLSearchParams(queryParameters);
  queryParametersObj.delete(key);
  return queryParametersObj.toString();
}

export function getArrayValueFromQueryParams(
  queryParameters: string,
  key: string
) {
  const queryParametersObj = new URLSearchParams(queryParameters);
  return queryParametersObj.getAll(key);
}

export function getStringValueFromQueryParams(
  queryParameters: string,
  key: string
): string | undefined {
  const values = getArrayValueFromQueryParams(queryParameters, key);
  const output = values[0];
  return output;
}

export function setQueryParam(
  queryParameters: string,
  key: string,
  value: string
): string {
  const queryParametersObj = new URLSearchParams(queryParameters);
  queryParametersObj.set(key, value);
  return queryParametersObj.toString();
}
