import {
  createContext,
  useContext,
  FC,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import api from '../core/api-client';
import {
  Version,
  ModelGraph,
  Session,
  DatasetVersion,
  Project,
  CodeIntegrationBinder,
} from '@tensorleap/api-client';
import { useLocalStorage } from './useLocalStorage';
import { useMergedObject } from './useMergedObject';
import {
  modelGraphToGroupedModelGraph,
  groupedModelGraphToModelGraph,
} from '../network-editor/networkStateUtils';
import { reorganizeModelGraphIfNeeded } from '../network-editor/autoorganize';
import { usePushNotifications } from './PushNotificationsContext';
import { isImportModelSuccessMsg } from './websocket-message-types';
import useSWR from 'swr';

export interface SaveNewVersionParams {
  modelGraph: ModelGraph;
  description: string;
  branchName: string;
  codeIntegration?: CodeIntegrationBinder;
  hash?: string;
  copySessions?: Session[];
}
export interface CurrentProjectContextInterface {
  currentProjectId?: string;
  currentVersionId?: string;
  currentProjectName: string;
  currentProjectTeamId: string;
  currentVersion?: Version;
  currentModelGraph?: ModelGraph;
  lastFullModelGraph?: ModelGraph;
  refetchCurrentVersion: () => void;
  fetchValidProjectCid: () => string;
  isModelLayersGrouped: boolean;
  maxNodeId: number | undefined;
  toggleModelNodesGrouping: (
    _modelGraph: ModelGraph,
    _shouldCollapse: boolean
  ) => void;
  saveNewVersion: (saveNewVersionParams: SaveNewVersionParams) => Promise<void>;
  overrideModelGraph(modelGraph: ModelGraph): void;
  isTrainDialogOpen: boolean;
  setIsTrainDialogOpen: (value: boolean) => void;
  isEvaluateDialogOpen: boolean;
  setIsEvaluateDialogOpen: (value: boolean) => void;
  selectedCodeIntegrationVersion?: DatasetVersion;
  setSelectedCodeIntegrationBinder: (value?: CodeIntegrationBinder) => void;
  selectedCodeIntegrationBinder?: CodeIntegrationBinder;
  latestVersionId?: string;
  setLatestVersionId: (value?: string) => void;
  loadCodeIntegration: (version?: Version) => Promise<void>;
}

const AUTO_COLLAPSE_MAP_LENGTH = 1000;

export const CurrentProjectContext = createContext<CurrentProjectContextInterface>(
  {
    currentProjectId: undefined,
    currentVersionId: undefined,
    currentProjectName: '',
    currentProjectTeamId: '',
    refetchCurrentVersion: () => undefined,
    fetchValidProjectCid: () => '',
    isModelLayersGrouped: false,
    maxNodeId: 0,
    toggleModelNodesGrouping: () => undefined,
    saveNewVersion: async () => undefined,
    overrideModelGraph: async () => undefined,
    isTrainDialogOpen: false,
    setIsTrainDialogOpen: () => undefined,
    isEvaluateDialogOpen: false,
    setIsEvaluateDialogOpen: () => undefined,
    selectedCodeIntegrationVersion: undefined,
    setSelectedCodeIntegrationBinder: () => undefined,
    latestVersionId: undefined,
    setLatestVersionId: () => undefined,
    loadCodeIntegration: async () => undefined,
  }
);

export const CurrentProjectProvider: FC<{
  project: Project;
  versionId: string;
}> = ({ children, project, versionId }) => {
  const projectId = project.cid;
  const [currentVersion, setCurrentVersion] = useState<Version>();
  const [lastFullModelGraph, setLastFullModelGraph] = useState<ModelGraph>();
  const [currentModelGraph, setCurrentModelGraph] = useState<ModelGraph>();
  const [isTrainDialogOpen, setIsTrainDialogOpen] = useState(false);
  const [isEvaluateDialogOpen, setIsEvaluateDialogOpen] = useState(false);
  const [
    selectedCodeIntegrationBinder,
    setSelectedCodeIntegrationBinder,
  ] = useState<CodeIntegrationBinder>();
  const selectedCodeIntegrationVersion = useFetchCodeIntegrationVersionByBinder(
    selectedCodeIntegrationBinder
  );
  const [latestVersionId, setLatestVersionId] = useState<string>();

  const [
    isModelLayersGrouped,
    setIsModelLayersGrouped,
  ] = useLocalStorage<boolean>(`isModelLayersGrouped`, false);
  const [maxNodeId, setMaxNodeId] = useState<number>();

  const applyFullModelGraph = useCallback(
    (fullModelGraph: ModelGraph | undefined) => {
      const maxFullModelGraphNodeId = Object.keys(
        fullModelGraph?.nodes || {}
      ).reduce((maxId, nodeId) => Math.max(maxId, Number(nodeId)), 0);
      setLastFullModelGraph(fullModelGraph);
      setMaxNodeId(maxFullModelGraphNodeId);
    },
    []
  );

  // TODO: Change logic so current project is non-nullable when project context is moved to project scope then use current project instead of this function
  const fetchValidProjectCid = useCallback(() => {
    if (!projectId) {
      console.error('Current project not defined in project scope');
      return '';
    }
    return projectId;
  }, [projectId]);

  const toggleModelNodesGrouping = useCallback<
    CurrentProjectContextInterface['toggleModelNodesGrouping']
  >(
    (modelGraph, shouldCollapse) => {
      if (!modelGraph) return;

      if (shouldCollapse) {
        const groupedModelGraph = modelGraphToGroupedModelGraph(modelGraph);
        setCurrentModelGraph(groupedModelGraph);
        applyFullModelGraph(modelGraph);
        setIsModelLayersGrouped(true);
      } else {
        if (!lastFullModelGraph) {
          console.error("shouldn't happen");
          return;
        }
        const restoredFullModelGraph = groupedModelGraphToModelGraph(
          modelGraph,
          lastFullModelGraph
        );
        setCurrentModelGraph(restoredFullModelGraph);
        applyFullModelGraph(restoredFullModelGraph);
        setIsModelLayersGrouped(false);
      }
    },
    [applyFullModelGraph, lastFullModelGraph, setIsModelLayersGrouped]
  );

  const loadCodeIntegrationFromVersion = useCallback(
    async (version: Version) => {
      if (!version) {
        setSelectedCodeIntegrationBinder(undefined);
        setLatestVersionId(undefined);
        return;
      }

      const versionHasChangedOrSelectedUndefined =
        latestVersionId !== version.cid || !selectedCodeIntegrationVersion;
      setLatestVersionId(version.cid);

      if (versionHasChangedOrSelectedUndefined) {
        setSelectedCodeIntegrationBinder(version.codeIntegration);
      }
    },
    [selectedCodeIntegrationVersion, latestVersionId]
  );

  const loadCodeIntegration = useCallback(async () => {
    if (!currentVersion) {
      return;
    }
    await loadCodeIntegrationFromVersion(currentVersion);
  }, [currentVersion, loadCodeIntegrationFromVersion]);

  const loadVersion = useCallback(
    async (versionId: string) => {
      setIsModelLayersGrouped(false);
      setCurrentVersion(undefined);
      setCurrentModelGraph(undefined);
      setSelectedCodeIntegrationBinder(undefined);
      applyFullModelGraph(undefined);
      const { version } = await api.loadVersion({
        versionId,
        projectId,
      });
      setCurrentVersion(version);
      loadCodeIntegrationFromVersion(version);
      if (!version.data) {
        setCurrentModelGraph({
          nodes: {},
          id: '',
        });
        return;
      }
      if (
        Object.keys(version.data.nodes).length >= AUTO_COLLAPSE_MAP_LENGTH ||
        isModelLayersGrouped
      )
        toggleModelNodesGrouping(version.data, true);
      else {
        const modelGraph = reorganizeModelGraphIfNeeded(version.data);
        setCurrentModelGraph(modelGraph);
        applyFullModelGraph(modelGraph);
      }
    },
    [
      setIsModelLayersGrouped,
      applyFullModelGraph,
      projectId,
      loadCodeIntegrationFromVersion,
      isModelLayersGrouped,
      toggleModelNodesGrouping,
    ]
  );

  const refetchCurrentVersion = useCallback(async () => {
    if (!currentVersion) {
      console.error('refetchCurrentVersion called without currentVersion');
      return;
    }
    loadVersion(currentVersion?.cid);
  }, [currentVersion, loadVersion]);

  useEffect(() => {
    loadVersion(versionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    versionId,
    // loadVersion //loadVersion can't be an use-effect dependency because it changes it self
  ]);

  const saveNewVersion = useCallback(
    async ({
      modelGraph,
      description,
      branchName,
      codeIntegration,
      hash,
      copySessions,
    }: SaveNewVersionParams) => {
      if (!projectId) {
        console.error('Somehow a new version was added without a project');
        return;
      }

      let modelGraphToUpdate: ModelGraph = modelGraph;
      if (isModelLayersGrouped && lastFullModelGraph) {
        modelGraphToUpdate = groupedModelGraphToModelGraph(
          modelGraph,
          lastFullModelGraph
        );
      }

      const { version } = await api.addVersion({
        projectId,
        modelGraph: modelGraphToUpdate,
        branchName,
        description,
        codeIntegration,
        hash,
        copySessionIds: copySessions?.map((session) => session.cid),
      });
      loadVersion(version.cid);
    },
    [isModelLayersGrouped, lastFullModelGraph, loadVersion, projectId]
  );

  const overrideModelGraph = useCallback(
    (modelGraph: ModelGraph) => setCurrentModelGraph(modelGraph),
    [setCurrentModelGraph]
  );

  const { lastServerMessage } = usePushNotifications();

  useEffect(() => {
    if (isImportModelSuccessMsg(lastServerMessage) && !currentVersion?.data) {
      refetchCurrentVersion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastServerMessage]);

  const value = useMergedObject({
    currentProjectId: projectId,
    currentVersionId: versionId,
    currentProjectName: project.name,
    currentProjectTeamId: project.teamId,
    currentVersion,
    currentModelGraph,
    lastFullModelGraph,
    fetchValidProjectCid,
    maxNodeId,
    isModelLayersGrouped,
    toggleModelNodesGrouping,
    refetchCurrentVersion,
    saveNewVersion,
    overrideModelGraph,
    isTrainDialogOpen,
    setIsTrainDialogOpen,
    isEvaluateDialogOpen,
    setIsEvaluateDialogOpen,
    selectedCodeIntegrationVersion,
    setSelectedCodeIntegrationBinder: (value?: CodeIntegrationBinder) =>
      setSelectedCodeIntegrationBinder(value),
    selectedCodeIntegrationBinder,
    latestVersionId,
    setLatestVersionId: (value?: string) => setLatestVersionId(value),
    loadCodeIntegration,
  });

  return (
    <CurrentProjectContext.Provider value={value}>
      {children}
    </CurrentProjectContext.Provider>
  );
};

export const useCurrentProject: () => CurrentProjectContextInterface = () =>
  useContext(CurrentProjectContext);

function useFetchCodeIntegrationVersionByBinder(
  codeIntegrationBinder?: CodeIntegrationBinder
): DatasetVersion | undefined {
  const key = useMemo(() => codeIntegrationBinderToKey(codeIntegrationBinder), [
    codeIntegrationBinder,
  ]);

  const refreshInterval =
    codeIntegrationBinder?.type === 'bindByBranch' ? 10_000 : undefined;

  const { data } = useSWR(
    key,
    async () => {
      const codeIntegrationVersion = await getCodeIntegrationVersionFromBinder(
        codeIntegrationBinder
      );
      return { codeIntegrationVersion, latestKey: key };
    },
    {
      refreshInterval,
    }
  );

  return data?.latestKey !== key ? undefined : data?.codeIntegrationVersion;
}

export function codeIntegrationBinderToKey(
  codeIntegrationBinder?: CodeIntegrationBinder
) {
  if (!codeIntegrationBinder) {
    return 'undefined_code_integration_binder';
  }
  if (codeIntegrationBinder.type === 'bindByBranch') {
    return `code_integration_version_${codeIntegrationBinder.codeIntegrationId}_${codeIntegrationBinder.branch}`;
  }
  return `code_integration_version_${codeIntegrationBinder.codeIntegrationVersionId}`;
}

export async function getCodeIntegrationVersionFromBinder(
  codeIntegration?: CodeIntegrationBinder
): Promise<DatasetVersion | undefined> {
  if (!codeIntegration) {
    return;
  }
  return await api.getCodeIntegrationVersionFromBinder(codeIntegration);
}
