import { FileMap } from './dataset-editor-content';
import { gzip } from '../../../core/compression/gzip';
import { ungzip } from '../../../core/compression/ungzip';
import api, { addBasePathToURL } from '../../../core/api-client';
import { fetchFile, uploadFile } from '../../../core/file-helper';

export async function fetchSourceCode(
  url: string | undefined
): Promise<File[] | undefined> {
  if (!url) return;

  try {
    const sourceFile = await fetchFile(
      url,
      'source.tar.gz',
      'application/x-gtar'
    );
    const files = await ungzip(sourceFile);

    return files;
  } catch (e) {
    console.error(e);
  }
}

export async function uploadSourceCode(
  datasetId: string,
  fileMap: FileMap
): Promise<string | undefined> {
  try {
    const { url } = await api.getDatasetVersionUploadUrl({ datasetId });
    const urlWithBasePath = addBasePathToURL(url);

    const files = Object.entries(fileMap).map(([filename, content]) => {
      return new File([content], filename, { type: 'text/x-python' });
    });
    const targzFile = await gzip(files, 'dataset-script.tar.gz');

    const fileUploadResponse = await uploadFile(urlWithBasePath, targzFile);

    return fileUploadResponse.url;
  } catch (e) {
    console.error('There was an error in uploading the source code to storage');
    console.error(e);
  }
}
