import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgMapEmpty = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M15.333 6.167 22 2v15.833L15.333 22l-6.666-4.167L2 22V6.167L8.667 2l6.666 4.167Zm-7.5 10.221V4.493L3.667 7.098v11.894l4.166-2.604ZM14.5 7.612l-5-3.132v11.908l5 3.125V7.612Zm5.833 9.297V5.008l-4.166 2.604v11.901l4.166-2.604Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMapEmpty;
