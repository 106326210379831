import { Session, SlimVersion } from '@tensorleap/api-client';
import clsx from 'clsx';
import { useCallback, useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Input } from '../../ui/atoms/Input';
import { Select } from '../../ui/atoms/Select';
import {
  getSourceNetworkName,
  isVersionMatchToHash,
} from '../helper-functions';
import { truncateLongtail } from '../../core/formatters/string-formatting';

type NewModelDetailsFields = { modelName: string };

type NewModelDetailsProps<Fields extends NewModelDetailsFields> = {
  versions: SlimVersion[];
  selectedVersion: SlimVersion;
  className?: string;
  onVersionChange: (version: SlimVersion) => void;
  selectedSession?: Session;
  onModelChange: ((session?: Session) => void) | null;
  form: UseFormReturn<Fields>;
};

export function NewModelDetails<Fields extends NewModelDetailsFields>({
  versions,
  selectedVersion,
  onVersionChange,
  onModelChange,
  selectedSession,
  className,
  form,
}: NewModelDetailsProps<Fields>) {
  const onVersionIdChange = useCallback(
    (id?: string) => {
      const newVersion = versions.find((v) => v.cid === id) || versions[0];
      onVersionChange(newVersion);
      !isVersionMatchToHash(newVersion, selectedSession?.hash) &&
        onModelChange?.(undefined);
    },
    [versions, selectedSession, onVersionChange, onModelChange]
  );

  const {
    setValue,
    control,
  } = (form as unknown) as UseFormReturn<NewModelDetailsFields>;

  useEffect(() => {
    setValue('modelName', getNewModelName(selectedVersion));
  }, [selectedVersion, setValue]);

  return (
    <div className={clsx('flex flex-col gap-4', className)}>
      <span className="text-base">New model details:</span>

      <Select
        value={selectedVersion?.cid}
        optionID="cid"
        options={versions}
        label="NETWORK VERSION"
        optionToLabel={({ notes }) =>
          truncateLongtail({
            value: notes,
            startSubsetLength: 20,
            endSubsetLength: 20,
          })
        }
        required
        onChange={onVersionIdChange}
      />

      <Controller
        name="modelName"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <Input
            {...field}
            error={fieldState.error && 'Model Name is Required'}
            containerProps={{ className: 'flex-1' }}
            label="NEW SESSION NAME"
          />
        )}
      />
    </div>
  );
}

function getNewModelName(version: SlimVersion): string {
  return `${getSourceNetworkName(version)}-${version.sessions.length}`;
}
