import api from '../api-client';
import useSWR, { KeyedMutator } from 'swr';
import { CacheKey } from './consts';
import {
  AllSessionsTestResults,
  GetSessionTestResultsRequest,
  SessionTestData,
} from '@tensorleap/api-client';
import { useMergedObject } from '../useMergedObject';
import { useCallback, useState } from 'react';

export interface useFetchAllModelTests {
  modelTestResults: AllSessionsTestResults[];
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<AllSessionsTestResults[]>;
}

export interface fetchAllModelTestResultsProps {
  projectId: string | undefined;
  sessionsTestData: SessionTestData[];
}
export function useFetchAllModelTestResults({
  projectId,
  sessionsTestData,
}: fetchAllModelTestResultsProps): useFetchAllModelTests {
  const [refetching, setRefetching] = useState(false);
  const modelIdentifiers = sessionsTestData
    .map((modelData) => `${modelData.sessionRunId}${modelData.epoch}`)
    .join('-');
  const uniqueCachKey = `${CacheKey.MODEL_TEST_RESULTS}-${projectId}-${modelIdentifiers}`;
  const { data: modelTestResults, error, mutate } = useSWR<
    AllSessionsTestResults[],
    Error
  >(uniqueCachKey, async () => {
    if (!projectId || !sessionsTestData?.length) {
      return [];
    }
    const request: GetSessionTestResultsRequest = {
      projectId,
      sessionsTestData,
    };
    const modelTestResults = await api.getSessionTestResult(request);
    return modelTestResults;
  });

  const refetch = useCallback(() => {
    setRefetching(true);
    return mutate().finally(() => setRefetching(false));
  }, [mutate]);

  return useMergedObject({
    modelTestResults: modelTestResults || [],
    error,
    isLoading: refetching || (!error && !modelTestResults),
    refetch,
  });
}
