import useAsyncEffect from '../core/useAsyncEffect';
import { MainNavBar } from '../ui/MainNavBar';
import { Divider } from '../ui/mui';
import { MemberManagement } from './MemberManagement';
import { TeamManagementTopBar } from './TeamManagementTopBar';
import { useState } from 'react';
import api from '../core/api-client';
import { TeamManagement } from './TeamManagement';

export function TeamManagementPage(): JSX.Element {
  const [maxActiveUsers, setMaxActiveUsers] = useState(10);

  useAsyncEffect(async () => {
    const { maxActiveUsers } = await api.getMaxActiveUsers();
    setMaxActiveUsers(maxActiveUsers);
  }, []);

  return (
    <div className="flex flex-col items-stretch w-screen h-screen overflow-hidden">
      <MainNavBar />

      <div className="w-full">
        <TeamManagementTopBar maxActiveUsers={maxActiveUsers} />
      </div>
      <div className="grid grid-cols-8 py-8 px-8 h-full w-full overflow-hidden bg-transparent">
        <div className="col-span-5 flex flex-row gap-8 border-r border-gray-600 overflow-hidden">
          <MemberManagement maxActiveUsers={maxActiveUsers} />
          <Divider className="ml-auto" orientation="vertical" />
        </div>
        <div className="col-span-3 flex flex-col gap-4 w-full h-full overflow-hidden pl-8">
          {/* <TeamStatistics /> */}
          <TeamManagement />
        </div>
      </div>
    </div>
  );
}
