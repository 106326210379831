import Keycloak from 'keycloak-js';
import {
  getApiOrigin,
  getFullBaseURL,
  isLocalhostHost,
} from './core/api-client';

function determineKeycloakUrl() {
  const currentHost = window.location.hostname;
  const currentPort = window.location.port;

  if (isLocalhostHost(currentHost) && currentPort === '3000') {
    return 'http://127.0.0.1:4589/auth';
  } else {
    return `${getFullBaseURL() || getApiOrigin()}/auth`;
  }
}

export function createKeycloak() {
  return new Keycloak({
    url: determineKeycloakUrl(),
    realm: 'tensorleap',
    clientId: 'tensorleap-client',
  });
}
