import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgIntervalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="m17 9-3-3v2h-1V5H5v3H4V6L1 9l3 3v-2h1v3h8v-3h1v2l3-3Zm-5 3H6V6h6v6Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgIntervalIcon;
