import { IconButton, Menu, PopoverOrigin } from '@material-ui/core';
import { PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { stopPropagation } from '../../core/stopPropagation';

type IconMenuProps = PropsWithChildren<{
  iconWrapperClassName?: string;
  paperClassName?: string;
  closeOnClick?: boolean;
  transformOrigin?: PopoverOrigin;
  icon: ReactNode;
}>;

export function IconMenu({
  children,
  iconWrapperClassName,
  paperClassName,
  closeOnClick,
  transformOrigin,
  icon,
}: IconMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        className={iconWrapperClassName ? iconWrapperClassName : 'h-10 w-10'}
        onClick={handleClick}
        onMouseDown={stopPropagation}
      >
        {icon}
      </IconButton>

      <Menu
        onClose={handleClose}
        onClick={closeOnClick ? handleClose : undefined}
        MenuListProps={{
          className: 'flex flex-col',
        }}
        open={open}
        anchorEl={anchorEl}
        PaperProps={{
          className: paperClassName,
        }}
        transformOrigin={
          transformOrigin ?? { horizontal: 'right', vertical: 'top' }
        }
      >
        {children}
      </Menu>
    </>
  );
}
