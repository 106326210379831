import { MouseEventHandler, MouseEvent, useCallback, useState } from 'react';

import { Position } from './position';
import { useMergedObject } from './useMergedObject';

export interface ContextMenuPopoverParams<T extends HTMLElement = HTMLElement> {
  isContextMenuOpen: boolean;
  setIsContextMenuOpen: (value: boolean) => void;
  contextMenuPosition: Position;
  openContextMenu: (event: MouseEvent<T>) => void;
  handleContextMenuClose: (event: MouseEvent<T>) => void;
}
export function useContextMenuPopoverState<
  T extends HTMLElement = HTMLElement
>(): ContextMenuPopoverParams<T> {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<Position>([
    0,
    0,
  ]);

  const openContextMenu = useCallback<MouseEventHandler<T>>((event) => {
    event.stopPropagation();
    event.preventDefault();
    setContextMenuPosition([event.clientX, event.clientY]);
    setIsContextMenuOpen(true);
  }, []);
  const handleContextMenuClose = useCallback<MouseEventHandler<T>>((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsContextMenuOpen(false);
  }, []);

  return useMergedObject({
    isContextMenuOpen,
    setIsContextMenuOpen,
    contextMenuPosition,
    openContextMenu,
    handleContextMenuClose,
  });
}
