import { TopBarRightButtons } from '../topbar/TopBarRightButtons';

type AnalyticsDashletTopBarProps = {
  remove: () => void;
  duplicate: () => void;
  fullScreenMode: boolean;
  toggleFullScreenMode: () => void;
};

export function SampleAnalysisTopBar({
  remove,
  duplicate,
  fullScreenMode,
  toggleFullScreenMode,
}: AnalyticsDashletTopBarProps) {
  return (
    <div className="flex flex-row items-center justify-between w-full border-b draggable-dashlet allow-dragging text-sm border-solid gap-2 px-3 border-gray-800 h-12">
      <div className="flex flex-row items-center gap-2">
        <span className="font-bold uppercase whitespace-nowrap">
          Sample Analysis
        </span>
      </div>

      <TopBarRightButtons
        remove={remove}
        duplicate={duplicate}
        fullScreenMode={fullScreenMode}
        toggleFullScreenMode={toggleFullScreenMode}
      />
    </div>
  );
}
