import { LinearProgress } from '@material-ui/core';
import SvgSaveCommit from '../ui/icons/SaveCommit';
import { HasChanges } from '../ui/atoms/HasChanges';

export enum SaveVersionStateEnum {
  LOADING_SHAPES,
  HAS_NO_CHANGES,
  SAVE_IS_NOT_ALLOWED,
  SAVE_IS_ALLOWED,
}

export interface SaveVersionIconProps {
  state: SaveVersionStateEnum;
}

export function SaveVersionIcon({ state }: SaveVersionIconProps): JSX.Element {
  switch (state) {
    case SaveVersionStateEnum.LOADING_SHAPES: {
      return <LoadingShapesIcon />;
    }
    case SaveVersionStateEnum.HAS_NO_CHANGES: {
      return <SvgSaveCommit className=" text-gray-450" />;
    }
    case SaveVersionStateEnum.SAVE_IS_NOT_ALLOWED: {
      return <SvgSaveCommit className=" text-loss-400" />;
    }
    case SaveVersionStateEnum.SAVE_IS_ALLOWED: {
      return <HasChangesIcon />;
    }
    default: {
      console.error('Unknown SaveVersionIcon state', { state });
      return <SvgSaveCommit />;
    }
  }
}
function LoadingShapesIcon(): JSX.Element {
  return (
    <div className="relative">
      <SvgSaveCommit className="" />
      <div className=" absolute -bottom-1 inset-x-0 w-6">
        <LinearProgress />
      </div>
    </div>
  );
}

function HasChangesIcon(): JSX.Element {
  return (
    <HasChanges hasChanges={true}>
      <SvgSaveCommit className="" />
    </HasChanges>
  );
}
