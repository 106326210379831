import { DebounceSettings, debounce } from 'lodash';
import { useMemo, useRef } from 'react';
import { useMounted } from './useMounted';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDebounce<T extends (...args: any[]) => void>(
  func: T,
  ms = 0,
  options?: DebounceSettings
): T {
  const ref = useRef(func);
  const isMounted = useMounted();
  ref.current = func;
  return useMemo(
    () =>
      debounce(
        (...args) => isMounted.current && ref.current(...args),
        ms,
        options
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ms]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any;
}
