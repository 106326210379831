import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgErrorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="m16.75 5.427-4.162-4.16A.905.905 0 0 0 11.957 1H6.052a.897.897 0 0 0-.623.258L1.258 5.427A.896.896 0 0 0 1 6.049v5.893c0 .24.098.462.258.631l4.162 4.16c.17.17.4.267.632.267h5.896c.24 0 .462-.098.631-.258l4.162-4.16a.878.878 0 0 0 .258-.63V6.048a.851.851 0 0 0-.249-.622Zm-1.52 6.15-3.647 3.645H6.425l-3.646-3.644V6.422l3.646-3.644h5.158l3.647 3.644v5.156Z"
      fill="currentColor"
    />
    <path
      d="M9 12.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM9 5.25a.752.752 0 0 0-.75.75v3.75c0 .412.338.75.75.75s.75-.338.75-.75V6A.752.752 0 0 0 9 5.25Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgErrorIcon;
