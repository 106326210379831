export function getOrSetDefault<K, V>(
  map: Map<K, V>,
  key: K,
  initializerOrDefaultValue: V | (() => V)
): V {
  let val = map.get(key);

  if (!val) {
    val =
      typeof initializerOrDefaultValue === 'function'
        ? (initializerOrDefaultValue as () => V)()
        : initializerOrDefaultValue;
    map.set(key, val);
  }

  return val;
}
