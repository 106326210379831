import { ReactNode } from 'react';
import clsx from 'clsx';

export interface ExternalLinkProps {
  href?: `https://${string}` | '#0';
  className?: string;
  padLeft?: boolean;
  padRight?: boolean;
  children: ReactNode;
}
export function ExternalLink({
  href = '#0',
  children,
  className,
  padLeft = true,
  padRight = true,
}: ExternalLinkProps) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className={clsx(
        'text-primary-500',
        'hover:text-primary-800',
        'visited:text-primary-400/50',
        'outline-none',
        { 'pl-1': padLeft, 'pr-1': padRight },
        className
      )}
    >
      {children}
    </a>
  );
}
