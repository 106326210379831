import clsx from 'clsx';
import { PropsWithChildren, useCallback, useMemo } from 'react';
import { AnalysisIcon, Epoch, LastIcon } from '../icons';
import { ModelChip } from '../molecules/ModelChip';
import { SelectedSessionRun } from '../molecules/useModelFilter';
import { PlaceholderChip } from '../atoms/PlaceholderChip';
import { IconButton, Tooltip } from '@material-ui/core';
import { ClassNameProp } from '../../core/types';
import { Select } from '../../ui/atoms/Select';

export type SessionRunSummaryData = {
  sessionRun: SelectedSessionRun;
  dataset: string;
  epoch: {
    best?: number;
    max: number;
    min: number;
    selected: number;
  };
};

export type OnEpochChange = (modelId: string, epoch: number) => void;
export type TabSessionRunSummaryProps = SessionRunSummaryData & {
  onEpochChange: OnEpochChange;
  className?: string;
};

export function TabModelSummary({
  onEpochChange,
  sessionRun,
  epoch,
  className,
  children,
}: PropsWithChildren<TabSessionRunSummaryProps>) {
  const handleEpochChange = useCallback(
    (value?: string) => onEpochChange(sessionRun.id, Number(value || 0)),
    [onEpochChange, sessionRun.id]
  );

  const handleSelectMaxEpoch = useCallback(
    () => onEpochChange(sessionRun.id, epoch.max),
    [onEpochChange, sessionRun.id, epoch.max]
  );
  const epochRange = useMemo(() => {
    const list = [];
    for (let i = epoch.min; i <= epoch.max; i++) {
      list.push(i);
    }
    return list;
  }, [epoch]);

  return (
    <div className={clsx('flex gap-4 py-4', className)}>
      {children}
      <div className="flex flex-1 flex-col gap-4 justify-center">
        <div className="flex items-center gap-2">
          <AnalysisIcon />
          <ModelChip {...sessionRun} hideInvisibilityIcon />
        </div>

        <div className="flex items-center gap-2">
          <Epoch />
          <Select
            small
            label="Epoch"
            className="w-20"
            options={epochRange}
            optionToLabel={(epoch) => String(epoch)}
            value={epoch.selected}
            onChange={handleEpochChange}
          />
          <Tooltip title={`Select last epoch (${epoch.max})`}>
            <span>
              <IconButton
                disabled={epoch.max === epoch.selected}
                onClick={handleSelectMaxEpoch}
              >
                <LastIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export function ModelSummaryPlaceholder({
  className,
  children,
}: PropsWithChildren<ClassNameProp>) {
  const notAvailable = <span className="text-sm text-gray-500">NA</span>;
  return (
    <div className={clsx('flex gap-4 py-4', className)}>
      {children}
      <div className="flex flex-1 flex-col gap-4 justify-center">
        <div className="flex items-center gap-2">
          <AnalysisIcon />
          <PlaceholderChip>SELECT MODELS</PlaceholderChip>
        </div>
        <div className="flex items-center gap-2">
          <Epoch />
          {notAvailable}
        </div>
      </div>
    </div>
  );
}
