import api from '../api-client';
import useSWR, { KeyedMutator } from 'swr';
import { CacheKey } from './consts';
import { SlimUserData, UserRole } from '@tensorleap/api-client';
import {
  TeamMemberData,
  USER_ROLE_TO_ROLE_NAME,
} from '../../team-management/TeamManagementTypes';
import { useMergedObject } from '../useMergedObject';

export interface UseFetchAllSlimUsers {
  slimUsers: SlimUserData[] | undefined;
  teamData: TeamMemberData[] | undefined;
  error?: Error;
  isLoading: boolean;
  activeUsersCount: number;
  refetch: KeyedMutator<SlimUserData[]>;
}

function mapSlimUserDataToTeamMemberData(
  slimUserData: SlimUserData
): TeamMemberData {
  return {
    cid: slimUserData.cid,
    userName: slimUserData.local.name,
    teamId: slimUserData.local.teamId,
    status: slimUserData.local.activated ? 'Active' : 'Pending',
    role: USER_ROLE_TO_ROLE_NAME[slimUserData.role as UserRole],
    joiningDate: slimUserData.createdAt,
  };
}

function getActiveUsersCount(users: TeamMemberData[] | undefined): number {
  return users ? users.filter((x) => x.status === 'Active').length : 0;
}

export function useTeamUsers(): SlimUserData[] {
  const { data: users } = useSWR<SlimUserData[], Error>(
    CacheKey.USERS,
    async () => {
      const { users } = await api.getTeamSlimUserData();
      return users;
    }
  );
  return users || [];
}

export function UseFetchAllSlimUsers(): UseFetchAllSlimUsers {
  const { data: slimUsers, error, mutate } = useSWR<SlimUserData[], Error>(
    CacheKey.ALL_USERS,
    async () => {
      const { users: slimUsers } = await api.getAllSlimUserData();
      return slimUsers;
    }
  );
  const teamData = slimUsers?.map(mapSlimUserDataToTeamMemberData);
  const activeUsersCount = getActiveUsersCount(teamData);

  return useMergedObject({
    slimUsers,
    teamData,
    error,
    isLoading: !error && !slimUsers,
    activeUsersCount: activeUsersCount,
    refetch: mutate,
  });
}
