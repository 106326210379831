import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgUploadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M17.97 10.926c-.047.938-.434 1.77-1.16 2.496-.704.704-1.665 1.055-2.883 1.055h-1.829a.84.84 0 0 1-.562-.21.84.84 0 0 1-.21-.563c0-.211.07-.387.21-.528a.84.84 0 0 1 .563-.21 35.576 35.576 0 0 1 1.828-.036c.75 0 1.336-.21 1.758-.633.445-.421.69-.925.738-1.511.047-.586-.13-1.114-.528-1.582-.375-.493-.96-.797-1.757-.914a.948.948 0 0 1-.528-.317.84.84 0 0 1-.105-.633c.234-.984.023-1.64-.633-1.968-.234-.164-.55-.223-.95-.176-.374.047-.714.222-1.019.527a.714.714 0 0 1-.668.211.876.876 0 0 1-.597-.387c-.352-.68-.786-1.183-1.301-1.511-.492-.328-.961-.48-1.406-.457a4.173 4.173 0 0 0-1.301.28c-.633.282-1.078.727-1.336 1.337-.258.586-.246 1.3.035 2.144.094.258.082.47-.035.633a.74.74 0 0 1-.457.422c-.727.211-1.278.527-1.653.95-.375.398-.585.737-.632 1.019a2.14 2.14 0 0 0 0 .773c.07.469.27.88.597 1.23.352.352.785.528 1.301.528h2.426c.21 0 .387.082.527.246a.774.774 0 0 1 .246.563c0 .21-.082.41-.246.597a.666.666 0 0 1-.527.246l-2.426-.07a3.326 3.326 0 0 1-2.285-.879 3.704 3.704 0 0 1-1.125-2.25c-.117-.773.023-1.547.422-2.32C.86 8.23 1.575 7.61 2.606 7.165c-.257-1.032-.152-1.97.317-2.813.469-.867 1.136-1.5 2.004-1.898.797-.47 1.746-.575 2.847-.317 1.125.235 2.051.867 2.778 1.899C11.63 3.473 12.66 3.46 13.645 4c1.079.656 1.57 1.628 1.477 2.917.984.305 1.71.844 2.18 1.618.492.75.715 1.546.668 2.39Zm-6.258.633c.21.164.398.246.562.246.164 0 .352-.082.563-.246.375-.375.375-.75 0-1.125L9.532 7.13c-.14-.14-.328-.21-.562-.21-.235 0-.422.07-.563.21l-3.305 3.34c-.375.375-.375.75 0 1.125.375.328.75.328 1.125 0l1.97-2.039v7.313c0 .234.07.433.21.597.164.141.363.211.598.211.21 0 .387-.07.527-.21a.813.813 0 0 0 .246-.598L9.743 9.59l1.969 1.97Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgUploadIcon;
