import { useMemo } from 'react';
import useSWR, { KeyedMutator } from 'swr';
import { FilterEnum, FilterFieldMeta } from '../../filters/helpers';
import api from '../api-client';
import { CacheKey, REFRESH_INTERVALS } from './consts';
import { VisualizationFilter } from '../types/filters';
import { mapToEsFilters } from '../../model-tests/modelTestHelpers';

export type DashletFields = {
  notActiveFields: string[];
  aggregatableFields: string[];
  numericFields: string[];
};

export interface UseFetchDashletFields {
  dashletFields: DashletFields | undefined;
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<DashletFields>;
}
export function useFetchDashletFields(
  projectId: string,
  sessionRunIds: string[]
): UseFetchDashletFields {
  const sessionIds = useMemo(() => sessionRunIds.join(','), [sessionRunIds]);
  const fetcheKey = `${CacheKey.DASHLET_FIELDS}-${projectId}-${sessionIds}`;
  const { data: dashletFields, error, mutate } = useSWR(
    fetcheKey,
    async () => {
      const projectField = await api.getDashletFields({
        projectId,
        sessionRunIds: [],
      });
      let notActiveFields: string[] = [];

      if (sessionRunIds.length) {
        const sessionFields = await api.getDashletFields({
          projectId,
          sessionRunIds,
        });
        const selectedSessionAggregatableFieldsSet = new Set(
          sessionFields.aggregatableFields
        );
        const selectedSessionNumericFieldsSet = new Set(
          sessionFields.numericFields
        );

        const allFields = [
          ...projectField.aggregatableFields,
          ...projectField.numericFields,
        ];

        notActiveFields = allFields.filter(
          (field) =>
            !selectedSessionAggregatableFieldsSet.has(field) &&
            !selectedSessionNumericFieldsSet.has(field)
        );
      }

      return {
        aggregatableFields: projectField.aggregatableFields,
        numericFields: projectField.numericFields,
        notActiveFields,
      };
    },
    { refreshInterval: REFRESH_INTERVALS.dashletFields }
  );

  return useMemo(
    () => ({
      dashletFields,
      error,
      isLoading: !error && !dashletFields,
      refetch: mutate,
    }),
    [error, dashletFields, mutate]
  );
}

export type FetchDashboardFilterFieldsMetaProps = {
  projectId: string;
  filters: VisualizationFilter[];
  sessionRunIds: string[];
};

const NUMERIC_FIELDS_EXCLUDE = new Set(['batch', 'epoch']);

export function useFetchDashboardFilterFieldsMeta({
  projectId,
  filters,
  sessionRunIds,
}: FetchDashboardFilterFieldsMetaProps): FilterFieldMeta[] {
  const { dashletFields } = useFetchDashletFields(projectId, sessionRunIds);
  return useMemo(() => {
    if (!dashletFields) return [];
    const {
      aggregatableFields,
      numericFields,
      notActiveFields,
    } = dashletFields;
    const esFilters = mapToEsFilters(filters);
    const numericFieldsSet = new Set(numericFields);
    const notActiveFieldsSet = new Set(notActiveFields);

    return aggregatableFields.map((field) => {
      const isNumeric = numericFieldsSet.has(field);
      const notActive = notActiveFieldsSet.has(field);
      if (isNumeric && !NUMERIC_FIELDS_EXCLUDE.has(field)) {
        return {
          field,
          type: 'number',
          notActive,
        };
      }
      const fetchOptionsFunc = async (query: string) => {
        try {
          let numericOrStringQuery = isNumeric ? Number(query) : query;
          if (Number.isNaN(numericOrStringQuery)) {
            numericOrStringQuery = '';
          }
          const result = await api.getFieldsValues({
            projectId,
            fields: [
              {
                field,
                query: numericOrStringQuery,
              },
            ],
            filters: esFilters,
            sessionRunIds,
          });
          const values = result.results[0]?.values;
          return values;
        } catch (e) {
          console.error(e);
          return [];
        }
      };
      if (isNumeric) {
        return {
          field,
          type: 'number',
          enum: fetchOptionsFunc as FilterEnum<number>,
          notActive,
        };
      }
      return {
        field,
        type: 'string',
        enum: fetchOptionsFunc as FilterEnum<string>,
        notActive,
      };
    });
  }, [dashletFields, projectId, filters, sessionRunIds]);
}
