import { TOUR_SELECTORS_ENUM } from '../../tour/ToursConfig';
import { BwArrowIcon } from '../icons';
import { Button } from './Button';

export interface BackButtonProps {
  onClick: () => void;
}

export function BackButton({ onClick }: BackButtonProps) {
  return (
    <div className="flex justify-between">
      <Button
        type="button"
        className="!p-1"
        variant="action-icon"
        onClick={onClick}
        tourId={
          TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_DRAWER_GO_BACK_BUTTON_ID
        }
      >
        <BwArrowIcon className="h-4" />
        Back
      </Button>
    </div>
  );
}
