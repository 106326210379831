import { Node } from '@tensorleap/engine-contract';

export function getLastInboundNode(node: Node) {
  const inboundNodesLen = node.data.inbound_nodes?.length - 1;
  const last = node.data.inbound_nodes?.[inboundNodesLen];
  return Array.isArray(last) ? last[last.length - 1] : last;
}

export function setLastInboundNode<TNode>(node: Node, lastInboundNode: TNode) {
  const nodeData: Node['data'] = JSON.parse(JSON.stringify(node.data));

  const inboundNodesLastIndex = nodeData.inbound_nodes?.length - 1;
  const last = nodeData.inbound_nodes?.[inboundNodesLastIndex];

  if (Array.isArray(last)) {
    nodeData.inbound_nodes = nodeData.inbound_nodes.map(() => ({
      ...lastInboundNode,
    }));
  } else if (last) {
    nodeData.inbound_nodes[inboundNodesLastIndex] = lastInboundNode;
  }

  return nodeData;
}
