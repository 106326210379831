import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgUserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M9.001 5.333a2.667 2.667 0 1 0 0 5.334 2.667 2.667 0 0 0 0-5.334ZM5.001 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
      fill="currentColor"
    />
    <path
      d="M5.084 14.667h7.835C12.554 13.202 11.012 12 9 12c-2.01 0-3.552 1.202-3.917 2.667Zm-1.416.666c0-2.67 2.488-4.666 5.333-4.666 2.846 0 5.334 1.996 5.334 4.666a.667.667 0 0 1-.667.667H4.335a.666.666 0 0 1-.667-.667Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgUserIcon;
