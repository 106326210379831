import { PopulationExplorationParams } from '@tensorleap/api-client';
import { calcHash } from '../../../core/calc-hash';

export type PopulationExplorationDigest = {
  populationParams: Omit<PopulationExplorationParams, 'digest' | 'projectId'>;
};

export function calcPopulationExplorationDigest(
  params: PopulationExplorationDigest
): string {
  return calcHash(params);
}
