import clsx from 'clsx';
import { ProjectTabs } from '../ProjectCardTabs';
import { TOUR_SELECTORS_ENUM } from '../../tour/ToursConfig';

export type TabsProps<T extends string | number> = {
  options: { label?: string; value: T; icon?: JSX.Element }[];
  value: T;
  onChange: (_: T) => void;
  className?: string;
  selectedTabClassName?: string;
  selectedTabBgClassName?: string;
  tabClassName?: string;
};
export function CardTabs<T extends string | number>({
  options,
  value,
  onChange,
  className,
  tabClassName,
  selectedTabClassName,
  selectedTabBgClassName,
}: TabsProps<T>) {
  return (
    <div
      className={clsx(
        className,
        'flex flex-row gap-2',
        'border-b-gray-700 border-b'
      )}
    >
      {options.map(({ label, value: tabValue, icon }) => (
        <div
          id={
            tabValue === ProjectTabs.Dashboard
              ? TOUR_SELECTORS_ENUM.DASHBOARD_TAB_ID
              : tabValue === ProjectTabs.Network
              ? TOUR_SELECTORS_ENUM.NETWORK_TAB_ID
              : ''
          }
          onClick={() => onChange(tabValue)}
          key={String(tabValue)}
          className={clsx(
            tabClassName,
            'drop-shadow-[0_-4px_4px_rgba(0,0,0,0.33)]',
            'hover:bg-primary-300 hover:text-primary-700',
            'relative flex flex-row gap-2 items-center px-4',
            'cursor-pointer uppercase',
            'border border-b-0 rounded-t-lg border-gray-700',
            value === tabValue &&
              clsx(
                'bg-red font-bold',
                'border-t-4 border-t-primary-500',
                'after:absolute after:inset-x-0 after:h-px after:-bottom-px',
                selectedTabBgClassName || 'after:bg-gray-800 bg-gray-800',
                selectedTabClassName
              )
          )}
        >
          {icon} {label || tabValue}
        </div>
      ))}
    </div>
  );
}
