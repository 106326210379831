import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import { ReactNode, useCallback } from 'react';
import { Checkbox } from './Checkbox';
import { IconMenu } from './IconMenu';
import {
  defaultLabelMap,
  mapValue,
  OptionDefaultObj,
  OptionType,
} from './utils/select';
import { BulletsListIcon } from '../icons';

export type LabelOption = OptionDefaultObj & { color: string };

export const FILTER_LABEL_ICON = (
  <Tooltip title="Filter labels">
    <div>
      <BulletsListIcon />
    </div>
  </Tooltip>
);

export function renderLabelOption(
  { color }: LabelOption,
  label: ReactNode,
  selected: boolean,
  onToggle: () => void
) {
  return (
    <div
      className={clsx(
        'flex p-2 border-l-4 border-solid cursor-pointer hover:bg-white/20',
        !selected && 'line-through'
      )}
      style={{ borderColor: color }}
      onClick={onToggle}
    >
      <Checkbox onChange={() => undefined} checked={selected} /> {label}
    </div>
  );
}

type RenderOption<T> = (
  _: T,
  label: ReactNode,
  selected: boolean,
  onToggle: () => void
) => ReactNode;

export type MultiSelectIconMenuProps<
  T = OptionType,
  V extends string | number = string | number
> = {
  icon: ReactNode;
  options: T[];
  value?: V[];
  noOptionsText?: ReactNode;
  disabled?: boolean;
  className?: string;
  optionID?: string;
  invertedSelection?: boolean;
  iconWrapperClassName?: string;
  onChange: (value: V[]) => void | Promise<void>;
  optionToLabel?: (_: T) => string;
  renderOption?: RenderOption<T>;
};

const CLICKABLE_TEXT_CLASSES =
  'm-2 underline  text-xs cursor-pointer text-primary-500 hover:text-primary-400';

export function MultiSelectIconMenu<
  T = OptionType,
  V extends string | number = string | number
>({
  value = [],
  options,
  onChange,
  optionID = 'value',
  invertedSelection,
  icon,
  noOptionsText = 'No Options',
  iconWrapperClassName,
  renderOption = defaultRenderOption,
  optionToLabel = defaultLabelMap,
}: MultiSelectIconMenuProps<T, V>) {
  const selectAll = useCallback(
    () => onChange(options.map((o) => mapValue(o, optionID)) as V[]),
    [options, optionID, onChange]
  );
  const clear = useCallback(() => onChange([]), [onChange]);

  return (
    <IconMenu
      paperClassName="mt-12"
      iconWrapperClassName={iconWrapperClassName}
      icon={icon}
    >
      <div className="flex px-2">
        <div
          className={CLICKABLE_TEXT_CLASSES}
          onClick={invertedSelection ? selectAll : clear}
        >
          CLEAR
        </div>
        <div
          className={CLICKABLE_TEXT_CLASSES}
          onClick={invertedSelection ? clear : selectAll}
        >
          SELECT ALL
        </div>
      </div>
      {options.map((o) => {
        const val = mapValue(o, optionID);
        const included = value.includes(val as V);
        const selected = invertedSelection ? !included : included;
        const label = optionToLabel(o);
        return (
          <div key={val}>
            {renderOption(o, label, selected, () =>
              onChange(
                (included
                  ? value.filter((v) => v !== val)
                  : [...value, val]) as V[]
              )
            )}
          </div>
        );
      })}
      {options.length === 0 && (
        <div className="flex justify-center p-2 uppercase text-gray-400">
          {noOptionsText}
        </div>
      )}
    </IconMenu>
  );
}

export function defaultRenderOption<T = OptionType>(
  option: T,
  label: ReactNode,
  selected: boolean,
  onToggle: () => void
) {
  return (
    <div
      className="flex p-2 cursor-pointer hover:bg-white/20"
      onClick={onToggle}
    >
      <Checkbox onChange={() => undefined} checked={selected} /> {label}
    </div>
  );
}
