import { Checkbox } from '@material-ui/core';
import clsx from 'clsx';
import { forwardRef, ReactNode } from 'react';

export type DashboardFlagProps = {
  value: boolean;
  onChange: (value: boolean) => void;
};

export function AddToDashboardFlag({ value, onChange }: DashboardFlagProps) {
  return (
    <Flag
      title="Add to Dashboard"
      subtitle="Display the model’s data on the dashboard"
      value={value}
      onChange={onChange}
    />
  );
}

export function SkipMetricsEstimationFlag({
  value,
  onChange,
}: DashboardFlagProps) {
  return (
    <Flag
      title="Skip Metrics Estimation"
      subtitle="Do not predict the available metrics and loss on validation, test and unlabeled subsets"
      value={value}
      onChange={onChange}
    />
  );
}

export type FlagProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  title: ReactNode;
  className?: string;
  subtitle: ReactNode;
};
export const Flag = forwardRef<HTMLButtonElement, FlagProps>(
  ({ value, onChange, className, title, subtitle }, ref) => {
    return (
      <div className={clsx('flex items-center py-3', className)}>
        <Checkbox
          ref={ref}
          defaultChecked={value}
          value={value}
          color="primary"
          onChange={(e) => onChange(e.target.checked)}
        />
        <div className="flex flex-col px-2">
          <span className="font-normal text-base tracking-normal">{title}</span>
          <span className="font-normal text-xs tracking-normal">
            {subtitle}
          </span>
        </div>
      </div>
    );
  }
);

Flag.displayName = 'Flag';
