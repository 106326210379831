import { PlusMini } from '../ui/icons';
import { IconButton } from '../ui/mui';
import { ModelTestCardsList } from './ModelTestCardsList';
import clsx from 'clsx';
import { UseModelTestStateReturn } from './useModelTestState';
import { ModelTestSummaryList } from './ModelTestSummaryList';
import { Title } from '../ui/atoms/Title';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';

export type ModelTestDrowerContentProps = {
  state: UseModelTestStateReturn;
};

export function ModelTestsDrawerContent({
  state,
}: ModelTestDrowerContentProps): JSX.Element {
  const { tests, testsSummary, testsResult } = state;

  return (
    <div className="flex flex-col bg-transparent w-full h-full overflow-hidden">
      <div className="flex flex-row w-full pb-3 pl-6 pr-3">
        <Title className="flex-1" bottomBorderClassName="border-b-primary-500">
          tests
        </Title>
        <IconButton
          disabled={tests.isLoading}
          className={clsx('w-fit h-fit', tests.isLoading && 'opacity-50')}
          onClick={() => tests.create()}
          id={TOUR_SELECTORS_ENUM.CREATE_NEW_TEST_BUTTON_ID}
        >
          <PlusMini className="text-white" />
        </IconButton>
      </div>
      <ModelTestSummaryList
        onModelEpochChange={testsSummary.updateEpochChange}
        data={testsSummary.data}
        testsCount={tests.data.length}
      />
      <ModelTestCardsList
        isLoading={tests.isLoading}
        tests={tests.data}
        testResults={testsResult.data}
        selectedTestId={tests.selectedTestId}
        editedTestState={tests.editedTestState}
        toggleSelectedTestId={tests.toggleSelectedTestId}
        onDelete={tests.delete}
        onUpdate={tests.update}
        onStartEditable={tests.startEditable}
        onStopEditable={tests.stopEditable}
      />
    </div>
  );
}
