import useSWR, { KeyedMutator } from 'swr';
import { CacheKey } from './consts';
import { FetchJsonBaseProps, REQUEST_INIT_FORCE_CACHE } from './fetch-json';
import { useEffect, useMemo, useState } from 'react';

export interface FetchJsonResponse<T> {
  url: string;
  data?: T;
  error?: Error;
  isLoading: boolean;
}
export interface FetchJsonMultiple<T> {
  data?: FetchJsonResponse<T>[];
  error?: Error;
  refetch: KeyedMutator<FetchJsonResponse<T>[] | undefined>;
}

export type FetchJsonMultipleProps<T> = FetchJsonBaseProps<T> & {
  urls?: Array<RequestInfo | URL>;
};

export function useFetchJsonMultiple<T>({
  urls,
  fetchOptions: init = REQUEST_INIT_FORCE_CACHE,
  key,
  postProcess,
  refreshIntervalMs,
  maxIsLoadingTimeoutMs,
  refreshIntervalAfterSuccessMS,
}: FetchJsonMultipleProps<T>): FetchJsonMultiple<T> {
  const swrKey = useMemo(() => `${CacheKey.FETCH}-${key || urls?.join(',')}`, [
    key,
    urls,
  ]);

  const [foundResult, setFoundResult] = useState(false);
  const [hasTimedOut, setHasTimedOut] = useState(false);

  useEffect(() => {
    if (swrKey) {
      setFoundResult(false);
      setHasTimedOut(false);
    }
  }, [swrKey]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    if (maxIsLoadingTimeoutMs && !hasTimedOut && !foundResult) {
      timer = setTimeout(() => {
        if (!foundResult) {
          setHasTimedOut(true);
        }
      }, maxIsLoadingTimeoutMs);
    }
    if (foundResult && timer) {
      clearTimeout(timer);
      timer = null;
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [maxIsLoadingTimeoutMs, hasTimedOut, swrKey, foundResult]);

  const adjustedRefreshInterval = useMemo(() => {
    if (foundResult && refreshIntervalAfterSuccessMS) {
      if (refreshIntervalAfterSuccessMS === 0) {
        return undefined;
      }
      return refreshIntervalAfterSuccessMS;
    }
    return hasTimedOut ? undefined : refreshIntervalMs;
  }, [
    foundResult,
    hasTimedOut,
    refreshIntervalAfterSuccessMS,
    refreshIntervalMs,
  ]);

  const { data, error, mutate } = useSWR<
    FetchJsonResponse<T>[] | undefined,
    Error
  >(
    swrKey,
    async () => {
      if (!urls || urls.length === 0) {
        return undefined;
      }

      const fetchPromises = urls.map(async (url) => {
        const response = await fetch(url, init);
        if (response.status === 404) {
          throw new Error('File not found');
        }
        const responseData = await response.json();
        return postProcess ? postProcess(responseData) : responseData;
      });

      const responses = await Promise.allSettled<
        PromiseFulfilledResult<unknown>
      >(fetchPromises);

      const transformedResponses: FetchJsonResponse<T>[] = urls.map(
        (url, index) => {
          const response = responses[index];
          let data: T | undefined;
          let responseError: Error | undefined;
          let isLoading = false;

          if (response.status === 'fulfilled') {
            data = response.value as T;
          } else if (
            response.status === 'rejected' &&
            response.reason instanceof Error
          ) {
            responseError = response.reason;
          }

          if (maxIsLoadingTimeoutMs && hasTimedOut) {
            isLoading = false;
          } else if (
            responseError &&
            responseError.message.includes('File not found')
          ) {
            isLoading = true;
          } else {
            isLoading = !responseError && !data;
          }

          return {
            url: url.toString(),
            data,
            error: responseError,
            isLoading,
          };
        }
      );

      const allSuccessful = transformedResponses.every(
        (item) =>
          item.error === undefined && !item.isLoading && item.data !== undefined
      );
      if (allSuccessful) {
        setFoundResult(true);
      }

      return transformedResponses;
    },
    { refreshInterval: adjustedRefreshInterval }
  );

  return {
    data,
    error,
    refetch: mutate,
  };
}
