import { useMemo, useCallback } from 'react';
import { DatasetMask, parseDatasetVersion } from './dataset-helper';
import { DetailsProps } from './NodeDetails';
import { NodeDetailsPanel, NoPreview } from './NodeDetalisPanel';
import { Settings, Visible } from '../ui/icons';
import { Input } from '../ui/atoms/Input';
import { Unreachable } from '../core/Errors';
import { MetricInstance } from '@tensorleap/api-client';
import { Select } from '../ui/atoms/Select';
import { UserUniqueName, USER_UNIQUE_NAME } from './UserUniqueName';
import { useNetworkMapContext } from '../core/NetworkMapContext';

export function MetricDetails({ node }: DetailsProps): JSX.Element {
  const {
    changeNodeProperty,
    updateConnection,
    currentDatasetSetup,
  } = useNetworkMapContext();

  const metricName = node.data.metric_name;
  const metricNames = currentDatasetSetup?.metrics || [];

  const metricDetails = useMemo(() => {
    return parseDatasetVersion(
      DatasetMask.Metric,
      currentDatasetSetup
    ).find(({ title }) => title.endsWith(metricName));
  }, [currentDatasetSetup, metricName]);

  const handleMetricChange = useCallback(
    (name: string | undefined, selectedMetric: MetricInstance | null) => {
      if (!name) throw new Unreachable();
      const { arg_names = [] } = selectedMetric || {};

      changeNodeProperty({
        nodeId: node.id,
        nodeDataPropsToUpdate: {
          name,
          metric_name: name,
          arg_names,
          [USER_UNIQUE_NAME]: name,
        },
      });

      updateConnection(node.id, arg_names);
    },
    [changeNodeProperty, node.id, updateConnection]
  );

  return (
    <div className="flex flex-col overflow-auto">
      <NodeDetailsPanel
        title="Properties"
        className="p-4 gap-8"
        icon={<Settings />}
        openByDefault
      >
        <Select
          label="SELECTED METRIC"
          optionID="name"
          options={metricNames}
          onChange={handleMetricChange}
          value={metricName}
        />
        <UserUniqueName node={node} />
      </NodeDetailsPanel>

      <NodeDetailsPanel
        title="Preview"
        className="p-4 gap-8"
        icon={<Visible />}
        openByDefault
      >
        {metricDetails?.subItems.length ? (
          metricDetails.subItems.map(({ title, value }) => (
            <Input key={title} label={title} value={value} readOnly />
          ))
        ) : (
          <NoPreview />
        )}
      </NodeDetailsPanel>
    </div>
  );
}
