import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgGroundTruthIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <g filter="url(#ground-truth-icon_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 9.75a6.75 6.75 0 0 0-13.5 0c0 2.769 2.215 6.378 6.75 10.701 4.535-4.323 6.75-7.932 6.75-10.701Zm-12 12.75h10.5c.5 0 .75-.25.75-.75s-.25-.75-.75-.75h-3.66c4.44-4.355 6.66-8.106 6.66-11.25a8.25 8.25 0 1 0-16.5 0c0 3.144 2.22 6.895 6.66 11.25H6.75c-.5 0-.75.25-.75.75s.25.75.75.75Zm3.871-9.03a.75.75 0 0 0 .616.308l.022-.022a.69.69 0 0 0 .616-.352l3.85-6.16c.11-.213.14-.414.088-.605a.759.759 0 0 0-.33-.451c-.212-.11-.414-.14-.605-.088a.758.758 0 0 0-.45.33l-3.257 5.214L9.873 9.95a.81.81 0 0 0-.517-.308c-.322-.044-.568.08-.737.374-.168.286-.161.572.022.858l1.98 2.596Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="ground-truth-icon_svg__a"
        x={3.75}
        y={1.5}
        width={16.5}
        height={22}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_12056_117382"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_12056_117382"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SvgGroundTruthIcon;
