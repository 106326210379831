import { Fragment } from 'react';
import { Tab } from '@headlessui/react';
import { SupportMenuButton } from './SupportMenuButton';
import { EmailIcon } from '../icons';
import { FlatCountdown } from '../molecules/FlatCountdown';
import { CalendarSupportWidget } from './CalendarSupportWidget';
import { Button } from '../atoms/Button';
import ZoomIcon from '../icons/ZoomIcon';
import { ExternalLink } from '../../trial/ExternalLink';
import { useFreemium } from '../../trial/useFreemium';
import { ReasonSelectionContactWidget } from './ReasonSelectionContactWidget';
import { Setter, StateObject } from '../../core/types';
import { PopupPaneTitle } from '../molecules/PopupPane';

export interface UpgradeFreemiumDialogContentProps {
  openState: StateObject;
  selectedIndex?: number;
  className?: string;
  setExtensionTokenIsOpen: Setter<boolean>;
}
export function UpgradeFreemiumDialogContent({
  selectedIndex = 0,
  setExtensionTokenIsOpen,
}: UpgradeFreemiumDialogContentProps) {
  const { daysLeft, freemiumUserData } = useFreemium();
  const freemiumEnded = daysLeft <= 0;
  return (
    <>
      <div className="flex justify-between items-center h-1/6 w-full">
        <PopupPaneTitle className="capitalize">
          hey{freemiumUserData.fullName ? ` ${freemiumUserData.fullName}` : ''},
        </PopupPaneTitle>
        <FlatCountdown />
      </div>
      {freemiumEnded ? (
        <p className="pt-2 h-1/6 w-full">
          The trial period has ended. We hope you found this demo useful and
          interesting to your DNN dev activities. You are welcome to contact us
          to upgrade to the full version, or should you need more time to
          continue your product evaluation, please contact us to extend the demo
          period.
        </p>
      ) : (
        <p className="pt-2 h-1/6 w-full">
          During the demo period, should you need help (beyond our
          <ExternalLink href="https://docs.tensorleap.ai/">
            documentation
          </ExternalLink>
          ), please contact our support team, at no cost. You can also request
          to upgrade and even extend the demo period.
        </p>
      )}
      <Tab.Group
        selectedIndex={selectedIndex}
        as="div"
        vertical
        className="flex flex-row w-full h-4/6 gap-2"
      >
        <div className="h-full w-2/5 flex flex-col">
          <Tab.List className="flex flex-col gap-1.5 h-auto pb-4">
            <Tab as={Fragment}>
              {({ selected }) => (
                <SupportMenuButton
                  selected={selected}
                  icon={<ZoomIcon className="w-1/3 h-1/3" />}
                >
                  book a zoom session
                </SupportMenuButton>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <SupportMenuButton
                  selected={selected}
                  icon={<EmailIcon className="w-1/3 h-1/3" />}
                >
                  send a message
                </SupportMenuButton>
              )}
            </Tab>
          </Tab.List>
          <Button
            variant="text"
            className="border-solid border border-primary-500 py-1.5 px-1 w-72 mt-auto"
            onClick={() => setExtensionTokenIsOpen(true)}
          >
            insert time extension code
          </Button>
        </div>
        <div className="flex h-full w-3/5">
          <Tab.Panels className="w-full h-full">
            <Tab.Panel className="w-full h-full">
              <CalendarSupportWidget />
            </Tab.Panel>
            <Tab.Panel className="w-full h-full">
              <ReasonSelectionContactWidget />
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </>
  );
}
