import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgHorizontalBarsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.667 1.333H3.333a2 2 0 0 0-2 2v21.334a2 2 0 0 0 2 2h21.334a2 2 0 0 0 2-2V3.333a2 2 0 0 0-2-2ZM3.333 0A3.333 3.333 0 0 0 0 3.333v21.334A3.333 3.333 0 0 0 3.333 28h21.334A3.333 3.333 0 0 0 28 24.667V3.333A3.333 3.333 0 0 0 24.667 0H3.333Z"
      fill="currentColor"
    />
    <rect x={6} y={5} width={8} height={2} rx={1} fill="currentColor" />
    <rect x={6} y={9} width={17} height={2} rx={1} fill="currentColor" />
    <rect x={6} y={13} width={14} height={2} rx={1} fill="currentColor" />
    <rect x={6} y={17} width={17} height={2} rx={1} fill="currentColor" />
    <rect x={6} y={21} width={11} height={2} rx={1} fill="currentColor" />
  </svg>
);

export default SvgHorizontalBarsIcon;
