import React from 'react';
import { ColorIcon, PreviewTip2Icon, SizeIcon } from '../../../ui/icons';
import { SimpleAutocomplete } from '../../../ui/molecules/SimpleAutocomplete';
import { NONE_OPTION } from '../../useScatterMapData';
import { ScatterViewSetting } from './hooks';
import {
  VisPayloadType,
  getMetaDataPreviewValue,
  getVisualizationPreviewValue,
  isMetaDataPreview,
  isVisualizationPreview,
} from '../VisualizationDisplay/visDataHelpers';
import { TOUR_SELECTORS_ENUM } from '../../../tour/ToursConfig';

export interface NoneOptionType {
  label: string;
  value: undefined;
}
const noneOption = { value: undefined, label: NONE_OPTION };

export interface ScatterSettingsProps {
  viewSettings: ScatterViewSetting;
}

const optionToLabel = (option: ScatterSettingsOption) => {
  if (typeof option === 'string') {
    if (isMetaDataPreview(option)) return getMetaDataPreviewValue(option);
    if (isVisualizationPreview(option))
      return getVisualizationPreviewValue(option);
  }
  return NONE_OPTION;
};

const groupBy = (option: ScatterSettingsOption) => {
  if (typeof option === 'string') {
    if (isMetaDataPreview(option)) return VisPayloadType.Metadata;
    if (isVisualizationPreview(option)) return VisPayloadType.Visualization;
  }
  return NONE_OPTION;
};
export type ScatterSettingsOption = string | NoneOptionType | null | undefined;

export const ScatterSettings = React.memo<ScatterSettingsProps>(
  ({ viewSettings }) => {
    const { dotColor, previewBy, sizeOrShape } = viewSettings;

    return (
      <>
        <SimpleAutocomplete<ScatterSettingsOption>
          title="Preview on hover"
          icon={<PreviewTip2Icon className="w-5 h-5" />}
          options={[noneOption, ...previewBy.options]}
          value={previewBy.value || undefined}
          onChange={previewBy.setOption}
          optionToLabel={optionToLabel}
          groupBy={groupBy}
          tourId={
            TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_PREVIEW_ON_HOVER_ID
          }
        />
        <SimpleAutocomplete
          title="Color By"
          icon={<ColorIcon />}
          options={[noneOption, ...dotColor.options]}
          value={dotColor.value}
          onChange={(v) => dotColor.setOption(v as string)}
          tourId={TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_COLOR_BY_ID}
        />
        <SimpleAutocomplete
          title="Size / Shape"
          icon={<SizeIcon />}
          options={[noneOption, ...sizeOrShape.options]}
          value={sizeOrShape.value}
          onChange={(v) => sizeOrShape.setOption(v as string)}
          tourId={TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_SIZE_BY_ID}
        />
      </>
    );
  }
);

ScatterSettings.displayName = 'ScatterSettings';
