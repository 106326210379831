const UNGZIP_WORKER = new Worker(
  new URL('./ungzip.worker.ts', import.meta.url),
  { type: 'module' }
);

export async function ungzip(targz: File): Promise<File[]> {
  const targzBuffer = await targz.arrayBuffer();

  return new Promise((resolve, reject) => {
    UNGZIP_WORKER.addEventListener('error', reject, { once: true });
    UNGZIP_WORKER.addEventListener(
      'message',
      ({
        data: fileBuffers,
      }: MessageEvent<{ u8buffer: ArrayBufferLike; name: string }[]>): void => {
        const files = fileBuffers.map(({ u8buffer, name }) => {
          return new File([u8buffer], name, { type: 'text/x-python' });
        });
        resolve(files);
      },
      { once: true }
    );

    UNGZIP_WORKER.postMessage(targzBuffer, { transfer: [targzBuffer] });
  });
}
