import { NumberOrString } from '@tensorleap/api-client';
import { useCallback, useMemo } from 'react';
import { TooltipProps } from 'recharts';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { Divider } from '../../../dashboard/Divider';
import { TooltipParams } from '../visualizers/ChartBlocks/TooltipProps';
import { ChartRequestData } from './interfaces';
import { CHART_META_FIELDS_KEY } from './constants';

export type CustomLineTooltipProps = TooltipProps<string, string> &
  TooltipParams;

type TooltipValues = {
  xFieldName: string;
  xFieldValue: string;
  data: NumberOrString[][];
};

function useTooltipValues(
  rechartPointList: Payload<string, string>[],
  chartRequestData: ChartRequestData
): TooltipValues | undefined {
  return useMemo(() => {
    if (!rechartPointList?.[0]?.payload) return;
    const { xField, yField } = chartRequestData;

    const labels: NumberOrString[] = [''];
    const yCol: NumberOrString[] = [yField];
    const metadata: Record<string, NumberOrString[]> = {};
    rechartPointList.forEach(({ dataKey = '', payload }) => {
      if (!dataKey || dataKey === CHART_META_FIELDS_KEY) {
        return;
      }
      labels.push(dataKey);

      const yValue = payload[dataKey];
      yCol.push(yValue);

      if (rechartPointList[0]?.payload[CHART_META_FIELDS_KEY]) {
        const metaPoints = Object.keys(
          rechartPointList[0].payload[CHART_META_FIELDS_KEY] ||
            ({} as Record<string, NumberOrString>)
        );
        metaPoints.forEach((key) => {
          metadata[key] = metadata[key] || [key];
          metadata[key].push(payload[CHART_META_FIELDS_KEY][key]);
        });
      }
    });

    const columns = [labels, yCol, ...Object.values(metadata)];
    return {
      xFieldName: xField,
      xFieldValue: rechartPointList[0].payload[xField],
      data: columns,
    };
  }, [rechartPointList, chartRequestData]);
}

export function CustomTooltip({
  payload: rechartPointList = [],
  active,
  colorMap,
  chartRequestData,
  mapValue,
}: CustomLineTooltipProps) {
  const getColor = useCallback(
    (label: NumberOrString) => {
      return colorMap[label || ''];
    },
    [colorMap]
  );
  const tooltipHasMultipleRows = useCallback((tooltipValues: TooltipValues) => {
    //First row are headers, second row is the first data row, third row signifies there is more than one data row
    const minMultipleRows = 2;
    return tooltipValues.data[1]?.length > minMultipleRows;
  }, []);

  const tooltipValues = useTooltipValues(rechartPointList, chartRequestData);

  if (!active || !tooltipValues) {
    return null;
  }
  return (
    <div
      className="custom-tooltip flex flex-col bg-gray-800 border-2 border-gray-500 rounded-xl justify-center items-start w-fit"
      key={`${tooltipValues.xFieldName}_${tooltipValues.xFieldValue}`}
    >
      <span className="px-4 pt-3 pb-1 capitalize gap-6">
        {tooltipValues.xFieldName}
        {' : '}
        {mapValue(tooltipValues.xFieldValue)}
      </span>
      <Divider />
      <div className="flex flex-row pr-4 pt-1 pb-2 ">
        {tooltipValues.data.map((col, colIndex) => (
          <div key={colIndex} className="flex flex-col text-ml ml-4 gap-2">
            {col.map(
              (row, rowIndex) =>
                (tooltipHasMultipleRows(tooltipValues) || rowIndex !== 0) && (
                  <span style={{ color: getColor(row) }} className="capitalize">
                    {row ? (
                      mapValue(row)
                    ) : (
                      <span className="opacity-0">none</span>
                    )}
                  </span>
                )
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
