import { Issue, IssueStatus, UpdateIssueParams } from '@tensorleap/api-client';
import { useCallback, useState } from 'react';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { Setter } from '../core/types';
import useAsyncEffect from '../core/useAsyncEffect';
import api from '../core/api-client';
import { useFetchIssues } from '../core/data-fetching/issues';
import { KeyedMutator } from 'swr';

export type UseIssuesState = {
  issues: Issue[];
  selectedIssue: Issue | undefined;
  setSelectedIssue: Setter<Issue | undefined>;
  createNewIssue: () => Promise<void>;
  refetch: KeyedMutator<Issue[]>;
  updateIssue: (updateIssueParams: UpdateIssueParams) => Promise<void>;
  deleteSelectedIssue: () => Promise<void>;
  isExpanded: boolean;
  setIsExpanded: Setter<boolean>;
  isEditable: boolean;
  setIsEditable: Setter<boolean>;
};

export function useIssuesState(): UseIssuesState {
  const { fetchValidProjectCid } = useCurrentProject();
  const projectId = fetchValidProjectCid();
  const { issues, refetch } = useFetchIssues(projectId);

  const [selectedIssue, setSelectedIssue] = useState<Issue | undefined>(
    undefined
  );

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);

  useAsyncEffect(async () => {
    refetch();

    if (!issues?.length && selectedIssue) {
      setSelectedIssue(undefined);
    }
  }, [selectedIssue]);

  const createNewIssue = useCallback(async () => {
    const newIssue = await api.addIssue({
      title: 'Untitled Issue',
      projectId,
      status: 'Open' as IssueStatus,
    });
    await refetch();
    setSelectedIssue(newIssue);
  }, [projectId, refetch]);

  const updateIssue = useCallback(
    async (updateIssueParams: UpdateIssueParams) => {
      const updatedIssue = await api.updateIssue(updateIssueParams);
      await refetch();
      setSelectedIssue(updatedIssue);
    },
    [refetch]
  );

  const deleteSelectedIssue = useCallback(async () => {
    if (!selectedIssue?.cid) {
      console.error('Issue not selected, should not happen');
      return;
    }

    await api.deleteIssue({
      cid: selectedIssue.cid,
      projectId,
    });
    setSelectedIssue(undefined);
  }, [projectId, selectedIssue?.cid]);

  return {
    issues: issues || [],
    selectedIssue,
    setSelectedIssue,
    createNewIssue,
    refetch,
    updateIssue,
    deleteSelectedIssue,
    isExpanded,
    setIsExpanded,
    isEditable,
    setIsEditable,
  };
}
