import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgLogIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M14.582 3.453H3.238V10H1.91V3.453c0-.364.13-.677.39-.937.261-.26.574-.391.938-.391h11.344c.365 0 .677.13.938.39.26.261.39.574.39.938v11.344c0 .364-.13.677-.39.937s-.573.391-.938.391H8v-1.328h6.582V3.453Z"
      fill="currentColor"
    />
    <path
      d="M3.238 14.797h11.344V8.25h1.328v6.547c0 .364-.13.677-.39.937s-.573.391-.938.391H3.238c-.364 0-.677-.13-.937-.39-.26-.261-.39-.573-.39-.938V3.453c0-.364.13-.677.39-.937s.573-.391.937-.391H9.82v1.328H3.238v11.344Z"
      fill="currentColor"
    />
    <path
      d="M6.39 12.5H4.5v.96h1.89v-.96ZM6.39 10.5H4.5v.96h1.89v-.96ZM6.39 4.5H4.5v.96h1.89V4.5ZM12.4 4.5H8v1h4.4v-1ZM13.5 6.5H8v1h5.5v-1ZM11.3 8.5H8v1h3.3v-1ZM12.4 10.5H8v1h4.4v-1ZM11.3 12.5H8v1h3.3v-1ZM6.39 8.5H4.5v.96h1.89V8.5ZM6.39 6.5H4.5v.96h1.89V6.5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLogIcon;
