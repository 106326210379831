import { RefObject, useEffect } from 'react';

export interface UseOutSideClickParams<T extends HTMLElement = HTMLElement> {
  refs?: RefObject<T>[];
  allowUnrenderedRefs?: RefObject<T>[];
  func: (event?: MouseEvent) => void;
}
export function useOutsideClick<T extends HTMLElement = HTMLElement>({
  refs = [],
  allowUnrenderedRefs = [],
  func,
}: UseOutSideClickParams<T>) {
  useEffect(() => {
    function handleOutsideClick(event: MouseEvent) {
      const eventTarget = event.target as Node | null;
      if (
        refs.every(
          (ref) => !!ref.current && !ref.current.contains(eventTarget)
        ) &&
        allowUnrenderedRefs.every((ref) => !ref.current?.contains(eventTarget))
      ) {
        func(event);
      }
    }
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [func, allowUnrenderedRefs, refs]);
}
