import { FilterOperatorType } from '@tensorleap/api-client';
import {
  BTWVisualizationFilter,
  NotBTWVisualizationFilter,
  EQVisualizationFilter,
  NEVisualizationFilter,
  VisualizationFilter,
} from '../core/types/filters';

export type FilterEnum<Type> = Type[] | ((query: string) => Promise<Type[]>);

export type FilterFieldMeta =
  | {
      field: string;
      type: 'number';
      enum?: FilterEnum<number>;
      notActive?: boolean;
    }
  | {
      field: string;
      type: 'string';
      enum?: FilterEnum<string>;
      notActive?: boolean;
    };
export type EqualOrNotEqualFilterType =
  | EQVisualizationFilter
  | NEVisualizationFilter;
export type BetweenOrNotBetweenFilterType =
  | BTWVisualizationFilter
  | NotBTWVisualizationFilter;

export const EQUAL_OR_NOT_EQUAL_SET = new Set<FilterOperatorType>([
  FilterOperatorType.Equal,
  FilterOperatorType.NotEqual,
]);

export const BETWEEN_OR_NOT_BETWEEN_SET = new Set<FilterOperatorType>([
  FilterOperatorType.Between,
  FilterOperatorType.NotBetween,
]);

export const OP_TO_LABEL: Record<FilterOperatorType, string> = {
  equal: 'Equals to',
  'not-equal': 'Not equals to',
  between: 'Between',
  'not-between': 'Not Between',
  in: 'In',
  'greater-than': 'Greater Than',
  'less-than': 'Less Than',
  cluster: 'Cluster',
};

export const NUM_FIELDS_ALLOWED_OPS: FilterOperatorType[] = [
  FilterOperatorType.Equal,
  FilterOperatorType.NotEqual,
  FilterOperatorType.In,
  FilterOperatorType.Between,
  FilterOperatorType.NotBetween,
];

export const AGG_FIELDS_ALLOWED_OPS: FilterOperatorType[] = [
  FilterOperatorType.Equal,
  FilterOperatorType.NotEqual,
  FilterOperatorType.In,
];

export function isNumericArray(values: unknown[]): boolean {
  return values
    .filter(isNotEmptyString)
    .every((value) => !isNaN(value as number));
}
const EMPTY_STRING_REG = /^\s*$/;
export function isNotEmptyString(value: unknown) {
  return typeof value !== 'string' || !EMPTY_STRING_REG.test(value);
}

export function mapFieldValue(
  value: VisualizationFilter['value'],
  { type }: FilterFieldMeta
) {
  if (!Array.isArray(value)) {
    return value;
  }
  if (type === 'string') {
    const withOutEmptyLines = (value as string[])
      .filter(isNotEmptyString)
      .map((line) => line.trim());
    return withOutEmptyLines;
  }
  const convertedToNumbers = (value as string[])
    .filter(isNotEmptyString)
    .map((line) => Number(line));

  return convertedToNumbers;
}
