import { useScatterData } from '../ScatterDataContext';
import { LabelsScatterLegend } from './LabelsScatterLegend';
import { RangeScatterLegend } from './RangeScatterLegend';
import { ShapesLegend } from './ShapeLegend';

export function ScatterLegend(): JSX.Element {
  const {
    scatterData,
    settings: { dotColor, sizeOrShape },
  } = useScatterData();
  const showSizeOrShape =
    sizeOrShape !== undefined &&
    scatterData.metadata[sizeOrShape].type !== 'range';
  const showRangeColorLegend =
    dotColor !== undefined && scatterData.metadata[dotColor].type === 'range';
  const showLabelsColorLegend =
    dotColor !== undefined && scatterData.metadata[dotColor].type !== 'range';
  return (
    <div className="flex flex-col h-full gap-2 overflow-auto">
      {showRangeColorLegend && <RangeScatterLegend />}
      {showLabelsColorLegend && <LabelsScatterLegend />}
      {showSizeOrShape && <ShapesLegend />}
    </div>
  );
}
