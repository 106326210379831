export const testIds = {
  editor: {
    renameFileInput: 'editor-rename-file-input',
    renameFileButton: 'editor-rename-file-button',
    fileNameRow: 'editor-file-name-row',
    addFileButton: 'editor-add-file-button',
  },
  codeIntegration: {
    addButton: 'code-integration-add-button',
    parseButton: 'code-integration-parse-button',
  },
  appBar: {
    menuButton: 'header-menu-button',
  },
} as const;
