import clsx from 'clsx';
import { PropsWithChildren, useState, useMemo, useCallback } from 'react';
import { ViIcon } from './icons';
import { Tooltip } from '@material-ui/core';

interface StateToggleProps {
  onClick: () => void;
  completed: boolean;
  tooltipTitle?: string;
  dislayVOnHover?: boolean;
}
export function StateToggleVi({
  onClick,
  completed,
  children,
  tooltipTitle = '',
  dislayVOnHover = true,
}: PropsWithChildren<StateToggleProps>) {
  const [mouseIsOverState, setMouseIsOverState] = useState(false);
  const [mouseWasClicked, setMouseWasClicked] = useState(false);
  const displayIndex = useMemo(
    () =>
      (!completed && !mouseIsOverState) ||
      (mouseIsOverState &&
        ((completed && !mouseWasClicked) || (!completed && mouseWasClicked))),
    [mouseIsOverState, mouseWasClicked, completed]
  );

  const onClickEvent = useCallback(() => {
    setMouseWasClicked(true);
    onClick();
  }, [onClick]);
  const onMouseEnter = useCallback(() => {
    if (dislayVOnHover) setMouseIsOverState(true);
  }, [dislayVOnHover]);
  const onMouseLeave = useCallback(() => {
    setMouseIsOverState(false);
    setMouseWasClicked(false);
  }, []);

  return (
    <Tooltip title={tooltipTitle}>
      <div
        className="flex w-5 h-5 justify-center items-center border border-gray-350 rounded-full m-0 p-0 cursor-pointer"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClickEvent}
      >
        {displayIndex ? (
          children
        ) : (
          <ViIcon
            className={clsx(
              'h-4 w-4',
              completed && 'bg-success-400 rounded-xl'
            )}
          />
        )}
      </div>
    </Tooltip>
  );
}
