import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgHeatMapIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M6.605 2c-2.05 0-3.664.61-4.84 1.832C.588 5.053 0 6.665 0 8.667v6.666c0 2.12.563 3.758 1.69 4.911C2.831 21.414 4.47 22 6.604 22h10.79c2.202 0 3.849-.577 4.941-1.73 1.11-1.154 1.664-2.8 1.664-4.937V8.667c0-2.104-.58-3.74-1.74-4.911C21.1 2.586 19.48 2 17.395 2H6.605ZM6 3.832h12c1.378 0 2.42.365 3.126 1.094.723.73 1.084 1.773 1.084 3.13v7.888c0 1.391-.361 2.451-1.084 3.18-.706.713-1.748 1.07-3.126 1.07H6c-1.361 0-2.403-.365-3.126-1.095-.723-.73-1.084-1.78-1.084-3.155V7.98c0-1.324.361-2.341 1.084-3.054.74-.73 1.781-1.094 3.126-1.094Z"
      fill="currentColor"
    />
    <path
      d="M5.6 6h12.8c.442 0 .818.16 1.13.482.313.318.47.703.47 1.154v8.728c0 .451-.157.836-.47 1.154A1.515 1.515 0 0 1 18.4 18H5.6c-.442 0-.82-.16-1.133-.482A1.591 1.591 0 0 1 4 16.364V7.636c0-.451.156-.836.467-1.154A1.528 1.528 0 0 1 5.6 6Zm5.867 10.91v-4.364h-6.4v3.818c0 .15.051.278.154.383a.512.512 0 0 0 .379.162h5.867ZM18.4 7.09h-5.867v4.364h6.4V7.636a.52.52 0 0 0-.158-.383.498.498 0 0 0-.375-.162Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgHeatMapIcon;
