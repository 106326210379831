import clsx from 'clsx';
import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
  MouseEventHandler,
} from 'react';
import { Button } from '../atoms/Button';

export type ActionButtonProps = PropsWithChildren<{
  icon: ReactNode;
  className?: string;
  disabled?: boolean;
  onRun: () => void | Promise<unknown>;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  buttonClassName?: string;
  tourId?: string;
}>;

export const ActionButton = React.forwardRef<HTMLDivElement, ActionButtonProps>(
  (
    {
      onRun,
      icon,
      children,
      className,
      disabled = false,
      onMouseDown,
      onMouseEnter,
      onMouseLeave,
      buttonClassName,
      tourId,
    },
    ref
  ) => {
    const [active, setActive] = useState(false);

    const handleOnClick = useCallback(async () => {
      if (disabled || active) return;
      try {
        setActive(true);
        await onRun();
      } finally {
        setActive(false);
      }
    }, [disabled, active, setActive, onRun]);

    return (
      <div
        ref={ref}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={clsx(
          className,
          'inline-block', // Ensure the div behaves like an inline element
          disabled && 'pointer-events-none' // Disable pointer events when button is disabled
        )}
      >
        <Button
          disabled={disabled}
          onClick={handleOnClick}
          variant="action"
          tourId={tourId}
          className={clsx(
            'flex items-center space-x-1.5 !justify-start w-full h-full',
            buttonClassName
          )}
        >
          {icon} <span className="pt-[2px]">{children}</span>
        </Button>
      </div>
    );
  }
);

ActionButton.displayName = 'ActionButton';
