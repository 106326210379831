import { useState, useMemo, useCallback, useEffect } from 'react';
import {
  ClusterVisualizationFilter,
  VisualizationFilter,
} from '../../core/types/filters';
import { useFetchDashboardFilterFieldsMeta } from '../../core/data-fetching/dashlet-fields';
import { FilterFieldMeta } from '../../filters/helpers';
import { useDashboardContext } from '../DashboardContext';
import { useMounted } from '../../core/useMounted';
import {
  FetchSimilarRequestParams,
  FilterOperatorType,
  FilterSessionRun,
} from '@tensorleap/api-client';
import api from '../../core/api-client';
import { useMergedObject } from '../../core/useMergedObject';
import { calcHash } from '../../core/calc-hash';
import { getFilterText } from '../../filters/FilterElements';
import { trimStorageUrlProject } from '../../core/useProjectStorage';
import { useModelFilter } from '../../ui/molecules/useModelFilter';

export interface DashboardFiltersParams {
  projectId: string;
  defaultFilters?: VisualizationFilter[];
  pinFilters?: VisualizationFilter[];
  useRegisteredFilters: boolean;
  updatePinFilters: (f: VisualizationFilter[]) => Promise<void>;
}

type FetchParams = Omit<FetchSimilarRequestParams, 'digest'> & {
  fetchSimilarFilters: VisualizationFilter[];
  sessionRun: FilterSessionRun;
};

export interface DashboardFiltersResponse {
  filterFieldsMeta: FilterFieldMeta[];
  dashletFilters: VisualizationFilter[];
  dashletAndGlobalFilters: VisualizationFilter[];
  createClusterFilterByFetchSimilar: (props: FetchParams) => void;
  updateDashletFilters: (f: VisualizationFilter[]) => Promise<void>;
  globalizeFilters: () => void;
}

export function useDashboardFilters({
  projectId,
  defaultFilters = [],
  pinFilters = [],
  updatePinFilters,
  useRegisteredFilters,
}: DashboardFiltersParams): DashboardFiltersResponse {
  const { subscribeFilterSetter } = useDashboardContext();
  const isMounted = useMounted();

  const [localFilters, setLocalFilters] = useState<VisualizationFilter[]>(
    defaultFilters
  );
  const [updatingPinFilters, setUpdatingPinFilters] = useState<
    null | VisualizationFilter[]
  >(null);

  const { globalFilters, setGlobalFilters } = useDashboardContext();
  const allFilters = useMemo(() => [...globalFilters, ...localFilters], [
    globalFilters,
    localFilters,
  ]);
  const { selected } = useModelFilter();
  const selectedSessionRunIds = useMemo(() => selected.map((s) => s.id), [
    selected,
  ]);
  const filterFieldsMeta = useFetchDashboardFilterFieldsMeta({
    projectId,
    filters: allFilters,
    sessionRunIds: selectedSessionRunIds,
  });

  const addLocalFilter = useCallback(
    (filter: VisualizationFilter) =>
      setLocalFilters((curr) => {
        const filterExists = curr.some(
          (currFilter) => JSON.stringify(currFilter) === JSON.stringify(filter)
        );
        return filterExists ? curr : [...curr, filter];
      }),
    []
  );
  useEffect(() => {
    if (useRegisteredFilters) {
      subscribeFilterSetter(addLocalFilter);
    }
  }, [subscribeFilterSetter, addLocalFilter, useRegisteredFilters]);

  const dashletFilters = useMemo(
    () => [...(updatingPinFilters || pinFilters), ...localFilters],
    [updatingPinFilters, pinFilters, localFilters]
  );

  const dashletAndGlobalFilters = useMemo(
    () => [...dashletFilters, ...globalFilters],
    [dashletFilters, globalFilters]
  );

  const globalizeFilters = useCallback(() => {
    setGlobalFilters((gf) => gf.concat(localFilters));
    setLocalFilters([]);
  }, [localFilters, setGlobalFilters]);

  const updateDashletFilters = useCallback(
    async (filters: VisualizationFilter[]) => {
      const newPinFilters = filters.filter((f) => f.pin);
      const newNonPinFilters = filters.filter((f) => !f.pin);

      const isPinChange =
        JSON.stringify(newPinFilters) !== JSON.stringify(pinFilters);

      isPinChange && setUpdatingPinFilters(newPinFilters);
      setLocalFilters(newNonPinFilters);

      if (isPinChange) {
        await updatePinFilters(newPinFilters);
        setUpdatingPinFilters(null);
      }
    },
    [updatePinFilters, pinFilters]
  );

  const createClusterFilterByFetchSimilar = useCallback(
    (props: FetchParams) => {
      const digest = calcHash(props);
      const fetchParams = { ...props, digest };

      const {
        limit,
        sampleIds,
        epoch,
        fetchSimilarFilters,
        sessionRun,
      } = props;

      const newFilter: ClusterVisualizationFilter = {
        field: FilterOperatorType.Cluster,
        operator: FilterOperatorType.Cluster,
        value: {
          url: 'calculating...',
          state: 'calculating',
        },
        displayData: {
          type: 'fetch-similar',
          limit,
          sampleIds,
          epoch,
          sessionRun,
          filtersUsed: fetchSimilarFilters
            .filter((f) => !f.disable)
            .map(getFilterText),
        },
      };

      addLocalFilter(newFilter);

      const triggerOrCheck = async (trigger = false) => {
        if (!isMounted.current) {
          return;
        }

        const result = await (trigger
          ? api.fetchSimilar(fetchParams)
          : api.getFetchSimilarStatus(fetchParams));

        if (!isMounted.current) {
          return;
        }

        if (result.status === 'FAILED') {
          const removeFunc = (curr: VisualizationFilter[]) =>
            curr.some((filter) => filter === newFilter)
              ? curr.filter((filter) => filter !== newFilter)
              : curr;
          setLocalFilters(removeFunc);
          setGlobalFilters(removeFunc);
          return;
        } else if (
          result.status !== 'FINISHED' ||
          result.readyArtifacts.clusterPath === undefined
        ) {
          setTimeout(triggerOrCheck, 5000);
          return;
        }

        const url = trimStorageUrlProject(result.readyArtifacts.clusterPath);

        const updatedFilter: ClusterVisualizationFilter = {
          ...newFilter,
          value: {
            ...newFilter.value,
            url,
            state: 'ready',
          },
        };

        const updateFunc = (curr: VisualizationFilter[]) =>
          curr.some((filter) => filter === newFilter)
            ? curr.map((filter) =>
                filter === newFilter ? updatedFilter : filter
              )
            : curr;
        setLocalFilters(updateFunc);
        setGlobalFilters(updateFunc);
      };
      triggerOrCheck(true);
    },
    [isMounted, addLocalFilter, setGlobalFilters]
  );

  return useMergedObject({
    filterFieldsMeta,
    dashletFilters,
    dashletAndGlobalFilters,
    createClusterFilterByFetchSimilar,
    globalizeFilters,
    updateDashletFilters,
  });
}
