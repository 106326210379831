import { useMemo } from 'react';
import useSWR, { KeyedMutator } from 'swr';
import { CacheKey, REFRESH_INTERVALS } from './consts';
import api from '../api-client';

export interface UseFetchModeldMetricNames {
  metricNames?: string[];
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<string[]>;
}
export interface FetchModelMetricNamesParams {
  projectId: string;
  sessionRunIds: string[];
}
export function useFetchModeldMetricNames({
  projectId,
  sessionRunIds,
}: FetchModelMetricNamesParams): UseFetchModeldMetricNames {
  const fetcheKey = `${
    CacheKey.MODEL_METRC_NAMES
  }-${projectId}-${sessionRunIds.join(',')}`;
  const { data: metricNames = [], error, mutate } = useSWR(
    fetcheKey,
    async () => {
      const { confusionMetricNames } = await api.getConfusionMetricNames({
        projectId,
        sessionRunIds,
      });
      return confusionMetricNames;
    },
    {
      refreshInterval: REFRESH_INTERVALS.modelMetricNames,
    }
  );

  return useMemo(
    () => ({
      metricNames,
      error,
      isLoading: !error && !metricNames,
      refetch: mutate,
    }),
    [error, metricNames, mutate]
  );
}
