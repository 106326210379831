import clsx from 'clsx';
import { CSSProperties, PropsWithChildren } from 'react';
import { HEADER_ALIGN_CLASSES, TEXT_ALIGN_CLASSES } from './table-helpers';
import { Align, HeaderSorting } from './table-types';
import { SortBigSmallIcon } from '../../icons';
import { IconButton } from '@material-ui/core';

type TableHeaderProps = PropsWithChildren<{
  align: Align;
  sort?: HeaderSorting;
  style?: CSSProperties;
  className?: string;
}>;

export function TableHeader({
  align,
  sort,
  children,
  className,
  style,
}: TableHeaderProps): JSX.Element {
  return (
    <th
      style={style}
      onClick={sort?.toggle}
      className={clsx(
        'px-3 font-normal group',
        sort && 'cursor-pointer',
        className
      )}
    >
      <div
        className={clsx(
          'flex items-center w-full',
          HEADER_ALIGN_CLASSES[align]
        )}
      >
        <div className={TEXT_ALIGN_CLASSES[align]}>{children}</div>
        {sort && (
          <IconButton size="small">
            <SortBigSmallIcon
              className={clsx(
                'transition-all',
                sort.isSorting
                  ? 'text-gray-300'
                  : 'invisible  group-hover:visible text-gray-500',
                sort.direction === 'desc' && ' -scale-y-100'
              )}
            />
          </IconButton>
        )}
      </div>
    </th>
  );
}
