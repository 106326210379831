import { orderBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { BaseItem, ModelFields, ModelFieldUnion } from './types';
import { isModelField } from './utils';

export type SortDirection = 'desc' | 'asc';
export type SortableDetails = {
  level: 'primary' | 'secondary';
  direction?: SortDirection;
};

export type SortBy<Key> = {
  field: Key;
  direction: SortDirection;
};

export type Sorter<Item extends BaseItem> = {
  // primarySortBy is visible sortBy
  primarySortBy?: SortBy<keyof Item>;
  // is contains the primarySortBy if existed and the secondarySortBy as an array
  sortBy: SortBy<keyof Item>[];
  toggle: (field: string) => void;
};

export const DEFAULT_SORT_DIRECTION: SortDirection = 'asc';

export function useSort<Item extends BaseItem>(
  fields: ModelFields<Item>
): Sorter<Item> {
  const defaultSort = useMemo(() => getInitialSortByFromFields(fields), [
    fields,
  ]);

  const [primarySortBy, setPrimarySortBy] = useState<
    SortBy<keyof Item> | undefined
  >(defaultSort.primary);

  const toggle = useCallback((field: keyof Item) => {
    setPrimarySortBy((primarySortBy) => {
      if (primarySortBy?.field !== field)
        return { field, direction: DEFAULT_SORT_DIRECTION } as SortBy<
          keyof Item
        >;
      if (primarySortBy?.direction === DEFAULT_SORT_DIRECTION)
        return { field, direction: 'desc' } as SortBy<keyof Item>;
      return;
    });
  }, []);

  const sortBy = useMemo(() => {
    if (!primarySortBy) {
      return [defaultSort.secondary];
    }
    if (primarySortBy.field === defaultSort.secondary.field) {
      return [primarySortBy];
    }
    return [primarySortBy, defaultSort.secondary];
  }, [primarySortBy, defaultSort.secondary]);

  return {
    primarySortBy,
    sortBy,
    toggle,
  };
}

export function sort<Item, Key extends keyof Item>(
  data: Item[],
  sortByList: SortBy<Key>[]
) {
  const sortDirections: SortDirection[] = sortByList
    .filter((element): element is SortBy<Key> => !!element)
    .map(({ direction }) => direction);
  const sortFields = sortByList
    .filter((element) => !!element)
    .map(({ field }) => field);

  return orderBy(data, sortFields, sortDirections);
}

type PrimaryAndSecondarySortBy<Item> = {
  primary?: SortBy<keyof Item>;
  secondary: SortBy<keyof Item>;
};
function getInitialSortByFromFields<Item extends BaseItem>(
  fields: ModelFields<Item>
): PrimaryAndSecondarySortBy<Item> {
  let primaryField = fields.find(
    (field) =>
      isModelField(field) &&
      field.sortable &&
      (field.sortable as SortableDetails)?.level == 'primary'
  );

  const secondaryField = fields.find(
    (field) =>
      isModelField(field) &&
      field.sortable &&
      (field.sortable as SortableDetails)?.level == 'secondary'
  );

  if (!primaryField && !secondaryField) {
    const firstSortableField = fields.find(
      (field) => !!isModelField(field) && field.sortable
    );

    primaryField = firstSortableField;
    if (!primaryField)
      console.warn(
        '[getInitialSortByFromFields] You must specify at least one sortable field'
      );
  }

  const primary = fieldToSortBy(primaryField);

  return {
    primary,
    secondary: (fieldToSortBy(secondaryField) || primary) as SortBy<keyof Item>,
  };
}

function fieldToSortBy<Item extends BaseItem>(
  field?: ModelFieldUnion<Item>
): SortBy<keyof Item> | undefined {
  const isNotSortable = !field || !isModelField(field) || !field.sortable;
  if (isNotSortable) return;

  return {
    field: field.accessorKey as string,
    direction:
      (field.sortable as SortableDetails)?.direction || DEFAULT_SORT_DIRECTION,
  };
}
