import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button } from '../ui/atoms/Button';
import { Target1, XClose } from '../ui/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import { useToggle } from '../core/useToggle';
import { ToursTypesEnum, TourSequence, Step, ToursData } from './ToursConfig';
import './tourStyle.css';
import { useDebounce } from '../core/useDebounce';
import { useLocation } from 'react-router';
import { SequenceState } from './ToursContext';
import { TourProgressBar } from './TourProgressBar';

const SHINE_BORDER = '2px solid #FFD700';
const SHINE_ANIMATION = 'tilt-shaking 0.6s 2';
const SHINE_ANIMATION_TIMING_FUNCTION = 'ease-in-out';

export interface TourPopoverProps {
  selectedTour: ToursTypesEnum;
  selectTour: (tour: ToursTypesEnum, sequenceIndex?: number) => void;
  sequencesStates: SequenceState[];
  initialSequenceIndex: number;
  updateSequenceState: (
    tour: ToursTypesEnum,
    sequenceIndex: number,
    completed: boolean
  ) => void;
  closeTour: () => void;
}

export function TourPopover({
  selectedTour,
  selectTour,
  sequencesStates,
  initialSequenceIndex,
  updateSequenceState,
  closeTour,
}: TourPopoverProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [stepIndex, setStepIndex] = useState(0);
  const [sequence, setSequence] = useState<TourSequence>();
  const [step, setStep] = useState<Step>();
  const [isLoading, setIsLoading] = useState(false);
  const [sequenceIndex, setSequenceIndex] = useState(initialSequenceIndex);

  const [forceGoToNextStep, setForceGoToNextStep] = useState(false);
  const { pathname } = useLocation();

  const increaseStepAndSequenceIndexes = useCallback(() => {
    if (isLoading) return;
    if (isLastStepInSequence(sequenceIndex, stepIndex, selectedTour)) {
      setIsLoading(true);
      setStepIndex(0);
      setSequenceIndex((prev) => prev + 1);
      updateSequenceState(selectedTour, sequenceIndex, true);
      setIsLoading(false);
    } else {
      setStepIndex((prev) => prev + 1);
    }
  }, [
    isLoading,
    sequenceIndex,
    stepIndex,
    selectedTour,
    updateSequenceState,
    setSequenceIndex,
  ]);

  const handleOnNext = useCallback(
    (executeOnNext: boolean) => {
      if (executeOnNext && step?.onNext && step?.selector) {
        const element = document.getElementById(step?.selector);
        if (element) {
          step.onNext(element);
        }
      }

      setForceGoToNextStep(true);
    },
    [setForceGoToNextStep, step]
  );

  const goNextStep = useCallback(
    (executeOnNext: boolean) => {
      if (isLastStepInTour(sequenceIndex, stepIndex, selectedTour)) {
        updateSequenceState(selectedTour, sequenceIndex, true);
        closeTour();
      } else {
        handleOnNext(executeOnNext);
      }
    },
    [
      closeTour,
      handleOnNext,
      selectedTour,
      sequenceIndex,
      stepIndex,
      updateSequenceState,
    ]
  );

  const handleOnNextDebounced = useDebounce(goNextStep, 500, {
    maxWait: 500,
  });

  const [triggerSkipIfCheck, setTriggerSkipIfCheck] = useToggle(false);
  const toggleTriggerSkipIfCheck = useDebounce(
    () => setTriggerSkipIfCheck(),
    1000
  );
  useEffect(toggleTriggerSkipIfCheck, [
    toggleTriggerSkipIfCheck,
    triggerSkipIfCheck,
  ]);

  useEffect(() => {
    if (isLoading) return;
    const tourData = ToursData[selectedTour];

    if (!tourData) {
      return;
    }

    if (!tourData.pages.some((page) => pathname.includes(page))) {
      closeTour();
      return;
    }

    const sequence = tourData.sequences[sequenceIndex];
    if (!sequence) {
      return;
    }
    const stepToCheck = sequence.steps[stepIndex];

    if (!stepToCheck) {
      console.error('Step not found', sequence, stepIndex);
      return;
    }

    if (
      forceGoToNextStep ||
      (stepToCheck.skipIf &&
        stepToCheck.skipIf() &&
        !stepToCheck.showError?.when())
    ) {
      setForceGoToNextStep(false);
      console.debug('Skipping step', stepToCheck.textContent);
      increaseStepAndSequenceIndexes();
    } else {
      setSequence(sequence);
      setStep(stepToCheck);
    }
  }, [
    selectedTour,
    step,
    stepIndex,
    goNextStep,
    sequenceIndex,
    increaseStepAndSequenceIndexes,
    isLoading,
    triggerSkipIfCheck, //do not remove!
    forceGoToNextStep,
    closeTour,
    pathname,
  ]);

  const [style, setStyle] = useState<CSSProperties>({
    top: '50%',
    left: '50%',
  });

  const isShining = useRef(false);

  const scrollAndShineElement = useCallback(() => {
    const selectorElement = step?.selector
      ? document.getElementById(step.selector)
      : null;

    const scrollToElement = step?.scrollToSelectorId
      ? document.getElementById(step.scrollToSelectorId)
      : selectorElement;

    scrollToElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });

    if (selectorElement && !isShining.current) {
      const prevBorder = selectorElement.style.border;
      const prevAnimation = selectorElement.style.animation;
      const prevAnimationTimingFunction =
        selectorElement.style.animationTimingFunction;

      selectorElement.style.border = SHINE_BORDER;
      selectorElement.style.animation = SHINE_ANIMATION;
      selectorElement.style.animationTimingFunction = SHINE_ANIMATION_TIMING_FUNCTION;
      isShining.current = true;

      setTimeout(() => {
        selectorElement.style.border = prevBorder;
        selectorElement.style.animation = prevAnimation;
        selectorElement.style.animationTimingFunction = prevAnimationTimingFunction;
        isShining.current = false;
      }, 1200);
    }
  }, [step]);

  // scroll to element and make it shine
  useEffect(() => {
    scrollAndShineElement();
  }, [scrollAndShineElement, step]);

  // position the popover
  useEffect(() => {
    if (step?.selector) {
      const element = document.getElementById(step?.selector);

      if (element) {
        // set element position
        let top;
        let left;
        const popoverWidth = ref.current?.clientWidth || 384;
        const popoverHeight = ref.current?.clientHeight || 240;
        const elementBounding = element.getBoundingClientRect();

        if (
          (elementBounding.left < window.innerWidth &&
            elementBounding.left >= window.innerWidth / 2) ||
          (elementBounding.left > 0 && elementBounding.left > popoverWidth)
        ) {
          left = elementBounding.left - popoverWidth;
        } else if (
          (elementBounding.right > 0 &&
            elementBounding.right <= window.innerWidth / 2) ||
          window.innerWidth - elementBounding.right > popoverWidth
        ) {
          left = elementBounding.right;
        } else {
          left = elementBounding.left;
        }
        left = (Math.abs(left) % window.innerWidth) + 'px';

        if (
          (elementBounding.top < window.innerHeight &&
            elementBounding.top >= window.innerHeight / 2) ||
          (elementBounding.top > 0 && elementBounding.top > popoverHeight)
        ) {
          top = elementBounding.top - popoverHeight;
        } else if (
          (elementBounding.bottom > 0 &&
            elementBounding.bottom <= window.innerHeight / 2) ||
          window.innerHeight - elementBounding.bottom > popoverHeight
        ) {
          top = elementBounding.bottom;
        } else {
          top = elementBounding.top;
        }
        top = (Math.abs(top) % window.innerHeight) + 'px';

        setStyle({
          top,
          left,
        });
      } else {
        setStyle({
          top: '50%',
          left: '50%',
        });
        console.warn('Element not found', step.selector);
      }
    }
  }, [
    step,
    triggerSkipIfCheck, //Do not remove! this is used to trigger the effect so the popover can be positioned correctly when the page is changed
  ]);

  const errorMsg = useMemo(() => {
    if (step?.showError && step.showError.when()) {
      return step.showError.message;
    }
    return null;
  }, [step]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        !isLoading &&
        event.key === 'ArrowRight' &&
        !step?.hideNextButton &&
        !errorMsg
      ) {
        handleOnNextDebounced(true);
      } else if (event.key === 'Escape') {
        closeTour();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    closeTour,
    errorMsg,
    handleOnNextDebounced,
    isLoading,
    step?.hideNextButton,
  ]);

  const nextButtonText = step?.nextButtonText
    ? step.nextButtonText
    : isLastStepInTour(sequenceIndex, stepIndex, selectedTour)
    ? 'Close'
    : 'Next';

  if (!sequence || !step) {
    return null;
  }

  return (
    <div
      className="absolute flex z-[5000] flex-col  bg-gray-700 border-[1px] border-gray-500 rounded-lg min-w-[24rem] w-fit max-w-lg h-fit transition-all duration-500"
      style={style}
      ref={ref}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex flex-row-reverse justify-between p-2">
        <Tooltip title="Close tour" placement="top">
          <span>
            <IconButton onClick={closeTour} className="right-0 w-4 h-4">
              <XClose className="w-4 h-4" />
            </IconButton>
          </span>
        </Tooltip>

        {step?.selector && (
          <Tooltip title="Highlight element" placement="top">
            <span>
              <IconButton className="w-4 h-4" onClick={scrollAndShineElement}>
                <Target1 className="w-4 h-4" />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </div>

      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="flex flex-col pb-2 gap-4 text-sm">
          <div className="flex flex-row justify-between">
            <p className="font-black text-base px-4">{sequence.description}</p>
          </div>

          <p className="font-normal px-4">{errorMsg || step.textContent}</p>

          {!errorMsg && step?.gifSrc && (
            <img
              src={step.gifSrc}
              alt="gif"
              className="w-full h-auto max-h-60 object-contain min-w-0"
            />
          )}

          {!errorMsg && !step?.hideNextButton && (
            <div className="flex justify-end gap-2 px-4 py-2">
              {step?.allowSkip && (
                <Button
                  variant="inverted-filled-gray"
                  className="w-fit h-7"
                  onClick={() => goNextStep(false)}
                >
                  <span className="text-xs">Skip</span>
                </Button>
              )}

              <Button className="w-fit h-8" onClick={() => goNextStep(true)}>
                <span>{nextButtonText}</span>
              </Button>
            </div>
          )}
          {sequencesStates.length > 1 && (
            <TourProgressBar
              sequenceIndex={sequenceIndex}
              sequencesStates={sequencesStates}
              stepIndex={stepIndex}
              selectTour={selectTour}
              selectedTour={selectedTour}
            />
          )}
        </div>
      )}
    </div>
  );
}

const isLastSequence = (sequenceIndex: number, selectedTour: ToursTypesEnum) =>
  sequenceIndex >= ToursData[selectedTour]?.sequences.length - 1;

const isLastStepInSequence = (
  sequenceIndex: number,
  stepIndex: number,
  selectedTour: ToursTypesEnum
) =>
  stepIndex >=
  ToursData[selectedTour]?.sequences?.[sequenceIndex]?.steps.length - 1;

const isLastStepInTour = (
  sequenceIndex: number,
  stepIndex: number,
  selectedTour: ToursTypesEnum
) =>
  isLastSequence(sequenceIndex, selectedTour) &&
  isLastStepInSequence(sequenceIndex, stepIndex, selectedTour);
