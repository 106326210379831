import clsx from 'clsx';
import { XClose, Vi1 } from '../icons';
import { IconButton } from '../mui';

export interface AcceptRejectIconButtonProps {
  disabled?: boolean;
  submit?: boolean;
  acceptClassName?: string;
  rejectClassName?: string;
  onAccept?: () => void;
  onReject: () => void;
}

const ICON_BUTTON_STYLE = 'w-8 h-8';

export function AcceptRejectIconButtons({
  disabled,
  acceptClassName,
  rejectClassName,
  submit,
  onAccept,
  onReject,
}: AcceptRejectIconButtonProps): JSX.Element {
  return (
    <>
      <IconButton
        disabled={disabled}
        onClick={onAccept}
        type={submit ? 'submit' : undefined}
        className={clsx(ICON_BUTTON_STYLE, 'text-success-600', acceptClassName)}
      >
        {<Vi1 />}
      </IconButton>
      <IconButton
        onClick={onReject}
        className={clsx(ICON_BUTTON_STYLE, 'text-error-600', rejectClassName)}
      >
        {<XClose />}
      </IconButton>
    </>
  );
}
