import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgDoubleCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M13.094 4.625a.77.77 0 0 1-.002 1.092l-4.373 4.342-1.09-1.09 4.377-4.346a.77.77 0 0 1 1.088.002Zm3.27-.002a.772.772 0 0 1 1.088 1.094L9.43 13.711a1 1 0 0 1-1.416-.005l-3.058-3.082a.762.762 0 0 1 1.077-1.078l2.687 2.657 7.646-7.58ZM.562 10.655A.782.782 0 1 1 1.67 9.552l3.745 3.776-.383.383a1 1 0 0 1-1.414 0L.561 10.655Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDoubleCheck;
