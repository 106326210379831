import { Collapse } from '@material-ui/core';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { Down, Up } from '../../ui/icons';

export type CategoryTitleViewProps = {
  title: ReactNode;
  children?: ReactNode;
};

export function CategoryTitleView({ title, children }: CategoryTitleViewProps) {
  return (
    <>
      <div className="font-bold">{title}</div>
      {children}
    </>
  );
}

export type CategoryViewProps = {
  title: ReactNode;
  open: boolean;
  onOpenToggle: () => void;
  children?: ReactNode;
  noBottomBorder?: boolean;
};

export function CategoryView({
  title,
  open,
  onOpenToggle,
  noBottomBorder,
  children,
}: CategoryViewProps) {
  const bottomBorderClass = 'border-b border-gray-700';
  return (
    <div
      className={clsx(
        open && !noBottomBorder && bottomBorderClass,
        open && 'overflow-hidden',
        'flex flex-col'
      )}
    >
      <div
        className={clsx(
          (!noBottomBorder || open) && bottomBorderClass,
          'flex min-h-[50px] items-center text-base gap-3 py-2 cursor-pointer'
        )}
        onClick={() => onOpenToggle()}
      >
        {open ? <Up /> : <Down />}
        {title}
      </div>
      <Collapse className="overflow-auto" in={open}>
        <div className="ml-4">{children}</div>
      </Collapse>
    </div>
  );
}

CategoryView.Title = CategoryTitleView;
