import {
  GeneratedDashlet,
  GetGeneratedDashletsResponse,
  JobStatus,
} from '@tensorleap/api-client';
import { useCallback, useMemo, useRef } from 'react';
import useSWR from 'swr';
import api from '../api-client';

type UseFetchSuggestedDashletProps = {
  projectId?: string;
  sessionRunIds: string[];
};

export type GroupByHashSuggested = {
  hash: string;
  dashlet: GeneratedDashlet;
  maxPriority: number;
  sessionRunIds: string[];
};

export type GeneratedDashletGeneralStatus = {
  isCalculating: boolean;
  isFailed: boolean;
};

type UseFetchSuggestedDashletResult = {
  data: GroupByHashSuggested[];
  status: GeneratedDashletGeneralStatus;
  reset: () => void;
};
type FetchResponse = {
  data: GroupByHashSuggested[];
  status: GeneratedDashletGeneralStatus;
};

const EMPTY_RESPONSE: FetchResponse = {
  data: [],
  status: { isCalculating: false, isFailed: false },
};

export function useFetchSuggestedDashlets({
  projectId,
  sessionRunIds,
}: UseFetchSuggestedDashletProps): UseFetchSuggestedDashletResult {
  const resetPreviousRef = useRef(false);
  const statusRef = useRef({ isCalculating: false, isFailed: false });

  const key = useMemo(() => {
    return `${projectId}-${sessionRunIds.join('-')}`;
  }, [projectId, sessionRunIds]);

  const fetch = useCallback(
    async (_key: string): Promise<FetchResponse> => {
      const resetPrevious = resetPreviousRef.current;
      resetPreviousRef.current = false;
      if (!projectId || sessionRunIds.length === 0) {
        statusRef.current = { isCalculating: false, isFailed: false };
        return EMPTY_RESPONSE;
      }

      const res = await api.getGeneratedDashlets({
        projectId,
        sessionRunIds,
        resetPrevious,
      });
      const status = calcStatus(res);
      statusRef.current = status;
      const data = groupByHash(res);
      return { data, status };
    },
    [projectId, sessionRunIds]
  );

  const { data: { data } = EMPTY_RESPONSE, mutate } = useSWR(key, fetch, {
    refreshInterval: statusRef.current.isCalculating ? 5000 : undefined,
  });

  const reset = useCallback(() => {
    resetPreviousRef.current = true;
    mutate();
  }, [mutate]);

  return { data, reset, status: statusRef.current };
}

function groupByHash(
  res: GetGeneratedDashletsResponse
): GroupByHashSuggested[] {
  const dashletGroupByHash: Record<string, GroupByHashSuggested> = {};
  for (const { sessionRunId, dashlets } of res.data) {
    for (const dashlet of dashlets) {
      const hash = calcGeneratedDashletHash({
        xField: dashlet.data.x.field,
        yField: dashlet.data.y.field,
      });
      let dashletGroup = dashletGroupByHash[hash];
      if (!dashletGroup) {
        dashletGroup = {
          hash,
          dashlet,
          maxPriority: dashlet.priority,
          sessionRunIds: [],
        };
        dashletGroupByHash[hash] = dashletGroup;
      }
      if (dashlet.priority > dashletGroup.maxPriority) {
        dashletGroup.dashlet = dashlet;
        dashletGroup.maxPriority = dashlet.priority;
      }
      dashletGroup.sessionRunIds.includes(sessionRunId) ||
        dashletGroup.sessionRunIds.push(sessionRunId);
    }
  }
  return Object.values(dashletGroupByHash);
}

type CalcGeneratedDashletHashProps = {
  xField: string;
  yField: string;
};

export function calcGeneratedDashletHash({
  xField,
  yField,
}: CalcGeneratedDashletHashProps): string {
  return `${xField}-${yField}`;
}

function calcStatus(
  res: GetGeneratedDashletsResponse
): GeneratedDashletGeneralStatus {
  const isCalculating = res.data.some(
    ({ status }) => status === JobStatus.Started
  );

  const isFailed = res.data.some(({ status }) => status === JobStatus.Failed);

  return {
    isCalculating,
    isFailed,
  };
}
