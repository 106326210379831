import { ErrorIcon, FailedIcon, TestIcon } from '../ui/icons';
import { Tooltip } from '../ui/mui';
import { TEXT_COLOR_TEMPLATES } from '../ui/molecules/colorTemplates';
export type modelTestStatus = 'success' | 'failed' | 'na';

export type ModelTestStatusIconProps = {
  name: string;
  modelUniqueKey?: number;
  status: modelTestStatus;
};

export function ModelTestStatusIcon({
  name,
  modelUniqueKey,
  status,
}: ModelTestStatusIconProps): JSX.Element {
  const textStyle =
    modelUniqueKey === undefined ? '' : TEXT_COLOR_TEMPLATES[modelUniqueKey];
  return (
    <Tooltip title={name} placement="bottom">
      <div>
        {status === 'na' ? (
          <ErrorIcon className={textStyle} />
        ) : status === 'success' ? (
          <TestIcon className={textStyle} />
        ) : (
          <FailedIcon className={textStyle} />
        )}
      </div>
    </Tooltip>
  );
}
