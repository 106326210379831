import { Epoch } from '../../../ui/icons';
import { SimpleAutocomplete } from '../../../ui/molecules/SimpleAutocomplete';
import { useCallback, useMemo } from 'react';

export type EpochSelectorProps = {
  epochs: number[];
  value: number;
  onSelect: (epoch: number) => void;
};

export function EpochSelector({ epochs, value, onSelect }: EpochSelectorProps) {
  const onChange = useCallback(
    (value: string | undefined) => {
      const epoch = parseInt(value || '');
      if (isNaN(epoch)) {
        console.error('Epoch is not a number', value);
        return;
      }

      onSelect(epoch);
    },
    [onSelect]
  );

  const options = useMemo(() => epochs.map((e) => e.toString()), [epochs]);

  return (
    <SimpleAutocomplete
      title="Epoch"
      icon={<Epoch />}
      narrow
      options={options}
      value={value.toString()}
      onChange={onChange}
    />
  );
}
