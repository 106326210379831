import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgNotIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18.5a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17Zm0 1.5c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.2 10.8H5.5a.791.791 0 0 1-.565-.23l-.006-.005A.791.791 0 0 1 4.7 10c0-.216.079-.41.232-.564A.785.785 0 0 1 5.5 9.2h9c.218 0 .411.081.564.236A.772.772 0 0 1 15.3 10a.784.784 0 0 1-.236.568.778.778 0 0 1-.564.232H9.2Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgNotIcon;
