import {
  AnalyticsDashletType,
  GenericDataItem,
  GenericDataResponse,
  NumberOrString,
} from '@tensorleap/api-client';
import { useCallback, useMemo, useState } from 'react';
import { Setter } from '../../../core/types';
import {
  CHART_META_FIELDS_KEY,
  GRAPH_STYLE,
  MOUSE_DOWN_RENDER_KEY,
} from '../common/constants';
import {
  SplitXYData,
  MergedXYData,
  DataPoint,
  ChartRequestData,
} from '../common/interfaces';
import { getAllLabels, getParsedChartPoints } from './utils';
import { isSplitDefined } from '../../../dashboard/dashlet/Analytics/ElasticVis/utils';

export interface UseXYChartRequest {
  graphData: GenericDataResponse;
  chartRequestData: ChartRequestData;
  chartType: AnalyticsDashletType;
  hiddenLabels?: NumberOrString[];
  hoverLabel?: NumberOrString;
}

export interface UseXYChart {
  splitData: () => SplitXYData;
  mergedData: () => MergedXYData;
  strokeOpacity: (dataKey: string) => number;
  fillOpacity: (datakey: string) => number;
  isHidden: (dataKey: string) => boolean;
  allLabels: string[];
  setIsMouseDown: Setter<boolean>;
  setIsMouseHover: Setter<boolean>;
  setIsMouseHoverPortion: Setter<boolean>;
  graphKey: number | typeof MOUSE_DOWN_RENDER_KEY;
}

export function useXYChart({
  graphData,
  chartRequestData,
  chartType,
  hiddenLabels,
  hoverLabel,
}: UseXYChartRequest): UseXYChart {
  const allPointsFlat = useMemo(
    (): DataPoint[] => getParsedChartPoints({ graphData, chartRequestData }),
    [chartRequestData, graphData]
  );

  const splitData = useCallback((): SplitXYData => {
    const { innerSplit } = chartRequestData;
    if (!isSplitDefined(innerSplit)) {
      return { [chartRequestData.yField]: allPointsFlat };
    }

    const mappedDataItems = graphData.data.reduce((acc, data) => {
      const key = data.data[innerSplit.field];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(data);
      return acc;
    }, {} as Record<string, GenericDataItem[]>);
    const mappedFlatDataPoints = Object.entries(mappedDataItems).reduce(
      (acc, [key, dataItems]) => {
        acc[key] = getParsedChartPoints({
          graphData: { data: dataItems },
          chartRequestData,
        });
        return acc;
      },
      {} as Record<string, DataPoint[]>
    );

    return mappedFlatDataPoints;
  }, [allPointsFlat, chartRequestData, graphData.data]);

  const mergedData = useCallback((): MergedXYData => {
    const mergedDataPoints: MergedXYData = [];
    allPointsFlat.forEach((point) => {
      const xValue = point[chartRequestData.xField];
      const existingValue = mergedDataPoints.find(
        (existingPoints) => existingPoints[chartRequestData.xField] === xValue
      );
      if (existingValue) {
        Object.entries(point).forEach(([key, value]) => {
          if (
            key === CHART_META_FIELDS_KEY &&
            typeof value !== 'number' &&
            typeof value !== 'string'
          ) {
            existingValue[CHART_META_FIELDS_KEY] = {
              ...(existingValue[CHART_META_FIELDS_KEY] as Record<
                NumberOrString,
                NumberOrString
              >),
              ...(point[CHART_META_FIELDS_KEY] as Record<
                NumberOrString,
                NumberOrString
              >),
            };
          } else if (key !== chartRequestData.xField) {
            existingValue[key] = value;
          }
        });
      } else {
        mergedDataPoints.push(point);
      }
    });
    return mergedDataPoints;
  }, [chartRequestData.xField, allPointsFlat]);

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isMouseHover, setIsMouseHover] = useState(false);
  const [isMouseHoverPortion, setIsMouseHoverPortion] = useState(false);
  const graphKey =
    isMouseDown || isMouseHover ? MOUSE_DOWN_RENDER_KEY : Math.random();

  const strokeOpacity = useCallback(
    (datakey: string): number =>
      hoverLabel && hoverLabel !== datakey
        ? GRAPH_STYLE.line.hoverOpacity
        : GRAPH_STYLE.line.noHoverOpacity,
    [hoverLabel]
  );

  const fillOpacity = useCallback(
    (datakey: string): number => {
      const noHoverActive = !hoverLabel && !isMouseHoverPortion;
      const hoveringOverThisLabel = !noHoverActive && hoverLabel == datakey;
      const displayFullOpacity = noHoverActive || hoveringOverThisLabel;
      if (!displayFullOpacity) {
        return GRAPH_STYLE.line.hiddenFillOpacity;
      }
      if (chartType === 'Donut' || chartType === 'Bar') {
        return GRAPH_STYLE.line.strongShowOpacity;
      }

      return GRAPH_STYLE.line.weakShowOpacity;
    },
    [chartType, hoverLabel, isMouseHoverPortion]
  );

  const isHidden = useCallback(
    (dataKey: string) => {
      return !!hiddenLabels?.some((hiddenLabel) => hiddenLabel === dataKey);
    },
    [hiddenLabels]
  );

  const allLabels = useMemo(
    (): string[] =>
      getAllLabels({
        dataPoints: allPointsFlat,
        chartType,
        xField: chartRequestData.xField,
      }),
    [chartType, allPointsFlat, chartRequestData.xField]
  );

  return useMemo(
    () => ({
      splitData,
      mergedData,
      strokeOpacity,
      fillOpacity,
      isHidden,
      allLabels,
      setIsMouseDown,
      setIsMouseHover,
      setIsMouseHoverPortion,
      graphKey,
    }),
    [
      allLabels,
      fillOpacity,
      graphKey,
      isHidden,
      mergedData,
      splitData,
      strokeOpacity,
    ]
  );
}
