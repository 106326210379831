import { memoize } from 'lodash';
import { CropImg } from './types';

export const IMG_DATA_CHANELS = 4; // r, g, b, a

export const fetchImage = memoize(
  (url: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.crossOrigin = `use-credentials`;
      img.onload = () => {
        resolve(img);
      };
      img.onerror = () => {
        fetchImage.cache.delete(url);
        reject(new Error(`Image loading failed: ${url}`));
      };
    });
  }
);

function imageDataFromImg(
  img: HTMLImageElement,
  { x, y, w, h }: CropImg
): ImageData {
  const maxHeight = img.height - y;
  const maxWidth = img.width - x;
  const height = h === undefined ? maxHeight : Math.min(maxHeight, h);
  const width = w === undefined ? maxWidth : Math.min(maxWidth, w);
  const canvas = createSizedCanves({ height, width });
  const context = canvas.getContext('2d');
  if (!context) throw new Error('Failed to get the context from the canves');
  context.drawImage(img, x, y, width, height, 0, 0, width, height);
  return context.getImageData(0, 0, width, height);
}

export async function fetchImageData(
  url: string,
  crop: CropImg = { x: 0, y: 0 }
): Promise<ImageData> {
  const img = await fetchImage(url);
  return imageDataFromImg(img, crop);
}

export function imageDataToImageSrc(imagedata: ImageData) {
  const canvas = createSizedCanves(imagedata);
  const ctx = canvas.getContext('2d');
  if (!ctx) return '';
  ctx.putImageData(imagedata, 0, 0);
  return canvas.toDataURL();
}

type ImgSize = {
  width: number;
  height: number;
};

export function createSizedCanves({
  width,
  height,
}: ImgSize): HTMLCanvasElement {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  return canvas;
}
