import { DefaultApi, Configuration } from '@tensorleap/api-client';

import { createBrowserHistory } from 'history';
import { URLS_ENUM } from '../url/url-builder';
import { getToken } from '../auth/AuthProvider';

// Create a history object
const history = createBrowserHistory();

const GENERIC_ERROR_MESSAGE = 'Something is not right.';

export class ClientError extends Error {
  constructor(
    msg: string,
    public status: number,
    public params: Record<string, string> = {}
  ) {
    super(msg);
  }
}

export function addBasePathToPath(path: string): string {
  let basePath = getBasePrefixURL();
  if (!basePath || basePath === '/') return path;
  if (basePath.endsWith('/')) {
    basePath = basePath.slice(0, -1);
  }
  if (path && !path.startsWith('/')) {
    path = `/${path}`;
  }
  return `${basePath}${path}`;
}

export function addBasePathToURL(originUrl: string): string {
  const urlObj = new URL(originUrl);
  urlObj.pathname = addBasePathToPath(urlObj.pathname);
  return urlObj.toString();
}

export function getFullBaseURL() {
  const baseElement = document.head.getElementsByTagName('base')[0];
  let baseHref = baseElement?.getAttribute('href');
  if (baseHref) {
    if (baseHref.endsWith('/')) {
      baseHref = baseHref.slice(0, -1);
    }
    return baseHref;
  }

  return baseElement?.getAttribute('href') || undefined;
}

export function getBasePrefixURL() {
  const fullBaseURL = getFullBaseURL();
  if (!fullBaseURL) {
    return undefined;
  }

  const url = new URL(fullBaseURL);
  return url.pathname;
}

export function getHostAndBase() {
  const host = getApiHost();
  const basePath = getBasePrefixURL();
  if (basePath === '/' || !basePath) {
    return host;
  }
  return `${host}${basePath}`;
}

export function isLocalhostHost(host: string) {
  return host === 'localhost' || host === '127.0.0.1';
}

export function isCloudEnvironment(host: string) {
  return host.endsWith('tensorleap.ai');
}

export function getApiHost() {
  const { host } = document.location;
  if (isCloudEnvironment(host)) {
    return `api.${host}`;
  }
  return host;
}

export function getApiOrigin() {
  const apiHost = getHostAndBase();
  return `${document.location.protocol}//${apiHost}`;
}

export function getBaseUrl() {
  return getFullBaseURL() || getApiOrigin();
}

export function getApiBasePath() {
  return `${getBaseUrl()}/api/v2`;
}

async function parseErrorResponse(response: Response) {
  try {
    const { error, ...otherArgs } = await response.json();
    return {
      msg: error || GENERIC_ERROR_MESSAGE,
      status: response.status,
      params: otherArgs,
    };
  } catch {
    return {
      msg: GENERIC_ERROR_MESSAGE,
      status: response.status,
    };
  }
}

const client = new DefaultApi(
  new Configuration({
    basePath: getApiBasePath(),
    credentials: 'include',
    middleware: [
      {
        pre: async ({ init }) => {
          const token = getToken();
          if (token) {
            init.headers = {
              ...init.headers,
              Authorization: `KBearer ${token}`,
            };
          }
          return;
        },
        post: async ({ response }) => {
          if (response.status === 409) {
            if (history.location.pathname === URLS_ENUM.CONFLICT_USERS) return;
            history.replace(URLS_ENUM.CONFLICT_USERS);
            window.location.reload();
            return;
          }
          if (response.status < 200 || response.status >= 400) {
            const { msg, status, params } = await parseErrorResponse(response);
            throw new ClientError(msg, status, params);
          }
        },
      },
    ],
  })
);

export default client;
