import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgBranch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M13.875 6c-.01-.501-.178-.916-.506-1.244-.328-.328-.743-.497-1.244-.506-.501.01-.916.178-1.244.506-.328.328-.497.743-.506 1.244 0 .328.078.624.232.889.165.255.379.46.643.615v.26c-.018.455-.2.856-.547 1.203-.346.346-.747.528-1.203.547-.72.009-1.303.141-1.75.396V5.754a1.77 1.77 0 0 0 .629-.615 1.65 1.65 0 0 0 .246-.889c-.01-.501-.178-.916-.506-1.244-.328-.328-.743-.497-1.244-.506-.501.01-.916.178-1.244.506-.328.328-.497.743-.506 1.244 0 .328.077.624.232.889.164.255.379.46.643.615v5.742a1.845 1.845 0 0 0-.643.629 1.652 1.652 0 0 0-.232.875c.01.501.178.916.506 1.244.328.328.743.497 1.244.506.501-.01.916-.178 1.244-.506.328-.328.497-.743.506-1.244-.01-.474-.164-.87-.465-1.19.055-.036.141-.104.26-.205a3.3 3.3 0 0 1 .26-.205c.218-.1.492-.15.82-.15.912-.037 1.713-.401 2.406-1.094.347-.355.61-.765.793-1.23.183-.474.283-.944.301-1.408h-.014c.274-.164.488-.374.643-.63A1.65 1.65 0 0 0 13.875 6Zm-7-2.803c.292.01.538.114.738.315.2.2.301.446.301.738s-.1.538-.3.738c-.201.2-.447.301-.739.301s-.538-.1-.738-.3c-.2-.201-.301-.447-.301-.739s.1-.538.3-.738c.201-.2.447-.306.739-.315Zm0 10.856a1.053 1.053 0 0 1-.738-.315c-.2-.2-.301-.446-.301-.738s.1-.538.3-.738c.201-.2.447-.301.739-.301s.538.1.738.3c.2.201.301.447.301.739s-.1.538-.3.738c-.201.2-.447.306-.739.315Zm5.25-7a1.053 1.053 0 0 1-.738-.315c-.2-.2-.301-.446-.301-.738s.1-.538.3-.738c.201-.2.447-.301.739-.301s.538.1.738.3c.2.201.301.447.301.739s-.1.538-.3.738c-.201.2-.447.306-.739.315Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBranch;
