import useSWR, { KeyedMutator } from 'swr';
import { GetScatterSampleVisualizationsResponse } from '@tensorleap/api-client';
import api from '../api-client';
import { CacheKey, REFRESH_INTERVALS } from './consts';
import { useMergedObject } from '../useMergedObject';

export interface UseFetchScatterVisualizationsMap {
  scatterVisualizationsMapResponse?: GetScatterSampleVisualizationsResponse;
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<GetScatterSampleVisualizationsResponse>;
}

export function useFetchScatterVisualizationsMap(
  projectId: string,
  sessionRunId: string,
  epoch: number
): UseFetchScatterVisualizationsMap {
  const { data: scatterVisualizationsMapResponse, error, mutate } = useSWR<
    GetScatterSampleVisualizationsResponse,
    Error
  >(
    `${CacheKey.SESSION_VISUALIZATIONS}-${sessionRunId}-${epoch}`,

    async () => {
      const scatterVisualizationsMapResponse = await api.getScatterSampleVisualizations(
        {
          projectId,
          sessionRunId,
          epoch,
        }
      );
      return scatterVisualizationsMapResponse;
    },
    {
      refreshInterval: REFRESH_INTERVALS.scatterVisualizationsMap,
    }
  );

  return useMergedObject({
    scatterVisualizationsMapResponse,
    error,
    isLoading: !error && !scatterVisualizationsMapResponse,
    refetch: mutate,
  });
}
