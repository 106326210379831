import { ReactNode } from 'react';

export type ButtonPopup = {
  key: string;
  button: ReactNode;
  popup?: ReactNode;
};

export interface DrawerTabsProps {
  drawerButtonsPopups: ButtonPopup[];
}

export function DrawerTabs({ drawerButtonsPopups }: DrawerTabsProps) {
  const drawerButtons = drawerButtonsPopups.map(({ key, button, popup }) => {
    return (
      <div key={key} className="pointer-events-auto h-full w-full relative">
        <div className="w-8">{button}</div>
        {popup && <div className="w-auto absolute right-11 top-8">{popup}</div>}
      </div>
    );
  });

  return (
    <div className="flex flex-col bg-transparent w-8 justify-start items-center pt-24">
      {drawerButtons}
    </div>
  );
}
