import { Redirect, useHistory, useLocation } from 'react-router';
import { CurrentProjectProvider } from '../core/CurrentProjectContext';
import {
  DEFAULT_VERSION_ID,
  SELECTED_SESSION_RUN_KEY,
  URLS_ENUM,
  buildVersionUrl,
  changeQueryParams,
  getArrayValueFromQueryParams,
  getIdFromUrl,
  switchVersionUrl,
} from '../url/url-builder';
import { ProjectPage } from './ProjectPage';
import { PageLoader } from './molecules/PageLoader';
import { useFetchProjects } from '../core/data-fetching/projects';
import { useEffect, useMemo, useState } from 'react';
import api from '../core/api-client';
import { Project } from '@tensorleap/api-client';
import { useLocalStorage } from '../core/useLocalStorage';
import { calcDefaultSessionRunsFromLocalStorageKey } from '../core/VersionControlContext';

const DEFAULT_PROJECT_ID_FROM_LOCAL_STORAGE = 'defaultProjectId';
const DEFAULT_VERSION_ID_PER_PROJECT_FROM_LOCAL_STORAGE = 'defaultVersionId';

export function calcDefaultVersionIdFromLocalStorageKey(projectId: string) {
  return `${DEFAULT_VERSION_ID_PER_PROJECT_FROM_LOCAL_STORAGE}_${projectId}`;
}

export function ProjectLoader(): JSX.Element {
  const location = useLocation();

  const { projects } = useFetchProjects();

  const unparsedDefaultProjectId = window.localStorage.getItem(
    DEFAULT_PROJECT_ID_FROM_LOCAL_STORAGE
  );
  const defaultProjectId = unparsedDefaultProjectId
    ? JSON.parse(unparsedDefaultProjectId)
    : '';

  const [_, setDefaultProjectId] = useLocalStorage(
    DEFAULT_PROJECT_ID_FROM_LOCAL_STORAGE,
    ''
  );

  const projectIdFromUrl = getIdFromUrl(location.pathname, URLS_ENUM.PROJECT);

  const project = useMemo(
    () => projects?.find((p) => p.cid === projectIdFromUrl),
    [projects, projectIdFromUrl]
  );

  useEffect(() => {
    if (project) {
      setDefaultProjectId(project.cid);
    }
  }, [project, setDefaultProjectId]);

  if (!projectIdFromUrl) {
    if (defaultProjectId) {
      return (
        <Redirect to={buildVersionUrl(defaultProjectId, DEFAULT_VERSION_ID)} />
      );
    } else {
      return <Redirect to={URLS_ENUM.WELCOME} />;
    }
  }

  if (projects === undefined) {
    return <PageLoader />;
  }

  if (project === undefined) {
    console.error('project not found, redirecting to welcome page', {
      projectIdFromUrl,
    });
    return <Redirect to={URLS_ENUM.WELCOME} />;
  }

  return <SelectedSessionRunsLoader project={project} key={project.cid} />;
}

export const DEFAULT_SESSION_RUN = 'default-session-run';

interface SelectedSessionRunsLoaderProps {
  project: Project;
}

function SelectedSessionRunsLoader({
  project,
}: SelectedSessionRunsLoaderProps): JSX.Element {
  const [selectedSessionRunIdsFromLocalStorage, __] = useLocalStorage<string[]>(
    calcDefaultSessionRunsFromLocalStorageKey(project.cid),
    [DEFAULT_SESSION_RUN]
  );

  const { pathname, search } = useLocation();

  const selectedSessionRunIdsFromUrl = getArrayValueFromQueryParams(
    search,
    SELECTED_SESSION_RUN_KEY
  );

  const versionIdFromUrl = getIdFromUrl(location.pathname, URLS_ENUM.VERSION);
  const isURLContainVersionId =
    versionIdFromUrl !== undefined && versionIdFromUrl !== DEFAULT_VERSION_ID;
  if (
    !isURLContainVersionId &&
    selectedSessionRunIdsFromLocalStorage.length > 0 &&
    selectedSessionRunIdsFromUrl.length === 0
  ) {
    return (
      <Redirect
        to={{
          pathname,
          search: changeQueryParams(
            search,
            SELECTED_SESSION_RUN_KEY,
            selectedSessionRunIdsFromLocalStorage
          ),
        }}
      />
    );
  }

  return <VersionLoader project={project} key={project.cid} />;
}

interface VersionLoaderProps {
  project: Project;
}

function VersionLoader({ project }: VersionLoaderProps): JSX.Element {
  const location = useLocation();
  const history = useHistory();

  const [defaultVersionId] = useLocalStorage(
    calcDefaultVersionIdFromLocalStorageKey(project.cid),
    ''
  );

  const [versionId, setVersionId] = useState<string | undefined>();

  const [versionWasNotFound, setVersionWasNotFound] = useState(false);

  const versionIdFromUrl = getIdFromUrl(location.pathname, URLS_ENUM.VERSION);

  const { search } = useLocation();

  useEffect(() => {
    function addVersionToUrl(_projectId: string, _versionId: string) {
      history.replace({
        pathname:
          _versionId === DEFAULT_VERSION_ID
            ? switchVersionUrl(window.location.pathname, _versionId)
            : buildVersionUrl(_projectId, _versionId),
        search,
      });
    }

    async function asyncLoadVersion(_projectId: string, _versionId: string) {
      const { version } = await api.loadVersion({
        versionId: _versionId,
        projectId: _projectId,
      });
      if (!version) {
        throw new Error('version not found');
      }

      setVersionId(version.cid);
    }

    async function addLatestValidVersionToURL(_projectId: string) {
      const res = await api.getCurrentProjectVersion({
        projectId: _projectId,
      });
      if (!res) {
        throw new Error('version not found');
      }

      const versionId = res.versionId;

      addVersionToUrl(_projectId, versionId);
    }

    if (
      versionIdFromUrl === undefined ||
      versionIdFromUrl === DEFAULT_VERSION_ID
    ) {
      if (versionId) return;
      asyncLoadVersion(project.cid, defaultVersionId)
        .then(() => {
          addVersionToUrl(project.cid, defaultVersionId);
        })
        .catch(() => {
          addLatestValidVersionToURL(project.cid).catch(() => {
            console.warn('current version was not found', versionIdFromUrl);
            setVersionWasNotFound(true);
          });
        });
    } else {
      asyncLoadVersion(project.cid, versionIdFromUrl).catch(() => {
        console.warn('version from url was not found', versionIdFromUrl);
        setVersionWasNotFound(true);
      });
    }
  }, [
    defaultVersionId,
    history,
    project.cid,
    search,
    versionId,
    versionIdFromUrl,
  ]);

  if (versionWasNotFound) {
    return <Redirect to={URLS_ENUM.WELCOME} />;
  }

  if (versionId === undefined) {
    return <PageLoader />;
  }

  return (
    <CurrentProjectProvider project={project} versionId={versionId}>
      <ProjectPage />
    </CurrentProjectProvider>
  );
}
