import clsx from 'clsx';
import { forwardRef, Ref } from 'react';
import {
  CommandAndBoundingBox,
  normalizeAndBBoxPoints,
  transformPath,
  normalizeAndBBoxPath,
  PointsAndBoundingBox,
  transformPolygon,
} from './svgPathUtlis';
import _ from 'lodash';

type AllShapeData =
  | {
      type: 'circle';
    }
  | {
      type: 'rect';
      scaleX?: number; // 0 - 1
      scaleY?: number; // 0 - 1
    }
  | {
      type: 'ellipse';
      scaleX?: number; // 0 - 1
      scaleY?: number; // 0 - 1
    }
  | {
      type: 'path';
      d: CommandAndBoundingBox;
    }
  | {
      type: 'polygon';
      points: PointsAndBoundingBox;
    };

export const SHAPES = [
  'circle',
  'rectangle',
  'rhombus',
  'triangle',
  'polygon',
  'vertical-ellipse',
  'horizontal-rectangle',
  'quadratic-curve',
  'horizontal-ellipse',
  'isosceles-triangle',
  'vertical-rectangle',
] as const;

export type ShapeType = typeof SHAPES[number];

export const SHAPE_MAP: Record<ShapeType, AllShapeData> = {
  circle: { type: 'circle' },
  rectangle: { type: 'rect' },
  'vertical-ellipse': { type: 'ellipse', scaleX: 1, scaleY: 0.5 },
  rhombus: {
    type: 'path',
    d: normalizeAndBBoxPath([
      { type: 'M', points: [100, 50] },
      { type: 'L', points: [150, 100] },
      { type: 'L', points: [100, 150] },
      { type: 'L', points: [50, 100] },
      { type: 'Z', points: [] },
    ]), // Rhombus path
  },

  'quadratic-curve': {
    type: 'path',
    d: normalizeAndBBoxPath([
      { type: 'M', points: [200, 50] },
      { type: 'Q', points: [250, 100, 300, 50] },
    ]), // Quadratic curve
  },

  triangle: {
    type: 'polygon',
    points: normalizeAndBBoxPoints([
      [0.2, 0.6],
      [0.36, 0.72],
      [0.36, 0.48],
    ]),
  },

  polygon: {
    type: 'path',
    d: normalizeAndBBoxPath([
      { type: 'M', points: [1, 4] },
      { type: 'L', points: [4, 4] },
      { type: 'L', points: [4, 6] },
      { type: 'Z', points: [] },
    ]), // Closed polygon path
  },

  'isosceles-triangle': {
    type: 'path',
    d: normalizeAndBBoxPath([
      { type: 'M', points: [50, 250] },
      { type: 'L', points: [150, 250] },
      { type: 'L', points: [100, 350] },
      { type: 'Z', points: [] },
    ]),
  },

  'horizontal-ellipse': { type: 'ellipse', scaleX: 0.5, scaleY: 1 },

  'vertical-rectangle': { type: 'rect', scaleX: 0.5, scaleY: 1 },

  'horizontal-rectangle': { type: 'rect', scaleX: 1, scaleY: 0.5 },
};

type ScatterShapeProps = {
  id?: string;
  className?: string;
  isSelected?: boolean;
  style?: React.CSSProperties;
  x: number;
  y: number;
  shapeType: ShapeType;
  size: number;
  isHighlighted?: boolean;
  onClick?: (e: React.SyntheticEvent<SVGElement, Event>) => void;
  onMouseDown?: (e: React.MouseEvent<SVGElement, MouseEvent>) => void;
};
const _ScatterShape = (
  {
    id,
    className,
    isSelected,
    x,
    y,
    size,
    style,
    onClick,
    onMouseDown,
    isHighlighted,
    shapeType,
    ...rest
  }: ScatterShapeProps,
  ref: React.Ref<SVGElement>
) => {
  const shape = SHAPE_MAP[shapeType];
  if (isHighlighted) {
    size = size * 1.1;
  }
  style = {
    ...style,
    transition:
      'height 0.1s ease-out, width 0.1s ease-out, stroke 0.1s ease-out, stroke-width 0.1s ease-out, stroke-opacity 0.1s ease-out',
  };

  const commonProps = {
    id,
    style,
    className: clsx(
      className,
      isSelected && 'selected-class',
      isHighlighted && 'hovered-class'
    ),
    onClick,
    onMouseDown,
    ...rest,
  };

  switch (shape.type) {
    case 'circle':
      return (
        <circle
          ref={ref as Ref<SVGCircleElement>}
          cx={x}
          cy={y}
          r={size / 2}
          {...commonProps}
        />
      );
    case 'rect': {
      const xSize = size * (shape.scaleX ?? 1);
      const ySize = size * (shape.scaleY ?? 1);
      return (
        <rect
          x={x - xSize / 2}
          y={y - ySize / 2}
          height={ySize}
          width={xSize}
          {...commonProps}
          ref={ref as Ref<SVGRectElement>}
        />
      );
    }
    case 'ellipse':
      return (
        <ellipse
          cx={x}
          cy={y}
          rx={(size / 2) * (shape.scaleY ?? 1)}
          ry={(size / 2) * (shape.scaleX ?? 1)}
          {...commonProps}
          ref={ref as Ref<SVGEllipseElement>}
        />
      );

    case 'polygon':
      return (
        <polygon
          points={transformPolygon(shape.points, x, y, size)}
          {...commonProps}
          ref={ref as Ref<SVGPolygonElement>}
        />
      );
    case 'path':
      // Use transform to scale the path
      return (
        <path
          d={transformPath(shape.d, x, y, size)}
          {...commonProps}
          ref={ref as Ref<SVGPathElement>}
        />
      );
    default:
      return null;
  }
};

export const ScatterShape = forwardRef<SVGElement, ScatterShapeProps>(
  _ScatterShape
);
ScatterShape.displayName = 'ScatterShape';
