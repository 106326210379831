import { labelsColorSupplier } from '../../../core/color-helper';

export interface LabelCircleSvgProps {
  label: string | number;
  colorMap?: Record<string, string>;
}
export function LabelCircleSvg({
  label,
  colorMap,
}: LabelCircleSvgProps): JSX.Element {
  return (
    <svg height="24" width="24">
      <circle
        cx="12"
        cy="12"
        r="6"
        fill={
          colorMap ? colorMap[label] : labelsColorSupplier.get(String(label))
        }
      />
    </svg>
  );
}
