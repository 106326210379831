import { LinearProgress } from '@material-ui/core';
import { Select, SelectProps } from './Select';
import { useEditableCell, UseEditableCellProps } from './utils/useEditableCell';

export type EditableSelectCellProps<Option> = UseEditableCellProps<
  string | number
> &
  Omit<SelectProps<Option>, 'value' | 'onChange' | 'className'>;

export function EditableSelectCell<Option>({
  value: itemValue,
  validate,
  onChange,
  disabled,
  ...selectProps
}: EditableSelectCellProps<Option>) {
  const { handleSave, value, saving, onBlur, onFocus } = useEditableCell({
    value: itemValue,
    onChange,
    validate,
  });

  return (
    <div className=" relative w-full">
      {saving && <LinearProgress className=" absolute top-0 inset-x-0" />}
      <Select
        small
        disabled={saving || disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={handleSave}
        value={value}
        {...selectProps}
      />
    </div>
  );
}
