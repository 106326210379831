import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { ButtonProps } from '../atoms/Button';
import { useRipple } from '../atoms/utils/useRipple';

export type SupportMenuButtonProps = ButtonProps &
  PropsWithChildren<{
    selected?: boolean;
    icon?: JSX.Element;
  }>;

export const SupportMenuButton = React.forwardRef<
  HTMLButtonElement,
  SupportMenuButtonProps
>(({ selected = false, className = '', icon, children, ...args }, ref) => {
  const ripple = useRipple();

  return (
    <button
      className={clsx('w-full h-24 flex flex-row outline-none', className)}
      onMouseUp={ripple}
      ref={ref}
      {...args}
    >
      <div
        className={clsx('flex flex-row h-full w-4/5 rounded', {
          'bg-gradient-to-t from-[#041442] to-[#1E3A8A] rounded-r-none': selected,
          'bg-gray-800 opacity-60 hover:opacity-100': !selected,
        })}
      >
        <div className="flex h-full w-1/4 justify-center items-center">
          {icon}
        </div>
        <div className="flex h-full w-3/4 pr-2 align-middle text-left tracking-ultrawide justify-start items-center drop-shadow-2xl textShadow uppercase">
          {children}
        </div>
      </div>
      <div className={clsx('h-full w-1/5', { hidden: !selected })}>
        {selected && (
          <svg viewBox="0 0 50 100" height="6rem">
            <defs>
              <linearGradient id="grad" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#1E3A8A" />
                <stop offset="100%" stopColor="#041442" />
              </linearGradient>
            </defs>
            <polygon points="0,0 25,50 0,100" fill="url(#grad)" />
          </svg>
        )}
      </div>
    </button>
  );
});
SupportMenuButton.displayName = 'SupportMenuButton';
