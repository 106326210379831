import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgLightning2Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M5.99 16.8c-.048-.048.162-.612.63-1.692l1.422-3.276c.48-1.08.708-1.668.684-1.764-.012-.072-.576-.342-1.692-.81-1.104-.468-1.692-.798-1.764-.99-.06-.144.444-.864 1.512-2.16a104.516 104.516 0 0 1 3.24-3.762c.564-.624.984-1.074 1.26-1.35.288-.288.444-.42.468-.396.072.048-.372 1.17-1.332 3.366-.96 2.184-1.428 3.294-1.404 3.33.012.048.582.306 1.71.774 1.104.468 1.686.81 1.746 1.026.06.24-.984 1.602-3.132 4.086S6.074 16.872 5.99 16.8Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLightning2Icon;
