import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="m9.871 4.1-4.68 4.681a.318.318 0 0 0 0 .462l4.68 4.681a.317.317 0 0 0 .462 0l.502-.502a.318.318 0 0 0 0-.462L6.888 9.012l3.947-3.947a.317.317 0 0 0 0-.462l-.502-.503a.318.318 0 0 0-.462 0Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBack;
