import { AllSessionsTestResults, SessionTest } from '@tensorleap/api-client';
import clsx from 'clsx';
import { useMemo } from 'react';
import { CircledIcon } from '../ui/atoms/CircledIcon';
import {
  FailedIcon,
  NotIcon,
  PencilIcon,
  Trash,
  SaveAs,
  TestIcon,
  XCloseIcon2,
  Up,
  Down,
} from '../ui/icons';
import { useModelFilter } from '../ui/molecules/useModelFilter';
import { getFormattedModelTestName } from './modelTestHelpers';
import { ModelTestResultrange } from './modelTestResultRange';
import { useStopPropagation } from '../core/stopPropagation';
import { isSessionTestResultSuccess } from './useModelTestSummaryResult';
import { IconButton } from '../ui/mui';
import { ModelTestStatusIcon } from './ModelTestStatusIcon';

export type ModelTestsCardTitleProps = {
  modelTest: SessionTest;
  modelTestsResults?: AllSessionsTestResults;
  className?: string;
  isOpen: boolean;
  editable: boolean;
  disabledEdit: boolean;
  onEdit: () => void;
  onDelete: (id: string) => void;
  onSubmit: () => void;
  onClose: () => void;
};

export function ModelTestsCardTitle({
  modelTest,
  modelTestsResults,
  className,
  isOpen,
  editable,
  disabledEdit,
  onEdit,
  onDelete,
  onSubmit,
  onClose,
}: ModelTestsCardTitleProps): JSX.Element {
  const { selected: selectedModels } = useModelFilter();

  const failedModels = useMemo(
    () =>
      modelTestsResults?.sessionsResults
        ? modelTestsResults.sessionsResults.filter(
            (result) =>
              !isSessionTestResultSuccess(result) || !result.testSucceeded
          )
        : [],
    [modelTestsResults?.sessionsResults]
  );

  const selectedFailedModels = useMemo(() => {
    const failedModelIds = failedModels.map((model) => model.sessionRunId);

    return selectedModels.filter((selectedModel) =>
      failedModelIds.some((failedId) => failedId === selectedModel.id)
    );
  }, [failedModels, selectedModels]);

  const testQueryFailed = useMemo(() => {
    const result: Record<string, boolean> = {};
    const failedModelIds = failedModels.map((failedModel) => {
      if (
        selectedModels.some(
          (selectedModel) => selectedModel.id === failedModel.sessionRunId
        )
      ) {
        return failedModel;
      }
    });
    failedModelIds
      .filter((x) => x !== null)
      .map((failedModel) => {
        if (failedModel?.sessionRunId) {
          result[failedModel?.sessionRunId] = !isSessionTestResultSuccess(
            failedModel
          );
        }
      });
    return result;
  }, [failedModels, selectedModels]);

  const handleEdit = useStopPropagation(onEdit, []);
  const handleDelete = useStopPropagation(() => {
    onDelete(modelTest.cid);
  }, [modelTest.cid, onDelete]);

  const parsedTitle = useMemo(() => getFormattedModelTestName(modelTest), [
    modelTest,
  ]);

  return (
    <div
      className={clsx(
        'group w-full h-28 grid grid-rows-3 grid-cols-8 py-1',
        isOpen ? 'bg-primary-950' : 'bg-gray-900',
        className
      )}
    >
      <div className="row-span-4 flex justify-center items-center">
        {!modelTestsResults ? (
          <CircledIcon text="NO RESULTS" borderStyle="!bg-gray-500 !w-8 !h-8">
            <NotIcon className="!w-5 !h-5" />
          </CircledIcon>
        ) : failedModels.length === 0 ? (
          <CircledIcon text="SUCCESS" borderStyle="!bg-success-700 !w-8 !h-8">
            <TestIcon className="!w-5 !h-5" />
          </CircledIcon>
        ) : (
          <CircledIcon text="FAILURE" borderStyle="!bg-error-700 !w-8 !h-8">
            <FailedIcon className="!w-5 !h-5" />
          </CircledIcon>
        )}
      </div>
      <div
        className={clsx(
          'col-span-6 flex flex-col mr-4 items-start justify-center',
          modelTestsResults ? 'row-span-2' : 'row-span-3'
        )}
      >
        {modelTest.name && (
          <span className="text-gray-300 text-lg capitalize">
            {modelTest.name}
          </span>
        )}
        <span
          className={clsx(
            'capitalize flex justify-start items-center',
            modelTest.name ? 'text-gray-500 text-xs' : 'text-gray-300 text-lg'
          )}
        >
          {parsedTitle}
        </span>
      </div>
      <div className="flex mt-2 items-start justify-end">
        {editable ? (
          <>
            <IconButton className="h-8 w-8" type="submit" onClick={onSubmit}>
              <SaveAs className="text-success-500" />
            </IconButton>
            <IconButton className="h-8 w-8" onClick={onClose}>
              <XCloseIcon2 className="text-error-500" />
            </IconButton>
          </>
        ) : (
          <div className="group-hover:flex hidden">
            <IconButton
              disabled={!!disabledEdit}
              className="h-8 w-8"
              onClick={handleEdit}
            >
              <PencilIcon className="h-4 w-4" />
            </IconButton>
            <IconButton className="h-8 w-8" onClick={handleDelete}>
              <Trash className="h-4 w-4" />
            </IconButton>
          </div>
        )}
      </div>
      <ModelTestResultrange
        className="col-start-2 col-span-3 mb-4"
        modelTestsResults={modelTestsResults}
      />
      <div className="col-span-4 col-end-9 flex justify-end items-start mr-2 gap-1">
        {selectedFailedModels.map((model, index) => (
          <ModelTestStatusIcon
            key={index}
            name={model.name}
            modelUniqueKey={model.modelUniqueKey}
            status={testQueryFailed[model.id] ? 'na' : 'failed'}
          />
        ))}
        {isOpen ? <Up /> : <Down />}
      </div>
    </div>
  );
}
