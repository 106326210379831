import { Box, createStyles, InputBase, makeStyles, MenuProps } from '../ui/mui';
import { Forward, MagnifyingGlass, FakeIcon } from '../ui/icons';
import { UI_COMPONENTS } from '../core/types/ui-components';
import { getOrSetDefault } from '../core/map-helper';
import { useCallback, useMemo, useState } from 'react';
import Menu from 'material-ui-popup-state/HoverMenu';
import {
  usePopupState,
  bindHover,
  bindMenu,
  PopupState,
} from 'material-ui-popup-state/hooks';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      '&:last-child': { borderBottom: 'none' },
      '&:hover': { backgroundColor: theme.palette.grey[700] },
    },
  })
);

interface MenuItemNode {
  title: string;
  items?: MenuItemNode[];
}
const CONTEXT_MENU = prepareContextMenu();

export function MapContextMenu({
  onItemClick,
}: {
  onItemClick: (title: string) => void;
}): JSX.Element {
  const [searchText, setSearchText] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearchText(event.target.value);
  };

  const menuItems = useMemo(
    () =>
      searchText ? searchRecoursive(searchText, CONTEXT_MENU) : CONTEXT_MENU,
    [searchText]
  );

  return (
    <div className="flex flex-col w-72 bg-gray-850">
      <div className="flex flex-row justify-between h-10 px-4">
        <InputBase placeholder="Add a new Layer" onChange={handleChange} />
        <MagnifyingGlass />
      </div>
      <MapMenu menuItems={menuItems} onItemClick={onItemClick} />
    </div>
  );
}

function MapMenu({
  menuItems,
  onItemClick,
  parentPopupState,
}: {
  menuItems: MenuItemNode[];
  onItemClick: (title: string) => void;
  parentPopupState?: PopupState;
}): JSX.Element {
  return (
    <>
      {menuItems.map((menuItem, index) => (
        <ContextMenuItem
          key={index}
          menuItem={menuItem}
          onItemClick={onItemClick}
          parentPopupState={parentPopupState}
        />
      ))}
    </>
  );
}

const STATIC_MENU_PROPS: Partial<MenuProps> = {
  MenuListProps: { disablePadding: true },
  anchorOrigin: { vertical: 'top', horizontal: 'right' },
};

export default function ContextMenuItem({
  menuItem,
  onItemClick,
  parentPopupState,
}: {
  menuItem: MenuItemNode;
  onItemClick: (title: string) => void;
  parentPopupState?: PopupState;
}): JSX.Element {
  const { title, items } = menuItem;
  const classes = useStyles();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `popupId-${title}`,
    parentPopupState: parentPopupState,
  });

  const handleItemClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      if (!items) {
        onItemClick(title);
      }
    },
    [title, onItemClick, items]
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      className={clsx(
        'w-72 h-9 bg-gray-850 border-b border-solid border-gray-700',
        classes.menuItem
      )}
      onClick={handleItemClick}
      {...bindHover(popupState)}
      px={2}
    >
      <div className="flex flex-row items-center">
        <FakeIcon />
        <h6 className="font-semibold text-lg tracking-normal ml-1 capitalize">
          {title}
        </h6>
      </div>
      {items && items.length > 0 && (
        <div>
          <Forward />
          <Menu {...bindMenu(popupState)} {...STATIC_MENU_PROPS}>
            <MapMenu
              menuItems={items}
              onItemClick={onItemClick}
              parentPopupState={parentPopupState}
            />
          </Menu>
        </div>
      )}
    </Box>
  );
}

function searchRecoursive(
  searchText: string,
  menuItems: MenuItemNode[]
): MenuItemNode[] {
  return menuItems.reduce((acc: MenuItemNode[], menuItem: MenuItemNode) => {
    if (menuItem.items) {
      acc.push(...searchRecoursive(searchText, menuItem.items));
      return acc;
    }
    if (menuItem.title.toLowerCase().includes(searchText.toLowerCase())) {
      acc.push({ title: menuItem.title });
    }
    return acc;
  }, []);
}

function prepareContextMenu(): MenuItemNode[] {
  const menuItemMap = UI_COMPONENTS.reduce((ret, descriptor) => {
    if (descriptor.menu_sections.length === 0) return ret;
    const subMenuName =
      descriptor.menu_sections.length > 1
        ? descriptor.menu_sections[1]
        : descriptor.type;

    const subMenu = getOrSetDefault(ret, descriptor.type, () => new Map());
    getOrSetDefault(subMenu, subMenuName, () => []).push(descriptor.name);
    return ret;
  }, new Map<string, Map<string, string[]>>());

  const contextMenu: MenuItemNode[] = [];
  menuItemMap.forEach((subMenuMap, type) => {
    const keys = Array.from(subMenuMap.keys());
    if (keys.length === 1 && keys[0] === type) {
      const keyItems = subMenuMap.get(type);
      if (!keyItems || keyItems.length === 0) return;
      contextMenu.push(
        keyItems.length > 1
          ? { title: type, items: keyItems.map((title) => ({ title })) }
          : { title: keyItems[0] }
      );
      return;
    }

    const items = keys.map((key) => ({
      title: key,
      items: subMenuMap.get(key)?.map((title) => ({ title })) || [],
    }));

    contextMenu.push({ title: type, items });
  });

  return contextMenu;
}
