import useSWR, { KeyedMutator } from 'swr';
import { SlimVersion } from '@tensorleap/api-client';
import api from '../api-client';
import { CacheKey, REFRESH_INTERVALS } from './consts';
import { useMergedObject } from '../useMergedObject';
import { useCallback, useState } from 'react';

export interface UseFetchVersionControl {
  versions: SlimVersion[];
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<SlimVersion[]>;
}
export function useFetchVersionControl(
  projectId: string | undefined
): UseFetchVersionControl {
  const [isLoading, setIsLoading] = useState(true);
  const { data: versions = [], error, mutate } = useSWR<SlimVersion[], Error>(
    `${CacheKey.VERSION_CONTROL}/${projectId}`,
    async () => {
      if (!projectId) return [];

      const { versions } = await api.getProjectSlimVersions({ projectId });
      const sortedVersions = versions.sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : -1
      );
      setTimeout(() => setIsLoading(false), 0);
      return sortedVersions;
    },
    {
      refreshInterval: REFRESH_INTERVALS.versions,
    }
  );

  if (error) {
    console.error(error);
  }
  const refetch = useCallback(async () => {
    setIsLoading(true);
    try {
      return await mutate();
    } finally {
      setIsLoading(false);
    }
  }, [mutate]);

  return useMergedObject({
    versions,
    error,
    isLoading,
    refetch,
  });
}
