import clsx from 'clsx';
import { useState, useMemo } from 'react';
import { NotificationCard } from './NotificationCard';
import { Notification } from '@tensorleap/api-client';

export interface GroupedNotificationsCardProps {
  notifications: Notification[];
}

export function GroupedNotificationsCard({
  notifications,
}: GroupedNotificationsCardProps): JSX.Element {
  const [isStacked, setIsStacked] = useState(true);

  const orderedNotifications = useMemo(
    () => (isStacked ? notifications.slice(0).reverse() : notifications),
    [notifications, isStacked]
  );

  return (
    <div
      className={clsx('flex flex-col px-4', isStacked && 'group')}
      onClick={() => setIsStacked(false)}
    >
      {orderedNotifications.map((notification, index) => {
        return (
          <div
            className={calcStackCardContainer(
              notifications.length,
              index,
              isStacked
            )}
            key={index}
          >
            <NotificationCard
              {...notification}
              cardClassName={calcStackCardClassName(
                notifications.length,
                index,
                isStacked
              )}
            />
          </div>
        );
      })}
    </div>
  );
}

type StackedCardConfigKey = '0' | '1' | '2' | '3' | 'hiddenCard';

const StackedCardConfig = {
  notStacked: {
    containerClassName: 'pb-1 min-w-[360px] max-h-[1000px] duration-700',
    cardClassName: 'w-[360px]',
  },
  stacked: {
    containerClassName: {
      '0':
        'max-h-1 overflow-hidden ml-[15px] group-hover:max-h-9 group-hover:ml-[3px] group-hover:-mt-2 group-hover:cursor-pointer duration-300',
      '1':
        'max-h-1 overflow-hidden ml-[10px] group-hover:max-h-9 group-hover:ml-[2px] group-hover:-mt-2 group-hover:cursor-pointer duration-300',
      '2':
        'max-h-2 overflow-hidden ml-[5px] group-hover:max-h-9 group-hover:ml-[1px] group-hover:-mt-2 group-hover:cursor-pointer duration-300',
      '3': 'group-hover:-mt-2 group-hover:cursor-pointer duration-300',

      hiddenCard:
        'max-h-0 overflow-hidden ml-[15px] group-hover:max-h-9 group-hover:ml-[3px] group-hover:-mt-2 group-hover:cursor-pointer duration-300',
    },
    cardClassName: {
      '0':
        'w-[330px] group-hover:w-[354px] duration-300 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]',
      '1':
        'w-[340px] group-hover:w-[356px] duration-300 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]',
      '2':
        'w-[350px] group-hover:w-[358px] duration-300 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]',
      '3': 'w-[360px] duration-300 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]',

      hiddenCard:
        'w-[330px] group-hover:w-[354px] duration-300 drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]',
    },
  },
};

function calcStackCardContainer(
  numOfNotifications: number,
  index: number,
  isStacked: boolean
): string {
  if (!isStacked) return StackedCardConfig['notStacked']['containerClassName'];

  const key = calcKey(numOfNotifications, index);

  return StackedCardConfig['stacked']['containerClassName'][key];
}
function calcStackCardClassName(
  numOfNotifications: number,
  index: number,
  isStacked: boolean
): string {
  if (!isStacked) return StackedCardConfig['notStacked']['cardClassName'];
  const key = calcKey(numOfNotifications, index);
  return StackedCardConfig['stacked']['cardClassName'][key];
}
function calcKey(
  numOfNotifications: number,
  index: number
): StackedCardConfigKey {
  const distacneFromLastCard = numOfNotifications - index;

  return distacneFromLastCard > 4
    ? 'hiddenCard'
    : `${(4 - distacneFromLastCard).toString() as '0' | '1' | '2' | '3'}`;
}
