import { useMemo } from 'react';
import { Title } from '../ui/atoms/Title';
import { InsightsBySessionRunAndEpoch } from './InsightsContext';
import { SessionRunAndEpochInsights } from './SessionRunAndEpochInsights';
import { useDashboardContext } from '../dashboard/DashboardContext';
import { PinnedInsight } from './PinnedInsight';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';

export type InsightsDrawerProps = {
  data: InsightsBySessionRunAndEpoch[];
};

export function InsightsDrawerContent({
  data,
}: InsightsDrawerProps): JSX.Element {
  const sortedData = useMemo(
    () =>
      data.sort((a, b) =>
        a.selectedSessionRun.id < b.selectedSessionRun.id ? -1 : 1
      ),
    [data]
  );

  const { displayPreviousInsight, displayedInsight } = useDashboardContext();

  return (
    <div className="flex flex-col bg-transparent w-full h-full gap-3 mr-auto ml-auto px-6">
      <Title bottomBorderClassName="border-b-insight-500">insights</Title>
      {displayedInsight && (
        <PinnedInsight
          displayedInsight={displayedInsight}
          displayPreviousInsight={displayPreviousInsight}
        />
      )}
      <div
        className="h-full w-full flex flex-col items-center gap-2 overflow-auto overflow-x-hidden scrollbar-hide pb-4 mb-20 text-sm"
        id={TOUR_SELECTORS_ENUM.INSIGHTS_LIST_ID}
      >
        {sortedData.map(
          ({ selectedSessionRun, epoch, data: insightPerDigestData }) => (
            <SessionRunAndEpochInsights
              key={`${selectedSessionRun.sessionId}-${epoch}`}
              selectedSessionRun={selectedSessionRun}
              epoch={epoch}
              data={insightPerDigestData}
              showBottomBorder
            />
          )
        )}
      </div>
    </div>
  );
}
