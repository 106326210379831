import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgMultiDsDashletIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.639 13.194a2 2 0 0 0 1.986 1.764h9.222a2 2 0 0 0 2-2V7.625a2 2 0 0 0-1.764-1.986c.01.077.014.156.014.236v.782a1 1 0 0 1 .75.968v5.333a1 1 0 0 1-1 1H5.625a1 1 0 0 1-.969-.75h-.781c-.08 0-.159-.004-.236-.014Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.222 4H3a1 1 0 0 0-1 1v5.333a1 1 0 0 0 1 1h9.222a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1ZM3 3a2 2 0 0 0-2 2v5.333a2 2 0 0 0 2 2h9.222a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H3Z"
      fill="currentColor"
    />
    <path
      d="m8.42 10.007 2.133-4.703c.132-.298.066-.52-.197-.67-.277-.148-.5-.087-.671.183L6.9 9.42c-.21.311-.224.629-.04.953.159.27.389.406.692.406.395 0 .684-.257.869-.77ZM10.672 6.642c.224-.216.434-.21.632.02.908 1 1.362 2.203 1.362 3.609 0 .122-.046.23-.138.324a.397.397 0 0 1-.296.122.49.49 0 0 1-.336-.122.492.492 0 0 1-.118-.324c0-1.149-.375-2.142-1.126-2.98-.197-.244-.19-.46.02-.65ZM8.282 5.81a.392.392 0 0 1-.316.102l-.177-.02c-.119 0-.178.006-.178.02-1.159 0-2.146.426-2.962 1.277-.803.852-1.205 1.886-1.205 3.102 0 .122-.046.23-.138.325a.42.42 0 0 1-.316.121.465.465 0 0 1-.316-.121.492.492 0 0 1-.119-.325c0-1.46.494-2.703 1.482-3.73C5.024 5.52 6.215 5 7.61 5c.04 0 .105.006.197.02h.237a.439.439 0 0 1 .297.162.417.417 0 0 1 .098.324.458.458 0 0 1-.158.305Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMultiDsDashletIcon;
