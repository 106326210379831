import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M11 3h3c.438 0 .797.14 1.078.422.281.281.422.64.422 1.078v.75H20v1.5h-.75v12a2.17 2.17 0 0 1-.656 1.594A2.17 2.17 0 0 1 17 21H8a2.17 2.17 0 0 1-1.594-.656 2.17 2.17 0 0 1-.656-1.594v-12H5v-1.5h4.5V4.5c0-.438.14-.797.422-1.078C10.203 3.14 10.562 3 11 3Zm0 1.5v.75h3V4.5h-3ZM7.25 6.75v12c0 .219.063.406.188.563A.872.872 0 0 0 8 19.5h9c.219 0 .39-.063.516-.188a.767.767 0 0 0 .234-.562v-12H7.25ZM8.75 9h1.5v8.25h-1.5V9Zm3 0h1.5v8.25h-1.5V9Zm3 0h1.5v8.25h-1.5V9Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTrash;
