import { useMemo } from 'react';
import clsx from 'clsx';
import { DatasetSetup, ModelSetup } from '@tensorleap/api-client';
import { createStyles, makeStyles } from '../ui/mui';
import {
  DatasetListItemInput,
  DatasetMask,
  GeneratePreprocess,
  parseDatasetVersion,
} from './dataset-helper';
import { NodeDetailsPanel } from './NodeDetalisPanel';
import { Input } from '../ui/atoms/Input';
import { useDatasets } from '../core/DatasetsContext';
import { useNetworkMapContext } from '../core/NetworkMapContext';

const useStyles = makeStyles((theme) =>
  createStyles({
    '@keyframes flashError': {
      '0%': { backgroundColor: 'transparent' },
      '50%': { backgroundColor: theme.palette.error.main },
      '100%': { backgroundColor: 'transparent' },
    },
    flashError: {
      animation: `$flashError .5s ${theme.transitions.easing.easeInOut}`,
    },
  })
);

interface DatasetVersionPropertiesProps {
  datasetSetup?: DatasetSetup;
  modelSetup?: ModelSetup;
  selectedErrorFn?: string;
}
export function DatasetVersionProperties({
  datasetSetup,
  modelSetup,
  selectedErrorFn,
}: DatasetVersionPropertiesProps): JSX.Element {
  const { errors } = useDatasets();

  const { validateAssetsData } = useNetworkMapContext();

  const datasetListItems = useMemo(
    () =>
      parseDatasetVersion(
        DatasetMask.All,
        datasetSetup,
        modelSetup,
        validateAssetsData
      ),
    [datasetSetup, modelSetup, validateAssetsData]
  );

  return (
    <>
      <GeneratePreprocess datasetSetup={datasetSetup} />
      <div className="flex flex-col overflow-y-auto">
        {datasetListItems.map((item, index) => (
          <DatasetListItem
            {...item}
            key={index}
            selected={item.fnName === selectedErrorFn}
            error={errors?.[item.fnName]}
          />
        ))}
      </div>
    </>
  );
}

export function DatasetListItem({
  title,
  icon,
  subItems,
  error,
  openByDefault,
  selected,
  validateAssetsResult,
  validateAssetsError,
}: DatasetListItemInput): JSX.Element {
  const classes = useStyles();
  return (
    <NodeDetailsPanel
      openByDefault={openByDefault}
      icon={icon}
      headerClassName={clsx(selected && classes.flashError)}
      error={error}
      title={title}
      className="p-4 gap-8"
      enableTooltip
      validateAssetsResult={validateAssetsResult}
      validateAssetsError={validateAssetsError}
    >
      {subItems.map(({ title, value }) => (
        <Input key={title} label={title} value={value} readOnly />
      ))}
    </NodeDetailsPanel>
  );
}
