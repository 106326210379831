import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgMagnifyingGlass = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M6.25 3.86C6.807 3.62 7.39 3.5 8 3.5c.61 0 1.191.12 1.746.36a4.494 4.494 0 0 1 1.438.956c.4.401.72.88.957 1.438.24.555.359 1.137.359 1.746a4.42 4.42 0 0 1-.258 1.504c-.17.48-.411.914-.726 1.305l2.84 2.836A.484.484 0 0 1 14.5 14a.475.475 0 0 1-.145.355.475.475 0 0 1-.355.145.484.484 0 0 1-.355-.145l-2.836-2.84c-.391.316-.826.558-1.305.727A4.42 4.42 0 0 1 8 12.5c-.61 0-1.193-.12-1.75-.36a4.507 4.507 0 0 1-1.434-.956 4.56 4.56 0 0 1-.96-1.434A4.427 4.427 0 0 1 3.5 8c0-.61.118-1.191.355-1.746.24-.557.56-1.037.961-1.438.401-.4.88-.72 1.434-.957Zm3.11.917A3.422 3.422 0 0 0 8 4.5c-.474 0-.927.092-1.36.277a3.516 3.516 0 0 0-1.117.746 3.516 3.516 0 0 0-.746 1.118A3.421 3.421 0 0 0 4.5 8c0 .474.092.927.277 1.36.185.432.434.804.746 1.117.313.312.685.56 1.118.746.432.185.885.277 1.359.277s.927-.092 1.36-.277a3.516 3.516 0 0 0 1.117-.746c.312-.313.56-.685.746-1.118.185-.432.277-.885.277-1.359s-.092-.927-.277-1.36a3.516 3.516 0 0 0-.746-1.117 3.516 3.516 0 0 0-1.118-.746Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMagnifyingGlass;
