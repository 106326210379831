import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgInputIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <g filter="url(#input-icon_svg__a)">
      <path
        d="M18.75 5.258H5.25c-.825 0-1.5.675-1.5 1.5V9.75h1.5V6.742h13.5v10.523H5.25V14.25h-1.5v3.008c0 .825.675 1.485 1.5 1.485h13.5c.825 0 1.5-.66 1.5-1.485v-10.5a1.5 1.5 0 0 0-1.5-1.5ZM11.25 15l3-3-3-3v2.25h-7.5v1.5h7.5V15Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="input-icon_svg__a"
        x={3.75}
        y={5.258}
        width={16.5}
        height={14.485}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_12056_117374"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_12056_117374"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SvgInputIcon;
