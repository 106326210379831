import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgCheckedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 5h-6c-.414 0-.768.147-1.063.441A1.442 1.442 0 0 0 5 6.5v6c0 .414.146.767.438 1.059.294.294.648.441 1.062.441h6c.414 0 .767-.147 1.059-.441.294-.292.441-.645.441-1.059v-6c0-.414-.147-.767-.441-1.059A1.436 1.436 0 0 0 12.5 5Zm-6 1h6c.138 0 .255.05.352.148A.472.472 0 0 1 13 6.5v6c0 .138-.05.255-.148.352A.473.473 0 0 1 12.5 13h-6a.485.485 0 0 1-.355-.148A.479.479 0 0 1 6 12.5v-6c0-.138.048-.255.145-.352A.485.485 0 0 1 6.5 6Zm1.997 5.387c.118.15.267.224.448.224l.016-.016a.501.501 0 0 0 .448-.256l2.8-4.48a.599.599 0 0 0 .064-.44.551.551 0 0 0-.24-.328.599.599 0 0 0-.44-.064.551.551 0 0 0-.328.24L8.897 10.06l-.944-1.232a.589.589 0 0 0-.376-.224c-.234-.032-.413.059-.536.272a.555.555 0 0 0 .016.624l1.44 1.888Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCheckedIcon;
