import React, { useCallback, useMemo } from 'react';
import {
  VisAssetWithIdentity,
  VisPayloadElements,
  VisPayloadType,
} from './visDataHelpers';
import { Button } from '../../../ui/atoms/Button';
import clsx from 'clsx';
import { truncateLongtail } from '../../../core/formatters/string-formatting';
import { Tooltip } from '../../../ui/mui';
import SvgForward from '../../../ui/icons/Forward';
import { DisplayVisualizedItemPreview } from './VisData';
import { VisualizedItem } from '@tensorleap/api-client';
import { TOUR_SELECTORS_ENUM } from '../../../tour/ToursConfig';

const MAX_NAME_LENGTH = 10;

export interface VisAssetMenuProps {
  visPayloadElements?: VisPayloadElements;
  selectedVisElement?: Record<string, VisAssetWithIdentity[]>;
  selectedPayloadType: VisPayloadType;
  setSelectedPayloadType: (payloadType: VisPayloadType) => void;
  selectedGroupId?: string;
  setSelectedGroupId: (groupId?: string) => void;
}

export const VisAssetMenu = React.memo(function VisAssetMenu(
  props: VisAssetMenuProps
): JSX.Element {
  const { visPayloadElements } = props;

  const metadata = useMemo(
    () => visPayloadElements?.[VisPayloadType.Metadata],
    [visPayloadElements]
  );
  const analysis = useMemo(
    () => visPayloadElements?.[VisPayloadType.Analysis],
    [visPayloadElements]
  );
  const visualizations = useMemo(
    () => visPayloadElements?.[VisPayloadType.Visualization],
    [visPayloadElements]
  );

  return (
    <div className="flex flex-col w-48 min-w-[12rem] h-full gap-2 py-2 overflow-x-hidden overflow-y-auto">
      {!!metadata && <VisAssetsMetadata {...props} />}
      {analysis && Object.keys(analysis).length > 0 && (
        <VisAssetsAnalysis {...props} />
      )}
      {visualizations && Object.keys(visualizations).length > 0 && (
        <VisAssetsVisualizations {...props} />
      )}
    </div>
  );
});

const VisAssetsMetadata = React.memo(function VisAssetsMetadata({
  visPayloadElements,
  selectedVisElement,
  setSelectedPayloadType,
}: VisAssetMenuProps): JSX.Element {
  const metadataExists = useMemo(
    () =>
      Object.values(visPayloadElements?.[VisPayloadType.Metadata] || {}).some(
        (metadata) => metadata.length > 0
      ),
    [visPayloadElements]
  );

  const isSelected = useMemo(
    () => selectedVisElement === visPayloadElements?.[VisPayloadType.Metadata],
    [selectedVisElement, visPayloadElements]
  );

  const handleClick = useCallback(
    () => setSelectedPayloadType(VisPayloadType.Metadata),
    [setSelectedPayloadType]
  );

  if (!metadataExists || !visPayloadElements) {
    return <></>;
  }

  return (
    <VisAssetMenuButton onClick={handleClick} isSelected={!!isSelected}>
      <Tooltip title="Metadata" placement="bottom" arrow disableHoverListener>
        <span>metadata</span>
      </Tooltip>
    </VisAssetMenuButton>
  );
});

const VisAssetsAnalysis = React.memo(function VisAssetsAnalysis({
  visPayloadElements,
  selectedVisElement,
  setSelectedPayloadType,
  setSelectedGroupId,
  selectedGroupId,
}: VisAssetMenuProps): JSX.Element {
  const analysisElements = useMemo(() => {
    if (!visPayloadElements) {
      return [];
    }
    return Object.entries(
      visPayloadElements[VisPayloadType.Analysis] || {}
    ).map(([name, visPayloadElementArray]) => {
      const uniqueVisElements = new Map<string, VisualizedItem>();
      Object.values(visPayloadElementArray).forEach((visPayloadElements) => {
        visPayloadElements.forEach((visElement) => {
          const visualizedItem = visElement.data as VisualizedItem;
          if (
            visualizedItem &&
            !uniqueVisElements.has(visualizedItem.visualizer_name)
          ) {
            uniqueVisElements.set(
              visualizedItem.visualizer_name,
              visualizedItem
            );
          }
        });
      });

      return Array.from(
        uniqueVisElements.values()
      ).map((visualizedItem, idx) => (
        <VisAnalysisVisualizationButtons
          key={`${name}-${idx}`}
          selectedVisElement={selectedVisElement}
          visPayloadElement={visPayloadElementArray}
          name={name}
          visualizedItem={visualizedItem}
          setSelectedPayloadType={setSelectedPayloadType}
          setSelectedGroupId={setSelectedGroupId}
          type={VisPayloadType.Analysis}
          tourId={TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_VIS_ASSET_ITEM_ID}
          selectedGroupId={selectedGroupId}
        />
      ));
    });
  }, [
    visPayloadElements,
    selectedVisElement,
    setSelectedPayloadType,
    setSelectedGroupId,
    selectedGroupId,
  ]);

  if (!visPayloadElements) {
    return <></>;
  }

  return (
    <div
      className="flex flex-col h-fit w-full"
      id={TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_VIS_ASSETS_MENU_ID}
    >
      <AssetMenuTitle title="Analysis" />
      {analysisElements}
    </div>
  );
});

const VisAssetsVisualizations = React.memo(function VisAssetsVisualizations({
  visPayloadElements,
  selectedVisElement,
  setSelectedPayloadType,
  setSelectedGroupId,
  selectedGroupId,
}: VisAssetMenuProps): JSX.Element {
  const visualizationElements = useMemo(() => {
    if (!visPayloadElements) {
      return [];
    }
    return Object.entries(
      visPayloadElements[VisPayloadType.Visualization] || {}
    ).map(([name, visPayloadElementArray]) => {
      const uniqueVisElements = new Map<string, VisualizedItem>();
      Object.values(visPayloadElementArray).forEach((visPayloadElements) => {
        visPayloadElements.forEach((visElement) => {
          const visualizedItem = visElement.data as VisualizedItem;
          if (
            visualizedItem &&
            !uniqueVisElements.has(visualizedItem.visualizer_name)
          ) {
            uniqueVisElements.set(
              visualizedItem.visualizer_name,
              visualizedItem
            );
          }
        });
      });

      return Array.from(
        uniqueVisElements.values()
      ).map((visualizedItem, idx) => (
        <VisAnalysisVisualizationButtons
          key={`${name}-${idx}`}
          selectedVisElement={selectedVisElement}
          visPayloadElement={visPayloadElementArray}
          name={name}
          visualizedItem={visualizedItem}
          setSelectedPayloadType={setSelectedPayloadType}
          setSelectedGroupId={setSelectedGroupId}
          type={VisPayloadType.Visualization}
          selectedGroupId={selectedGroupId}
        />
      ));
    });
  }, [
    visPayloadElements,
    selectedVisElement,
    setSelectedPayloadType,
    setSelectedGroupId,
    selectedGroupId,
  ]);

  if (!visPayloadElements) {
    return <></>;
  }

  return (
    <div
      className="flex flex-col h-fit w-full uppercase"
      id={
        TOUR_SELECTORS_ENUM.POPULATION_EXPLORATION_RIGHT_PANEL_VISUALIZATIONS_ID
      }
    >
      <AssetMenuTitle title="Visualizations" />
      {visualizationElements}
    </div>
  );
});

interface VisAnalysisVisualizationButtonsProps {
  selectedVisElement?: Record<string, VisAssetWithIdentity[]>;
  setSelectedPayloadType: (payloadType: VisPayloadType) => void;
  setSelectedGroupId: (groupId?: string) => void;
  visPayloadElement: Record<string, VisAssetWithIdentity[]>;
  name: string;
  visualizedItem: VisualizedItem;
  type: VisPayloadType;
  tourId?: string;
  selectedGroupId?: string;
}

const VisAnalysisVisualizationButtons = React.memo(
  function VisAnalysisVisualizationButtons({
    selectedVisElement,
    setSelectedPayloadType,
    setSelectedGroupId,
    visPayloadElement,
    name,
    visualizedItem,
    type,
    tourId,
    selectedGroupId,
  }: VisAnalysisVisualizationButtonsProps): JSX.Element {
    const onClick = useCallback(() => {
      setSelectedPayloadType(type);
      setSelectedGroupId(name);
    }, [name, setSelectedGroupId, setSelectedPayloadType, type]);

    const isSelected = useMemo(() => {
      if (selectedGroupId === name) {
        return true;
      }
      if (!selectedVisElement || !selectedVisElement[type]) {
        return false;
      }
      return selectedVisElement[type].some((element) =>
        Object.values(visPayloadElement).flat().includes(element)
      );
    }, [selectedGroupId, name, selectedVisElement, type, visPayloadElement]);

    return (
      <VisAssetMenuButton isSelected={isSelected} onClick={onClick}>
        <div
          className="flex flex-row gap-2 w-full justify-start items-center"
          id={tourId}
        >
          <DisplayVisualizedItemPreview
            visualizedItem={visualizedItem}
            className="h-6 w-6"
          />
          <Tooltip
            title={name}
            placement="bottom"
            arrow
            disableHoverListener={name.length <= MAX_NAME_LENGTH}
          >
            <span>
              {truncateLongtail({
                value: name,
                startSubsetLength: MAX_NAME_LENGTH / 2,
                endSubsetLength: MAX_NAME_LENGTH / 2,
              })}
            </span>
          </Tooltip>
        </div>
      </VisAssetMenuButton>
    );
  }
);

interface VisAssetMenuButtonProps {
  isSelected: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

const VisAssetMenuButton = React.memo(function VisAssetMenuButton({
  isSelected,
  onClick,
  children,
}: VisAssetMenuButtonProps): JSX.Element {
  return (
    <Button
      className={clsx(
        '!rounded-none w-full !h-fit !text-xs !justify-start  focus:bg-primary-950 !ml-1 !pl-1',
        isSelected
          ? 'bg-primary-950 active:bg-primary-950'
          : 'bg-transparent hover:!bg-gray-800 active:bg-transparent'
      )}
      onClick={onClick}
    >
      <div className="flex w-full flex-row justify-start items-center">
        {children}
        <div className="flex ml-auto">
          <SvgForward className="w-3 h-3" />
        </div>
      </div>
    </Button>
  );
});

const AssetMenuTitle = React.memo(function AssetMenuTitle({
  title,
}: {
  title: string;
}): JSX.Element {
  return (
    <div className="flex w-full justify-start items-center uppercase pl-1">
      <span className="text-sm text-gray-400">{title}</span>
    </div>
  );
});
