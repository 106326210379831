import { Collapse } from '@material-ui/core';
import clsx from 'clsx';
import { first } from 'lodash';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useLocalStorageToggle } from '../../core/useToggle';
import { Button } from '../atoms/Button';
import { Select } from '../atoms/Select';
import { OptionDefaultObj } from '../atoms/utils/select';
import { CollapseIcon, ExpandIcon } from '../icons';
import { SessionRunSummaryData } from './TabModelSummary';
import { TOUR_SELECTORS_ENUM } from '../../tour/ToursConfig';

export type RenderSummary<T extends SessionRunSummaryData> = (
  model: T,
  containerClassName: string
) => JSX.Element;

export type TabModelSummaryListProps<T extends SessionRunSummaryData> = {
  data: T[];
  renderModelSummary: RenderSummary<T>;
  renderModelSummaryPlaceholder: (className: string) => JSX.Element;
  className?: string;
};

export function TabModelSummaryList<T extends SessionRunSummaryData>({
  data,
  className,
  renderModelSummary,
  renderModelSummaryPlaceholder,
}: TabModelSummaryListProps<T>) {
  const [open, toogleOpen] = useLocalStorageToggle('TAB_SUMMARY_OPEN', true);
  const modelOptions = useMemo(
    () =>
      data.map(
        ({ sessionRun: { id, name } }) =>
          ({ value: id, label: name } as OptionDefaultObj)
      ),
    [data]
  );

  const [selectedModel, setSelectedModel] = useState<
    SessionRunSummaryData | undefined
  >(first(data));
  const [selectedModelId, setSelectedModelId] = useState<string | undefined>(
    selectedModel?.sessionRun.id
  );

  const handelSelectModelChange = useCallback((id?: string) => {
    setSelectedModelId(id);
  }, []);

  useEffect(() => {
    const selectedModel =
      data.find(({ sessionRun: { id } }) => id === selectedModelId) ||
      first(data);
    setSelectedModelId(selectedModel?.sessionRun.id);
    setSelectedModel(selectedModel);
  }, [data, selectedModelId]);

  const summaryClassName = 'border-b border-gray-800 border-solid px-4';

  return (
    <div
      className={clsx('flex flex-col', className)}
      id={TOUR_SELECTORS_ENUM.TESTS_SUMMARY_ID}
    >
      <div className="flex items-center px-4 h-9">
        <span className="flex-1 text-gray-200 uppercase">
          SUMMARY
          <span className="text-gray-500"> {data.length} </span>
        </span>
        {modelOptions.length > 1 && (
          <>
            {!open && (
              <Select
                options={modelOptions}
                value={selectedModel?.sessionRun.id}
                onChange={handelSelectModelChange}
                small
              />
            )}
            <Button
              variant="action-icon"
              className="h-9 w-9"
              onClick={toogleOpen}
            >
              {open ? <CollapseIcon /> : <ExpandIcon />}
            </Button>
          </>
        )}
      </div>
      {data.map((sessionRunSummaryData) => (
        <Collapse
          key={sessionRunSummaryData.sessionRun.id}
          in={
            open ||
            selectedModel?.sessionRun.id === sessionRunSummaryData.sessionRun.id
          }
        >
          {renderModelSummary(sessionRunSummaryData, summaryClassName)}
        </Collapse>
      ))}
      <Collapse in={!data.length}>
        {renderModelSummaryPlaceholder(summaryClassName)}
      </Collapse>
    </div>
  );
}
