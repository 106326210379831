export interface HasChanges {
  hasChanges: boolean;
  children: React.ReactNode;
}

export function HasChanges({ hasChanges, children }: HasChanges) {
  return (
    <div className="relative">
      {hasChanges && (
        <span className="absolute -top-1 -right-1 w-3 h-3 bg-warning-400 rounded-full shadow-lg" />
      )}
      {children}
    </div>
  );
}
