import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgTestIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.55 3.427a6.1 6.1 0 1 0 3.618 5.576v-.625a.7.7 0 0 1 1.399 0v.626a7.501 7.501 0 1 1-4.448-6.855.7.7 0 1 1-.569 1.278Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.727 2.887a.7.7 0 0 1 0 .989l-6.8 6.807a.7.7 0 0 1-.99 0l-2.04-2.04a.7.7 0 0 1 .99-.989L8.432 9.2l6.306-6.312a.7.7 0 0 1 .99 0Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTestIcon;
