import { useCallback, useMemo } from 'react';
import { Slider } from '../../ui/atoms/Slider';
import { Setter } from '../../core/types';
export interface OpacitySlideSelectorProps {
  value?: number;
  setValue: Setter<number | undefined>;
  className?: string;
}
export function OpacitySlideSelector({
  value = 0.75,
  setValue,
  className,
}: OpacitySlideSelectorProps): JSX.Element {
  const onChange = useCallback(
    (_: React.ChangeEvent<object>, newValue: number | number[]) => {
      setValue((newValue as number) / 100);
    },
    [setValue]
  );

  const actualValue = useMemo(() => value * 100, [value]);

  return (
    <Slider
      labelPrefix="Opacity: "
      value={actualValue}
      sliderColor="GradiantBW"
      min={0}
      max={100}
      onChange={onChange}
      className={className}
    />
  );
}
