import { SampleAnalysisAlgo } from '@tensorleap/api-client';
import { Select } from './Select';
import { SampleAnalysisAlgoToString } from '../../dashboard/utils';

export const ALGO_LOCAL_STORAGE_KEY = 'sampleAnalysisAlgo';
export interface AlgoSelectProps {
  selectedAlgo: SampleAnalysisAlgo;
  setSelectedAlgo: (algo: SampleAnalysisAlgo) => void;
  small?: boolean | undefined;
  className?: string;
}

export function AlgoSelect({
  selectedAlgo,
  setSelectedAlgo,
  small,
  className,
}: AlgoSelectProps): JSX.Element {
  return (
    <Select
      value={selectedAlgo}
      options={Object.values(SampleAnalysisAlgo)}
      label={'Algorithm'}
      onChange={(algo) => setSelectedAlgo(algo as SampleAnalysisAlgo)}
      optionToLabel={SampleAnalysisAlgoToString}
      small={small}
      className={className}
    />
  );
}
