import { CSSProperties } from 'react';
import { Sorter, DEFAULT_SORT_DIRECTION } from '../sorter';
import { HeaderSorting } from './table-types';
import { Align, TableColumnDisplay } from './table-types';
import { BaseItem } from '../types';
import { Sortable } from '../model-field-types';

export const TEXT_ALIGN_CLASSES: Record<Align, string> = {
  left: 'text-left',
  center: 'text-center',
  right: 'text-right',
};

export const HEADER_ALIGN_CLASSES: Record<Align, string> = {
  left: 'justify-start',
  center: 'justify-center',
  right: 'flex-row-reverse',
};

export function calcHeaderStyle({
  width,
}: TableColumnDisplay = {}): CSSProperties {
  return {
    width,
  };
}

export function calcAlign(tableColumn: TableColumnDisplay): Align {
  return tableColumn?.align ? tableColumn?.align : 'left';
}

export function calcHeaderSorting<Item extends BaseItem>(
  field?: string,
  sortable?: Sortable,
  sorter?: Sorter<Item>
): HeaderSorting | undefined {
  const isNotSortable = !sortable || !sorter || !field;
  if (isNotSortable) {
    return undefined;
  }
  const isSorting = field === sorter.primarySortBy?.field;
  const toggle = () => sorter.toggle(field);
  return {
    toggle,
    isSorting,
    direction: sorter.primarySortBy?.direction || DEFAULT_SORT_DIRECTION,
  };
}
