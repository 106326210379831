import {
  InputAdornment,
  LinearProgress,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  ChangeEventHandler,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AcceptRejectIconButtons } from './AcceptRejectIconButtons';
import { InputProps } from './Input';
import { useDebounce } from '../../core/useDebounce';
import {
  validationCompose,
  requiredValidator,
} from './utils/editable-field-utils';
import { UseEditableCellProps, useEditableCell } from './utils/useEditableCell';
import { truncateLongtail } from '../../core/formatters/string-formatting';

export type EditableInputCellProps = UseEditableCellProps<string> & {
  placeholder?: string;
  withAcceptButtons?: boolean;
  type?: InputProps['type'];
  disabled?: boolean;
  readonly?: boolean;
  required?: boolean;
  maxLength?: number;
  minLength?: number;
  textWhenNotEditable?: boolean;
  truncateStartSubsetLength?: number;
  truncateEndSubsetLength?: number;
  tooltip?: string | React.ReactNode;
};

export function EditableInputCell({
  value: originalValue,
  disabled,
  readonly,
  required,
  validate,
  onChange,
  withAcceptButtons,
  textWhenNotEditable = false,
  truncateStartSubsetLength = 10,
  truncateEndSubsetLength = 5,
  tooltip = originalValue,
  ...inputProps
}: EditableInputCellProps) {
  const inputValidate = useCallback(
    (value) => {
      return validationCompose(value, required && requiredValidator, validate);
    },
    [required, validate]
  );

  const {
    handleChange,
    handleClose,
    isChanged,
    error,
    handleSave,
    value,
    valueRef,
    saving,
    onFocus,
    onBlur,
  } = useEditableCell({
    value: originalValue,
    validate: inputValidate,
    onChange,
  });
  const textRef = useRef(null);

  const handleOnSave = useCallback(
    async (event?: FormEvent<HTMLFormElement>) => {
      event?.preventDefault();
      await handleSave(valueRef.current);
    },
    [handleSave, valueRef]
  );

  const handleOnSaveDebounce = useDebounce(() => {
    !error && handleOnSave();
  }, 800);

  const handleOnChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const updateValue = e.target.value || undefined;
      handleChange(updateValue);
      !withAcceptButtons && handleOnSaveDebounce();
    },
    [handleChange, handleOnSaveDebounce, withAcceptButtons]
  );
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (isEditable && textRef.current)
      (textRef.current as HTMLInputElement).focus();
  }, [isEditable]);

  return !textWhenNotEditable || isEditable ? (
    <form className="relative" onSubmit={handleOnSave}>
      {saving && <LinearProgress className=" absolute top-0 inset-x-0" />}
      <TextField
        inputRef={textRef}
        onFocus={onFocus}
        onBlur={async () => {
          setIsEditable(false);
          onBlur();
        }}
        id="outlined-basic"
        error={!!error}
        size="small"
        variant="outlined"
        required={required}
        disabled={disabled}
        value={value || ''}
        className="w-full border-none"
        onChange={handleOnChange}
        inputProps={inputProps}
        InputProps={{
          className: 'pr-0 !h-9',
          readOnly: readonly,
          endAdornment: withAcceptButtons && (
            <InputAdornment position="end">
              {isChanged && !saving ? (
                <AcceptRejectIconButtons
                  submit
                  disabled={!!error}
                  onReject={handleClose}
                />
              ) : (
                <div className="w-2" />
              )}
            </InputAdornment>
          ),
        }}
      />
    </form>
  ) : (
    <Tooltip title={tooltip ?? ''} arrow className="flex-1">
      <span
        onDoubleClick={() => {
          setIsEditable(true);
        }}
      >
        {truncateLongtail({
          value,
          startSubsetLength: truncateStartSubsetLength,
          endSubsetLength: truncateEndSubsetLength,
        })}
      </span>
    </Tooltip>
  );
}
