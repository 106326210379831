import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgSampleIncluderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.273 7.564a2.29 2.29 0 1 0 0-4.582 2.29 2.29 0 0 0 0 4.582Zm0 .981a3.273 3.273 0 1 0 0-6.545 3.273 3.273 0 0 0 0 6.545ZM12.636 7.564a2.29 2.29 0 1 0 0-4.582 2.29 2.29 0 0 0 0 4.582Zm0 .981a3.273 3.273 0 1 0 0-6.545 3.273 3.273 0 0 0 0 6.545ZM5.273 14.927a2.29 2.29 0 1 0 0-4.582 2.29 2.29 0 0 0 0 4.582Zm0 .982a3.273 3.273 0 1 0 0-6.545 3.273 3.273 0 0 0 0 6.545Z"
      fill="currentColor"
    />
    <path
      d="M15.794 13.377a3.176 3.176 0 0 1-.85 1.47 3.198 3.198 0 0 1-1.472.853 3.223 3.223 0 0 1-1.68-.006 3.171 3.171 0 0 1-1.47-.848 3.193 3.193 0 0 1-.851-1.472 3.276 3.276 0 0 1-.003-1.678c.147-.56.431-1.05.853-1.472a3.176 3.176 0 0 1 1.47-.85 3.3 3.3 0 0 1 1.683-.003c.56.146 1.05.43 1.472.851.421.422.704.911.848 1.47a3.246 3.246 0 0 1 0 1.685Zm-2.695.986a.262.262 0 0 0 .078-.193v-1.09h1.09c.077 0 .14-.026.193-.078a.267.267 0 0 0 .081-.196v-.542a.267.267 0 0 0-.08-.196.262.262 0 0 0-.193-.078h-1.09V10.9a.262.262 0 0 0-.078-.192.267.267 0 0 0-.196-.082h-.542a.267.267 0 0 0-.196.082.262.262 0 0 0-.078.193v1.09h-1.09a.262.262 0 0 0-.193.078.267.267 0 0 0-.081.196v.542c0 .076.027.142.08.196a.262.262 0 0 0 .193.078h1.09v1.09c0 .076.026.14.078.192.054.055.12.082.196.082h.542a.267.267 0 0 0 .196-.082Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSampleIncluderIcon;
