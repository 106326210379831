import { Button } from '../../../ui/atoms/Button';

export interface PaneActionsProps {
  enableSubmit?: boolean;
  closeTooltip: () => void;
}
export function PaneActions({
  enableSubmit = true,
  closeTooltip,
}: PaneActionsProps): JSX.Element {
  return (
    <footer className="flex flex-row justify-end items-center justify-self-end mt-4">
      <Button type="submit" disabled={!enableSubmit} onClick={closeTooltip}>
        <span className="font-medium text-sm leading-relaxed uppercase">
          analyze
        </span>
      </Button>
    </footer>
  );
}
