import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgRunsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.985 3h8.042a.39.39 0 0 1 .327.168.611.611 0 0 1 .138.401.575.575 0 0 1-.138.392.397.397 0 0 1-.327.159H7.985a.471.471 0 0 1-.351-.159.576.576 0 0 1-.139-.392c0-.155.046-.289.139-.401A.46.46 0 0 1 7.985 3ZM6.477 5.38H17.51c.134 0 .247.053.34.159.1.112.15.245.15.401 0 .15-.05.28-.15.392a.424.424 0 0 1-.34.168H6.477a.424.424 0 0 1-.339-.168A.598.598 0 0 1 6 5.94c0-.156.046-.29.138-.401a.433.433 0 0 1 .34-.159ZM3 20.14V8.85c0-.233.081-.433.244-.6A.788.788 0 0 1 3.83 8H20.16c.226 0 .421.083.584.25a.81.81 0 0 1 .255.6v11.29a.846.846 0 0 1-.255.61.788.788 0 0 1-.584.25H3.829a.788.788 0 0 1-.585-.25.865.865 0 0 1-.244-.61Zm1.075-.249h15.828V9.11H4.075V19.89Zm6.328-.89c-.027-.026.09-.34.35-.94l.79-1.82c.266-.6.393-.926.38-.98-.007-.04-.32-.19-.94-.45-.614-.26-.94-.443-.98-.55-.034-.08.246-.48.84-1.2.62-.753 1.22-1.45 1.8-2.09.313-.346.546-.596.7-.75.16-.16.246-.233.26-.22.04.027-.207.65-.74 1.87-.534 1.214-.794 1.83-.78 1.85.006.027.323.17.95.43.613.26.936.45.97.57.033.134-.547.89-1.74 2.27-1.194 1.38-1.814 2.05-1.86 2.01Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgRunsIcon;
