import { NumberOrString } from '@tensorleap/api-client';
import { AxisDomain } from 'recharts/types/util/types';
import { formatGraphAxisNumber } from './utils';
export const TICK_LINE_SIZE = 4;
export const AXIS_LINE_STROKE_COLOR = 'white';
export const LEGEND_STYLE = { cursor: 'pointer' } as const;
export const DOMAIN_BY_DATA: AxisDomain = ['dataMin', 'dataMax'];

export function chartFormatIfNumber(value: NumberOrString): NumberOrString {
  return typeof value === 'number' || !Number.isNaN(Number(value))
    ? formatGraphAxisNumber(Number(value))
    : value;
}

export const valueFormattingFunction = (value: unknown): string => {
  if (typeof value !== 'string' && typeof value !== 'number') {
    console.warn(`Unsupported value type for ${value}`);
    return '';
  }
  return String(chartFormatIfNumber(value));
};

const TICKET_FONT_SIZE = { fontSize: 10 } as const;
export const ticket = ({
  payload,
  verticalAnchor: _va,
  visibleTicksCount: _vtc,
  tickFormatter: _tf,
  ...p
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
  const hasAngle = p.angle !== undefined;
  return (
    <text
      {...p}
      transform={hasAngle ? `rotate(${p.angle},${p.x}, ${p.y})` : undefined}
    >
      <tspan x={p.x - (hasAngle ? 8 : 0)} dy="0.4em" style={TICKET_FONT_SIZE}>
        {chartFormatIfNumber(payload.value)}
      </tspan>
    </text>
  );
};
