import { createContext, useContext, FC, MutableRefObject } from 'react';
import {
  NetworkMapControl,
  NetworkNodeUiRefs,
  useNetworkMap,
} from '../network-editor/hooks';
import { ValidateAssetsStatus } from '../network-editor/interfaces/ValidateGraphStatus';
import { NodePresentationState } from '../network-editor/graph-calculation/contract';
import { SaveVersionStateEnum } from './SaveCommitLoading';

const networkMapContextDefault: NetworkMapControl = {
  nodes: new Map(),
  connections: [],
  selectNode: () => undefined,
  clearNodeSelection: () => undefined,
  changeNodeProperty: () => undefined,
  updateNodePosition: () => undefined,
  addNewConnection: () => undefined,
  updateConnection: () => undefined,
  addNewNode: () => undefined,
  cloneNode: () => undefined,
  spawnNode: () => undefined,
  deleteOneOrManyConnections: () => undefined,
  deleteOneOrManyNodes: () => undefined,
  updateDataset: () => undefined,
  saveCurrentVersion: async () => undefined,
  saveVersion: async () => undefined,
  handleToggleModelNodesGrouping: () => undefined,
  nodeRefs: {} as MutableRefObject<Map<string, NetworkNodeUiRefs>>,
  nodesShapesRef: {} as MutableRefObject<Map<string, NodePresentationState>>,
  organizeMap: () => undefined,
  addPredictionLabel: () => undefined,
  isLoadingShapes: true,
  isVersionChanged: false,
  getNewNodeId: () => '',
  validateAssetsStatus: ValidateAssetsStatus.CalculatingDigest,
  handleValidateAssetsClicked: async () => undefined,
  validateAssetsButtonState: {
    isDisabled: true,
    tooltipTitle: '',
    confirmDialogMsg: '',
  },
  confirmValidateAssetsDialogIsOpen: false,
  setConfirmValidateAssetsDialogIsOpen: () => undefined,
  overrideSaveDialogIsOpen: false,
  setOverrideSaveDialogIsOpen: () => undefined,
  lockOverrideSaveDialogMsg: '',
  suggestSavingDialog: false,
  saveButtonState: SaveVersionStateEnum.LOADING_SHAPES,
  saveIconTooltipTitle: '',
  handleSaveClicked: async () => undefined,
  handleOverrideSaveClicked: async () => undefined,
  ungroupedNetworkModelGraph: undefined,
  onFitNodeToScreen: (_) => undefined,
  setPanZoom: () => undefined,
  openNetworkTab: undefined,
  setOpenNetworkTab: () => undefined,
  codeIntegrationIsExpanded: false,
  toggleCodeIntegrationExpanded: () => undefined,
  validateAssetsErrors: [],
  networkContextGeneralError: undefined,
};

export const NetworkMapContext = createContext<NetworkMapControl>(
  networkMapContextDefault
);

export const NetworkMapProvider: FC = ({ children }) => {
  const value = useNetworkMap();

  return (
    <NetworkMapContext.Provider value={value}>
      {children}
    </NetworkMapContext.Provider>
  );
};

export const useNetworkMapContext: () => NetworkMapControl = () =>
  useContext(NetworkMapContext);
