import { FilterOperatorType, NumberOrString } from '@tensorleap/api-client';
import { LabelsLegend } from '../../ui/charts/legend/LabelsLegend';
import { useDashletScatterContext } from '../dashlet/PopulationExploration/DashletScatterContext';
import { useLabelsLegendData } from '../useLabelsLegendData';
import {
  ClusterVisualizationFilter,
  EQVisualizationFilter,
  VisualizationFilter,
} from '../../core/types/filters';
import { useScatterData } from '../ScatterDataContext';
import { trimStorageUrlProject } from '../../core/useProjectStorage';

export function LabelsScatterLegend(): JSX.Element {
  const labelsAppearances = useLabelsLegendData();

  const {
    settings: { dotColor },
    clusterBlobPaths,
    miByCluster,
    showLegendNames: showNames,
    toggleShowLegendNames: toggleShowNames,
    legendTruncatedLongtail: truncatedLongtail,
    setLegendTruncatedLongtail: setTruncatedLongtail,
  } = useScatterData();

  const {
    filters: { updateDashletFilters, dashletFilters },
  } = useDashletScatterContext();

  const addFilter = (label: NumberOrString) => {
    if (!dotColor) return;

    let filter: VisualizationFilter;

    if (clusterBlobPaths && clusterBlobPaths[dotColor]) {
      const url = clusterBlobPaths[dotColor][label];
      const mappedUrl = trimStorageUrlProject(url); // fixing wrong path

      const clusterFilter: ClusterVisualizationFilter = {
        field: dotColor,
        operator: FilterOperatorType.Cluster,
        value: {
          url: mappedUrl,
          state: 'ready',
        },
        pin: false,
      };
      filter = clusterFilter;
    } else if (dotColor.startsWith('cluster')) {
      console.warn(
        `Cluster filter is not supported for this dotColor: ${dotColor}`
      );
      return;
    } else {
      let field = dotColor;
      if (!field.endsWith('.keyword')) {
        field += '.keyword';
      }
      const eqFilter: EQVisualizationFilter = {
        field,
        operator: FilterOperatorType.Equal,
        value: label,
        pin: false,
      };
      filter = eqFilter;
    }

    updateDashletFilters([...dashletFilters, filter]);
  };

  const clusterData =
    dotColor && miByCluster ? miByCluster[dotColor] : undefined;

  return (
    <LabelsLegend
      truncatedLongtail={truncatedLongtail}
      setTruncatedLongtail={setTruncatedLongtail}
      showNames={showNames}
      toggleShowNames={toggleShowNames}
      labels={Array.from(labelsAppearances.keys())}
      appearances={labelsAppearances}
      onLegendClick={addFilter}
      innerSplit={null}
      clusterData={clusterData}
    />
  );
}
