import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '../ui/atoms/Button';
import { Input } from '../ui/atoms/Input';
import { TextArea } from '../ui/atoms/TextArea';
import { AddDashboardProps } from './DashboardContext';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';

const FORM_ID = 'ADD_DASHBOARD_DIALOG';
export type EditDashboardNameDialogProps = {
  open: boolean;
  value?: AddDashboardProps;
  onClose: () => void;
  onSubmit: (_: AddDashboardProps) => void;
};

export function EditDashboardNameDialog({
  open,
  onClose,
  value,
  onSubmit: _onSubmit,
}: EditDashboardNameDialogProps) {
  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors, isValid },
  } = useForm<AddDashboardProps>({
    mode: 'onChange',
    defaultValues: value,
  });

  useEffect(() => {
    setTimeout(() => setFocus('name'));
  }, [setFocus]);

  const onSubmit: SubmitHandler<AddDashboardProps> = async ({
    name,
    description,
  }) => {
    await _onSubmit({ name, description });
    onClose();
  };

  const AddOrRename = value ? `RENAME` : `ADD`;

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <div
        className="w-fit h-fit"
        id={TOUR_SELECTORS_ENUM.ADD_NEW_DASHBOARD_DIALOG_ID}
      >
        <DialogTitle>{AddOrRename} DASHBOARD</DialogTitle>
        <DialogContent>
          <form
            className="w-64 px-2 flex flex-col space-y-4"
            id={FORM_ID}
            action=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              label="NAME"
              {...register('name', {
                required: true,
                validate: (value) => value?.trim().length > 0,
              })}
              error={errors.name && 'This field is required'}
            />
            <TextArea label="DESCRIPTION" {...register('description', {})} />
          </form>
        </DialogContent>
        <DialogActions className="p-4">
          <Button onClick={onClose} variant="text">
            CANCEL
          </Button>
          <Button disabled={!isValid} form={FORM_ID} type="submit">
            {AddOrRename}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
