export const compactNumberFormatter = new Intl.NumberFormat('en', {
  notation: 'compact',
  compactDisplay: 'short',
});

export const minTwoDigits = new Intl.NumberFormat('en', {
  minimumIntegerDigits: 2,
  useGrouping: false,
});

export const chartNumberFormatter = new Intl.NumberFormat('en', {
  notation: 'compact',
  compactDisplay: 'short',
  maximumFractionDigits: 4,
});
