import { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { ActionResult } from '../core/types';
import { Select } from '../ui/atoms/Select';
import {
  ApplyToTest,
  AreaIcon,
  BagelIcon,
  BarsIcon,
  BlocksIcon,
  ConfusionMatrixIcon,
  Duplicate,
  GraphIcon,
  HeatMapIcon,
  PencilIcon,
  Plus,
  PopExIcon,
  ResetSettingsIcon,
  SampleIncluderIcon,
  TableIcon,
  Trash,
  XClose,
} from '../ui/icons';
import { Divider } from './Divider';
import { ActionButton } from '../ui/molecules/ActionButton';
import { useModelFilter } from '../ui/molecules/useModelFilter';
import { MoreMenu } from '../ui/atoms/MoreMenu';
import { ModelChip } from '../ui/molecules/ModelChip';
import { Button } from '../ui/atoms/Button';
import { IconButton, Tooltip } from '../ui/mui';
import { FilterList } from '../filters/FilterList';
import { PlaceholderChip } from '../ui/atoms/PlaceholderChip';
import { useFetchDashboardFilterFieldsMeta } from '../core/data-fetching/dashlet-fields';
import { useCurrentProject } from '../core/CurrentProjectContext';
import { ButtonMenu } from '../ui/atoms/ButtonMenu';
import {
  AddDashlet,
  DashletType,
  useDashboardContext,
} from './DashboardContext';
import {
  SuggestedDashlet,
  UseSuggestedDashletResult,
  useSuggestedDashlet,
} from './dashlet/SuggestedDashlet/SuggestedDashlet';
import { NoDataChart } from '../ui/charts/common/NoDataChart';
import SvgInfo from '../ui/icons/Info';
import { ButtonBase, LinearProgress } from '@material-ui/core';
import { AnalyticsDashletType } from '@tensorleap/api-client';
import { SELECTED_DASHBOARD_KEY, setQueryParam } from '../url/url-builder';
import { useHistory, useLocation } from 'react-router';
import { useFetchDashboards } from '../core/data-fetching/dashboards';
import { TOUR_SELECTORS_ENUM } from '../tour/ToursConfig';

export type DashboardBarProps = {
  className?: string;
  addDashboard: () => ActionResult;
  renameDashboard: () => ActionResult;
  addModelTestFromFilters: () => ActionResult;
  organizeDashboard: () => ActionResult;
};
export function DashboardBar({
  className,
  addDashboard,
  renameDashboard,
  addModelTestFromFilters,
  organizeDashboard,
}: DashboardBarProps) {
  const { fetchValidProjectCid } = useCurrentProject();
  const projectId = fetchValidProjectCid();

  const history = useHistory();
  const { search, pathname } = useLocation();

  const {
    selected,
    globalFilters,
    setGlobalFilters,
    addDashlet,
    removeDashboard,
    duplicateDashboard,
  } = useDashboardContext();

  const { dashboards } = useFetchDashboards({
    projectId,
  });

  const { selected: selectedSessionRun } = useModelFilter();
  const selectedSessionRunIds = selectedSessionRun.map((m) => m.id);

  const options = useMemo(
    () => dashboards.map((d) => ({ value: d.cid, label: d.name })),
    [dashboards]
  );
  const modelFieldsMeta = useFetchDashboardFilterFieldsMeta({
    projectId,
    filters: globalFilters,
    sessionRunIds: selectedSessionRunIds,
  });
  const suggestedDashletsProps = useSuggestedDashlet();

  const switchDashboard = useCallback(
    (value?: string) => {
      if (!value) {
        console.error('No dashboard selected');
        return;
      }
      const newSearch = setQueryParam(search, SELECTED_DASHBOARD_KEY, value);
      history.push({
        pathname,
        search: newSearch,
      });
    },
    [history, pathname, search]
  );

  return (
    <div className={clsx(`flex items-center min-w-0`, className)}>
      <Select
        className="w-60"
        clean
        noOptionsText={<div>No dashboards.</div>}
        label="SELECT DASHBOARD"
        value={selected}
        onChange={switchDashboard}
        options={options}
      />
      <Tooltip title="Add a Dashboard">
        <Button className="ml-2" onClick={addDashboard} variant="action-icon">
          <Plus className="h-4 w-4" />
        </Button>
      </Tooltip>

      <Divider vertical />

      <ModelFilter className="flex-1 text-xs" />

      <Divider vertical />

      <FilterList
        filters={globalFilters}
        filterFieldsMeta={modelFieldsMeta}
        onFiltersChange={setGlobalFilters}
        listClassName="flex-1"
      />

      <Tooltip title={'Create a new test based on these filters'}>
        <span>
          <IconButton
            className="w-10 h-10"
            disabled={!globalFilters.length}
            onClick={addModelTestFromFilters}
          >
            <ApplyToTest className="w-6 h-6" />
          </IconButton>
        </span>
      </Tooltip>

      <Divider vertical />

      {selected && (
        <>
          <AddDashletButton
            onAdd={addDashlet}
            suggestedDashletsState={suggestedDashletsProps}
          />
          <Divider vertical />
        </>
      )}

      <MoreMenu>
        <ActionButton
          disabled={!selected}
          onRun={removeDashboard}
          icon={<Trash />}
        >
          REMOVE DASHBOARD
        </ActionButton>

        <ActionButton
          disabled={!selected}
          onRun={duplicateDashboard}
          icon={<Duplicate />}
        >
          DUPLICATE DASHBOARD
        </ActionButton>
        <ActionButton
          disabled={!selected}
          onRun={renameDashboard}
          icon={<PencilIcon />}
        >
          RENAME DASHBOARD NAME
        </ActionButton>

        <ActionButton
          disabled={!selected}
          onRun={organizeDashboard}
          icon={<BlocksIcon />}
        >
          ORGANIZE DASHBOARD
        </ActionButton>
      </MoreMenu>
    </div>
  );
}

interface ModelFilterProps {
  className?: string;
}
function ModelFilter({ className }: ModelFilterProps): JSX.Element {
  const { selected, remove, toggleVisibility } = useModelFilter();

  return (
    <div className={clsx('flex px-2 space-x-1 items-center', className)}>
      {selected.length ? (
        selected.map((model) => (
          <ModelChip
            key={model.id}
            {...model}
            remove={remove}
            toggleVisibility={toggleVisibility}
          />
        ))
      ) : (
        <PlaceholderChip>NO MODEL SELECTED</PlaceholderChip>
      )}
    </div>
  );
}

interface AddDashletButtonProps {
  onAdd: AddDashlet;
  suggestedDashletsState: UseSuggestedDashletResult;
}
const ADD_CONTENT_FRAME_CLASSES = 'border-gray-600 border rounded';
const ADD_CONTENT_CLASSES = clsx(ADD_CONTENT_FRAME_CLASSES, 'bg-gray-800');

function AddDashletButton({
  onAdd,
  suggestedDashletsState,
}: AddDashletButtonProps) {
  const { isAddDashletsOpen, setIsAddDashletsOpen } = useDashboardContext();

  const {
    suggestionDashletProps,
    resetSuggestions,
    status,
  } = suggestedDashletsState;

  const closeAndAdd = useCallback<AddDashlet>(
    async (...props) => {
      const id = await onAdd(...props);
      setIsAddDashletsOpen(false);

      return id;
    },
    [onAdd, setIsAddDashletsOpen]
  );
  const calcDashletHeightClass = useCallback((sessionRunCount: number) => {
    switch (sessionRunCount) {
      case 0:
      case 1:
        return '';
      case 2:
        return 'min-h-[420px]';
      default:
        return 'min-h-[520px]';
    }
  }, []);
  const divider = <Divider className="!bg-gray-700" />;
  const titleClasses = clsx('text-gray-400 uppercase px-2 text-xs font-bold');

  return (
    <ButtonMenu
      menuClassName="w-120 p-3 gap-1 bg-gray-850 border-gray-700 border rounded"
      label="ADD NEW DASHLET"
      open={isAddDashletsOpen}
      setOpen={setIsAddDashletsOpen}
      tourId={TOUR_SELECTORS_ENUM.ADD_NEW_DASHLET_BUTTON_ID}
    >
      <div className="items-center flex justify-between -mb-2">
        <p className={titleClasses}>Add from scratch</p>
        <IconButton
          className="h-8 w-8"
          onClick={() => setIsAddDashletsOpen(false)}
        >
          <XClose />
        </IconButton>
      </div>
      {divider}
      <AddDashletOptions onAdd={closeAndAdd} />
      {divider}
      <div className="flex items-center pb-2 relative">
        {status.isCalculating && (
          <LinearProgress className="absolute w-full bottom-0" />
        )}
        <p className={titleClasses}>Suggested dashlets</p>
        <Tooltip
          title={
            <span className="text-base">
              Dashlets generated by identifying correlations in the selected
              models
            </span>
          }
        >
          <div>
            <SvgInfo className="h-5" />
          </div>
        </Tooltip>
        <span className="flex-1" />
        <Tooltip
          title={
            <span className="text-base">
              {status.isFailed
                ? 'Some jobs have failed. Click this button to reset and rerun them.'
                : 'Reset'}
            </span>
          }
        >
          <IconButton
            className={clsx('h-5 w-5', status.isFailed && 'text-warning-400')}
            onClick={resetSuggestions}
          >
            <ResetSettingsIcon className="h-4" />
          </IconButton>
        </Tooltip>
      </div>
      <div className="flex flex-col gap-4 overflow-auto max-h-[550px]">
        {suggestionDashletProps.map((dashlet, i) => (
          <SuggestedDashlet
            className={clsx(
              ADD_CONTENT_CLASSES,
              calcDashletHeightClass(dashlet.data.sessionRunIds.length)
            )}
            key={i}
            {...dashlet}
            onAdd={onAdd}
          />
        ))}
        {suggestionDashletProps.length === 0 && (
          <div className={ADD_CONTENT_FRAME_CLASSES}>
            <NoDataChart text="No Suggestions" />
          </div>
        )}
      </div>
    </ButtonMenu>
  );
}

export type AddOptionsProps = {
  onAdd: AddDashlet;
};

export function AddDashletOptions({ onAdd }: AddOptionsProps) {
  const iconClasses = 'h-6 w-6';
  return (
    <div
      className="grid grid-cols-3 gap-2"
      id={TOUR_SELECTORS_ENUM.ADD_NEW_DASHLET_MENU_ID}
    >
      <AddDashletCard
        onAdd={() =>
          onAdd(DashletType.Analytics, {
            type: AnalyticsDashletType.Line,
            name: '',
            data: {},
          })
        }
        icon={<GraphIcon className={iconClasses} />}
        label="Line"
        cardId={TOUR_SELECTORS_ENUM.ADD_ANALYTICS_DASHLET_CARD_ID}
        buttonId={TOUR_SELECTORS_ENUM.ADD_ANALYTICS_DASHLET_BUTTON_ID}
      />
      <AddDashletCard
        onAdd={() =>
          onAdd(DashletType.Analytics, {
            type: AnalyticsDashletType.Bar,
            name: '',
            data: {},
          })
        }
        icon={<BarsIcon className={iconClasses} />}
        label="Bar"
      />
      <AddDashletCard
        onAdd={() =>
          onAdd(DashletType.Analytics, {
            type: AnalyticsDashletType.Area,
            name: '',
            data: {},
          })
        }
        icon={<AreaIcon className={iconClasses} />}
        label="Area"
      />
      <AddDashletCard
        onAdd={() =>
          onAdd(DashletType.Analytics, {
            type: AnalyticsDashletType.Donut,
            name: '',
            data: {},
          })
        }
        icon={<BagelIcon className={iconClasses} />}
        label="Pie"
      />
      <AddDashletCard
        onAdd={() =>
          onAdd(DashletType.Analytics, {
            type: AnalyticsDashletType.Table,
            name: '',
            data: {},
          })
        }
        icon={<TableIcon className={iconClasses} />}
        label="Table"
      />

      <AddDashletCard
        onAdd={() =>
          onAdd(DashletType.Analytics, {
            type: AnalyticsDashletType.Heatmap,
            name: '',
            data: {},
          })
        }
        icon={<HeatMapIcon className={iconClasses} />}
        label="Heatmap"
      />
      <AddDashletCard
        onAdd={() =>
          onAdd(DashletType.Analytics, {
            type: AnalyticsDashletType.ConfusionMatrix,
            name: '',
            data: {},
          })
        }
        icon={<ConfusionMatrixIcon className={iconClasses} />}
        label="Confusion Matrix"
      />
      <AddDashletCard
        onAdd={() => onAdd(DashletType.SampleAnalysis)}
        icon={<SampleIncluderIcon className={iconClasses} />}
        label="Sample Analysis"
        cardId={TOUR_SELECTORS_ENUM.ADD_SAMPLE_ANALYSIS_DASHLET_CARD_ID}
        buttonId={TOUR_SELECTORS_ENUM.ADD_SAMPLE_ANALYSIS_DASHLET_BUTTON_ID}
      />
      <AddDashletCard
        onAdd={() => onAdd(DashletType.PopulationExploration)}
        icon={<PopExIcon className={iconClasses} />}
        label="Population Exploration"
        cardId={TOUR_SELECTORS_ENUM.ADD_POPULATION_EXPLORATION_DASHLET_CARD_ID}
        buttonId={
          TOUR_SELECTORS_ENUM.ADD_POPULATION_EXPLORATION_DASHLET_BUTTON_ID
        }
      />
    </div>
  );
}

type AddDashletCardProps = {
  onAdd: () => void;
  icon: JSX.Element;
  label: string;
  cardId?: string;
  buttonId?: string;
};

function AddDashletCard({
  onAdd,
  icon,
  label,
  cardId,
  buttonId,
}: AddDashletCardProps) {
  return (
    <div
      className="group h-20 relative p-2  gap-2 flex flex-col justify-center items-center bg-gray-800 border-gray-600 border rounded-xl hover-pointer"
      id={cardId}
    >
      {icon}
      <span className="uppercase text-center text-xs">{label}</span>
      <ButtonBase
        onClick={onAdd}
        id={buttonId}
        className="cursor-pointer rounded-xl absolute text-center p-2 gap-2 hidden group-hover:flex flex-col items-center top-0 left-0 right-0 bottom-0 bg-gray-900 opacity-90"
      >
        <Plus />
        ADD
      </ButtonBase>
    </div>
  );
}
