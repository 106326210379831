import clsx from 'clsx';
import { Down, Up } from '../icons';
import { Tooltip } from '@material-ui/core';

export function UpOrDownArrow({
  isOpen,
  onClick,
  text,
  icon,
  className,
}: {
  isOpen: boolean;
  onClick: () => void;
  text?: string;
  icon?: JSX.Element;
  className?: string;
}) {
  return (
    <Tooltip title={isOpen ? 'Collapse' : 'Extended'} placement="bottom">
      <div
        className={clsx(
          'flex flex-row rounded hover:bg-gray-850 justify-center items-center cursor-pointer w-full h-4 gap-3',
          className
        )}
        onClick={onClick}
      >
        {isOpen ? <Up /> : <Down />}
        {icon}
        {text && <div className="text-xs text-gray-400">{text}</div>}
      </div>
    </Tooltip>
  );
}
