import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgKillIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="M4.895 14.395c0-.48-.424-.869-.948-.869-.523 0-.947.39-.947.869 0 .222.091.425.241.579a.826.826 0 0 0-.241.579c0 .48.424.868.947.868.524 0 .948-.389.948-.868h8.21c0 .48.424.868.948.868.523 0 .947-.389.947-.868a.826.826 0 0 0-.241-.58.826.826 0 0 0 .241-.578c0-.48-.424-.869-.947-.869-.524 0-.948.39-.948.869h-8.21Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.381 11.422C12.542 11.047 15 10.074 15 7.514 15 4.407 12.132 2 8.872 2 6.499 2 3 3.844 3 7.514c0 2.773 2.666 3.597 3.306 3.795.038.011.068.02.09.028-.016.325.073 1.018.564 1.195.492.178 1.04 0 1.178-.199.195.334.529.42.82.42.442 0 .671-.221.716-.42.078.199.495.42 1.007.284.378-.1.837-.529.7-1.195ZM8.087 7.07c0 1.065-.628 1.877-1.57 1.877-.943 0-1.708-.812-1.708-1.877 0-1.066.765-1.93 1.707-1.93.943 0 1.57.864 1.57 1.93Zm5.001 0c0 1.065-.747 1.877-1.69 1.877-.942 0-1.638-.812-1.638-1.877 0-1.066.764-1.93 1.707-1.93.942 0 1.621.864 1.621 1.93ZM9.008 9.1c.274 0 .683.632.632.99-.051.359-.256.444-.631.444-.376 0-.717-.205-.717-.495 0-.232.341-.939.717-.939Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgKillIcon;
