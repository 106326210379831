import { Insight, MutualInformationElement } from '@tensorleap/api-client';
import { Chip } from '../ui/atoms/Chip';
import { ModelChip } from '../ui/molecules/ModelChip';
import { SelectedSessionRun } from '../ui/molecules/useModelFilter';
import {
  isDataLeakageInsightClusterInsight,
  isDuplicationInsightClusterInsight,
  isOverfittingClusterInsight,
  isRedundantSamplesClusterInsight,
  isUnderRepresentationClusterInsight,
  isWeakClusterInsight,
} from './utils';
import { useCallback, useState } from 'react';

const PRECISION = 10000;

type InsightCardTextProps = {
  insightData: Insight['data'];
  selectedModel: SelectedSessionRun;
};

export function InsightCardText({
  insightData,
  selectedModel,
}: InsightCardTextProps): JSX.Element {
  const { metric, value } = insightData.filter;
  const { mutual_info_elements: infoElements } = insightData;
  if (isWeakClusterInsight(insightData)) {
    const formattedLoss = insightData.avg_metric.toFixed(3);
    const formattedSamples = Math.ceil(insightData.n_samples);
    return (
      <span>
        <span>
          {`Detected a cluster with low performance with ${formattedLoss} ${insightData.metric_name} for `}
        </span>
        <div className="h-fit w-fit inline-block">
          <ModelChip {...selectedModel} hideInvisibilityIcon />
        </div>
        <span>
          {` with ${formattedSamples} samples, cluster #${value} of ${metric}`}
        </span>
        <CorrelatedMetadataOnCluster infoElements={infoElements} />
      </span>
    );
  }

  if (isRedundantSamplesClusterInsight(insightData)) {
    const formattedLoss = insightData.avg_metric.toFixed(4);
    const formattedSamples = Math.ceil(insightData.n_samples);
    return (
      <span>
        <span>
          {`Detected a cluster with repetitive information with ${formattedLoss} ${insightData.metric_name} for `}{' '}
        </span>
        <div className="h-fit w-fit inline-block">
          <ModelChip {...selectedModel} hideInvisibilityIcon />
        </div>
        <span>
          {` with ${formattedSamples} samples, cluster #${value} of ${metric}`}
        </span>
        <CorrelatedMetadataOnCluster infoElements={infoElements} />
      </span>
    );
  }

  if (isOverfittingClusterInsight(insightData)) {
    const trainingSamples = Math.ceil(insightData.n_samples_training);
    const validationSamples = Math.ceil(insightData.n_samples_validation);
    const avgLossTraining =
      Math.round(insightData.avg_metric_training * PRECISION) / PRECISION;
    const avgLossValidation =
      Math.round(insightData.avg_metric_validation * PRECISION) / PRECISION;
    return (
      <span>
        <span>Detected a cluster with overfitted population </span>
        <div className="h-fit w-fit inline-block">
          <ModelChip {...selectedModel} hideInvisibilityIcon />
        </div>
        <span>{` , cluster #${value} of ${metric}`}</span>
        <div className="flex flex-col gap-1">
          <span>{`${trainingSamples} samples from training : ${insightData.metric_name} - ${avgLossTraining}`}</span>
          <span>
            <span>{`${validationSamples} samples from validation : ${insightData.metric_name} - ${avgLossValidation}`}</span>
          </span>
        </div>
        <CorrelatedMetadataOnCluster infoElements={infoElements} />
      </span>
    );
  }

  if (isUnderRepresentationClusterInsight(insightData)) {
    const {
      under_representation_dataset,
      over_representation_dataset,
      under_representation_n_samples,
      over_representation_n_samples,
    } = insightData;

    const underSamples = Math.ceil(under_representation_n_samples);
    const overSamples = Math.ceil(over_representation_n_samples);

    return (
      <span>
        <span>
          {`Detected a cluster with under represented population on ${under_representation_dataset} `}
        </span>
        <div className="h-fit w-fit inline-block">
          <ModelChip {...selectedModel} hideInvisibilityIcon />
        </div>
        <span>{` , cluster #${value} of ${metric}`}</span>
        <div className="flex flex-col gap-1">
          <span>
            {`${underSamples} under represented samples in ${under_representation_dataset} dataset.`}
          </span>
          <span>
            {`${overSamples} over represented samples in ${over_representation_dataset} dataset.`}
          </span>
        </div>
        <CorrelatedMetadataOnCluster infoElements={infoElements} />
      </span>
    );
  }

  if (isDuplicationInsightClusterInsight(insightData)) {
    const { n_duplicate_samples, subset } = insightData;

    const duplicatedSamples = Math.ceil(n_duplicate_samples);

    return (
      <span>
        <span>
          Detected {duplicatedSamples} duplicate samples at {subset} subset
        </span>
        <div className="h-fit w-fit inline-block">
          <ModelChip {...selectedModel} hideInvisibilityIcon />
        </div>
        <CorrelatedMetadataOnCluster infoElements={infoElements} />
      </span>
    );
  }

  if (isDataLeakageInsightClusterInsight(insightData)) {
    const {
      first_subset,
      second_subset,
      n_cross_subsets_duplications,
    } = insightData;

    const dataLeakageSamples = Math.ceil(n_cross_subsets_duplications);

    return (
      <span>
        <span>
          Detected {dataLeakageSamples} data leakages samples over{' '}
          {first_subset} and {second_subset} subsets
        </span>
        <div className="h-fit w-fit inline-block">
          <ModelChip {...selectedModel} hideInvisibilityIcon />
        </div>
        <CorrelatedMetadataOnCluster infoElements={infoElements} />
      </span>
    );
  }

  console.error('should not happen', insightData);
  return <>Unknown insight</>;
}

const NUM_OF_METADATA_TO_SHOW_WITHOUT_SHOW_MORE = 3;

type CorrelatedMetadataOnClusterProps = {
  infoElements?: Array<MutualInformationElement>;
};
export function CorrelatedMetadataOnCluster({
  infoElements,
}: CorrelatedMetadataOnClusterProps) {
  const [showingMore, setShowingMore] = useState(false);
  const handleShowMore = useCallback(() => setShowingMore(true), []);

  if (!infoElements?.length) return null;

  return (
    <span className="block">
      <span className="block pt-2 text-sm text-gray-350">
        Correlated metadata:
      </span>

      <span className="flex gap-2 flex-wrap">
        {infoElements
          .slice(
            0,
            showingMore
              ? infoElements.length
              : NUM_OF_METADATA_TO_SHOW_WITHOUT_SHOW_MORE
          )
          .map(({ is_categorical, feature_name, feature_value, direction }) => (
            <MutualInfoElement
              key={feature_name}
              name={feature_name}
              value={feature_value}
              isCategorical={is_categorical}
              isDown={direction === 'down'}
            />
          ))}

        {!showingMore &&
          infoElements.length > NUM_OF_METADATA_TO_SHOW_WITHOUT_SHOW_MORE && (
            <ShowMore onClick={handleShowMore} />
          )}
      </span>
    </span>
  );
}

type MutualInfoElementProps = {
  isCategorical: boolean;
  name: string;
  value?: string;
  isDown: boolean;
};

function MutualInfoElement({
  isCategorical,
  name,
  value,
  isDown,
}: MutualInfoElementProps) {
  const markedWordClasses = 'italic text-primary-400';
  const chipClasses = 'gap-2 bg-gray-700 border-gray-600';

  if (isCategorical) {
    return (
      <Chip borderClassName={chipClasses}>
        {name}:{isDown && <span className={markedWordClasses}>not</span>}{' '}
        {value}
      </Chip>
    );
  }
  return (
    <Chip borderClassName={chipClasses}>
      <span className={markedWordClasses}>{isDown ? 'lower' : 'higher'}</span>
      {name}
    </Chip>
  );
}

function ShowMore({ onClick }: { onClick: () => void }) {
  return (
    <Chip className="bg-gray-900 border-gray-700 w-fit font-sans cursor-pointer">
      <div onClick={onClick} className="flex gap-2 items-center">
        <span>MORE</span>
        {/* three horizntal dots in the center */}
        <div className="flex gap-1">
          <div className="w-1 h-1 bg-gray-400 rounded-full" />
          <div className="w-1 h-1 bg-gray-400 rounded-full" />
          <div className="w-1 h-1 bg-gray-400 rounded-full" />
        </div>
      </div>
    </Chip>
  );
}
