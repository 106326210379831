import { Fragment } from 'react';
import { Tab } from '@headlessui/react';
import { OpenMessageContactFormWidget } from './OpenMessageContactFormWidget';
import { SupportMenuButton } from './SupportMenuButton';
import { EmailIcon, SlackIcon, ZoomIcon } from '../icons';
import { SlackSupportWidget } from './SlackSupportWidget';
import { PopupPane, PopupPaneTitle } from '../molecules/PopupPane';
import { StateObject } from '../../core/types';
import { CalendarSupportWidget } from './CalendarSupportWidget';

export interface SupportDialogProps {
  openState: StateObject;
  selectedIndex?: number;
  className?: string;
}
export function SupportDialog({
  openState,
  selectedIndex = 0,
  className = '',
}: SupportDialogProps) {
  return (
    <PopupPane className={className} openState={openState} showClose>
      <PopupPaneTitle className="capitalize h-1/6 flex items-center">
        support
      </PopupPaneTitle>
      <p className="h-1/6 w-full">
        If you couldn’t find an answer to your issue on our Documentation, you
        are welcome to use our support’s help. While testing this time-limited
        version, our support team is here to assist you, at no cost. Please use
        any of the following channels to reach out to us:
      </p>
      <Tab.Group
        selectedIndex={selectedIndex}
        as="div"
        vertical
        className="flex flex-row w-full h-4/6 gap-2"
      >
        <div className="h-full w-2/5 flex flex-col">
          <Tab.List className="flex flex-col gap-1.5 h-full">
            <Tab as={Fragment}>
              {({ selected }) => (
                <SupportMenuButton
                  selected={selected}
                  icon={<SlackIcon className="w-1/3 h-1/3" />}
                >
                  slack support channel
                </SupportMenuButton>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <SupportMenuButton
                  selected={selected}
                  icon={<ZoomIcon className="w-1/3 h-1/3" />}
                >
                  book a zoom session
                </SupportMenuButton>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <SupportMenuButton
                  selected={selected}
                  icon={<EmailIcon className="w-1/3 h-1/3" />}
                >
                  send a message
                </SupportMenuButton>
              )}
            </Tab>
          </Tab.List>
        </div>
        <div className="h-full w-3/5">
          <Tab.Panels className="w-full h-full">
            <Tab.Panel className="w-full h-full">
              <SlackSupportWidget />
            </Tab.Panel>
            <Tab.Panel className="w-full h-full">
              <CalendarSupportWidget />
            </Tab.Panel>
            <Tab.Panel className="w-full h-full">
              <OpenMessageContactFormWidget />
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </PopupPane>
  );
}
