import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgApplyToTest = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      d="m5.78 15.194 2.24-2.24H1.667v-1.249H8.02l-2.24-2.24.884-.883 3.747 3.748-3.747 3.747-.884-.883ZM20.333 6.333v1.334h-.666V17a2.666 2.666 0 1 1-5.334 0V7.667h-.666V6.333h6.666Zm-2 5.334h-2.666V17a1.333 1.333 0 1 0 2.666 0v-5.333ZM17.667 15a.667.667 0 1 1 0 1.333.667.667 0 0 1 0-1.333Zm-1.334-2a.666.666 0 1 1 0 1.333.666.666 0 0 1 0-1.333Zm2-5.333h-2.666v2.666h2.666V7.667Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgApplyToTest;
