import { UserRole } from '@tensorleap/api-client';

export const USER_ROLE_TO_ROLE_NAME: Record<UserRole, UserRole> = {
  demo: UserRole.User,
  user: UserRole.User,
  admin: UserRole.Admin,
};

export type TeamMemberStatus = 'Pending' | 'Active';

export type TeamMemberData = {
  cid: string;
  userName: string;
  teamId: string;
  status: TeamMemberStatus;
  role: UserRole;
  joiningDate: Date;
};
