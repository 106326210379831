import { ComponentType, SVGProps, useEffect } from 'react';

import {
  Hand,
  Select,
  SelectionMinusIcon,
  SelectionPlusIcon,
} from '../../ui/icons';
import { IconMenu } from '../../ui/atoms/IconMenu';
import { ListItem } from '../../ui/atoms/List';
import { ToggleButtonGroup } from '../../ui/atoms/ToggleButtonGroup';
import { UseMultiSelectMode } from '../../core/useMultiSelect';
import { useScatterData } from '../ScatterDataContext';
import {
  AltName,
  CtrlName,
  PressedKeys,
  ShiftName,
  SpaceName,
} from '../../core/useKeyComboListener';
import { Chip } from '../../ui/atoms/Chip';

type selectionModeOption = {
  value: UseMultiSelectMode;
  icon: JSX.Element;
  title: JSX.Element | string;
};

export type ScatterMode = 'grab' | 'box-selection' | 'magic-selection';

function HoldCommand({ name }: { name: string }) {
  return (
    <div className="flex flex-1 gap-2 items-center text-gray-400 text-sm">
      hold
      <Chip className="max-h-6 whitespace-nowrap bg-gray-800/20"> {name}</Chip>
    </div>
  );
}

type SelectionMenuItem = {
  title: JSX.Element | string;
  Icon: ComponentType<SVGProps<SVGSVGElement>>;
  value: ScatterMode;
  selectionModeOptions?: selectionModeOption[];
};

const SELECTION_MODE_OPTIONS: selectionModeOption[] = [
  {
    value: 'add',
    icon: <SelectionPlusIcon />,
    title: (
      <>
        Add to selection <HoldCommand name={CtrlName} />
      </>
    ),
  },
  {
    value: 'subtract',
    icon: <SelectionMinusIcon />,
    title: (
      <>
        Remove from selection <HoldCommand name={AltName} />{' '}
      </>
    ),
  },
  {
    value: 'select',
    title: 'Select only',
    icon: <Select />,
  },
];

export const SELECTION_MENU_ITEMS: SelectionMenuItem[] = [
  {
    title: (
      <>
        Grab
        <span className="flex-1" />
        <HoldCommand name={SpaceName} />
      </>
    ),
    Icon: Hand,
    value: 'grab',
  },
  {
    title: (
      <>
        Area Selector
        <span className="flex-1" />
        <HoldCommand name={ShiftName} />
      </>
    ),
    Icon: Select,
    value: 'box-selection',
    selectionModeOptions: SELECTION_MODE_OPTIONS,
  },
];

const preventDefault = (e: React.MouseEvent) => {
  e.stopPropagation();
  e.preventDefault();
};

type SelectionMenuProps = {
  pressedKeys: PressedKeys;
  isDrawing: boolean;
};

export function SelectionMenu({
  pressedKeys,
  isDrawing,
}: SelectionMenuProps): JSX.Element {
  const {
    scatterMode,
    setScatterMode,
    setPressedScatterMode,
    selection: { mode, setMode, setPressedMode },
  } = useScatterData();

  useEffect(() => {
    if (pressedKeys.isAltPressed) {
      setPressedMode('subtract');
    } else if (pressedKeys.isCtrlPressed) {
      setPressedMode('add');
    } else {
      setPressedMode(undefined);
    }

    if (isDrawing || pressedKeys.isShiftPressed) {
      setPressedScatterMode('box-selection');
    } else if (pressedKeys.isSpacePressed) {
      setPressedScatterMode('grab');
    } else {
      setPressedScatterMode(undefined);
    }
  });

  const selectedMenuItem =
    SELECTION_MENU_ITEMS.find(({ value }) => value === scatterMode) ||
    SELECTION_MENU_ITEMS[0];
  const { Icon, selectionModeOptions } = selectedMenuItem;

  return (
    <div
      onMouseDown={preventDefault}
      className="flex absolute bottom-4 gap-2 items-center left-4"
      onClick={preventDefault}
    >
      <IconMenu
        iconWrapperClassName="h-10 w-10 bg-gray-850"
        icon={<Icon />}
        paperClassName="-mt-10 ml-10 min-w-[15rem]"
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        closeOnClick
      >
        {SELECTION_MENU_ITEMS.map(({ title, value, Icon }, index) => (
          <ListItem
            key={value}
            selected={scatterMode === value}
            onClick={() => {
              setScatterMode(value);
              if (!SELECTION_MENU_ITEMS[index].selectionModeOptions) {
                setMode('select');
              }
            }}
          >
            <div className="flex flex-row items-center gap-2 w-full">
              <Icon />
              {title}
            </div>
          </ListItem>
        ))}
      </IconMenu>

      {selectionModeOptions && (
        <ToggleButtonGroup
          onChange={setMode}
          options={selectionModeOptions}
          value={mode}
          tooltipClassName="bg-gray-800 text-base"
        />
      )}
    </div>
  );
}

export default SelectionMenu;
