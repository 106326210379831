import { isNumber, isString } from 'lodash';
import {
  buildGetAndValidateValue,
  FieldValueExtractor,
  invertCondition,
  isStringOrNumberOrArray,
} from './utils';
import { NumberOrString } from '@tensorleap/api-client';

export type TestCondition<T> = (item: T) => boolean;

export function buildNotEqualCondition<T>(
  fieldName: string,
  notEqualTo: string | number,
  extractFieldValue?: FieldValueExtractor<T>
): TestCondition<T> {
  return invertCondition(
    buildEqualCondition(fieldName, notEqualTo, extractFieldValue)
  );
}

export function buildEqualCondition<T>(
  fieldName: string,
  equalTo: string | number,
  extractFieldValue?: FieldValueExtractor<T>
): TestCondition<T> {
  const getObjectNestedValue = buildGetAndValidateValue<
    NumberOrString | NumberOrString[],
    T
  >(fieldName, isStringOrNumberOrArray, extractFieldValue);

  return (item: T) => {
    const value = getObjectNestedValue(item);
    if (value === undefined) return false;
    if (Array.isArray(value)) return value.includes(equalTo);
    return equalTo === value;
  };
}

export function buildNotBetweenCondition<T>(
  fieldName: string,
  min?: number,
  max?: number,
  extractFieldValue?: FieldValueExtractor<T>
): TestCondition<T> {
  return invertCondition(
    buildBetweenCondition<T>(fieldName, min, max, extractFieldValue)
  );
}

export function buildBetweenCondition<T>(
  fieldName: string,
  min?: number,
  max?: number,
  extractFieldValue?: FieldValueExtractor<T>
): TestCondition<T> {
  const getObjectNestedValue = buildGetAndValidateValue<number, T>(
    fieldName,
    isNumber,
    extractFieldValue
  );
  let isBetweenMinToMax = (_: number) => false;
  if (min !== undefined && max !== undefined)
    isBetweenMinToMax = (value) => value >= min && value <= max;
  else if (min !== undefined) isBetweenMinToMax = (value) => value >= min;
  else if (max !== undefined) isBetweenMinToMax = (value) => value <= max;
  else {
    throw Error('Either min or max are required');
  }

  return (item: T) => {
    const value = getObjectNestedValue(item);
    if (value === undefined) return false;
    return isBetweenMinToMax(value);
  };
}

export function buildInCondition<T>(
  fieldName: string,
  inValues: (string | number)[],
  extractFieldValue?: FieldValueExtractor<T>
): TestCondition<T> {
  const getObjectNestedValue = buildGetAndValidateValue<
    NumberOrString | NumberOrString[],
    T
  >(fieldName, isStringOrNumberOrArray, extractFieldValue);
  const inValuesSet = new Set<string | number>(inValues);

  return (item: T) => {
    const value = getObjectNestedValue(item);
    if (value === undefined) return false;
    if (Array.isArray(value)) return value.some((v) => inValuesSet.has(v));
    return inValuesSet.has(value);
  };
}

export function buildClusterCondition<T>(
  fieldName: string,
  blob: string,
  extractFieldValue?: FieldValueExtractor<T>
): TestCondition<T> {
  const getObjectNestedValue = buildGetAndValidateValue<string, T>(
    fieldName,
    isString,
    extractFieldValue
  );

  return (item: T) => {
    const value = getObjectNestedValue(item);
    if (value === undefined) return false;
    if (Array.isArray(value)) return value.includes(blob);
    return blob === value;
  };
}
