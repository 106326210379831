import { IconButton, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { ReactNode } from 'react';

export type ItemAction = {
  icon: ReactNode;
  label: string;
  onClick: () => void;
};

export type ItemHoverActionsProps = {
  actions: ItemAction[];
  inRow?: boolean;
  snapToClass?: string;
  className?: string;
};

export function ItemHoverActions({
  actions,
  className,
  inRow,
  snapToClass = 'right-0',
}: ItemHoverActionsProps) {
  const Elm = inRow ? 'td' : 'div';
  return (
    <Elm
      className={clsx(
        className,
        'hidden group-hover:flex gap-1',
        'bg-gradient-to-r from-primary-925/60 via-primary-925 to-primary-925',
        'absolute inset-y-0',
        snapToClass
      )}
    >
      {actions.map(({ icon, label, onClick }) => (
        <Tooltip key={label} arrow title={label} placement="right">
          <IconButton
            className="h-12 w-12"
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              onClick();
            }}
          >
            {icon}
          </IconButton>
        </Tooltip>
      ))}
    </Elm>
  );
}
