import clsx from 'clsx';
import React, {
  PropsWithChildren,
  MouseEventHandler,
  useMemo,
  ForwardedRef,
} from 'react';

import { useRipple } from './utils/useRipple';

export type Variant =
  | 'filled'
  | 'text'
  | 'inverted-gray'
  | 'inverted-red'
  | 'inverted-green'
  | 'inverted-blue'
  | 'inverted-orange'
  | 'inverted-filled-gray'
  | 'inverted-filled-red'
  | 'inverted-filled-green'
  | 'inverted-filled-blue'
  | 'inverted-filled-orange'
  | 'action'
  | 'action-icon'
  | 'dark'
  | 'outline';

export type ButtonProps = PropsWithChildren<
  {
    className?: string;
    fullWidth?: boolean;
    disabled?: boolean;
    variant?: Variant;
    type?: 'submit' | 'reset' | 'button';
    uppercase?: boolean;
    capitalize?: boolean;
    form?: string;
    tourId?: string;
  } & Partial<
    | { href: string; onClick: never; openInNewTab: boolean }
    | {
        onClick: MouseEventHandler<HTMLElement>;
        href: never;
        openInNewTab: never;
      }
  >
>;

const SHARED_CLASSES = [
  'flex',
  'items-center',
  'justify-center',
  'gap-1',
  'font-bold',
  'outline-none',
  'uppercase',
  'tracking-wider',
  'focus:outline-none',
  'focus:shadow-none',
  'transition-all',
  'duration-300',
  'rounded',
  'text-sm',
].join(' ');

const SHARED_ACTION_CLASSES = [
  'bg-transparent',
  'hover:bg-primary-300',
  'hover:text-primary-700',
  'disabled:text-gray-400',
  'disabled:bg-transparent',
].join(' ');

const SHARED_INVERTED_VARIANT_CLASSES = [
  'bg-transparent',
  'border',
  'border-solid',
  'shadow-none',
  'disabled:bg-transparent',
  'py-2.5',
  'px-5',
].join(' ');

const SHARED_INVERTED_FILLED_VARIANT_CLASSES = [
  'border',
  'border-solid',
  'shadow-none',
  'py-2.5',
  'px-5',
].join(' ');

const VARIANTS: Record<Variant, string> = {
  filled: [
    'text-white',
    'bg-primary-500',
    'hover:bg-primary-700',
    'focus:bg-primary-400',
    'active:bg-primary-800',
    'disabled:bg-gray-600',
    'disabled:text-gray-400',
    'shadow-md-primary',
    'hover:shadow-lg-primary',
    'py-2.5',
    'px-5',
  ].join(' '),
  'inverted-gray': [
    SHARED_INVERTED_VARIANT_CLASSES,
    'text-gray-500',
    'border-gray-500',
    'hover:bg-gray-300',
    'hover:border-gray-700',
    'hover:text-gray-700',
    'active:bg-gray-100',
    'disabled:border-gray-400',
    'disabled:text-gray-400',
  ].join(' '),
  'inverted-red': [
    SHARED_INVERTED_VARIANT_CLASSES,
    'text-error-500',
    'border-error-500',
    'hover:bg-error-300',
    'hover:border-error-700',
    'hover:text-error-700',
    'active:bg-error-100',
    'disabled:border-error-400',
    'disabled:text-error-400',
  ].join(' '),
  'inverted-green': [
    SHARED_INVERTED_VARIANT_CLASSES,
    'text-success-500',
    'border-success-500',
    'hover:bg-success-300',
    'hover:border-success-700',
    'hover:text-success-700',
    'active:bg-success-100',
    'disabled:border-success-400',
    'disabled:text-success-400',
  ].join(' '),
  'inverted-blue': [
    SHARED_INVERTED_VARIANT_CLASSES,
    'text-dashboard-500',
    'border-dashboard-500',
    'hover:bg-dashboard-300',
    'hover:border-dashboard-700',
    'hover:text-dashboard-700',
    'active:bg-dashboard-100',
    'disabled:border-dashboard-400',
    'disabled:text-dashboard-400',
  ].join(' '),
  'inverted-orange': [
    SHARED_INVERTED_VARIANT_CLASSES,
    'text-warning-500',
    'border-warning-500',
    'hover:bg-warning-300',
    'hover:border-warning-700',
    'hover:text-warning-700',
    'active:bg-warning-100',
    'disabled:border-warning-400',
    'disabled:text-warning-400',
  ].join(' '),
  'inverted-filled-gray': [
    SHARED_INVERTED_FILLED_VARIANT_CLASSES,
    'bg-gray-800',
    'text-gray-300',
    'border-gray-400',
    'hover:bg-gray-400',
    'hover:border-gray-700',
    'hover:text-gray-800',
    'disabled:border-gray-600',
    'disabled:text-gray-600',
    'disabled:bg-gray-900',
  ].join(' '),
  'inverted-filled-red': [
    SHARED_INVERTED_FILLED_VARIANT_CLASSES,
    'bg-error-800',
    'text-error-300',
    'border-error-400',
    'hover:bg-error-400',
    'hover:border-error-700',
    'hover:text-error-800',
    'disabled:border-error-600',
    'disabled:text-error-600',
    'disabled:bg-error-950',
  ].join(' '),
  'inverted-filled-green': [
    SHARED_INVERTED_FILLED_VARIANT_CLASSES,
    'bg-success-800',
    'text-success-300',
    'border-success-400',
    'hover:bg-success-400',
    'hover:border-success-700',
    'hover:text-success-800',
    'disabled:border-success-600',
    'disabled:text-success-600',
    'disabled:bg-success-950',
  ].join(' '),
  'inverted-filled-blue': [
    SHARED_INVERTED_FILLED_VARIANT_CLASSES,
    'bg-dashboard-800',
    'text-dashboard-300',
    'border-dashboard-400',
    'hover:bg-dashboard-400',
    'hover:border-dashboard-700',
    'hover:text-dashboard-800',
    'disabled:border-dashboard-600',
    'disabled:text-dashboard-600',
    'disabled:bg-dashboard-950',
  ].join(' '),
  'inverted-filled-orange': [
    SHARED_INVERTED_FILLED_VARIANT_CLASSES,
    'bg-warning-800',
    'text-warning-300',
    'border-warning-400',
    'hover:bg-warning-400',
    'hover:border-warning-700',
    'hover:text-warning-800',
    'disabled:border-warning-600',
    'disabled:text-warning-600',
    'disabled:bg-warning-950',
  ].join(' '),
  text: [
    'bg-transparent',
    'text-primary-500',
    'hover:bg-primary-300',
    'hover:text-primary-700',
    'active:bg-primary-100',
    'disabled:text-gray-400',
    'disabled:bg-transparent',
    'py-2.5',
    'px-4',
  ].join(' '),
  action: [SHARED_ACTION_CLASSES, 'py-2.5', 'px-4'].join(' '),
  'action-icon': [SHARED_ACTION_CLASSES, 'p-2.5'].join(' '),
  dark: [
    'bg-gray-800',
    'text-gray-200',
    'hover:bg-gray-850',
    'hover:text-gray-100',
    'active:bg-gray-900',
    'disabled:text-gray-400',
    'disabled:bg-transparent',
  ].join(' '),
  outline: [
    'bg-transparent',
    'text-primary-500',
    'hover:bg-primary-300',
    'hover:text-primary-700',
    'active:bg-primary-100',
    'disabled:text-gray-400',
    'disabled:bg-transparent',
    'py-2.5',
    'px-5',
    'border border-primary-500',
    'disabled:border-gray-500',
  ].join(' '),
};

export const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(
  (
    {
      className,
      fullWidth,
      variant = 'filled',
      uppercase,
      capitalize,
      children,
      href,
      onClick,
      disabled = false,
      type,
      form,
      openInNewTab,
      tourId,
      ...rest
    },
    ref
  ) => {
    const ripple = useRipple();
    const isLink = typeof href === 'string' && !!href.length && !disabled;

    const buttonProps = useMemo(
      () => ({
        disabled,
        type,
        form,
        ...(isLink && { href }),
        ...(isLink &&
          openInNewTab && { target: '_blank', rel: 'noopener noreferrer' }),
        ...(!isLink && { onClick }),
        onMouseUp: ripple,
        className: clsx(
          className,
          SHARED_CLASSES,
          VARIANTS[variant],
          fullWidth && 'w-full',
          { uppercase, capitalize }
        ),
        ...rest,
      }),
      [
        capitalize,
        className,
        disabled,
        form,
        fullWidth,
        href,
        isLink,
        onClick,
        openInNewTab,
        rest,
        ripple,
        type,
        uppercase,
        variant,
      ]
    );

    return isLink ? (
      <a ref={ref as ForwardedRef<HTMLAnchorElement>} {...buttonProps}>
        {children}
      </a>
    ) : (
      <button
        ref={ref as ForwardedRef<HTMLButtonElement>}
        id={tourId}
        {...buttonProps}
      >
        {children}
      </button>
    );
  }
);
Button.displayName = 'Button';
