import { Dispatch, SetStateAction, useState } from 'react';
import { useMergedObject } from './useMergedObject';

export type ActionResult<T = void> = Promise<T> | T;

export type Setter<T = string> = Dispatch<SetStateAction<T>>;

export type ClassNameProp = {
  className?: string;
};

export type Nullable<T> = T | null | undefined;
export function isNonNullable<T>(param: Nullable<T>): param is NonNullable<T> {
  return param !== null && param !== undefined;
}

export type StateObject<T = boolean> = {
  state: T;
  setState: Setter<T>;
};

export function useStateObject<T = boolean>(defaultState: T): StateObject<T> {
  const [state, setState] = useState<T>(defaultState);
  return useMergedObject({
    state,
    setState,
  }) as StateObject<T>;
}
