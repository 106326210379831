import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.22 2.754a.25.25 0 0 0-.44 0L2.698 14.132a.25.25 0 0 0 .22.368h12.164a.25.25 0 0 0 .22-.368L9.22 2.754Zm-1.763-.707c.659-1.234 2.427-1.234 3.086 0l6.082 11.378A1.75 1.75 0 0 1 15.082 16H2.918a1.75 1.75 0 0 1-1.543-2.575L7.457 2.047ZM10 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-.25-5.25a.75.75 0 0 0-1.5 0v2.5a.75.75 0 0 0 1.5 0v-2.5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgAlert;
