import {
  PropsWithChildren,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { StateObject } from '../../core/types';
import { useOutsideClick } from '../../core/useOutsideClick';

export type Position = 'top' | 'right' | 'bottom' | 'left';
export type DrawerSize =
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '5xl'
  | '6xl'
  | '7xl';

const POSITION_TRANSLATIONS: Record<DrawerSize, Record<Position, string>> = {
  md: {
    top: '-translate-y-80',
    right: 'translate-x-80',
    bottom: 'translate-y-80',
    left: '-translate-x-80',
  },
  lg: {
    top: '-translate-y-120',
    right: 'translate-x-120',
    bottom: 'translate-y-120',
    left: '-translate-x-120',
  },
  xl: {
    top: '-translate-y-160',
    right: 'translate-x-160',
    bottom: 'translate-y-160',
    left: '-translate-x-160',
  },
  '2xl': {
    top: '-translate-y-200',
    right: 'translate-x-200',
    bottom: 'translate-y-200',
    left: '-translate-x-200',
  },
  '3xl': {
    top: '-translate-y-240',
    right: 'translate-x-240',
    bottom: 'translate-y-240',
    left: '-translate-x-240',
  },
  '4xl': {
    top: '-translate-y-280',
    right: 'translate-x-280',
    bottom: 'translate-y-280',
    left: '-translate-x-280',
  },
  '5xl': {
    top: '-translate-y-320',
    right: 'translate-x-320',
    bottom: 'translate-y-320',
    left: '-translate-x-320',
  },
  '6xl': {
    top: '-translate-y-360',
    right: 'translate-x-360',
    bottom: 'translate-y-360',
    left: '-translate-x-360',
  },
  '7xl': {
    top: '-translate-y-400',
    right: 'translate-x-400',
    bottom: 'translate-y-400',
    left: '-translate-x-400',
  },
};

const POSITION_JUSTIFY: Record<Position, string> = {
  top: 'justify-start',
  right: 'justify-end',
  bottom: 'justify-end',
  left: 'justify-start',
};

const POSITION_FLEX: Record<Position, string> = {
  top: 'flex flex-col',
  right: 'flex flex-row',
  bottom: 'flex flex-col',
  left: 'flex flex-row',
};

export type DrawerProps = PropsWithChildren<{
  position?: Position;
  persist?: boolean;
  drawerClassName?: string;
  contentClassName?: string;
  subContentClassName?: string;
  openState: StateObject;
  subContent?: ReactNode;
  drawerSize?: DrawerSize;
  closeOnBlur?: boolean;
}>;

export function Drawer({
  position = 'left',
  persist = false,
  drawerClassName = '',
  contentClassName = '',
  subContentClassName = '',
  children,
  subContent,
  openState: { state: isOpen, setState: setIsOpen },
  drawerSize = 'md',
  closeOnBlur = true,
}: DrawerProps) {
  const drawerRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const [initialized, setInitialized] = useState(false);
  const [isFocused, setIsFocused] = useState(isOpen);

  const handleOnBlur = useCallback(
    (event) => {
      if (
        closeOnBlur &&
        !event.currentTarget.contains(event.relatedTarget) &&
        !persist &&
        isOpen
      ) {
        setIsFocused(false);
      }
    },
    [closeOnBlur, persist, isOpen]
  );

  useOutsideClick({
    refs: [drawerRef, childrenRef],
    allowUnrenderedRefs: [],
    func: handleOnBlur,
  });

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (isOpen && !isFocused) {
        setIsOpen(false);
      }
    }, 150);

    return () => {
      clearTimeout(timerId);
    };
  }, [isFocused, isOpen, setIsOpen]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setInitialized(true);
    }, 150);

    return () => {
      clearTimeout(timerId);
    };
  }, [childrenRef]);

  useEffect(() => {
    if (isOpen) {
      drawerRef.current?.focus({
        preventScroll: true,
      });
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
  }, [isOpen]);

  const fullDrawerElement = useMemo(
    () =>
      DrawerAndSubcontent(
        position,
        children,
        subContent,
        childrenRef,
        initialized,
        contentClassName,
        subContentClassName
      ),
    [
      children,
      contentClassName,
      initialized,
      position,
      subContent,
      subContentClassName,
    ]
  );

  const drawerCss = getDrawerClasses(position, drawerClassName);
  const closeTransform = POSITION_TRANSLATIONS[drawerSize][position];
  const openTransform =
    position == 'bottom' || position == 'top'
      ? 'translate-x-0'
      : 'translate-y-0';

  const transformation =
    isOpen && (persist || isFocused) ? openTransform : closeTransform;

  return (
    <div
      className={clsx(drawerCss, transformation)}
      ref={drawerRef}
      tabIndex={1}
    >
      {fullDrawerElement}
    </div>
  );
}

function DrawerAndSubcontent(
  position: Position,
  children: ReactNode,
  subContent: ReactNode,
  ref: RefObject<HTMLDivElement>,
  initialized: boolean,
  contentClassname: string,
  subContentClassName: string
): ReactNode {
  if (!subContent) {
    return (
      <div className="pointer-events-auto" ref={ref}>
        {children}
      </div>
    );
  }
  if (position == 'right' || position == 'bottom') {
    return (
      <>
        <div
          className={clsx('outline-0 outline-none ml-0', subContentClassName)}
        >
          {subContent}
        </div>
        {initialized && (
          <div
            ref={ref}
            className={clsx(
              'outline-0 outline-none ml-0 pl-0',
              'pointer-events-auto',
              contentClassname
            )}
          >
            {children}
          </div>
        )}
      </>
    );
  }
  return (
    <>
      {initialized && (
        <div
          ref={ref}
          className={clsx(
            'outline-0 outline-none ml-0 pl-0',
            'pointer-events-auto',
            contentClassname
          )}
        >
          {children}
        </div>
      )}
      <div
        className={clsx(
          'outline-0 outline-none mr-0 pr-0',
          subContentClassName
        )}
      >
        {subContent}
      </div>
    </>
  );
}

function getDrawerClasses(position: Position, className: string): string {
  return [
    'outline-none shadow-md',
    'pointer-events-none',
    'transition-all 150ms ease-in-out',
    POSITION_JUSTIFY[position],
    POSITION_FLEX[position],
    className,
  ].join(' ');
}
