import { useCallback, useMemo } from 'react';
import { CodeIntegrationBinder, Dataset } from '@tensorleap/api-client';
import { useDatasets } from '../../core/DatasetsContext';
import { DatasetVersionProperties } from '../../layer-details/DatasetVersionProperties';
import {
  NodeDetailsPanel,
  NoPreview,
} from '../../layer-details/NodeDetalisPanel';
import { MiniDocIcon, Settings } from '../../ui/icons';
import {
  BranchChip,
  DatasetVersionsPopover,
  ShowVersion,
} from '../../ui/molecules/DatasetVersionsPopover';
import { Select } from '../../ui/atoms/Select';
import { Button } from '../../ui/atoms/Button';
import { Tooltip } from '@material-ui/core';
import { useNetworkMapContext } from '../../core/NetworkMapContext';
import { ValidateAssetsButton } from '../../ui/molecules/ValidateAssetsButton';
import { useCurrentProject } from '../../core/CurrentProjectContext';
import { CodeIntegrationEditor } from './CodeIntegrationEditor';
import { useMappingUtils } from '../../core/mappingUtils';
import {
  useMappingCreateOrUpdateText,
  useMappingCreateOrUpdateTooltip,
  NO_DATASET_MAPPING_MESSAGE,
  DATASET_MAPPING_MISSMATCH_MESSAGE,
  APPLY_BUTTON_TOOLTIP,
  APPLY_BUTTON_TEXT,
} from '../../network-editor/wizard/cards/DatasetMappingCreateUpdateApplyData';

export interface MapCodeViewProps {
  selectedCodeIntegrationBinder?: CodeIntegrationBinder;
  handleConnectedDatasetVersionChange: (binder?: CodeIntegrationBinder) => void;
}
export function MapCodeView({
  selectedCodeIntegrationBinder,
  handleConnectedDatasetVersionChange,
}: MapCodeViewProps): JSX.Element {
  const {
    dataset,
    switchDatasetVersion,
    datasetMappingFileExists,
    networkAndDatasetMappingsAreEqual,
    datasetsMap,
    datasets,
    datasetVersions,
    codeIntegrationVersionToVersionIndexMap,
  } = useDatasets();

  const { addOrUpdateNetworkMapping, applyDatasetMapping } = useMappingUtils();

  const { codeIntegrationIsExpanded } = useNetworkMapContext();

  const {
    currentModelGraph,
    selectedCodeIntegrationVersion,
  } = useCurrentProject();

  const handleConnectedDatasetChange = useCallback(
    async (_, changedDataset: Dataset | null) => {
      if (!changedDataset) {
        console.error(
          'Failed to switch connected dataset since the required dataset object was not found',
          datasetsMap
        );
        return;
      }

      const binder: CodeIntegrationBinder = {
        type: 'bindByBranch',
        codeIntegrationId: changedDataset.cid,
        branch: changedDataset.defaultBranch,
      };

      handleConnectedDatasetVersionChange(binder);
    },
    [datasetsMap, handleConnectedDatasetVersionChange]
  );

  const handleBranchChange = useCallback(
    (branch?: string) => {
      if (!branch || !selectedCodeIntegrationVersion) {
        console.error('Failed to switch branch since the branch was not found');
        return;
      }
      if (dataset) {
        handleConnectedDatasetVersionChange({
          type: 'bindByBranch',
          codeIntegrationId: dataset?.cid,
          branch,
        });
      }
    },
    [
      dataset,
      handleConnectedDatasetVersionChange,
      selectedCodeIntegrationVersion,
    ]
  );

  const onConnectedDatasetVersionChange = useCallback(
    (id: string) => {
      const datasetVersion = datasetVersions.find(({ cid }) => cid === id);
      if (!datasetVersion) {
        console.error(
          'Failed to find datasetVersion according to the event datasetVersionId',
          id
        );
        return;
      }

      switchDatasetVersion(datasetVersion);

      const dataset = datasetsMap?.get(datasetVersion.datasetId);
      if (!dataset) {
        console.error(
          'Failed to switch connected datasetVersion since the required datasetId was not found',
          id,
          datasetsMap,
          datasetVersion
        );
        return;
      }

      handleConnectedDatasetVersionChange({
        type: 'bindByVersionId',
        codeIntegrationVersionId: id,
      });
    },
    [
      datasetVersions,
      datasetsMap,
      handleConnectedDatasetVersionChange,
      switchDatasetVersion,
    ]
  );

  const isConnectedVersionValid =
    selectedCodeIntegrationVersion?.testStatus === 'testSuccess';
  const createOrUpdateText = useMappingCreateOrUpdateText();
  const createOrUpdateTooltip = useMappingCreateOrUpdateTooltip();

  const selectedBranch = useMemo(() => {
    if (selectedCodeIntegrationBinder?.type === 'bindByBranch') {
      return selectedCodeIntegrationBinder.branch;
    } else {
      return selectedCodeIntegrationVersion?.branch;
    }
  }, [selectedCodeIntegrationBinder, selectedCodeIntegrationVersion]);

  return (
    <div className="h-full w-full flex flex-row-reverse bg-gray-900">
      {/* todo: We have wired situation that cause the hasUnsavedCode to be true even if there is no change need more investigation */}
      {/* <Prompt
        when={hasUnsavedCode}
        message="Leaving will discard your unsaved changes"
      /> */}

      <div className="flex w-80 items-center flex-col overflow-y-auto">
        <NodeDetailsPanel
          className="p-4 gap-4"
          title="Settings"
          icon={<Settings />}
          openByDefault
        >
          <Select
            label="SELECTED SCRIPTS"
            optionID="cid"
            value={dataset?.cid}
            optionToLabel={(option) => option?.name || 'None'}
            options={datasets}
            onChange={handleConnectedDatasetChange}
          />
          {dataset && selectedBranch && (
            <>
              <div className="flex gap-2 flex-col">
                <span className="text-gray-400 text-xs uppercase">
                  selected{' '}
                  {selectedCodeIntegrationBinder?.type === 'bindByBranch'
                    ? 'script branch'
                    : 'script version'}
                  :
                </span>
                <div className="w-full flex items-start gap-2">
                  <DatasetVersionsPopover
                    codeIntegrationVersionToVersionIndexMap={
                      codeIntegrationVersionToVersionIndexMap
                    }
                    dataset={dataset}
                    currentBranch={selectedBranch ?? dataset?.defaultBranch}
                    currentDatasetVersion={selectedCodeIntegrationVersion}
                    hideNonPassedVersionsByDefault
                    showBranchByDefault={
                      selectedCodeIntegrationBinder?.type === 'bindByBranch'
                    }
                    handleDatasetVersionIdSelect={
                      onConnectedDatasetVersionChange
                    }
                    handleBranchSelect={handleBranchChange}
                    datasetVersions={datasetVersions}
                  >
                    {selectedCodeIntegrationBinder?.type ===
                    'bindByVersionId' ? (
                      <ShowVersion
                        codeIntegrationVersion={selectedCodeIntegrationVersion}
                        codeIntegrationVersionToVersionIndexMap={
                          codeIntegrationVersionToVersionIndexMap
                        }
                        commitStartTruncateLength={18}
                      />
                    ) : (
                      <BranchChip branch={selectedBranch} />
                    )}
                  </DatasetVersionsPopover>
                </div>
              </div>
              {selectedCodeIntegrationBinder?.type === 'bindByBranch' && (
                <div className="flex gap-2 flex-col">
                  <span className="text-gray-400 text-xs uppercase">
                    branch latest version:
                  </span>
                  <ShowVersion
                    codeIntegrationVersion={selectedCodeIntegrationVersion}
                    codeIntegrationVersionToVersionIndexMap={
                      codeIntegrationVersionToVersionIndexMap
                    }
                    commitStartTruncateLength={20}
                    hideBranch
                  />
                </div>
              )}

              {!isConnectedVersionValid && (
                <span className="text-error-500 px-2 -mt-6">
                  Please select a valid version
                </span>
              )}
            </>
          )}
        </NodeDetailsPanel>
        {isConnectedVersionValid && (
          <>
            <ValidateAssetsButton />
            {(!datasetMappingFileExists ||
              !networkAndDatasetMappingsAreEqual) && (
              <div className="flex flex-col justify-center items-center py-4 gap-2 border-b border-solid border-white/20">
                <span className="flex w-full h-fit text-sm text-gray-350 px-4">
                  {!datasetMappingFileExists
                    ? NO_DATASET_MAPPING_MESSAGE
                    : DATASET_MAPPING_MISSMATCH_MESSAGE}
                </span>
                <div className="flex w-full flex-row gap-4 justify-end px-4">
                  <Tooltip title={createOrUpdateTooltip}>
                    <Button
                      className="h-8"
                      variant="inverted-gray"
                      onClick={() => addOrUpdateNetworkMapping()}
                    >
                      {createOrUpdateText}
                    </Button>
                  </Tooltip>
                  {datasetMappingFileExists &&
                    !networkAndDatasetMappingsAreEqual &&
                    currentModelGraph && (
                      <Tooltip title={APPLY_BUTTON_TOOLTIP}>
                        <Button
                          className="h-8"
                          variant="inverted-gray"
                          onClick={applyDatasetMapping}
                        >
                          {APPLY_BUTTON_TEXT}
                        </Button>
                      </Tooltip>
                    )}
                </div>
              </div>
            )}
            <NodeDetailsPanel
              title="Assets"
              icon={<MiniDocIcon />}
              openByDefault
            >
              {selectedCodeIntegrationVersion ? (
                <DatasetVersionProperties
                  datasetSetup={selectedCodeIntegrationVersion.metadata.setup}
                  modelSetup={
                    selectedCodeIntegrationVersion.metadata.modelSetup
                  }
                />
              ) : (
                <NoPreview />
              )}
            </NodeDetailsPanel>
          </>
        )}
      </div>

      {codeIntegrationIsExpanded && <CodeIntegrationEditor />}
    </div>
  );
}
