import { ReactNode } from 'react';
import { useOpenState } from '../../ui/atoms/utils/useOpenState';
import { CategoryValue, CategoryMeta } from './types';
import { first } from 'lodash';
import { CategoriesSelectorPopup } from './CategoriesSelectorPopup';
import { CategoriesChips } from './CategoriesChips';

export type SelectCategoriesProps = {
  className?: string;
  label: ReactNode;
  categoriesMeta: CategoryMeta[];
  value?: CategoryValue;
  onChange: (value: CategoryValue) => void;
};

export function SelectCategories({
  value = {},
  onChange,
  label,
  categoriesMeta,
}: SelectCategoriesProps) {
  const { isOpen, open, close } = useOpenState(false);
  return (
    <div className="flex flex-col">
      {label}
      <CategoriesSelectorPopup
        open={isOpen}
        onClose={close}
        categoriesMeta={categoriesMeta}
        value={value}
        onChange={onChange}
        defaultOpenCategory={first(categoriesMeta)?.category}
      >
        <div
          tabIndex={0}
          onFocus={open}
          onClick={open}
          className="p-3 border cursor-pointer border-gray-600 rounded min-h-[48px]"
        >
          <CategoriesChips
            onChange={onChange}
            categoriesMeta={categoriesMeta}
            value={value}
          />
        </div>
      </CategoriesSelectorPopup>
    </div>
  );
}
