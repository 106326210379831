import {
  MouseEvent,
  MouseEventHandler,
  ReactElement,
  useCallback,
} from 'react';
import { Node as NodeData } from '@tensorleap/api-client';
import { Button, Popover, PopoverProps, Tooltip } from '../ui/mui';
import { Duplicate, SpawnIcon, Trash } from '../ui/icons';
import { ContextMenuPopoverParams } from '../core/useContextMenuPopoverState';
import { COMPONENT_DESCRIPTORS_MAP, OutputBlock } from './interfaces';
import { TooltipProps } from '@material-ui/core';
import clsx from 'clsx';
import { useNetworkMapContext } from '../core/NetworkMapContext';

const STATIC_CONTEXT_MENU_POPOVER_PROPS: Partial<PopoverProps> = {
  anchorReference: 'anchorPosition',
  transformOrigin: { vertical: 'top', horizontal: 'left' },
};

interface ContextItemProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  hideBorder?: boolean;
  icon: ReactElement;
  title: TooltipProps['title'];
}
function ContextItem({
  hideBorder,
  onClick,
  icon,
  disabled,
  title,
}: ContextItemProps) {
  return (
    <Tooltip title={title} arrow>
      {/**
       * @description
       * Since disabled buttons don't emit events it MUST be wrapped in another HTML
       * tag (`div`) for the tooltip (`Tooltip`) to work
       */}
      <div
        className={clsx(
          !hideBorder && 'border-solid border-r border-r-gray-800'
        )}
      >
        <Button disabled={disabled} onClick={onClick}>
          {icon}
        </Button>
      </div>
    </Tooltip>
  );
}

interface NodeContextMenuProps extends ContextMenuPopoverParams {
  node: NodeData;
}
export function NodeContextMenu({
  node,
  isContextMenuOpen,
  contextMenuPosition,
  handleContextMenuClose,
}: NodeContextMenuProps): JSX.Element {
  const { deleteOneOrManyNodes, cloneNode, spawnNode } = useNetworkMapContext();

  const handleDelete = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      const outputBlocks = (node.data.output_blocks as OutputBlock[]) || [];
      const rpNodesIds = outputBlocks.map(({ block_node_id }) => block_node_id);
      deleteOneOrManyNodes(node.id, ...rpNodesIds);
    },
    [node, deleteOneOrManyNodes]
  );

  const handleClone = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      cloneNode(node.id);
    },
    [node, cloneNode]
  );

  const handleSpawn = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.preventDefault();
      spawnNode(node.id);
    },
    [node, spawnNode]
  );

  const componentDescriptor = COMPONENT_DESCRIPTORS_MAP.get(node.name);

  const isCopyDisabled = node.name === 'Dataset';
  const isSpawnDisabled = !componentDescriptor?.options?.representation_block;

  return (
    <Popover
      {...STATIC_CONTEXT_MENU_POPOVER_PROPS}
      open={isContextMenuOpen}
      classes={{
        paper: 'bg-gray-850',
      }}
      anchorPosition={{
        top: contextMenuPosition[1],
        left: contextMenuPosition[0],
      }}
    >
      <div className="flex" onMouseLeave={handleContextMenuClose}>
        <ContextItem
          disabled={isCopyDisabled}
          onClick={handleClone}
          title="Duplicate"
          icon={<Duplicate />}
        />
        <ContextItem onClick={handleDelete} title="Delete" icon={<Trash />} />
        <ContextItem
          disabled={isSpawnDisabled || isCopyDisabled}
          onClick={handleSpawn}
          title="Spawn"
          hideBorder
          icon={<SpawnIcon />}
        />
      </div>
    </Popover>
  );
}
