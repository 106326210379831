import {
  AllSessionsTestResults,
  SessionTestData,
} from '@tensorleap/api-client';
import { useMemo } from 'react';
import { useCurrentProject } from '../core/CurrentProjectContext';
import {
  fetchAllModelTestResultsProps,
  useFetchAllModelTestResults,
} from '../core/data-fetching/model-tests-results';
import { useSelectedModelSummaryFilter } from '../ui/molecules/useSelectedModelSummary';
import { OnEpochChange } from './ModelTestSummary';

export type UseModelTestsResultsReturn = {
  refetch: () => void;
  isLoading: boolean;
  data: AllSessionsTestResults[];
};

export type UseModelTetstResultQueryReturn = {
  query: fetchAllModelTestResultsProps;
  updateEpochChange: OnEpochChange;
  epochBySessionRunId: Map<string, number>;
};

export function useModelTestsResultsQuery(): UseModelTetstResultQueryReturn {
  const { fetchValidProjectCid } = useCurrentProject();
  const projectId = fetchValidProjectCid();
  const {
    updateEpochChange,
    epochBySessionRunId,
  } = useSelectedModelSummaryFilter();

  const sessionsTestData: SessionTestData[] = useMemo(
    () =>
      Array.from(epochBySessionRunId.entries()).reduce(
        (acc, [sessionRunId, epoch]) => {
          if (epoch !== undefined && epoch >= 0) {
            acc.push({
              projectId,
              sessionRunId,
              epoch,
            });
          }
          return acc;
        },
        [] as SessionTestData[]
      ),
    [epochBySessionRunId, projectId]
  );

  return {
    query: {
      projectId,
      sessionsTestData,
    },
    updateEpochChange,
    epochBySessionRunId,
  };
}

export function useModelTestsResults(
  modelTestResultQuery: UseModelTetstResultQueryReturn
): UseModelTestsResultsReturn {
  const { modelTestResults, isLoading, refetch } = useFetchAllModelTestResults(
    modelTestResultQuery.query
  );

  return {
    refetch,
    isLoading: isLoading,
    data: modelTestResults,
  };
}
