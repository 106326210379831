import { PropsWithChildren } from 'react';
import { Chip } from './Chip';
import { ClassNameProp } from '../../core/types';

export function PlaceholderChip({
  children,
  className,
}: PropsWithChildren<ClassNameProp>) {
  return (
    <Chip
      className={className}
      borderClassName="bg-transparent border-dashed text-white/40 border-gray-600"
    >
      {children}
    </Chip>
  );
}
