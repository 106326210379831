import { useCallback, useEffect } from 'react';
import { useQueryParams } from '../core/useQueryParams';
import { Button } from '../ui/atoms/Button';
import api from '../core/api-client';
import { useHistory } from 'react-router';
import { CODE_CHALLENGE_KEY, URLS_ENUM } from '../url/url-builder';

export function AuthorizationRequestPage(): JSX.Element {
  const [code_challenge] = useQueryParams(CODE_CHALLENGE_KEY);
  const history = useHistory();

  const handleAllow = useCallback(async () => {
    await api.setCodeChallenge({ codeChallenge: code_challenge });
    history.replace(URLS_ENUM.WELCOME);
  }, [code_challenge, history]);

  useEffect(() => {
    if (!code_challenge) {
      history.replace(URLS_ENUM.WELCOME);
    }
  }, [code_challenge, history]);

  return (
    <div className="grid h-screen place-items-center">
      <div className="flex flex-col items-center border border-gray-600 p-4 gap-2 rounded">
        <h3 className=" font-bold">Authorization Request</h3>
        <p className="text-gray-300">
          Application wants to access your account
        </p>
        <Button onClick={handleAllow}>authorize</Button>
      </div>
    </div>
  );
}
