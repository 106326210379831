import { Divider, IconButton } from '@material-ui/core';
import { Title } from '../ui/atoms/Title';
import { XClose } from '../ui/icons';

type NoNodeSelectedProps = {
  onClose: () => void;
};

export function NoNodeSelected({ onClose }: NoNodeSelectedProps): JSX.Element {
  return (
    <div className="w-full gap-2 py-2 h-full flex flex-col px-4">
      <div className="flex items-center justify-between">
        <Title
          className="py-2"
          small
          bottomBorderClassName="border-b-network-500"
        >
          node details
        </Title>
        <IconButton size="small" onClick={onClose}>
          <XClose />
        </IconButton>
      </div>
      <Divider />
      <span className="text-gray-300 text-center">No node selected</span>
    </div>
  );
}
