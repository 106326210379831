import useSWR, { KeyedMutator } from 'swr';
import { JobTrigger, RunProcess } from '@tensorleap/api-client';

import api from '../api-client';
import { CacheKey } from './consts';
import { useMergedObject } from '../useMergedObject';

export interface UseFetchJobList {
  processesList: RunProcess[] | undefined;
  error?: Error;
  isLoading: boolean;
  refetch: KeyedMutator<RunProcess[]>;
}
export function useFetchJobList(): UseFetchJobList {
  const { data: processesList, error, mutate } = useSWR<RunProcess[], Error>(
    CacheKey.JOB_LIST,
    async () => {
      const { processes } = await api.getTeamJobs({
        trigger: JobTrigger.Manual,
      });
      return processes;
    }
  );

  return useMergedObject({
    processesList,
    error,
    isLoading: !error && !processesList,
    refetch: mutate,
  });
}
