import { LayerProperties } from '../../layer-details/Properties';
import {
  EntityDescriptor,
  EntityDescriptorWithoutType,
  NodeTypes,
} from './types';
import {
  updateStateIfNotAllNodeInputsConnected,
  updateStateIfNotConnectedToOptimizer,
} from './utils';

const layerDescriptor: EntityDescriptorWithoutType<NodeTypes.Layer> = {
  name: NodeTypes.Layer,
  colorTheme: 'layer',
  detailsTitle: 'LAYER DETAILS',
  CustomDetails: LayerProperties,
};

const lossDescriptor: EntityDescriptorWithoutType<NodeTypes.Loss> = {
  name: NodeTypes.Loss,
  colorTheme: 'loss',
  detailsTitle: 'LOSS DETAILS',
  CustomDetails: LayerProperties,
  updateState: (node, ctx) => {
    updateStateIfNotConnectedToOptimizer(node, ctx);
    updateStateIfNotAllNodeInputsConnected(node, ctx);
  },
};

const optimizerDescriptor: EntityDescriptorWithoutType<NodeTypes.Optimizer> = {
  name: NodeTypes.Optimizer,
  colorTheme: 'optimizer',
  detailsTitle: 'OPTIMIZER DETAILS',
  CustomDetails: LayerProperties,
};

const wrapperDescriptor: EntityDescriptorWithoutType<NodeTypes.Wrapper> = {
  name: NodeTypes.Wrapper,
  colorTheme: 'layer',
  detailsTitle: 'WRAPPER DETAILS',
};

export const NODE_TYPE_DESCRIPTORS: Partial<
  { [N in NodeTypes]: EntityDescriptor<'nodeType', N> }
> = {
  [layerDescriptor.name]: { ...layerDescriptor, type: 'nodeType' },
  [lossDescriptor.name]: { ...lossDescriptor, type: 'nodeType' },
  [optimizerDescriptor.name]: { ...optimizerDescriptor, type: 'nodeType' },
  [wrapperDescriptor.name]: { ...wrapperDescriptor, type: 'nodeType' },
};
