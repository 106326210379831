import { ChangeEvent, useCallback } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Chip } from './Chip';
import { XCloseIcon2 } from '../icons';

export type ChipsInputProps = {
  label: string;
  value: string[];
  options?: string[];
  onChange: (value: string[]) => void;
};

export function ChipsInput({
  value,
  options = [],
  onChange,
  label,
}: ChipsInputProps) {
  const handleChipAdd = useCallback(
    (_event: ChangeEvent<unknown>, newChips: string[]) => {
      onChange(newChips);
    },
    [onChange]
  );

  const handleChipDelete = useCallback(
    (chipToDelete: string) => {
      onChange(value.filter((chip) => chip !== chipToDelete));
    },
    [value, onChange]
  );

  return (
    <Autocomplete
      multiple
      freeSolo
      options={options}
      value={value}
      onChange={handleChipAdd}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip key={index} {...getTagProps({ index })}>
            {option}
            <XCloseIcon2
              className="ml-2 text-sm cursor-pointer"
              onClick={() => handleChipDelete(option)}
            />
          </Chip>
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          placeholder="Type and press Enter"
        />
      )}
    />
  );
}
