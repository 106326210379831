import { useCallback, useEffect, useMemo } from 'react';
import { Drawer } from '../../ui/atoms/Drawer';
import { ButtonPopup, DrawerTabs } from '../../ui/atoms/DrawerTabs';
import {
  IssueIcon,
  PushpinOffIcon,
  PushpinOnIcon,
  TestIcon,
  XClose,
} from '../../ui/icons';
import { useInsightsComponents } from '../../insights/insightsDrawerHooks';
import { DrawerSideTab } from '../../ui/atoms/DrawerSideTab';
import { IssueDrawerContent } from '../../issues/IssueDrawerContent';
import clsx from 'clsx';
import { StateObject } from '../../core/types';
import { IconButton } from '../../ui/mui';
import { ModelTestsDrawerContent } from '../../model-tests/ModelTestsDrawerContent';
import { UseModelTestStateReturn } from '../../model-tests/useModelTestState';
import { fakeSetter } from '../../core/fakeSetter';
import { useIssuesState } from '../../issues/useIssuesState';
import { TOUR_SELECTORS_ENUM } from '../../tour/ToursConfig';

export enum DrawerTabsEnum {
  Insights = 'insights',
  Issues = 'issues',
  Tests = 'tests',
}

export type DashboardDrawerProps = {
  selectedDrawerTab: DrawerTabsEnum | undefined;
  setSelectedDrawerTab: (_: DrawerTabsEnum | undefined) => void;
  modelTestsState: UseModelTestStateReturn;
  pinned: boolean;
  setIsPinned: (isPinned: boolean) => void;
};

export function DashboardDrawer({
  selectedDrawerTab,
  setSelectedDrawerTab,
  modelTestsState,
  pinned,
  setIsPinned,
}: DashboardDrawerProps): JSX.Element {
  const issuesState = useIssuesState();

  const isOpen = useMemo(() => selectedDrawerTab !== undefined || pinned, [
    pinned,
    selectedDrawerTab,
  ]);
  const isOpenState: StateObject = useMemo(
    () => ({
      state: isOpen,
      setState: fakeSetter(isOpen, (v) =>
        setSelectedDrawerTab(
          v ? selectedDrawerTab || DrawerTabsEnum.Insights : undefined
        )
      ),
    }),
    [isOpen, setSelectedDrawerTab, selectedDrawerTab]
  );

  const onMenuItemClick = useCallback(
    (drawerType: DrawerTabsEnum) => {
      setSelectedDrawerTab(
        selectedDrawerTab === drawerType && !pinned ? undefined : drawerType
      );
    },
    [pinned, selectedDrawerTab, setSelectedDrawerTab]
  );

  const {
    insightMenuOption,
    insightDrawerPopup,
    insightDrawerContent,
  } = useInsightsComponents(selectedDrawerTab === DrawerTabsEnum.Insights, () =>
    onMenuItemClick(DrawerTabsEnum.Insights)
  );

  const issuesMenuOption = useMemo(
    () => (
      <DrawerSideTab
        onClick={() => onMenuItemClick(DrawerTabsEnum.Issues)}
        selected={selectedDrawerTab === DrawerTabsEnum.Issues}
        icon={<IssueIcon className="rotate-180 h-auto w-3/4" />}
        buttonColor="blue"
        tourId={TOUR_SELECTORS_ENUM.ISSUES_PANEL_BUTTON_ID}
      >
        issues
      </DrawerSideTab>
    ),
    [onMenuItemClick, selectedDrawerTab]
  );

  const modelTestsMenuOption = useMemo(
    () => (
      <DrawerSideTab
        onClick={() => onMenuItemClick(DrawerTabsEnum.Tests)}
        selected={selectedDrawerTab === DrawerTabsEnum.Tests}
        icon={<TestIcon className="rotate-180 h-auto w-2/3" />}
        buttonColor="blue"
        tourId={TOUR_SELECTORS_ENUM.TESTS_PANEL_BUTTON_ID}
      >
        tests
      </DrawerSideTab>
    ),
    [onMenuItemClick, selectedDrawerTab]
  );

  const drawerButtonsPopups: ButtonPopup[] = useMemo(() => {
    const drawerFeautures: ButtonPopup[] = [
      {
        key: DrawerTabsEnum.Insights,
        button: insightMenuOption,
        popup: insightDrawerPopup,
      },
      { key: DrawerTabsEnum.Issues, button: issuesMenuOption },
      { key: DrawerTabsEnum.Tests, button: modelTestsMenuOption },
    ];

    return drawerFeautures;
  }, [
    insightMenuOption,
    insightDrawerPopup,
    issuesMenuOption,
    modelTestsMenuOption,
  ]);

  const drawerContent = useMemo(() => {
    switch (selectedDrawerTab) {
      case DrawerTabsEnum.Insights: {
        return insightDrawerContent;
      }
      case DrawerTabsEnum.Issues: {
        return <IssueDrawerContent {...issuesState} />;
      }
      case DrawerTabsEnum.Tests: {
        return <ModelTestsDrawerContent state={modelTestsState} />;
      }
    }
  }, [selectedDrawerTab, insightDrawerContent, issuesState, modelTestsState]);

  const subContent = <DrawerTabs drawerButtonsPopups={drawerButtonsPopups} />;

  const isExpanded = useMemo(
    () => issuesState.isExpanded && selectedDrawerTab === 'issues',
    [issuesState.isExpanded, selectedDrawerTab]
  );
  const drawerSize = isExpanded ? '5xl' : 'lg';

  const togglePinned = useCallback(() => {
    setIsPinned(!pinned);
  }, [pinned, setIsPinned]);

  const closeDrawer = useCallback(() => {
    setSelectedDrawerTab(undefined);
    setIsPinned(false);
  }, [setIsPinned, setSelectedDrawerTab]);

  useEffect(() => {
    if (isExpanded) {
      setIsPinned(false);
    }
  }, [isExpanded, pinned, setIsPinned, togglePinned]);

  return (
    <Drawer
      openState={isOpenState}
      drawerClassName={clsx(
        'right-0 z-30 h-full w-auto',
        (!pinned || !isOpenState.state) && 'absolute'
      )}
      contentClassName="bg-gray-900"
      position="right"
      subContent={subContent}
      subContentClassName="bg-gray-900"
      drawerSize={drawerSize}
      closeOnBlur={false}
      persist={pinned}
    >
      <div
        className={clsx(
          'flex flex-col h-full bg-gray-900 border-l-2 border-gray-700 items-end pt-2 justify-start transition-[width]',
          isExpanded ? 'w-320' : 'w-120'
        )}
      >
        <div className="flex flex-row mx-6 my-2 w-full h-fit">
          <div className="flex-grow ml-10">
            <IconButton
              disabled={isExpanded}
              className="w-2 h-2"
              onClick={togglePinned}
            >
              {pinned ? (
                <PushpinOnIcon className="rounded-full bg-primary-900 border border-gray-700" />
              ) : (
                <PushpinOffIcon />
              )}
            </IconButton>
          </div>
          <IconButton className="w-2 h-2" onClick={closeDrawer}>
            <XClose />
          </IconButton>
        </div>
        {isOpen && drawerContent}
      </div>
    </Drawer>
  );
}
