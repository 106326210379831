import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgNnMapBoldIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.632 10a2 2 0 0 0-2 2v.942c-.526.136-1.156.386-1.633.858-.54.535-.814 1.169-1.03 1.676l-.015.037c-.221.521-.387.91-.677 1.244-.14.16-.327.303-.54.43V17a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h4.737a2 2 0 0 0 2-2v-.186a2.2 2.2 0 0 1 .54.429c.29.334.456.723.677 1.244l.015.037c.216.507.49 1.141 1.03 1.676.477.472 1.107.722 1.633.858V25a2 2 0 0 0 2 2h4.736a2 2 0 0 0 2-2v-.942c.526-.136 1.156-.386 1.633-.858.54-.535.814-1.169 1.03-1.676l.015-.037c.221-.521.387-.91.677-1.244a2.2 2.2 0 0 1 .54-.43V20a2 2 0 0 0 2 2H33a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2h-4.737a2 2 0 0 0-2 2v.186a2.202 2.202 0 0 1-.54-.429c-.29-.334-.456-.723-.677-1.244l-.015-.037c-.216-.507-.49-1.141-1.03-1.676-.477-.472-1.107-.722-1.633-.858V12a2 2 0 0 0-2-2h-4.736Zm-2 12.485V22a2 2 0 0 1 2-2h4.736a2 2 0 0 1 2 2v.485c.232-.092.434-.208.578-.351.306-.303.484-.68.704-1.197l.04-.096c.2-.471.45-1.064.902-1.584.273-.312.608-.56.946-.757a3.728 3.728 0 0 1-.946-.757c-.452-.52-.702-1.113-.901-1.584l-.041-.096c-.22-.518-.398-.893-.704-1.197a1.718 1.718 0 0 0-.578-.35V15a2 2 0 0 1-2 2h-4.736a2 2 0 0 1-2-2v-.485a1.718 1.718 0 0 0-.578.351c-.306.303-.484.68-.704 1.197l-.04.096c-.2.471-.45 1.064-.902 1.584a3.728 3.728 0 0 1-.946.757c.338.196.673.445.946.757.452.52.702 1.113.902 1.584l.04.096c.22.518.398.893.704 1.197.144.143.346.259.578.35Zm2-10.485h4.736v3h-4.736v-3Zm0 10h4.736v3h-4.736v-3ZM33 17h-4.737v3H33v-3ZM3 17h4.737v3H3v-3Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgNnMapBoldIcon;
