import { useCallback, useMemo } from 'react';
import { useFetcher } from '../../core/useFetcher';
import { useMapStoredProjectImgUrl } from '../../core/useProjectStorage';
import { CropImg } from './types';
import { fetchImageData, imageDataToImageSrc } from './utils';

type MapImageProps = {
  src: string;
  className?: string;
  crop: CropImg;
};

export function CropImageWithMapUrl({
  src,
  ...props
}: MapImageProps): JSX.Element {
  const mappedSrc = useMapStoredProjectImgUrl(src);
  return <CropImage src={mappedSrc} {...props} />;
}

export function CropImage({
  src,
  className,
  crop,
}: MapImageProps): JSX.Element {
  const fetchImage = useCallback(() => {
    return fetchImageData(src, crop);
  }, [src, crop]);

  const { data: imgData } = useFetcher(fetchImage);

  const imageSrc = useMemo(() => {
    if (!imgData) return '';
    return imageDataToImageSrc(imgData);
  }, [imgData]);

  return <img src={imageSrc} className={className} />;
}
type OptinalCropImageProps = Omit<MapImageProps, 'crop'> & {
  crop?: CropImg;
};

export function OptionalCropImage({
  src,
  className,
  crop,
}: OptinalCropImageProps) {
  return crop ? (
    <CropImage src={src} className={className} crop={crop} />
  ) : (
    <img src={src} className={className} />
  );
}
