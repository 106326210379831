import { TOUR_SELECTORS_ENUM } from '../../../tour/ToursConfig';
import { NoDataChart } from '../../../ui/charts/common/NoDataChart';

export function NoSampleSelected() {
  return (
    <div
      className="flex flex-col flex-1 bg-gray-900"
      id={TOUR_SELECTORS_ENUM.SAMPLE_ANALYSIS_NO_SAMPLE_SELECTED_ID}
    >
      <NoDataChart text="Select a sample to view its assets" />
    </div>
  );
}
