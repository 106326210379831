import { CodeEditor } from './CodeEditor';
import { DatasetEditorTopBar } from './DatasetEditorTopBar';
import { FileTree } from './FileTree';

export function CodeIntegrationEditor(): JSX.Element {
  return (
    <div className="flex-1 flex flex-col">
      <DatasetEditorTopBar />
      <div className="flex flex-row flex-1 h-full overflow-hidden">
        <FileTree className="max-w-sm" />
        <CodeEditor />
      </div>
    </div>
  );
}
