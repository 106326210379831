const KB = 1024;
const MB = 1048576;
const GB = 1073741824;

export function formatBytesSizeUnits(bytes: number): string {
  if (bytes >= GB) {
    return (bytes / GB).toFixed(2) + ' GB';
  } else if (bytes >= MB) {
    return (bytes / MB).toFixed(2) + ' MB';
  } else if (bytes >= KB) {
    return (bytes / KB).toFixed(2) + ' KB';
  } else if (bytes > 1) {
    return bytes + ' bytes';
  } else if (bytes == 1) {
    return bytes + ' byte';
  } else {
    return '0 bytes';
  }
}
