import { Tooltip } from '@material-ui/core';
import { useControls } from 'react-zoom-pan-pinch';
import { ZoomFit, ZoomInIcon, ZoomOutIcon } from '../icons';
import clsx from 'clsx';

export interface ZoomButtonsProps {
  className?: string;
}

export function ZoomButtons({ className }: ZoomButtonsProps): JSX.Element {
  const { zoomIn, zoomOut, resetTransform } = useControls();
  return (
    <div className={clsx('flex gap-1', className)}>
      <Tooltip title={ZoomInTitle}>
        <div
          onClick={() => zoomIn()}
          className="focus:outline-none cursor-pointer"
        >
          <ZoomInIcon className="w-6 h-6 text-gray-400 hover:text-white" />
        </div>
      </Tooltip>

      <Tooltip title={ZoomOutTitle}>
        <div
          onClick={() => zoomOut()}
          className="focus:outline-none cursor-pointer"
        >
          <ZoomOutIcon className="w-6 h-6 text-gray-400 hover:text-white" />
        </div>
      </Tooltip>

      <Tooltip title="Reset zoom">
        <div
          onClick={() => resetTransform()}
          className="focus:outline-none cursor-pointer"
        >
          <ZoomFit className="w-6 h-6 text-gray-400 hover:text-white" />
        </div>
      </Tooltip>
    </div>
  );
}

const ZoomInTitle = (
  <div className="flex flex-col">
    <div>Zoom in</div>
    <div>Ctrl + Scroll up</div>
  </div>
);

const ZoomOutTitle = (
  <div className="flex flex-col">
    <div>Zoom out</div>
    <div>Ctrl + Scroll down</div>
  </div>
);
