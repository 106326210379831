import { useCallback, useEffect } from 'react';
import { CodeIntegrationBinder } from '@tensorleap/api-client';
import { IconButton, Divider, Tooltip } from '../ui/mui';

import {
  Check1,
  ClosePanel,
  OpenPanel,
  XClose,
  XCloseIcon2,
} from '../ui/icons';

import { SecretManagementProvider } from '../assets-management/SecretManagementContext';
import { Title } from '../ui/atoms/Title';
import { ValidateAssetsStatus } from '../network-editor/interfaces/ValidateGraphStatus';
import { useNetworkMapContext } from '../core/NetworkMapContext';
import { useDatasets } from '../core/DatasetsContext';
import { MapCodeView } from '../assets-management/code-integration/MapCodeView';
import { FullScreenEnabler } from '../ui/atoms/FullScreenDialogEnabler';
import { useNetworkWizardContext } from '../network-editor/wizard/NetworkWizardContext';
import { NetworkWizardErrorSeverity } from '../network-editor/wizard/types';
import { useCurrentProject } from '../core/CurrentProjectContext';

export interface CodeIntegrationProps {
  onClose: () => void;
}

export function CodeIntegration({
  onClose,
}: CodeIntegrationProps): JSX.Element {
  const {
    codeIntegrationIsExpanded,
    toggleCodeIntegrationExpanded,
  } = useNetworkMapContext();
  return (
    <div className="h-full w-full flex flex-col bg-gray-900 z-50">
      <div className="flex justify-end">
        <IconButton size="small" onClick={onClose}>
          <XClose />
        </IconButton>
      </div>
      <div className="flex items-center px-4 pb-2">
        <Title small bottomBorderClassName="border-b-dataset-500">
          code integration
        </Title>
        <span className="flex-1" />
        <IconButton
          onClick={toggleCodeIntegrationExpanded}
          data-testid="expand-node-details"
        >
          {codeIntegrationIsExpanded ? <OpenPanel /> : <ClosePanel />}
        </IconButton>
      </div>

      <Divider />
      <div className="flex-1 w-full max-h-full overflow-auto">
        <CodeIntegrationDetails />
      </div>
    </div>
  );
}

export function CodeIntegrationDetails(): JSX.Element {
  const { updateDataset } = useNetworkMapContext();
  const {
    selectedCodeIntegrationVersion,
    selectedCodeIntegrationBinder,
  } = useCurrentProject();

  // const [connectedDatasetVersion, setConnectedDatasetVersion] = useState(
  //   selectedCodeIntegrationVersion
  // );

  const {
    changeDatasetContext,
    fullScreenMode,
    setFullScreenMode,
  } = useDatasets();

  const handleConnectedDatasetVersionChange = useCallback(
    (codeIntegrationBinder?: CodeIntegrationBinder) => {
      updateDataset(codeIntegrationBinder);
    },
    [updateDataset]
  );

  useEffect(() => {
    if (selectedCodeIntegrationVersion?.cid) {
      changeDatasetContext(
        selectedCodeIntegrationVersion.datasetId,
        selectedCodeIntegrationVersion
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCodeIntegrationVersion?.cid]);

  return (
    <FullScreenEnabler
      fullScreenMode={fullScreenMode}
      exitFullScreenMode={() => setFullScreenMode(false)}
    >
      <SecretManagementProvider>
        <MapCodeView
          selectedCodeIntegrationBinder={selectedCodeIntegrationBinder}
          handleConnectedDatasetVersionChange={
            handleConnectedDatasetVersionChange
          }
        />
      </SecretManagementProvider>
    </FullScreenEnabler>
  );
}

export function ValidateGraphResultIcon(): JSX.Element {
  const { validateAssetsStatus } = useNetworkMapContext();
  const { allAlerts } = useNetworkWizardContext();

  if (
    [
      ValidateAssetsStatus.Calculating,
      ValidateAssetsStatus.Unstarted,
      ValidateAssetsStatus.CalculatingDigest,
    ].includes(validateAssetsStatus)
  )
    return <div />;
  else if (
    validateAssetsStatus === ValidateAssetsStatus.Failed ||
    allAlerts.filter(
      ({ errorSeverity }) => errorSeverity === NetworkWizardErrorSeverity.ERROR
    ).length > 0
  )
    return (
      <Tooltip title="Assets are not valid, fix the error alerts">
        <div>
          <XCloseIcon2 className="rounded-full text-gray-350 bg-error-500" />
        </div>
      </Tooltip>
    );
  else if (
    allAlerts.filter(
      ({ errorSeverity }) =>
        errorSeverity === NetworkWizardErrorSeverity.WARNING
    ).length > 0
  )
    return (
      <Tooltip title="Assets may be not valid, fix the warnings alerts">
        <div>
          <XCloseIcon2 className="rounded-full text-gray-350 bg-warning-500" />
        </div>
      </Tooltip>
    );
  else if (validateAssetsStatus === ValidateAssetsStatus.Passed)
    return (
      <Tooltip title="Assets are valid!">
        <div>
          <Check1 className="rounded-full text-gray-350 bg-secondary-500" />
        </div>
      </Tooltip>
    );
  else {
    console.error(
      `validateAssetsStatus ${validateAssetsStatus} is not supported`
    );
    return <div />;
  }
}
