import { Node } from '@tensorleap/api-client';
import { useCallback } from 'react';
import { NodeDetailsPanel } from './NodeDetalisPanel';
import SvgSettingsCog2 from '../ui/icons/SettingsCog2';
import { Input } from '../ui/atoms/Input';
import { useDebounceOnInputChange } from '../ui/atoms/utils/useDebonceOnInputChange';
import { useNetworkMapContext } from '../core/NetworkMapContext';

interface DynamicShapeDetailsProps {
  node: Node;
}
export function DynamicShapeDetails({
  node,
}: DynamicShapeDetailsProps): JSX.Element {
  const { changeNodeProperty } = useNetworkMapContext();

  const handleDatasetChange = useCallback(
    (e) => {
      changeNodeProperty({
        nodeId: node.id,
        nodeDataPropsToUpdate: {
          expected_output_shpae: e.target.value as string,
        },
      });
    },
    [changeNodeProperty, node.id]
  );

  const [value, onChange] = useDebounceOnInputChange(
    node.data.expected_output_shpae,
    handleDatasetChange,
    200
  );

  return (
    <NodeDetailsPanel
      openByDefault
      title="Dynamic Shape"
      className="p-4"
      icon={<SvgSettingsCog2 />}
    >
      <Input
        label="Expected Output Shape"
        value={value}
        info="e.g. 26,26,5"
        onChange={onChange}
      />
    </NodeDetailsPanel>
  );
}
