import * as React from 'react';
import { SVGProps } from 'react';
import clsx from 'clsx';

const SvgSaveCommit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(
      'inline-block text-2xl fill-current select-none shrink-0',
      props.className
    )}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.046 2H3.963A.965.965 0 0 0 3 2.964v17.357a.966.966 0 0 0 .964.965h16.072A.965.965 0 0 0 21 20.32V6.955a.958.958 0 0 0-.282-.682l-3.99-3.99A.957.957 0 0 0 16.044 2ZM7.3 3.485H4.486V19.8h15.028V7.17l-3.457-3.457v4.915H7.3V3.485Zm7.072 3.458V3.486H8.986v3.457h5.386Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.664 11.429a4 4 0 0 0-1.882-1.059V9.2h-1.886v1.17a3.995 3.995 0 0 0-1.883 6.71c.52.519 1.171.884 1.883 1.057v1.238h1.886v-1.238a3.995 3.995 0 0 0 1.882-6.708Zm-2.825 4.935a2.11 2.11 0 1 1 0-4.222 2.11 2.11 0 0 1 0 4.222Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSaveCommit;
